<template>
  <div class="initiatives" v-if="items.length">
    <div class="g-wrapper-offset-left">
      <div class="initiatives__list">
        <div v-for="(initiative, i) in items" :key="i" class="initiatives__col">
          <a
            :href="hrefWithRegion(initiative.url)"
            class="initiatives-item"
            :target="initiative.target"
          >
            <div class="initiatives-item__title" v-html="initiative.title" />
            <div class="initiatives-item__text" v-html="initiative.text" />
          </a>
        </div>
      </div>
      <div class="events__load-more" v-show="items.length < initItems.length">
        <div class="g-h4" @click="loadMore">Показать ещё</div>
      </div>
    </div>
    <ScrollTopBtn />
  </div>
</template>

<script>
export default {
  name: "Initiatives",
  props: {
    initItems: {
      type: Array,
      // TODO. Удалить
      default: () => [
        {
          title: "Инициатива «Наука рядом»",
          text: "Вовлечение школьников в&nbsp;исследовательскую деятельность через знакомство с&nbsp;учеными и&nbsp;изобретениями.",
          // url: "/upload/documents/1_Наука_рядом_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title:
            "Инициатива «Школьники в&nbsp;научно-технической деятельности»",
          text: "Систематизация имеющихся форматов и&nbsp;создание новых форматов детской проектной деятельности и&nbsp;научно-технического творчества.",
          // url: "/upload/documents/2_Школьники_в_НТД_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title: "Инициатива «Научное волонтерство»",
          text: "Вовлечение граждан в&nbsp;масштабные исследовательские проекты, в&nbsp;том числе по&nbsp;сбору и&nbsp;анализу научных данных.",
          // url: "/upload/documents/3_Научное_волонтерство_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title: "Инициатива «Наука побеждать»",
          text: "Создание новых и&nbsp;совершенствование имеющихся механизмов выявления талантливой молодежи.",
          // url: "/upload/documents/4_Наука_побеждать_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title: "Проект «Научные детские площадки»",
          text: "Создание инфраструктуры развивающего досуга для детей и&nbsp;их родителей.",
          // url: "/upload/documents/5_Научные_детские_площадки_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title: "Инициатива «Снова в&nbsp;школу»",
          text: "Развитие летних и&nbsp;других каникулярных школ для студентов и&nbsp;молодых ученых.",
          // url: "/upload/documents/6_Снова_в_школу_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title: "Инициатива «Проектирование будущего»",
          text: "Создание условий для долгосрочного планирования и&nbsp;регулярной актуализации приоритетных научных, научно-технических проектов.",
          // url: "/upload/documents/7_Проектирование_будущего_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title: "Инициатива «Работа с&nbsp;опытом»",
          text: "Изучение и&nbsp;систематизация знаний об&nbsp;истории отечественной науки и&nbsp;ее&nbsp;роли в&nbsp;развитии человечества, а&nbsp;также использование этого опыта для научно-технологического развития страны.",
          // url: "/upload/documents/8_Работа_с_опытом_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title:
            "Инициатива «Площадки для взаимодействия науки, бизнеса, государства и&nbsp;общества»",
          text: "Совершенствование механизмов взаимодействия исследователей, разработчиков и&nbsp;компаний реального сектора экономики.",
          // url: "/upload/documents/9_Площадки_для_взаимодействия_науки,_бизнеса,_государства_и_общества.pdf",
          // target: "_blank",
        },
        {
          title:
            "Инициатива «Решения и&nbsp;сервисы для профессионального сообщества»",
          text: "Создание привлекательных условий для построения карьеры в&nbsp;сфере науки и&nbsp;технологий.",
          // url: "/upload/documents/10_Решения_и_сервисы_для_профессионального_сообщества_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title:
            "Тематические инициативы по&nbsp;приоритетам научно-технологического развития Российской Федерации",
          text: "Разработка и&nbsp;реализация тематических инициатив по&nbsp;приоритетам стратегии научно-технологического развития Российской̆ Федерации.",
          // url: "/upload/documents/11_Разработка_и_реализация_тематических_инициатив_по_ПНТР_РФ_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title:
            "Конгресс молодых ученых и&nbsp;мероприятия - спутники Конгресса",
          text: "Проведение тематических ежегодных мероприятий, объединяющих молодых ученых Российской Федерации.",
          // url: "/upload/documents/12_Конгресс_молодых_ученых_и_мероприятия_спутники_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title: "Инициатива «Наука для всей семьи»",
          text: "Мероприятия для проведения семейного досуга, посвященные науке и&nbsp;технологиям.",
          // url: "/upload/documents/13_Наука_для_всей_семьи_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title: "Инициатива «Научно-популярный туризм»",
          text: "Создание по&nbsp;всей стране маршрутов для научно-популярного туризма.",
          // url: "/upload/documents/14_Научно_популярный_туризм_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title: "Инициатива «Наука как искусство»",
          text: "Популяризация современного научного знания и&nbsp;технологических достижений через художественные формы и&nbsp;средства креативных индустрий.",
          // url: "/upload/documents/15_Наука_как_искусство_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title:
            "Инициатива «Инфраструктура для популяризации науки, создание контента»",
          text: "Создание условий и&nbsp;стимулирующих экосистем для производства и&nbsp;массового распространения произведений, посвященных науке и&nbsp;деятельности ученых.",
          // url: "/upload/documents/16_Инфраструктура_для_популяризации_науки,_создание_контента_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title: "Юбилейные мероприятия",
          text: "Организация мероприятий, приуроченных к&nbsp;памятным датам отечественной̆ науки.",
          // url: "/upload/documents/17_Юбилейные_мероприятия_Паспорт.pdf",
          // target: "_blank",
        },
        {
          title:
            "Открытие центров, лабораторий, запуск исследовательской инфраструктуры",
          text: "Открытые новых передовых исследовательских центров и&nbsp;лабораторий с&nbsp;самым современным научным оборудованием.",
          // url: "/upload/documents/18_Открытие_центров,_лабораторий,_запуск_исследовательской_Паспорт.pdf",
          // target: "_blank",
        },
      ],
    },
  },
  data() {
    return {
      pageSize: 6,
    };
  },
  computed: {
    items() {
      return this.initItems.filter((item, index) => index + 1 <= this.pageSize);
    },
  },
  methods: {
    loadMore() {
      this.pageSize += this.pageSize;
    },
  },
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".initiatives";

#{$b} {
  &__list {
    list-style: none;
    padding: 0;
    margin: 0 0 0 -30px;
    display: flex;
    flex-wrap: wrap;

    @include desktop-min {
      margin-left: -40px;
    }
  }

  &__col {
    width: 100%;
    padding-left: 30px;
    margin-bottom: 30px;

    @include mobile-min {
      width: 50%;
    }

    @include desktop-min {
      width: calc(100% / 3);
      padding-left: 40px;
      margin-bottom: 40px;
    }

    &:nth-child(3n + 2) {
      @include desktop-min {
        width: calc(100% / 3 * 2);
      }
    }

    &:nth-child(6n + 3),
    &:nth-child(6n + 4) {
      @include mobile-min {
        width: 50%;
      }
    }
  }

  &-item {
    height: 100%;
    min-height: 200px;
    display: block;
    background-color: $color-dark-blue-3;
    background-image: url("/frontend/assets/img/events/regional-bg.jpg");
    background-position: center;
    background-size: cover;
    color: $white-true;
    text-decoration: none;
    padding: 40px;
    box-shadow: 0px 40px 80px rgba($color: $black-true, $alpha: 0.05);
    transition: 0.2s ease;
    transition-property: transform, box-shadow;

    @include desktop {
      padding: 30px;
    }

    @include mobile {
      min-height: auto;
    }

    &:hover,
    &:focus {
      transform: translateY(-10px);
      box-shadow: $shadow-default-hover;
    }

    &__title {
      display: block;
      text-decoration: none;
      font-weight: 400;
      font-size: 22px;
      line-height: 1.4;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      @include mobile {
        font-size: 16px;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 1.6;
      font-weight: 700;

      @include mobile {
        font-size: 12px;
      }
    }
  }

  &__load-more {
    @include clickable;
    position: relative;
    color: $color-base;
    transition: color 0.3s ease;

    &:hover,
    &:focus {
      color: $color-black;
    }

    @include tablet {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:after {
      content: "";
      width: 116px;
      height: 1.5px;
      position: absolute;
      top: 0.5em;
      right: calc(100% + 38px);
      background-color: $color-blue-line;
      background: linear-gradient(0deg, $color-blue-line 0%, #4f92ff 100%);

      @include desktop {
        right: calc(100% + 30px);
        width: 85px;
      }

      @include tablet {
        position: static;
        margin-left: 25px;
      }

      @include mobile {
        width: 55px;
      }
    }

    &:not(:first-child) {
      margin-top: 30px;

      @include desktop {
        margin-top: 20px;
      }
    }
  }
}
</style>
