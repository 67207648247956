<template>
  <div class="talisman-contestants-window">
    <TalismanContestantsPhone
      v-if="step === 1"
      :actionVerifyPhone="actionVerifyPhone"
      @success="onSuccessPhone"
    />
    <TalismanContestantsVerify
      v-if="step === 2"
      :actionVerifyCode="actionVerifyCode"
      :actionRefreshCode="actionVerifyPhone"
      :phone="phone"
      :workId="workId"
      @success="successVote"
    />
    <div v-if="step === 'success'" class="talisman-contestants-window__success">
      <div class="talisman-contestants-window__succes-text">Спасибо за участие в голосовании</div>
      <CustomButton
        class="talisman-contestants-window__succes-btn"
        theme="primary"
        size="md"
        @click="closeModal"
      >
        Закрыть
      </CustomButton>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['topMailRuHandler', 'ymHandler'],
  props: {
    workId: {
      type: String,
      default: '',
    },
    actionVerifyPhone: {
      type: String,
      default: '',
    },
    actionVerifyCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      step: 1,
      errorsAfterSubmit: [],
      phone: ''
    }
  },
  methods: {
    closeModal() {
      this.$root.$refs.customModal.closeModal()
    },
    successVote() {
      this.step = 'success'
      this.$root.$emit('updateTalismanVote', this.workId)
      this.topMailRuHandler({ goal: 'final' })
      this.ymHandler({ code: 93982301, type: 'reachGoal', operation: 'click_SendCode' })
    },
    onSuccessPhone(phone) {
      this.step = 2
      this.phone = phone
      this.topMailRuHandler({ goal: 'lead' })
      this.ymHandler({ code: 93982301, type: 'reachGoal', operation: 'click_GetSms' })
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-contestants-window';

#{$b} {
  font-family: $font-family-inter;

  // .talisman-contestants-window__succes
  &__succes {
    // .talisman-contestants-window__succes-btn

    &-btn {
      margin-top: 32px;

      @include mobile {
        width: 100%;
      }
    }
  }

}
</style>
