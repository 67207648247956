<template>
  <div ref="objectivesForm" class="initiatives-objectives-form">
    <div class="initiatives-objectives-form__objectives">
      <h3 class="initiatives-objectives-form__title tt-uppercase">Цели</h3>
      <div class="initiatives-objectives-form__fields">
        <div
          class="initiatives-objectives-form__field"
          v-for="(item, i) in objectives"
          :key="`initiatives-objectives-form-${i}`"
        >
          <div
            class="initiatives-objectives-form__field-delete"
            @click="remove(item.id)"
          >
            Удалить
          </div>
          <UTextInput
            class="u-text-input--smaller"
            name="eventName"
            label="Название цели"
            v-model="item.text"
            :is-text-area="true"
            placeholder="..."
            :error="
              $v.objectives.$each[i].text.$error &&
              !$v.objectives.$each[i].text.required
            "
          />
          <ErrorFormWrapper
            :isVisible="
              $v.objectives.$each[i].text.$error &&
              !$v.objectives.$each[i].text.required
            "
            class="initiatives-objectives-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
        <CustomButton
          class="initiatives-objectives-form__add-objective"
          theme="outline-primary"
          size="md"
          @click="addObjective"
        >
          Добавить
        </CustomButton>
      </div>
    </div>
    <div class="initiatives-objectives-form__description">
      <h3 class="initiatives-objectives-form__title tt-uppercase">
        Детальное описание
      </h3>
      <QuillWyz
        class="initiatives-objectives-form__editor"
        :initial-value="description"
        :toolbar-options="toolbarOptions"
        @change="onChangeWyz"
      />
    </div>
    <ErrorFormWrapper
      :isVisible="Boolean(errorsAfterSubmit.length)"
      class="initiatives-objectives-form__feedback-errors"
    >
      <div
        v-for="error in errorsAfterSubmit"
        :key="error.code"
        class="initiatives-objectives-form__feedback-error"
      >
        <span v-html="error.message" />
      </div>
    </ErrorFormWrapper>
    <div class="initiatives-objectives-form__actions">
      <CustomButton
        class="initiatives-objectives-form__submit"
        theme="primary"
        size="md"
        :status="statusBtn"
        @click="submit"
      >
        Сохранить
      </CustomButton>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: {
    initialObjectives: {
      type: Array,
      default: () => [],
    },
    initialDescription: {
      type: String,
      default: "",
    },
    submitUrl: {
      type: String,
      default: "",
    },
    initiativeId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      objectives: JSON.parse(JSON.stringify(this.initialObjectives)),
      description: this.initialDescription,
      statusBtn: "",
      errorsAfterSubmit: [],
    };
  },
  validations: {
    objectives: {
      $each: {
        text: { required },
      },
    },
  },
  computed: {
    toolbarOptions() {
      return [
        ["bold", "italic", "underline", "strike", "link"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ header: [3, 4, 5, 6, false] }],
      ];
    },
  },
  methods: {
    addObjective() {
      this.objectives = [...this.objectives, { id: Date.now(), text: "" }];
    },
    scrollToForm(to = "start") {
      this.$refs.objectivesForm.scrollIntoView({
        behavior: "smooth",
        block: to,
      });
    },
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.scrollToForm();
        return;
      }

      this.statusBtn = "loading";

      const options = {
        url: this.submitUrl,
        method: "POST",
        data: {
          initiativeId: this.initiativeId,
          objectives: this.objectives,
          description: this.description,
        },
      };

      const response = await axios(options);

      if (response.data.status === "success") {
        this.succesResponse();
      } else {
        this.errorResponse(response);
      }
    },
    succesResponse() {
      this.statusBtn = "success";
      this.$v.$reset();
      this.errorsAfterSubmit = [];
      this.$root.$emit("objectivesUpdate", {
        objectives: this.objectives,
        description: this.description,
      });

      setTimeout(() => {
        this.$root.$refs.customModal.closeModal(null, null, true);
      }, 3000);
    },
    errorResponse(response) {
      this.statusBtn = "";
      this.errorsAfterSubmit = response.data.errors;
    },
    remove(id) {
      this.objectives = this.objectives.filter((item) => item.id !== id);
    },
    onChangeWyz(html) {
      this.description = html;
    },
  },
};
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: ".initiatives-objectives-form";

#{$b} {
  // .initiatives-objectives-form__title
  &__title {
    font-family: $font-family-main-head;
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 100%;
    margin: 0 0 32px 0;

    @include mobile {
      font-size: 28px;
      margin-bottom: 24px;
    }
  }

  // .initiatives-objectives-form__objectives
  &__objectives {
    margin-bottom: 64px;
    @include mobile {
      margin-bottom: 32px;
    }
  }

  // .initiatives-objectives-form__field
  &__field {
    position: relative;
    margin-bottom: 24px;

    @include mobile {
      margin-bottom: 16px;
    }

    // .initiatives-objectives-form__field-delete
    &-delete {
      position: absolute;
      right: 0;
      top: 0;
      color: $color-base;
      font-size: 12px;
      line-height: 1;
      cursor: pointer;

      &:hover {
        text-decoration-line: underline;
        text-underline-offset: 3px;
      }
    }
  }

  // .initiatives-objectives-form__actions
  &__actions {
    margin-top: 64px;

    @include mobile {
      text-align: center;
      margin-top: 32px;
    }
  }

  // .initiatives-objectives-form__feedback-error
  &__feedback-error {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 15px;

    @include mobile {
      font-size: 18px;
    }

    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
}
</style>
