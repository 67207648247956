<template>
  <div class="raport-initiative-card">
    <div class="raport-initiative-card__text" v-html="card.text" />
    <div class="raport-initiative-card__info" v-html="card.info" />
  </div>
</template>

<script>
export default {
  name: 'raport-initiative-card',
  props: {
    card: {
      type: Object,
      default: () => ({
        info: "",
        text: ""
      })
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.raport-initiative-card';

#{$b} {
  padding-right: 20px;

  &__text {
    margin: 0;
    padding-right: 30px;
  }

  &__info {
    font-size: 24px;
    line-height: 1.5;
    color: $color-primary;
    margin-top: 16px;
    word-break: break-word;
  }
}
</style>
