<template>
  <section class="talisman-contestants">
    <h2 class="talisman-contestants__title tt-uppercase title-xl">
      РАБОТЫ КОНКУРСАНТОВ: ТОП-5
    </h2>
    <TalismanContestantsList
      class="talisman-contestants-works__list"
      :contestantsList="contestantsList"
      :actionVerifyPhone="actionVerifyPhone"
      :actionVerifyCode="actionVerifyCode"
    />
  </section>
</template>

<script>
export default {
  props: {
    contestantsList: {
      type: Array,
      default: () => []
    },
    actionVerifyPhone: {
      type: String,
      default: '',
    },
    actionVerifyCode: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-contestants';

#{$b} {

  // .talisman-contestants__title
  &__title {
    margin: 0 0 32px 0;
  }

}
</style>
