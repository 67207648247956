<template>
  <a
    :href="associateData.href"
    target="_blank"
    class="u-doc"
  >
    <img
      class="u-doc__icon"
      :src="fileIcon"
      :alt="associateData.name"
    >
    <span
      v-html="associateData.name"
      class="u-doc__name"
    />
  </a>
</template>

<script>
const ICON_TYPES_SRC = {
  pdf: '/frontend/assets/img/filetypes/filetype-pdf.svg',
}

export default {
  name: "UDoc",
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    availableFileTypes() {
      return Object.keys(ICON_TYPES_SRC);
    },
    fileIcon() {
      const type = this.associateData.type;
      return ICON_TYPES_SRC[type];
    },
    associateData() {
      const doc = this.data;
      const type = this.availableFileTypes.includes(doc.type) ? doc.type : this.availableFileTypes[0];

      return {
        ...this.data,
        name: doc.name || '',
        type,
        href: doc.href || '#'
      };
    },
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-doc';

#{$b} {
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 400;
  color: $color-primary;
  transition: $transtion-default;

  &:hover {
    @include mobile-min {
      color: rgba($color-primary, 0.75);

      #{$b}__name {
        text-decoration-color: transparent;
      }
    }
  }

  @include mobile {
    font-size: 18px;
  }

  &__icon {
    width: 19px;
    height: auto;
    margin-right: 11px;
    margin-top: 5px;
  }

  &__name {
    text-decoration: underline;
  }
}
</style>
