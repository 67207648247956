<template>
  <div>
    <div class="g-h2 lk-form__title">
      Отчет о проведенных мероприятиях
    </div>
    <div class="lk-form__subtitle">Основные показатели</div>
    <div class="lk-form__row_3">
      <div class="lk-form__col_3">
        <div class="events-filter__dropdown">
          <div class="select">
            <v-select
              v-model="selectedMonth"
              :clearable="false"
              :searchable="false"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="mainMonth"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
          </div>
        </div>
      </div>
    </div>
    <div class="lk-form__row_3">
      <div class="lk-form__col_3" v-for="(item, i) in items" :key="i">
        <p class="lk-form__chart-label" v-html="item.label"></p>
        <bar-chart
          :dataSets="item.chart.dataSets"
          :labels="item.chart.labels"
        ></bar-chart>
        <div v-if="item.hint">
          <span class="lk-form__chart-hint" v-html="item.hint"></span>
          <div v-if="item.tooltip" class="lk-form__chart-tooltip" v-tooltip="item.tooltip">
            <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8.5" cy="8.5" r="8.5" fill="#5893FB"></circle><path d="M7.72 6.62H9.28V12H7.72V6.62ZM8.5 5.87C8.21333 5.87 7.98 5.78667 7.8 5.62C7.62 5.45333 7.53 5.24667 7.53 5C7.53 4.75333 7.62 4.54667 7.8 4.38C7.98 4.21333 8.21333 4.13 8.5 4.13C8.78667 4.13 9.02 4.21 9.2 4.37C9.38 4.53 9.47 4.73 9.47 4.97C9.47 5.23 9.38 5.44667 9.2 5.62C9.02 5.78667 8.78667 5.87 8.5 5.87Z" fill="white"></path></svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import axios from 'axios'
  import vSelect from 'vue-select'
  import BarChart from "./BarChart";
  import { VTooltip } from 'v-tooltip'

  export default {
    name: "LkRaportMain",
    components: {BarChart, vSelect},
    directives: {
      'tooltip': VTooltip
    },
    data() {
      return {
        selectedMonth: 0,
        mainMonth: [
          {
            id: 0,
            name: 'все месяцы'
          },
          {
            id: 1,
            name: 'январь'
          },
          {
            id: 2,
            name: 'февраль'
          }
        ],
        items: [
          {
            label: 'Совокупный охват аудитории <br> (млн. чел.)',
            chart: {
              dataSets: [20.6, 50],
              labels: ['Достигнуто', 'Цель']
            },
            hint: 'Замеряется мониторинговыми системами &laquo;Медиалогия&raquo; или аналогами в&nbsp;связке двух неотделимых инфоповодов: &laquo;Название мероприятия&nbsp;+&nbsp;Год науки&raquo;',
            tooltip: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A accusamus ad assumenda deleniti dignissimos, facilis labore maiores obcaecati omnis pariatur quam qui quidem quis quos rem repellat rerum sint tempora. A, fugit.'
          },
          {
            label: 'Непосредственное участие в&nbsp;онлайн <br> и&nbsp;офлайн активностях (млн. чел.)',
            chart: {
              dataSets: [1.7, 25],
              labels: ['Достигнуто', 'Цель']
            },
            hint: 'Общее число людей, принимающих участие в&nbsp;мероприятии'
          },
          {
            label: 'Охват профессионального <br> сообщества (тыс. чел.)',
            chart: {
              dataSets: [3.7, 300],
              labels: ['Достигнуто', 'Цель']
            },
            hint: 'Общее число людей, принимающих участие в&nbsp;мероприятии'
          },
        ],
        OpenIndicator: {
          render: createElement => createElement('span')
        },
      }
    }
  }
</script>

<style scoped>

</style>
