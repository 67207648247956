<template>
  <ModalWrapper
    v-if="MODAL?.name"
    class="location-window"
    :class="locationWindowClasses"
    closeBtnClass="location-window__close"
    contentClass="location-window__modal-content"
    @close="CLOSE_MODAL"
  >
    <div class="location-window__inner">
      <template v-if="isErrorStep && !isCanChangeLocation">
        <div class="location-window__caption text-ll">
          Не удалось определить ваш регион
        </div>
        <div class="location-window__actions">
          <CustomButton
            theme="primary"
            size="md"
            class="location-window__actions-item"
            @click="isCanChangeLocation = true"
          >
            Выбрать регион
          </CustomButton>
        </div>
      </template>
      <template v-if="isConfirmationStep && !isErrorStep && !isCanChangeLocation">
        <div class="location-window__caption text-ll">
          {{ getCaptionRegionName }}
        </div>
        <div class="location-window__actions">
          <CustomButton
            theme="primary"
            size="md"
            class="location-window__actions-item"
            @click="onSave"
          >
            Да, верно
          </CustomButton>
          <CustomButton
            theme="outline-primary"
            size="md"
            class="location-window__actions-item"
            @click="isCanChangeLocation = true"
          >
            Нет, другой
          </CustomButton>
        </div>
      </template>
      <template v-if="isCanChangeLocation">
        <div class="location-window__caption text-ll">
          Выбор региона
        </div>
        <div
          ref="selectBlock"
          class="location-window__select u-select u-select--secondary"
          :style="selectStyle"
        >
          <v-select
            v-model="locationId"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="GET_REGION_LIST"
            placeholder="Выбор региона"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
        <div class="location-window__actions">
          <CustomButton
            theme="primary"
            size="md"
            class="location-window__actions-item"
            @click="onSave"
          >
            Сохранить
          </CustomButton>
          <CustomButton
            theme="outline-primary"
            size="md"
            class="location-window__actions-item"
            @click="close"
          >
            Отменить
          </CustomButton>
        </div>
      </template>
    </div>
  </ModalWrapper>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import vSelect from "vue-select";

export default {
  components: { vSelect },
  data() {
    return {
      selectedLocationId: '',
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      canChangeLocation: null,
      maxHeightSelectMenu: 0
    }
  },
  computed: {
    ...mapGetters('modal', ['MODAL']),
    ...mapGetters('region', ['GET_REGION', 'GET_REGION_LIST']),
    currentRegion() {
      return this.GET_REGION_LIST && this.GET_REGION_LIST.length ? this.GET_REGION_LIST.find(item => item.id === this.locationId) : null
    },
    getCurrentRegionName() {
      return this.currentRegion ? this.currentRegion.name : ''
    },
    getCaptionRegionName() {
      return this.getCurrentRegionName ? `Ваш регион ${this.getCurrentRegionName}` : `Неудалось определить ваш регион`
    },
    locationId: {
      get() {
        return this.selectedLocationId || (this.GET_REGION?.id || '')
      },
      set(value) {
        this.selectedLocationId = value
      }
    },
    isErrorStep() {
      return !this.GET_REGION?.id
    },
    isConfirmationStep() {
      return !this.selectedLocationId && Boolean(this.GET_REGION?.id)
    },
    isCanChangeLocation: {
      get() {
        return this.canChangeLocation === null
          ? this.MODAL?.props?.changeLocation
          : this.canChangeLocation
      },
      set(value) {
        this.canChangeLocation = value
      }
    },
    selectStyle() {
      return this.maxHeightSelectMenu
        ? {
          '--u-select-max-height': `${this.maxHeightSelectMenu}px`
        }
        : ''
    },
    locationWindowClasses() {
      return { 'location-window--change-step': this.isCanChangeLocation }
    }
  },
  methods: {
    ...mapActions('region', ['SAVE_REGION']),
    ...mapActions('modal', ['CLOSE_MODAL']),
    async onSave() {
      this.SAVE_REGION(this.currentRegion)

      window.location.reload()
    },
    close() {
      this.CLOSE_MODAL()
    },
  },
}
</script>


<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.location-window';

#{$b} {
  font-family: $font-family-inter;

  #{$b}__close {
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
  }
  &--change-step {
    #{$b}__modal-content {
      @include mobile {
        margin-top: 0;
        margin-bottom: auto;
      }
    }
  }


  // .location-window__inner
  &__inner {
    max-width: 100%;
    width: 559px;
    padding: 40px;
    background-color: $white-true;
    border-radius: 16px;

    @include mobile {
      width: 100%;
      padding: 20px;
    }
  }

  // .location-window__caption
  &__caption {
    font-weight: 500;
    padding-right: 30px;
    margin-bottom: 20px;
    opacity: 0.5;

    @include mobile {
      font-size: 16px;
    }
  }

  // .location-window__select
  &__select {
    margin-bottom: 20px;

    &.u-select {
      .vs__dropdown-menu {
        max-height: 240px;
      }
    }
  }

  // .location-window__errors
  &__errors {

    &-item {
      font-size: 24px;
      line-height: 1.4;
      margin-bottom: 12px;

      @include mobile {
        font-size: 18px;
      }

      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

  // .location-window__actions
  &__actions {
    display: flex;
    align-items: center;

    @include mobile-sm {
      display: block;
    }

    // .location-window__actions-item
    &-item {
      &:not(:last-child) {
        margin-right: 10px;

        @include mobile-sm {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      @include mobile {
        flex: 1;
      }

      @include mobile-sm {
        width: 100%;
      }
    }
  }
}
</style>
