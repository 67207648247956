<template>
  <div class="event-panel-slider g-wrapper-offset-left">
    <swiper ref="newsSwiper" :options="swiperOptions">
      <slot />
    </swiper>
    <div class="event-panel-slider__controls-wrapper">
      <div class="event-panel-slider__controls">
        <div class="event-panel-slider__prev">
          <inline-svg src="arrow-left" />
        </div>
        <div class="event-panel-slider__count swiper-pagination"></div>
        <div class="event-panel-slider__next">
          <inline-svg src="arrow-right" />
        </div>
      </div>
      <div class="event-panel-slider__bullets g-pagination-bullets"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventPanelSlider",
  data() {
    return {
      swiperOptions: {
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        autoplay: {
          delay: 5000,
        },
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        watchOverflow: true,
        watchSlidesVisibility: true,
        slideVisibleClass: "swiper-slide-visible",
        navigation: {
          prevEl: ".event-panel-slider__prev",
          nextEl: ".event-panel-slider__next",
          disabledClass: "is-disabled"
        },
        pagination: {
          el: ".event-panel-slider__bullets",
          type: "bullets",
          bulletClass: "g-pagination-bullet",
          bulletActiveClass: "is-active",
          clickable: true
        },
        breakpoints: {
          1024: {
            pagination: {
              el: ".event-panel-slider__count",
              type: "fraction"
            }
          }
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.newsSwiper.$swiper;
    },
  },
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".event-panel-slider";

#{$b} {
  color: $color-black;

  &__inner {
    position: relative;
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;

    .event-panel {
      height: 100%;
    }
  }

  &__controls-wrapper {
    position: absolute;
    left: 0;
    bottom: 6px;
    z-index: 10;

    @include desktop {
      bottom: 4px;
    }

    @include tablet {
      position: static;
      margin: 30px 0;
      padding: 0 40px;
    }

    @include mobile {
      padding: 0 30px;
    }
  }

  &__controls {
    height: 0;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: inherit;

    @include desktop {
      width: 88px;
    }

    @include tablet {
      display: none;
    }

    .inline-svg {
      height: 100%;
    }

    .swiper-pagination {
      position: static;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  &__prev,
  &__next {
    outline: 0;
    text-decoration: none;
    user-select: none;
    cursor: pointer;
    flex-shrink: 0;
    width: 20.5px;
    height: 11px;
    transition: 0.3s;
    color: inherit;

    @include desktop {
      width: 15.5px;
      height: 11px;
    }

    &:hover {
      &:not(.is-disabled) {
        color: $color-base;
      }
    }

    &.is-disabled {
      cursor: default;
      opacity: 0.35;
    }

    &.swiper-button-lock {
      display: none;
    }
  }

  &__bullets {

    @include tablet-min {
      display: none;
    }
  }
}
</style>
