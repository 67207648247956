import axios from 'axios'

import { getQuery, setQuery, deleteQuery } from '@/components/helpers/queryHelper.js'

export default {
  namespaced: true,
  state: {
    region: null,
    urlRegionName: 'region',
    lsRegionName: '_currentRegion',
    regionList: [],
    detectDone: false
  },

  getters: {
    GET_REGION(state, getters) {
      return getters.GET_REGION_FROM_URL || state.region || getters.GET_REGION_FROM_LC
    },
    GET_REGION_FROM_URL(state, getters) {
      const regionSymbol = getQuery({ name: state.urlRegionName })

      const region = regionSymbol && getters.GET_REGION_LIST?.length > 0
        ? getters.GET_REGION_LIST.find(item => item.code === regionSymbol)
        : null

      return region || null
    },
    GET_REGION_FROM_LC(state) {
      const jsonRegion = localStorage.getItem(state.lsRegionName)
      return jsonRegion ? state.regionList.find(item => item.code === JSON.parse(jsonRegion)?.code) : null
    },
    GET_REGION_LIST(state) {
      return state.regionList
    },
    IS_DETECT_DONE(state) {
      return state.detectDone
    }
  },

  mutations: {
    SET_REGION(state, region) {
      state.region = region
    },
    SET_REGION_LIST(state, regionList) {
      state.regionList = regionList
    },
    SAVE_REGION_IN_URL(state, region) {
      setQuery({ params: { [state.urlRegionName]: region.code } })
    },
    SAVE_REGION_IN_LC(state, region) {
      localStorage.setItem(state.lsRegionName, JSON.stringify(region))
    },
    REMOVE_REGION(state) {
      state.region = ''
      deleteQuery({ name: state.urlRegionName })
      localStorage.removeItem(state.lsRegionName)
    },
    SET_DETECT_DONE(state, isDone) {
      state.detectDone = isDone
    }
  },

  actions: {
    async DETECT_REGION(ctx, action) {
      try {
        const options = {
          url: action,
          method: 'POST'
        }
        const response = await axios(options)

        if (response && response.data.status === 'success') {
          ctx.commit('SET_REGION', response.data.data);
        }

        return response.data.data

      } catch(e) {
        ctx.commit('REMOVE_REGION');
        console.warn('store:LOAD_REGION', e)
      } finally {
        ctx.commit('SET_DETECT_DONE', true)
      }
    },

    SAVE_REGION(ctx, region) {
      ctx.commit('SET_REGION', region);
      ctx.commit('SAVE_REGION_IN_URL', region);
      ctx.commit('SAVE_REGION_IN_LC', region);
    }
  },
}
