<template>
  <div class="initiatives-audience">
    <div class="initiatives-audience__inner">
      <div
        v-if="isEditable"
        class="initiatives-audience__add"
        @click="addAudience"
      >
        <div class="initiatives-audience__add-icon" />
        <div class="initiatives-audience__add-text tt-uppercase">
          Добавить
          <br>
          Аудиторию
        </div>
      </div>
      <div
        v-for="item in audienceList"
        :key="`initiatives-audience__item-${item.id}`"
        class="initiatives-audience__item"
        :class="{'initiatives-audience__item--is-editable': isEditable}"
      >
        <EditingCard
          v-if="isEditable"
          class="initiatives-audience__editing"
          :is-edit="false"
          size="sm"
          @remove="removeAudience(item.id)"
        />
        <img
          :src="item.image"
          :alt="item.text"
          class="initiatives-audience__image"
        >
        <p class="initiatives-audience__text">{{item.text}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  inject: {
    isEditable: {
      from: 'isEditable',
      default: false
    },
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    // Эндпоинты для удаления/добавления Аудитории
    actionAdd: {
      type: String,
      default: ''
    },
    actionDelete: {
      type: String,
      default: ''
    },
    initialAudienceOptions: {
      type: Array,
      default: () => []
    },
    initiativeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      audienceList: this.data,
    }
  },
  computed: {
    audienceIdList() {
      return this.audienceList?.map(item => item.id)
    },
    audienceOptions() {
      return this.initialAudienceOptions?.filter((item) => {
        if (!this.audienceIdList?.includes(item.id)) {
          return item
        }
      }) || []
    }
  },
  methods: {
    addAudience() {
      this.$root.$refs.customModal.openModal(null, null, true, true);

      this.$root.$refs.customModal.passContent({
        name: 'initiatives-audience-form',
        props: {
          actionAdd: this.actionAdd,
          initialAudienceOptions: this.audienceOptions,
          initiativeId: this.initiativeId,
        },
        title: 'Добавить Аудиторию'
      });
    },
    async removeAudience(id) {
      const options = {
        url: this.actionDelete,
        method: 'POST',
        data: { audience: id, initiativeId: this.initiativeId }
      }

      const response = await axios(options)

      if (response.data.status === 'success') {
        this.succesResponse(id)
      } else {
        this.errorResponse(response)
      }
    },
    succesResponse(id) {
      this.audienceList = this.audienceList.filter(item => item.id !== id)
    },
    errorResponse(response) {
       const error = response.data && response.data.errors ? response.data.errors
          .map(item => item.message)
          .join('. ')
          : 'Произошла непредвиденная ошибка'

      alert(error)
    },
    updateData(audience) {
      let list = [audience]

      if (this.audienceList && Array.isArray(this.audienceList)) {
        list = [...this.audienceList, ...list]
      }

      this.audienceList = list
    }
  },
  mounted() {
    this.$root.$on('audienceUpdate', this.updateData)
  },
  beforeDestroy() {
    this.$root.$off('audienceUpdate', this.updateData)
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.initiatives-audience';

#{$b} {
  font-family: $font-family-inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: $black-true;

  // .initiatives-audience__inner
  &__inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    @include desktop-ll {
      grid-template-columns: repeat(3, 1fr);
    }

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      display: block;
    }
  }

  // .initiatives-audience__add
  &__add {
    display: flex;
    align-items: center;
    border: 3px solid $color-base;
    padding: 24px;
    border-radius: 16px;
    cursor: pointer;

    @include mobile {
      background: $gradient-primary-light;
      border: 0;
      margin-bottom: 24px;
    }

    &:hover {
      @include mobile-min {
        #{$b}__add-icon {
          background-color: $color-base;

          &::after,
          &::before {
            background-color: $white-true;
          }
        }
      }
    }

    // .initiatives-audience__add-icon
    &-icon {
      width: 64px !important;
      height: 64px;
      margin-right: 16px;
      position: relative;
      transition: $transtion-default;
      border: 2px solid $color-base;
      border-radius: 50%;

      &::after,
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        transition: $transtion-default;
        background-color: $color-base;
      }

      &::before {
        width: 28px;
        height: 2px;
      }
      &::after {
        height: 28px;
        width: 2px;
      }
    }

    &-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.3;
      color: $color-base;
    }

  }

  // .initiatives-audience__item
  &__item {
    display: flex;
    align-items: center;
    padding: 24px;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    transition: $transtion-default;

    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }

    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $gradient-primary-light;
      pointer-events:none;
      touch-action: none;
      transition: $transtion-default;
    }

    &:not(#{$b}__item--is-editable):hover {
      @include mobile-min {
        background-color: $color-base;
        color: $white-true;

        .initiatives-audience__image {
          filter: grayscale(1) brightness(1000%);
        }

        &::before {
          opacity: 0;
        }
      }
    }
  }

  // .initiatives-audience__image
  &__image {
    width: 64px;
    height: 64px;
    object-position: center;
    object-fit: contain;
    margin-right: 16px;
    transition: $transtion-default;
  }

  // .initiatives-audience__text
  &__text {
    margin: 0;
  }

}
</style>
