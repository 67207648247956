<template>
  <div class="childrens-playground-banner-detail">
    <div class="u-g-container">
      <div class="childrens-playground-banner-detail__inner">
        <BackLink class="childrens-playground-banner-detail__back" :href="hrefWithRegion(backLink)" />
        <img src="/frontend/assets/img/childrens-playground/ChildrensPlaygroundBannerDetail/bg-el.png" class="childrens-playground-banner-detail__bg-el">
        <div class="childrens-playground-banner-detail__content">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backLink: {
      type: String,
      default: '/'
    },
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.childrens-playground-banner-detail';
$padding-bottom: 40px;
$padding-bottom-mob: 50px;

#{$b} {
  position: relative;
  color: $white-true;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('/frontend/assets/img/childrens-playground/ChildrensPlaygroundBannerDetail/bg.png');
  padding-top: calc(var(--header-height) + 60px);
  padding-bottom: $padding-bottom;
  margin-bottom: 64px;


  @include mobile {
  }

  @include mobile {
    background-image: url('/frontend/assets/img/childrens-playground/ChildrensPlaygroundBannerDetail/bg-mob.png');
    padding-bottom: $padding-bottom-mob;
    padding-top: calc(var(--header-height) + 32px);
  }

  // .childrens-playground-banner-detail__back
  &__back {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .childrens-playground-banner-detail__title
  &__title {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 24px;

      @include mobile {
        margin-bottom: 16px;
      }
    }
  }

  // .childrens-playground-banner-detail__tag
  &__tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid $white-true;
    border-radius: 20px;
    padding: 5px 23px;
    min-height: 40px;
  }

  // .childrens-playground-banner-detail__bg-el
  &__bg-el {
    position: absolute;
    right: 40px;
    bottom: calc(-70px + $padding-bottom);
    width: 139px;
    height: auto;

    @include mobile {
      right: 16px;
      bottom: -50px;
    }
  }
}
</style>
