<template>
  <div class="i-excursion-filter">
    <div class="i-excursion-filter__inner">
      <IExcursionTabFilter class="i-excursion-filter__tabs"
        :currentFilter="filter.filter"
        :items="tabFilterItems"
        @change="onChangeTabFilter"
      />
      <div class="i-excursion-filter__caption text-lg text-lg--mob-default fw-500">
        <slot />
      </div>
      <div class="i-excursion-filter__dropdowns">
        <div class="u-select i-excursion-filter__dropdowns-item">
          <v-select
            :value="filter.region"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.code"
            :options="options.region"
            @input="(v) => onSelect('region', v)"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
        <div class="u-select i-excursion-filter__dropdowns-item">
          <v-select
            :value="filter.type"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="options.type"
            @input="(v) => onSelect('type', v)"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
        <div v-if="options?.ageGroup?.length > 0" class="u-select i-excursion-filter__dropdowns-item">
          <v-select
            :value="filter.ageGroup"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="options.ageGroup"
            key="ageGroup"
            @input="(v) => onSelect('ageGroup', v)"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
        <div v-if="options?.year?.length > 0" class="u-select i-excursion-filter__dropdowns-item">
          <v-select
            :value="filter.year"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="options.year"
            key="year"
            @input="(v) => onSelect('year', v)"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
      </div>
      <div v-if="!monthsDisabled" class="i-excursion-filter__month">
        <MonthsFilter
          :values="filter.months"
          :options="options.month"
          @change="onChangeMonths"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: { vSelect },

  props: {
    tabFilterItems: {
      type: Array,
      default: () => []
    },

    options: {
      type: Object,
      default: () => ({})
    },

    filter: {
      type: Object,
      default: () => ({})
    },

    monthsDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      OpenIndicator: {
        render: createElement => createElement('span')
      },
    }
  },

  methods: {
    onChangeTabFilter(filter) {
      this.emitFilter('filter', filter)
    },

    emitFilter(name, value) {
      this.$emit('change', { name, value })
    },

    onSelect(name, value) {
      this.emitFilter(name, value)
    },

    onChangeMonths(value) {
      this.emitFilter('months', value)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.i-excursion-filter';
$sm-dropdown-width: 296px;
$drodpwn-list-gap: 16px;

#{$b} {
  user-select: none;

  // .i-excursion-filter__tabs
  &__tabs {
    margin-bottom: 67px;

    @include mobile {
      margin-bottom: 48px;
    }
  }

  // .i-excursion-filter__caption
  &__caption {
    padding-bottom: 32px;
    border-bottom: 1px solid $black-true;
    margin-bottom: 32px;

    @include mobile {
      border: none;
      padding: 0;
      margin-bottom: 49px;
    }

    & > * {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }

  // .i-excursion-filter__dropdowns
  &__dropdowns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px $drodpwn-list-gap;

    @include mobile {
      gap: 24px;
    }

    &:not(:last-child) {
      margin-bottom: 64px;

      @include mobile {
        margin-bottom: 48px;
      }
    }

    // .i-excursion-filter__dropdowns-item
    &-item {
      width: calc((100% - $sm-dropdown-width - ($drodpwn-list-gap * 2)) / 2);
      max-width: calc((100% - $sm-dropdown-width - ($drodpwn-list-gap * 2)) / 2);

      @include tablet-min {
        &:nth-child(3) {
          max-width: $sm-dropdown-width;
          width: $sm-dropdown-width;
        }
      }

      @include tablet {
        width: calc(50% - ($drodpwn-list-gap / 2));
        max-width: calc(50% - ($drodpwn-list-gap / 2));
      }

      @include mobile {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>
