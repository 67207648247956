<template>
  <section class="i-excursion-block">
    <UFigures
      :items="[
        { 'name':'f-filtered-cards-page-1', 'image':'/frontend/assets/img/excursions/tall-circle-group-line.svg', 'animation':'fade-in-left' },
      ]"
      fullWidth
      position="static"
    >
      <div class="u-g-container">
        <div class="i-excursion-block__inner">
          <div class="i-excursion-block__filter">
            <IExcursionFilter
              :tabFilterItems="tabFilterItems"
              :filter="FILTER"
              :options="OPTIONS"
              :monthsDisabled="MONTHS_DISABLED"
              @change="onChangeFilter"
            >
              <slot name="caption"/>
            </IExcursionFilter>
          </div>
          <div class="i-excursion-block__body">
            <IExcursionList v-if="ITEMS?.length > 0" :items="ITEMS" />
            <NotFound v-else text="По данным параметрам ничего не найдено" />
          </div>
          <div class="i-excursion-block__actions" v-show="HAS_NEXT_PAGE">
              <CustomButton
                theme="primary"
                size="md"
                @click="LOAD_ITEMS(false)"
              >
                Показать ещё
              </CustomButton>
            </div>
        </div>
      </div>
    </UFigures>
  </section>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { regionFromOptions } from '@/components/helpers/regionHelper'

export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    initialNextUrl: {
      type: String,
      default: ''
    },
    initialPaginator: {
      type: Object,
      default: () => ({})
    },
    initialFilterItems: {
      type: Object,
      default: () => ({})
    },
    initialItems: {
      type: Array,
      default: () => []
    },
    pagenName: {
      type: String,
      default: 'PAGEN_1'
    }
  },

  created() {
    this.init()
  },

  computed: {
    ...mapGetters('excursions', ['ITEMS', 'IS_LOADING', 'HAS_NEXT_PAGE', 'OPTIONS', 'FILTER', 'MONTHS_DISABLED']),
    ...mapGetters('region', ['GET_REGION', 'IS_DETECT_DONE']),

    initialRegion() {
      return this.IS_DETECT_DONE ? regionFromOptions(this.GET_REGION, this.OPTIONS.region)?.code || '' : ''
    },

    tabFilterItems() {
      return this.initialFilterItems?.initialFilters || []
    },
  },

  methods: {
    ...mapMutations('excursions', ['SET_STATE', 'SET_OPTIONS', 'SET_FILTER_ITEM']),
    ...mapActions('excursions', ['LOAD_ITEMS']),

    onChangeFilter({ name, value }) {
      this.setFilter(name, value)

      this.LOAD_ITEMS(true);
    },

    setFilter(name, value) {
      this.SET_FILTER_ITEM({ name, value })
    },

    initTabFilter() {
      const initialTabFilter = this.initialFilterItems?.initialFilters?.find(item => item.isActive)

      if (initialTabFilter) {
        this.setFilter('filter', initialTabFilter.filter)
      }
    },

    init() {
      this.SET_STATE({ name: 'items', value: this.initialItems })
      this.SET_STATE({ name:'paginator', value: this.initialPaginator })
      this.SET_STATE({ name: 'actionGet', value: this.url })
      this.SET_STATE({ name: 'pagenName', value: this.pagenName })

      this.SET_OPTIONS({
        region: this.initialFilterItems?.region || [],
        type: this.initialFilterItems?.type || [],
        ageGroup: this.initialFilterItems?.ageGroup || [],
        year: this.initialFilterItems?.year || [],
      })

      this.initTabFilter()

      if (this.IS_DETECT_DONE) {
        this.setFilter('region', this.initialRegion)
        this.LOAD_ITEMS(true);
      }
    }
  },

  watch: {
    IS_DETECT_DONE() {
      this.setFilter('region', this.initialRegion)
      this.LOAD_ITEMS(true);
    },

    IS_LOADING(state) {
      this.$root.$emit('setLoading', state)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.i-excursion-block';

#{$b} {
  position: relative;
  --tab-height: 45px;
  margin-top: calc(var(--tab-height) * -1);

  // .i-excursion-block__inner
  &__inner {
    position: relative;
    z-index: 5;
  }

  // .i-excursion-block__filter
  &__filter {
    margin-bottom: 64px;

    @include mobile {
      margin-bottom: 48px;
    }
  }

  // .i-excursion-block__actions
  &__actions {
    margin-top: 32px;

    @include mobile {
      text-align: center;
    }
  }
}
</style>
