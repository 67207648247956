<template>
  <div class="lk-count">
    <div class="lk-count__row">
      <div ref="bar" class="lk-count__col" :class="{'lk-count__col--leveled': leveled}">
        <div class="lk-count__values" v-for="(item, i) in eventsPercents" :key="i" :style="{width: `${item.percent}%`}">
          <div class="lk-count__value">
            <span>{{item.value | formatNumber}} </span>
            <span class="lk-count__additional" v-if="additional && item.valueAdditional">({{item.valueAdditional > 0 ? '+' : ''}}{{item.valueAdditional | formatNumber}})</span>
          </div>
          <div class="lk-count__bar" :style="{backgroundColor: additional ? getAdditionalColor(item.color) : item.color }"></div>
          <div class="lk-count__title">{{item.title}}</div>
        </div>
      </div>
      <div class="lk-count__col">
        <div class="lk-count__total">
          Всего / {{totalValue | formatNumber}} <span
          class="lk-count__additional"
          v-if="additional && (totalValueAdditional > 0)"
        >({{(totalValueAdditional > 0 ? '+' : '')}}{{totalValueAdditional | formatNumber}})</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "LkCountGraph",
    props: {
      events: { type: Array, required: true },
      additional: { type: Boolean, default: false },
    },
    data() {
      return {
        leveled: false,
        eventsPercents: [],
      };
    },
    computed: {
      totalValue() {
        return this.events.reduce(function (a, b) {
          return a + b.value
        }, 0)
      },
      totalValueAdditional() {
        return this.events.reduce(function (a, b) {
          if (b.valueAdditional) {
            return a + b.valueAdditional
          } else return a
        }, 0)
      },
    },
    watch: {
      events() {
        this.transformEvents();
        this.adjustGraph();
      },
    },
    mounted() {
      this.transformEvents();
      this.adjustGraph();

      window.addEventListener('resize', this.adjustGraph);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.adjustGraph);
    },
    methods: {
      getPercentage(value) {
        return (value / this.totalValue * 100);
      },
      adjustGraph() {
        this.leveled = false;
        const fullWidth = this.$refs.bar.clientWidth;
        this.eventsPercents.forEach((event) => {
          const idealWidth = 16 + (event.title.length * 8.4);
          const factualWidth = event.percent < 11 ? fullWidth * 0.11 : fullWidth / 100 * event.percent;
          if (factualWidth < idealWidth) {
            this.leveled = true;
          }
        });
      },
      transformEvents() {
        this.eventsPercents = this.events.map((event) => {
          event.percent = this.getPercentage(event.value);
          return event;
        });
      },
      getAdditionalColor(hexColor) {
        if (hexColor.length < 8) {
          return 'rgb(88, 147, 251)';
        } else {
          const hexAlpha = hexColor.substring(7);
          const rgbAlpha = parseInt(hexAlpha, 16);
          return `rgba(88, 147, 251, ${rgbAlpha / 255})`;
        }
      },
    },
  };
</script>
