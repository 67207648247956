<template>
  <div class="u-projects">
    <UProjectCard
      v-for="(card,i) in items"
      :key="`u-projects__card-${i}`"
      class="u-projects__card"
      :data="card"
    />
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-projects';

#{$b} {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @include mobile {
    display: block;
  }

  // .u-projects__card
  &__card {
    @include mobile {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
