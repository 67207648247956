<template>
  <section
    ref="section"
    class="u-figures"
    :class="{ 'u-figures--overflow': isOverflow }"
    :style="`position: ${this.position}`"
  >
    <div :class="{ 'u-g-container': !fullWidth }">
      <div class="u-figures__container-wrapper">
        <div
          v-for="(item, i) in items"
          :key="i"
          ref="uFigure"
          class="u-figures-item"
          :class="item.name"
        >
          <div class="u-figures-item__img-wrapper" :class="{ 'u-figures__reverse-x': item.reverseX, 'u-figures__reverse-y': item.reverseY }">
            <img
              :class="{ animated: animateParallax && isAnimateImg, 'no-transform': !isAnimateImg }"
              v-rellax="rellax"
              :src="item.image"
              :alt="item.name"
            />
          </div>
        </div>
      </div>
    </div>
    <slot />
  </section>
</template>

<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// IE fix ScrollTrigger
window.requestAnimationFrame = window.requestAnimationFrame.bind(window);

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "UFigures",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isFirst: {
      type: Boolean,
      default: false
    },
    isOverflow: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    alwaysShow: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'relative'
    },
    isAnimateImg: {
      type: Boolean,
      default: true
    },
    startPosition: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      animateParallax: false,
      rellax: false,
      animateIn: undefined,
      fadeOutLeft: {
        x: -80,
        opacity: 0,
        ease: "power2",
        duration: 1,
        onComplete: this.startParallax
      },
      fadeOutRight: {
        x: 80,
        opacity: 0,
        ease: "power2",
        duration: 1,
        onComplete: this.startParallax
      },
      fadeOutTop: {
        y: -80,
        opacity: 0,
        ease: "power2",
        duration: 1,
        onComplete: this.startParallax
      }
    };
  },
  mounted() {
    this.animateIn = gsap.timeline({
      scrollTrigger: {
        trigger: this.$refs.section,
        start: this.getStartPosition,
        // markers: "true",
        toggleActions: this.alwaysShow ? "play" : "play none none reverse"
      }
    });

    this.items.forEach((item, i) => {
      const element = this.$refs.uFigure[i];

      switch (item.animation) {
        case "fade-in-left":
          this.animateIn.from(element, this.fadeOutLeft);
          break;
        case "fade-in-right":
          this.animateIn.from(element, this.fadeOutRight);
          break;
        case "fade-in-top":
          this.animateIn.from(element, this.fadeOutTop);
          break;
        default:
          this.startParallax();
          break;
      }
    });
  },
  computed: {
    getStartPosition() {
      return this.startPosition ? this.startPosition : this.isFirst ? "top top" : "top center";
    }
  },
  methods: {
    startParallax() {
      if (this.rellax > 0) {
        return
      }

      this.animateParallax = true;
      this.rellax = { speed: -0.4 };
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: ".u-figures";

#{$b} {

  &--overflow {
    overflow: hidden;
  }

  &__container-wrapper {
    // position: relative;

    @include low-desktop {
      position: static;
    }
  }

  & > .u-g-container {
    position: relative;

    @include low-desktop {
      position: static;
    }
  }

  &_absolute {
    > .u-g-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      min-height: 1760px;
    }
  }

  &__reverse-x {
    transform: scaleX(-1);
  }

  &__reverse-y {
    transform: scaleY(-1);
  }

  &-item {
    position: absolute;
    z-index: 0;
    pointer-events: none;
    touch-action: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;

      &.animated {
        transition: transform 0.5s ease;
      }
      &.no-transform {
        transform: none !important;
      }
    }

    svg {
      path {
        fill: currentColor;
      }
    }

    // .u-figures-item__img-wrapper
    &__img-wrapper {
      height: inherit;
      width: inherit;
    }

    // main page
    &.f-main-page-1 {
      width: 700px;
      height: 700px;
      left: 0;
      top: -246px;
      transform: none !important;
      opacity: 1 !important;

      img {
        transform: none !important;
      }

      @include mobile {
        top: -349px;
        left: -60px;
        max-width: 100vw;
        width: 400px;
        height: unset;
        aspect-ratio: 400/400;
      }
    }
    &.f-main-page-1-2 {
      width: 166px;
      height: 166px;
      top: -40px;
      right: calc((100vw - ($container-size - ($container-pd-desk * 2))) / 2);

      @include desktop {
        right: $container-pd-desk;
      }

      @include low-desktop {
        right: $container-pd-right-tablet;
      }

      @include tablet {
        display: none
      }
    }
    &.f-main-page-2 {
      width: 90vw;
      aspect-ratio: 1260/420;
      top: 199px;
      left: 0;

      .u-figures-item__img-wrapper {
        @include mobile {
          transform: rotateZ(180deg) translateY(-80%);
        }
      }

      @include tablet {
        top: 50px;
      }

      @include mobile {
        width: 180vw;
        top: -110px;
        left: -80vw;
      }
    }
    &.f-main-page-2-2 {
      display: none;

      .u-figures-item__img-wrapper {
        transform: rotate(180deg);
      }

      @include mobile {
        display: block;
        width: 400px;
        height: 400px;
        bottom: -180px;
        right: -182px;
      }
    }
    &.f-main-page-3 {
      width: 50vw;
      left: 0;
      top: -144px;
      aspect-ratio: 1/1;
      @include mobile {
        display: none;
      }
    }
    &.f-main-page-4 {
      width: 166px;
      height: 166px;
      left: calc(50vw + 256px);
      top: calc(25vw - 166px - 144px);
      aspect-ratio: 1/1;

      @include mobile {
        width: 400px;
        height: 400px;
        left: -225px;
        top: -440px
      }
    }
    &.f-main-page-5 {
      width: 900px;
      max-width: 80vw;
      right: 3vw;
      top: -70px;
      aspect-ratio: 957/576;

      @include mobile {
        max-width: 70vw;
        top: -170px;
        right: 10vw;
      }
    }
    &.f-main-page-jurnal {
      display: none;

      @include mobile {
        display: block;
        top: -150px;
        width: 175vw;
        height: 400px;
        left: 0;
        aspect-ratio: 1260/420;
      }
    }
    &.f-main-page-science {
      display: none;

      @include mobile {
        display: block;
        top: -375px;
        width: 175vw;
        height: 400px;
        left: -70px;
        aspect-ratio: 1260/420;
      }
    }

    &.f-banner-top {
      top: 0;
      left: 0;
      width: 1400px;
      max-width: 100vw;
      z-index: 1;

      @include desktop {
        width: 100vw;
        max-width: 1400px;
      }

      @include mobile {
        max-width: none;
        width: 210vw;
        height: 267px;
        left: -26vw;
      }
    }
    &.f-banner-part-bottom {
      bottom: 0;
      left: 0;
      width: 700px;
      height: 182px;
      transform: none !important;
      opacity: 1 !important;
      z-index: 1;

      img {
        transform: none !important;
        object-position: top;
        object-fit: cover;
      }

      @include mobile {
        max-width: 100vw;
        width: 400px;
        height: 301px;
        left: -60px;
      }
    }
    &.f-banner-atom {
      bottom: 0;
      right: 0;
      width: 587px;
      height: 346px;

      img {
        object-position: left top;
        object-fit: cover;
      }

      @include mobile {
        width: 195px;
        height: 155px;
        right: 0;
      }
    }

    &.f-about-page-1 {
      width: 166px;
      height: 166px;
      top: -6px;
      left: calc(700px + 13%);

      .u-figures-item__img-wrapper {
        transform: rotate(90deg);
      }
      @include mobile {
        display: none;
      }
    }
    &.f-about-page-2 {
      right: -90px;
      top: -195px;
      width: 1212px;
      height: 706px;

      @include mobile {
        top: -185px;
        right: unset;
        left: -440px;
      }
    }

    // news page
    &.f-news-page-1 {
      top: 190px;
      right: -70px;
      width: 700px;
      height: 700px;

      .u-figures-item__img-wrapper {
        transform: rotate(180deg);
      }

      img {
        @include mobile {
          object-fit: 100%;
        }
      }

      @include mobile {
        width: 400px;
        height: 323px;
        right: -170px;
      }
    }
    &.f-news-page-lines {
      max-width: 90vw;
      width: 996px;
      bottom: 0;
      right: 0;
      aspect-ratio: 1157/693;

      @include mobile {
        display: none;
      }
    }

    &.f-news-page-2 {
      @include mobile-min {
        display: none;
      }

      left: 0;
      bottom: 28.3%;
      width: calc(100vw - 20px);
      aspect-ratio: 400/400;
    }
    &.f-news-page-3 {
      @include mobile-min {
        display: none;
      }

      max-width: 100vw;
      width: 400px;
      aspect-ratio: 400/400;
      left: -22px;
      bottom: 18.5%;
    }

    &.f-news-detail-page-1 {
      top: 81px;
      left: 0;
      width: 700px;
      height: 700px;

      @include mobile {
        top: 174px;
        width: 400px;
        height: 400px;
        left: -60px;
      }
    }
    &.f-news-detail-page-2 {
      top: 1065px;
      right: -320px;
      width: 700px;
      height: 700px;

      @include mobile {
        top: 1100px;
        width: 400px;
        height: 400px;
        right: -190px;
      }
    }
    &.f-news-detail-page-3 {
      top: 1420px;
      left: -429px;
      width: 700px;
      height: 700px;

      @include mobile {
        top: 2250px;
        width: 400px;
        height: 400px;
        left: -188px;
      }

      .u-figures-item__img-wrapper {
        transform: rotate(180deg);
      }
    }
    &.f-news-detail-page-lines {
      top: -400px;
      right: 0;
      max-width: 90vw;
      width: 996px;
      aspect-ratio: 1157/693;
      opacity: 0.15;
      mix-blend-mode: multiply;

      @include tablet {
        top: -250px;
      }

      @include mobile {
        top: -550px;
        width: 100%;
        right: -87px;
      }
    }

    // initiatives page
    &.f-initiatives-page-1 {
      top: 123px;
      left: 0;
      width: 700px;
      height: 700px;

      .u-figures-item__img-wrapper {
        @include mobile-min {
          transform: rotate(180deg)
        }
      }

      @include mobile {
        top: 181px;
        left: unset;
        right: -69px;
        width: 400px;
        height: 400px;
      }
    }
    &.f-initiatives-page-circle-1 {
      @include mobile-min {
        display: none;
      }

      top: 1600px;
      left: -10px;
      width: 400px;
      max-width: 100vw;
      aspect-ratio: 400/267;
    }
    &.f-initiatives-page-circle-2 {
      @include mobile-min {
        display: none;
      }

      top: 2600px;
      left: 0;
      width: 400px;
      max-width: 100vw;
      aspect-ratio: 400/400;
    }
    // &.f-initiatives-page-lines {
    //   top: 34%;
    //   left: 0;
    //   width: 100%;
    //   aspect-ratio: 1470/577;

    //   @include mobile {
    //     display: none;
    //   }
    // }

    &.f-initiatives-detail-page-1 {
      top: 135px;
      left: 0;
      width: 700px;
      height: 700px;

      .u-figures-item__img-wrapper {
        @include mobile-min {
          transform: rotate(180deg);
        }
      }

      @include mobile {
        top: 120px;
        left: -72px;
        width: 400px;
        height: 400px;
      }
    }

    &.f-initiatives-detail-page-routes {
      top: 160px;
      left: -80px;
      width: 700px;
      height: 700px;

      .u-figures-item__img-wrapper {
        @include mobile-min {
          transform: rotate(180deg);
        }
      }

      @include mobile {
        top: 160px;
        left: -72px;
        width: 400px;
        height: 400px;
      }
    }

    &.f-initiatives-detail-page-2 {
      top: -199px;
      right: -154px;
      width: 700px;
      height: 700px;

      .u-figures-item__img-wrapper {
        @include mobile-min {
          transform: rotate(180deg);
        }
      }

      @include mobile {
        top: -180px;
        right: -72px;
        width: 400px;
        height: 400px;
      }
    }

     // science page
    &.f-science-detail-page-1 {
      top: -17px;
      left: 0;
      width: 700px;
      height: 700px;

      @include mobile {
        width: 400px;
        height: 400px;
        left: -60px;
        top: -92px;
      }
    }

    &.f-science-detail-page-lines {
      bottom: 14%;
      right: 0;
      max-width: 100%;
      opacity: 0.15;

      @include mobile {
        display: none;
      }
    }

    // media page
    &.f-media-page-top-mob {
      @include mobile-min {
        display: none;
      }
      top: -50px;
      left: -60px;
      width: 400px;
      height: 400px;
    }
    &.f-media-page-bottom-1 {
      bottom: 0;
      left: 0;
      width: 1260px;
      max-width: 100%;
      aspect-ratio: 1260/420;

      .u-figures-item__img-wrapper {
        @include mobile-min {
          transform: translateY(70%);
        }
      }

      @include mobile {
        bottom: 0;
        left: -80px;
        width: 860px;
      }
    }
    &.f-media-page-bottom-2 {
      @include mobile-min {
        display: none;
      }

      .u-figures-item__img-wrapper {
        transform: rotate(180deg);
      }

      bottom: 370px;
      width: 760px;
      aspect-ratio: 1260/420;
      right: -33px;
    }
    &.f-media-page-lines {
      bottom: 0;
      right: -20px;
      width: 1180px;
      max-width: 100%;

      @include tablet {
        right: 110px;
      }

      @include mobile {
        top: 240px;
        bottom: unset;
        right: unset;
        width: 200vw;
        left: -50vw;
      }
    }

    // search page
    &.f-search-page-1 {
      top: 110px;
      left: 0;
      width: 700px;
      height: 700px;

      @include mobile {
        top: 90px;
        width: 400px;
        height: 400px;
        left: -162px;
      }
    }

    // special projects page
    &.f-special-project-1 {
      width: 700px;
      height: 700px;
      left: 0;
      top: -12px;
      mix-blend-mode: multiply;

      @include mobile {
        width: 400px;
        height: 400px;
        left: -60px;
        top: -92px;
      }
    }

    &.f-special-projects-detail-1 {
      top: 0;
      left: 0;
      width: 700px;
      height: 700px;

      @include mobile {
        display: none;
      }
    }
    &.f-brandstyle-page-1 {
      top: 14px;
      left: -143px;
      width: 682px;
      height: 682px;

      @include mobile {
        width: 492px;
        height: 492px;
        left: -213px;
      }
    }
    &.f-brandstyle-page-2 {
      top: 884px;
      right: -439px;
      width: 817px;
      height: 817px;

      .u-figures-item__img-wrapper {
        transform: rotate(180deg)
      }

      @include mobile {
        top: 1148px;
        width: 595px;
        height: 595px;
        right: -500px;
      }
    }
    &.f-brandstyle-page-lines {
      bottom: -200px;
      right: 0;
      mix-blend-mode: multiply;

      @include tablet {
        width: 100vw;
        bottom: 0;
      }

      @include mobile {
        width: 200vw;
      }
    }

    // lk
    &.f-lk-1 {
      right: 116px;
      bottom: -200px;
      height: 530px;

      .u-figures-item__img-wrapper {
        opacity: 0.15;

        @include mobile {
          width: 100%;
        }
      }

      img {
        mix-blend-mode: multiply;
      }

      @include low-desktop {
        right: -250px;
      }

      @include mobile {
        height: auto;
        width: 160vw;
        max-width: 900px;
        right: -59vw;
        bottom: -230px;
      }
    }

    &.f-lk-digest-1 {
      width: 700px;
      height: 663px;
      top: 46px;
      left: 0;

      // disabled parallax on tablet-desktop
      @include mobile-min {
        transform: none !important;
        opacity: 1 !important;
      }

      @include mobile {
        width: 400px;
        height: 400px;
        left: -60px;
        top: unset;
        bottom: -52px;
      }

      img {
        @include mobile-min {
          transform: none !important;
        }

        object-fit: cover;
        object-position: bottom;
      }
    }

    &.f-lk-digest-circle {
      width: 700px;
      height: 663px;
      top: 46px;
      left: 0;

      // disabled parallax on tablet-desktop
      @include mobile-min {
        transform: none !important;
        opacity: 1 !important;
      }

      @include mobile {
        width: 400px;
        height: 400px;
        left: -60px;
        top: 250px;
        bottom: unset;
      }

      img {
        @include mobile-min {
          transform: none !important;
        }

        object-fit: cover;
        object-position: bottom;
      }
    }

    &.f-lk-subscribe-1 {
      width: 700px;
      height: 700px;
      left: 0;
      top: 80px;

      @include mobile {
        width: 400px;
        height: 400px;
        left: -60px;
      }
    }

    &.f-lk-organizations-1 {
      width: 700px;
      height: 663px;
      top: 46px;
      left: 0;

      // disabled parallax on tablet-desktop
      @include mobile-min {
        transform: none !important;
        opacity: 1 !important;
      }

      @include mobile {
        width: 400px;
        height: 400px;
        left: -60px;
        top: 80px;
      }

      img {
        @include mobile-min {
          transform: none !important;
        }

        object-fit: cover;
        object-position: bottom;
      }
    }

    &.f-lk-report-1 {
      @include mobile-min {
        display: none;
      }

      @include mobile {
        width: 400px;
        height: 400px;
        left: -60px;
        top: 80px;
      }
    }

    &.f-lk-your-initiatives-1 {
      right: 116px;
      bottom: -200px;
      height: 530px;

      .u-figures-item__img-wrapper {
        opacity: 0.15;

        @include mobile {
          width: 100%;
        }
      }

      img {
        mix-blend-mode: multiply;
      }

      @include low-desktop {
        right: -250px;
      }

      @include mobile {
        height: auto;
        width: 160vw;
        max-width: 900px;
        right: -59vw;
        bottom: unset;
        top: 200px;
      }
    }

    &.f-lk-application-history,
    &.f-lk-region-plan {
      width: 700px;
      height: 663px;
      top: 46px;
      left: 0;

      // disabled parallax on tablet-desktop
      @include mobile-min {
        transform: none !important;
        opacity: 1 !important;
      }

      @include mobile {
        width: 400px;
        height: 400px;
        left: -60px;
        top: 80px;
      }

      img {
        @include mobile-min {
          transform: none !important;
        }

        object-fit: cover;
        object-position: bottom;
      }
    }

    // events page
    &.f-events-page-mob-1 {
      width: 177vw;
      top: 980px;
      right: 0;

      .u-figures-item__img-wrapper {
        transform: rotate(180deg);
      }

      @include mobile-min {
        display: none;
      }
    }
    &.f-events-page-mob-2 {
      width: 400px;
      top: 1280px;
      right: -200px;

      @include mobile-min {
        display: none;
      }
    }
    &.f-events-page-mob-3 {
      width: 400px;
      top: 1950px;
      left: 0;

      @include mobile-min {
        display: none;
      }
    }
    &.f-events-page-mob-4 {
      width: 300px;
      top: 2950px;
      right: 12px;

      @include mobile-min {
        display: none;
      }
    }
    &.f-events-page-mob-5 {
      width: 400px;
      top: 3300px;
      left: 0px;

      @include mobile-min {
        display: none;
      }
    }
    &.f-events-detail-page-1 {
      width: 700px;
      height: 700px;
      left: 0;
      top: -177px;

      @include mobile {
        display: none;
      }
    }

    &.f-videos-detail-page {
      width: 700px;
      height: 700px;
      left: 0;
      top: 150px;

      @include tablet {
        display: none;
      }
    }
    &.f-videos-detail-page-2 {
      max-width: 90vw;
      width: 1326px;
      bottom: -200px;
      right: -100px;
      aspect-ratio: 1157/693;

      .u-figures-item__img-wrapper {
        opacity: 0.15;
      }

      @include mobile {
        display: none;
      }
    }

    &.f-video-page-circle {
      width: 700px;
      height: 700px;
      right: -70px;
      top: 96px;

      .u-figures-item__img-wrapper {
        transform: rotate(180deg);
      }

      @include mobile {
        width: 400px;
        height: 400px;
        right: -170px;
        top: 130px;
      }
    }
    &.f-video-page-lines {
      max-width: 90vw;
      width: 1326px;
      bottom: -200px;
      right: -100px;
      aspect-ratio: 1157/693;

      .u-figures-item__img-wrapper {
        opacity: 0.15;
      }

      @include mobile {
        display: none;
      }
    }

    &.f-talisman-about {
      width: 700px;
      height: 700px;
      top: 0;
      left: 0;

      @include mobile {
        display: none;
      }
    }

    // Наука.рядом
    &.f-partnership-offer-1 {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    &.f-faq-banner-desktop,
    &.f-excursions-subscribe-1 {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      @include mobile {
        display: none;
      }
    }

    &.f-talisman-regulations {
      width: 700px;
      height: 700px;
      top: 64px;
      right: -112px;

      .u-figures-item__img-wrapper {
        transform: rotate(180deg);
      }
      @include mobile {
        display: none;
      }
    }

    &.f-faq-banner-mob,
    &.f-excursions-subscribe-mob {
      @include mobile-min {
        display: none;
      }
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      img {
        object-position: left;
      }
    }

    &.f-faq-banner-mob {
      @include mobile {
        img {
          height: 100%;
          max-height: 100%;
          width: auto;
          position: absolute;
          top: 50%;
          right: 0;
          left: unset;
          transform: translateX(50%) translateY(-50%) !important;
          opacity: 0.5;
        }
      }
    }

    &.f-filtered-cards-page-1 {
      left: 0;
      @include mobile {
        display: none;
      }
    }
    &.f-talisman-timelaps {
      right: 16px;
      bottom: 0;
      height: 100%;
      width: 795px;

      img {
        object-position: center bottom;
      }

      @include mobile {
        right: -184px;
        width: 505px;
      }
    }
    &.f-talisman-works {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @include tablet {
        width: 140%;
        height: auto;
      }
    }

    &.f-excursions-photos-1 {
      right: 0;
      bottom: 0;
      @include mobile {
        display: none;
      }
    }
    &.f-excursions-accordion-1 {
      width: 166px;
      height: 166px;
      right: 2.8%;
      top: -100px;
      @include mobile {
        display: none;
      }
    }
    &.f-excursions-statistics-1 {
      left: 0;
      top: -180px;
      @include mobile {
        display: none;
      }
    }

    // навигатор - универы
    &.f-universities-main-page-1 {
      width: 700px;
      height: 700px;
      left: 0;
      top: -450px;
      transform: none !important;
      opacity: 1 !important;

      img {
        transform: none !important;
      }

      @include mobile {
        top: -349px;
        left: -60px;
        max-width: 100vw;
        width: 400px;
        height: unset;
        aspect-ratio: 400/400;
      }
    }
    &.f-universities-main-page-2 {
      width: 90vw;
      aspect-ratio: 1260/420;
      top: 0;
      left: 0;

      .u-figures-item__img-wrapper {
        @include mobile {
          transform: rotateZ(180deg) translateY(-80%);
        }
      }

      @include tablet {
        top: 50px;
      }

      @include mobile {
        width: 180vw;
        top: -110px;
        left: -80vw;
      }
    }
    &.f-universities-main-page-3 {
      width: 700px;
      height: 700px;
      left: 0;
      top: 197px;

      .u-figures-item__img-wrapper {
        transform: rotate(180deg);
      }
    }
    &.f-u-intro-1 {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      img {
        object-fit: cover;
      }
    }
    &.f-u-specialties-1,
    &.f-u-specialties-2,
    &.f-u-specialties-3 {
      width: 700px;
      height: 700px;

      @include mobile {
        width: 400px;
        height: 400px;
      }

      .u-figures-item__img-wrapper {
        transform: rotate(180deg);
      }
    }

    &.f-u-specialties-1 {
      left: 0;
      top: -140px;

      @include mobile {
        left: -20px;
        top: -50px;
      }
    }
    &.f-u-specialties-2 {
      left: -88px;
      bottom: 0;

      @include mobile {
        left: -20px;
      }
    }
    &.f-u-specialties-3 {
      left: -88px;
      bottom: -150px;

      @include mobile {
        left: -20px;
        bottom: -180px;
      }
    }
    &.f-u-institutions-1,
    &.f-u-institutions-2 {
      width: 700px;
      height: 700px;

      @include mobile {
        width: 400px;
        height: 400px;
      }

      .u-figures-item__img-wrapper {
        transform: rotate(180deg);
      }
    }
    &.f-u-institutions-1 {
      top: -180px;
      left: 0;

      @include mobile {
        left: -20px;
        top: -50px;
      }
    }
    &.f-u-institutions-2 {
      bottom: -220px;
      left: -88px;

      @include mobile {
        left: -20px;
        bottom: -180px;
      }
    }
    &.f-u-branches-1,
    &.f-u-branches-2 {
      width: 700px;
      height: 700px;

      .u-figures-item__img-wrapper {
        transform: rotate(180deg);
      }

      @include mobile {
        width: 400px;
        height: 400px;
      }
    }
    &.f-u-branches-1 {
      top: -210px;
      left: 0;

      @include mobile {
        top: -50px;
      }
    }
    &.f-u-branches-2 {
      bottom: -355px;
      left: -88px;

      @include mobile {
        bottom: -110px;
        left: -20px;
      }
    }

    &.u-subscribe-block {
      top: 22px;
      left: unset;
      right: 0;
      width: 100%;
      height: auto;

      @include mobile {
        display: none;
      }
    }

    &.u-subscribe-block-mob {
      @include mobile-min {
        display: none;
      }

      bottom: -50px;
      left: -50px;
      width: 400px;
      height: auto;
    }
  }
}
</style>
