<template>
  <div class="u-lk-digest-form">
    <div
      class="u-lk-digest-form__body"
      :class="{ 'u-lk-digest-form__body--is-invalid': isInvalidForm }"
    >
      <div
        v-if="initiativesOptions.length > 0"
        class="u-lk-digest-form__field u-select u-select--small"
      >
        <div>
          <div class="u-lk-digest-form__field-label">
            Название инициативы*
          </div>
          <v-select
            v-model="form.initiative"
            :class="{
              'vs--error': isRequiredErrorField('initiative')
            }"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="initiativesOptions"
            placeholder="Выберите инициативу"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
        <ErrorFormWrapper
          :isVisible="isRequiredErrorField('initiative')"
          class="u-lk-digest-form__error-field"
        >
          <div>Обязательное поле</div>
        </ErrorFormWrapper>
      </div>
      <div class="u-lk-digest-form__field">
        <UTextInput
          class="u-text-input--smaller"
          name="fullName"
          label="Ключевые мероприятия или проекты*"
          v-model="form.events"
          placeholder="…"
          :error="isRequiredErrorField('events')"
          isTextArea
        />
        <ErrorFormWrapper
          :isVisible="isRequiredErrorField('events')"
          class="u-lk-digest-form__error-field"
        >
          <div>Обязательное поле</div>
        </ErrorFormWrapper>
      </div>
      <div class="u-lk-digest-form__field">
        <UTextInput
          class="u-text-input--smaller"
          name="fullName"
          label="Информационные поводы, которые необходимо отметить*"
          v-model="form.reasons"
          placeholder="…"
          :error="isRequiredErrorField('reasons')"
          isTextArea
        />
        <ErrorFormWrapper
          class="u-lk-digest-form__error-field"
          :isVisible="isRequiredErrorField('reasons')"
        >
          <div>Обязательное поле</div>
        </ErrorFormWrapper>
      </div>
      <div class="u-lk-digest-form__field">
        <UTextInput
          class="u-text-input--smaller"
          name="fullName"
          label="Ссылки на информационные поводы (при наличии)"
          v-model="form.links"
          placeholder="…"
          isTextArea
        />
      </div>
      <div class="u-lk-digest-form__field">
        <div class="u-lk-digest-form__field-label u-lk-digest-form__field-label--lg-gap">
          Документ, максимум 5 файлов ({{ stringValidFormatFile }}. 2 Мб макс.)
        </div>
        <div class="lk-plan-form__field-el">
          <MediaLoaderController
            ref="mediaController"
            :validFormat="validFormatFile"
            :initialFiles="form.file"
            :maxSize="2"
            :maxCount="5"
            @change="onFileChangeFileEvent"
          />
        </div>
      </div>
    </div>
    <div class="u-lk-digest-form__errors">
      <div class="u-lk-digest-form__error-item">
        *Поля обязательны для заполнения
      </div>
      <ErrorFormWrapper
        :isVisible="Boolean(errorsAfterSubmit) && Boolean(errorsAfterSubmit.length)"
        class="u-lk-digest-form__error-feedback"
      >
        <div
          v-for="(error, i) in errorsAfterSubmit"
          :key="`u-lk-digest-form__error-feedback-item-${error.code}-${i}`"
        >
          <span v-html="error.message" />
        </div>
      </ErrorFormWrapper>
    </div>
    <div class="u-lk-digest-form__footer">
      <CustomButton
        theme="primary"
        size="md"
        :status="statusBtn"
        @click="onSubmit"
      >
        Отправить
      </CustomButton>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  mutateClearFilesFromForm,
  mutateOnFilesChange,
  mutateSetIsDeletedFile,
  getResetForm,
} from '@/components/helpers/formHelpers.js'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  mixins: [validationMixin],
  components: { vSelect },
  props: {
    initiativesOptions: {
      type: Array,
      default: () => []
    },
    formData: {
      type: Object,
      default: () => ({
        initiative: '',
        events: '',
        reasons: '',
        links: ''
      })
    },
    submitUrl: {
      type: String,
      default: ''
    },
    requestId: {
      type: [String, Number],
      default: ''
    },
  },
  data() {
    return {
      form: cloneDeep(this.formData),
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      isInvalidForm: false,
      errorsAfterSubmit: [],
      statusBtn: '',
      validFormatFile: ['pdf', 'doc', 'docx']
    }
  },
  validations: {
    form: {
      initiative: { required },
      events: { required },
      reasons: { required },
    }
  },
  computed: {
    getFormData() {
      const data = new FormData()

      if (this.requestId) {
        data.append('idUpdate', this.requestId)
      }

      for (let prop in this.form) {
        if (['file'].includes(prop)) {
          data.append(prop, JSON.stringify(this.form[prop]))
        } else {
          data.append(prop, this.form[prop])
        }
      }

      mutateSetIsDeletedFile(
        ['file'],
        data,
        this.formData,
        this.form
      )

      return data
    },

    stringValidFormatFile() {
      return this.validFormatFile.join(', ')
    }
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.isInvalidForm = true
        return
      }
      this.isInvalidForm = false

      const options = {
        url: this.submitUrl,
        method: 'POST',
        data: this.getFormData
      }

      const response = await axios(options);

      if (response.data.status === 'success') {
        this.succesResponse(response)
      } else {
        this.errorResponse(response)
      }
    },
    succesResponse(response) {
      this.statusBtn = 'success'
      this.$v.$reset();
      this.resetForm()
      this.errorsAfterSubmit = []

      const id = this.requestId ? this.requestId : response.data.data.result_id
      this.$root.$emit('uploadDigestItem', id)

      setTimeout(() => {
        if (this.requestId) {
          this.$root.$refs.customModal.closeModal(null, null, true);
        }
        this.statusBtn = ''
      }, 3000);
    },
    errorResponse(response) {
      this.statusBtn = ''
      this.errorsAfterSubmit = response.data.errors
    },
    resetForm() {
      this.form = getResetForm(this.form)
      this.$nextTick().then(() => {
        this.$refs.mediaController?.reInitFiles()
      })
    },
    isRequiredErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].required
    },
    onFileChange(files, fileProp) {
      mutateClearFilesFromForm(this.form, fileProp)
      mutateOnFilesChange(this.form, files, fileProp)
    },
    onFileChangeFileEvent(files) {
      this.onFileChange(files, 'file')
    },
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-lk-digest-form';
$gap: 32px;

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;

  // .u-lk-digest-form__body
  &__body {
    max-width: 620px;
    margin-bottom: 32px;
    transition: $transtion-default;

    @include mobile {
      display: block;
      margin-bottom: 24px;
    }

    &--is-invalid {
      row-gap: calc($gap + 10px);
    }
  }

  // .u-lk-digest-form__errors
  &__errors {
    font-family: $font-family-inter;
    margin-bottom: 32px;
    color: $color-danger;
    transition: $transtion-default;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .u-lk-digest-form__field
  &__field {
    margin-top: auto;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 32px;
      @include mobile {
        margin-bottom: 23px;
      }
    }
  }

  // .u-lk-digest-form__error-field
  &__error-field {
    position: absolute;
    top: 100%;
  }

  // .u-lk-digest-form__field-label
  &__field-label {
    font-family: $font-family-inter;
    font-size: 12px;
    line-height: 1.3;
    text-transform: initial;
    margin-bottom: 8px;
    margin-left: 8px;
    font-weight: normal;
  }

  // .u-lk-digest-form__footer
  &__footer {
    @include mobile {
      text-align: center;
    }
  }

  // .u-lk-digest-form__table
  &__table {
    margin-top: 64px;

    @include mobile {
      margin-top: 48px;
    }
  }
}
</style>
