<template>
  <div class="science-playground-block">
    <SciencePlaygroundFilter
      class="science-playground-block__filter"
      :tabs="filterTabs"
      :tags="filterTags"
      :tagsTitle="tagsTitle"
      :tabsTitle="tabsTitle"
      :activeFilters="filters"
      :enabledYm="enabledYm"
      @filter="onFilter"
    />
    <SciencePlaygroundList class="science-playground-block__list" :items="items" :enabledYm="enabledYm" />
    <div v-show="hasNextPage && !isLoading" ref="infinityTrigger" class="science-playground-block__infinity-trigger"></div>
  </div>
</template>

<script>
import axios from 'axios'
import { hasQueryInUrl } from '@/components/helpers/queryHelper.js'

const DEFAULT_FILTER = {
  theme: [],
  tags: []
}

export default {
  props: {
    initialItems: {
      type: Array,
      default: () => []
    },
    filterTabs: {
      type: Array,
      default: () => []
    },
    filterTags: {
      type: Array,
      default: () => []
    },
    tabsTitle: {
      type: String,
      default: '',
    },
    tagsTitle: {
      type: String,
      default: '',
    },
    actionFilter: {
      type: String,
      default: '',
    },
    initialNextPageNum: {
      type: Number,
      default: 0,
    },
    enabledYm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: JSON.parse(JSON.stringify(this.initialItems)),
      filters: DEFAULT_FILTER,
      intersectionObserver: null,
      nextPageNum: this.initialNextPageNum,
      isLoading: false,
    }
  },
  computed: {
    filtersFormData() {
      const result = new FormData()

      for (const key in this.filters) {
        const value = this.filters[key]

        if (value && value.length) {
          result.append(key, JSON.stringify(value))
        }
      }

      result.append('ajaxCall', 1)

      return result
    },
    hasNextPage() {
      return this.nextPageNum > 1
    }
  },
  methods: {
    onFilter(filters) {
      this.filters = filters

      this.loadList(true)
    },
    async loadList(withClear) {
      try {
        const page = withClear ? 1 : this.nextPageNum
        const separator = hasQueryInUrl(this.actionFilter) ? '&' : '?'

        const url = `${this.actionFilter}${separator}PAGEN_1=${page}`

        const options = {
          url,
          method: 'POST',
          data: this.filtersFormData
        }

        this.isLoading = true
        this.$root.$emit('setLoading', this.isLoading)

        const response = await axios(options)

        if (response && response.data.result) {
          this.items = withClear ? response.data.result.items : [ ...this.items, ...response.data.result.items ]
          this.nextPageNum = response.data.result.nextNumPage
        }
      } catch(e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
        this.$root.$emit('setLoading', this.isLoading)
      }
    },
    infinityObserve(entry) {
      if (!entry[0].isIntersecting || !this.hasNextPage || this.isLoading) {
        return
      }

      this.loadList(false)
    },
    setObserve() {
      this.intersectionObserver = new IntersectionObserver(this.infinityObserve)

      this.intersectionObserver.observe(this.$refs.infinityTrigger)
    }
  },
  mounted() {
    this.setObserve()
  },
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.science-playground-block';

#{$b} {

  // .science-playground-block__filter
  &__filter {
    @include mobile {
      margin-bottom: 10px;
    }
  }

  // .science-playground-block__list
  &__list {
    position: relative;
    z-index: 1;
  }

  // .science-playground-block__infinity-trigger
  &__infinity-trigger {
    height: 1px;
    width: 100%;
    opacity: 0;
  }
}
</style>
