import axios from 'axios'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    errorsAfterSubmit: [],
  },

  getters: {
    IS_LOADING(state) {
      return state.isLoading
    },

    ERRORS_AFTER_SUBMIT(state) {
      return state.errorsAfterSubmit
    }
  },

  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },

    SET_ERRORS_AFTER_SUBMIT(state, errors) {
      state.errorsAfterSubmit = errors
    }
  },

  actions: {
    async SAVE({ commit }, { url, form, successHandler }) {
      try {
        commit('SET_IS_LOADING', true)

        const options = {
          url,
          method: 'POST',
          data: form
        }

        const response = await axios(options)

        if ([200,201].includes(response?.status) && response?.data.status === 'success') {
          if (successHandler) {
            successHandler()
          }
        }
        console.log('response', response)
        if (response?.data?.errors) {
          commit('SET_ERRORS_AFTER_SUBMIT', response.data.errors)
        }

      } catch (e) {
        throw new Error(`lkReportTable:SAVE | ${e}`)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    }
  }
}
