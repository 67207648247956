<template>
  <div class="initiatives-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      isEditable: this.isEditable
    }
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false
    }
  }
}
</script>
