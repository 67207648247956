import axios from "axios"
import { hasQueryInUrl } from '/frontend/components/helpers/queryHelper.js'

export default {
  props: {
    initialItems: {
      type: Array,
      default: () => []
    },
    initialNextPage: {
      type: [String, Number],
      default: ''
    },
    actionGet: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      items: JSON.parse(JSON.stringify(this.initialItems)),
      btnStatus: '',
      pageNumber: Number(this.initialNextPage) || 1,
      selectedRegion: '',
    }
  },
  methods: {
    getActionUrl(query, loadWithReplace) {
      const separator = hasQueryInUrl(this.actionGet) ? '&' : '?'
      const page = loadWithReplace ? 1 : this.pageNumber
      const queryStr = query ? `&${query}` : ''

      return `${this.actionGet}${separator}page=${page}${queryStr}`
    },
    async showMoreHandler() {
      this.btnStatus = 'loading'
      const filterQuery = this.filterQuery || (this.selectedRegion ? `region=${this.selectedRegion}` : '')
      await this.loadList(filterQuery)
      this.btnStatus = ''
    },
    async loadList(filterParams = '', loadWithReplace = false) {
      const url = this.getActionUrl(filterParams, loadWithReplace)
      const response = await axios(url)

      if (response && response.data.status === 'success') {
        this.items = loadWithReplace ? response.data.data.items : [...this.items, ...response.data.data.items]
        this.pageNumber = response.data.data.nextPage ? Number(response.data.data.nextPage) : ''
      }
    },
  },
}
