<template>
  <div class="stories-block" :class="{'stories-block--has-compensator': hasCompensator}">
    <div class="stories-block__inner">
      <img src="/frontend/assets/img/childrens-playground/StorisBlock/bg.png" class="stories-block__bg">
      <div class="u-g-container">
        <div class="stories-block__wrap">
          <div class="stories-block__wrap-item stories-block__wrap-item--left">
            <h2 class="stories-block__title title-xl" v-html="title" />
            <img src="/frontend/assets/img/childrens-playground/StorisBlock/3.png" class="stories-block__img-el">
          </div>
          <div class="stories-block__wrap-item stories-block__stories">
            <StoriesList :list="list" class="stories-block__stories-list"/>
          </div>
          <div class="stories-block__wrap-item">
            <div class="stories-block__images">
              <img src="/frontend/assets/img/childrens-playground/StorisBlock/1.png" class="stories-block__images-item">
              <img src="/frontend/assets/img/childrens-playground/StorisBlock/2.png" class="stories-block__images-item">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    hasCompensator: {
      type: Boolean,
      default: false
    }
  }
}
</script>


<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.stories-block';

#{$b} {

  // .stories-block__inner
  &__inner {
    position: relative;

    #{$b}--has-compensator & {
      margin-bottom: -100px;

      @include tablet {
        margin-bottom: 30px;
      }
    }
  }

  // .stories-block__bg
  &__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    pointer-events: none;
    touch-action: none;

    @include tablet {
      display: none;
    }
  }

  // .stories-block__title
  &__title {
    margin: 0;

    @include tablet {
      margin-bottom: 24px;
    }
  }

  // .stories-block__wrap
  &__wrap {
    display: grid;
    grid-template-columns: 1fr 368px 1fr;
    gap: 40px;

    @include tablet {
      display: block;
    }

    // .stories-block__wrap-item
    &-item {
      // .stories-block__wrap-item--left
      &--left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

    }
  }

  // .stories-block__img-el
  &__img-el {
    position: relative;
    width: 100%;
    max-width: 318px;
    height: auto;
    margin: 30px 0 0;
    z-index: 2;

    @include tablet {
      display: none;
    }
  }

  // .stories-block__stories
  &__stories {
    @include tablet {
      max-width: 450px;
      margin: 0 auto;
    }
  }

  // .stories-block__images
  &__images {
    text-align: right;
    width: auto;
    height: auto;
    max-width: 100%;

    @include tablet {
      display: none;
    }

    // .stories-block__images-item
    &-item {
      position: relative;
      z-index: 1;

      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }

  // .stories-block__stories-list
  &__stories-list {

  }
}
</style>
