<template>
  <div class="initiatives-features" >
    <div v-if="isEditable" class="initiatives-features__header">
      <CustomButton
        theme="outline-primary"
        size="md"
        @click="edit()"
      >
        Редактировать
      </CustomButton>
    </div>
    <div class="initiatives-features__inner" v-html="features"></div>
  </div>
</template>

<script>
export default {
  inject: {
    isEditable: {
      from: 'isEditable',
      default: false
    },
  },
  props: {
    data: {
      type: String,
      default: ''
    },
    // Эндпоинт для редактирования Возможности
    submitUrl: {
      type: String,
      default: ''
    },
    initiativeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      features: this.data
    }
  },
  methods: {
    edit() {
      this.$root.$refs.customModal.openModal(null, null, true, true);
      this.$root.$refs.customModal.passContent({
        name: 'initiatives-features-form',
        props: {
          initialValue: this.features || '',
          submitUrl: this.submitUrl,
          initiativeId: this.initiativeId
        },
        title: 'Редактировать Возможности'
      });
    },
    updateData(data) {
      this.features = data
    }
  },
  mounted() {
    if (this.isEditable) {
      this.$root.$on('featuresUpdate', this.updateData)
    }
  },
  beforeDestroy() {
    if (this.isEditable) {
      this.$root.$off('featuresUpdate', this.updateData)
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: ".initiatives-features";

#{$b} {
  // .initiatives-features__header
  &__header {
    margin-bottom: 32px;
  }

  h2 {
    font-family: $font-family-main-head;
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 100%;
    text-transform: uppercase;
    margin: 0 0 32px 0;

    @include mobile {
      font-size: 32px;
      margin-bottom: 48px;
    }
  }

  ul {
    margin: 0 0 32px 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    font-family: $font-family-inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: $black-true;

    &:last-child {
      margin-bottom: 0;
    }

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      display: block;
    }
  }

  li {
    display: flex;
    margin: 0;
    padding: 24px;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    transition: $transtion-default;

    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      background: $gradient-primary-light;
      pointer-events:none;
      touch-action: none;
      transition: $transtion-default;
      transform: rotate(180deg)
    }

    &:hover {
      @include mobile-min {
        background-color: $color-base;
        color: $white-true;

        &::after {
          opacity: 0;
        }
      }
    }
  }
}
</style>
