<template>
  <div class="u-tabs">
    <div class="u-tabs__header">
      <ul
        class="u-tabs__list"
        :class="{'u-tabs__list--hide-on-mobile': dropDownOnMobile }"
      >
        <template v-for="(item, i) in tabs" >
          <li
            v-if="item !== $refs.dropdown"
            :id="item.id"
            :key="`u-tabs__list-item-${i}`"
            :class="{ 'is-active': item.isActive }"
          >
            <a v-if="item.url" class="u-tabs__list-item" :href="hrefWithRegion(item.url)">
              <span>{{ item.title }}</span>
            </a>
            <span v-else class="u-tabs__list-item" @click.prevent="selectTab(i)">
              <span>{{ item.title }}</span>
            </span>
          </li>
        </template>
      </ul>
      <TabListDropdown
        ref="dropdown"
        v-if="isExistsMobileTabs"
        class="u-tabs__list-mobile"
        uniqueKey="title"
        :tabs="tabs"
        :value="activeTab.title"
        @input="(title, i) => selectTab(getIndexByTitle(title))"
      />
    </div>
    <div ref="tabContent" class="u-tabs__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",

  props: {
    current: { type: Boolean, default: false },
    dropDownOnMobile: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tabs: [],
      isOpenedOptions: false
    };
  },

  mounted() {
    this.tabs = this.$children;
    this.selectTab(0);
  },

  methods: {
    getIndexByTitle(title) {
      return this.tabs.findIndex(item => item.title === title)
    },

    selectTab(selectedTab) {
      this.tabs.forEach((tab, i) => {
        this.tabs[i].isActive = false;
      });
      this.tabs[selectedTab].isActive = true;

      this.$emit('changeTab', this.tabs[selectedTab])
    },
  },

  computed: {
    isExistsMobileTabs(){
      return (this.dropDownOnMobile && this.tabs && this.tabs.length) ? true : false
    },

    activeTab() {
      if (!this.isExistsMobileTabs) {
        return
      }

      return this.tabs.find(tab => tab.isActive)
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: ".u-tabs";

#{$b} {
  &-overflow {
    min-width: 100%;
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    box-sizing: border-box;

    @include tablet {
      margin-left: #{-$container-pd-left-tablet};
      margin-right: #{-$container-pd-left-tablet};
      padding-left: $container-pd-left-tablet;
      padding-right: $container-pd-left-tablet;
    }

    @include mobile {
      margin-left: #{-$container-pd-left-mobile};
      margin-right: #{-$container-pd-left-mobile};
      padding-left: $container-pd-left-mobile;
      padding-right: $container-pd-left-mobile;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &__list {
    width: max-content;
    display: inline-flex;
    align-items: center;
    border: 1px solid $color-base;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 10px;

    @include tablet {
      margin-bottom: 1.5rem;
    }

    // .u-tabs__list--hide-on-mobile
    &--hide-on-mobile {
      @include mobile {
        display: none;
      }
    }

    & > li {
      height: 40px;

      &:not(:first-child) {
        #{$b}__list-item{
          border-left: 1px solid $color-base;
        }
      }

      @include mobile {
        height: 36px;
      }
    }

    &-item {
      cursor: pointer;
      display: flex;
      height: 100%;
      align-items: center;
      text-decoration: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      padding: 0 19px;
      color: $color-base;
      white-space: nowrap;
      transition: .2s ease;
      transition-property: color;

      @include mobile {
        font-weight: 500;
        font-size: 13px;
        line-height: 130%;
      }

      &:hover {
        color: $color-base;
      }

      .is-active & {
        color: #ffffff;
      }
    }

    .is-active {
      border: 1px solid $color-base;
      background-color: $color-base;
    }
  }

  // .u-tabs__list-mobile
  &__list-mobile {
    @include mobile-min {
      display: none;
    }
  }
}

</style>
