<template>
  <transition name="modal-fade">
    <div
      v-show="isShow"
      ref="modal"
      class="v-modal"
      :class="{
        'is-open': isShow,
        'v-modal--full-width-mob': isFullWidthModalComponent,
        'v-modal--size-xl': isXlWidthComponent,
        'v-modal--size-ll': isLlWidthComponent,
        'v-modal--size-lg': isLgWidthComponent,
        'v-modal--size-sm': isSmWidthComponent,
      }"
      v-body-scroll-lock:reserveScrollBarGap="isShow"
      @mousedown.self="closeModal"
    >
      <div
        v-if="dialogVisible"
        class="v-modal__dialog"
        :class="{
          'v-modal__dialog--hidden': !dialogVisible,
          'v-modal__dialog--full': isFullWidthComponent,
          'v-modal__dialog--size-lg': isLgWidthComponent,
        }"
        tabindex="0"
      >
        <input v-if="redirectUrl" ref="redirectUrl" type="hidden" :value="redirectUrl">
        <div class="v-modal__close" @click="closeModal">
          <InlineSvg src="close-thin"></InlineSvg>
        </div>
        <div v-if="title || contentComponent.title" class="v-modal__title tt-uppercase" v-html="title || contentComponent.title"></div>
        <template v-if="isShow && !contentComponent.name">
          <slot />
        </template>
        <template v-else>
          <component :is="contentComponent.name" v-bind="contentComponent.props" />
        </template>
      </div>
      <div class="v-modal__spinner" v-else>
        <div class="v-modal__spinner-inner">
          <InlineSvg src="spinner" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    isShowed: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      isShow: false,
      modal: undefined,
      redirectUrl: "",
      dialogVisible: true,
      contentComponent: {
        name: null,
        props: null,
        title: null,
      },
      clearContentAfterClose: false
    };
  },
  mounted() {
    this.modal = this.$refs.modal;

    if (this.isShowed) {
      this.isShow = true;
    }

    document.addEventListener("keyup", e => {
      const key = e.code || e.key;
      if (key == "Escape") {
        this.closeModal();
      }
    });

    this.$root.$on('modal-cancel', () => {
      this.closeModal();
    });

    this.$root.$on('modal-link', (link) => {
      window.location.href = this.hrefWithRegion(link);
    });

    this.$root.$on('modal-tab', (tab) => {
      this.$root.$emit('header-tabs-change', tab);
      this.isShow = false;
    });
  },
  computed: {
    fullWidthModalComponents() {
      return [
        'lk-plan-form',
        'u-lk-digest-form',
        'lk-plan-documents',
        'initiatives-objectives-form',
        'initiatives-features-form',
        'initiatives-audience-form',
        'initiatives-news-form',
        'initiatives-partners-form',
        'u-event-form',
        'talisman-contestants-detail',
      ]
    },
    fullWidthComponents() {
      return [
        'event-form',
        ...this.fullWidthModalComponents,
      ]
    },
    lgWidthComponent() {
      return [
        'talisman-work-window',
        'talisman-contestants-window',
      ]
    },
    xlWidthComponent() {
      return [
        'tour-block',
      ]
    },
    llWidthComponent() {
      return [
        'tour-form-success',
      ]
    },
    smWidthComponent() {
      return [
        'location-window',
      ]
    },
    isFullWidthComponent() {
      return this.fullWidthComponents.includes(this.contentComponent.name)
    },
    isLgWidthComponent() {
      return this.lgWidthComponent.includes(this.contentComponent.name)
    },
    isFullWidthModalComponent() {
      return this.fullWidthModalComponents.includes(this.contentComponent.name)
    },
    isXlWidthComponent() {
      return this.xlWidthComponent.includes(this.contentComponent.name)
    },
    isLlWidthComponent() {
      return this.llWidthComponent.includes(this.contentComponent.name)
    },
    isSmWidthComponent() {
      return this.smWidthComponent.includes(this.contentComponent.name)
    },
  },
  methods: {
    openModal(link = '', content, awaitContent, clearContentAfterClose = false) {
      if (link) {
        this.redirectUrl = link;
      }
      if (content) {
        this.passContent(content);
      }
      if (awaitContent) {
        this.dialogVisible = false;
      }
      this.clearContentAfterClose = clearContentAfterClose
      this.isShow = true;
    },
    passContent(componentData) {
      Object.assign(this.contentComponent, componentData);
      this.dialogVisible = true;
    },
    closeModal() {
      this.isShow = false;

      if (this.clearContentAfterClose) {
        this.clearContentComponent()
      }
    },
    toggleDialog(visible = true) {
      this.dialogVisible = visible;
    },
    clearContentComponent() {
      this.contentComponent = {
        name: null,
        props: null,
        title: null,
      }
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".v-modal";

#{$b} {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 80px;
  background: rgba($black-true, 0.5);
  opacity: 0;
  transition: opacity 0.15s linear;

  @include tablet {
    padding: 40px;
  }

  @include mobile {
    padding: 12px;
  }

  &.is-open {
    opacity: 1;
    display: flex;
    align-items: flex-start;
  }

  &--full-width-mob,
  &--size-lg,
  &--size-xl,
  &--size-ll {
    @include tablet {
      padding: 40px 12px;
    }
    @include mobile {
      padding: 12px 0;
    }
  }

  &--size-lg,
  &--size-xl,
  &--size-ll {

    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__dialog {
    position: relative;
    box-sizing: border-box;
    margin: auto;
    padding: 32px;
    width: 504px;
    max-width: calc(100% - 0.01px) !important;
    background: $white-true;
    transition: 0.3s linear;
    transition-property: opacity, transform;
    border-radius: 16px;

    @include mobile {
      margin: auto auto;
    }

    &--full {
      width: 1330px;

      @include tablet-min {
        padding: 48px;
      }

      @include mobile {
        padding: 24px 12px;
      }
    }

    #{$b}--size-sm & {
      width: 340px;
      padding: 20px;

      @include mobile {
        width: 100%;
      }
    }

    &--size-lg {
      width: 600px;
    }

    #{$b}--size-ll & {
      width: 613px;

      @include mobile {
        padding: 32px 24px;
      }
    }

    #{$b}--size-xl & {
      width: 1082px;

      @include mobile {
        padding: 32px 24px;
      }
    }
  }

  &__spinner {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    &-inner {
      flex: 50px 0 0;
      max-width: 50px;
    }
  }

  &__close {
    cursor: pointer;
    user-select: none;
    position: absolute;
    z-index: 10;
    top: 34px;
    right: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin: 0;
    color: $color-black;
    text-decoration: none;
    transition: color 0.2s ease;

    @include mobile {
      top: 32px;
      right: 32px;

      #{$b}--full-width-mob & {
        top: 27px;
        right: 15px;
      }
    }

    #{$b}--size-lg & {
      top: 20px;
      right: 20px;
    }

    #{$b}--size-sm & {
      top: 20px;
      right: 20px;
      width: 15px;
      height: 15px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: $color-base;
    }

    .inline-svg {
      flex-shrink: 0;
      display: block;
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
  }

  &__title {
    font-family: $font-family-main-head;
    display: block;
    font-weight: 500;
    font-size: 44px;
    line-height: 1;
    color: $color-black;
    padding-right: 30px;
    margin: 0 0 31px;

    @include mobile {
      font-size: 28px;

      #{$b}--full-width-mob & {
        font-size: 22px;
      }
    }

    @media screen and (max-width: 350px) {
      word-break: break-word;
    }
  }
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.2s;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}
</style>
