<template>
  <div class="i-excursion-list">
    <div class="i-excursion-list__inner">
      <IExcursionCard
        v-for="item in items"
        :key="`i-excursion-list__card-${item.id}`"
        class="i-excursion-list__card"
        :data="item"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.i-excursion-list';

#{$b} {

  // .i-excursion-list__inner
  &__inner {
    @include gridcols(2, 16px);

    @include mobile {
      display: block;
    }
  }

  // .i-excursion-list__card
  &__card {
    @include mobile {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
