<template>
  <div class="photo-slider" ref="wrapper">
    <swiper
      ref="photoSwiper"
      :options="swiperOptions"
      :style="{marginRight: rightOffset}"
    >
      <swiper-slide class="photo-slider__slide" v-for="(item, index) in slides" :key="item.id">
        <div class="photo-slider__img" @click="lightboxIndex = index" :style="{ backgroundImage: 'url(\'' + item.thumb + '\')' }"></div>
      </swiper-slide>
    </swiper>
    <div class="photo-slider__controls-wrapper">
      <div class="photo-slider__controls">
        <div class="photo-slider__prev">
          <inline-svg src="arrow-left" />
        </div>
        <div class="photo-slider__count swiper-pagination"></div>
        <div class="photo-slider__next">
          <inline-svg src="arrow-right" />
        </div>
      </div>
      <div class="photo-slider__bullets g-pagination-bullets"></div>
    </div>

    <CoolLightBox
      :items="slides"
      :index="lightboxIndex"
      :loop="true"
      :slideshow="false"
      thumbs-position="bottom"
      @close="onLightboxClose"
      @on-open="onLightboxOpen"
    />
  </div>
</template>

<script>
  import InlineSvg from "./InlineSvg";
  import  CoolLightBox from 'vue-cool-lightbox'
  import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
  import scrollbarWidth from './helpers/scrollbarWidth'
  export default {
    name: "PhotoSlider",
    components: {InlineSvg, CoolLightBox},
    props: {
      slides: {
        type: Array,
        default: () => [
          {
            id: '0',
            thumb: '/frontend/assets/img/projects/photo.jpg',
            src: '/frontend/assets/img/projects/photo3x.jpg'
          },
          {
            id: '1',
            thumb: '/frontend/assets/img/projects/photo2.jpg',
            src: '/frontend/assets/img/projects/photo2-3x.jpg'
          },
          {
            id: '2',
            thumb: '/frontend/assets/img/projects/photo.jpg',
            src: '/frontend/assets/img/projects/photo3x.jpg'
          },
          {
            id: '3',
            thumb: '/frontend/assets/img/projects/photo.jpg',
            src: '/frontend/assets/img/projects/photo3x.jpg'
          }
        ]
      }
    },
    data() {
      return {
        swiperOptions: {
          slidesPerView: "auto",
          spaceBetween: 0,
          loop: false,
          watchOverflow: true,
          watchSlidesVisibility: true,
          slideVisibleClass: "swiper-slide-visible",
          navigation: {
            prevEl: ".photo-slider__prev",
            nextEl: ".photo-slider__next",
            disabledClass: "is-disabled"
          },
          pagination: {
            el: ".photo-slider__bullets",
            type: "bullets",
            bulletClass: "g-pagination-bullet",
            bulletActiveClass: "is-active",
            clickable: true
          },
          breakpoints: {
            1024: {
              pagination: {
                el: ".photo-slider__count",
                type: "fraction"
              }
            }
          }
        },
        rightOffset: 0,
        lightboxIndex: null,
        htmlBlock: undefined
      }
    },
    mounted() {
      let offset = window.innerWidth - (this.$refs.wrapper.getBoundingClientRect().left + this.$refs.wrapper.offsetWidth) - scrollbarWidth();
      if (offset > 0) {
        this.rightOffset = -offset + 'px'
      }
      this.htmlBlock = document.querySelector("html");
    },
    methods: {
      onLightboxOpen() {
        this.htmlBlock.style.overflow = "hidden";
      },
      onLightboxClose() {
        this.htmlBlock.style.overflow = "";
        this.lightboxIndex = null;
      },
    }
  }
</script>

<style lang="scss">
  @import "../scss/base/includes";
  .photo-slider {
    position: relative;

    &__slide {
      cursor: pointer;
      width: 574px;
      height: 374px;
      margin-right: 40px;
      text-decoration: none;
      @include low-desktop {
        width: 390px;
        height: 255px;
        margin-right: 30px;
      }
      @include tablet {
        width: 324px;
        height: 213px;
      }

      @include mobile {
        width: 260px;
        height: 160px;
        margin-right: 20px;
      }
    }

    &__img {
      @include bg-cover;
      width: 100%;
      height: 100%;
    }

    &__controls-wrapper {
      position: absolute;
      left: -150px;
      bottom: 6px;
      z-index: 10;

      @include low-desktop {
        left: -128px;
      }

      @include tablet {
        position: static;
        margin: 40px 0 0;
      }
    }

    &__controls {
      height: 0;
      width: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $color-black;

      @include desktop {
        width: 88px;
      }

      @include tablet {
        display: none;
      }

      .swiper-pagination {
        position: static;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }

    &__prev,
    &__next {
      outline: 0;
      text-decoration: none;
      user-select: none;
      cursor: pointer;
      flex-shrink: 0;
      width: 20.5px;
      height: 11px;
      transition: 0.3s;
      color: inherit;

      @include desktop {
        width: 15.5px;
        height: 11px;
      }

      @include hover {
        &:not(.is-disabled) {
          color: $color-base;
        }
      }

      &.is-disabled {
        cursor: default;
        opacity: 0.35;
      }

      &.swiper-button-lock {
        display: none;
      }
    }

    &__bullets {

      @include tablet-min {
        display: none;
      }
    }
  }
</style>
