<template>
  <div class="initiatives-conditions">
    <div class="initiatives-conditions__head">
      <h2
        v-html="title"
        class="initiatives-conditions__title title-xl tt-uppercase"
      ></h2>
      <p
        v-html="description"
        class="initiatives-conditions__description text-lg"
      ></p>
    </div>
    <div class="initiatives-conditions__body">
      <u-initiative-condition
        v-for="item in formattedItems"
        :key="`u-initiative-condition-${item.id}`"
        :icon-href="item.iconHref"
        :text="item.text"
        :size="item.size"
      ></u-initiative-condition>
    </div>
  </div>
</template>

<script>
import { randomString } from "../../helpers/randomString";

export default {
  name: "InitiativesConditions",
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    initialItems: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    formattedItems() {
      return this.initialItems.map((item) => {
        return {
          ...item,
          iconHref: item.iconHref || '',
          text: item.text || '',
          size: item.size || 'm',
          id: item.id || randomString(3)
        }
      });
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

.initiatives-conditions {
  &__head {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
      gap: 24px;
    }
  }

  &__title {
    font-family: $font-family-main-head;
    margin: 0 0;
  }

  &__description {
    margin: 0 0;

    @include mobile {
      font-size: 18px;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @include tablet {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}
</style>
