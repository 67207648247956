<template>
  <div class="u-event-small-cards">
    <UEventSmallCard
      v-for="card in initItems"
      :key="`u-event-small-card-${card.id}`"
      :data="card"
      class="u-event-small-cards__card"
    />
  </div>
</template>

<script>
export default {
  props: {
    initItems: {
      type: Array,
      default: ()=> [
        {
            id: 8899,
            date: "2022-01-01 00:00",
            title: "Лаборатория технологии материалов и устройств электрохимических источников энергии",
            url: "/events/8899/",
            isAdditional: false,
            isRegional: false,
            period: "2022",
            organizers: [
                {
                    name: "Илья Родионов"
                },
                {
                    name: "Константин Констотинопольский"
                },
            ],
            place: ""
        },
        {
            id: 8898,
            date: "2022-01-01 00:00",
            title: "Расширение существующих научных лабораторий академии, а также создание новых, направленных на решение важнейших задач развития общества и региона",
            url: "/events/8898/",
            isAdditional: false,
            isRegional: false,
            period: "2022",
            organizers: [
                {
                    name: "Олеся Максимова"
                }
            ],
            place: ""
        },
        {
            id: 8897,
            date: "2022-01-01 00:00",
            title: "Расширение лаборатории сельскохозяйственных биотехнологий",
            url: "/events/8897/",
            isAdditional: false,
            isRegional: false,
            period: "2022-2023",
            organizers: [
                {
                    name: "Олеся Максимова"
                }
            ],
            place: ""
        },
        {
            id: 8896,
            date: "2024-01-01 00:00",
            title: "Лаборатория в области разработки и внедрения в производство природных технологий секвестрации углерода и снижения углеродного следа продукции сельского хозяйства в составе карбонового полигона ОмГАУ",
            url: "/events/8896/",
            isAdditional: false,
            isRegional: false,
            period: "2024",
            organizers: [
                {
                    name: "Андрей Хог"
                }
            ],
            place: ""
        },
        {
            id: 8895,
            date: "2023-01-01 00:00",
            title: "Лаборатория мирового уровня в области анализа и оценки природного капитала",
            url: "/events/8895/",
            isAdditional: false,
            isRegional: false,
            period: "2023",
            organizers: [
                {
                    name: "Симон Мацкеплишвили"
                }
            ],
            place: ""
        },
        {
            id: 8894,
            date: "2024-01-01 00:00",
            title: "Лаборатория цифровых двойников в сельском хозяйстве (на основе цифровых проектов в области развития технологий в растениеводстве и животноводстве)",
            url: "/events/8894/",
            isAdditional: false,
            isRegional: false,
            period: "2024",
            organizers: [
                {
                    name: "Андрей Хог"
                }
            ],
            place: ""
        }
      ]
    }
  }
}
</script>


<style lang="scss">
@import '/frontend/scss/base/u-includes';

.u-event-small-cards{

  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 16px;

  @include low-desktop {
    grid-template-columns: repeat(2,1fr);
  }

  @include mobile {
    display: block;
  }

  // .u-event-small-cards__card
  &__card {
    @include mobile {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
