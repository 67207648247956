<template>
  <div class="file-input">
    <div v-if="label.length" class="file-input__label text-input__label" v-html="label"></div>
    <div class="file-input__wrapper">
      <div class="file-input__previews">
        <div v-for="(file, i) in files" :key="i" @click.stop.prevent="removeFile(i)" class="file-input__preview button-rounded">
          <div class="file-input__preview-name">
            {{ truncate(file.name, 20) }}
          </div>
          <span class="file-input__preview-remove"></span>
        </div>
      </div>
      <div class="file-input__upload">
        <input @change="fileChange" type="file" v-bind="attributes">
        <label v-if="files.length < maxFiles" :for="this.id" class="file-input__btn button-rounded button-rounded--blue">
          прикрепить
        </label>
      </div>
    </div>
    <div v-if="showOversizeErr" class="file-input__oversized-err event-form__error">
      Максимальный размер файла - {{ this.maxSizeMb }} Мб.
    </div>
  </div>
</template>

<script>
export default {
  name: "FileInput",
  props: {
    name: { type: String, required: true },
    label: { type: String, default: '' },
    multiple: { type: Boolean, default: true },
    accept: { type: String, default: 'image/jpg, image/jpeg, image/png' },
    maxSizeMb: { type: [String, Number], default: 10 },
    maxFiles: { type: [String, Number], default: 10 },
    initialFiles: { type: Array, default: () => ([]) },
  },
  data() {
    return {
      files: [],
      filesAdded: [],
      attributes: {},
      id: null,
      showOversizeErr: false,
    };
  },
  computed: {
    filesAllowedNum() {
      return this.maxFiles - this.files.length;
    },
  },
  created() {
    this.initialFiles.forEach((file) => {
      if (file) {
        this.files.push({ name: file.replace(/^.*[\\/]/, ''), url: file });
      }
    });

    this.id = `${this.name}_${Math.round(Math.random() * 100)}`;

    this.attributes = {
      name: `${this.name}[]`,
      accept: this.accept,
      id: this.id,
    };

    if (this.multiple) {
      this.attributes.multiple = true;
    }
  },
  methods: {
    fileChange(ev) {
      this.showOversizeErr = false;

      if (!ev.target.files) {
        return;
      }

      this.filesAdded = Array.from(ev.target.files);
      this.removeDuplicatesOrOversized(this.filesAdded);
      this.filesAdded.splice(this.filesAllowedNum);
      this.files = this.files.concat(this.filesAdded);
      this.filesAdded =[];
      ev.target.value = '';

      this.$emit('file-added', this.files);
    },
    truncate (fullStr, strLen, separator = '...') {
      if (fullStr.length <= strLen) return fullStr;

      const sepLen = separator.length;
      const charsToShow = strLen - sepLen;
      const frontChars = Math.ceil(charsToShow/2);
      const backChars = Math.floor(charsToShow/2);

      return fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars);
    },
    removeFile(i) {
      this.files.splice(i, 1);
      this.$emit('file-added', this.files);
    },
    removeDuplicatesOrOversized(files) {
      this.filesAdded =  files.filter((file) => {
        const maxSizeInBytes = this.maxSizeMb * (1 << 20);
        if (file.size > maxSizeInBytes) {
          this.showOversizeErr = true;
          return false;
        }
        let isNotDuplicate = true;
        this.files.forEach((curFile) => {
          if (file.name === curFile.name && file.lastModified === curFile.lastModified) {
            isNotDuplicate = false;
          }
        });
        return isNotDuplicate;
      });
    }
  },
};
</script>

<style lang="scss">
  @import "../scss/base/includes";
  $b: '.file-input';

  #{$b} {
    &__wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;

      @include mobile {
        display: block;
      }
    }

    &__upload {
      margin-left: 25px;

      @include mobile {
        margin-left: 0;
      }

      input {
        position: absolute;
        width: 0;
        height: 0;
        appearance: none;
        opacity: 0;
        pointer-events: none;
      }
    }

    &__previews {
      display: flex;
      flex-wrap: wrap;

      @include mobile {
        display: block;
      }
    }

    &__preview {
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 0 32px 0 16px;
      position: relative;
      cursor: pointer;

      @include mobile {
        width: 100%;
        margin-right: 0;
      }

      &-remove {
        @include remove-cross;
        position: absolute;
        right: 13px;
        top: 9.5px;
      }
    }
  }
</style>
