<template>
  <div v-if="src" class="img-wyz" :class="`img-wyz--${imgAttrs.align}`">
    <img v-bind="imgAttrs" @click="openInModal"/>
    <div v-if="author" class="img-wyz__author text-base fw-600" v-html="author" />
    <div v-if="description" class="img-wyz__description text-base" v-html="description" />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    align: {
      type: String,
      default: 'none'
    },
    author: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  computed: {
    imgAttrs() {
      const attrs = {
        src: this.src,
        align: this.align || 'left'
      }

      if (this.width) {
        attrs.width = this.width
      }
      if (this.height) {
        attrs.height = this.height
      }

      return attrs
    },
    galleryItem() {
      return {
        src: this.src,
        title: this.author || '',
        description: this.description || ''
      }
    }
  },
  methods: {
    openInModal() {
      this.$root.$emit('openPopupGallery', 0, [this.galleryItem], { loop: true })
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.img-wyz';

#{$b} {
  border-radius: 0 !important;
  margin-bottom: 16px;

  img {
    float: unset !important;
    margin: 0 !important;
    cursor: pointer;
    transition: $transtion-default;

    @include hover {
      filter: brightness(0.85)
    }
  }

  // .img-wyz__author,
  // .img-wyz__description
  &__author,
  &__description {
    margin: 10px 0 0 0;

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    @include tablet {
      margin-top: 5px;
      font-size: 14px;
    }

    @include mobile {
      font-size: 12px;
    }
  }
}
</style>
