<template>
  <form class="login-form">
    <div class="login-form__title">
      Вход
    </div>
    <div class="login-form__field">
      <text-input
        class="text-input--smaller"
        name="email"
        label="Логин"
        placeholder="E-mail"
        input-type="email"
        v-model="form.email"
        :error="$v.form.email.$error"
      />
      <div class="login-form__error" v-if="$v.form.email.$error && !$v.form.email.required">Обязательное поле</div>
      <div class="login-form__error" v-if="$v.form.email.$error && !$v.form.email.email">Неправильный e-mail</div>
    </div>
    <div class="login-form__field">
      <text-input
        class="text-input--smaller"
        name="password"
        label="Пароль"
        placeholder="Пароль"
        v-model="form.password"
        input-type="password"
        :error="$v.form.password.$error"
        isPassword
      />
      <div class="login-form__error" v-if="$v.form.password.$error && !$v.form.password.required">Обязательное поле</div>
      <div class="login-form__error" v-if="$v.form.password.$error && !$v.form.password.minLength">Должен содержать не менее 8 символов</div>
    </div>
    <div class="login-form__bottom">
      <button
        class="button button--base"
        type="button"
        @click.prevent="submitForm"
      >
        Войти
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import TextInput from "./TextInput";
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "LoginForm",
  mixins: [validationMixin],
  components: { TextInput },
  props: {
    action: {
      type: String,
      default: ""
    },
    bus: {
      type: Object,
    }
  },
  data() {
    return {
      formSent: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  validations: {
    form: {
      email: { required, email },
      password: { required, minLength: minLength(8) },
    }
  },
  created() {
    this.bus.$on('submitLogin', () => {
      this.submitForm();
    });
  },
  methods: {
    async submitForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log('error');
      } else {
        const formData = new FormData();
        formData.append('email', this.form.email);
        formData.append('password', this.form.password);
        formData.append('acceptPolicy', this.acceptPolicy ? 1 : 0);

        const options = {
          url: '/',
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: formData
        };

        const { data } = await axios(options);

        if (
          typeof data.errors !== 'undefined'
          && typeof data.errors[0] !== 'undefined'
          && data.errors[0].customData === 'acceptPolicy'
        ) {
          this.$emit('showAcceptPolicyModal');
        }

        if (data.status === 'error') {
          console.log(data.errors);

        } else {
          this.reset();
          try {
            window.location.reload();
          } catch (exception) {
            window.location.reload();
          }
        }
      }
    },
    reset() {
      this.$v.$reset();
      this.form.email = "";
      this.form.password = "";
    },
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".login-form";

#{$b} {
  width: 470px;
  background-color: $white-true;
  padding: 36px 40px 40px 36px;

  @include tablet {
    margin: 0 auto;
  }

  @include mobile {
    width: 100%;
    padding: 20px 15px;
  }

  &__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 26px;

    @include mobile {
      font-size: 26px;
    }
  }

  &__bottom {
    display: block;
    text-align: center;
    color: $color-black;
    padding-left: 4px;

    .button {
      display: flex;
      width: 100%;
    }
  }

  &__field {
    position: relative;
    padding-left: 4px;

    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  &__error {
    position: absolute;
    left: 0;
    top: calc(100% + 3px);
    font-size: 12px;
    line-height: 160%;
    color: $color-error;
  }
}
</style>
