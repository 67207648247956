<template>
  <div
    v-if="isVisible && IS_DETECT_DONE"
    class="location-btn"
    :class="`location-btn--mode-${mode}`"
    :style="rootStyle"
    @mouseleave="onMouseleave"
  >
    <div class="location-btn__btn" @click="openModal(true)" @mouseenter="onMouseenterBtn">
      <InlineSvg class="location-btn__btn-svg" src="location" />
      <div v-if="mode === 'menu' && withAnimation" class="location-btn__btn-caption">
        <span
          v-for="i in 5"
          :key="`location-btn__btn-caption-${i}`"
          class="location-btn__btn-text"
        >
          {{ regionState.caption }}
        </span>
      </div>
      <div v-else class="location-btn__btn-caption">
        {{ regionState.caption }}
      </div>
    </div>
    <div
      ref="locationBtnWindow"
      class="location-btn__window"
      :class="{ 'location-btn__window--is-visible': isShowWindow }"
    >
      <div class="location-btn__window-inner">
        <div class="location-btn__window-shape" />
        <div class="location-btn__window-caption">
          {{ regionState.captionWindow }}
        </div>
        <div class="location-btn__window-actions">
          <CustomButton
            v-if="regionState.status !== 3"
            theme="light"
            size="ml"
            @click="saveRegion"
          >
            Да, верно
          </CustomButton>
          <CustomButton
            :theme="regionState.status === 3 ? 'light' : 'outline-light'"
            size="ml"
            @click="changeRegion"
          >
            {{ regionState.changeRegionBtnText }}
          </CustomButton>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  inject: ['breakpoint'],
  props: {
    regions: {
      type: Array,
      default: () => []
    },
    actionSave: {
      type: String,
      default: ''
    },
    actionDetect: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'menu' // menu || burger
    }
  },
  data() {
    return {
      isShowWindow: false,
      canCloseFromMouseout: false,
    }
  },
  computed: {
    ...mapGetters('region', ['GET_REGION', 'GET_REGION_FROM_URL', 'GET_REGION_FROM_LC', 'IS_DETECT_DONE']),
    isVisible() {
      return this.mode === 'menu'
        ? this.breakpoint.isDesktop
        : this.breakpoint.isTablet || this.breakpoint.isMobile
    },
    regionState() {
      if (this.GET_REGION) {
        return {
          caption: this.GET_REGION.name,
          captionWindow: this.GET_REGION.name,
          status: 1,
          changeRegionBtnText: 'Нет, другой'
        }
      } else {
        return {
          caption: 'Не удалось определить регион',
          captionWindow: 'Не удалось определить регион',
          status: 3,
          changeRegionBtnText: 'Выбрать регион'
        }
      }
    },
    withAnimation() {
      return this.regionState.status !== 3 && this.regionState?.caption?.length > 18
    },
    animationTime() {
      return this.withAnimation
        ? Math.floor(this.regionState?.caption?.length / 3.5)
        : 0
    },
    rootStyle() {
      return {
        '--animation-time': `${this.animationTime}s`
      }
    }
  },
  methods: {
    ...mapActions('region', ['DETECT_REGION', 'SAVE_REGION']),
    ...mapActions('modal', ['OPEN_MODAL']),
    ...mapMutations('region', ['SET_REGION_LIST', 'SET_DETECT_DONE']),
    openModal(changeLocation = false) {
      this.OPEN_MODAL({ name: 'location-window', props: { changeLocation } })
    },
    saveRegion() {
      this.SAVE_REGION(this.GET_REGION)
      window.location.reload()
    },
    setStateShowWindow(state, closeFromMouseout = false) {
      this.isShowWindow = state
      this.canCloseFromMouseout = closeFromMouseout
    },
    changeRegion() {
      this.setStateShowWindow(false)
      this.openModal(true)
    },
    onMouseenterBtn() {
      if (this.isShowWindow || this.regionState.status === 1) {
        return
      }
      this.setStateShowWindow(true, true)
    },
    onMouseleave() {
      if (!this.canCloseFromMouseout) {
        return
      }

      this.setStateShowWindow(false)
    },
    closeWindowByScroll() {
      const bottom = this.$refs.locationBtnWindow?.getBoundingClientRect()?.bottom || window.scrollY - 300

      if (bottom <= 0) {
        this.setStateShowWindow(false)
      }
    },
    closeWindowByClickout(e) {
      const isTarget = e.target.classList.contains('location-btn')
      const isClosest = e.target.closest('.location-btn')

      if (!isTarget && !isClosest) {
        this.setStateShowWindow(false)
      }
    }
  },
  async created() {
    if (!this.isVisible) {
      return
    }

    this.SET_REGION_LIST(this.regions)

    await this.DETECT_REGION(this.actionDetect)

    if (this.regionState.status === 3) {
      if (this.mode === 'menu') {
        this.setStateShowWindow(true)
      } else {
        this.openModal()
      }
    }
  },
  mounted() {
    if (this.mode === 'burger') {
      return
    }

    window.addEventListener('scroll', this.closeWindowByScroll)
    window.addEventListener('click', this.closeWindowByClickout)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.closeWindowByScroll)
    window.removeEventListener('click', this.closeWindowByClickout)
  },
}
</script>


<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.location-btn';

#{$b} {
  font-family: $font-family-inter;
  position: relative;

  // .location-btn__btn
  &__btn {
    font-size: 14px;
    color: $white-true;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    max-width: 100%;

    @include tablet {
      font-size: 16px;
      padding: 10px;
      border-radius: 24px;
      border: 1px solid $white-true;
    }

    // .location-btn__btn-svg
    &-svg {
      width: 100%;
      height: 100%;
      max-width: 14px;
      max-height: 14px;
      margin-right: 8px;

      svg {
        width: 14px;
        height: 14px;
        max-width: 14px;
        max-height: 14px;
      }
    }

    // .location-btn__btn-caption
    &-caption {
      #{$b}--mode-menu & {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    // .location-btn__btn-text
    &-text {
      display: inline-block;
      animation: animate-text var(--animation-time) linear infinite;
      animation-direction: reverse;
      padding: 0 16px;
    }
  }

  // .location-btn__window
  &__window {
    width: 292px;
    position: absolute;
    top: calc(100% - 1px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    padding-top: 12px;
    transition: $transtion-default;

    #{$b}--mode-burger & {
      display: none;
    }

    &:not(#{$b}__window--is-visible) {
      opacity: 0;
      pointer-events: none;
      touch-action: none;
    }

    // .location-btn__window-inner
    &-inner {
      position: relative;
      padding: 16px;
      border-radius: 16px;
      background-color: $color-base-origin;
      color: $white-true;
    }

    // .location-btn__window-shape
    &-shape {
      position: absolute;
      width: 21px;
      height: 21px;
      left: 50%;
      top: -8px;
      pointer-events: none;
      touch-action: none;
      transform: translateX(-50%);

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $color-base-origin;
        border-radius: 5px;
        transform: rotate(45deg);
      }
    }

    // .location-btn__window-caption
    &-caption {
      text-align: center;
      color: inherit;
      font-weight: 500;
      margin-bottom: 20px;
    }

    &-actions {
      display: flex;
      align-items: center;

      & > * {
        flex: 1 0;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

@keyframes animate-text {
  from {
    transform: translate(0);
  } to {
    transform: translate(100%);
  }
}
</style>
