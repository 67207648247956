import axios from 'axios';

export default {
  data() {
    return {
      pagination: {
        page: 0,
        totalCount: 0,
        count: 6,
      },
      btnStatus: '',
    }
  },
  computed: {
    isExistsBtnLoadMore() {
      return this.pagination.totalCount > this.pagination.count * this.pagination.page
    },
  },
  methods: {
    async loadMore(state) {
      this.btnStatus = 'loading'
      const pageQuery = `page=${this.pagination.page + 1}`
      await this.loadPage(pageQuery, state)
      this.btnStatus = ''
    },
    async loadPage(additionalQuery, withClear = true) {
      const response = await axios.get(
        additionalQuery ? `${this.getUrlForLoadPage}&${additionalQuery}` : this.getUrlForLoadPage
      )

      if (response.data.status === 'success') {
        this.pageList = withClear ? response.data.data.items : [...this.pageList, ...response.data.data.items]
        this.pagination = { ...this.pagination, ...response.data.data.pagination }
      }
    },
  }
}
