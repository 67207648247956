<template>
  <div v-if="Boolean(filters.length)" class="report-filter">
    <FilterBtn
      v-for="(item, i) in filters"
      :key="`report-filter__item-${i}`"
      class="report-filter__item"
      :isActive="item.isActive"
      @click="toggleFilter(item, i)"
    >
      {{ item.name }}
    </FilterBtn>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    toggleFilter(_, i) {
      if (this.filters[i].isActive === true) {
        this.filters[i].isActive = !this.filters[i].isActive;
        this.filters[0].isActive = true;
      } else {
        this.filters.forEach(filter => {
          filter.isActive = false;
        });
        this.filters[i].isActive = true;
      }

      this.$emit('onFiltered')
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.report-filter';

#{$b} {
  display: flex;
  align-items: center;
  overflow-x: auto;
  @include removeScrollBar;

  @include mobile {
    margin: 0 (-$container-pd-left-mobile);
    padding: 0 $container-pd-left-mobile;
  }

  // .report-filter__item
  &__item {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
</style>
