import customScrollTo from '/frontend/components/helpers/customScrollTo.js'

export default {
  inject: ['breakpoint'],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultVideoObject: {
        id: 0,
        src: '',
        poster: ''
      },
      activeIndex: 0,
      prevInnerScroll: 0,
      scrollGap: 0,
      isScrollBlocked: false,
    }
  },
  computed: {
    loadedIndexesList() {
      const canLoadVideoIndexes = [
        this.activeIndex - 1,
        this.activeIndex,
        this.activeIndex + 1
      ]
      return canLoadVideoIndexes
    },
  },
  methods: {
    isCanLoadVideo(index) {
      return this.loadedIndexesList.includes(index)
    },
    getStoriesRef(index) {
      return this.$refs[`storiesCard${index}`][0]
    },
    setStoriesState(state, storiesRef) {
      const method = state ? 'play' : 'pause'
      const stories = storiesRef || this.currentStoriesRef

      stories[method]()
    },
    onClickStories(index) {
      this.isInteractive = true

      if (!this.currentStoriesRef) {
        return
      }

      if (index !== this.activeIndex) {
        this.goToNextStories(index)
        return
      }

      const isPaused = this.currentStoriesRef.$refs.video.paused
      this.setStoriesState(isPaused)
    },
    storiesEnded(index) {
      this.goToNextStories(index + 1)
    },
    goToNextStories(index = this.activeIndex + 1) {
      if (this.isScrollBlocked) {
        return
      }
      const prevStoriesIndex = this.activeIndex
      this.setActiveIndex(index)
      this.scrollToCurrentStories()

      if (this.activeIndex !== prevStoriesIndex && this.isInteractive) {
        this.setStoriesState(false, this.getStoriesRef(prevStoriesIndex))
        this.setStoriesState(true)
      }
    },
    setActiveIndex(newIndex) {
      const min = 0
      const max = this.list.length - 1

      this.activeIndex = newIndex <= min ? min : newIndex >= max ? max : newIndex
    },
    scrollToCurrentStories() {
      if (!this.currentStoriesRef || !this.$refs.inner) {
        return
      }

      const innerOffsetTop = this.$refs.inner.offsetTop
      const storiesOffsetTop = this.currentStoriesRef.$el.offsetTop
      const result = storiesOffsetTop - innerOffsetTop - this.scrollGap

      this.scrollTo(this.$refs.inner, result, 500)
    },
    scrollTo(container, to, duration) {

      if (this.isScrollBlocked) {
        return
      }

      this.isScrollBlocked = true
      customScrollTo(container, to, duration).then(() => {
        const time = this.hasLodashThrottle ? 100 + 10 : 0
        setTimeout(() => {
          this.isScrollBlocked = false
        }, time); // ожидаем throttle лодаша
      })
    },
    getVideo(i, stories) {
      return this.isCanLoadVideo(i) ? stories : { ...stories, src: '' }
    }
  }
}
