<template>
  <div class="tour-form">
    <template v-if="isVerifyStep">
      <div class="tour-form__verify">
        <div class="tour-form__verify-header">
          <div class="tour-form__verify-line">
            <b>Операторам персональных данных (далее – Операторы):</b>
          </div>
          <div class="tour-form__verify-line">
            Автономная некоммерческая организация «Национальные приоритеты», 121069, г. Москва, ул.Б.Молчановка, 21А, ОГРН 1197700017415, ИНН 9704007633, КПП 770401001
          </div>
          <div
            v-for="item in getRequisites"
            :key="item.name"
            class="tour-form__verify-line"
            v-html="item"
          />
        </div>
        <div class="tour-form__verify-user">
          От
          <br>
          фамилия, имя, отчество:  {{ form.fio }}
          <br>
          номер телефона: {{ form.phone }}
          <br>
          адрес электронной почты: {{ form.email }}
        </div>
        <div class="tour-form__verify-content">
          <div class="tour-form__verify-caption title-md tt-uppercase">
            Согласие на обработку персональных данных
          </div>
          <p class="tour-form__verify-text">Я, {{ form.fio }}, имея намерение принять участие в мероприятии Акции «Наука рядом», в соответствии с требованиями ст. 9 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», даю согласие на обработку моих персональных данных Операторами с целью моего участия в мероприятии Акции «Наука рядом», проводимой в рамках Десятилетия науки и технологий в России.
            Перечень моих персональных данных, передаваемых Операторам на обработку: фамилия, имя и отчество, дата рождения, адрес электронной почты, номер телефона.
            Я даю согласие на обработку Операторами своих персональных данных, а именно на совершение следующих действий: сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, блокирование, уничтожение персональных данных.
            Обработка моих персональных данных может осуществляться как с применением средств автоматизации, так и без применения таких средств.
            Настоящее согласие действует до достижения целей обработки либо до моего отзыва. Настоящее согласие может быть мной отозвано в любой момент путем направления соответствующего письменного заявления Операторам по указанным в настоящем согласии адресам.
            Я уведомлен(а), что Операторы вправе продолжить обработку персональных данных в случаях, предусмотренных действующим законодательством.
            Я по письменному запросу имею право на получение информации, касающейся обработки моих персональных данных (в соответствии со ст. 14 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных»).
            Подтверждаю, что ознакомлен(а) с положениями Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», права и обязанности в области защиты персональных данных мне разъяснены.
            Я также даю свое согласие на использование Оператором АНО «Национальные приоритеты» моего изображения (с указанием фамилии, имени, отчества или без указания таковых) путем опубликования и доведения до всеобщего сведения посредством размещения в сети Интернет, на телевидении, на сайтах - https://национальныепроекты.рф, https://национальныеприоритеты.рф, https://наука.рф, в социальных сетях в сети Интернет. Срок использования изображения – 3 (три) года с момента выдачи настоящего согласия. Территория использования – территория всего мира.
          </p>
        </div>
        <div class="tour-form__verify-actions">
          <div class="tour-form__verify-action">
            <CustomButton
              class="tour-form__btn"
              theme="primary"
              size="md"
              :status="statusBtn"
              @click="onSubmit"
            >
              Записаться
            </CustomButton>
          </div>
          <div class="tour-form__verify-action">
            <CustomButton
              class="tour-form__btn"
              theme="outline-primary"
              size="md"
              :status="statusBtn"
              @click="isVerifyStep = false"
            >
              Вернуться
            </CustomButton>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="tour-form__fields" :class="{'tour-form__fields--gap-large': isInvalidForm}">
        <div class="tour-form__field">
          <u-text-input
            class="u-text-input--smaller"
            name="fio"
            label="ФИО*"
            v-model="form.fio"
            placeholder="ФИО"
            :error="isRequiredErrorField('fio')"
            @input="onCheckValidate('fio')"
            @blur="onCheckValidate('fio')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('fio')"
            class="tour-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
        <div class="tour-form__field u-select u-select--small">
          <div class="tour-form__label">
            Выберите категорию*
          </div>
          <div>
            <v-select
              ref="selectCategory"
              v-model="form.category"
              :class="{ 'vs--error': isRequiredErrorField('category') }"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="categoryOptions"
              placeholder="Выберите категорию"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
            <ErrorFormWrapper
              :isVisible="isRequiredErrorField('category')"
              class="tour-form__error-field"
            >
              <div>Обязательное поле</div>
            </ErrorFormWrapper>
          </div>
        </div>
        <div class="tour-form__field">
          <CustomCalendar
            v-model="form.dateOfBirth"
            :minDate="minDate"
            :maxDate="maxDate"
            subtitle="Дата рождения*"
            :isFormTheme="true"
            :is-error="isRequiredErrorField('dateOfBirth') || isDateRangeError('dateOfBirth')"
            @blur="onCheckValidate('dateOfBirth')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('dateOfBirth') || isDateRangeError('dateOfBirth')"
            class="tour-form__error-field"
            :maxHeight="100"
          >
            <div v-show="isRequiredErrorField('dateOfBirth')">Обязательное поле</div>
            <div v-show="isDateRangeError('dateOfBirth')">{{ getYearValidateCaption }}</div>
          </ErrorFormWrapper>
        </div>
        <div class="tour-form__field">
          <u-text-input
            class="u-text-input--smaller"
            name="byWhomIssued"
            label="Мобильный номер*"
            v-model="form.phone"
            placeholder="+7 (999) 999 99 99"
            mask="+7 (###) ###-##-##"
            :error="isRequiredErrorField('phone') || isPhoneErrorField('phone')"
            @input="onCheckValidate('phone')"
            @blur="onCheckValidate('phone')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('phone') || isPhoneErrorField('phone')"
            class="tour-form__error-field"
          >
            <div v-show="isRequiredErrorField('phone')">Обязательное поле</div>
            <div v-show="isPhoneErrorField('phone') && !isRequiredErrorField('phone')">Введите номер полностью</div>
          </ErrorFormWrapper>
        </div>
        <div class="tour-form__field">
          <u-text-input
            class="u-text-input--smaller"
            name="email"
            label="E-mail*"
            v-model="form.email"
            placeholder="..."
            :error="isRequiredErrorField('email') || isEmailErrorField('email') || isGmailErrorField('email')"
            @input="onCheckValidate('email')"
            @blur="onCheckValidate('email')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('email') || isEmailErrorField('email') || isGmailErrorField('email')"
            class="tour-form__error-field"
          >
            <div v-show="isRequiredErrorField('email')">Обязательное поле</div>
            <div v-show="isEmailErrorField('email')">Неправильный e-mail</div>
            <div v-show="isGmailErrorField('email') && !isEmailErrorField('email')">Укажите почту в российской зоне .ru</div>
          </ErrorFormWrapper>
        </div>
      </div>
      <div class="tour-form__caption">
        Поля отмеченные знаком* обязательны для заполнения
      </div>
      <div class="tour-form__checkboxes">
        <div
          class="tour-form__checkboxes-item"
          :class="{'tour-form__checkboxes-item--is-error': isCheckedErrorField('rules')}"
        >
          <CustomCheckbox v-model="form.rules" :error="isCheckedErrorField('rules')" @change="onCheckValidate('rules')">
            Согласен с <a class="u-link u-link--base" href="/upload/documents/Правила_наукарядом.docx" target="_blank">правилами</a> участия в акции
          </CustomCheckbox>
          <ErrorFormWrapper
            :isVisible="isCheckedErrorField('rules')"
            class="tour-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
        <div
          class="tour-form__checkboxes-item"
          :class="{'tour-form__checkboxes-item--is-error': isCheckedErrorField('policy')}"
        >
          <CustomCheckbox v-model="form.policy" :error="isCheckedErrorField('policy')" @change="onCheckValidate('policy')">
            Согласен с <a class="u-link u-link--base" href="/upload/documents/Политика_конфиденциальности_наука_рф.pdf" target="_blank">политикой конфиденциальности</a>
          </CustomCheckbox>
          <ErrorFormWrapper
            :isVisible="isCheckedErrorField('policy')"
            class="tour-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
        <div
          class="tour-form__checkboxes-item"
          :class="{'tour-form__checkboxes-item--is-error': isCheckedErrorField('userPolicy')}"
        >
          <CustomCheckbox v-model="form.userPolicy" :error="isCheckedErrorField('userPolicy')" @change="onCheckValidate('userPolicy')">
            Согласен с <a class="u-link u-link--base" href="/upload/documents/Пользователское_соглашение_наука_рф.pdf" target="_blank">пользовательским соглашением</a>
          </CustomCheckbox>
          <ErrorFormWrapper
            :isVisible="isCheckedErrorField('userPolicy')"
            class="tour-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
      </div>
      <ErrorFormWrapper
        class="tour-form__feedback-errors"
        :max-height="1000"
        :isVisible="Boolean(errorsAfterSubmit) && Boolean(errorsAfterSubmit.length)"
      >
        <div
          v-for="error in errorsAfterSubmit"
          :key="`tour-form__feedback-error-${error.code}`"
          class="tour-form__feedback-error"
        >
          <span v-html="error.message" />
        </div>
      </ErrorFormWrapper>
      <div class="tour-form__actions">
        <CustomButton
          class="tour-form__btn"
          theme="primary"
          size="md"
          :status="statusBtn"
          @click="onSubmitHandler"
        >
          Записаться
        </CustomButton>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { validationMixin } from "vuelidate";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { getResetForm } from '@/components/helpers/formHelpers.js'
import { checkGmail, dateInRange } from '@/components/helpers/validate.js'

export default {
  inject: ['ymHandler'],
  mixins: [validationMixin],
  components: {vSelect},
  props: {
    categoryOptions: {
      type: Array,
      default: () => []
    },
    submitUrl: {
      type: String,
      default: ''
    },
    decreaseSlotsCardHandler: {
      type: Function,
      required: true
    },
    excursionId: {
      type: String,
      default: ''
    },
    requisites: {
      type: Array,
      default: () => []
    },
    yearLimit: {
      type: Object,
      default: () => ({max: null, min: null})
    }
  },
  data() {
    return {
      form: {
        fio: '',
        category: '',
        dateOfBirth: '',
        phone: '',
        email: '',
        rules: false,
        policy: false,
        userPolicy: false,
      },
      isInvalidForm: false,
      btnStatus: '',
      errorsAfterSubmit: [],
      maxDate: null,
      minDate: null,
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      statusBtn: '',
      isVerifyStep: false,
    }
  },
  validations() {
    return {
      form: {
        fio: { required },
        category: { required },
        dateOfBirth: { required, inRange: this.dateInRangeValidate },
        phone: { required, phone: minLength(18)},
        email: { required, email, isGmail: checkGmail },
        rules: { checked: sameAs( () => true ) },
        policy: { checked: sameAs( () => true ) },
        userPolicy: { checked: sameAs( () => true ) },
      }
    }
  },
  computed: {
    getFormData() {
      const data = new FormData()
      data.append('excursion', this.excursionId)

      for (let prop in this.form) {
        data.append(prop, this.form[prop])
      }

      return data
    },
    getRequisites() {
      return this.requisites
        .map(item => {
          const name = item.name ? `${item.name}` : ''
          const address = item.address ? `${item.address}` : ''
          const ogrn = item.ogrn ? `ОГРН ${item.ogrn}` : ''
          const inn = item.inn ? `ИНН ${item.inn}` : ''
          const kpp = item.kpp ? `КПП ${item.kpp}` : ''
          const arr = [name,address,ogrn,inn,kpp]

          return arr
            .filter(item => item)
            .join(', ')
        })
        .filter(item => item)
    },
    getDateLimit() {
      const yMax = this.yearLimit.max ? Number(this.yearLimit.max) : null
      const yMin = this.yearLimit.min ? Number(this.yearLimit.min) : null

      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth()
      const currentDay = currentDate.getDate()
      const maxDate = yMax ? new Date(currentYear - yMax, currentMonth, currentDay) : null
      const minDate = yMin ? new Date(currentYear - yMin, currentMonth, currentDay) : null

      return {
        min: minDate,
        max: maxDate,
      }
    },
    getYearValidateCaption() {
      const max = this.yearLimit.max
      const min = this.yearLimit.min

      const minText = min ? `от ${min}` : ''
      const maxText = max ? `до ${max}` : ''
      const captionYear = this.getYearErrorCaption(max || min)
      const separatorYear = {
        start: min && max ? '(' : '',
        end: min && max ? ')' : ''
      }

      return `
        К сожалению, вы не можете записаться на это мероприятие: организатор установил ограничения по возрасту ${separatorYear.start}${minText} ${maxText} ${captionYear}${separatorYear.end}
      `
    },
  },
  methods: {
    resetForm() {
      this.form = getResetForm(this.form)
    },
    async onSubmitHandler() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.isInvalidForm = true
        return
      }
      this.isInvalidForm = false
      this.isVerifyStep = true
    },
    async onSubmit() {
      this.btnStatus = 'loading'

      const data = this.getFormData

      const options = {
        url: this.submitUrl,
        method: 'POST',
        data
      };

      const response = await axios(options);

      if (response.data.status === 'success') {
        this.succesResponse()
      } else {
        this.errorResponse(response)
      }
    },
    succesResponse() {
      this.statusBtn = 'success'
      this.$v.$reset();
      this.resetForm()
      this.errorsAfterSubmit = []

      if (this.decreaseSlotsCardHandler && typeof decreaseSlotsCardHandler === 'function') {
        this.decreaseSlotsCardHandler();
      }

      this.statusBtn = 'loading'
      this.$root.$refs.customModal.passContent({
        name: 'tour-form-success',
        title: '',
      });

      this.ymHandler({ code: 95339628, type: 'reachGoal', operation: 'click_formSignUp' })
    },
    errorResponse(response) {
      this.isVerifyStep = false
      this.statusBtn = ''
      this.errorsAfterSubmit = response.data.errors
    },
    isRequiredErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].required
    },
    isCheckedErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].checked
    },
    isEmailErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].email
    },
    isGmailErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].isGmail
    },
    isPhoneErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].phone
    },
    dateInRangeValidate(val) {
      return dateInRange(val, this.getDateLimit.min, this.getDateLimit.max)
    },
    isDateRangeError(field) {
      return this.$v.form[field].$error && !this.$v.form[field].inRange
    },
    getYearErrorCaption(year) {
      return (year === 1 || year % 10 === 1 && year !== 11) ? 'года' : 'лет';
    },
    onCheckValidate(name) {
      this.$v.form[name].$touch()
      if (this.$v.$invalid) {
        this.isInvalidForm = true
        return
      }
      this.isInvalidForm = false
    },
    criticalValidateForVueSelect() {
      this.onCheckValidate('category')
    },
  },
  created() {
    const minDate = new Date(1900, 0, 1)
    const maxDate = new Date()
    this.minDate = minDate.toLocaleDateString('ru-RU')
    this.maxDate = maxDate.toLocaleDateString('ru-RU')
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.selectCategory && this.$refs.selectCategory.$refs.search) {
        this.$refs.selectCategory.$refs.search.addEventListener('blur', this.criticalValidateForVueSelect)
      }
    })
  },
  beforeDestroy() {
    if (this.$refs.selectCategory && this.$refs.selectCategory.$refs.search) {
      this.$refs.selectCategory.$refs.search.removeEventListener('blur', this.criticalValidateForVueSelect)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.tour-form';

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;

  // .tour-form__fields
  &__fields {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, calc(50% - 16px));
    gap: 32px;
    transition: $transtion-default;

    @include tablet {
      grid-template-columns: repeat(2, calc(50% - 8px));
      gap: 16px;
    }

    @include mobile {
      display: block;
    }

    // .tour-form__fields--gap-large
    &--gap-large {
      gap: 50px 32px;

      @include tablet {
        gap: 60px 16px;
      }
    }
  }

  // .tour-form__field
  &__field {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    // .tour-form__field--space-between
    &--space-between {
      justify-content: space-between;
    }

    &:not(:last-child) {
      @include mobile {
        margin-bottom: 32px;
      }
    }

    // .tour-form__field-el
    &-el {
      margin-left: 8px;
    }
  }

  // .tour-form__error-field
  &__error-field {
    @include mobile-min {
      position: absolute;
      top: 100%;
    }
  }

  // .tour-form__label
  &__label {
    font-family: $font-family-inter;
    font-size: 12px;
    line-height: 1.3;
    text-transform: initial;
    margin-bottom: 8px;
    margin-left: 8px;
    font-weight: normal;

    &--lg-gap {
      margin-bottom: 17px;

      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  // .tour-form__caption
  &__caption {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.3;
    color: $color-danger-2;
    margin: 32px 0;
  }

  // .tour-form__feedback-error
  &__feedback-error {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 15px;

    @include mobile {
      font-size: 18px;
    }

    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }

  // .tour-form__checkboxes
  &__checkboxes {

    // .tour-form__checkboxes-item
    &-item {
      position: relative;
      transition: $transtion-default;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &--is-error {
        @include mobile-min {
          margin-bottom: 40px !important;
        }
      }
    }
  }

  // .tour-form__actions
  &__actions {
    margin-top: 32px;
  }

  // .tour-form__verify
  &__verify {

    // .tour-form__verify-header
    &-header {
      margin-bottom: 32px;
    }

    // .tour-form__verify-line
    &-line {
      padding-bottom: 8px;
      border-bottom: 1px solid $color-base-origin;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    // .tour-form__verify-user
    &-user {
      margin-bottom: 32px;
    }

    // .tour-form__verify-content
    &-content {
      margin-bottom: 32px;
    }

    // .tour-form__verify-caption
    &-caption {
      text-align: center;
      margin-bottom: 16px;
    }

    // .tour-form__verify-actions
    &-actions {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: -5px;
    }

    &-action {
      padding: 5px;
    }
  }
}
</style>
