<template>
  <div class="custom-checkbox-button">
    <input
      :id="`${name}-${id}`"
      type="checkbox"
      class="custom-checkbox-button__input"
      :name="name"
      :value="val"
      @input="onInput"
    >
    <label
      :for="`${name}-${id}`"
      class="custom-checkbox-button__label"
    >
      <span>#<slot></slot></span>
      <span class="custom-checkbox-button__icon">
        <inline-svg class="custom-checkbox-button__svg" src="close-bold"/>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      require: true
    },
    name: {
      type: String,
      require: true
    },
    value: {
      type: Array,
      require: true
    },
    val: {
      type: String,
      require: true
    }
  },
  methods: {
    onInput(e){
      if (e.target.checked) {
        this.$emit('input', [...this.value, e.target.value])
      } else {
        this.$emit('input', [...this.value.filter(id => id != this.val)])
      }
    }
  }
}
</script>

<style lang="scss">
@import 'frontend/scss/base/u-includes';

.custom-checkbox-button {
  // .custom-checkbox-button__input
  &__input{
    display: none;
    &:checked{
      & ~ .custom-checkbox-button__label{
        background-color: $color-base;
        border-color: $color-base;
        padding-right: 11px;
        color:$white-true;

        .custom-checkbox-button__icon{
          display: inline-block;
        }
      }
    }
  }

  // .custom-checkbox-button__label
  &__label{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 20px;
    padding: 10px 22px ;
    background-color: $white-true;
    border: 1px solid $white-true;
    font-size: 16px;
    line-height: 1;
    color: rgba($black-true, 0.5);
    font-weight: 500;
    user-select: none;
    cursor: pointer;
    transition: $transtion-default;
    &:hover {
      @include mobile-min {
        border-color: $color-base;
      }
    }
  }

  // .custom-checkbox-button__icon
  &__icon{
    max-width: 18px;
    max-height: 18px;
    margin-left: 8px;
    display: none;
  }
}
</style>
