<template>
  <div class="suggest-talisman-btn" @click="onSuggestTalisman">
    <slot />
  </div>
</template>

<script>
import axios from "axios";

export default {
  inject: ['ymHandler'],
  props: {
    metricPos: {
      type: String,
      default: 'top', // top | middle | bottom
    }
  },
  data() {
    return {
      actionSubmit: '/bitrix/services/main/ajax.php?mode=class&c=dalee:talisman.works&action=submit',
      actionGetData: '/bitrix/services/main/ajax.php?mode=class&c=dalee:talisman.works&action=getData',
      metricOperations: {
        top: 'сlick_SuggestTalismanTop',
        middle: 'сlick_SuggestTalismanMiddle',
        bottom: 'сlick_SuggestTalismanBottom',
      }
    }
  },
  methods: {
    async onSuggestTalisman() {
      this.ymHandler( {operation: this.metricOperations[this.metricPos] })
      this.$root.$refs.customModal.openModal(null, null, true, true);
      const propsData = await this.getPropData()

      this.$root.$refs.customModal.passContent({
        name: 'talisman-work-window',
        title: '',
        props: {
          actionSubmit: this.actionSubmit,
          categoryOptions: propsData.category,
          regionOptions: propsData.regions,
          siteKey: propsData.clientKey,
        }
      })
    },
    async getPropData() {
      const response = await axios.get(this.actionGetData)

      if (response.data.status === 'success') {
        return response.data.data
      }
    },
  }
}
</script>

<style lang="scss">
$b: '.suggest-talisman-btn';

#{$b} {
  display: inline-block;
}
</style>
