var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-dropdown",class:[
      {
        'custom-dropdown--is-opened': _vm.isOpened,
        'custom-dropdown--multiply': _vm.multiply,
      },
      `custom-dropdown--theme-${_vm.theme}`
    ]},[_c('div',{staticClass:"custom-dropdown__inner"},[_c('div',{staticClass:"custom-dropdown__caption",class:{'custom-dropdown__caption--is-placeholder': _vm.caption.isPlaceholder},on:{"click":_vm.toggle}},[_c('div',{staticClass:"custom-dropdown__caption-text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.caption.text)}}),(_vm.multiply && _vm.value.length > 1)?_c('span',[_vm._v(" +"+_vm._s(_vm.value.length - 1)+" ")]):_vm._e()]),_c('div',{staticClass:"custom-dropdown__caption-arrow"},[_c('inline-svg',{staticClass:"custom-dropdown__caption-icon",attrs:{"src":"u-arr-down"}})],1)]),_c('div',{staticClass:"custom-dropdown__list"},[(_vm.canSelectAll)?_c('div',{staticClass:"custom-dropdown__item",class:{'custom-dropdown__item--is-active': (!_vm.value || !_vm.value.length)},domProps:{"innerHTML":_vm._s(_vm.placeholder)},on:{"click":function($event){return _vm.select('all')}}}):_vm._e(),_vm._l((_vm.options),function(item){return _c('div',{key:`custom-dropdown__item-${item.id}`,staticClass:"custom-dropdown__item",class:{'custom-dropdown__item--is-active': _vm.isActiveOption(item.id)},domProps:{"innerHTML":_vm._s(item.name)},on:{"click":function($event){return _vm.select(item.id)}}})})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }