<template>
  <section class="talisman-regulations">
    <UFigures :items="figures" :full-width="true" position="static"/>
    <div class="u-g-container">
      <div class="talisman-regulations__inner">
        <h2 v-if="title && !isHiddenTitle" class="talisman-regulations__title tt-uppercase title-xl">
          {{ title }}
        </h2>
        <TalismanTimelaps
          class="talisman-regulations__timelaps"
          theme="block"
          :timelapsInitial="timelapsInitial"
        />
        <div class="talisman-regulations__body">
          <div class="talisman-regulations__accordions">
            <accordion
              v-for="accordion in regulationsList"
              :key="`talisman-regulations__accordion-${accordion.id}`"
              class="u-accordion"
              :close-all="false"
            >
              <template #header>
                <h3 class="talisman-regulations__accordion-title title-lg tt-uppercase">
                  {{ accordion.title }}
                </h3>
              </template>
              <template #body>
                <div
                  v-for="(content, index) in accordion.content"
                  :key="`talisman-regulations__accordion-${index}`"
                  class="talisman-regulations__accordion-item"
                  v-html="content"
                />
              </template>
            </accordion>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    isHiddenTitle: {
      type: Boolean,
      default: false,
    },
    regulationsList: {
      type: Array,
      default: () => []
    },
    timelapsInitial: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      figures: [
        {
          name: 'f-talisman-regulations',
          image: '/frontend/assets/img/bg-shapes/circle-left-opacity.svg',
          animation: 'fade-in-left'
        }
      ]
    }
  },
  methods: {
    scrollBlock() {
      this.$el.scrollIntoView({ behavior: "smooth" });
    }
  },
  mounted() {
    this.$root.$on('scrollToRegulations', this.scrollBlock)
  },
  beforeDestroy() {
    this.$root.$off('scrollToRegulations', this.scrollBlock)
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-regulations';

#{$b} {
  position: relative;
  margin: 64px 0;

  @include mobile {
    margin: 48px 0;
  }

  // .talisman-regulations__inner
  &__inner {
    position: relative;
  }

  // .talisman-regulations__title
  &__title {
    margin: 0 0 32px 0;
  }

  // .talisman-regulations__timelaps
  &__timelaps {
    margin: 40px 0 32px 0;

    @include mobile {
      margin: 24px 0;
    }
  }

  .accordion__body {
    font-size: 18px;
    line-height: 1.5;
    padding: 20px 0 32px 0;

    @include mobile {
      font-size: 16px;
      line-height: 1.3;
      padding: 16px 16px 32px 16px;
    }
  }

  .accordion__header {
    @include mobile {
      padding: 32px 80px 32px 32px;

      &::after {
        right: 32px;
      }
    }
  }

  // .talisman-regulations__accordions
  &__accordions {
    a {
      color: $black-true;
      transition: $transtion-default;

      &:hover {
        @include mobile-min {
          color: $color-base-origin;
        }
      }
    }
  }

  // .talisman-regulations__accordion
  &__accordion {

    // .talisman-regulations__accordion-title
    &-title {
      margin: 0;

      @include mobile {
        font-size: 22px;
      }
    }

    // .talisman-regulations__accordion-item
    &-item {
      &:not(:last-child) {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid rgba($black-true, 0.15);

        @include mobile {
          padding-bottom: 16px;
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>
