import axios from 'axios'

export class PartnerCreaterController {
  create({ name, inn }) {
    try {
      const options = {
        method: 'post',
        url: '/local/ajax/createCompany.php',
        data: {
          name: name,
          inn: Number(inn),
          hash: Number(inn) * 33
        }
      }

      return axios(options)
    } catch(e) {
      throw new Error('partnerCreaterController:create', e)
    }
  }

  async find(inn) {
    try {
      const options = {
        method: 'post',
        url: '/local/ajax/findCompany.php',
        data: {
          inn: inn,
          hash: Number(inn) * 33
        }
      }

      const response = await axios(options);

      if (response?.data?.status === 'success' && response.data.data.create_partner) {
        return this.create({
          inn: response.data.data.partner.inn,
          name: response.data.data.partner.name,
        })
      } else {
        return response
      }
    } catch(e) {
      throw new Error('partnerCreaterController:find', e)
    }
  }
}
