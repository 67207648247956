<template>
  <div
    ref="wrapper"
    class="news-slider"
    :class="{ _presidium: isPresidium }"
    :style="{ marginRight: rightOffset }"
  >
    <swiper ref="newsSwiper" :options="swiperOptions">
      <slot />
    </swiper>
    <div class="news-slider__controls-wrapper" :class="{'news-slider__controls-wrapper--is-hidden': !isExistsSlides}">
      <div class="news-slider__controls">
        <div class="news-slider__prev">
          <inline-svg src="u-arrow-left" />
        </div>
        <div class="news-slider__count swiper-pagination"></div>
        <div class="news-slider__next">
          <inline-svg src="u-arrow-right" />
        </div>
      </div>
      <div class="news-slider__bullets g-pagination-bullets"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsSlider",
  props: {
    isPresidium: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      rightOffset: 0,
      lengthSlides: null,
      currentSlide: null,
      isExistsSlides: true,
    };
  },
  computed: {
    swiper() {
      return this.$refs.newsSwiper.$swiper;
    },
    swiperOptions() {
      if (this.isPresidium) {
        return {
          slidesPerView: "auto",
          spaceBetween: 0,
          loop: this.count >= 6,
          watchOverflow: true,
          watchSlidesVisibility: true,
          slideVisibleClass: "swiper-slide-visible",
          navigation: {
            prevEl: ".news-slider__prev",
            nextEl: ".news-slider__next",
            disabledClass: "is-disabled"
          },
          pagination: {
            el: ".news-slider__bullets",
            type: "bullets",
            bulletClass: "g-pagination-bullet",
            bulletActiveClass: "is-active",
            clickable: true
          },
          breakpoints: {
            1024: {
              pagination: {
                el: ".news-slider__count",
                type: "fraction"
              }
            }
          }
        }
      } else {
        return {
          slidesPerView: "auto",
          watchOverflow: true,
          watchSlidesVisibility: true,
          slideVisibleClass: "swiper-slide-visible",
          lazy: {
            loadPrevNext: true,
          },
          navigation: {
            prevEl: ".news-slider__prev",
            nextEl: ".news-slider__next",
            disabledClass: "is-disabled"
          },
          pagination: {
            el: ".news-slider__bullets",
            type: "bullets",
            bulletClass: "g-pagination-bullet",
            bulletActiveClass: "is-active",
            clickable: true
          },
          on: {
            init: function () {
              setTimeout(()=> {
                this.pagination.init();
                this.pagination.render();
                this.pagination.update();
              }, 0);
            }
          },
          breakpoints: {
            320: {
              slidesPerView: 1.2,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: "auto",
              spaceBetween: 30,
              pagination: {
                el: ".news-slider__count",
                type: "fraction"
              }
            },
            1024: {
              pagination: {
                el: ".news-slider__count",
                type: "fraction"
              }
            },
            1440: {
              pagination: {
                el: ".news-slider__count",
                type: "fraction"
              }
            }
          }
        }
      }
    },

  },
  mounted() {
    if (this.isPresidium) {
      let offset = window.innerWidth - (this.$refs.wrapper.getBoundingClientRect().left + this.$refs.wrapper.offsetWidth) - 100;
      if (offset > 0) {
        this.rightOffset = -offset + 'px'
      }
    }

    if (!this.swiper || this.swiper.slides.length < 2) {
      this.isExistsSlides = false
    }
  },
};
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: ".news-slider";

#{$b} {
  color: $color-black;
  position: relative;

  &._presidium {
    margin-left: -$offset-wrapper;
    @include desktop {
      margin-left: -$offset-wrapper-tablet;
    }
    @include tablet {
      margin-left: 0;
    }
  }

  &__inner {
    position: relative;
  }

  .swiper-container {
    overflow: visible;

    @include mobile {
      margin-bottom: 1.5rem;
    }
  }

  .swiper-slide {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-out;
    height: auto;
    background: rgba(0, 61, 255, 0.9);
    border-radius: 16px;

    &.swiper-slide-visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__controls {
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: inherit;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 10px 17px;

    @include mobile {
      display: none;
    }

    &-wrapper {
      position: absolute;
      right: 24px;
      bottom: 24px;
      z-index: 10;

      @include tablet {
        bottom: -68px;
      }

      @include mobile {
        position: relative;
        right: auto;
        bottom: auto;
      }

      &--is-hidden {
        opacity: 0;
        visibility: hidden;
      }

      .g-pagination-bullets {
        display: none;

        @include mobile {
          display: flex;
          justify-content: center;
        }

        .g-pagination-bullet {
          @include mobile {
            background-color: $black-true !important;
            width: 3px;
            height: 3px;
          }

          &:not(:last-child) {
            @include mobile {
              margin-right: 10px;
            }
          }
          &.is-active {
            @include mobile {
              width: 7px;
              height: 7px;
            }
          }
        }
      }
    }

    .inline-svg {
      height: 100%;
    }

    .swiper-pagination {
      position: static;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;
      white-space: nowrap;

      @include mobile {
        display: flex;
        align-items: center;
      }
    }
  }

  &__prev,
  &__next {
    outline: 0;
    text-decoration: none;
    user-select: none;
    cursor: pointer;
    flex-shrink: 0;
    width: 20.5px;
    height: 11px;
    transition: 0.3s;
    color: inherit;

    @include mobile {
      display: none;
    }

    svg {
      path {
        fill: transparent !important;
      }
    }

    @include desktop {
      width: 15.5px;
      height: 11px;
    }

    &:hover {
      &:not(.is-disabled) {
        color: $color-base;
      }
    }

    &.is-disabled {
      cursor: default;
      opacity: 0.35;
    }

    &.swiper-button-lock {
      display: none;
    }
  }

  &__bullets {

    @include tablet-min {
      display: none;
    }
  }

  &-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    position: relative;
    z-index: 0;
    padding: 21px 1.5rem 1.5rem 1.5rem;

    @include mobile {
      padding: 20px;
    }

    &__image {
      position: relative;
      top: 0;
      display: block;
      @include bg-cover;
      margin: -80px 0 35px 0;
      transition: top .2s linear;

      @include desktop {
        margin: -60px 0 24px 0;
      }

      @include mobile {
        margin-top: -40px;
      }

      &:before {
        content: "";
        display: block;
        padding-top: 56%;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
      color: #ffffff;
      margin-bottom: 2rem;

      @include low-desktop {
        margin-top: 14px;
        margin-bottom: 26px;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
    }

    &__title {
      width: 75%;
      display: block;
      font-weight: 500;
      font-size: 24px;
      line-height: 1.3;
      margin-bottom: 16px;
      word-break: break-word;

      @include low-desktop-min {
        @include lineClamp(4);
      }

      @include mobile {
        width: 100%;
        font-size: 16px;
      }
    }

    &__subtitle {
      width: 75%;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;

      @include low-desktop-min {
        @include lineClamp(9);
      }

      @include mobile {
        width: 100%;
        font-size: 11px;
      }
    }
  }

  .swiper-wrapper {
    @include low-desktop-min {
      min-height: 431px;
    }

    @include mobile {
      min-height: 320px;
    }
  }
}
</style>
