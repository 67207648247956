<template>
  <div class="brandstyle-typography">
    <div class="brandstyle-head">
      <div class="brandstyle-head__left">
        <h2 class="brandstyle-title" v-html="title"></h2>
      </div>
      <div class="brandstyle-head__right">
        <div class="brandstyle-head__labels">
          <custom-button
            v-for="(link,i) in links"
            :key="`brandstyle-panel__btn-${i}`"
            class="brandstyle-head__labels-btn"
            theme="outline-black"
            size="md"
            tag="a"
            :href="link.href"
            target="_blank"
            download
          >
            <inline-svg class="brandstyle-head__labels-icon" src="u-arr-download"></inline-svg>
            {{link.title}}
          </custom-button>
        </div>
      </div>
    </div>
    <div class="brandstyle-panel__text u-typography" v-html="description"></div>
    <div class="brandstyle-typography__items">
      <BrandstyleFont
        v-for="(item,i) in fontItems"
        :key="`brandstyle-typography__item-${i}`"
        :font="item"
        class="brandstyle-typography__item"
      />
    </div>
    <div class="brandstyle-typography__items-mob">
      <swiper ref="brandstyleTypographySwiper" :options="swiperOptions">
        <swiper-slide
          v-for="(item,i) in mobileListFontItems"
          :key="`brandstyle-typography__item-mob-${i}`"
        >
          <BrandstyleFont
            :font="item"
            class="brandstyle-typography__item-mob"
          />
        </swiper-slide>
      </swiper>
       <div class="brandstyle-typography__pagination">
        <div class="u-swiper-pagination brandstyle-typography__pagination-el"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    links: {
      type: Array,
      default: ()=> [
        {title:'Mulish.zip', href:'/upload/documents/brandstyle/Mulish.zip'},
      ]
    },
    description: {
      type: String,
      default: ''
    },
    fontItems: {
      type: Array,
      default: ()=> [
        {
          isPrimary: true,
          header: 'ОСНОВНЫЕ НАЧЕРТАНИЯ',
          items: [
            {
              word:'Аа',
              text:'Mulish Regular',
              fontWeight: 400
            }
          ]
        },
        {
          isPrimary: false,
          header: 'ДОПОЛНИТЕЛЬНЫЕ НАЧЕРТАНИЯ',
          items: [
            {
              word:'Аа',
              text:'Mulish Medium',
              fontWeight: 500
            },
            {
              word:'Аа',
              text:'Mulish Bold',
              fontWeight: 700
            },
            {
              word:'Аа',
              text:'Mulish Black',
              fontWeight: 900
            }
          ]
        },
      ]
    }
  },
  computed: {
    swiper() {
      return this.$refs.brandstyleTypographySwiper.$swiper
    },
    swiperOptions() {
      return {
        slidesPerView: 1.13,
        spaceBetween: 16,
        pagination: {
          el: ".brandstyle-typography__pagination-el",
          type: "bullets",
          bulletClass: "u-swiper-pagination__bullet",
          bulletActiveClass: "u-swiper-pagination__bullet--is-active",
          clickable: true
        },
      }
    },
    mobileListFontItems() {
      const res = []
      this.fontItems.forEach(item => {
        if(item.items.lenght < 2) {
          return res.push(item)
        }

        item.items.forEach(font => {
          res.push(
            {
              isPrimary: item.isPrimary,
              header: item.header,
              items: [font]
            }
          )
        })
      });

      return res
    }
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.brandstyle-typography';

#{$b} {
  // .brandstyle-typography__items
  &__items {
    display: flex;
    @include mobile {
      display: none;
    }
  }

  // .brandstyle-typography__item
  &__item {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  // .brandstyle-typography__items-mob
  &__items-mob {
    @include mobile-min {
      display: none;
    }

    .swiper-container {
      @include mobile {
        padding: 0 15px;
        margin: 0 -15px;
      }
    }

    .swiper-slide {
      @include mobile {
        height: auto;
      }
    }
  }

  // .brandstyle-typography__pagination
  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}
</style>
