<template>
  <div class="initiatives-thematics">
    <div class="initiatives__row">
      <a
        v-for="card in data"
        :key="`initiatives__card-${card.id}`"
        class="initiatives__card"
        :href="card.url"
      >
        <p class="initiatives-card__theme fw-500">{{ card.type }}</p>
        <img class="initiatives-card__image" :src="card.image" alt="card_pic" />
        <h5 class="initiatives-card__title title-md" v-html="card.title" />
        <p class="initiatives-card__text" v-html="card.text" />
        <CustomButton
          class="initiatives-__show-more"
          theme="outline-inherit"
        >
          Узнать больше
        </CustomButton>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      themes: [],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/scss/base/u-includes";

.initiatives-thematics {
  margin-top: 32px;
}

.initiatives__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.initiatives__card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc((100% - 2 * 16px) / 3);
  color: $black-true;
  background-color: $white-true;
  border-radius: 1rem;
  padding: 24px;
  text-decoration: none;
  transition: $transtion-default;

  @include hover {
    transform: scale(1.01);
  }

  @include tablet {
    width: calc((100% - 16px) / 2);
  }
  @include mobile {
    width: 100%;
  }
}

.initiatives-card__theme {
  opacity: 0.5;
  text-transform: uppercase;
  margin: 0 0 24px 0;
}
.initiatives-card__image {
  height: 34px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: left;
  margin-bottom: 24px;
}
.initiatives-card__title {
  margin: 0 0 16px 0;
}
.initiatives-card__text {
  opacity: 0.5;
  margin: 0 0 24px 0;
}
.initiatives__card:deep(.initiatives-__show-more) {
  margin-top: auto;
  align-self: flex-start;
}
</style>
