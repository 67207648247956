import { render, staticRenderFns } from "./Initiatives.vue?vue&type=template&id=0c6c6645&"
import script from "./Initiatives.vue?vue&type=script&lang=js&"
export * from "./Initiatives.vue?vue&type=script&lang=js&"
import style0 from "./Initiatives.vue?vue&type=style&index=0&id=0c6c6645&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports