<template>
  <div class="custom-pagination">
    <div
      class="custom-pagination__navigator"
      :class="{'custom-pagination__navigator--is-disabled': pagination.currentPage <= 1}"
      @click="$emit('onSelect', pagination.currentPage - 1)"
    >
      Назад
    </div>
    <div class="custom-pagination__list">
      <div
        v-for="item in pagination.countPage"
        :key="`custom-pagination__item-${item}`"
        class="custom-pagination__item"
        :class="{
          'custom-pagination__item--is-active': isActive(item),
          'custom-pagination__item--is-hidden': isHidden(item)
        }"
        @click="$emit('onSelect', item)"
      >
        {{item}}
      </div>
    </div>
    <div
      class="custom-pagination__navigator"
      :class="{'custom-pagination__navigator--is-disabled': pagination.currentPage >= pagination.countPage}"
      @click="$emit('onSelect', pagination.currentPage + 1)"
    >
      Дальше
    </div>
  </div>
</template>

<script>
export default {
  inject: ["breakpoint"],
  props: {
    pagination: {
      type: Object,
      default: () => ({
        countPage: 6,
        currentPage: 1
      })
    }
  },
  data() {
    return {
      limitOnPage: 5,
      liminOnPageMobile: 4,
    }
  },
  methods: {
    isActive(page) {
      return page === this.pagination.currentPage
    },
    isHidden(page) {
      const limit = this.breakpoint.isMobile ? this.liminOnPageMobile : this.limitOnPage

      return ((page < this.pagination.currentPage) && (this.pagination.countPage - page >= limit))
        || page - limit >= this.pagination.currentPage
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.custom-pagination';

#{$b} {
  font-family: $font-family-inter;
  font-size: 16px;
  line-height: 1.3;
  color: $black-true;
  display: flex;
  align-items: center;
  user-select: none;

  @include mobile {
    justify-content: center;
  }

  @include mobile-sm {
    font-size: 14px;
  }

  // .custom-pagination__list
  &__list {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-right: 8px;
  }

  // .custom-pagination__item
  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid rgba($black-true, 0.1);
    cursor: pointer;
    transition: $transtion-default;
    margin-right: 8px;

    // .custom-pagination__item--is-active
    &--is-active {
      background-color: $color-base-origin;
      border-color: $color-base-origin;
      color: $white-true;
    }

    // .custom-pagination__item--is-hidden
    &--is-hidden {
      display: none;
    }

    &:hover {
      @include mobile-min {
        border-color: $color-base-origin
      }
    }

    @include mobile-sm {
      width: 35px;
      height: 35px;
    }
  }

  // .custom-pagination__navigator
  &__navigator {
    cursor: pointer;
    transition: $transtion-default;

    // .custom-pagination__navigator--is-disabled
    &--is-disabled {
      color: $color-info-secondary;
      pointer-events: none;
      touch-action: none;
    }

    &:hover {
      color: $color-base-origin;
    }
  }
}
</style>
