<template>
  <form class="lk-plan">
    <div class="lk-plan__container">
      <div class="lk-plan__action">
        <div class="lk-plan__action-item">
          <CustomButton
            theme="primary"
            size="md"
            @click.stop.prevent="routeTo()"
          >
            {{ btnText }}
          </CustomButton>
        </div>
        <div v-if="type === 'event'" class="lk-plan__action-item">
          <CustomButton
            theme="outline-primary"
            size="md"
            tag="a"
            href="/local/ajax/downloadRegionalEvents.php"
            target="_blank"
          >
            <div class="lk-plan__action-file">
              Cкачать все мероприятия
              <InlineSvg src="u-arr-download" />
            </div>
          </CustomButton>
        </div>
      </div>
      <div class="lk-plan__table">
        <div class="lk-plan__table-row lk-plan__table-row--head">
          <div class="lk-plan__table-cell lk-plan__table-cell--num">
            №
          </div>
          <div class="lk-plan__table-cell lk-plan__table-cell--title">
            <button
              class="lk-plan__filter-btn"
              @click.stop.prevent="setSortedType('name')"
            >
              <div
                class="u-sorted-icon lk-plan__sorted-icon"
                :class="{'u-sorted-icon--desc': getSortOrder('name') === 'DESC'}"
              />
              Название
            </button>
          </div>
          <div class="lk-plan__table-cell lk-plan__table-cell--date">
            <button
              class="lk-plan__filter-btn"
              @click.stop.prevent="setSortedType('date')"
            >
              <div
                class="u-sorted-icon lk-plan__sorted-icon"
                :class="{'u-sorted-icon--desc': getSortOrder('date') === 'DESC'}"
              />
              Дата
            </button>
          </div>
        </div>
        <div
          v-for="(event, i) in sortedEvents"
          :key="event.id"
          class="lk-plan__table-row"
        >
          <div class="lk-plan__table-cell lk-plan__table-cell--num">
            {{ i + 1 }}
          </div>
          <div class="lk-plan__table-cell lk-plan__table-cell--title" v-html="event.title" />
          <div class="lk-plan__table-cell lk-plan__table-cell--date">
            <div class="_color-secondary-info g-visible-mobile">
              Дата
            </div>
            {{ event.date }}
          </div>
          <div class="lk-plan__table-cell lk-plan__table-cell--edit">
            <button class="lk-plan__table-link" @click.stop.prevent="deletePrompt(event.id)">
              Удалить
            </button>
            <button class="lk-plan__table-link" @click.stop.prevent="getEvent(event.id)">
              Редактировать
            </button>
            <button v-if="type === 'event'" class="lk-plan__table-link" @click.stop.prevent="copy(event.id)">
              Копировать
            </button>
            <a v-if="type === 'plan' && event.url" class="lk-plan__table-link" :href="event.url" target="_blank">
              Скачать
            </a>
            <ConfirmationWindow
              :isVisible="currentRemoveId === event.id"
              :deleteStatus="deleteStatus"
              :initPosition="confirmationPosition"
              :leftMobile="true"
              @success="onDelete()"
              @cancel="currentRemoveId = null"
            >
              Вы действительно хотите удалить
              {{ getRowName }}?
            </ConfirmationWindow>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';

export default {
  name: "LkPlan",
  props: {
    initEvents: {
      type: Array,
      default: () => [],
    },
    btnText: {
      type: String,
      required: true
    },
    actionGet: {
      type: String,
      required: true
    },
    actionUpdate: {
      type: String,
      required: true
    },
    actionRemove: {
      type: String,
      default: ''
    },
    componentInModal: {
      type: String,
      required: true
    },
    titleInModal: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      months: {
        '01': 'января',
        '02': 'февраля',
        '03': 'марта',
        '04': 'апреля',
        '05': 'мая',
        '06': 'июня',
        '07': 'июля',
        '08': 'августа',
        '09': 'сентября',
        '10': 'октября',
        '11': 'ноября',
        '12': 'декабря',
      },
      dateFromOlder: false,
      currentEditId: null,
      currentRemoveId: null,
      reportBtnKey: 0,
      events: this.initEvents,
      sortItems: {
        name: {
          type: 'DESC',
          query: 'name',
          sortedFn: this.sortNames
        },
        date: {
          type: 'DESC',
          query: 'date',
          sortedFn: this.sortDates
        }
      },
      activeSortQuery: "date",
      activeSortType: "DESC",
      deleteStatus: '',
      confirmationPosition: {
        desktop: {
          x: 'right',
          y: 'top',
        },
        mobile: {
          x: 'left',
          y: 'top',
        }
      },
    };
  },
  computed: {
    sortedEvents() {
      const arrToSort = [...this.events];

      return arrToSort.sort(this.sortItems[this.activeSortQuery].sortedFn);
    },
    getRowName() {
      return this.type === 'plan' ? 'этот региональный план' : 'это событие'
    }
  },
  methods: {
    getEvent(id) {
      this.currentEditId = id;
      this.$root.$refs.customModal.openModal(null, null, true);

      axios.get(`${this.actionGet}&id=${id}`)
        .then((res) => {
          if (res.data.status === 'success') {
            const title = res.data.data.type === 'event' ? 'Редактировать Мероприятие' : 'Редактировать Проект'
            this.$root.$refs.customModal.passContent({
              name: this.componentInModal,
              props: {
                ...this.formEventFormProps(res.data.data),
                title: 'Заполните форму'
              },
              title: this.type === 'event' ? title : this.titleInModal
            });
          } else {
            this.$root.$refs.customModal.closeModal();
            this.$root.$refs.eventErrorModal.openModal();
          }
        })
        .catch((e) => {
          console.error(e);
          this.$root.$refs.customModal.closeModal();
          this.$root.$refs.eventErrorModal.openModal();
        });
    },
    async loadEvents() {
      const response = await axios.get('/local/ajax/getRegionalEvents.php');

      if (response.data) {
        this.events = response.data;
      }
    },
    formEventFormProps(data) {
      const submitUrl = this.actionUpdate;
      const requestId = this.currentEditId;

      return { ...data, submitUrl, requestId };
    },
    handleReport(id, url = null) {
      this.events.forEach((event) => {
        if (event.id === id) {
          event.report = url;
          this.reportBtnKey += 1;
        }
      });
    },
    getDate(str) {
      const parts = str.split('.');
      const newStr = `${parts[1]}/${parts[0]}/${parts[2]}`;

      return new Date(newStr);
    },
    rmZero(str) {
      return str[0] === '0' ? str.substring(1) : str;
    },
    formDate(dateArr) {
      if (dateArr.length === 1) {
        const parts = dateArr[0].split('.');
        return `${this.rmZero(parts[0])} ${this.months[parts[1]]}`;
      } else {
        const parts1 = dateArr[0].split('.');
        const parts2 = dateArr[1].split('.');
        return `${this.rmZero(parts1[0])} ${parts1[1] === parts2[1]
          ? '—'
          : `${this.months[parts1[1]]} —`} ${this.rmZero(parts2[0])} ${this.months[parts2[1]]}`;
      }
    },
    parseDateFromStr(date) {
      let thisDate = date

      if (!(date instanceof Date && !isNaN(date))) {
        const partialsDate = date.split('.')
        const dd = partialsDate[0]
        const mm = partialsDate[1]
        const yy = partialsDate[2]

        thisDate = new Date(yy, mm-1, dd)
      }

      return thisDate
    },
    sortDates(event1, event2) {
      const date1 = Date.parse(this.parseDateFromStr(event1.date))
      const date2 = Date.parse(this.parseDateFromStr(event2.date))

      return this.sortItems.date.type === 'DESC' ? (date2 - date1) : (date1 - date2);
    },
    sortNames(event1, event2) {
      const name1 = event1.title
      const name2 = event2.title

      return this.sortItems.name.type === 'DESC' ? name1.localeCompare(name2) : name2.localeCompare(name1);
    },
    setSortedType(name) {
      this.toggleTypeSort(name)

      this.activeSortQuery = this.sortItems[name].query
      this.activeSortType = this.sortItems[name].type
    },
    async getItemById(id, type) {
      if (this.type === type) {
        const url = `${this.actionGet}Short&id=${id}`
        const response = await axios(url)

        if (response.data.status === 'success') {
          this.updateItem(response.data.data)
        } else {
          throw new Error('Не удалось получить обновленный объект:', url)
        }
      }
    },
    routeTo() {
      this.$root.$emit('header-tabs-change', 'add-event')
      if (this.type === 'event') {
        setTimeout(() => {
          this.$root.$emit('scrollToPlanForm')
        },);
      }
      if (this.type === 'plan') {
        setTimeout(() => {
          window.scrollTo({top: 0,})
        });
      }
    },
    updateItem(data) {
      const index = this.sortedEvents.findIndex(item => item.id === data.id)
      const result = [...this.sortedEvents.slice(0, index), data, ...this.sortedEvents.slice(index + 1)]

      this.events = result
    },
    toggleTypeSort(name) {
      const currentType = this.sortItems[name].type

      if (currentType === 'ASC') {
        this.sortItems[name].type = 'DESC'
      } else {
        this.sortItems[name].type = 'ASC'
      }
    },
    getSortOrder(name) {
      return this.sortItems[name].type
    },
    deletePrompt(id) {
      this.deleteStatus = ''
      this.currentRemoveId = id
    },
    async onDelete() {
      this.deleteStatus = 'loading'

      const options = {
        url: this.actionRemove,
        method: 'POST',
        data: {
          idRemove: this.currentRemoveId
        }
      };

      const response = await axios(options)

      if (response.data.status === 'success') {
        this.deleteStatus = 'success'
        this.removeItemById(this.currentRemoveId)
      } else {
        const error = response.data && response.data.errors ? response.data.errors
          .map(item => item.message)
          .join('. ')
          : 'Произошла непредвиденная ошибка'

        alert(error)
      }
      setTimeout(() => {
        this.deleteStatus = ''
        this.currentRemoveId = null
      }, 1000);
    },
    removeItemById(id) {
      this.events = this.events.filter(item => item.id !== id)
    },
    copy(id) {
      this.$root.$emit('header-tabs-change', 'add-event')

      setTimeout(() => {
        this.$root.$emit('scrollToPlanForm' , id)
      },);
    }
  },
  async created() {
    if (this.type === 'event') {
      await this.loadEvents();
    }
  },
  mounted() {
    this.$root.$on('editedLkPlan', this.getItemById)
  }
};
</script>

<style lang="scss">
  @import "@/scss/base/u-includes";

  $b: '.lk-plan';

  $pd-right: 18px;
  $cell-num: 32px;
  $cell-report: 180px;
  $cell-edit: 340px;
  $cell-edit-tablet: 340px;
  $cell-date: 115px;
  $cell-date-header: #{$cell-report + $cell-edit + $cell-date};

  #{$b} {
    font-family: $font-family-inter;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 400;
    color: $black-true;

    &__container {
      margin-top: 22px;

      @include tablet {
        margin-top: 48px;
      }
    }

    &__table {
      font-size: 16px;

      @include tablet-only {
        @include gridcols(2, 16px);
        & > * {
          margin: 0;
        }
      }

      &-row {
        display: flex;
        align-items: center;
        width: calc(100% + 30px);
        margin-left: -15px;
        padding: 7px 15px;
        min-height: 40px;
        border-radius: 20px;
        &:not(#{$b}__table-row--head) {
          @include tablet-min {
            cursor: pointer;
            transition: $transtion-default;

            &:hover {
              background-color: rgba($color-base-origin, 0.1);
            }
          }
          &:not(:last-child) {
            margin-bottom: 6px;
          }
        }

        @include tablet {
          flex-direction: column;
          padding: 34px 24px;
          border-radius: 16px;
          background-color: $white-true;
          margin: 0;
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }

        &--head {
          color: $color-info-secondary;
          position: relative;
          padding-top: 0;
          margin-bottom: 15px;

          &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 15px;
            width: calc(100% - 30px);
            height: 1px;
            background-color: rgba($color-black, .2);
          }

          #{$b}__table-cell {
            padding-bottom: 17px;
            padding-top: 0;

            &--title {
              @include tablet {
                display: none;
              }
            }

            &--date {
              flex: $cell-date-header 0 1;
              max-width: $cell-date-header;

              @include tablet {
                width: 100%;
                flex: 100%;
                padding-right: 0;
                margin-left: 0;
              }
            }
          }

          @include tablet {
            display: none;
          }
        }
      }

      &-cell {
        display: inline-flex;
        &--num {
          flex: $cell-num 0 0;
          max-width: $cell-num;

          @include tablet {
            display: none;
          }
        }

        &--title {
          flex: 1 1;
          padding-right: 55px;
          width: 700px;
          max-width: 700px;
          min-width: 700px;
          word-break: break-all;

          @include low-desktop {
            width: 500px;
            max-width: 500px;
            min-width: 500px;
          }

          @include tablet {
            flex: 100%;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            padding-right: 0;
            border-bottom: 1px solid rgba($black-true, 0.25);
            padding-bottom: 24px;
            margin-bottom: 24px;
          }
        }

        &--date {
          flex: $cell-date 0 0;
          max-width: $cell-date;
          padding-right: $pd-right;

          @include tablet {
            flex: 100%;
            width: 100%;
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 24px 0;
            padding: 0;
          }
        }

        &--edit,
        &--report {
          padding-right: $pd-right;
          margin-left: auto;

          @include tablet {
            text-align: left;
          }
        }

        &--edit {
          position: relative;
          flex: auto 0 0;
          max-width: $cell-edit;
          gap: 16px;

          @include tablet {
            width: 100%;
            flex: 100% 1 1;
            flex-wrap: wrap;
            margin: 0;
            padding-right: 0;
            max-width: 100%;
            text-align: center;
            justify-content: space-between;
          }
        }

        &--report {
          flex: $cell-report 0 0;
          max-width: $cell-report;

          @include tablet {
            flex: 100% 1 1;
            margin-left: $cell-num;
            padding-right: 0;
            max-width: 100%;
          }
        }
      }

      &-link {
        text-decoration: none;
        border: none;
        outline: none;
        color: $color-base;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        background: transparent;
        cursor: pointer;
        padding: 0;

        &:hover {
          @include tablet-min {
            text-decoration: underline;
          }
        }
      }
    }

    &__filter-btn {
      display: inline-flex;
      align-items: center;
      border: 0;
      background: transparent;
      color: inherit;
      font-weight: inherit;
      padding: 0;
      position: relative;
      cursor: pointer;
    }

    &__report {
      &--uploaded {
        position: relative;
        padding-right: 20px;
        margin-right: -20px;

        @include tablet {
          margin-right: unset;
          width: max-content;
          max-width: 170px;
        }
      }

      &-label {
        input {
          position: absolute;
          width: 0;
          height: 0;
          appearance: none;
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    &__remove-btn {
      @include remove-cross;
      position: absolute;
      top: 9px;
      right: 0;
      width: 20px;
      max-width: 20px;
      height: 20px;
      cursor: pointer;
    }

    &__info {
      font-weight: 500;
      font-size: 12px;
      color: rgba($color-black, .5);
      padding: 40px 0;
    }

    &__action {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 24px;
      margin-bottom: 32px;

      @include tablet {
        margin-bottom: 48px;
        text-align: center;
      }

      &-item {
        @include tablet {
          width: 100%;

          & > * {
            width: 100%;
          }
        }
      }

      &-file {
        display: inline-flex;
        align-items: center;

        svg {
          margin-left: 8px;
          width: 18px;
          height: 18px;

          path {
            fill: transparent !important;
            stroke: currentColor;
          }
        }
      }
    }

    // .lk-plan__sorted-icon
    &__sorted-icon {
      margin-right: 8px;
    }
  }
</style>
