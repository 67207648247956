<template>
  <div class="create-options">
    <div class="create-options__inner">
      <u-text-input
        ref="optionInputRef"
        class="u-text-input--smaller"
        name="option"
        :label="label"
        v-model="option"
        :placeholder="placeholder"
        :error="(this.$v.option.$error && !this.$v.option.required) || Boolean(errorText)"
        @change="$emit('change', option)"
      />
      <ErrorFormWrapper
        :isVisible="(this.$v.option.$error && !this.$v.option.required) || Boolean(errorText)"
        class="lk-plan-form__error-field"
      >
        <div v-show="!this.$v.option.required">Обязательное поле</div>
        <div v-show="Boolean(errorText) && Boolean(option)">{{ errorText }}</div>
      </ErrorFormWrapper>
    </div>
    <div
      class="create-options__actions"
      :class="{'create-options__actions--has-error': (this.$v.option.$error && !this.$v.option.required) || Boolean(errorText)}"
    >
      <CustomButton
        theme="primary"
        size="md"
        class="create-options__btn"
        @click="add"
      >
        Добавить
      </CustomButton>
      <CustomButton
        theme="outline-primary"
        size="md"
        class="create-options__btn"
        @click="close"
      >
        Отмена
      </CustomButton>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: {
    placeholder: {
      type: String,
      default: '...'
    },
    label: {
      type: String,
      default: '...'
    },
    value: {
      type: String,
      default: ''
    },
    errorText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      option: ''
    }
  },
  validations() {
    return { option: { required } }
  },
  created() {
    this.option = this.value
  },
  mounted() {
    this.setFocus()
    this.$nextTick(() => {
      document.addEventListener('mousedown', this.closeFromClickOutside)
      document.addEventListener('touchstart', this.closeFromClickOutside)
    })
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.closeFromClickOutside)
    document.removeEventListener('touchstart', this.closeFromClickOutside)
  },
  methods: {
    setFocus() {
      this.$nextTick(() => {
        const input = this.$refs.optionInputRef.$el.querySelector('input')
        input.focus()
      })
    },
    add() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return
      }

      this.$emit('createOption', this.option)
    },
    close() {
      this.$emit('close')
    },
    closeFromClickOutside(e) {
      if (!(e.target.classList.contains('create-options') || e.target.closest('.create-options'))) {
        this.close()
      }
    },
  }
}
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: '.create-options';

#{$b} {
  padding: 10px;
  background-color: $white-true;
  box-shadow: $shadow-default;

  // .create-options__inner
  &__inner {
    position: relative;
  }

  // .create-options__actions
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    margin-top: 15px;

    @include mobile {
      display: block;
    }

    // .create-options__actions--has-error
    &--has-error {
      margin-top: 30px;
    }
  }

  // .create-options__btn
  &__btn {
    @include mobile {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}
</style>
