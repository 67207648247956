<template>
  <div class="faq-item">
    <div class="faq-item__inner">
      <div class="faq-item__header">
        <h3 class="faq-item__title text-lg fw-500" v-html="faq.title" />
        <div class="faq-item__tag fw-500">
          #
          <span v-html="faq.tag" />
        </div>
      </div>
      <div class="faq-item__person">
        <img v-if="faq.personImg" :src="faq.personImg" class="faq-item__person-img">
        <div class="faq-item__person-info">
          <div class="faq-item__person-name fw-700" v-html="faq.personName" />
          <div class="faq-item__person-position" v-html="faq.personPosition" />
        </div>
      </div>
      <div class="faq-item__answer" :class="{'faq-item__answer--is-full': isFullAnswer || !hasLimitAnswer}" v-html="faq.answer" />
      <div v-if="hasLimitAnswer" class="faq-item__actions">
        <div class="faq-item__toggle" :class="{'faq-item__toggle--is-full': isFullAnswer}" @click="toggleAnswer">
          <div class="faq-item__toggle-text fw-500">
            <span class="faq-item__toggle-opened">Свернуть</span>
            <span class="faq-item__toggle-hidden">Узнать подробнее</span>
          </div>
          <div class="faq-item__toggle-icon">
            <inline-svg src="u-arr-down"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['breakpoint'],
  props: {
    faq: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isFullAnswer: false,
    }
  },
  computed: {
    maxLengthAnswer() {
      return this.breakpoint.isMobile ? 200 : 300
    },
    hasLimitAnswer() {
      return this.faq.answer && this.faq.answer.length > this.maxLengthAnswer
    }
  },
  methods: {
    toggleAnswer() {
      this.isFullAnswer = !this.isFullAnswer
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.faq-item';

#{$b} {

  // .faq-item__header
  &__header {
    display: grid;
    grid-template-columns: auto 250px;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    @include tablet {
      display: block;
    }
  }

  // .faq-item__title
  &__title {
    margin: 0;

    @include tablet {
      margin-bottom: 32px;
    }
  }

  // .faq-item__tag
  &__tag {
    color: $color-primary;
    display: inline-flex;
  }

  // .faq-item__person
  &__person {
    display: flex;
    margin-bottom: 32px;

    // .faq-item__person-img
    &-img {
      width: 46px;
      min-width: 46px;
      height: 46px;
      margin-right: 16px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      object-position: center;

      @include mobile {
        width: 56px;
        min-width: 56px;
        height: 56px;
      }
    }

    // .faq-item__person-name
    &-name {
      margin-bottom: 4px;
    }

    // .faq-item__person-position
    &-position {
      color: $color-info;
    }
  }

  // .faq-item__answer
  &__answer {

    &:not(#{$b}__answer--is-full) {
      @include lineClamp(2);

      @include mobile {
        @include lineClamp(4);
      }
    }

    p {
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  // .faq-item__toggle
  &__toggle {
    display: inline-flex;
    align-items: center;
    margin-top: 8px;
    transition: $transtion-default;
    color: $color-primary;
    text-decoration-line: none;
    cursor: pointer;
    transition: $transtion-default;

    @include mobile-min {
      &:hover {
        color: rgba($color-primary, 0.75);
      }
    }

    // .faq-item__toggle--is-full
    &--is-full {
      margin-top: 24px;
    }

    // .faq-item__toggle-text
    &-text {
      margin-right: 13px;
    }

    // .faq-item__toggle-opened
    &-opened {
      display: none;

      #{$b}__toggle--is-full & {
        display: inline;
      }
    }

    // .faq-item__toggle-hidden
    &-hidden {
      display: inline;

      #{$b}__toggle--is-full & {
        display: none;
      }
    }

    // .faq-item__toggle-icon
    &-icon {
      width: 19px;
      height: 19px;
      color: $white-true;
      background-color: $color-primary;
      padding: 4px;
      border: 1px solid $color-primary;
      border-radius: 50%;
      transition: $transtion-default;

      #{$b}__toggle--is-full & {
        background-color: transparent;
        color: $color-primary;
        transform: rotate(180deg);
      }

      svg {
        path {
          fill: transparent !important;
          stroke: currentColor;
        }
      }
    }
  }
}
</style>
