<template>
  <div class="u-progress-bar">
    <h4 class="u-progress-bar__title">{{title}}</h4>
    <div class="u-progress-bar__header">
      <div class="u-progress-bar__left">
        Прошло
        <span>{{values.current}}</span>
      </div>
      <div class="u-progress-bar__right">
        из {{values.max}}
      </div>
    </div>
    <div class="u-progress-bar__bar">
      <div class="u-progress-bar__bar-current" :style="{width: getPercentage(values.current, values.max)}" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    values: {
      type: Object,
      require: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    getPercentage(current, max) {
      return (current / max * 100) + '%'
    },
  }
}
</script>


<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-progress-bar';

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;

  // .u-progress-bar__title
  &__title {
    font-size: 24px;
    line-height: 1.5;
    font-weight: normal;
    margin: 0 0 8px 0;
  }

  // .u-progress-bar__header
  &__header {
    font-size: 16px;
    line-height: 1.3;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 8px 0;
  }

  // .u-progress-bar__bar
  &__bar {
    width: 100%;
    height: 10px;
    position: relative;
    border-radius: 8px;
    background-color: $color-sky-light;

    // .u-progress-bar__bar-current
    &-current {
      width: 0px;
      height: 100%;
      border-radius: inherit;
      background-color: $color-base;
      transition: $transtion-default;
    }
  }

  // .u-progress-bar__right
  &__right {
    opacity: 0.5;
  }

  // .u-progress-bar__delta
  &__delta {
    color: $color-base;
  }
}
</style>
