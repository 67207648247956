<template>
  <div class="report-block" :style="rootStyle">
    <div class="report-block__inner">
      <div class="report-block__header">
        <h2 class="report-block__title title-xl">
          <slot name="title" />
        </h2>
        <div v-if="isExistHeaderCaption" class="report-block__caption">
          <slot name="caption" />
        </div>
      </div>
      <div v-if="list?.length" class="report-block__body">
        <UTabs dropDownOnMobile @changeTab="onChangeTab">
          <Tab v-for="item in list" :title="String(item.year)" :key="`tab-${item.year}`">
            <ReportCard
              :ref="`card_${i}`"
              class="report-block__card"
              :class="{'report-block__card--disable': !itemListByYear?.canSendReport}"
              v-for="(card, i) in item.items"
              :key="`report-block__card-${card.id}-${currentYear}`"
              :card="card"
              @changeField="(data) => onChangeField(data, i, item.year)"
            />
          </Tab>
        </UTabs>
      </div>
      <div class="report-block__footer">
        <div v-if="isExistFooterText" class="report-block__footer-text">
          <slot name="footer-text" />
        </div>
        <ErrorFormWrapper
          class="report-block__feedback-errors"
          :isVisible="Boolean(ERRORS_AFTER_SUBMIT?.length)"
        >
          <div
            v-for="(error, i) in ERRORS_AFTER_SUBMIT"
            :key="`report-block__feedback-error-${error.code}-${i}`"
            class="report-block__feedback-error"
          >
            <span v-html="error.message" />
          </div>
        </ErrorFormWrapper>
        <div ref="actionsBlock" class="report-block__footer-actions" :class="{ 'report-block__footer-actions--is-fixed': isFixedActions }">
          <div ref="actionsInner" class="report-block__footer-actions-inner">
            <CustomButton
              v-if="itemListByYear?.canSendReport"
              class="report-block__footer-action"
              theme="outline-primary"
              size="3md"
              :status="statusBtn"
              @click="save"
            >
              Сохранить всё
            </CustomButton>
            <CustomButton
              class="report-block__footer-action"
              theme="primary"
              size="3md"
              tag="a"
              target="_blank"
              :isDisabled="!isFilledAll"
              :href="downloadReport"
            >
              Скачать отчет
            </CustomButton>
          </div>
        </div>
        <div v-if="itemListByYear?.caption" class="report-block__footer-caption fw-700" v-html="itemListByYear.caption" />
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash-es/cloneDeep'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { getFormattedValue } from '@/components/helpers/formatHelper.js'

export default {
  props: {
    initialList: {
      type: Array,
      default: () => []
    },
    actionSave: {
      type: String,
      default: '/bitrix/services/main/ajax.php?mode=class&c=dalee:report.form&action=saveResponse'
    },
    downloadReport: {
      type: String,
      default: '/local/ajax/downloadYearReport.php'
    }
  },

  data() {
    return {
      currentYear: null,
      list: [],
      actionsHeight: 0,
      prevInnerWidth: 0,
      isFixedActions: false,
      originalList: [],
    }
  },

  computed: {
    ...mapGetters('lkReportTable', ['IS_LOADING', 'ERRORS_AFTER_SUBMIT']),

    itemListByYear() {
      return this.list.find(item => String(item.year) === String(this.currentYear))
    },

    isExistHeaderCaption() {
      return Boolean(this.$slots?.caption?.length)
    },

    isExistFooterText() {
      return Boolean(this.$slots?.['footer-text']?.length)
    },

    statusBtn() {
      return this.IS_LOADING ? 'loading' : ''
    },

    filledFieldsByYears() {
      const years = {}

      this.originalList.forEach(item => {
        years[item.year] = item.items.every(({ fields }) => fields.every(field => field.value))
      })

      return years
    },

    isFilledAll() {
      return Object.values(this.filledFieldsByYears).every(Boolean)
    },

    rootStyle() {
      return {
        '--actions-height': `${this.actionsHeight}px`
      }
    }
  },

  methods: {
    ...mapActions('lkReportTable', ['SAVE']),
    ...mapMutations('lkReportTable', ['SET_ERRORS_AFTER_SUBMIT']),

    initFilter() {
      if (!this.list?.length) {
        return
      }

      this.setCurrentYear(this.list[0]?.year)
    },

    setCurrentYear(year) {
      this.currentYear = year
    },

    onChangeTab(tab) {
      this.list = cloneDeep(this.originalList)
      this.setCurrentYear(tab.title)
      this.SET_ERRORS_AFTER_SUBMIT([])
    },

    onChangeField(field, cardIndex, year) {
      const list = cloneDeep(this.list)
      const listIndex = this.list.findIndex(item => item.year === year)
      const card = list[listIndex].items[cardIndex]
      const fieldIndex = card.fields.findIndex(item => item.id === field.id)
      list[listIndex].items[cardIndex].fields[fieldIndex] = field

      this.list = list
    },

    save() {
      const form = new FormData()
      form.append('year', this.currentYear)

      if (this.itemListByYear?.resultId) {
        form.append('resultId', this.itemListByYear.resultId)
      }

      const fields = []
      let file = []

      this.itemListByYear?.items?.forEach(item => {
        if (item.file) {
          file = [...file, ...item.file]
        }

        Object.entries(item).forEach(el => {
          const name = el[0]
          const value = el[1]

          if (name.includes('file') && name !== 'file' && value instanceof File) {
            form.append(name, value)
          }
        })

        item.fields.forEach(field => {
          fields.push({ id: field.id, value: field.value || '0' })
        })
      })

      form.append('fields', JSON.stringify(fields))
      form.append('file', JSON.stringify(file))

      this.SAVE({ url: this.actionSave, form, successHandler: () => { window.location.reload() } })
    },

    updateActionsHeight() {
      this.$nextTick().then(() => {
        this.actionsHeight =  this.$refs.actionsInner?.getBoundingClientRect()?.height || 0
      })
    },


    initAndFormatList() {
      this.originalList = this.initialList.map(initialListItem => {
        return {
          ...initialListItem,
          items: initialListItem.items.map(item => (
            {
              ...item,
              fields: item.fields.map(field => ({ ...field, value: getFormattedValue(field.value) }))
            }
          )
        )}
      })

      this.list = cloneDeep(this.originalList)
    },

    onScroll() {
      const card = this.$refs['card_0']?.[0]?.$el
      const cardRect = card?.getBoundingClientRect()

      const actionsInner = this.$refs.actionsInner
      const actionsInnerRect = actionsInner?.getBoundingClientRect()
      const actionsBlock = this.$refs.actionsBlock
      const actionsBlockRect = actionsBlock?.getBoundingClientRect()

      if (!cardRect || !actionsInnerRect || !actionsBlockRect) {
        return
      }

      if (cardRect.bottom < window.innerHeight && actionsBlockRect.bottom > window.innerHeight) {
        this.isFixedActions = true
      } else {
        this.isFixedActions = false
      }

      this.updateActionsHeight()
    },

    onResize() {
      if (this.prevInnerWidth === window.innerWidth) {
        return
      }

      this.updateActionsHeight()

      this.prevInnerWidth = window.innerWidth
    }
  },

  mounted() {
    this.updateActionsHeight()
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  },

  created() {
    this.initFilter()
    this.initAndFormatList()
  }
}
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: '.report-block';

#{$b} {
  font-family: $font-family-inter;

  .u-tabs__list {
    margin-bottom: 24px;
  }

  .u-tabs__header {
    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .report-block__header
  &__header {
    margin-bottom: 64px;

    @include tablet {
      margin-bottom: 32px;
    }
  }

  // .report-block__title
  &__title {
    max-width: 885px;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  // .report-block__caption
  &__caption {
    max-width: 665px;
  }

  // .report-block__filter
  &__filter {
    margin-bottom: 24px;
  }

  // .report-block__card
  &__card {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    // .report-block__card--disable
    &--disable {
      pointer-events: none;
      cursor: default;
    }
  }

  // .report-block__body
  &__body {
    margin-bottom: 20px;
  }

  // .report-block__footer
  &__footer {
    text-align: center;

    // .report-block__footer-actions
    &-actions {
      min-height: var(--actions-height);

      &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 20px 0;
        transition: background 0.3s ease;

        #{$b}__footer-actions--is-fixed & {
          padding-left: 12px;
          padding-right: 12px;
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: $white-true;
          box-shadow: 0 0 40px 10px rgba($color-base-origin, 0.05);
          z-index: 2;
        }

        @include mobile {
          display: block;
        }
      }
    }

    // .report-block__footer-action
    &-action {
      width: 302px;

      @include tablet {
        width: auto;
        flex: 1;
      }

      @include mobile {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }

    // .report-block__footer-caption
    &-caption {
      color: $color-base-origin;
    }
  }

  // .report-block__feedback-error
  &__feedback-error {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 15px;

    @include mobile {
      font-size: 18px;
    }

    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
}
</style>
