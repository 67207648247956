<template>
  <div class="talisman-contestants-phone">
    <div class="talisman-contestants-phone__caption">
      Введите номер телефона, чтобы проголосовать. Голосование доступно одному пользователю один раз.
    </div>
    <div class="talisman-contestants-phone__field">
      <UTextInput
        class="u-text-input--smaller talisman-contestants-phone__input"
        v-model="phone"
        label="Номер телефона*"
        placeholder="+7-912-345-67-89"
        mask="+7 (###) ###-##-##"
        :error="isPhoneErrorField || isRequiredErrorField"
      />
      <ErrorFormWrapper
        :isVisible="isPhoneErrorField || isRequiredErrorField"
        class="lk-plan-form__error-field"
      >
        <div v-show="isRequiredErrorField">Обязательное поле</div>
        <div v-show="isPhoneErrorField">Введите номер полностью</div>
      </ErrorFormWrapper>
    </div>
    <ErrorFormWrapper
      class="talisman-contestants-phone__feedback-errors"
      :max-height="1000"
      :isVisible="Boolean(errorsAfterSubmit) && Boolean(errorsAfterSubmit.length)"
    >
      <div
        v-for="error in errorsAfterSubmit"
        :key="`talisman-contestants-phone__feedback-error-${error.code}`"
        class="talisman-contestants-phone__feedback-error"
      >
        <span v-html="error.message" />
      </div>
    </ErrorFormWrapper>
    <CustomButton
      class="talisman-contestants-phone__btn"
      theme="primary"
      size="md"
      @click="onSubmit"
      :status="btnStatus"
    >
      Получить СМС
    </CustomButton>
  </div>
</template>

<script>
import axios from "axios";

import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: {
    actionVerifyPhone: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      phone: '',
      btnStatus: '',
      errorsAfterSubmit: [],
    }
  },
  validations() {
    return {
      phone: { required, phone: minLength(18) }
    }
  },
  computed: {
    isPhoneErrorField() {
      return this.$v.phone.$error && !this.$v.phone.phone
    },
    isRequiredErrorField() {
      return this.$v.phone.$error && !this.$v.phone.required
    },
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return
      }

      this.btnStatus = 'loading'

      const data = new FormData()
      data.append('phone', this.phone)

      const options = {
        url: this.actionVerifyPhone,
        method: 'POST',
        data
      }

      const response = await axios(options);

      if (response.data.status === 'success') {
        this.$emit('success', this.phone)
      } else {
        this.errorsAfterSubmit = response.data.errors
      }

      this.btnStatus = ''
    }
  },

}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-contestants-phone';

#{$b} {

  // .talisman-contestants-phone__caption
  &__caption {
    margin-bottom: 32px;
  }

  // .talisman-contestants-phone__field
  &__field {
    position: relative;
    margin-bottom: 32px;
  }

  // .talisman-contestants-phone__feedback-error
  &__feedback-error {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;

    @include mobile {
      font-size: 18px;
    }

    &:last-child {
      margin-bottom: 32px;
    }
  }

  // .talisman-contestants-phone__btn
  &__btn {
    @include mobile {
      width: 100%;
    }
  }
}
</style>
