export function getResetForm(form) {
  const pureForm = JSON.parse(JSON.stringify(form))

  for (let field in pureForm) {
    if (typeof pureForm[field] === 'boolean') {
      pureForm[field] = false
    } else if (typeof pureForm[field] === 'string') {
      pureForm[field] = ''
    } else if (typeof pureForm[field] === 'number') {
      pureForm[field] = 0
    } else if (Array.isArray(pureForm[field])) {
      pureForm[field] = []
    } else if (
      pureForm[field] instanceof Object
      && !(Array.isArray(pureForm[field]))
      && !(pureForm[field] instanceof File)
    ) {
      pureForm[field] = getResetForm(pureForm[field])
    } else {
      pureForm[field] = null
    }
  }

  return pureForm
}

export function mutateClearFilesFromForm(form, propFile) {
  const propsFile = Array.isArray(propFile) ? propFile : [propFile]

  propsFile.forEach(propFile => {
    for (let prop in form) {
      if (prop.startsWith(propFile) && prop !== propFile) {
        delete form[prop]
      }
    }
  })
}

export function mutateOnFilesChange(form, files, fileProp) {
  form[fileProp] = files.old

  if (files.new && files.new.length > 0) {
    files.new.forEach((file, i) => {
      form[`${fileProp}${i+1}`] = file
    })
  }
}

export function mutateSetIsDeletedFile(files, formData, initialForm, form) {
  files.forEach(file => {
    if (initialForm[file] && !form[file]) {
      formData.delete(file)
      formData.append(
        file,
        JSON.stringify({
          ...initialForm[file],
          isDeleted: true
        })
      )
    }
  })
}

export function getValidFormatFileStr(validFormatList, separator = ', ') {
  return validFormatList.join(separator)
}
