<template>
  <div
    class="modal-wrapper"
    @mousedown.self="$emit('close')"
  >
    <div class="modal-wrapper__content" :class="contentClass">
      <div class="modal-wrapper__close" :class="closeBtnClass" @click="$emit('close')">
        <InlineSvg class="modal-wrapper__close-icon" src="close-thin" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import lockPageScroll from '@/components/helpers/lockPageScroll.js'

export default {
  props: {
    closeBtnClass: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    }
  },

  mounted() {
    document.body.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.$emit('close')
      }
    })
    this.setScrollLockState(true)
  },

  beforeDestroy() {
    this.setScrollLockState(false)
  },

  methods: {
    setScrollLockState(state) {
      lockPageScroll(state)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.modal-wrapper';

#{$b} {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1010;
  padding: $container-pd-desk;
  background: rgba($black-true, 0.5);
  overflow: auto;

  @include desktop {
    padding: $container-pd-left-md;
  }

  @include tablet {
    padding: $container-pd-left-tablet;
  }

  @include mobile {
    padding: $container-pd-left-mobile;
  }

  // .modal-wrapper__content
  &__content {
    position: relative;
    z-index: 999;
    margin: auto 0;
    width: max-content;
    max-width: 100%;
    display: flex;
    justify-content: center;

    @include mobile {
      width: 100%;
    }
  }

  // .modal-wrapper__close
  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $black-true;
    transition: all 0.3s ease;
    z-index: 2;

    @include hover {
      color: $color-base-origin;
    }

    // .modal-wrapper__close-icon
    &-icon {
      height: fit-content;
      width: fit-content;

      svg {
        height: 20px;
        width: 20px;
        path {
          stroke: currentColor;
        }
      }
    }
  }
}
</style>
