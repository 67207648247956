<template>
  <div class="u-lk-raport-count">
    <div v-if="currentInitiativeName" class="u-lk-raport-count__initiative">
      По инициативе <strong>{{ currentInitiativeName }}</strong>
    </div>
    <div class="u-lk-raport-count__filter">
      <ReportFilter :filters="filters" @onFiltered="onFiltered"/>
    </div>
    <div class="lk-count__container">
      <u-lk-count-graph :events="events" />
      <template v-if="showAdditionalGraph">
        <div class="u-lk-form__subtitle u-lk-raport-count__subtitle">Прирост за {{ currentAdditionalText }}</div>
        <u-lk-count-graph :events="events" additional />
      </template>
      <slot name="note"></slot>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "LkRaportCount",
  props: {
    eventsList: {
      type: Array,
      default: () => []
    },
    momentFilterList: {
      type: Array,
      default: () => [
        {
          filter: 'now',
          name: 'На текущий момент',
          additionalName: '',
          isActive: true
        },
        {
          filter: 'week',
          name: 'За предыдущую неделю',
          additionalName: 'неделю',
          isActive: false
        },
        {
          filter: 'month',
          name: 'За предыдущий месяц',
          additionalName: 'месяц',
          isActive: false
        }
      ]
    },
    code: {
      type: String,
      required: true,
    },
    initiativeId: {
      type: String,
      default: '',
    },
    initiatives: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filters: [],
      events: [],
      showAdditionalGraph: false,
      currentAdditionalText: '',
      currentInitiativeName: '',
      currentInitiativeId: this.initiativeId,
    }
  },
  created() {
    this.filters = this.momentFilterList;
    this.events = [...this.eventsList];

    if (this.currentInitiativeId) {
      this.currentInitiativeName = this.initiatives.find(item => item.id === this.currentInitiativeId)?.name;
    }
  },
  computed: {
    activeFilterItem() {
      return this.filters.find(item => item.isActive)
    },
  },
  mounted() {
    this.$root.$on('updateReportBlocks', (idInitiative) => {
      this.currentInitiativeId = idInitiative;
      this.currentInitiativeName = this.initiatives.find(item => item.id === idInitiative)?.name;

      this.onFiltered();
    });
  },
  methods: {
    onFiltered() {
      let url = `/bitrix/services/main/ajax.php?mode=class&c=dalee:activity.report&action=filter&period=${this.activeFilterItem.filter}&section=${this.code}`;
      if (this.currentInitiativeId !== '') {
        url += `&initiativeId=${this.currentInitiativeId}`;
      }

      axios.get(url)
        .then((res) => {
          if (res.data.status === 'success') {
            this.showAdditionalGraph = false;

            this.events = res.data.data.map((event) => {
              if (+event.valueAdditional) {
                this.showAdditionalGraph = true;
                this.currentAdditionalText = this.activeFilterItem.additionalName;
              }

              event.value = +event.value;
              event.valueAdditional = +event.valueAdditional;

              return event;
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  }
}
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: '.u-lk-raport-count';

#{$b} {

  // .u-lk-raport-count__filter
  &__filter {
    margin-bottom: 32px;
  }

  // .u-lk-raport-count__subtitle
  &__subtitle {
    margin: 32px 0;
  }

  // .u-lk-raport-count__initiative
  &__initiative {
    margin: -15px 0 30px;
  }
}
</style>
