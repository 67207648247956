<template>
  <div class="science-news">
    <div class="science-news-list">
      <div
        v-for='item in items'
        :key="`science-news-list__item-${item.id}`"
        class="science-news-list__item"
      >
        <ScienceNewsCard :data="item" />
      </div>
    </div>
    <div
      v-if="showLoadMore || paginator.NavPageNomer < paginator.NavPageCount"
      class="science-news__load-more"
    >
      <CustomButton
        theme="primary"
        size="md"
        @click="loadMoreItems(false)"
      >
        Показать ещё
      </CustomButton>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ScienceNews",
  props: {
    url: {
      type: String,
      required: true
    },
    initialNewsItems: {
      type: Array,
      default: () => [
        {
          image: "/frontend/assets/img/content/science-news-01.jpg",
          date: "2021-03-31",
          title: "Ученые АлтГУ разработали пленку с антимикробными свойствами"
        },
        {
          image: "/frontend/assets/img/content/science-news-02.jpg",
          date: "2021-04-01",
          title: "Разработка набора для диагностики острых лейкозов"
        },
        {
          image: "/frontend/assets/img/content/science-news-03.jpg",
          date: "2021-04-05",
          title: "Международная ярмарка интеллектуальной литературы"
        },
        {
          image: "/frontend/assets/img/content/science-news-04.jpg",
          date: "2021-04-09",
          title: "Исследователи БФУ изучили, как размер селезенки влияет на тяжесть инфекционных заболеваний"
        },
        {
          image: "",
          date: "2021-05-18",
          title: "Ученые предложили способ неинвазивной диагностики рака мозга на основе анализа крови"
        },
        {
          image: "/frontend/assets/img/content/science-news-05.jpg",
          date: "2021-05-25",
          title: "Мегасайенс под водой: на Байкале запустили нейтринный телескоп"
        }
      ]
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    initPaginator: {
      type: Object,
      default: () => {}
    },
    showLoadMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: this.initialNewsItems,
      nextUrl: this.initNextUrl,
      paginator: this.initPaginator,
    };
  },
  methods: {

    loadFirstPage() {
      this.nextUrl = `?AJAX=Y&PAGEN_${this.paginator.NavNum}=1`;
      this.loadMoreItems(true);
    },
    async loadMoreItems(clearItems) {
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.NEXT_URL;
      this.paginator = res.data.PAGINATOR;
      if (clearItems) {
        this.items = [];
      }
      res.data.ITEMS.map(item => {
        this.items.push(item);
      });
    }
  }
};
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: ".science-news";
$offset-card: 8px;

#{$b} {
  display: block;

  &-list {
    list-style: none;
    padding: 0;
    margin: -$offset-card;
    display: flex;
    flex-wrap: wrap;

    @include mobile {
      margin: 0;
    }

    &__item {
      width: 100%;
      padding: $offset-card;

      &:first-child {
        @include low-desktop {
          width: 100%;
        }
      }

      @include mobile {
        padding:0;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      @include mobile-min {
        width: 50%;
        min-height: 400px;
      }

      @include low-desktop-min {
        width: calc(100% / 3);

        &:nth-child(5n+4),
        &:nth-child(5n) {
          width: 50%;
        }
      }

    }
  }

  &__load-more {
    margin-top: 32px;

    @include mobile {
      text-align: center;
      margin-top: 24px;
    }
  }

}
</style>
