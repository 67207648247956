<template>
  <div class="u-caption" @click="openMobileModal">
    <inline-svg class="u-caption__icon" src="error-icon" />
    <div class="u-caption__tooltip">
      <div class="u-caption__tooltip-content" v-html="caption" />
    </div>
  </div>
</template>

<script>
export default {
  inject: ['breakpoint'],
  props: {
    caption: {
      type: String,
      default: 'Скоро ты найдешь здесь еще больше вузов, направлений подготовки и специальностей'
    }
  },
  methods: {
    openMobileModal() {
      if (this.breakpoint.isDesktop) {
        return
      }

      this.$root.$refs.customModal.openModal(null, null, true, true);
      this.$root.$refs.customModal.passContent({
        name: 'u-caption-window',
        title: '',
        props: {
          caption: this.caption,
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-caption';

#{$b} {
  position: relative;
  display: inline-flex;
  align-items: center;

  // .u-caption__icon
  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;

    path {
      &:first-child {
        fill: $color-base-origin !important;
      }
    }
  }

  // .u-caption__tooltip
  &__tooltip {
    position: absolute;
    top: 50%;
    right: 0;
    width: 290px;
    padding: 12px;
    border-radius: 16px;
    background-color: $white-true;
    box-shadow: $shadow-gray;
    transform: translate(100%, -50%);
    opacity: 0;
    pointer-events: none;
    touch-action: none;
    transition: $transtion-default;
    z-index: 3;

    @include low-desktop {
      width: 230px;
    }

    #{$b}:hover & {
      @include tablet-min {
        opacity: 1;
        pointer-events: unset;
        touch-action: unset;
      }
    }

    // @include tablet {
    //   transform: translate(50%, 0);
    //   right: 50%;
    //   top: calc(100% + 16px);
    // }
  }

  // .u-caption__text
  &__text {
    margin: 0;
  }

}
</style>
