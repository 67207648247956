<template>
  <div class="u-lk-count">
    <div class="u-lk-count__row">
      <div ref="bar" class="u-lk-count__col" :class="{'u-lk-count__col--leveled': leveled}">
        <div class="u-lk-count__values" v-for="(item, i) in eventsPercents" :key="i" :style="{width: `${item.percent}%`}">
          <div class="u-lk-count__value">
            <span>{{ item.value | formatNumber }} </span>
            <span v-if="additional && item.valueAdditional" class="u-lk-count__additional">
              ({{item.valueAdditional > 0 ? '+' : ''}}{{item.valueAdditional | formatNumber}})
            </span>
          </div>
          <div
            class="u-lk-count__bar"
            :style="{backgroundColor: additional ? getAdditionalColor(item.color) : item.color, width: `${item.percent}%` }"
          />
          <div class="u-lk-count__title">{{ item.title }}</div>
        </div>
      </div>
      <div class="u-lk-count__col">
        <div class="u-lk-count__total">
          Всего
          <span class="u-lk-count__total-separator">/</span>
          {{ totalValue | formatNumber }}
          <span v-if="additional && (totalValueAdditional > 0)" class="u-lk-count__additional">
            ({{(totalValueAdditional > 0 ? '+' : '')}}{{totalValueAdditional | formatNumber}})
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ULkCountGraph",
  props: {
    events: { type: Array, required: true },
    additional: { type: Boolean, default: false },
  },
  data() {
    return {
      leveled: false,
      eventsPercents: [],
    };
  },
  computed: {
    totalValue() {
      return this.events.reduce(function (a, b) {
        return a + b.value
      }, 0)
    },
    totalValueAdditional() {
      return this.events.reduce(function (a, b) {
        if (b.valueAdditional) {
          return a + b.valueAdditional
        } else {
          return a
        }
      }, 0)
    },
  },
  watch: {
    events() {
      this.transformEvents();
      this.adjustGraph();
    },
  },
  mounted() {
    this.transformEvents();
    this.adjustGraph();

    window.addEventListener('resize', this.adjustGraph);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustGraph);
  },
  methods: {
    getPercentage(value) {
      return (value / this.totalValue * 100) || 0;
    },
    adjustGraph() {
      this.leveled = false;
      const fullWidth = this.$refs.bar.clientWidth;
      this.eventsPercents.forEach((event) => {
        const idealWidth = 16 + (event.title.length * 8.4);
        const factualWidth = event.percent < 11 ? fullWidth * 0.11 : fullWidth / 100 * event.percent;
        if (factualWidth < idealWidth) {
          this.leveled = true;
        }
      });
    },
    transformEvents() {
      this.eventsPercents = this.events.map((event) => {
        event.percent = this.getPercentage(event.value);
        return event;
      });
    },
    getAdditionalColor(hexColor) {
      if (hexColor.length < 8) {
        return 'rgb(0, 46, 192)';
      } else {
        const hexAlpha = hexColor.substring(7);
        const rgbAlpha = parseInt(hexAlpha, 16);

        return `rgba(0, 46, 192, ${rgbAlpha / 255})`;
      }
    },
  },
};
</script>


<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-lk-count';

#{$b} {
  font-family: $font-family-inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;

  &__container {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile {
      flex-wrap: wrap;
    }
  }
  &__col {
    &:nth-child(1) {
      display: flex;
      align-items: flex-start;
      width: calc(74% - 20px);

      @include low-desktop {
        font-size: 14px;
      }

      @include mobile {
        font-size: 16px;
        flex-wrap: wrap;
        width: 100%;
      }
    }
    &:nth-child(2) {
      width: calc(26% - 20px);

      @include mobile {
        width: 100%;
      }
    }
  }
  &__total {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    &-separator {
      color: $color-gray-2;
    }

    @include mobile {
      text-align: right;
    }
  }
  &__values {
    position: relative;
    padding: 36px 0 36px 0;
    min-width: 25%;

    @include mobile {
      min-width: 100%;
      margin-bottom: 24px;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 8px;
    }

    .lk-count__col & {
      &:nth-last-child(2) {
        padding-bottom: 70px;

        @include mobile {
          padding-bottom: 22px;
        }
      }

      &:nth-last-child(3) {
        padding-bottom: 105px;

        @include mobile {
          padding-bottom: 22px;
        }
      }
    }
  }
  &__value {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;

    @include low-desktop {
      white-space: normal;
    }

    @include mobile {
      position: unset;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 2;
      grid-column-end: 3;
      text-align: right;
    }

    span {
      display: inline-block;
      white-space: nowrap;
      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
  &__bar {
    position: relative;
    z-index: 2;
    height: 10px;
    width: 100%;

    #{$b}__values:first-child & {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    #{$b}__values:last-child & {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    @include mobile-min {
      min-width: 100%;
    }

    @include mobile {
      border-radius: 8px;
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 3;
    }

  }
  &__title {
    position: absolute;
    top: 46px;
    bottom: 1px;
    left: 0;
    z-index: 1;
    padding-top: 15px;
    padding-left: 16px;
    width: calc(100% + 3px);
    border-left: 1px solid rgba($color-black, .2);
    display: flex;
    align-items: flex-end;
    @include mobile {
      position: unset;
      padding-top: 0;
      padding-left: 0;
      border: 0;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }
  &__additional {
    color: $color-base-origin;
  }

  &__note {
    font-size: 12px;
    margin-top: 20px;
    color: rgba($color-black, .6);
  }
}
</style>
