<template>
  <div class="custom-checkbox" :class="{ 'custom-checkbox--error':error }">
    <input
      :id="id"
      :checked="value"
      type="checkbox"
      class="custom-checkbox__input"
      @change="onChange"
    >
    <label :for="id" class="custom-checkbox__label">
      <slot />
    </label>
  </div>
</template>

<script>
import { randomString } from '@/components/helpers/randomString.js'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: randomString()
    }
  },
  methods: {
    onChange(e) {
      if (e && e.target && e.target instanceof HTMLInputElement) {
        this.$emit('input', e.target.checked)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.custom-checkbox';
$checkbox-size: 23px;

#{$b} {
  display: flex;
  align-items: center;
  font-family: $font-family-inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  color: $black-true;
  min-height: $checkbox-size;

  // .custom-checkbox__input
  &__input {
    display: none;

    &:checked {
      & ~ #{$b}__label {
        &::after {
          opacity: 1;
          transform: translate(0, calc(-50% - 3px));
        }

        &::before {
          border-color: $color-secondary-3;
        }
      }
    }
  }

  // .custom-checkbox__label
  &__label {
    position: relative;
    padding-left: 40px;
    display: inline-block;
    transition: $transtion-default;
    cursor: pointer;

    @include hover {
      &::before {
        border-color: $color-base-origin;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: $checkbox-size;
      height: $checkbox-size;
      transform: translateY(-50%);
      border: 1px solid $color-secondary-3;
      border-radius: 4px;
      transition: $transtion-default;

      #{$b}--error & {
        border-color: $color-error;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 7px;
      width: 20px;
      height: 15px;
      background-image: url("data:image/svg+xml,%3Csvg width='23' height='18' viewBox='0 0 23 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 10.8667L7.09756 16L21 2' stroke='%23003DFF' stroke-width='2.5' stroke-linecap='round'/%3E%3C/svg%3E");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0;
      transition: $transtion-default;
      transform: translate(3px,-3px);
      z-index: 1;
    }
  }
}

</style>
