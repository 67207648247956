<template>
  <div class="calendar" :class="{ 'calendar--detail': isDetail }">
    <transition name="fade">
      <div v-if="isShow" class="calendar-inner">
        <div class="calendar-head">
          <div class="calendar-head__left">
            <div class="calendar-head__info">
              <div class="calendar-head__title">
                {{ getFormatDate(filterDate, "MMMM") }}
              </div>
              <div v-if="subtitle" class="calendar-head__subtitle" v-html="subtitle"></div>
            </div>
          </div>
          <div class="calendar-head__right">
            <div class="calendar-controls">
              <div class="calendar-controls__prev" @click="prevMonth">
                <inline-svg src="arrow-left" />
              </div>
              <div v-if="!isDetail" class="calendar-controls__count">
                <span>{{ getFormatDate(filterDate, "MM") }}</span>
                <span> / </span>
                <span>12</span>
              </div>
              <div class="calendar-controls__next" @click="nextMonth">
                <inline-svg src="arrow-right" />
              </div>
            </div>
          </div>
        </div>
        <div class="calendar-wrapper">
          <div class="calendar-week">
            <div
              class="calendar-week__day"
              :class="{ 'is-weekend': isWeekEnd(i) }"
              v-for="(day, i) in days"
              :key="'day' + i"
            >
              {{ day }}
            </div>
          </div>
          <div class="calendar-days" v-for="(item, i) in gridArray" :key="i">
            <div
              v-for="(date, i) in item"
              :key="i"
              class="calendar-days__day"
              :class="{
                'is-selected': isActiveDay(date),
                'is-event': isEventDay(date)
              }"
              @click="setDate(date)"
            >
              {{ date.getDate() }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Calendar",
  props: {
    subtitle: {
      type: String,
      default: ""
    },
    propDay: {
      type: Date,
      default: ""
    },
    eventDays: {
      type: Array
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isWaitChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShow: true,
      selectedDay: undefined,
      filterDate: new Date(),
      days: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
      locale: "ru"
    };
  },
  watch: {
    eventDays() {
      if (this.isWaitChange) {
        this.isShow = true;
      }
    }
  },
  mounted() {
    this.selectedDay = this.propDay;
    this.setDate(this.selectedDay);
  },
  methods: {
    prevMonth() {
      const tmpDate = this.filterDate;
      let tmpMonth = tmpDate.getMonth() - 1;
      let tmpYear = tmpDate.getFullYear();

      if (this.isWaitChange) {
        this.isShow = false;
      }
      if (tmpMonth === -1) {
        tmpYear--;
      }
      this.filterDate = new Date(tmpDate.setMonth(tmpMonth));
      this.$emit('change-month', tmpDate.getMonth() + 1, tmpYear);
    },
    nextMonth() {
      const tmpDate = this.filterDate;
      let tmpMonth = tmpDate.getMonth() + 1;
      let tmpYear = tmpDate.getFullYear();

      if (tmpMonth === 12) {
        tmpYear++;
      }

      if (this.isWaitChange) {
        this.isShow = false;
      }

      this.filterDate = new Date(tmpDate.setMonth(tmpMonth));
      this.$emit('change-month', tmpDate.getMonth() + 1, tmpYear);
    },
    setDate(date) {
      this.selectedDay = date;
      this.$emit("setdate", this.selectedDay);
    },
    isActiveDay(date) {
      return moment(date).isSame(this.selectedDay, 'day');
    },
    isEventDay(date) {
      return this.eventDays.some((element) => moment(element).isSame(moment(date), 'day'));
    },
    isWeekEnd(index) {
      return index === 5 || index === 6;
    },
    getCalendarMatrix(date) {
      const calendarMatrix = [];

      const startDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      const startDow = (startDay.getDay() + 6) % 7;
      const endDow = (lastDay.getDay() + 6) % 7;

      startDay.setDate(startDay.getDate() - startDow);
      lastDay.setDate(lastDay.getDate() + (6 - endDow));

      let week = [];
      while (startDay <= lastDay) {
        week.push(new Date(startDay));
        if (week.length === 7) {
          calendarMatrix.push(week);
          week = [];
        }

        startDay.setDate(startDay.getDate() + 1);
      }

      return calendarMatrix;
    },
    getFormatDate(date, format) {
      return date
        ? moment(date)
            .locale(this.locale)
            .format(format)
        : "";
    }
  },
  computed: {
    gridArray() {
      const grid = this.getCalendarMatrix(this.filterDate);
      return grid;
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".calendar";

#{$b} {
  display: block;

  &--detail {
    min-width: 442px;

    @include desktop {
      min-width: auto;
    }
  }

  &-head {
    display: flex;
    margin-bottom: 35px;

    #{$b}--detail & {
      align-items: stretch;
    }

    &__left {
      flex: 1 1 auto;
      padding-right: 30px;
    }

    &__info {
      #{$b}--detail & {
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &:after {
          content: "";
          width: 116px;
          height: 1.5px;
          position: absolute;
          top: 0.5em;
          left: #{-$offset-wrapper};
          background-color: $color-blue-line;
          background: linear-gradient(0deg, $color-blue-line 0%, #4f92ff 100%);

          @include low-desktop {
            display: none;
          }
        }
      }
    }

    &__title {
      display: block;
      font-weight: 300;
      font-size: 40px;
      line-height: 1;
      text-transform: capitalize;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      #{$b}--detail & {
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $color-base;
        margin: 0;

        &:not(:last-child) {
          &::after {
            content: "/";
            display: inline-block;
            vertical-align: baseline;
            margin: 0 10px;
            color: $color-base;
          }
        }
      }
    }

    &__subtitle {
      min-height: 20px;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color-base;

      #{$b}--detail & {
        color: inherit;
      }
    }
  }

  &-controls {
    margin-top: 50px;
    min-height: 20px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    #{$b}--detail & {
      margin-top: 0;
    }

    &__count {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color-gray;
      white-space: nowrap;
      margin: 0 15px;

      & > * {
        &:first-child {
          color: $color-black;
        }
      }
    }

    &__prev,
    &__next {
      outline: 0;
      text-decoration: none;
      user-select: none;
      cursor: pointer;
      flex-shrink: 0;
      width: 20.5px;
      height: 11px;
      transition: 0.3s;
      color: inherit;

      &:hover {
        &:not(.is-disabled) {
          color: $color-base;
        }
      }
    }
  }

  &-wrapper {
    margin: -18px;

    @include desktop {
      margin: -15px;
    }
  }

  &-week,
  &-days {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 8px;

      @include desktop {
        margin-bottom: 4px;
      }

      #{$b}--detail & {
        margin-bottom: 0;
      }
    }

    &__day {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: $color-black;

      @include desktop {
        width: 50px;
        height: 50px;
        border-radius: 50px;
      }

      #{$b}--detail & {
        color: $white-true;
      }
    }
  }

  &-week {
    &__day {
      &.is-weekend {
        color: $color-base;
      }
    }
  }

  &-days {
    &__day {
      cursor: default;
      pointer-events: none;
      color: $color-gray;
      transition: 0.2s ease-out;
      transition-property: color, background-color;

      #{$b}--detail & {
        color: #6387EC;
      }

      &:hover,
      &:focus {
        &:not(.is-selected) {
          color: $color-base;
        }
      }

      &.is-event {
        cursor: pointer;
        pointer-events: all;
        position: relative;
        color: $color-base;

        #{$b}--detail & {
          color: $white-true;
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          margin: 15px auto 0;
          background-color: currentColor;
          width: 3px;
          height: 3px;
          border-radius: 3px;
        }
      }

      &.is-selected {
        background-color: $color-base;
        color: $white-true;

        #{$b}--detail & {
          background-color: $white-true;
          color: $color-base;
        }
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: 0.2s ease;
    transition-property: opacity;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
