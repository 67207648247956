<template>
  <div class="initiatives-audience-form">
    <div class="initiatives-audience-form__field u-select u-select--small">
      <div class="initiatives-audience-form__label">
        Выберите аудиторию
      </div>
      <div>
        <v-select
          v-model="audience"
          :class="{ 'vs--error': $v.audience.$error && !$v.audience.required }"
          :clearable="false"
          :searchable="true"
          label="name"
          :components="{ OpenIndicator }"
          :reduce="option => option.id"
          :options="initialAudienceOptions"
          placeholder="..."
        >
          <template #no-options>
            Не найдено
          </template>
        </v-select>
        <ErrorFormWrapper
          :isVisible="$v.audience.$error && !$v.audience.required"
          class="initiatives-audience-form__error-field"
        >
          <div>Обязательное поле</div>
        </ErrorFormWrapper>
      </div>
    </div>
    <ErrorFormWrapper
      :isVisible="Boolean(errorsAfterSubmit.length)"
      class="initiatives-audience-form__feedback-errors"
    >
      <div
        v-for="error in errorsAfterSubmit"
        :key="error.code"
        class="initiatives-audience-form__feedback-error"
      >
        <span v-html="error.message" />
      </div>
    </ErrorFormWrapper>
    <div class="initiatives-audience-form__actions">
      <CustomButton
        class="initiatives-audience-form__btn-submit"
        theme="primary"
        size="md"
        :status="statusBtn"
        @click="submit"
      >
        Добавить
      </CustomButton>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { validationMixin } from "vuelidate";
import { required  } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  components: {vSelect},
  props: {
    initialAudienceOptions: {
      type: Array,
      default: () => []
    },
    actionAdd: {
      type: String,
      default: '/'
    },
    initiativeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      audience: '',
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      statusBtn: '',
      errorsAfterSubmit: []
    }
  },
  validations: {
    audience: { required }
  },
  methods: {
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return
      }

      this.statusBtn = 'loading'

      const options = {
        url: this.actionAdd,
        method: 'POST',
        data: {
          audience: this.audience,
          initiativeId: this.initiativeId
        }
      };

      const response = await axios(options);

      if (response.data.status === 'success') {
        this.successResponse(response)
      } else {
        this.errorResponse(response)
      }
    },
    successResponse(response) {
      this.statusBtn = 'success'
      this.$v.$reset();
      this.errorsAfterSubmit = []

      this.$root.$emit('audienceUpdate', response.data.data.audience)
      setTimeout(() => {
        this.$root.$refs.customModal.closeModal(null, null, true);
      }, 1000);
    },
    errorResponse(response) {
      this.statusBtn = ''
      this.errorsAfterSubmit = response.data.errors
    },
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.initiatives-audience-form';

#{$b} {

  // .initiatives-audience-form__label
  &__label {
    margin-left: 8px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.3;
    color: $black-true;
  }

  // .initiatives-audience-form__actions
  &__actions {
    margin-top: 64px;

    @include mobile {
      margin-top: 32px;
      text-align: center;
    }
  }

  // .initiatives-audience-form__feedback-error
  &__feedback-error {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 15px;

    @include mobile {
      font-size: 18px;
    }

    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
}
</style>
