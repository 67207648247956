<template>
  <div class="scientific-infrastructure">
    <div class="scientific-infrastructure__inner">
      <div class="scientific-infrastructure__header">
        <h2 class="scientific-infrastructure__title title-xl tt-uppercase" v-html="title" />
        <div class="scientific-infrastructure__filter">
          <div class="scientific-infrastructure__filter-item u-select">
            <v-select
              v-model="filter.region"
              :clearable="false"
              :searchable="true"
              label="name"
              :reduce="option => option.id"
              :options="regionOptions"
              @input="(val) => onFilter('region', val)"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
          </div>
           <div class="scientific-infrastructure__filter-item u-select">
            <v-select
              v-model="filter.date"
              :clearable="false"
              :searchable="true"
              label="name"
              :reduce="option => option.id"
              :options="dateOptions"
              placeholder="Год открытия"
              @input="(val) => onFilter('date', val)"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="scientific-infrastructure__body">
        <div class="scientific-infrastructure__list">
          <ScientificInfrastructureCard
            class="scientific-infrastructure__card"
            v-for="item in items"
            :key="`scientific-infrastructure__card-${item.id}`"
            :card="item"
          />
        </div>
      </div>
      <div v-if="pageNumber && initialNextPage" class="scientific-infrastructure__footer">
        <CustomButton
          class="scientific-infrastructure__btn"
          size="md"
          theme="primary"
          :status="btnStatus"
          @click="showMoreHandler"
        >
          Показать ещё
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import SimpleFilterMixin from '/frontend/mixins/SimpleFilterMixin.js'

export default {
  mixins: [ SimpleFilterMixin ],
  components: {
    vSelect
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    regionOptions: {
      type: Array,
      default: () => []
    },
    dateOptions: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      filter: {
        region: '',
        date: '',
      }
    }
  },
  computed: {
    filterQuery() {
      let filter = []

      for (const key in this.filter) {
        const value = this.filter[key]

        if (value) {
          filter.push(`${key}=${value}`)
        }
      }

      return filter.join('&')
    }
  },
  methods: {
    onFilter(name, id) {
      this.filter[name] = id

      this.loadList(this.filterQuery, true)
    }
  },
  created() {
    if (this.regionOptions.length) {
      this.filter.region = this.regionOptions[0].id
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.scientific-infrastructure';

$gap: 15px;

#{$b} {

  // .scientific-infrastructure__header
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    @include tablet {
      display: block;
    }

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .scientific-infrastructure__title
  &__title {
    margin: 0 24px 0 0;

    @include tablet {
      margin: 0 0 24px 0;
    }
  }

  // .scientific-infrastructure__filter
  &__filter {
    display: grid;
    grid-template-columns: 320px 200px;
    gap: 32px;
    align-items: center;

    @include mobile {
      display: block;
    }

    // .scientific-infrastructure__filter-item
    &-item {
      @include mobile {
        &:not(:last-child) {
          margin-bottom: 16px;
          width: 100%;
        }
      }
    }
  }

  // .scientific-infrastructure__list
  &__list {
    display: grid;
    grid-template-columns: repeat(2, calc((100% - $gap) / 2));
    gap: $gap;

    @include mobile {
      display: block;
    }
  }

  // .scientific-infrastructure__card
  &__card {
    @include mobile {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  // .scientific-infrastructure__footer
  &__footer {
    margin-top: 32px;
  }
}
</style>
