import { helpers } from "vuelidate/lib/validators";
import { parseStringToDate } from '/frontend/components/helpers/parseDate.js'

export function checkEmail(value) {
  return /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(value);
}

export function mustBeValidUrl(value) {
  // eslint-disable-next-line
  const reg = new RegExp(/^http[s]?:\/\/[a-zA-Zа-яёА-ЯЁ\d.-]+[:]?[\d]{0,4}[\/]?[a-zA-Zа-яёА-ЯЁ\d\/-]+/)
  return !helpers.req(value) || reg.test(value)
}

export function checkGmail(value) {
  return !value?.toLowerCase()?.includes('@gmail.')
}

export function dateInRange(value, max, min) {
  const valDate = value ? Date.parse(parseStringToDate(value)) : null
  const maxDate = max ? Date.parse(parseStringToDate(max)) : null
  const minDate = min ? Date.parse(parseStringToDate(min)) : null

  const isMaxValidate = maxDate ? valDate <= maxDate : true
  const isMinValidate = minDate ? valDate >= minDate : true

  return valDate ? (isMaxValidate && isMinValidate) : true
}
