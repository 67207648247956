// удаляет все повторяющиеся символы кроме одного
export function deleteExceptFirst(str, symbol = '.') {
  const firstSymbolIndex = str.indexOf(symbol)

  if (firstSymbolIndex === -1) {
    return str
  }

  const beforeFirstSymbol = str.slice(0, firstSymbolIndex + 1)
  const afterFirstSymbol = str.slice(firstSymbolIndex + 1)
  const removedSymbols = afterFirstSymbol.replaceAll(symbol, '')

  return beforeFirstSymbol.concat(removedSymbols)
}

// возвращает форматированную строку для инпута чисел
export function getFormattedValue(value) {
  if (String(value) === '0') {
    return '0'
  }

  const { int, fraction } = getParsedValue(value)

  const numberWithClearDots = deleteExceptFirst(int.replace(/,/g, '.'), '.')
  const numberValue = Number(numberWithClearDots.replace(/[^0-9.]/g, ''))

  return (numberValue || numberValue === 0) ?
    numberValue.toLocaleString('ru-RU').concat(fraction)
    : ''
}

// разбивает число на дробную и целую часть для инпута чисел
export function getParsedValue(value) {
  const val = deleteExceptFirst(
    String(value).trim().replace(/\./g, ',').replace(/[^0-9,]/g, ''),
    ','
  );

  const separatorSymbol = ','
  let intPart = ''
  let fractionPart = ''

  const intPartIndex = val.indexOf(separatorSymbol)

  if (intPartIndex !== -1) {
    intPart = val.slice(0, intPartIndex)
    fractionPart = val.slice(intPartIndex)
  } else {
    intPart = val
  }

  return { int: intPart, fraction: fractionPart }
}

export function isEmpty(value) {
  return typeof value === 'string' || Array.isArray(value)
    ? !value?.length
    : typeof value === 'object'
      ? !Object.keys(value).some(Boolean)
      :!value
}
