<template>
  <div class="not-found g-blur-bg">
    <div class="not-found__text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  props: {
    text: {
      type: String,
      default: "Ничего не найдено"
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".not-found";

#{$b} {
  display: block;
  padding: 40px 30px;
  text-align: center;
  border-radius: 24px;

  @include mobile {
    padding: 30px 20px;
  }

  &__text {
    font-size: 24px;
    line-height: 1.6;

    @include mobile {
      font-size: 16px;
    }
  }
}
</style>
