<template>
  <div class="talisman-work-form">
    <div class="talisman-work-form__body">
      <div class="talisman-work-form__body-wrapper">
        <div
          class="talisman-work-form__field"
          :class="{'talisman-work-form__field--is-error': isRequiredErrorField('fio')}"
        >
          <u-text-input
            class="u-text-input--smaller"
            name="fio"
            label="Фамилия, имя и отчество*"
            v-model="form.fio"
            placeholder="Иванов Иван Иванович"
            :error="isRequiredErrorField('fio')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('fio')"
            class="talisman-work-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
        <div
          class="talisman-work-form__field"
          :class="{'talisman-work-form__field--is-error': isRequiredErrorField('dateOfBirth')}"
        >
          <CustomCalendar
            v-model="form.dateOfBirth"
            :minDate="minDate"
            :maxDate="maxDate"
            subtitle="Дата рождения***"
            :isFormTheme="true"
            :is-error="isRequiredErrorField('dateOfBirth')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('dateOfBirth')"
            class="talisman-work-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
        <div
          class="talisman-work-form__field u-select u-select--small"
          :class="{'talisman-work-form__field--is-error': isRequiredErrorField('category')}"
        >
          <div class="talisman-work-form__label text-sm">
            Категория**
          </div>
          <div>
            <v-select
              v-model="form.category"
              :class="{ 'vs--error': isRequiredErrorField('category') }"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="categoryOptions"
              placeholder="Ваш статус"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
            <ErrorFormWrapper
              :isVisible="isRequiredErrorField('category')"
              class="talisman-work-form__error-field"
            >
              <div>Обязательное поле</div>
            </ErrorFormWrapper>
          </div>
        </div>
        <div
          class="talisman-work-form__field u-select u-select--small"
          :class="{'talisman-work-form__field--is-error': isRequiredErrorField('region')}"
        >
          <div class="talisman-work-form__label text-sm">
            Регион*
          </div>
          <div>
            <v-select
              v-model="form.region"
              :class="{ 'vs--error': isRequiredErrorField('region') }"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="regionOptions"
              placeholder="Регион участника"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
            <ErrorFormWrapper
              :isVisible="isRequiredErrorField('region')"
              class="talisman-work-form__error-field"
            >
              <div>Обязательное поле</div>
            </ErrorFormWrapper>
          </div>
        </div>
        <div
          class="talisman-work-form__field"
          :class="{'talisman-work-form__field--is-error': isRequiredErrorField('phone') || isMinErrorField('phone')}"
        >
          <u-text-input
            class="u-text-input--smaller"
            name="eventName"
            label="Контактный телефон*"
            v-model="form.phone"
            placeholder="+7 (999) 999-99-99"
            mask="+7 (###) ###-##-##"
            :error="isRequiredErrorField('phone') || isMinErrorField('phone')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('phone') || isMinErrorField('phone')"
            class="talisman-work-form__error-field"
          >
            <div v-show="isRequiredErrorField('phone')">Обязательное поле</div>
            <div v-show="isMinErrorField('phone') && !isRequiredErrorField('phone')">Введите номер полностью</div>
          </ErrorFormWrapper>
        </div>
        <div
          class="talisman-work-form__field"
          :class="{
            'talisman-work-form__field--is-error':
              isRequiredErrorField('email')
              || isEmailErrorField('email')
              || isGmailErrorField('email')
          }"
        >
          <u-text-input
            class="u-text-input--smaller"
            name="email"
            label="Адрес электронной почты*"
            v-model="form.email"
            placeholder="talisman@10nauki.ru"
            :error="
              isRequiredErrorField('email')
              || isEmailErrorField('email')
              || isGmailErrorField('email')
            "
          />
          <ErrorFormWrapper
            :isVisible="
              isRequiredErrorField('email')
              || isEmailErrorField('email')
              || isGmailErrorField('email')
            "
            class="talisman-work-form__error-field"
          >
            <div v-show="isRequiredErrorField('email')">Обязательное поле</div>
            <div v-show="isEmailErrorField('email')">Неправильный e-mail</div>
            <div v-show="isGmailErrorField('email') && !isEmailErrorField('email')">Укажите почту в российской зоне .ru</div>
          </ErrorFormWrapper>
        </div>
        <div
          class="talisman-work-form__field"
          :class="{'talisman-work-form__field--is-error': isRequiredErrorField('talismanName')}"
        >
          <u-text-input
            class="u-text-input--smaller"
            name="talismanName"
            label="Имя Талисмана*"
            v-model="form.talismanName"
            placeholder="Название вашего Талисмана"
            :error="isRequiredErrorField('talismanName')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('talismanName')"
            class="talisman-work-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
        <div
          class="talisman-work-form__field"
          :class="{
            'talisman-work-form__field--is-error':
              isRequiredErrorField('talismanDescription')
              || isMinErrorField('talismanDescription')
              || isMaxErrorField('talismanDescription')
            }
          "
        >
          <u-text-input
            class="u-text-input--smaller"
            name="talismanName"
            label="Описание Талисмана*"
            :isTextArea="true"
            v-model="form.talismanDescription"
            placeholder="Что изображено на картинке? Почему был выбран этот образ Талисмана? Каковы его наиболее важные черты? Описание должно быть от 100 до 1000 знаков. "
            :error="
              isRequiredErrorField('talismanDescription')
              || isMinErrorField('talismanDescription')
              || isMaxErrorField('talismanDescription')
            "
          />
          <ErrorFormWrapper
            :isVisible="
              isRequiredErrorField('talismanDescription')
              || isMinErrorField('talismanDescription')
              || isMaxErrorField('talismanDescription')
            "
            class="talisman-work-form__error-field"
          >
            <div v-show="isRequiredErrorField('talismanDescription')">Обязательное поле</div>
            <div v-show="isMinErrorField('talismanDescription')">Минимальное количество символов: {{ getMinForField('talismanDescription') }}</div>
            <div v-show="isMaxErrorField('talismanDescription')">Максимальное количество символов: {{ getMaxForField('talismanDescription') }}</div>
          </ErrorFormWrapper>
        </div>
      </div>
      <div class="talisman-work-form__checkboxes">
        <div
        class="talisman-work-form__checkboxes-item"
        :class="{'talisman-work-form__checkboxes-item--is-error': isCheckedErrorField('agreeRegulations')}"
      >
        <CustomCheckbox v-model="agreeRegulations" :error="isCheckedErrorField('agreeRegulations')">
          Согласен с
          <a
            class="u-link u-link--dark"
            href="/upload/documents/Правила_Конкурса_Талисман_Десятилетия_науки_и_технологий.pdf"
            target="_blank"
          >
            правилами конкурса
          </a>
        </CustomCheckbox>
        <ErrorFormWrapper
          :isVisible="isCheckedErrorField('agreeRegulations')"
          class="talisman-work-form__error-field"
        >
          <div>Обязательное поле</div>
        </ErrorFormWrapper>
      </div>
      <div
        class="talisman-work-form__checkboxes-item"
        :class="{'talisman-work-form__checkboxes-item--is-error': isCheckedErrorField('agreePolicy')}"
      >
        <CustomCheckbox v-model="agreePolicy" :error="isCheckedErrorField('agreePolicy')">
          Согласен с
          <a
            class="u-link u-link--dark"
            href="/upload/documents/Политика_конфиденциальности_наука.рф.pdf"
            target="_blank"
          >
            политикой конфиденциальности
          </a>
        </CustomCheckbox>
        <ErrorFormWrapper
          :isVisible="isCheckedErrorField('agreePolicy')"
          class="talisman-work-form__error-field"
        >
          <div>Обязательное поле</div>
        </ErrorFormWrapper>
      </div>
      </div>
      <div class="talisman-work-form__info text-sm">
        *Обязательное поле
        <br>
        **Носит справочный характер для организаторов конкурса
        <br>
        ***Если участнику конкурса меньше 14 лет, то персональные
        данные ребенка вносятся в заявку его родителем (законным
        представителем)
      </div>
      <div
        class="talisman-work-form__file talisman-work-form__field"
        :class="{'talisman-work-form__field--is-error': isRequiredErrorField('file')}"
      >
        <div>
          <div class="talisman-work-form__field-caption text-sm">
            Изображение Талисмана (от 1 до 4 изображений)
            в одном из форматов: {{ getValidFormatFileStr }}. Размер
            файла должен быть не более {{maxSizeFile}}&nbsp;Мб
          </div>
          <MediaLoader
            :initialFiles="form.file"
            :validFormat="validFormatFile"
            :maxSize="maxSizeFile"
            :maxCount="4"
            :error="isRequiredErrorField('file')"
            @change="onFileChange"
          />
        </div>
        <ErrorFormWrapper
          :isVisible="isRequiredErrorField('file')"
          class="talisman-work-form__error-field"
        >
          <div>Обязательное поле</div>
        </ErrorFormWrapper>
      </div>
    </div>
    <ErrorFormWrapper
      class="talisman-work-form__feedback-errors"
      :max-height="1000"
      :isVisible="Boolean(errorsAfterSubmit) && Boolean(errorsAfterSubmit.length)"
    >
      <div
        v-for="error in errorsAfterSubmit"
        :key="`talisman-work-form__feedback-error-${error.code}`"
        class="talisman-work-form__feedback-error"
      >
        <span v-html="error.message" />
      </div>
    </ErrorFormWrapper>
    <div class="talisman-work-form__actions">
      <CustomButton
        theme="primary"
        size="md"
        class="talisman-work-form__btn"
        @click="submitHandler"
      >
        Отправить
      </CustomButton>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { validationMixin } from "vuelidate";
import { required, email, minLength, sameAs, maxLength } from "vuelidate/lib/validators";
import { getValidFormatFileStr } from '/frontend/components/helpers/formHelpers.js'
import { checkGmail } from '/frontend/components/helpers/validate.js'

export default {
  inject: ['ymHandler'],
  mixins: [validationMixin],
  components: {vSelect},
  props: {
    validFormatFile: {
      type: Array,
      default: () => [
        'jpeg',
        'png',
        'jpg',
        'tiff',
        'svg',
        'pdf',
      ]
    },
    maxSizeFile: {
      type: Number,
      default: 15,
    },
    categoryOptions: {
      type: Array,
      default: () => []
    },
    regionOptions: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      form: {
        fio: '',
        dateOfBirth: '',
        category: '',
        region: '',
        phone: '',
        email: '',
        talismanName: '',
        talismanDescription: '',
        file: '',
      },
      agreeRegulations: false,
      agreePolicy: false,
      isInvalidForm: false,
      errorsAfterSubmit: [],
      minDate: null,
      maxDate: null,
      OpenIndicator: {
        render: createElement => createElement('span')
      },
    }
  },
  validations: {
    form: {
      fio: { required },
      dateOfBirth: { required },
      category: { required },
      region: { required },
      phone: { required, min: minLength(18) },
      email: { required, email, isGmail: checkGmail },
      talismanName: { required },
      talismanDescription: { required, min: minLength(100), max: maxLength(1000) },
      file: { required },
    },
    agreeRegulations: { checked: sameAs( () => true ) },
    agreePolicy: { checked: sameAs( () => true ) },
  },
  computed: {
    getValidFormatFileStr() {
      return getValidFormatFileStr(this.validFormatFile)
    },
    getFormData() {
      const data = new FormData()

      for (let prop in this.form) {
        if (prop === 'file' && Array.isArray(this.form[prop])) {
          this.form[prop].forEach(file => {
            data.append('file[]', file)
          })
        } else {
          data.append(prop, this.form[prop])
        }
      }

      return data
    },
  },
  methods: {
    isRequiredErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].required
    },
    isEmailErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].email
    },
    isMinErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].min
    },
    isMaxErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].max
    },
    isCheckedErrorField(field) {
      return this.$v[field].$error && !this.$v[field].checked
    },
    isGmailErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].isGmail
    },
    getMaxForField(field) {
      return this.$v.form[field].$params.max.max
    },
    getMinForField(field) {
      return this.$v.form[field].$params.min.min
    },
    onFileChange(file) {
      this.form.file = file
    },
    submitHandler() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.isInvalidForm = true
        return
      }

      this.isInvalidForm = false
      this.$emit('submit', this.getFormData)
      this.ymHandler( {operation: 'click_SendForm'} )
    },
  },
  created() {
    const minDate = new Date(1900, 0, 1)
    const maxDate = new Date()
    this.minDate = minDate.toLocaleDateString('ru-RU')
    this.maxDate = maxDate.toLocaleDateString('ru-RU')
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-work-form';

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;

  // .talisman-work-form__label
  &__label {
    text-transform: initial;
    margin-bottom: 8px;
    margin-left: 8px;

    &--lg-gap {
      margin-bottom: 17px;

      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  // .talisman-work-form__field
  &__field {
    position: relative;
    transition: $transtion-default;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    // .talisman-work-form__field--is-error
    &--is-error {
      @include mobile-min {
        margin-bottom: 36px !important;
      }
    }

    // .talisman-work-form__field-caption
    &-caption {
      margin-bottom: 8px;
    }
  }

  // .talisman-work-form__error-field
  &__error-field {
    @include mobile-min {
      position: absolute;
      top: 100%;
    }
  }

  // .talisman-work-form__checkboxes
  &__checkboxes {
    margin: 24px 0;
    // .talisman-work-form__checkboxes-item
    &-item {
      position: relative;
      transition: $transtion-default;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &--is-error {
        @include mobile-min {
          margin-bottom: 38px !important;
        }
      }
    }
  }

  // .talisman-work-form__info
  &__info {
    margin: 24px 0;

    @include mobile {
      margin: 16px 0;
    }
  }

  // .talisman-work-form__actions
  &__actions {
    margin-top: 24px;
  }

  // .talisman-work-form__btn
  &__btn {
    @include mobile {
      width: 100%;
    }
  }
}
</style>
