<template>
  <a
    class="partners-card"
    :href="card.url"
    target="_blank"
    v-if="isExistsCard"
  >
    <div class="partners-card__wrapper" :style="`background-image:url(${card.image})`">
      <!--img
        v-if="card.image"
        class="partners-card__logo"
        :src="card.image"
        :alt="card.title"
      -->
    </div>

    <div class="partners-card__info">
        <h3 class="partners-card__title">
          {{card.title}}
        </h3>
        <p
          v-if="Boolean(card.text)"
          class="partners-card__text"
        >
          {{card.text}}
        </p>
      </div>
  </a>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      require: true
    }
  },
  computed: {
    isExistsCard() {
      return Boolean(this.card) && Boolean(Object.values(this.card).length)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

.partners-card {
  font-family: $font-family-inter;
  color: black;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: $transtion-default;
  text-decoration: none;
  transition: $transtion-default;

  &:hover {
    @include mobile-min {
      transform: scale(1.01)
    }
  }

  &__wrapper {
    background: rgba(255, 255, 255, .9);
    border-radius: 16px;
    height: 200px;
    margin-bottom: 25px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__logo {
    height: 100%;
    margin: 0 auto;
    display: block;
  }

  &__info {
    margin-top: auto;
    text-align: center;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    margin: 0;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    margin: 7px 0 0 0;

    @include mobile {
      margin-top: 8px;
    }
  }
}
</style>
