<template>
  <div class="u-brandstyle-video-list">
    <div class="u-brandstyle-video-list__videos">
      <UBrandstyleVideo
        class="u-brandstyle-video-list__item"
        v-for="(item,i) in videoItems"
        :key="`u-brandstyle-video-item-${item.id}`"
        :data="item"
        @changeIndex="openVideo(i)"
      />
    </div>
    <div class="u-brandstyle-video-list__footer" v-if="showMore">
      <custom-button
        theme="primary"
        size="md"
        :disabled="isDisabledBtn"
        @click="loadVideos"
      >
        Показать ещё
      </custom-button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: "VideoList",
    props: {
      videos: {
        type: Array,
        default: () => [],
      },
      count: {
        type: [String, Number],
        default: '3',
      },
      pages: {
        type: [String, Number],
        required: true,
      },
      url: {
        type: String,
        default: '/local/ajax/videoPagination.php'
      }
    },
    data() {
      return {
        lightboxIndex: null,
        videoItems: [],
        nextPage: 2,
        isDisabledBtn: false
      }
    },
    computed: {
      showMore() {
        return this.nextPage <= this.pages;
      },
    },
    created() {
      this.videoItems = [...this.videos];
    },
    methods: {
      async loadVideos() {
        this.isDisabledBtn = true
        const res = await axios({
          method: 'get',
          url: this.url,
          params: {
            count: +this.count,
            page: this.nextPage++
          }
        });

        this.videoItems = this.videoItems.concat(res.data);
        this.isDisabledBtn = false
      },
      openVideo(i) {
        this.$root.$emit('openPopupGallery', i, this.videoItems)
      },
    }
  }
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

.u-brandstyle-video-list {

  // .u-brandstyle-video-list__videos
  &__videos {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 16px;

    @include tablet-only {
      grid-template-columns: repeat(2,1fr);
    }

    @include mobile {
      display: block;
    }
  }

  // .u-brandstyle-video-list__item
  &__item {
    @include tablet-only {
      &:nth-child(3n+1) {
        .u-brand-style-video__header {
          height: auto;
          aspect-ratio: 3/1;
        }
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    @include mobile {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  // .u-brandstyle-video-list__footer
  &__footer {
    margin-top: 32px;
    @include mobile {
      text-align: center;
      margin-top: 24px;
    }
  }

  .cool-lightbox__navigation {
    display: none !important;
  }
}
</style>
