<template>
  <div class="application-filter">
    <div class="application-filter__inner">
      <div class="application-filter__item">
        <FilterBtn
          class="application-filter__btn"
          :isActive="isActive('all')"
          @click="selectItem('all')"
        >
          Все
        </FilterBtn>
      </div>
      <div
        v-for="filter in filterList"
        :key="`application-filter__btn-${filter.code}`"
        class="application-filter__item"
      >
        <FilterBtn
          class="application-filter__btn"
          :isActive="isActive(filter.code)"
          @click="selectItem(filter.code)"
        >
          {{filter.name}}
        </FilterBtn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedItems: []
    }
  },
  methods: {
    selectItem(code) {
      if (code === 'all') {
        this.selectedItems = []
      } else if (this.isActive(code)) {
        this.selectedItems = this.selectedItems.filter(item => item !== code)
      } else {
        this.selectedItems = [...this.selectedItems, code]
      }

      if (this.selectedItems.length === this.filterList.length) {
        this.selectedItems = []
      }

      this.$root.$emit('applicationFilter', this.selectedItems)
    },
    isActive(code) {
      if (code === 'all') {
        return this.selectedItems.length === this.filterList.length || !this.selectedItems.length
      } else {
        return this.selectedItems.includes(code)
      }
    }
  },
}
</script>


<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: '.application-filter';

#{$b} {

  // .application-filter__inner
  &__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -5px -4px;
  }

  // .application-filter__item
  &__item {
    padding: 5px 4px;
  }

}
</style>
