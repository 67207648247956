<template>
  <object-fit-video
    class="hero-slider-item__video js-video-slide-video"
    v-bind="$attrs"
    @play="onPlay"
    @pause="onPause"
  >
      <source :src="src">
  </object-fit-video>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      require: true
    }
  },

  methods: {
    onPlay() {
      this.$root.$emit('playingVideo', true)
    },
    onPause() {
      this.$root.$emit('playingVideo', false)
    },
  }
}
</script>
