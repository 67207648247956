<template>
  <div class="u-caption-window">
    <div class="u-caption-window__content" v-html="caption" />
  </div>
</template>

<script>
export default {
  props: {
    caption: {
      type: String,
      defualt: ''
    }
  }
}
</script>

<style lang="scss">
$b: '.u-caption-window';

#{$b} {
  // .u-caption-window__content
  &__content {
    padding-right: 32px;
  }
}
</style>
