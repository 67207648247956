<template>
  <div class="photobank-filter">
    <div class="photobank-filter__top">
      <div class="photobank-filter__overflow" v-dragscroll.x:nochilddrag>
        <ul class="photobank-filter__list">
          <li v-for="(item, i) in filters" :key="i">
            <div
              class="photobank-filter__item"
              :class="{ 'is-active': item.isActive }"
              @click.prevent="toggleFilter(item, i)"
            >
              {{ item.name }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="photobank-filter__bottom">
      <ul class="photobank-filter__dropdowns">
        <li>
          <div class="photobank-filter__dropdown">
            <div class="select">
              <v-select
                v-model="selectedCategory"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.id"
                :options="categoryOptions"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
        <li>
          <div class="photobank-filter__dropdown">
            <div class="select">
              <v-select
                v-model="selectedSorting"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.id"
                :options="sortingOptions"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
      </ul>
      <ul class="photobank-filter__month-list">
        <li v-for="(item, i) in monthFilters" :key="i">
          <div
            class="photobank-filter__month-item"
            :class="{ 'is-active': item.isActive }"
            @click.prevent="toggleMonthFilter(item, i)"
          >
            <span>{{ item.name }}</span>
            <inline-svg v-if="item.isActive && i > 0" src="close"/>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import InlineSvg from './InlineSvg.vue';
import vSelect from 'vue-select'

export default {
  name: 'EventsFilter',
  components: { InlineSvg, vSelect },
  props: {
    categoryOptions: {
      type: Array,
      default: () => [
        { id: '', name: 'Все категории' },
        { id: '1', name: 'Категория 1' }
      ]
    },
    sortingOptions: {
      type: Array,
      default: () => [
        { id: '', name: 'Сначала новые' },
        { id: '1', name: 'Сначала старые' }
      ]
    },
    initialFilters: {
      type: Array,
      default: () => [
        { name: "Всё", filter: "all", isActive: true },
        { name: "Фото", filter: "photo", isActive: false },
        { name: "Видео", filter: "video", isActive: false },
      ]
    },
    initialMonthFilters: {
      type: Array,
      default: () => [
        { name: "Все", filter: "all", isActive: true },
        { name: "Янв", filter: "", isActive: false },
        { name: "Фев", filter: "", isActive: false },
        { name: "Мар", filter: "", isActive: false },
        { name: "Апр", filter: "", isActive: false },
        { name: "Май", filter: "", isActive: false },
        { name: "Июн", filter: "", isActive: false },
        { name: "Июл", filter: "", isActive: false },
        { name: "Авг", filter: "", isActive: false },
        { name: "Сен", filter: "", isActive: false },
        { name: "Окт", filter: "", isActive: false },
        { name: "Ноя", filter: "", isActive: false },
        { name: "Дек", filter: "", isActive: false },
      ]
    }
  },
  computed: {
    selectedMonths() {
      const result = [];
      this.monthFilters.map((item, key) => {
        if (item.isActive && key) {
          result.push(item.id);
        }
      });
      return result;
    },
    selectedFilters() {
      let result = "";
      this.filters.map(item => {
        if (item.isActive) {
          result = item.filter;
        }
      });
      return result;
    },
    filterState() {
      return {
        filter: this.selectedFilters,
        category: this.selectedCategory,
        sorting: this.selectedSorting,
        month: this.selectedMonths,
      };
    }
  },
  data() {
    return {
      filters: [],
      monthFilters: [],
      selectedCategory: "",
      selectedSorting: "",
      OpenIndicator: {
        render: createElement => createElement('span')
      },
    };
  },
  watch: {
    filterState() {
      this.$emit('change', this.filterState);
    }
  },
  created() {
    this.filters = this.initialFilters;
    this.monthFilters = this.initialMonthFilters;
  },
  methods: {
    toggleFilter(el, i) {
      this.filters.forEach(item => {
        item.isActive = false;
      });
      this.filters[i].isActive = !this.filters[i].isActive;
    },
    toggleMonthFilter(el, i) {
      const indexClear = 0;

      this.monthFilters[i].isActive = !this.monthFilters[i].isActive;

      if (el.filter === "all") {
        this.monthFilters.forEach(item => {
          item.isActive = false;
        });
        this.monthFilters[indexClear].isActive = true;
      } else {
        this.monthFilters[indexClear].isActive = !this.monthFilters.some(item => item.isActive);
      }
    },
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: '.photobank-filter';

#{$b} {
  display: block;
  position: relative;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    top: 11px;
    right: calc(100% + 38px);
    width: 116px;
    height: 2px;
    background-color: $color-base;

    @include desktop {
      right: calc(100% + 30px);
      width: 85px;
    }

    @include tablet {
      display: none;
    }
  }

  &__overflow {
    min-width: 100%;
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    box-sizing: border-box;

    @include tablet {
      margin-left: #{-$container-pd-left-tablet};
      margin-right: #{-$container-pd-right-tablet};
      padding-left: $container-pd-left-tablet;
      padding-right: $container-pd-right-tablet;
    }

    @include mobile {
      margin-left: #{-$container-pd-left-mobile};
      margin-right: #{-$container-pd-right-mobile};
      padding-left: $container-pd-left-mobile;
      padding-right: $container-pd-right-mobile;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &__top {
    margin-bottom: 70px;

    @include desktop {
      margin-bottom: 50px;
    }

    @include mobile {
      margin-bottom: 30px;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: flex-end;
    min-width: 100%;

    &>li {

      &:not(:last-child) {
        margin-right: 30px;

        @include low-desktop {
          margin-right: 26px;
        }
      }
    }
  }

  &__item {
    cursor: pointer;
    display: block;
    position: relative;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba($color: $white-true, $alpha: 0.7);
    padding-bottom: 40px;
    white-space: nowrap;

    @include mobile {
      font-size: 10px;
    }

    &::after {
      content: "";
      width: 100%;
      height: 7px;
      background-color: transparent;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &.is-active {
      color: $white-true;

      &::after {
        background-color: $color-base;
      }
    }
  }

  &__dropdowns {
    list-style: none;
    padding: 0;
    margin: 0 0 0 -40px;
    display: flex;
    flex-wrap: wrap;

    @include low-desktop {
      margin-left: -30px;
    }

    &:not(:last-child) {
      margin-bottom: 70px;

      @include tablet {
        margin-bottom: 20px;
      }

      @include mobile {
        margin-bottom: 24px;
      }
    }

    &>li {
      padding-left: 40px;
      width: 306px;

      @include low-desktop {
        padding-left: 30px;
        width: calc(100% / 3);
      }

      @include tablet {
        width: 50%;

        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }

      @include mobile {
        width: 100%;
      }
    }
  }

  &__dropdown {
    select {
      width: 100%;
    }
  }

  &__month-list {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include low-desktop {
      max-width: 650px;
    }

    &>li {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      @include low-desktop {
        margin-bottom: 12px;
      }

      @include mobile {
        font-size: 10px;
        margin-bottom: 5px;
      }

      &:not(:last-child) {
        margin-right: 32px;

        @include mobile {
          margin-right: 16px;
        }
      }

      &:first-child {
        margin-right: 0;
        display: flex;
        align-items: center;

        &::after {
          content: "/";
          color: #c7c7c7;
          margin: 0 32px;

          @include mobile {
            margin: 0 16px;
          }
        }
      }
    }
  }

  &__month-item {
    cursor: pointer;
    user-select: none;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    color: $color-black;
    border-radius: 30px;
    transition: .2s ease;
    transition-property: color, background-color, padding;

    @include mobile {
      height: 24px;
    }

    &.is-active {
      padding: 0 10px;
      background-color: $color-base;
      color: $white-true;

      @include mobile {
        padding: 0 8px;
      }
    }

    .inline-svg {
      width: 9px;
      height: 9px;
      margin: 0 0 1px 6px;
    }
  }
}
</style>
