<template>
  <div
    class="error-form-wrapper"
    :class="{'error-form-wrapper--is-visible': isVisible}"
    :style="errorFormStyle"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      maxHeight: 0,
      resizeObserver: null,
      mutationObserver: null,
    }
  },
  computed: {
    errorFormStyle() {
      return this.maxHeight ? `max-height: ${this.maxHeight}px` : ''
    }
  },
  methods: {
    setMaxHeight() {
      this.maxHeight = Math.ceil(this.$el.scrollHeight);
    },
    resizeObserverHandler(entries) {
      entries.forEach(() => {
        this.$nextTick().then(this.setMaxHeight)
      })
    },
    mutationObserverHandler() {
      this.$nextTick().then(this.setMaxHeight)
    }
  },
  mounted() {
    this.$nextTick().then(this.setMaxHeight)

    this.resizeObserver = new ResizeObserver(this.resizeObserverHandler)
    this.resizeObserver.observe(this.$el)

    this.mutationObserver = new MutationObserver(this.mutationObserverHandler)
    this.mutationObserver.observe(this.$el, { attributes: true, childList: true, subtree: true })
  },
  beforeDestroy() {
    if (this.$el && this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
    if (this.$el && this.mutationObserver) {
      this.mutationObserver.disconnect()
      this.mutationObserver = null
    }
  },
  watch: {
    isVisible() {
      this.$nextTick().then(this.setMaxHeight)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.error-form-wrapper';

#{$b} {
  display: block;
  font-size: 12px;
  line-height: 160%;
  color: $color-error;
  overflow: hidden;
  transition: $transtion-default;
  max-height: 0;

  &:not(#{$b}--is-visible) {
    margin: 0 !important;
    max-height: 0 !important;
  }

  &--is-visible {
    margin: 3px 0 0;
  }

  @include mobile-sm {
    font-size: 10px;
    line-height: 1.3;
  }
}
</style>
