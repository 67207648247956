import axios from "axios";

export default {
  namespaced: true,
  state: {
    statusSubmitBtn: '',
    statusDraftBtn: '',
    errorsAfterSubmit: [],
    errorsAfterGetEvent: [],
    disabledDraftBtn: false,
    disabledSubmitBtn: false,
    isLoadingGetEvent: false
  },

  mutations: {
    SET_STATE(state, { name, value }) {
      state[name] = value
    },

    RESET(state) {
      state.statusSubmitBtn = ''
      state.statusDraftBtn = ''
      state.errorsAfterSubmit = []
      state.errorsAfterGetEvent = []
      state.disabledDraftBtn = false
      state.disabledSubmitBtn = false
      state.isLoadingGetEvent = false
    }
  },

  actions: {
    async SEND({ commit }, { url, form, successHandler }) {
      commit('SET_STATE', { name: 'statusSubmitBtn', value: 'loading' })
      commit('SET_STATE', { name: 'isInvalidForm', value: false })
      commit('SET_STATE', { name: 'disabledDraftBtn', value: true })

      const options = {
        url,
        method: 'POST',
        data: form
      };
      const response = await axios(options);

      if (response.data.status === 'success') {
        commit('SET_STATE', { name: 'statusSubmitBtn', value: 'success' })
        commit('SET_STATE', { name: 'errorsAfterSubmit', value: [] })
        this.errorsAfterSubmit = []

        setTimeout(() => {
          if (successHandler) {
            successHandler(response)
          }

          commit('SET_STATE', { name: 'statusSubmitBtn', value: '' })
          commit('SET_STATE', { name: 'disabledDraftBtn', value: false })
        }, 3000);
      } else {
        commit('SET_STATE', { name: 'statusSubmitBtn', value: '' })
        commit('SET_STATE', { name: 'disabledDraftBtn', value: false })
        commit('SET_STATE', { name: 'errorsAfterSubmit', value: response.data.errors })
      }

      return response
    },

    async SAVE({ commit }, { url, form, successHandler }) {
      commit('SET_STATE', { name: 'statusDraftBtn', value: 'loading' })
      commit('SET_STATE', { name: 'disabledSubmitBtn', value: true })

      const options = {
        url,
        method: 'POST',
        data: form
      };
      const response = await axios(options);

      if (response.data.status === 'success') {
        commit('SET_STATE', { name: 'statusDraftBtn', value: 'success' })
        commit('SET_STATE', { name: 'errorsAfterSubmit', value: [] })

        setTimeout(() => {
          if (successHandler) {
            successHandler(response)
          }

          commit('SET_STATE', { name: 'statusDraftBtn', value: '' })
          commit('SET_STATE', { name: 'disabledSubmitBtn', value: false })
        }, 3000);
      } else {
        commit('SET_STATE', { name: 'disabledSubmitBtn', value: false })
        commit('SET_STATE', { name: 'statusDraftBtn', value: '' })
        commit('SET_STATE', { name: 'errorsAfterSubmit', value: response.data.errors })
      }
    },

    async GET_EVENT({ commit }, { url, id, successHandler }) {
      commit('SET_STATE', { name: 'errorsAfterGetEven', value: [] })
      commit('SET_STATE', { name: 'errorsAfterSubmit', value: [] })

      commit('SET_STATE', { name: 'isLoadingGetEvent', value: true })
      const response = await axios({ url, params: { id } })
      commit('SET_STATE', { name: 'isLoadingGetEvent', value: false })

      if (response.data.status === 'success') {
        if (successHandler) {
          successHandler(response)
        }
      } else {
        commit('SET_STATE', { name: 'errorsAfterGetEvent', value: response.data.errors })
      }
    },
  }
}
