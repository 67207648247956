<template>
  <div class="u-lk-raport-progress">
    <div v-if="currentInitiativeName" class="u-lk-raport-progress__initiative">
      По инициативе <strong>{{ currentInitiativeName }}</strong>
    </div>
    <div class="u-lk-raport-progress__filter">
      <CustomCalendar
        class="u-lk-raport-progress__calendar"
        v-model="datePeriod.dateFrom"
        :minDate="minDate"
        :maxDate="datePeriod.dateTo"
      />
      <CustomCalendar
        class="u-lk-raport-progress__calendar"
        v-model="datePeriod.dateTo"
        :minDate="datePeriod.dateFrom"
        :maxDate="maxDate"
      />
    </div>
    <div class="u-lk-raport-progress__body">
      <div class="u-lk-raport-progress__row">
        <div class="u-lk-raport-progress__col" v-for="(item, i) in events" :key="i">
          <UProgressBar
            class="u-lk-raport-progress__progress-bar"
            :title="item.title"
            :values="{
              current: item.values.current,
              max: item.values.max
            }"
          />
        </div>
      </div>
      <slot name="note"></slot>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ULkRaportProgress",
  props: {
    eventsList: {
      type: Array,
      default: () => []
    },
    code: {
      type: String,
      default: 'event_counter',
    },
    initiativeId: {
      type: String,
      default: '',
    },
    initiatives: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      events: [],
      datePeriod: {
        dateFrom: null,
        dateTo: null,
      },
      minDate: null,
      maxDate: null,
      currentInitiativeName: '',
      currentInitiativeId: this.initiativeId,
    }
  },
  mounted() {
    this.$root.$on('updateReportBlocks', (idInitiative) => {
      this.currentInitiativeId = idInitiative;
      this.currentInitiativeName = this.initiatives.find(item => item.id === idInitiative)?.name;

      this.toggleFilter();
    });
  },
  methods: {
    async toggleFilter() {
      let url = `/bitrix/services/main/ajax.php?mode=class&c=dalee:activity.report&action=filter&period=interval&dateFrom=${this.datePeriod.dateFrom}&dateTo=${this.datePeriod.dateTo}&section=${this.code}`;
      if (this.currentInitiativeId !== '') {
        url += `&initiativeId=${this.currentInitiativeId}`;
      }

      axios.get(url)
      .then((res) => {
        if (res.data.status === 'success') {
          this.events = res.data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
    }
  },
  created() {
    const minDate = new Date(2022, 0, 1)
    const maxDate = new Date()

    this.minDate = minDate.toLocaleDateString('ru-RU')
    this.datePeriod.dateFrom = this.minDate
    this.maxDate = maxDate.toLocaleDateString('ru-RU')
    this.datePeriod.dateTo = this.maxDate

    this.events = [...this.eventsList];

    if (this.currentInitiativeId) {
      this.currentInitiativeName = this.initiatives.find(item => item.id === this.currentInitiativeId)?.name;
    }
  },
  watch: {
    datePeriod: {
      handler() {
        this.toggleFilter()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: '.u-lk-raport-progress';

#{$b} {

  // .u-lk-raport-progress__filter
  &__filter {
    display: inline-grid;
    grid-template-columns: repeat(2, auto);
    gap: 16px;
    margin-bottom: 24px;

    @include tablet {
      width: 100%;
      justify-content: space-between;
      gap: 0;
    }

    @include mobile {
      display: block;
    }
  }

  // .u-lk-raport-progress__calendar
  &__calendar {
    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  // .u-lk-raport-progress__row
  &__row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;

    @include low-desktop {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      display: block;
    }
  }

  // .u-lk-raport-progress__col
  &__col {
    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  // .u-lk-raport-progress__initiative
  &__initiative {
    margin: -15px 0 15px;
  }
}

</style>
