<template>
  <a :href="card.target !== '_blank' ? hrefWithRegion(card.url) : card.url" :target="card.target" class="scientific-infrastructure-card">
    <div class="scientific-infrastructure-card__inner">
      <div v-if="card.img" class="scientific-infrastructure-card__preview">
        <img :src="card.img" class="scientific-infrastructure-card__preview-img">
      </div>
      <div class="scientific-infrastructure-card__content">
        <div class="scientific-infrastructure-card__content-header">
          <time v-if="card.date" class="scientific-infrastructure-card__date tt-uppercase fw-500" v-html="card.date" />
          <div v-if="card.partner" class="scientific-infrastructure-card__partner text-sm">
            <span>
              При поддержке:
            </span>
            <span v-html="card.partner" />
          </div>
        </div>
        <div class="scientific-infrastructure-card__content-body">
          <h3 class="scientific-infrastructure-card__title title-lg tt-uppercase ff-title" v-html="card.title" />
          <p v-if="card.description" class="scientific-infrastructure-card__description" v-html="card.description" />
        </div>
        <div class="scientific-infrastructure-card__content-footer">
          <div v-if="card.location" class="scientific-infrastructure-card__location text-sm" v-html="card.location" />
          <CustomButton class="scientific-infrastructure-card__btn">
            Подробнее
          </CustomButton>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.scientific-infrastructure-card';

#{$b} {
  display: block;
  text-decoration: none;

  // .scientific-infrastructure-card__inner
  &__inner {
    height: 100%;
    display: flex;
    background-color: $color-base;
    border-radius: 16px;
    color: $white-true;

    @include tablet {
      display: block;
    }
  }

  // .scientific-infrastructure-card__preview
  &__preview {
    width: 100%;
    max-width: 200px;
    border-radius: 16px;
    overflow: hidden;

    @include tablet {
      max-width: 100%;
      aspect-ratio: 376/200;
    }

    // .scientific-infrastructure-card__preview-img
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  // .scientific-infrastructure-card__content
  &__content {
    width: 100%;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;

    @include tablet {
      display: block;
      padding: 24px;
    }

    // .scientific-infrastructure-card__content-header
    &-header {
      margin-bottom: 24px;
    }

    // .scientific-infrastructure-card__content-body
    &-body {
      margin-top: auto;
      margin-bottom: 16px;
    }

    // .scientific-infrastructure-card__content-footer
    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include tablet {
        display: block;
      }
    }
  }

  // .scientific-infrastructure-card__date
  &__date {
    display: inline-block;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  // .scientific-infrastructure-card__title
  &__title {
    margin: 0;
  }

  // .scientific-infrastructure-card__description
  &__description {
    margin: 16px 0 0 0;
  }

  // .scientific-infrastructure-card__location
  &__location {
    margin-right: 16px;

    @include tablet {
      margin-bottom: 32px;
      margin-right: 0;
    }
  }
}

</style>
