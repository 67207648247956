<template>
  <div class="universities-banner-main" :style="`background-image: url(${background})`">
    <div class="universities-banner-main__wrapper">
      <div class="u-g-container">
        <div class="universities-banner-main__inner">
          <h1 class="universities-banner-main__title title-xxl" v-html="title" />
          <img v-if="image" :src="image" class="universities-banner-main__img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.universities-banner-main';

#{$b} {
  min-height: 379px;
  display: flex;
  color: $white-true;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: calc(var(--header-height) + 40px);
  transition: padding 0.3s ease;

  @include tablet {
    min-height: unset;
  }

  // .universities-banner-main__wrapper
  &__wrapper {
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }

  // .universities-banner-main__inner
  &__inner {
    padding-bottom: 60px;

    @include tablet {
      padding-top: 60px;
    }

    @include mobile {
      padding-top: 0;
      padding-bottom: 24px;
    }
  }

  // .universities-banner-main__title
  &__title {
    position: relative;
    z-index: 1;
    margin: 0;
    max-width: calc(100% - 180px);

    @include mobile {
      max-width: 100%;
    }
  }

  // .universities-banner-main__img
  &__img {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: auto;
  }
}
</style>
