<template>
  <div class="subscribe-form">
    <form v-if="!isSuccess" @submit.prevent="checkForm" action="" ref="form">
      <div class="subscribe-form__input">
        <text-input
          name="email"
          v-model="email"
          @input="onInput"
          :error="errors.email"
          :placeholder="inputPlaceholder"
        ></text-input>
        <div class="error-text" v-if="errors.email">Введите корректный E-mail</div>
      </div>
      <slot>
        <button type="submit" class="button button--base">Подписаться</button>
      </slot>
    </form>
    <div v-else class="subscribe-form__success" :class="{ _error: errorResponse }">
      <inline-svg v-if="!errorResponse" src="check"></inline-svg>
      {{ resultText }}
    </div>
  </div>
</template>

<script>
  import TextInput from "./TextInput";
  import { checkEmail } from "./helpers/validate"
  import InlineSvg from "./InlineSvg";
  const axios = require('axios');

  export default {
    name: "SubscribeForm",
    props: {
      inputPlaceholder: {
        type: String,
        require: true,
        default: "Электронная почта"
      }
    },
    data() {
      return {
        email: '',
        errors: {
          email: false
        },
        isSuccess: false,
        errorResponse: false,
        action: '/local/ajax/subscribe.php',
        resultText: ''
      }
    },
    components: {InlineSvg, TextInput},
    methods: {
      checkForm() {
        if (checkEmail(this.email.toLowerCase())) {
          const form = this.$refs.form;
          const formElements = form.querySelectorAll('input');
          const formData = new FormData();

          formElements.forEach((el) => {
            formData.set(el.name, el.value);
          });

          axios.post(this.action, formData)
            .then((result) => {
              if (result.data.error) {
                this.resultText = result.data.message;
                this.errorResponse = true;
              } else {
                this.resultText = 'Ваша заявка успешно отправлена!';
              }
              this.isSuccess = true;
            }).catch((err) => {
              console.error(err); // eslint-disable-line
            });
        } else {
          this.errors.email = true;
        }
      },
      onInput() {
        this.errors.email = false;
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/base/includes";
  .subscribe-form {
    &__input {
      position: relative;
    }

    &__success {
      display: flex;
      align-items: center;
      font-size: 22px;
      line-height: 160%;
      margin-bottom: 40px;

      @include mobile {
        font-size: 16px;
      }

      &._error {
        color: $color-red;
      }

      .inline-svg {
        width: 26px;
        height: 29px;
        margin-right: 16px;

        path {
          fill: none;
        }
      }
    }

    .error-text {
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
    }

    .button {
      margin-top: 50px;
    }
  }
</style>
