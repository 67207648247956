<template>
  <div class="custom-video-cards">
    <CustomVideoCard
      v-for="video in getVideoList"
      :key="`custom-video-cards__card-${video.id}`"
      :cardData="video"
      class="custom-video-cards__card"
      :class="{'custom-video-cards__card--is-large-tablet': video.isLargeTablet}"
    />
  </div>
</template>

<script>
export default {
  props: {
    videoList: {
      type: Array,
      default: () => []
    },
    isLargeLastCardInTablet: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getVideoList() {
      return this.isLargeLastCardInTablet
        ? this.videoList.map((card, i) => ({ ...card, isLargeTablet: Boolean(this.videoList.length % 2 && i === this.videoList.length - 1) }))
        : this.videoList
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.custom-video-cards';

#{$b} {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
  }
  @include mobile {
    display: block;
  }

  // .custom-video-cards__card
  &__card {

    @include mobile {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    // .custom-video-cards__card--is-large-tablet
    &--is-large-tablet {
      @include tablet-only {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
}
</style>
