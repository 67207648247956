<template>
  <div
    class="stories-modal"
    ref="inner"
    @touchstart="onTouchStart"
    @touchend="onTouchEnd"
    @touchmove="onTouchMove"
  >
    <div class="stories-modal__inner">
      <div
        class="stories-modal__close"
        @touchstart.stop=""
        @touchend.stop=""
        @touchmove.stop=""
        @click="$emit('close')"
      >
        <inline-svg class="stories-modal__close-icon" src="close" />
      </div>
      <div class="stories-modal__list">
        <StoriesCard
          :ref="`storiesCard${i}`"
          v-for="(stories, i) in list"
          :key="`stories-modal__card-${stories.id}`"
          class="stories-modal__card"
          :video="isCanLoadVideo(i) ? stories : defaultVideoObject"
          :style="storiesCardStyle"
          @click="onClickStories(i)"
          @storiesEnded="storiesEnded(i)"
          @progressClick="onProgressClick(i)"
        />
      </div>
      <div class="stories-modal__next">
        <inline-svg class="stories-modal__close-icon" src="triangle-down" />
      </div>
    </div>
  </div>
</template>

<script>
import StoriesMixin from '@/mixins/stories/StoriesMixin.js'

export default {
  mixins: [ StoriesMixin ],
  data() {
    return {
      activeIndex: 0,
      prevInnerScroll: 0,
      canSlide: false,
      translateY: 0,
      scrollGap: 40,
    }
  },
  computed: {
    currentStoriesRef() {
      if (this.breakpoint.isDesktop) {
        return
      }

      const storiesRef = this.getStoriesRef(this.activeIndex)
      if (!storiesRef) {
        return
      }

      return storiesRef
    },
    storiesCardStyle() {
      return {
        transform: `translateY(${this.translateY}px)`
      }
    }
  },
  methods: {
    onProgressClick(index) {
      if (index !== this.activeIndex) {
        this.goToNextStories(index)
        return
      }
    },
    onTouchStart(e) {
      this.canSlide = true
      this.prevInnerScroll = e.touches ? e.touches[0].pageY : e.pageY
    },
    onTouchEnd() {
      this.canSlide = false
    },
    onTouchMove(e) {
      if (!this.canSlide || !this.currentStoriesRef) {
        return
      }

      const prevIndex = this.activeIndex
      const scrollTop = e.touches ? e.touches[0].pageY : e.pageY
      const newIndex = scrollTop > this.prevInnerScroll ? this.activeIndex - 1 : scrollTop < this.prevInnerScroll ? this.activeIndex + 1 : this.activeIndex

      if (prevIndex !== newIndex) {
        this.canSlide = false
        this.goToNextStories(newIndex)
      }

      this.prevInnerScroll = e.touches ? e.touches[0].pageY : e.pageY

    }
  }
}
</script>


<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.stories-modal';

#{$b} {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 40px 16px;
  background: rgba($black-true, 0.8);
  @include removeScrollBar();

  // .stories-modal__inner
  &__inner {
    margin: auto !important;
    height: 100%;
  }

  // .stories-modal__list
  &__list {
    height: 100%;
  }

  // .stories-modal__card
  &__card {
    max-height: 100%;
    height: 100%;
    transition: all 0.2s ease;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  // .stories-modal__close
  &__close {
    position: fixed;
    top: 16px;
    right: 16px;
    width: 48px;
    height: 48px;
    background-color: $white-true;
    border-radius: 50%;
    padding: 12px;
    color: $color-purple;
    z-index: 2;
    cursor: pointer;
  }
}
</style>
