<template>
  <div class="childrens-playground-banner-image">
    <button
      v-for="(item, index) in list" :key="index"
      class="childrens-playground-banner-image__btn"
      :class="`childrens-playground-banner-image__btn--${index + 1}`"
      @click="selectTag(item.name)"
    >
      <span class="childrens-playground-banner-image__name">
        {{ item.name }}
      </span>
      <img class="childrens-playground-banner-image__icon" :src="item.img" alt="" />
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          img: '/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/banner-1.png',
          name: 'будь здоров',
        },
        {
          img: '/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/banner-2.png',
          name: 'космос',
        },
        {
          img: '/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/banner-3.png',
          name: 'технологии',
        },
        {
          img: '/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/banner-4.png',
          name: 'технологии',
        },
        {
          img: '/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/banner-5.png',
          name: 'технологии',
        },
        {
          img: '/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/banner-6.png',
          name: 'техника',
        },
        {
          img: '/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/banner-7.png',
          name: 'человек',
        },
        {
          img: '/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/banner-8.png',
          name: 'природа',
        },
      ],
    }
  },

  methods: {
    selectTag(name) {
      this.$root.$emit('select-tag', name);
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.childrens-playground-banner-image';

#{$b} {
  position: relative;
  display: block;
  margin-left: auto;
  top: -28px;
  right: 3px;
  --ratio: calc(352 / 329);
  width: 100%;
  aspect-ratio: 1/var(--ratio);
  // aspect-ratio: 352 / 329;
  // max-width: 100%;

  @include tablet-min-only {
    top: get-range-calc(27px, -28px, 1024px, 1400px);
  }

  @include tablet-only {
    width: get-range-calc(300px, 400px, 768px, 1023px);
    top: get-range-calc(0px, -54px, 768px, 1023px);
  }

  @include mobile {
    margin-top: 20px;
    top: 0;
    right: 0;
  }

  &__btn {
    display: inline-block;
    position: absolute;
    transition: 0.3s;
    line-height: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
    }

    &:hover {
      transform: scale(1.15);
      z-index: 1;

      #{$b}__name {
        transform: scale(0.85);
        opacity: 1;
      }
    }

    &--1 {
      width: 10%;
      top: 2%;
      right: 19%;

      #{$b}__name {
        top: 100%;
        left: -30%;
      }
    }

    &--2 {
      width: 19%;
      left: 36%;
      top: 11%;

      #{$b}__name {
        top: -30%;
        left: 40%;
      }
    }

    &--3 {
      width: 13%;
      left: 16%;
      top: 21%;
      z-index: 1;

      #{$b}__name {
        top: -42%;
        left: -80%;
      }
    }

    &--4 {
      bottom: 10%;
      left: 36%;
      width: 17%;

      #{$b}__name {
        top: -60%;
        left: 10%;
      }
    }

    &--5 {
      right: 16%;
      top: 21%;
      width: 27%;

      #{$b}__name {
        top: -12%;
        left: 70%;

        @include tablet {
          top: -15%;
          left: 30%;
        }
      }
    }

    &--6 {
      left: 10%;
      top: 32%;
      width: 40%;

      #{$b}__name {
        top: 13%;
        left: 13%;
      }
    }

    &--7 {
      right: 0;
      bottom: 39%;
      width: 11%;

      #{$b}__name {
        top: -40%;
        left: -100%;
      }
    }

    &--8 {
      bottom: 18%;
      right: 13%;
      width: 33%;

      #{$b}__name {
        top: 10%;
        left: 10%;
      }
    }
  }

  &__icon {
    animation: floater 1.5s infinite;
    animation-delay: 0.5s;
    transition: ease 0.5s;
    transform: translateY(-5%);
  }

  &__name {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px 16px;
    border-radius: 16px;
    background-color: $color-purple;
    color: $white-true;
    font-size: 20px;
    line-height: normal;
    opacity: 0;
    pointer-events: none;
    touch-action: none;
    z-index: 5;
    box-shadow: 0px 8px 24px 0px rgba($color-purple, 0.30);
    white-space: nowrap;

    @include low-desktop {
      padding: 6px 12px;
      font-size: 16px;
    }
  }

  @keyframes floater {
    0% { transform: translateY(-5%); }
    50% { transform: translateY(5%); }
  }
}
</style>
