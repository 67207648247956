<template>
  <div class="tabs">
    <div class="tabs-container">
      <ul class="tabs-list">
        <li
          v-for="(item, i) in tabs"
          :id="item.id"
          :key="i"
          :class="{ 'is-active': item.isActive }"
        >
          <a v-if="item.url" class="tabs-list__item" :href="hrefWithRegion(item.url)">
            <span>{{ item.title }}</span>
          </a>
          <span v-else class="tabs-list__item" @click.prevent="selectTab(i)">
            <span>{{ item.title }}</span>
          </span>
        </li>
      </ul>
    </div>
    <div class="tabs-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    current: { type: Boolean, default: false },
  },
  data() {
    return {
      tabs: [],
    };
  },
  mounted() {
    this.tabs = this.$children;
    this.selectTab(0);
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab, i) => {
        this.tabs[i].isActive = false;
      });
      this.tabs[selectedTab].isActive = true;
    },
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".tabs";

#{$b} {
  &-overflow {
    min-width: 100%;
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    box-sizing: border-box;

    @include tablet {
      margin-left: #{-$container-pd-left-tablet};
      margin-right: #{-$container-pd-left-tablet};
      padding-left: $container-pd-left-tablet;
      padding-right: $container-pd-left-tablet;
    }

    @include mobile {
      margin-left: #{-$container-pd-left-mobile};
      margin-right: #{-$container-pd-left-mobile};
      padding-left: $container-pd-left-mobile;
      padding-right: $container-pd-left-mobile;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &-list {
    width: max-content;
    display: inline-flex;
    align-items: center;
    border: 1px solid $color-base;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 10px;

    @include tablet {
      margin-bottom: 1.5rem;
    }

    & > li {
      height: 40px;

      @include mobile {
        height: 36px;
      }
    }

    &__item {
      cursor: pointer;
      display: flex;
      height: 100%;
      align-items: center;
      text-decoration: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      padding: 0 20px;
      color: $color-base;
      white-space: nowrap;
      transition: .2s ease;
      transition-property: color;

      @include mobile {
        font-weight: 500;
        font-size: 13px;
        line-height: 130%;
      }

      &:hover {
        color: $color-base;
      }

      .is-active & {
        color: #ffffff;
      }
    }

    .is-active {
      border: 1px solid $color-base;
      background-color: $color-base;
    }
  }
}
</style>
