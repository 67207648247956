<template>
  <div class="media-loader-controller">
    <MediaLoader
      ref="mediaLoader"
      :validFormat="validFormat"
      :maxSize="maxSize"
      :maxSizeVideo="maxSizeVideo"
      :maxCount="maxCount"
      :error="error"
      :initialFiles="initialFilesSrc"
      @change="onFileChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    maxSize: {
      type: Number,
      default: Infinity
    },
    validFormat: {
      type: Array,
      default: () => []
    },
    error: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    maxCount: {
      type: Number,
      default: Infinity
    },
    initialFiles: {
      type: Array,
      default: () => []
    },
    maxSizeVideo: {
      type: Number,
      default: Infinity
    },
  },
  computed: {
    initialFilesSrc() {
      return this.initialFiles || []
    }
  },
  methods: {
    onFileChange(files) {
      const newFiles = files.filter(file => file instanceof File)
      const oldFiles = this.initialFiles.map((file) => {
        const resultFile = file

        if (!file.id) {
          if (!files.includes(file.src)) {
            resultFile.isDeleted = true
          }
        } else {
          if (!files.find(item => item.id === file.id)) {
            resultFile.isDeleted = true
          }
        }

        return resultFile
      })

      this.$emit('change', {
        old: oldFiles,
        new: newFiles
      })
    },

    reInitFiles() {
      this.$refs.mediaLoader.reInit()
    }
  }
}
</script>


<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.media-loader-controller';

#{$b} {
  @include mobile-min {
    display: inline-block;
  }
}
</style>
