<script>
  import {Bar} from 'vue-chartjs';
  import 'chartjs-plugin-datalabels';

  export default {
    name: "BarChart",
    extends: Bar,
    props: {
      height: {
        type: Number,
        default: 210
      },
      dataSets: {
        type: Array,
        required: true
      },
      colors: {
        type: Array,
        required: true
      },
      maxEdgeValue: {
        type: Number
      }
    },
    data() {
      return {
        chartdata: {
          labels: this.dataSets,
          datasets: [
            {
              backgroundColor: this.colors,
              data: this.dataSets,
            }
          ],
          datalabels: {
            formatter: (value) => this.formatDataLabel(value),
          },
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: false },
          tooltips: { enabled: false },
          scales: {
            xAxes: [{
              gridLines : {
                display : false,
              },
              ticks: {
                display: false,
                padding: 20,
                fontColor: '#20202080',
                fontFamily: 'Montserrat',
              }
            }],
            yAxes: [{
              gridLines: {
                tickMarkLength: 0
              },
              ticks: {
                fontColor: '#20202080',
                fontFamily: 'Montserrat',
                padding: 25,
                suggestedMax: this.maxEdgeValue || this.calcSuggestedMax(this.dataSets),
                beginAtZero: true,
              }
            }],
          },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'top',
              textAlign: 'left',
              color: '#202020',
              font: {
                family: 'Montserrat',
                size: 20,
                lineHeight: 1,
                weight: 'normal'
              },
              offset: 0,
            }
          },
        }
      }
    },
    mounted() {
      this.renderChart(this.chartdata, this.options);

      this.$nextTick(() => {
        this.renderChart(this.chartdata, this.options);
      });
    },
    methods: {
      calcSuggestedMax(values) {
        const maxValue = values.reduce((max, val) => {
          const current = Array.isArray(val) ? Math.max(...val) : val;

          return Math.max(max, current);
        }, 0);

        return Math.ceil((maxValue) * 1.1);
      },
      formatDataLabel(value) {
        return value.toString().replace('.', ',');
      },
    }
  }
</script>
