<template>
  <div class="talisman-person-list">
    <div class="u-g-container">
      <div class="talisman-person-list__inner">
        <TalismanPerson
          v-for="person in personList"
          :key="`talisman-person-list__person-${person.id}`"
          class="talisman-person-list__person"
          :person="person"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    personList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-person-list';

#{$b} {
  margin: 64px 0;

  @include mobile {
    margin: 48px 0;
  }

  // .talisman-person-list__inner
  &__inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @include tablet {
      display: block;
    }
  }

  // .talisman-person-list__person
  &__person {
    height: 100%;

    @include tablet {
      height: auto;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
