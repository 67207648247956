import axios from 'axios';

export default {
  data() {
    return {
      filterOptions: {},
      pageList: [],
      filterErrors: {
        region: 'Выберите другой регион',
      },
      currentFilterError: ''
    }
  },
  computed: {
    isExistsPageList() {
      return this.pageList && this.pageList.length
    },
    isExistsFilterOptions() {
      return this.filterOptions && Object.values(this.filterOptions).length
    },
    getFilterParam() {
      const filterList = []
      const params = (new URL(document.location)).searchParams;

      for (let key in this.selectedOptions) {
        const value = this.selectedOptions[key]

        filterList.push({ key,value })
        params.set(key, value)
      }

      return {
        forUrl: params.toString(),
        list: filterList,
      }
    },
  },
  methods: {
    async onFilterHelper() {
      this.saveFilterParam()
      await this.onLoadFromApi()
    },
    getFilterParamForApi(prefix) {
      return this.getFilterParam.list
        .map(item => {
          return `${prefix}[${item.key}]=${item.value}`
        })
        .join('&')
    },
    initFilterFromUrlParams() {
      const params = (new URL(document.location)).searchParams;
      for (let key in this.selectedOptions) {
        this.selectedOptions[key] = params.get(key) || ''
      }
    },
    saveFilterParam() {
      const newSearch = this.getFilterParam.forUrl
      const baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      const resultUrl = `${baseUrl}?${newSearch}`
      history.pushState(null, null, resultUrl);
    },
    async onLoadFromApi() {
      this.$root.$emit('setLoading', true)
      const result = await Promise.all([this.loadOptions(), this.loadPage()])
      this.$root.$emit('setLoading', false)
      this.checkCurrentFilter()
      return result
    },
    async loadOptions() {
      const response = await axios.get(this.getUrlForLoadOptions)

      if (response.data.status === 'success') {
        const filterOptions = {}

        for (let key in response.data.data) {
          const options = response.data.data[key]
          const preset = this.filterOptionsPreset[key]
          filterOptions[key] = preset ? [preset , ...options] : options
        }

        this.filterOptions = filterOptions
      }
    },
    getFilterId(id, key, relatedKey) {
      const option = this.filterOptions[key].find(item => item.id === id)

      return option && option[relatedKey] ? option[relatedKey] : ''
    },
    setSelectedOption(id, key, relatedKey) {
      const newOptionId = this.getFilterId(id, key, relatedKey)
      if (!newOptionId) {
        return
      }

      this.selectedOptions[relatedKey] = newOptionId
    },
    clearFilterByKey(key) {
      if (!Object.hasOwn(this.filterOptions, key) || !Object.hasOwn(this.selectedOptions, key)) {
        return
      }

      const filterById = this.filterOptions[key].find(item => item.id == this.selectedOptions[key])

      if (!filterById) {
        this.selectedOptions[key] = ''
        this.currentFilterError = this.filterErrors[key] || ''
      }
    },
    checkCurrentFilter() {
      this.clearFilterByKey('region')
    }
  },
  async created() {
    this.initFilterFromUrlParams()
    await this.onLoadFromApi()
  }
}
