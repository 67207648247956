<template>
  <div class="lk-count__container">
    <ul class="events-filter__month-list _without-first">
      <li v-for="(item, i) in filters" :key="i">
        <div
          class="events-filter__month-item"
          :class="{ 'is-active': item.isActive }"
          @click.prevent="toggleFilter(item, i)"
        >
          <span>{{ item.name }}</span>
          <inline-svg v-if="item.isActive" src="close"/>
        </div>
      </li>
    </ul>
    <lk-count-graph :events="events" />
    <template v-if="showAdditionalGraph">
      <div class="lk-form__subtitle">Прирост за {{ currentAdditionalText }}</div>
      <lk-count-graph :events="events" additional />
    </template>
    <slot name="note"></slot>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: "LkRaportCount",
    props: {
      eventsList: {
        type: Array,
        default: () => [
          {
            title: 'Основные',
            value: 151355,
            valueAdditional: 15,
            color: '#203B87'
          },
          {
            title: 'Дополнительные',
            value: 175568,
            color: '#203b8799'
          },
          {
            title: 'Региональные',
            value: 134657,
            valueAdditional: 7,
            color: '#203b8773'
          }
        ]
      },
      momentFilterList: {
        type: Array,
        default: () => [
          {
            filter: 'now',
            name: 'на текущий момент',
            additionalName: '',
            isActive: true
          },
          {
            filter: 'week',
            name: 'за предыдущую неделю',
            additionalName: 'неделю',
            isActive: false
          },
          /*{
            filter: 'month',
            name: 'за предыдущий месяц',
            additionalName: 'месяц',
            isActive: false
          }*/
        ]
      },
      code: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        filters: [],
        events: [],
        showAdditionalGraph: false,
        currentAdditionalText: '',
      }
    },
    created() {
      this.filters = this.momentFilterList;
      this.events = [...this.eventsList];
    },
    methods: {
      async toggleFilter(el, i) {
        if (this.filters[i].isActive === true) {
          this.filters[i].isActive = !this.filters[i].isActive;
          this.filters[0].isActive = true;
        } else {
          this.filters.forEach(item => {
            item.isActive = false;
          });
          this.filters[i].isActive = true;
        }

        axios.get(`/bitrix/services/main/ajax.php?mode=class&c=dalee:activity.report&action=filter&period=${this.filters[i].filter}&section=${this.code}`)
          .then((res) => {
            if (res.data.status === 'success') {
              this.showAdditionalGraph = false;

              this.events = res.data.data.map((event) => {
                if (+event.valueAdditional) {
                  this.showAdditionalGraph = true;
                  if (i) {
                    this.currentAdditionalText = this.filters[i].additionalName;
                  }
                }

                event.value = +event.value;
                event.valueAdditional = +event.valueAdditional;

                return event;
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/base/includes";

  .lk-count {
    margin: 20px 0 0;
    &__container {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include mobile {
        flex-wrap: wrap;
      }
    }
    &__col {
      &:nth-child(1) {
        display: flex;
        align-items: flex-start;
        width: calc(74% - 20px);
        @include mobile {
          flex-wrap: wrap;
          width: 100%;
        }
      }
      &:nth-child(2) {
        width: calc(26% - 20px);
        @include mobile {
          width: 100%;
        }
      }
    }
    &__total {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
    }
    &__values {
      position: relative;
      padding: 36px 0;
      min-width: 25%;
      @include mobile {
        min-width: 100%;
        margin-bottom: 20px;
        padding: 22px 0;
      }

      .lk-count__col & {
        &:nth-last-child(2) {
          padding-bottom: 70px;

          @include mobile {
            padding-bottom: 22px;
          }
        }

        &:nth-last-child(3) {
          padding-bottom: 105px;

          @include mobile {
            padding-bottom: 22px;
          }
        }
      }
    }
    &__value {
      position: absolute;
      bottom: calc(100% - 20px);
      left: 0;
      font-size: 16px;
      line-height: 20px;
      white-space: nowrap;

      @include low-desktop {
        white-space: normal;
      }

      @include mobile {
        bottom: unset;
        top: 0;
      }

      span {
        display: inline-block;
        white-space: nowrap;
        &:not(:last-child) {
          margin-right: 4px;
        }
      }
    }
    &__bar {
      position: relative;
      z-index: 2;
      height: 10px;
      width: 100%;
      @include mobile {
        height: 0;
      }
    }
    &__title {
      position: absolute;
      top: 46px;
      bottom: 1px;
      left: 0;
      z-index: 1;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      padding-top: 15px;
      padding-left: 16px;
      width: calc(100% + 3px);
      border-left: 1px solid rgba($color-black, .2);
      display: flex;
      align-items: flex-end;
      @include mobile {
        top: unset;
        bottom: 1px;
        padding-top: 0;
        padding-left: 0;
        border: 0;
      }
    }
    &__additional {
      color: $color-base;
    }

    &__note {
      font-size: 12px;
      margin-top: 20px;
      color: rgba($color-black, .6);
    }

    & + .lk-form__subtitle {
      margin-top: 38px;
      margin-bottom: 18px;
    }
  }
</style>
