<template>
  <div class="u-event-form">
    <UTabs>
      <Tab title="Мероприятие">
        <LkPlanForm
          class="u-event-form__form"
          type="event"
          :initiativesOptions="initiativesOptions"
          :eventLevelOptions="eventLevelOptions"
          :regionOptions="regionOptions"
          :partnerOptions="partnerOptions"
          :meetingFormatOptions="meetingFormatOptions"
          :targetAudienceOptions="targetAudienceOptions"
          :eventObjectivesOptions="eventObjectivesOptions"
          :submitUrl="this.actionSave"
          :eventForm="{ initiative: initiativeId }"
          :multipleInitiative="multipleInitiative"
        />
      </Tab>
      <Tab title="Проект">
        <LkPlanForm
          class="u-event-form__form"
          type="project"
          :initiativesOptions="initiativesOptions"
          :eventLevelOptions="eventLevelOptions"
          :regionOptions="regionOptions"
          :partnerOptions="partnerOptions"
          :meetingFormatOptions="meetingFormatOptions"
          :targetAudienceOptions="targetAudienceOptions"
          :eventObjectivesOptions="eventObjectivesOptions"
          :submitUrl="this.actionSave"
          :projectForm="{ initiative: initiativeId }"
          :multipleInitiative="multipleInitiative"
        />
      </Tab>
    </UTabs>
  </div>
</template>

<script>

export default {
  props: {
    actionSave: {
      type: String,
      default: ''
    },
    initiativesOptions: {
      type: Array,
      default: () => []
    },
    eventLevelOptions: {
      type: Array,
      default: () => []
    },
    regionOptions: {
      type: Array,
      default: () => []
    },
    partnerOptions: {
      type: Array,
      default: () => []
    },
    meetingFormatOptions: {
      type: Array,
      default: () => []
    },
    eventObjectivesOptions: {
      type: Array,
      default: () => []
    },
    targetAudienceOptions: {
      type: Array,
      default: () => []
    },
    initiativeId: {
      type: String,
      default: ''
    },
    multipleInitiative: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: '.u-event-form';

#{$b} {

  // .u-event-form__form
  &__form {
    margin-top: 24px;

    @include mobile {
      margin-top: 0;
    }
  }
}
</style>
