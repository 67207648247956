<template>
  <div>
    <div class="lk-count__container">
      <ul class="events-filter__month-list _without-first">
        <li v-for="(item, i) in filters" :key="i">
          <div
            class="events-filter__month-item"
            :class="{ 'is-active': item.isActive }"
            @click.prevent="toggleFilter(item, i)"
          >
            <span>{{ item.name }}</span>
            <inline-svg v-if="item.isActive" src="close"/>
          </div>
        </li>
      </ul>
      <div class="lk-community">
        <div class="lk-community__row">
          <div class="lk-community__col">
            <div class="lk-community__donut">
              <template v-if="currentPeriod === 'now'">
                <svg width="100%" height="100%" :viewBox="`0 0 ${circleSize} ${circleSize}`" class="donut">
                  <circle class="donut-ring" :cx="circleSize / 2" :cy="circleSize / 2" r="15.91549430918954" fill="transparent" stroke="#5893fb1a" :stroke-width="circleSize / 8"></circle>
                  <circle class="donut-segment" :cx="circleSize / 2" :cy="circleSize / 2" r="15.91549430918954" fill="transparent" :stroke="events[0].color" :stroke-width="circleSize / 8" :stroke-dasharray="`${events[1].value} ${100 - events[1].value}`" stroke-dashoffset="25"></circle>
                  <g>
                    <text x="89%" y="50%" class="donut-text" style="font-size: 9%;" :style="{fill: ((0 <= events[1].value) && (events[1].value < 24)) ? '#202020' : '#fff' }">
                      {{events[1].value}}%
                    </text>
                  </g>
                </svg>
              </template>
              <template v-else>
                <svg width="100%" height="100%" :viewBox="`0 0 ${circleSize} ${circleSize}`" class="donut">
                  <circle class="donut-ring" :cx="circleSize / 2" :cy="circleSize / 2" r="15.91549430918954" fill="transparent" stroke="#5893fb1a" :stroke-width="circleSize / 8"></circle>
                  <circle class="donut-segment" :cx="circleSize / 2" :cy="circleSize / 2" r="15.91549430918954" fill="transparent" :stroke="events[0].color" :stroke-width="circleSize / 8" :stroke-dasharray="`${events[0].value} ${100 - events[0].value}`" stroke-dashoffset="25"></circle>
                  <g>
                    <text x="89%" y="50%" class="donut-text" style="font-size: 9%;" :style="{fill: ((0 <= events[0].value) && (events[0].value < 24)) ? '#202020' : '#fff' }">
                      {{events[0].value}}%
                    </text>
                  </g>
                </svg>
                <svg :key="dataKey" width="100%" height="100%" :viewBox="`0 0 ${circleSize*2} ${circleSize*2}`" class="donut">
                  <circle class="donut-ring" :cx="circleSize*2 / 2" :cy="circleSize*2 / 2" r="15.91549430918954" fill="transparent" stroke="#5893fb1a" :stroke-width="circleSize*2 / 8"></circle>
                  <circle class="donut-segment" :cx="circleSize*2 / 2" :cy="circleSize*2 / 2" r="15.91549430918954" fill="transparent" :stroke="events[1].color" :stroke-width="circleSize*2 / 8" :stroke-dasharray="`${events[1].value} ${100 - events[1].value}`" stroke-dashoffset="25"></circle>
                  <g class="donut-text">
                    <text  x="67%" y="50%" class="donut-text" style="font-size: 18%;" :style="{fill: ((0 <= events[1].value) && (events[1].value < 24)) ? '#202020' : '#fff' }">
                      {{events[1].value}}%
                    </text>
                  </g>
                </svg>
              </template>
            </div>
          </div>
          <div class="lk-community__col">
            <div class="lk-community__info">
              <div class="lk-community__total">Всего, {{total | formatNumber}} человек.</div>
              <ul class="lk-community__labels">
                <li v-if="currentPeriod !== 'now'">Вовлечено за все время — <span :style="{color: events[0].color}">{{events[0].value}}%</span></li>
                <li :key="dataKey">Вовлечено {{currentFilterName}} — <span :style="{color: events[currentPeriod === 'now' ? 0 : 1].color}">{{events[1].value}}%</span></li>
              </ul>
              <ul class="lk-community__legend">
                <li v-if="currentPeriod !== 'now'"><i :style="{backgroundColor: events[0].color}"></i>Все время</li>
                <li><i :style="{backgroundColor: events[currentPeriod === 'now' ? 0 : 1].color}"></i>{{currentFilterName | firstLetterUppercase}}</li>
              </ul>
            </div>
          </div>
        </div>
        <slot name="note"></slot>
      </div>
    </div>
    <template v-if="smiData.length">
      <div class="lk-form__divider-2"></div>
      <div class="lk-form__subtitle">Число публикаций в СМИ<sup>*</sup></div>
      <div class="lk-form__smi">
        <div class="lk-form__smi-row" :class="smiData.length === 2 ? 'lk-form__smi-row--two-items' : ''">
          <div v-for="item in smiData" :key="item.code" class="lk-form__smi-col">
            <div class="lk-form__smi-title">
              {{ item.name }}
            </div>
            <div class="lk-form__smi-value">
              <span>{{ item.value }}</span>
              <span
                v-if="item.additional"
                class="lk-form__smi-additional"
              >
              {{ item.additional > 0 ? `+${item.additional}` : item.additional }}
            </span>
            </div>
          </div>
        </div>
        <div class="lk-form__smi-note">*по данным медиалогии</div>
      </div>
    </template>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: "LkRaportCommunity",
    props: {
      eventsList: {
        type: Array,
        default: () => [
          {
            value: '3960',
            color: '#203B87'
          },
          {
            value: '1620',
            color: '#5893FB'
          }
        ]
      },
      totalCount: {
        type: String,
        default: '300000',
      },
      momentFilterList: {
        type: Array,
        default: () => [
          {
            filter: 'now',
            name: 'на текущий момент',
            isActive: true
          },
          {
            filter: 'week',
            name: 'за предыдущую неделю',
            isActive: false
          },
          {
            filter: 'month',
            name: 'за предыдущий месяц',
            isActive: false
          }
        ]
      },
      code: {
        type: String,
        required: true,
      },
      initialSmiData: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        circleSize: 37,
        total: 0,
        currentFilterName: 'на текущий момент',
        filters: [],
        events: [],
        smiData: [],
        dataKey: 0,
        currentPeriod: 'now'
      }
    },
    created() {
      this.filters = this.momentFilterList;
      this.total = +this.totalCount;
      this.events = this.transformData(this.eventsList);
      this.smiData = [...this.initialSmiData];
    },
    methods: {
      getPercentage(value) {
        return Math.round(+value / this.totalCount * 100);
      },
      transformData(eventsArr) {
        return eventsArr.map((evt) => {
          evt.value = +evt.value;
          return evt;
        });
      },
      toggleFilter(el, i) {
        let filterParam;

        if (this.filters[i].isActive === true) {
          this.filters[i].isActive = !this.filters[i].isActive;
          this.filters[0].isActive = true;
          this.currentFilterName = this.filters[0].name;
          filterParam = this.filters[0].filter;
        } else {
          this.filters.forEach(item => {
            item.isActive = false;
          });
          this.filters[i].isActive = true;
          this.currentFilterName = this.filters[i].name;
          filterParam = this.filters[i].filter;
        }

        axios.get(`/bitrix/services/main/ajax.php?mode=class&c=dalee:activity.report&action=filter&period=${filterParam}&section=${this.code}`)
          .then((res) => {
            this.events[1] = res.data.data.activities_professional[0];
            this.smiData = res.data.data.media;

            this.$nextTick(() => {
              this.dataKey++;
            });
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.currentPeriod = filterParam;
          });
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/base/includes";

  .lk-community {
    margin-top: 18px;
    margin-bottom: 100px;
    &__row {
      display: flex;
      @include mobile {
        flex-wrap: wrap;
      }
    }
    &__col {
      &:first-child {
        width: 308px;
        flex-shrink: 0;
        margin-right: 100px;
        @include tablet {
          margin-right: 50px;
        }
        @include mobile {
          width: auto;
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
      &:last-child {
        align-self: flex-end;
      }
    }
    &__donut {
      position: relative;
      width: 308px;
      height: 308px;
      @include mobile {
        width: 260px;
        height: 260px;
        margin: 0 auto;
      }

      .donut {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &-text {
          font-weight: bold;
        }
      }
    }
    &__total {
      font-weight: bold;
      font-size: 18px;
      line-height: 160%;
      margin-bottom: 16px;
    }
    &__labels {
      font-size: 16px;
      line-height: 20px;
      span {
        font-weight: bold;
      }
      li {
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
    &__legend {
      display: flex;
      margin-top: 100px;
      @include mobile {
        flex-direction: column;
        margin-top: 20px;
      }
      li {
        position: relative;
        padding-left: 21px;
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
      i {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
    }

    &__note {
      font-size: 12px;
      margin-top: 60px;
      color: rgba($color-black, .6);
      position: absolute;

      @include tablet {
        margin-top: 50px;
      }
    }
  }
</style>
