<template>
  <div class="modal-content">
    <div class="modal-content__title" v-html="title"></div>
    <div class="modal-content__message" v-html="message"></div>
    <div
      class="modal-content__actions"
      :class="buttons.length > 1 ? 'modal-content__actions--flex': ''"
    >
      <button
        v-for="(button, i) in buttons"
        :key="i"
        class="modal-content__button button"
        :class="`button--base${button.type ? `-${button.type}` : ''}`"
        type="button"
        @click="emitModalEvent($event, button.action, button.arg)"
        v-html="button.text"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: "ModalContent",
    props: {
      title: { type: String, default: '' },
      message: { type: String, default: '' },
      buttons: { type: Array, default: () => ([]) },
    },
    data() {
      return {};
    },
    methods: {
      emitModalEvent($evt, customEvt, evtArg = false) {
        if (evtArg) {
          this.$root.$emit(customEvt, evtArg);
        } else {
          this.$root.$emit(customEvt);
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/base/includes";

  $b: ".modal-content";

  #{$b} {
    &__title {
      display: block;
      font-weight: 700;
      font-size: 40px;
      line-height: 1;
      color: $color-black;
      padding-right: 30px;
      margin: 0 0 28px;

      @include mobile {
        font-size: 26px;
      }

      @media screen and (max-width: 350px) {
        word-break: break-word;
      }
    }

    &__message {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.6;
      margin-bottom: 25px;
    }

    &__actions {
      display: flex;
      justify-content: center;

      &--flex {
        justify-content: space-between;

        @include mobile {
          flex-direction: column;
          align-items: center;
        }
      }
    }

    &__button {
      padding: 0 35px;
      width: 180px;

      @include mobile {
        width: 100%;
      }

      &:only-child {
        width: 100%;
      }

      &:nth-child(2) {
        @include mobile {
          margin-top: 20px;
        }
      }
    }
  }
</style>
