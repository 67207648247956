<template>
  <div class="stories-card" :class="{'stories-card--is-paused': isPaused}">
    <div class="stories-card__inner">
      <div class="stories-card__bg">
        <div class="stories-card__bg-btn">
          <InlineSvg src="u-play" class="stories-card__bg-icon"/>
        </div>
      </div>
      <video
        ref="video"
        class="stories-card__video"
        :src="video.src"
        :poster="video.poster"
        playsInline
        preload="auto"
        @click="onClick"
      ></video>
      <div class="stories-card__progress" @click.stop="setProgress">
        <div
          class="stories-card__progress-bar"
          :style="progressBarStyle"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    video: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      currentTime: 0,
      duration: 0,
      isPaused: true,
    }
  },
  computed: {
    progressInPercent() {
      return (this.currentTime * 100) / this.duration;
    },
    progressBarStyle() {
      return {
        width: `${this.progressInPercent}%`
      }
    }
  },
  methods: {
    play() {
      this.$refs.video.play()
    },
    pause() {
      this.$refs.video.pause()
    },
    onClick() {
      this.$emit('click')
    },
    onTimeUpdate(e) {
      this.currentTime = e.target.currentTime
    },
    onEnded() {
      this.currentTime = 0
      this.$emit('storiesEnded')
    },
    setDuration(e) {
      this.duration = e.target.duration
    },
    onLoadeddata(e) {
      this.setDuration(e)

      this.$nextTick(() => {
        this.$emit('loadeddata')
      })
    },
    setProgress(e) {
      const { width, left } = e.currentTarget.getBoundingClientRect()
      const res = (((e.clientX - left) * 100) / width) * this.duration / 100

      this.$refs.video.currentTime = res
      this.$emit('progressClick')
    },
    onPlay() {
      this.isPaused = false
    },
    onPause() {
      this.isPaused = true
    }
  },
  mounted() {
    this.$nextTick(() => {
      const videoRef = this.$refs.video
      if (!videoRef) {
        return
      }

      videoRef.addEventListener('timeupdate', this.onTimeUpdate);
      videoRef.addEventListener('ended', this.onEnded);
      videoRef.addEventListener('loadeddata', this.onLoadeddata);
      videoRef.addEventListener('play', this.onPlay);
      videoRef.addEventListener('pause', this.onPause);
    })
  },
  beforeDestroy() {
    const videoRef = this.$refs.video
    if (!videoRef) {
      return
    }

    videoRef.removeEventListener('timeupdate', this.onTimeUpdate);
    videoRef.removeEventListener('ended', this.onEnded);
    videoRef.removeEventListener('loadeddata', this.onLoadeddata);
    videoRef.removeEventListener('play', this.onPlay);
    videoRef.removeEventListener('pause', this.onPause);
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.stories-card';

#{$b} {
  aspect-ratio: 368/654;
  width: 100%;
  height: auto;

  // .stories-card--is-paused
  &--is-paused {
    cursor: pointer;

    &:hover {
      @include mobile-min {
        #{$b}__bg-btn {
          color: $white-true;
          background-color: $color-purple;
        }
      }
    }
  }

  // .stories-card__inner
  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
  }

  // .stories-card__bg
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black-true, 0.5);
    transition: opacity 0.3s ease;
    pointer-events: none;
    touch-action: none;
    opacity: 0;
    border-radius: 24px;
    overflow: hidden;

    #{$b}--is-paused & {
      cursor: pointer;
      opacity: 1;
      z-index: 1;
    }

    // .stories-card__bg-btn
    &-btn {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 124px;
      height: 124px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $color-purple;
      background-color: $white-true;
      padding: 33px;
      border-radius: 50%;
      pointer-events: none;
      touch-action: none;
      z-index: 2;
      transition: color 0.3s ease, background-color 0.3s ease;
    }

    // .stories-card__bg-icon
    &-icon {
      margin-left: 10px;
    }
  }

  // .stories-card__video
  &__video {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    object-position: center;
    object-fit: cover;
    clip-path: polygon(12px 0, calc(100% - 12px) 0, 100% 12px, 100% calc(100% - 12px), calc(100% - 12px) 100%, 12px 100%, 0 calc(100% - 12px), 0 12px);
  }

  // .stories-card__progress
  &__progress {
    position: absolute;
    bottom: 25px;
    left: 16px;
    right: 16px;
    height: 4px;
    background-color: rgba($white-true, 0.5);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    z-index: 1;
    transition: $transtion-default;

    #{$b}--is-paused & {
      pointer-events: none;
      touch-action: none;
      opacity: 0;
    }

    // .stories-card__progress-bar
    &-bar {
      height: 100%;
      background-color: $white-true;
      transition: linear all 0.3s;
    }
  }
}
</style>
