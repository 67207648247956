<template>
  <section class="hero-slider" :class="{ 'hero-slider--with-navigation': isExistNavigationSlot}">
    <swiper ref="heroSwiper" :options="swiperOptions">
      <slot />
    </swiper>
    <div v-if="!disableNavigation" class="u-g-container hero-slider__container-controls">
      <div  class="hero-slider__controls-wrapper" >
        <div class="u-swiper-controls hero-slider__swiper-controls">
          <button
            ref="heroSwiperBtnPrev"
            class="u-swiper-controls__btn u-swiper-controls__btn--prev hero-slider__swiper-controls-btn hero-slider__swiper-controls-btn--prev">
          </button>
          <div
            class="u-swiper-controls__count hero-slide__count"
          >
          </div>
          <button
            ref="heroSwiperBtnNext"
            class="u-swiper-controls__btn u-swiper-controls__btn--next hero-slider__swiper-controls-btn hero-slider__swiper-controls-btn--next">
          </button>
        </div>
      </div>
    </div>
    <slot v-if="isExistNavigationSlot" name="navigation" />
  </section>
</template>

<script>
export default {
  inject: ['breakpoint'],
  name: "HeroSlider",
  data() {
    return {
      disableNavigation: false,
      swiperOptions: {
        slidesPerView: 1,
        loop: true,
        effect: "fade",
        fadeEffect: {
          crossFade: true
        },
        autoplay: {
          delay: 5000,
        },
        watchOverflow: true,
        navigation: {
          prevEl: '.hero-slider__swiper-controls-btn--prev',
          nextEl: '.hero-slider__swiper-controls-btn--next',
          disabledClass: "u-swiper-controls__btn--is-disabled"
        },
        pagination: {
          el: ".hero-slide__count",
          type: "fraction",
        },
        breakpoints: {
          1024: {
            pagination: {
              el: ".hero-slide__count",
              type: "fraction"
            }
          },
          1280: {
            allowTouchMove: false,
            pagination: {
              el: ".hero-slide__count",
              type: "fraction"
            }
          },
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.heroSwiper.$swiper;
    },
    isExistNavigationSlot() {
      return this.$slots?.navigation?.length > 0 && this.breakpoint.isDesktop
    }
  },
  mounted() {
    if (this.$refs.heroSwiperBtnPrev.classList.contains('is-disabled') && this.$refs.heroSwiperBtnNext.classList.contains('is-disabled')) {
      this.disableNavigation = true;
    }

    this.$root.$on('playingVideo', (status) => {
      if (status) {
        this.swiper.autoplay.stop()
        this.$el.classList.add('video-playing')
      } else {
        this.swiper.autoplay.start()
        this.$el.classList.remove('video-playing')
      }
    })
  }
};
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: ".hero-slider";
#{$b} {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  color: $white-true;

  &.video-playing {
    .u-figures-item {
      display: none;
    }
  }

  .swiper-slide {
    height: auto;
  }

  // .hero-slider__container-controls
  &__container-controls {
    position: relative;
  }

  // .hero-slider__swiper-controls
  &__swiper-controls {
    @include mobile {
      height: 38px;
      width: 111px;
      font-size: 14px;
    }

    // .hero-slider__swiper-controls-btn
    &-btn{
      @include mobile {
        width: 35px;
      }
    }
  }

  &__inner {
    position: relative;
  }

  &__controls-wrapper {
    position: absolute;
    right: $container-pd-desk;
    z-index: 10;

    #{$b}:not(#{$b}--with-navigation) & {
      bottom: 32px;
    }

    @include low-desktop {
      right: $container-pd-right-tablet;
    }

    @include mobile {
      right: $container-pd-right-mobile;
    }

    #{$b}--with-navigation & {
      bottom: calc(32px + (var(--navigation-height) / 2));
    }
  }

  &-item {
    position: relative;
    height: 100%;
    & > div {
      height: 100%;
    }
    &.is-played {
      &::before {
        content: "";
        @include full-abs;
        z-index: -1;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(19,50,134,1) 100%);
        opacity: 0.5;
      }
    }

    &__inner {
      height: 100%;
      min-height: 470px;
      position: relative;
      z-index: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 2rem 0;

      @include low-desktop {
        min-height: 420px;
        padding-top: 60px;
      }

      @include tablet {
        min-height: 350px;
      }

      @include mobile {
        padding-top: 160px;
        padding-bottom: 2rem;
      }

      #{$b}--with-navigation & {
        padding-bottom: calc((var(--navigation-height) / 2) + 2rem);
      }

      #{$b}:not(#{$b}--with-navigation) & {
        padding-bottom: 2rem;

        @include mobile {
          padding-bottom: 2rem;
        }
      }
    }

    &__text {
      max-width: 773px;

      @include desktop {
        max-width: 620px;
      }

      &:not(:last-child) {
        margin-bottom: 30px;

        @include mobile {
          margin-bottom: 18px;
        }
      }
    }

    .g-h4 {
      position: relative;
      color: $color-base;
      margin: 0 0 20px;

      @include mobile {
        margin-bottom: 18px;
      }
    }

    &__title {
      display: block;
      font-weight: 400;
      font-size: 40px;
      line-height: 100%;
      color: inherit;
      margin: 0 0 0 -5px;
      font-family: $font-family-main-head;
      // text-transform: uppercase;

      @include low-desktop {
        margin-left: -2px;
      }

      @include mobile {
        font-size: 22px;
        line-height: 100%;
      }

      &:not(:last-child) {
        margin-bottom: 20px;

        @include mobile {
          margin-bottom: 25px;
        }
      }
    }

    &__subtitle {
      max-width: 642px;
      display: block;
      font-size: 18px;
      line-height: 1.6;
      color: inherit;

      @include desktop {
        font-size: 16px;
      }

      @include mobile {
        font-size: 14px;
        line-height: 1.6;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &__image {
      height: 100%;
      max-width: 720px;
      position: absolute;
      bottom: 0;
      right: -60px;
      z-index: -1;

      @include desktop {
        right: -70px;
        max-width: 480px;
      }

      @include tablet {
        display: none;
      }

      #{$b}-item.is-played & {
        opacity: 0;
        visibility: hidden;
      }
    }

    &__bg-image {
      @include full-abs;
      @include bg-cover;
      z-index: -1;
      transition: .2s ease;
      transition-property: visibility, opacity;

      #{$b}-item.is-played & {
        opacity: 0;
        visibility: hidden;
      }

      &::after {
        content: "";
        @include full-abs;
        background-color: #041138;
        background: linear-gradient(to bottom, #041138, #000000);
        opacity: 0.5;
      }

      &>img {
        @include full-abs;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__video {
      @include full-abs;
      z-index: -2;
      object-fit: cover;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s ease;

      #{$b}-item.is-played & {
        opacity: 1;
        visibility: visible;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include tablet {
        justify-content: stretch;
      }

      @include mobile {
        align-items: flex-end;
      }

      & > * {
        &:not(:last-child) {
          @include tablet {
            margin-right: 30px;
          }
          @include mobile {
            margin-right: 18px;
          }
        }
      }
    }

    &__play-btn {
      position: absolute;
      right: 0;
      bottom: 100px;
      z-index: 2;

      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      color: $white-true;

      #{$b}-item.is-played & {
        color: $white-true;
        background-color: transparent;
      }

      @include tablet {
        background-color: $color-base;
        color: $white-true;

        .inline-svg g:first-child path {
          fill: $color-base;
        }
      }

      @include mobile {
        position: static;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        margin-bottom: -5px;
      }

      .play-icon {
        #{$b}-item.is-played & {
          display: none;
        }
      }

      .pause-icon {
        display: none;

        #{$b}-item.is-played & {
          display: block;
        }
      }

      #{$b}--with-navigation & {
        bottom: calc((var(--navigation-height) / 2) +  100px);

        @include mobile {
          padding-bottom: 100px;
        }
      }
    }

    // .hero-slider-item__learn-more
    &__learn-more {
      @include mobile {
        height: 36px;
        font-size: 13px;
        line-height: 130%;
        padding: 5px 15px;
      }
    }
  }

  &__img {
    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      z-index: -1;

      img {
        filter: brightness(50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;

        @include mobile {
          filter: brightness(50%);
          height: 100%;
          object-position: center;
        }
      }
    }
  }

  // .hero-slider__navigation-block
  &__navigation-block {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    transform: translateY(50%);
    z-index: 2;
  }
}
</style>
