<template>
  <div class="u-simple-card-list">
    <USimpleCard
      v-for="card in cardList"
      :key="`u-simple-card-list__item-${card.id}`"
      class="u-simple-card-list__item"
      :data="card"
      :buttonLabel="buttonLabel"
      @ymSend="onClick(card)"
    />
  </div>
</template>

<script>
export default {
  inject: ['ymHandler'],
  props: {
    cardList: {
      type: Array,
      default: () => []
    },
    buttonLabel: {
      type: String,
      default: ''
    },
    ymData: {
      type: Object,
      default: () => ({})
    },
    ymParamKey: {
      type: String,
      default: '',
    }
  },
  methods: {
    onClick(card) {
      if (this.ymData && Object.values(this.ymData).length) {
        const options = this.ymData.options || {}

        options[this.ymParamKey] = card.name

        this.ymHandler({...this.ymData, options})
      }
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-simple-card-list';
$gap: 15px;

#{$b} {
  display: grid;
  grid-template-columns: repeat(2, calc((100% - $gap) / 2));
  gap: $gap;

  @include mobile {
    display: block;
  }

  // .u-simple-card-list__item
  &__item {

    @include mobile {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
