<template>
  <div class="u-lk-personal">
    <u-figures
      class="lk-wrapper__figures"
      :items="[
        {'name':'f-lk-1','image':'/frontend/assets/img/bg-shapes/lines.svg','animation':'fade-in-left'},
      ]"
      :full-width="true"
      :is-oveflow="true"
      position="static"
    >
      <div>
        <div class="u-lk-form__row">
          <div v-if="isNotUndefined(form.fullName)" class="u-lk-form__col">
            <u-text-input
              class="u-text-input--smaller"
              name="fullName"
              label="ФИО *"
              v-model="form.fullName"
              editableAction="#"
              :error="isRequiredErrorField('fullName')"
              @change="submit"
              @blur="submit"
            />
            <ErrorFormWrapper :isVisible="isRequiredErrorField('fullName')">
              <div>Обязательное поле</div>
            </ErrorFormWrapper>
          </div>
          <div v-if="isNotUndefined(form.email)" class="u-lk-form__col">
            <u-text-input
              class="u-text-input--smaller"
              name="email"
              label="E-mail *"
              v-model="form.email"
              is-read-only
            />
          </div>
          <div v-if="isNotUndefined(form.phone)" class="u-lk-form__col">
            <u-text-input
              class="u-text-input--smaller"
              name="phone"
              label="Телефон *"
              v-model="form.phone"
              editableAction="#"
              placeholder="+7 (999) 999 99 99"
              mask="+7 (###) ###-##-##"
              :error="isRequiredErrorField('phone')"
              @change="submit"
              @blur="submit"
            />
            <ErrorFormWrapper :isVisible="isRequiredErrorField('phone')">
              <div>Обязательное поле</div>
            </ErrorFormWrapper>
          </div>
          <div v-if="isNotUndefined(form.role)" class="u-lk-form__col">
            <u-text-input
              class="u-text-input--smaller"
              name="role"
              label="Должность"
              v-model="form.role"
              editableAction="#"
              placeholder="Например, старший менеджер"
              @change="submit"
              @blur="submit"
            />
          </div>
          <div v-if="isNotUndefined(form.company)" class="u-lk-form__col">
            <u-text-input
              class="u-text-input--smaller"
              name="company"
              label="Наименование организации"
              v-model="form.company"
              editableAction="#"
              placeholder="Например, ООО «Организация»"
              @change="submit"
              @blur="submit"
            />
          </div>
        </div>
        <ErrorFormWrapper
          class="u-lk-personal__errors-after-submit"
          :max-height="1000"
          :isVisible="Boolean(errorsAfterSubmit.length)"
        >
          <div v-for="(error,i) in errorsAfterSubmit" :key="`error-after-submit-${i}`">
            <span v-html="error.message" />
          </div>
        </ErrorFormWrapper>
        <div class="u-lk-personal__actions">
          <CustomButton
            theme="primary"
            size="md"
            @click="openPassModal"
          >
            Сменить пароль
          </CustomButton>
        </div>
      </div>
    </u-figures>
  </div>

</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ULkPersonal",
  mixins: [validationMixin],
  props: {
    userInfo: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      form: this.userInfo,
      selectedRegion: '0',
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      errorsAfterSubmit: []
    }
  },
  validations() {
    return {
      form: this.getValidationsFields({
        fullName: { required },
        email: { required },
        phone: { required },
        // role: { required }, // TODO: временно убираем валидацию
        // company: { required },
      })
    }
  },
  methods: {
    async submit() {
      if (this.$v.$invalid) {
        return
      }

      const options = {
        url: '/bitrix/services/main/ajax.php?mode=class&c=dalee:personal_form&action=update',
        method: 'POST',
        data: this.form
      };

      const response = await axios(options);

      if (
        Boolean(response.data)
        && Boolean(response.data.errors)
        && Boolean(response.data.errors.length)
      ) {
        this.errorsAfterSubmit = response.data.errors
      } else {
        this.errorsAfterSubmit = []
      }
    },
    openPassModal() {
      this.$root.$refs.customModal.openModal(
        null,
        {
          name: 'change-pass',
          props: {},
          title: 'Смена пароля'
        },
        null,
        true
      );
    },
    isNotUndefined(value) {
      return typeof value !== 'undefined'
    },
    isRequiredErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].required
    },
    getValidationsFields(validationOptions = {}) {
      const validationsForm = {}

      for (let prop in this.userInfo) {
        if (Object.getOwnPropertyDescriptor(validationOptions, prop)) {
          validationsForm[prop] = validationOptions[prop]
        }
      }

      return validationsForm
    }
  },
  mounted() {
    this.$v.$touch();
  },
}
</script>

<style lang="scss">
  @import "/frontend/scss/base/u-includes";
  $b: '.u-lk-personal';

  #{$b} {
    max-width: 1128px;

    // .u-lk-personal__actions
    &__actions {
      margin-top: 64px;
      position: relative;

      @include mobile {
        text-align: center;
        margin-top: 48px;
      }
    }

    &__errors-after-submit {
      & > *:first-child {
        margin-top: 31px;
      }
    }
  }
</style>
