<template>
  <a
    class="initiatives-partners-card"
    :href="card.url"
    target="_blank"
    v-if="isExistsCard"
  >
    <img
      v-if="card.image"
      class="initiatives-partners-card__logo"
      :src="card.image"
      :alt="card.title"
    >
    <div class="initiatives-partners-card__info">
      <h3 class="initiatives-partners-card__title">
        {{card.title}}
      </h3>
      <p
        v-if="Boolean(card.text)"
        class="initiatives-partners-card__text"
      >
        {{card.text}}
      </p>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      require: true
    }
  },
  computed: {
    isExistsCard() {
      return Boolean(this.card) && Boolean(Object.values(this.card).length)
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

.initiatives-partners-card {
  height: 100%;
  min-height: 266px;
  font-family: $font-family-inter;
  color: $white-true;
  display: flex;
  flex-direction: column;
  padding: 23px 17px;
  border-radius: 12px;
  background-color: $color-base;
  cursor: default;
  text-decoration: none;
  transition: $transtion-default;

  &[href] {
    cursor: pointer;
  }

  &:hover {
    @include mobile-min {
      transform: scale(1.01)
    }
  }

  @include mobile {
    min-height: 286px;
    padding: 24px 35px 50px;
  }

  // .initiatives-partners-card__logo
  &__logo {
    margin: auto auto;
    max-width: 100%;

    @include mobile {
      margin-top: 0;
      margin-bottom: 32px;
    }
  }

  // .initiatives-partners-card__info
  &__info {
    margin-top: auto;
    text-align: center;
  }

  // .initiatives-partners-card__title
  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    margin: 0;
  }

  // .initiatives-partners-card__text
  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    margin: 7px 0 0 0;

    @include mobile {
      margin-top: 8px;
    }
  }
}
</style>
