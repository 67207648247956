<template>
  <div class="u-projects-filter">
    <div
      v-for="(tab,i) in tabs"
      :key="`u-projects-filter__tab-${i}`"
      class="u-projects-filter__tab"
      :class="{'u-projects-filter__tab--is-active': tab.query === currentTab}"
      @click="$emit('selectTab', tab.query)"
    >
      {{tab.name}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    currentTab: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-projects-filter';

#{$b} {
  font-family: $font-family-inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  display: flex;

  // .u-projects-filter__tab
  &__tab {
    padding-bottom: 24px;
    color: rgba($white-true, 0.5);
    position: relative;
    cursor: pointer;
    transition: $transtion-default;

    &:hover {
      color: $white-true;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $color-base;
      transition: $transtion-default;
      transform: scaleX(0);
    }

    &:not(:last-child) {
      margin-right: 24px;
    }

    &--is-active {
      color: $white-true;

      &:after {
        transform: scaleX(1);
      }
    }
  }
}
</style>
