<template>
  <article v-if="isExistsCardData" class="talisman-contestants-card tt-uppercase" @click="showMore">
    <div v-if="cardData.mainImg" class="talisman-contestants-card__preview">
      <img class="talisman-contestants-card__img" :src="cardData.mainImg" :alt="cardData.title">
    </div>
    <div class="talisman-contestants-card__info">
      <!-- <div class="talisman-contestants-card__votes">
        {{ getVotesCaption }}
      </div> -->
      <div class="talisman-contestants-card__title fw-500">
        {{ cardData.title }}
      </div>
      <div class="talisman-contestants-card__region fw-500">
        {{ cardData.region }}
      </div>
      <CustomButton
        class="talisman-contestants-card__btn"
        size="md"
      >
        Подробнее
      </CustomButton>
    </div>
  </article>
</template>

<script>
import numberHelper from '/frontend/components/helpers/numberHelper.js'

export default {
  inject: ['ymHandler'],
  props: {
    initialCardData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      cardData: JSON.parse(JSON.stringify(this.initialCardData))
    }
  },
  computed: {
    isExistsCardData() {
      return this.cardData && Object.values(this.cardData).length
    },
    getVotes() {
      return numberHelper(this.cardData.votes) || '0'
    },
    getVotesCaption() {
      const votes = this.cardData.votes
      const isExceptions = String(votes).endsWith('11')
        || String(votes).endsWith('12')
        || String(votes).endsWith('13')
        || String(votes).endsWith('14')

      let result = '';

      if (votes % 10 === 0) {
        result = 'голосов'
      } else if (votes % 10 === 1 && !isExceptions) {
        result = 'голос'
      } else if (votes % 10 > 1 && votes % 10 <= 4 && !isExceptions) {
        result = 'голоса'
      } else if (votes % 10 > 4 || isExceptions) {
        result = 'голосов'
      }

      return `${this.getVotes} ${result}`;
    },
  },
  methods: {
    showMore() {
      this.$emit('showMore', {...this.cardData, votesCaption: this.getVotesCaption})
      this.ymHandler({ code: 93982301, type: 'reachGoal', operation: 'click_MoreDetails' })
    },
    updateVote(id) {
      if (id === this.cardData.id) {
        this.cardData = { ...this.cardData, votes: this.cardData.votes + 1 }
      }
    }
  },
  created() {
    this.$root.$on('updateTalismanVote', this.updateVote)
  },
  beforeDestroy() {
    this.$root.$off('updateTalismanVote', this.updateVote)
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-contestants-card';
$info-witdh: 316px;
$pseudo-bg-width: 20px;

#{$b} {
  display: flex;
  font-family: $font-family-inter;
  color: $white-true;
  border-radius: 16px;
  cursor: pointer;

  @include tablet {
    display: block;
  }

  // .talisman-contestants-card__preview
  &__preview {
    width: calc(100% - $info-witdh);
    height: 100%;
    max-height: 202px;
    min-height: 100%;
    z-index: 1;

    @include tablet {
      width: 100%;
      height: auto;
      max-height: unset;
      min-height: unset;
      aspect-ratio: 292/230;
    }
  }

  // .talisman-contestants-card__image
  &__img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    object-position: top center;
  }

  // .talisman-contestants-card__info
  &__info {
    width: calc($info-witdh + $pseudo-bg-width);;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px calc(24px + $pseudo-bg-width);
    background-color: $color-base-origin;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-left: -#{$pseudo-bg-width};

    @include tablet {
      margin-left: 0;
      width: 100%;
      padding-left: 20px;
      padding-top: calc(20px + $pseudo-bg-width);
      margin-top: -#{$pseudo-bg-width};
      border-top-right-radius: 0;
      border-bottom-right-radius: 16px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 16px;
    }
  }

  // .talisman-contestants-card__votes
  &__votes {
    margin-bottom: 16px;
  }

  // .talisman-contestants-card__title
  &__title {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 16px;
  }

  // .talisman-contestants-card__region
  &__region {
    margin-bottom: 16px;
    letter-spacing: 1.6px;
  }

  // .talisman-contestants-card__btn
  &__btn {
    align-self: flex-end;
    margin-top: auto;

    @include mobile {
      width: 100%;
    }
  }
}
</style>
