/**
 * Фотогалерея
 * вынес из компонента фотогалереи UGallerySlider
 *
 * если необходима кастомизация по кол-ву отображаемых элементов в карусели,
 * подключаем миксин, дублируем swiperOptions,
 * задаем необходимое кол-во отображаемых элементов (слайдов)
 */
export default {
  props: {
    initialItems: { // {type: ('video'|'image'), file: {src: ''}}
      type: Array,
      default: ()=> []
    },
  },
  data(){
    return {
      isUpdatedSwiper: true,
      paginationEl: this.$refs.paginationFraction,
    }
  },
  computed: {
    swiper() {
      return this.$refs.gallerySwiper.$swiper;
    },
    swiperOptions() {
      return {
        slidesPerView: 1.29,
        slidesPerGroup: 1,
        spaceBetween: 16,
        navigation: {
          prevEl: this.$refs.btnPrev,
          nextEl: this.$refs.btnNext,
          disabledClass: "u-swiper-controls__btn--is-disabled"
        },
        pagination: {
          el: this.paginationEl,
          type: "bullets",
          bulletClass: "u-swiper-pagination__bullet",
          bulletActiveClass: "u-swiper-pagination__bullet--is-active",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 1.6,
            slidesPerGroup: 1,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          },
          1280: {
            slidesPerView: 2.45,
            slidesPerGroup: 2,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          }
        }
      }
    },
    galleryInLightBox() {
      return this.initialItems.map((item) => ({
        cover: this.isVideo(item) ? item.file.poster : item.file.src,
        src: item.file.src
      }))
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateSwiper)
    this.updateSwiper()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSwiper)
  },
  methods: {
    setBackground(item) {
      const image = this.isVideo(item) ? '/frontend/assets/img/placeholder-video-gallery.svg' : item.file.src
      return `background-image: url('${image}')`
    },
    isVideo(item) {
      return item.type === 'video'
    },
    updateSwiper(){
      this.isUpdatedSwiper = false
      this.setPaginationEl()
      this.$nextTick(()=> {
        this.isUpdatedSwiper = true
      })
    },
    setPaginationEl(){
      if (window.innerWidth > 767) {
        this.paginationEl = this.$refs.paginationFraction
      } else {
        this.paginationEl = this.$refs.paginationMobile
      }
    },
    onLightboxClose() {
      this.$root.$emit('closePopupGallery')
    },
    onOpenLigthBox(i) {
      this.$root.$emit('openPopupGallery', i, this.galleryInLightBox, { loop: true })
    }
  },
}
