<template>
  <section class="u-news-list">
    <UNewsCard
      v-for="(news,i) in newsList"
      :key="`u-news-page__news-card${news.id}`"
      :cardData="news"
      :size="newsCardSizeClass(i)"
      class="u-news-list__item"
    />
  </section>
</template>

<script>
export default {
  props: {
    newsList: {
      type: Array,
      require: true
    },
  },
  methods: {
    newsCardSizeClass(index) {
      return (((index - 1) % 10 === 0) || ((index - 5) % 10 === 0)) ? 'large' : 'default'
    },
  }
}
</script>


