<template>
  <div class="brandstyle-panel brandstyle__panel">
    <div class="brandstyle-head">
      <div class="brandstyle-head__left">
        <h2 class="brandstyle-title" v-html="title"></h2>
      </div>
      <div class="brandstyle-head__right">
        <div class="brandstyle-head__labels">
          <custom-button
            v-for="(link,i) in links"
            :key="`brandstyle-panel__btn-${i}`"
            class="brandstyle-head__labels-btn"
            theme="outline-black"
            size="md"
            tag="a"
            :href="link.href"
            target="_blank"
            download
          >
            <inline-svg class="brandstyle-head__labels-icon" src="u-arr-download"></inline-svg>
            {{link.title}}
          </custom-button>
        </div>
      </div>
    </div>
    <div class="brandstyle-panel__text u-typography" v-html="description"></div>
    <div class="brandstyle-panel__logos">
      <BrandstyleLogo
        v-for="(logo,i) in logos"
        :key="`brandstyle-panel__logo-${i}`"
        :logo="logo"
      />
    </div>
    <div class="brandstyle-panel__logos-mob">
      <swiper ref="logosSwiper" :options="swiperOptions">
        <swiper-slide
          v-for="(logo,i) in logos"
          :key="`brandstyle-panel__logo-mob-${i}`"
        >
          <BrandstyleLogo
            class="brandstyle-panel__logo-mob"
            :logo="logo"
          />
        </swiper-slide>
      </swiper>
      <div class="brandstyle-panel__pagination">
        <div class="u-swiper-pagination brandstyle-panel__pagination-el"></div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    links: {
      type: Array,
      default: ()=> [
        {title:'Logo.png', href:'/upload/documents/brandstyle/Logo_PNG.zip'},
        {title:'Logo.eps', href:'/upload/documents/brandstyle/Logo_EPS.zip'}
      ]
    },
    description: {
      type: String,
      default: ''
    },
    logos: {
      type: Array,
      default: ()=> [
        {img:'/frontend/assets/img/brandstyle/logo-type-01.png', text:'Для светлого фона', darkTheme: false},
        {img:'/frontend/assets/img/brandstyle/logo-type-02.png', text:'Для тёмного фона', darkTheme: true},
        {img:'/frontend/assets/img/brandstyle/logo-type-03.png', text:'Охранное поле', darkTheme: false}
      ]
    }
  },
  computed: {
    swiper() {
      return this.$refs.logosSwiper.$swiper
    },
    swiperOptions() {
      return {
        slidesPerView: 1.13,
        spaceBetween: 16,
        pagination: {
          el: ".brandstyle-panel__pagination-el",
          type: "bullets",
          bulletClass: "u-swiper-pagination__bullet",
          bulletActiveClass: "u-swiper-pagination__bullet--is-active",
          clickable: true
        },
      }
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.brandstyle-panel';

#{$b} {

  // .brandstyle-panel__text
  &__text {
    margin: 0 0 32px 0;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .brandstyle-panel__logos
  &__logos {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 16px;

    @include mobile {
      display: none;
    }
  }

  // .brandstyle-panel__logos-mob
  &__logos-mob {
    @include mobile-min {
      display: none;
    }

    .swiper-container {
      @include mobile {
        padding: 0 15px;
        margin: 0 -15px;
      }
    }

    .swiper-slide {
      @include mobile {
        height: auto;
      }
    }
  }

  // .brandstyle-panel__logo-mob
  &__logo-mob {
    @include mobile {
      aspect-ratio: 330/280;
    }
  }

  // .brandstyle-panel__pagination
  &__pagination {
    @include mobile-min {
      display: none;
    }

    @include mobile {
      display: flex;
      justify-content: center;
      margin-top: 24px;
    }
  }
}
</style>
