<template>
  <div class="science-playground-video">
    <div class="science-playground-video__inner">
      <div class="science-playground-video__wrapp">
        <video
          :src="video.src"
          class="science-playground-video__video"
          controls
          playsInline
          preload="auto"
        ></video>
      </div>
      <div v-if="video.caption" class="science-playground-video__caption" v-html="video.caption" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    video: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.science-playground-video';

#{$b} {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  // .science-playground-video__inner
  &__inner {
    height: 100%;
  }

  // .science-playground-video__wrapp
  &__wrapp {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // .science-playground-video__video
  &__video {
    max-width: 100%;
    max-height: 100%;
    border-radius: 48px;

    @include tablet {
      border-radius: 24px;
    }
  }

  // .science-playground-video__caption
  &__caption {
    color: $white-true;
    text-align: center;
    margin-top: 16px;
  }
}
</style>
