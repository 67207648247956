<template>
  <div class="partner-creater-block" :class="{ 'partner-creater-block--is-loading': isLoading }">
    <div class="partner-creater-block__inner">
      <div class="partner-creater-block__field">
        <u-text-input
          :value="innFind"
          class="u-text-input--smaller partner-creater-block__input"
          inputType="number"
          :label="label"
          :placeholder="placeholder"
          :error="!!errors?.length"
          :isReadOnly="isLoading"
          :isLoading="isLoading"
          :maxLength="10"
          @input="onInput"
        />
        <div v-if="confirmPartner" class="partner-creater-block__confirm">
          <div class="partner-creater-block__confirm-caption">
            Найдено: <span class="fw-600" v-html="confirmPartner.name" />
          </div>
          <div class="partner-creater-block__confirm-actions">
            <CustomButton
              theme="primary"
              @click="setPartner(confirmPartner)"
            >
              Добавить
            </CustomButton>
            <CustomButton
              theme="outline-primary"
              @click="clear"
            >
              Отменить
            </CustomButton>
          </div>
        </div>
        <ErrorFormWrapper
          :isVisible="!!errors?.length"
          class="partner-creater-block__errors"
        >
          <div v-for="(e, i) in errors" :key="`partner-creater-block__error-${i}`" v-html="e" />
        </ErrorFormWrapper>
      </div>
      <div v-if="partners?.length" class="partner-creater-block__partners">
        <div
          v-for="partner in partners"
          :key="`partner-creater-block__partners-item-${partner.id}`"
          class="partner-creater-block__partners-item"
        >
          <div class="partner-creater-block__partner">
            <span v-html="partner.name" class="partner-creater-block__partner-name" />
            <span class="partner-creater-block__partner-delete" @click="deletePartner(partner.id)" >
              <inline-svg src="close" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PartnerCreaterController } from '@/components/pages/lk/RegionPlan/PartnerCreater/partnerCreaterController.js'

export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      innFind: '',
      isLoading: false,
      errorAfterSubmit: '',
      duplicateError: '',
      partnerCreaterController: null,
      confirmPartner: null
    }
  },

  computed: {
    partners: {
      get() {
        return this.value
      },
      set(partners) {
        this.$emit('input', partners)
      }
    },
    errors() {
      let errors = []

      if (this.error) {
        errors.push(this.error)
      }

      if (this.errorAfterSubmit) {
        errors.push(this.errorAfterSubmit)
      }

      if (this.duplicateError) {
        errors.push(this.duplicateError)
      }

      return errors
    }
  },

  methods: {
    onInput(v) {
      if (this.innFind !== v) {
        this.duplicateError = ''
        this.errorAfterSubmit = ''
      }

      const condition = v.length === 10 && this.innFind !== v
      this.innFind = v

      if (condition) {
        this.find()
      }
    },

    deletePartner(id) {
      this.partners = this.partners.filter(item => item.id !== id)
    },

    setPartner(partner) {
      this.partners = [...this.partners, partner]
      this.confirmPartner = null
    },

    setConfirmPartner(partner) {
      const isExistPartner = this.partners.some(item => item.id === partner.id)

      if (isExistPartner) {
        this.duplicateError = `<b>${partner.name}</b> уже добавлен`
        this.confirmPartner = null
      } else {
        this.duplicateError = ''
        this.confirmPartner = partner
      }
    },

    clear() {
      this.duplicateError = ''
      this.confirmPartner = ''
      this.errorAfterSubmit = ''
    },

    async find() {
      this.clear()

      this.isLoading = true
      const response = await this.partnerCreaterController.find(this.innFind)
      this.isLoading = false

      if (response.data?.status === 'success') {
        this.setConfirmPartner(response.data.data.partner)
      } else {
        this.errorAfterSubmit = response.data.message
      }
    },
  },

  created() {
    this.partnerCreaterController = new PartnerCreaterController()
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.partner-creater-block';

#{$b} {

  // .partner-creater-block__field
  &__field {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  // .partner-creater-block__confirm
  &__confirm {
    margin: 8px 0;

    &:last-child {
      margin-bottom: 0;
    }

    // .partner-creater-block__confirm-actions
    &-actions {
      margin-top: 8px;
      display: flex;
      align-items: center;

      & > * {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }

  // .partner-creater-block__partners
  &__partners {

    // .partner-creater-block__partners-item
    &-item {
      font-size: 14px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  // .partner-creater-block__partner
  &__partner {

    // .partner-creater-block__partner-delete
    &-delete {
      display: inline-flex;
      width: 20px;
      height: 20px;
      cursor: pointer;
      padding: 5px;
      background-color: rgba($color-base, 0.15);
      border-radius: 50%;
      margin-left: 10px;
      transition: $transtion-default;

      &:hover {
        background-color: $color-base;
        color: $white-true;
      }
    }
  }
}
</style>
