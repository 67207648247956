<template>
  <div class="science-playground-gallery">
    <Swiper class="science-playground-gallery__swiper" :options="swiperOptions">
      <SwiperSlide
        v-for="(item, i) in gallery"
        :key="`science-playground-gallery__slide-${i}`"
        class="science-playground-gallery__slide"
      >
        <div class="science-playground-gallery__item">
          <div class="science-playground-gallery__preview">
            <img :src="item" class="science-playground-gallery__img">
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
    <div v-show="hasNavigation" class="science-playground-gallery__navigation">
      <div
        class="science-playground-gallery__navigation-item science-playground-gallery__navigation-item--prev"
        :class="`science-playground-gallery__navigation-prev-${_uid}`"
      >
        <inline-svg src="u-arr-left-r" class="science-playground-gallery__navigation-icon"/>
      </div>
      <div
        class="science-playground-gallery__navigation-item science-playground-gallery__navigation-item--next"
        :class="`science-playground-gallery__navigation-next-${_uid}`"
      >
        <inline-svg src="u-arr-right-r" class="science-playground-gallery__navigation-icon"/>
      </div>
    </div>
    <div v-show="hasNavigation" class="science-playground-gallery__pagination" :class="`science-playground-gallery__pagination-${_uid}`" />
  </div>
</template>

<script>
export default {
  props: {
    gallery: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasNavigation() {
      return this.gallery.length > 1
    },
    swiperOptions() {
      return {
        spaceBetween: 32,
        navigation: {
          enabled: this.hasNavigation,
          prevEl: `.science-playground-gallery__navigation-prev-${this._uid}`,
          nextEl: `.science-playground-gallery__navigation-next-${this._uid}`,
          disabledClass: "science-playground-gallery__navigation-item--is-disabled"
        },
        pagination: {
          enabled: this.hasNavigation,
          el: `.science-playground-gallery__pagination-${this._uid}`,
          type: 'bullets',
          bulletClass: 'u-swiper-pagination__bullet-v3',
          bulletActiveClass: 'u-swiper-pagination__bullet--is-active',
          clickable: true
        },
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.science-playground-gallery';

#{$b} {
  height: 100%;

  // .science-playground-gallery__swiper
  &__swiper {
    height: 100%;
  }

  // .science-playground-gallery__slide
  &__slide {
    height: auto;
  }

  // .science-playground-gallery__item
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  // .science-playground-gallery__img
  &__img {
    width: 100%;
    object-fit: contain;
    border-radius: 48px;
  }

  // .science-playground-gallery__preview
  &__preview {
    position: relative;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 48px;
    overflow: hidden;
  }

  // .science-playground-gallery__pagination
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  // .science-playground-gallery__navigation
  &__navigation {

    @include tablet {
      display: none;
    }

    // .science-playground-gallery__navigation-item
    &-item {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 64px;
      height: 64px;
      background-color: $white-true;
      border-radius: 50%;
      padding: 12px;
      z-index: 1;
      cursor: pointer;
      color: $color-purple;
      transition: $transtion-default;

      // .science-playground-gallery__navigation-item--prev
      &--prev {
        left: 32px;
        padding-right: 18px;
      }

      // .science-playground-gallery__navigation-item--next
      &--next {
        right: 32px;
        padding-left: 18px;
      }

      &--is-disabled {
        background-color: $white-true !important;
        color: $color-purple !important;
        pointer-events: none;
        touch-action: none;
        opacity: 0.75;
      }

      @include mobile-min {
        &:hover {
          background-color: $color-purple;
          color: $white-true;
        }
      }
    }

    // .science-playground-gallery__navigation-icon
    &-icon {
      path {
        fill: transparent !important;
        stroke: currentColor;
      }
    }
  }
}
</style>
