<template>
  <div class="partners-block">
    <div class="partners-block__header">
      <div v-if="isExistsPartnerList" class="partners-block__caption partners-block__text">
        При поддержке:
      </div>
    </div>
    <template v-if="isExistsPartnerList">
      <div
        v-if="breakpoint.isMobile"
        class="partners-block__slider"
        @touchstart="pauseAutoplay"
        @touchend="resumeAutoplay"
      >
        <swiper
          ref="partnersSwiper"
          :options="swiperOption"
          class="partners-block__swiper"
        >
          <swiper-slide
            v-for="partner in partnerList"
            :key="`partners-block__swiper-slide-${partner.id}`"
            class="partners-block__swiper-slide"
          >
            <PartnerLogo :initial-data="partner"/>
          </swiper-slide>
        </swiper>
        <div v-if="!isOnePartner" class="partners-block__pagination">
          <div class="u-swiper-pagination" :class="`partners-block__pagination-${_uid}`" />
        </div>
      </div>
      <div v-else class="partners-block__grid">
        <PartnerLogo
          v-for="partner in partnerList"
          :key="`partners-block__grid-item-${partner.id}`"
          :initial-data="partner"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  inject: ['breakpoint'],
  props: {
    partnerList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isMoreThanTwoPartners() {
      return this.partnerList.length > 2
    },
    isOnePartner() {
      return this.partnerList.length === 1
    },
    isExistsPartnerList() {
      return this.partnerList.length > 0
    },
    swiperOption() {
      return {
        slidesPerView: this.isOnePartner ? 1 : 1.08,
        spaceBetween: 9,
        pagination: {
          el: `.partners-block__pagination-${this._uid}`,
          type: "bullets",
          bulletClass: "u-swiper-pagination__bullet-v2",
          bulletActiveClass: "u-swiper-pagination__bullet--is-active",
          clickable: true,
        },
        autoplay: this.isOnePartner ? false : { delay: 2000, disableOnInteraction: false, },
        loop: !this.isOnePartner,
        centeredSlides: !this.isOnePartner,
      }
    }
  },
  methods: {
    pauseAutoplay() {
      this.$refs.partnersSwiper.$swiper.autoplay.stop()
    },
    resumeAutoplay() {
      this.$refs.partnersSwiper.$swiper.autoplay.start()
    }
  }
}
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: '.partners-block';
$gap: 16px;

#{$b} {

  // .partners-block__header
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  // .partners-block__caption
  &__caption {
    @include mobile-min {
      margin-right: 30px;
    }
  }

  // .partners-block__text
  &__text {
    font-family: $font-family-montserrat;
    font-size: 12px;
    line-height: 20px;
    font-weight: normal;
  }

  // .partners-block__grid
  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    gap: $gap;

    @include tablet {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  // .partners-block__pagination
  &__pagination {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // .partners-block__swiper
  &__swiper {
    overflow: unset;

    // .partners-block__swiper-slide
    &-slide {
      height: auto;

      @include mobile {
        user-select: none;
      }
    }
  }
}
</style>
