<template>
  <div class="faq-list">
    <div class="faq-list__inner">
      <FaqItem
        v-for="item in list"
        :key="`faq-list__item-${item.id}`"
        class="faq-list__item"
        :faq="item"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.faq-list';

#{$b} {

  // .faq-list__item
  &__item {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid $color-secondary-3;

    &:last-child {
      @include mobile-min {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
}
</style>
