<template>
  <div
    ref="confirmationWindow"
    class="confirmation-window"
    :class="rootClasses"
    :style="`--transition-time: ${transitionTime}ms`"
  >
    <div class="confirmation-window__text">
      <slot />
    </div>
    <div class="confirmation-window__actions">
      <CustomButton
        class="confirmation-window__btn"
        theme="primary"
        size="md"
        :status="deleteStatus"
        @click.stop.prevent="$emit('success')"
      >
        Да
      </CustomButton>
      <CustomButton
        class="confirmation-window__btn"
        size="md"
        theme="outline-primary"
        @click.stop.prevent="$emit('cancel')"
      >
        Отмена
      </CustomButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    deleteStatus: {
      type: String,
      default: ''
    },
    leftMobile: {
      type: Boolean,
      default: false
    },
    initPosition: {
      type: Object,
      default: () => ({
        desktop: {
          x: 'right',
          y: 'top',
        },
        mobile: {
          x: 'left',
          y: 'top',
        }
      }) // x: left||right||center, y: top||bottom
    },
    transitionTime: {
      type: Number,
      default: 0
    }
  },
  data() {
    return  {
      timeoutId: null,
    }
  },
  computed: {
    rootClasses() {
      const classes = []
      const prefix = 'confirmation-window--'

      classes.push(prefix + 'desktop-x-' + this.initPosition.desktop.x)
      classes.push(prefix + 'desktop-y-' + this.initPosition.desktop.y)
      classes.push(prefix + 'mobile-x-' + this.initPosition.mobile.x)
      classes.push(prefix + 'mobile-y-' + this.initPosition.mobile.y)

      if (this.isVisible) {
        classes.push(prefix + 'is-visible')
      }
      if (this.leftMobile) {
        classes.push(prefix + 'left-mobile')
      }

      return classes.join(' ')
    }
  },
  watch: {
    isVisible() {
      if (this.isVisible) {
        this.timeoutId = setTimeout(() => {
          const rootRect = this.$refs.confirmationWindow.getBoundingClientRect()

          if (rootRect.top < 0) {
            this.$refs.confirmationWindow.scrollIntoView({ behavior: 'smooth' })
          }
        }, this.transitionTime);
      } else {
        clearTimeout(this.timeoutId)
      }
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

@mixin positionSettings($breakpoint) {
  &-x {
    &-left {
      left: 0;
      right: unset;
    }
    &-right {
      left: unset;
      right: 0;
    }
    &-center {
      left: 50%;
      right: 0;
      transform: translateX(-50%);
    }
  }
  &-y {
    &-top {
      top: -(#{$offset});

      &#{$b}--is-visible {
        transform: translateY(-100%);

        &#{$b}--#{$breakpoint}-x-center {
          transform: translateY(-100%) translateX(-50%);
        }
      }
    }
    &-bottom {
      top: 100%;

      &#{$b}--is-visible {
        transform: translateY(100%);

        &#{$b}--#{$breakpoint}-x-center {
          transform: translateY(100%) translateX(-50%);
        }
      }
    }
  }
}

$b: '.confirmation-window';
$offset: 16px;

#{$b} {
  position: absolute;
  width: 322px;
  top: -(#{$offset});
  right: 0;
  background-color: $white-true;
  border-radius: 16px;
  padding: 24px;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  touch-action: none;
  z-index: 2;
  box-shadow: $shadow-gray;

  &--left-mobile {
    @include mobile {
      right: unset;
      left: 0;
    }
  }

  &--desktop {
    @include mobile-min {
      @include positionSettings('desktop');
    }
  }

  &--mobile {
    @include mobile {
      @include positionSettings('mobile');
    }
  }

  @include mobile-sm {
    padding: 12px;
    width: 275px;
  }

  // .confirmation-window__text
  &__text {
    text-align: left;
    font-size: 16px;
    line-height: 1.3;
    color: $black-true;

    @include mobile {
      font-size: 14px;
    }
  }

  // .confirmation-window__actions
  &__actions {
    margin-top: 16px;
    display: flex;
  }

  // .confirmation-window__btn
  &__btn {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &--is-visible {
    opacity: 1;
    pointer-events: unset;
    touch-action: unset;
    transition: all var(--transition-time) ease;
  }
}
</style>
