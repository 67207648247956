<template>
  <div  class="filter-tags">
    <div ref="filterTags" class="filter-tags__list">
      <div
        v-for="item in list"
        :key="`filter-tags-item-${item.id}`"
        class="filter-tags__item"
      >
        <CustomButton
          size="md"
          :theme="item.isActive ? 'primary' : 'simple-light'"
          :no-hover="item.isActive"
          class="filter-tags__item-btn"
          :class="{'filter-tags__item-btn--is-active': item.isActive}"
          @click="onSelect(item)"
        >
          # {{ item.name }}
          <span class="filter-tags__item-icon">
            <inline-svg class="filter-tags__item-svg" src="close-bold"/>
          </span>
        </CustomButton>
      </div>
    </div>
    <CustomButton
      v-if="isShowToggleBtn"
      class="filter-tags__show-all"
      size="md"
      theme="outline-primary"
      @click="toggleTabs"
    >
      {{ isShowAllTags ? 'Скрыть' : 'Показать ещё' }}
    </CustomButton>
  </div>
</template>

<script>
export default {
  inject: ['breakpoint'],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isShowAllTags: false,
      maxHeight: 0,
      isCanToggle: false
    }
  },
  computed: {
    isShowToggleBtn() {
      return this.breakpoint.isMobile && this.isCanToggle
    },
    getList() {
      return this.list.map(item => ({...item, isActive: item.isActive || false}))
    }
  },
  methods: {
    onSelect(value) {
      if (this.value.includes(value.id)) {
        const newValue = this.value.filter(item => item !== value.id)
        this.$emit('input', newValue)
      } else {
        this.$emit('input', [...this.value, value.id])
      }
    },
    checkForToggle() {
      this.$refs.filterTags.style.height = 'auto'
      const tagsHeight = this.$refs.filterTags.getBoundingClientRect().height
      const tagHeight = this.$el.querySelector('.filter-tags__item').getBoundingClientRect().height
      this.maxHeight = tagHeight * 2
      this.isCanToggle = tagsHeight > this.maxHeight

      if (this.isCanToggle && this.breakpoint.isMobile) {
        this.hideTags(this.maxHeight)
      } else {
        this.showAllTags()
      }
    },
    toggleTabs() {
      if (this.isShowAllTags) {
        this.hideTags(this.maxHeight)
      } else {
        this.showAllTags()
      }
    },
    showAllTags() {
      this.isShowAllTags = true
      this.$refs.filterTags.style.height = 'auto';
    },
    hideTags(maxHeight) {
      this.isShowAllTags = false
      this.$refs.filterTags.style.height = `${maxHeight}px`;
    },
    resetToggle() {
      if (!this.breakpoint.isMobile) {
        this.isCanToggle = false
        this.showAllTags()
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.checkForToggle()
        window.addEventListener('resize', this.resetToggle)
      })
    }, 200);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resetToggle)
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.filter-tags';
$gap: 4px;

#{$b} {

  // .filter-tags__list
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -($gap);
    overflow: hidden;
  }

  // .filter-tags__item
  &__item {
    padding: $gap;

    // .filter-tags__item-btn
    &-btn {

      // .filter-tags__item-btn--is-active
      &--is-active {
        padding-right: 11px !important;
        #{$b}__item-icon {
          display: block;
        }
      }
    }

    // .filter-tags__item-icon
    &-icon {
      display: none;
      max-width: 18px;
      max-height: 18px;
      margin-left: 8px;
    }
  }

  // .filter-tags__show-all
  &__show-all {
    margin-top: 16px;
    width: 100%;
  }
}
</style>
