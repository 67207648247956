<template>
  <component
    :is="MODAL.name"
    v-if="MODAL?.name"
    @close="CLOSE_MODAL"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('modal', ['MODAL']),
  },

  methods: {
    ...mapActions('modal', ['CLOSE_MODAL']),
  }
}
</script>
