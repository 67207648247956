<template>
  <div class="u-specialties">
    <UFigures
      class="u-specialties__figures"
      :items="figures"
      position="static"
      :fullWidth="true"
    >
      <div class="u-specialties__inner">
        <div class="u-g-header">
          <h2 class="u-specialties__title title-xl tt-uppercase ff-title">{{ title }}</h2>
          <UCaption class="u-specialties__caption" />
        </div>
        <USelectFilters
          v-if="isExistsFilterOptions"
          class="u-specialties__filter"
          :selectList="getSelectList"
          :selectedOptions="selectedOptions"
          :filterOptions="filterOptions"
          @onChangeSelect="onChangeSelect"
        />
        <USimpleCardList
          v-if="isExistsPageList"
          class="u-specialties__list"
          :cardList="getPageList"
          :buttonLabel="buttonLabel"
          :ymData="ymData"
          ymParamKey="Specialties"
        />
        <NotFound v-else :text="currentFilterError || 'По данным параметрам ничего не найдено'" />
        <div class="u-specialties__footer">
          <div class="u-specialties__back">
            <UniversitiesBack :href="hrefWithRegion(backUrl)" />
          </div>
          <CustomButton
            v-if="isExistsBtnLoadMore"
            class="u-specialties__show-more"
            size="md"
            theme="primary"
            :status="btnStatus"
            @click="loadMore(false)"
          >
            Показать еще
          </CustomButton>
        </div>
      </div>
    </UFigures>
  </div>
</template>

<script>
import FilterPageMixin from '@/mixins/universities/FilterPageMixin.js'
import PaginationPageMixin from '@/mixins/universities/PaginationPageMixin.js'

export default {
  mixins: [FilterPageMixin, PaginationPageMixin],
  props: {
    title: {
      type: String,
      default: 'Специальности',
    },
    buttonLabel: {
      type: String,
      default: ''
    },
    backUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      selectedOptions: {
        knowledgeArea: '',
        direction: '',
        qualification: '',
        region: '',
      },
      filterOptionsPreset: {
        knowledgeArea: {id: '', name: 'Все области знания'},
        direction: {id: '', name: 'Все направления'},
        qualification: {id: '', name: 'Все квалификации'},
        region: {id: '', name: 'Все Регионы'},
      },
      figures: [
        {
          name: 'f-u-specialties-1',
          image: '/frontend/assets/img/bg-shapes/circle-left-opacity.svg',
          animation: 'fade-in-left'
        },
        {
          name: 'f-u-specialties-2',
          image: '/frontend/assets/img/bg-shapes/circle-left-opacity.svg',
          animation: 'fade-in-left'
        },
        {
          name: 'f-u-specialties-3',
          image: '/frontend/assets/img/bg-shapes/circle-left-opacity.svg',
          animation: 'fade-in-left'
        }
      ],
      ymData: {
        code: 94756882,
        type: 'reachGoal',
        operation: 'click_wheretoStudy',
        options: {
          Specialties: ''
        }
      }
    }
  },
  computed: {
    getUrlForLoadPage() {
      return `/api/rest-component/dalee/specialties/getElementsList/?${this.getFilterParamForApi('filter')}`
    },
    getUrlForLoadOptions() {
      return `/api/rest-component/dalee/specialties/getFilter/?${this.getFilterParamForApi('preFilter')}`
    },
    getPageList() {
      return this.selectedOptions.region ? this.pageList.map(item => {
        return {
          ...item,
          url: `${item.url}&region=${this.selectedOptions.region}`
        }
      }) : this.pageList
    },
    getChangeHandlers() {
      return {
        knowledgeArea: this.onChangeKnowledgeArea,
        direction: this.onChangeDirection,
        qualification: this.onChangeQualification,
        region: this.onChangeRegion,
      }
    },
    getSelectList() {
      return [
        {
          label: 'Область знания',
          key: 'knowledgeArea'
        },
        {
          label: 'Направление',
          key: 'direction'
        },
        {
          label: 'Квалификация',
          key: 'qualification'
        },
        {
          label: 'Регион',
          key: 'region'
        },
      ]
    }
  },
  methods: {
    onChangeKnowledgeArea() {
      this.selectedOptions.direction = ''
      this.onFilterHelper()
    },
    onChangeDirection(id) {
      this.setSelectedOption(id, 'direction', 'knowledgeArea')
      this.onFilterHelper()
    },
    onChangeQualification() {
      this.onFilterHelper()
    },
    onChangeRegion() {
      this.onFilterHelper()
    },
    onChangeSelect(id, key) {
      this.getChangeHandlers[key](id)
    }
  },
};
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-specialties';
$gap: 32px;

#{$b} {
  position: relative;

  // .u-specialties__inner
  &__inner {
    position: relative;
  }

  // .u-specialties__caption
  &__caption {
    margin-right: auto;
    margin-left: 30px;

    @include tablet {
      margin-left: 16px;
    }
  }

  // .u-specialties__title
  &__title {
    margin: 0;
  }

  // .u-specialties__filter
  &__filter {
    margin-bottom: 68px;

    @include mobile {
      margin-bottom: 32px;
    }
  }

  // .u-specialties__footer
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  // .u-specialties__show-more
  &__show-more {
    @include mobile {
      order: -1;
      margin-bottom: 24px;
      width: 100%;
    }
  }
}
</style>
