<template>
  <div class="talisman-person">
    <div class="talisman-person__header">
      <div class="talisman-person__preview">
        <img
          :src="person.img"
          :alt="person.name"
          class="talisman-person__preview-img"
        >
      </div>
      <div class="talisman-person__header-content">
        <h3 class="talisman-person__title tt-uppercase title-lg" v-html="person.name" />
        <p class="talisman-person__position" v-html="person.position" />
      </div>
    </div>
    <div class="talisman-person__body">
      <p class="talisman-person__content text-ll" v-html="person.content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-person';

#{$b} {
  font-family: $font-family-inter;
  color: $white-true;
  border-radius: 16px;
  background-color: $color-base-origin;

  // .talisman-person__header
  &__header {
    display: grid;
    grid-template-columns: 175px auto;
    margin-bottom: 20px;

    @include mobile {
      display: block;
      margin-bottom: 24px;
    }

    // .talisman-person__header-content
    &-content {
      padding: 24px 24px 0 24px;

      @include mobile {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  // .talisman-person__preview
  &__preview {

    // .talisman-person__preview-img
    &-img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
      border-radius: 16px;
      overflow: hidden;
      aspect-ratio: 175/237;

      @include mobile {
        aspect-ratio: 376/300;
      }
    }
  }

  // .talisman-person__title
  &__title {
    font-family: $font-family-main-head;
    margin: 0;
    word-spacing: 100vw;
  }

  // .talisman-person__position
  &__position {
    position: relative;
    margin: 15px 0 0 0;
    padding-bottom: 9px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 77%;
      height: 1px;
      border-bottom: 1px solid $white-true;

      @include mobile {
        width: 100%;
      }
    }

    @include mobile {
      margin-top: 24px;
      padding-bottom: 24px;

      font-size: 14px;
    }
  }

  // .talisman-person__body
  &__body {
    padding: 0 19px 24px 32px;

    @include mobile {
      padding: 0 20px 20px 20px;
    }
  }

  // .talisman-person__content
  &__content {
    margin: 0;

    @include mobile {
      font-size: 16px;
    }
  }
}
</style>
