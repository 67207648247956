<script>
export default {
  name: 'InlineSvg',
  functional: true,
  props: {
    src: { type: String, required: true },
    tag: { type: String, default: 'i' },
  },
  render(h, ctx) {
    return h(ctx.props.tag, {
      ...ctx.data,
      class: [ctx.data.class, 'inline-svg'],
      domProps: {
        // eslint-disable-next-line
        innerHTML: require(`!svg-inline-loader?removeSVGTagAttrs=false!/frontend/assets/icons/${ctx.props.src}.svg`),
      },
    });
  },
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: '.inline-svg';

#{$b} {
  display: block;
  font-style: normal;
  width: 100%;
  height: 100%;

  svg {
    display: block;
    width: 100%;
    height: 100%;

    circle,
    polygon,
    path,
    rect {
      &:not(._not-fill) {
        fill: currentColor;
      }
    }
  }

  .svg-hover {
    transition: .3s;
  }
}
</style>
