<template>
  <div class="u-lk-raport-initiatives">
    <div class="u-lk-raport-initiatives__header">
      <div class="u-lk-raport-initiatives__filter-top">
        <div class="u-lk-raport-initiatives__filter-select u-select">
          <v-select
            v-model="filter.selectedInitiative"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="initiativeOptions"
            @input="onSubmitFilter"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
        <div
          class="u-lk-raport-initiatives__filter-select u-select"
          :class="{'u-select--no-interactive': getYearsOptions.length <= 1}"
        >
          <v-select
            v-model="filter.selectedYear"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="getYearsOptions"
            @input="selectAllMonth"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
      </div>
      <div v-show="!isAllYearSelected" class="u-lk-raport-initiatives__filter-bottom">
        <div class="u-lk-raport-initiatives__month-wrapper">
          <div
            class="u-lk-raport-initiatives__month tt-uppercase"
            :class="{
              'u-lk-raport-initiatives__month--is-active': month.code === filter.selectedMonth,
              'u-lk-raport-initiatives__month--is-disabled': !isExsistMonth(month)
            }"
            v-for="month in monthFilters"
            :key="`u-lk-raport-initiatives__month-${month.code}`"
            @click="filter.selectedMonth = month.code"
          >
            {{ month.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="u-lk-raport-initiatives__body">
      <div v-if="isExistsRaportlist" class="u-lk-raport-initiatives__list">
        <div
          class="u-lk-raport-initiatives__item"
          v-for="(item, i) in raportList"
          :key="`u-lk-raport-initiatives__item-${i}`"
        >
          <p class="u-lk-raport-initiatives__item-text" v-html="item.text" />
          <div class="u-lk-raport-initiatives__item-info" v-html="item.info" />
          <div class="u-lk-raport-initiatives__item-more">
            <span class="u-lk-raport-initiatives__more-action" @click="showInModal(item)">Подробнее</span>
          </div>
        </div>
      </div>
      <not-found v-else text="По данным параметрам ничего не найдено" />
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from 'axios'

export default {
  components: {
    vSelect,
  },
  props: {
    initiativeOptions: {
      type: Array,
      default: () => []
    },
    initiative: {
      type: Object,
      default: () => ({
        allData: [],
        columns: [],
        data: {},
        yearsOptions: []
      })
    },
    updateBlocks: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      monthFilters: [
        { name: "Все", code: "all"},
        { name: "Янв", code: "1" },
        { name: "Фев", code: "2" },
        { name: "Мар", code: "3" },
        { name: "Апр", code: "4" },
        { name: "Май", code: "5" },
        { name: "Июн", code: "6" },
        { name: "Июл", code: "7" },
        { name: "Авг", code: "8" },
        { name: "Сен", code: "9" },
        { name: "Окт", code: "10" },
        { name: "Ноя", code: "11" },
        { name: "Дек", code: "12" },
      ],
      filter: {
        selectedInitiative: "",
        selectedYear: "all",
        selectedMonth: "all"
      },
      allData: [],
      columns: [],
      data: {
      },
      yearsOptions: []
    }
  },
  computed: {
    getYearsOptions() {
      return [...this.yearsOptions.map((item) => {
        return {
          id: item,
          name: item
        }
      }), {id:'all', name:'все года'}]
    },
    isAllYearSelected() {
      return this.filter.selectedYear === 'all'
    },
    raportList() {
      return this.columns.map((item,i) => {
        let info

        if (
          !this.isAllYearSelected
          && this.data[this.filter.selectedYear]
          && this.data[this.filter.selectedYear][this.filter.selectedMonth]
        ) {
          info = this.data[this.filter.selectedYear][this.filter.selectedMonth][i]
        } else {
          info = this.allData[i]
        }

        return {
          text: item,
          info,
        }
      })
    },
    isExistsRaportlist() {
      return this.raportList && Boolean(this.raportList.length)
    }
  },
  methods: {
    isExsistMonth(month) {
      return Boolean(this.data[this.filter.selectedYear]) && Boolean(this.data[this.filter.selectedYear][month.code])
    },
    selectAllYear() {
      this.filter.selectedYear = 'all'
    },
    selectAllMonth() {
      this.filter.selectedMonth = 'all'
    },
    async onSubmitFilter(value) {
      const response = await axios(`/bitrix/services/main/ajax.php?mode=class&c=dalee:activity.report&action=getInitiative&id=${value}`)

      // При необходимости обновляем связанные блоки
      if (this.updateBlocks) {
        this.$root.$emit('updateReportBlocks', value);
      }

      if (response && response.data && response.data.status === 'success') {
        this.setInitialData(response.data.data)
        this.selectAllYear()
        this.selectAllMonth()
      }
    },
    setInitialData(initialData) {
      this.allData = initialData.allData
      this.columns = initialData.columns
      this.data = initialData.data
      this.yearsOptions = initialData.yearsOptions
    },
    showInModal(item) {
      this.$root.$refs.customModal.openModal(null, null, true);
      this.$root.$refs.customModal.passContent({
        name: 'raport-initiative-card',
        props: {card: item},
      });
    }
  },
  created() {
    this.filter.selectedInitiative = this.initiativeOptions[0]
    this.setInitialData(this.initiative)
  },
}
</script>


<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-lk-raport-initiatives';

#{$b} {
  font-family: $font-family-inter;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.3;
  color: $black-true;

  // .u-lk-raport-initiatives__filter-top
  &__filter-top {
    display: inline-grid;
    grid-template-columns: repeat(2, 296px);
    gap: 16px;
    margin-bottom: 32px;

    @include mobile {
      display: block;
    }
  }

  // .u-lk-raport-initiatives__filter-select
  &__filter-select {
    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  // .u-lk-raport-initiatives__filter-bottom
  &__filter-bottom {
    margin-bottom: 64px;

    @include mobile {
      margin-bottom: 32px;
    }
  }

  // .u-lk-raport-initiatives__month-wrapper
  &__month-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: (-24px);
  }

  // .u-lk-raport-initiatives__month
  &__month {
    height: 32px;
    font-family: $font-family-main-head;
    font-size: 16px;
    line-height: 13px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background-color: transparent;
    border-radius: 16px;
    user-select: none;
    margin-bottom: 24px;
    transition: $transtion-default;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &:first-child {
      position: relative;
      margin-right: calc(24px + 15px + 9px);
      &::after {
        content: '/';
        position: absolute;
        top: 50%;
        right: -32px;
        width: 9px;
        height: 16px;
        transform: translateY(-50%);
        color: $color-info-secondary;
        pointer-events: none;
        touch-action: none;
      }
    }

    &:hover{
      &:not(#{$b}__month--is-active){
        @include mobile-min {
          color: $color-base;
        }
      }
    }

    // .u-lk-raport-initiatives__month--is-active
    &--is-active {
      background-color: $color-base;
      color: $white-true;
    }

    // .u-lk-raport-initiatives__month--is-disabled
    &--is-disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  // .u-lk-raport-initiatives__list
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mobile {
      display: block;
    }
  }

  // .u-lk-raport-initiatives__item
  &__item {
    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }

    // .u-lk-raport-initiatives__item-text
    &-text {
      margin: 0;
    }

    // .u-lk-raport-initiatives__item-info
    &-info {
      font-size: 24px;
      line-height: 1.5;
      color: $color-primary;
      margin-top: 16px;
      word-break: break-word;
      @include lineClamp(3);
    }

    &-more {
      margin-top: 10px;
    }
  }
  &__more-action {
    user-select: none;
    cursor: pointer;
    color: $color-base-origin;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
