<template>
  <div class="i-excursions-tab-filter" v-dragscroll.x:nochilddrag>
    <ul class="i-excursions-tab-filter__list">
      <li
        v-for="item in items"
        :key="`i-excursions-tab-filter__list-item-${item.filter}`"
        class="i-excursions-tab-filter__list-item"
      >
        <div
          class="i-excursions-tab-filter__list-btn"
          :class="{ 'i-excursions-tab-filter__list-btn--is-active': isActive(item.filter) }"
          @click.prevent="selectTab(item)"
        >
          {{ item.name }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    currentFilter: {
      type: String,
      default: ''
    }
  },

  methods: {
    isActive(filter) {
      return filter === this.currentFilter
    },

    selectTab(tab) {
      this.$emit('change', tab.filter)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.i-excursions-tab-filter';

#{$b} {
  position: relative;
  -ms-overflow-style: none;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 10;

  @include removeScrollBar();

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: flex-end;

    // .i-excursions-tab-filter__list-item
    &-item {

      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    // .i-excursions-tab-filter__list-btn
    &-btn {
      height: var(--tab-height);
      display: block;
      position: relative;
      color: rgba($white-true, 0.5);
      letter-spacing: .1em;
      white-space: nowrap;
      padding-bottom: 24px;
      cursor: pointer;

      &::after {
        content: '';
        width: 100%;
        height: 4px;
        background-color: transparent;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &--is-active {
        color: $white-true;

        &::after {
          background-color: $color-base;
        }
      }
    }
  }
}

</style>
