const punycode = require('punycode/');

export default function(encodedUrl) {
  if (!encodedUrl) {
    return
  }

  const url = decodeURI(encodedUrl)
  const urlObject = new URL(url)
  let idnHost = null
  const nonIdnHost = urlObject.hostname

  try {
    idnHost = punycode.toUnicode(nonIdnHost)
  } catch (ignored) { console.warn(ignored) }

  if (idnHost === null || idnHost === nonIdnHost) {
    return url
  }

  return url.replace(nonIdnHost, idnHost)
}
