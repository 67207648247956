<template>
  <div class="talisman-work-captcha">
    <div ref="captcha" class="talisman-work-captcha__container"></div>
  </div>
</template>

<script>
export default {
  props: {
    siteKey: {
      type: String,
      default: '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.captchaInit()
    })
  },
  methods: {
    captchaInit() {
      if (window.smartCaptcha) {
        window.smartCaptcha.render(this.$refs.captcha, {
          sitekey: this.siteKey,
          callback: (token) => {
            this.$emit('submit', token)
          }
        });
      }
    },
  },
}
</script>
