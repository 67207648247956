<template>
  <div ref="initiativesNewsForm" class="initiatives-news-form">
    <div class="initiatives-news-form__fields" :class="{'initiatives-news-form__fields--gap-large': isInvalidForm}">
      <div class="initiatives-news-form__field">
        <u-text-input
          class="u-text-input--smaller"
          name="name"
          label="Название*"
          v-model="form.name"
          placeholder="..."
          :error="isRequiredErrorField('name')"
        />
        <ErrorFormWrapper
          :isVisible="isRequiredErrorField('name')"
          class="initiatives-news-form__error-field"
        >
          <div>Обязательное поле</div>
        </ErrorFormWrapper>
      </div>
      <div class="initiatives-news-form__field">
        <CustomCalendar
          v-model="form.dateStart"
          :minDate="minDate"
          :maxDate="maxDate"
          subtitle="Начало активности*"
          :isFormTheme="true"
        />
        <ErrorFormWrapper
          :isVisible="isRequiredErrorField('dateStart')"
          class="initiatives-news-form__error-field"
        >
          <div>Обязательное поле</div>
        </ErrorFormWrapper>
      </div>
      <div class="initiatives-news-form__field initiatives-news-form__field--full-width">
        <div class="initiatives-news-form__label">
          Текст деталки*
        </div>
        <QuillWyz
          class="initiatives-news-form__editor"
          :initial-value="form.detailText"
          :toolbar-options="toolbarOptions"
          :is-error="isRequiredErrorField('detailText')"
          :height-editor="QuillWyzHeightSettings"
          @change="onChangeDetailText"
        />
        <ErrorFormWrapper
          :isVisible="isRequiredErrorField('detailText')"
          class="initiatives-news-form__error-field"
        >
          <div>Обязательное поле</div>
        </ErrorFormWrapper>
      </div>
      <div class="initiatives-news-form__field">
        <div class="initiatives-news-form__label initiatives-news-form__label--lg-gap">
          Изображение для анонса (jpg, png. 500 Кб макс.)
        </div>
        <div class="initiatives-news-form__field-el">
          <MediaLoader
            :initialFiles="form.announcementImage"
            :validFormat="validFormatFile"
            :maxSize="0.5"
            :maxCount="1"
            @change="announcementImageChange"
          />
        </div>
      </div>
      <div class="initiatives-news-form__field">
        <div class="initiatives-news-form__label initiatives-news-form__label--lg-gap">
          Изображение для деталки (jpg, png. 2 Мб макс.)
        </div>
        <div class="initiatives-news-form__field-el">
          <MediaLoader
            :initialFiles="form.detailImage"
            :validFormat="validFormatFile"
            :maxSize="2"
            :maxCount="1"
            @change="detailImageChange"
          />
        </div>
      </div>
      <div class="initiatives-news-form__field">
        <div class="initiatives-news-form__label initiatives-news-form__label--lg-gap">
          Медиагалерея (jpg, gif, bmp, png, jpeg, webp. 2 Мб макс. mpg, avi, wmv, mpeg, mpe, webm, mp4. 50 Мб макс)
        </div>
        <div class="initiatives-news-form__field-el">
          <MediaLoaderController
            :validFormat="validFormatFileGallery"
            :initialFiles="form.mediaGallery"
            :maxSize="2"
            :maxSizeVideo="50"
            @change="mediaGalleryChange"
          />
        </div>
      </div>
    </div>
    <div class="initiatives-news-form__footer">
      <ErrorFormWrapper class="initiatives-news-form__errors" :isVisible="true">
        <div>*Поля обязательны для заполнения </div>
      </ErrorFormWrapper>
      <ErrorFormWrapper
        class="initiatives-news-form__feedback-errors"
        :isVisible="Boolean(errorsAfterSubmit) && Boolean(errorsAfterSubmit.length)"
      >
        <div
          v-for="error in errorsAfterSubmit"
          :key="`initiatives-news-form__feedback-error-${error.code}`"
          class="initiatives-news-form__feedback-error"
        >
          <span v-html="error.message"/>
        </div>
      </ErrorFormWrapper>
      <div class="initiatives-news-form__actions">
        <CustomButton
          class="initiatives-news-form__btn-submit"
          theme="primary"
          size="md"
          :status="statusBtn"
          @click="onSubmit"
        >
          Отправить
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  getResetForm,
  mutateClearFilesFromForm,
  mutateOnFilesChange,
  mutateSetIsDeletedFile,
} from '/frontend/components/helpers/formHelpers.js'

export default {
  mixins: [validationMixin],
  props: {
    initialForm: {
      type: Object,
      default: () => ({
        name: '',
        dateStart: '',
        announcementImage: '',
        detailImage: '',
        detailText: '',
        mediaGallery: []
      })
    },
    initiativeId: {
      type: String,
      default: ''
    },
    idUpdate: {
      type: String,
      default: ''
    },
    validFormatFile: {
      type: Array,
      default: () => [
        'jpg',
        'png',
      ]
    },
    validFormatFileGallery: {
      type: Array,
      default: () => [
        'jpg',
        'gif',
        'bmp',
        'png',
        'jpeg',
        'webp',
        'mpg',
        'avi',
        'wmv',
        'mpeg',
        'mpe',
        'webm',
        'mp4',
      ]
    },
    actionSave: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: JSON.parse(JSON.stringify(this.initialForm)),
      isInvalidForm: false,
      statusBtn: '',
      errorsAfterSubmit: [],
      minDate: null,
      maxDate: null,
      QuillWyzHeightSettings: {
        lg: '220',
        md: '200'
      }
    }
  },
  validations: {
    form: {
      name: { required },
      dateStart: { required },
      detailText: { required },
    }
  },
  computed: {
    getFormData() {
      const data = new FormData()

      data.append('initiativeId', this.initiativeId);

      for (let prop in this.form) {
         if (prop === 'mediaGallery') {
          data.append(prop, JSON.stringify(this.form[prop]))
        } else {
          data.append(prop, this.form[prop])
        }
      }

      if (this.idUpdate) {
        data.append('idUpdate', this.idUpdate)
      }

      mutateSetIsDeletedFile(
        ['detailImage', 'announcementImage'],
        data,
        this.initialForm,
        this.form
      )

      return data
    },
    toolbarOptions() {
      return [
        ['bold', 'italic', 'underline', 'strike', 'link'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'header': [2, 3, 4, 5, 6, false] }],
      ]
    }
  },
  methods: {
    isRequiredErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].required
    },
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.scrollToForm()
        this.isInvalidForm = true
        return
      }

      this.statusBtn = 'loading'

      const options = {
        url: this.actionSave,
        method: 'POST',
        data:  this.getFormData
      };

      const response = await axios(options)

      if (response.data.status === 'success') {
        this.succesResponse(response)
      } else {
        this.errorResponse(response)
      }
    },
    succesResponse(response) {
      this.statusBtn = 'success'
      this.$v.$reset();
      this.resetForm()
      this.errorsAfterSubmit = []
      this.$root.$emit('editedInitiativesNews', { type: this.idUpdate ? 'update' : 'add', data: response.data.data.news })

      setTimeout(() => {
        this.$root.$refs.customModal.closeModal(null, null, true);
        this.statusBtn = ''
      }, 3000);
    },
    errorResponse(response) {
      this.statusBtn = ''
      this.errorsAfterSubmit = response.data.errors
    },
    onFileChange(files, fileProp) {
      mutateClearFilesFromForm(this.form, fileProp)
      mutateOnFilesChange(this.form, files, fileProp)
    },
    announcementImageChange(files) {
      this.form.announcementImage = files[0]
    },
    detailImageChange(files) {
      this.form.detailImage = files[0]
    },
    mediaGalleryChange(files) {
      this.onFileChange(files, 'mediaGallery')
    },
    resetForm() {
      mutateClearFilesFromForm(this.form, ['mediaGallery'])
      this.form = getResetForm(this.form)
    },
    scrollToForm(to = 'start') {
      this.$refs.initiativesNewsForm.scrollIntoView({
        behavior: "smooth",
        block: to,
      })
    },
    onChangeDetailText(html) {
      this.form.detailText = html
    },
  },
  created() {
    const minDate = new Date(2022, 0, 1)
    const maxDate = new Date(2031, 11, 31)
    this.minDate = minDate.toLocaleDateString('ru-RU')
    this.maxDate = maxDate.toLocaleDateString('ru-RU')

    if (!this.form.dateStart) {
      const startDate = new Date()
      this.form.dateStart = startDate.toLocaleDateString('ru-RU')
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.initiatives-news-form';

#{$b} {
  // .initiatives-news-form__field
  &__field {

    // .initiatives-news-form__field--full-width
    &--full-width {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  // .initiatives-news-form__fields
  &__fields {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 16px));
    gap: 32px;
    transition: $transtion-default;

    @include tablet {
      grid-template-columns: repeat(2, calc(50% - 8px));
      gap: 16px;
    }

    @include mobile {
      display: block;
    }

     // .initiatives-news-form__fields--gap-large
    &--gap-large {
      gap: 42px 32px;

      @include tablet {
        gap: 42px 16px;
      }
    }
  }

  // .initiatives-news-form__field
  &__field {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }

    // .initiatives-news-form__field-el
    &-el {
      margin-left: 8px;
    }
  }

  // .initiatives-news-form__error-field
  &__error-field {
    @include mobile-min {
      position: absolute;
      top: 100%;
    }
  }

  // .initiatives-news-form__label
  &__label {
    font-family: $font-family-inter;
    font-size: 12px;
    line-height: 1.3;
    text-transform: initial;
    margin-bottom: 8px;
    margin-left: 8px;
    font-weight: normal;

    &--lg-gap {
      margin-bottom: 17px;

      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  // .initiatives-news-form__footer
  &__footer {
    margin-top: 32px;

    @include mobile {
      margin-top: 24px;
    }
  }

  // .initiatives-news-form__actions
  &__actions {
    margin-top: 64px;

    @include mobile {
      text-align: center;
      margin-top: 32px;
    }
  }

  // .initiatives-news-form__feedback-error
  &__feedback-error {
    font-size: 24px;
    margin-bottom: 15px;

    @include mobile {
      font-size: 18px;
    }

    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
}
</style>
