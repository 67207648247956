<template>
  <div
    v-if="list && list.length"
    class="stories-list"
    @click="interactive"
    @mouseenter.self="onEnterStories"
    @mouseleave.self="onLeaveStories"
  >
    <div class="stories-list__wrapper">
      <template v-if="breakpoint.isDesktop">
        <img src="/frontend/assets/img/childrens-playground/StorisList/storis-icon.svg" class="stories-list__icon-element">
        <div
          ref="inner"
          class="stories-list__inner"
          :class="{'stories-list__inner--block-scroll': isScrollBlocked}"
          :style="getInnerStyle"
          @scroll="onScrollInnerHandler"
        >
          <div class="stories-list__cards">
            <StoriesCard
              :ref="`storiesCard${i}`"
              v-for="(stories, i) in list"
              :key="`stories-list__card-${stories.id}`"
              class="stories-list__card"
              :video="getVideo(i, stories)"
              @click="onClickStories(i)"
              @storiesEnded="storiesEnded(i)"
              @loadeddata="onLoadeddata"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="stories-list__mob">
          <div class="stories-list__trigger" @click="setStateModal(true)">
            <div class="stories-list__trigger-btn">
              <InlineSvg src="u-play" class="stories-list__trigger-icon"/>
            </div>
            <img :src="list[0].poster" class="stories-list__trigger-poster">
          </div>
        </div>
        <StoriesModal v-if="isOpenedModal" :list="list" @close="setStateModal(false)"/>
      </template>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash-es/throttle'

import StoriesMixin from '@/mixins/stories/StoriesMixin.js'

export default {
  inject: ['breakpoint'],
  mixins: [ StoriesMixin ],
  data() {
    return {
      isInteractive: false,
      innerHeight: 0,
      isOpenedModal: false,
      hasLodashThrottle: true
    }
  },
  computed: {
    currentStoriesRef() {
      if (!this.breakpoint.isDesktop) {
        return
      }

      const storiesRef = this.getStoriesRef(this.activeIndex)
      if (!storiesRef) {
        return
      }

      return storiesRef
    },
    getInnerStyle() {
      return {
        height: `${this.innerHeight}px`
      }
    },
  },
  methods: {
    setStoriesStateByScroll() {
      const inView = this.inViewActiveStories()

      if (!this.isInteractive || inView || !this.breakpoint.isDesktop) {
        return
      }

      this.setStoriesState(inView)
    },
    interactive() {
      if (this.isInteractive || !this.breakpoint.isDesktop) {
        return
      }
      this.isInteractive = true

      this.setStoriesStateByScroll()
    },
    inViewActiveStories() {
      if (!this.currentStoriesRef) {
        return
      }

      const storiesRect = this.currentStoriesRef.$el.getBoundingClientRect()

      const isTopСondition = storiesRect.top + storiesRect.height / 2 < window.innerHeight
      const isBottomCondition = storiesRect.bottom > storiesRect.height / 2

      return isTopСondition && isBottomCondition
    },
    setInnerHeight() {
      if (!this.currentStoriesRef || !this.$refs.inner) {
        return
      }

      const { height } = this.currentStoriesRef.$el.getBoundingClientRect()
      this.innerHeight = height + 136
    },
    onScrollInner(e) {
      if (!this.currentStoriesRef) {
        return
      }

      const scrollTop = e.target.scrollTop
      const newIndex = scrollTop > this.prevInnerScroll ? this.activeIndex + 1 : scrollTop < this.prevInnerScroll ? this.activeIndex - 1 : this.activeIndex

      this.goToNextStories(newIndex)

      this.prevInnerScroll = e.target.scrollTop
    },
    onScrollInnerHandler: throttle(
      function(e) {
        this.onScrollInner(e)
      },
      100
    ),
    onLoadeddata() {
      this.setInnerHeight()
    },
    setStateModal(state) {
      this.isOpenedModal = state

      this.setStateScrollLock(state, true)
    },
    setStateScrollLock(state, withModal) {
      const windowPositionY = window.scrollY
      const delta = state ? window.innerWidth - document.documentElement.clientWidth : ''
      document.body.style.overflow = state ? 'hidden' : ''
      document.body.style.paddingRight = state ? `${delta}px` : ''

      if (!withModal) {
        return
      }

      document.body.style.position = state ? 'fixed' : '';

      if (!state) {
        const bodyStyles = window.getComputedStyle(document.body)
        const top = parseInt(bodyStyles.getPropertyValue('top'), 10) * -1

        window.scrollTo({
          top
        })
      }

      document.body.style.top = state ? -windowPositionY + 'px' : '';
    },
    onEnterStories() {
      if (!this.breakpoint.isDesktop) {
        return
      }
      this.setStateScrollLock(true)
    },
    onLeaveStories() {
      this.setStateScrollLock(false)
    }
  },

  mounted() {
    window.addEventListener('scroll', this.setStoriesStateByScroll)
    window.addEventListener('resize', this.setInnerHeight)

    this.$nextTick(() => {
      this.setInnerHeight()
    })
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.stories-list';

#{$b} {

  // .stories-list__wrapper
  &__wrapper {
    position: relative;
  }

  // .stories-list__inner
  &__inner {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 24px 24px 0 0;

    @include removeScrollBar();

    // .stories-list__inner--block-scroll
    &--block-scroll {
      overflow: hidden;
    }
  }

  // .stories-list__icon-element
  &__icon-element {
    position: absolute;
    top: 32px;
    left: -104px;
    pointer-events: none;
    touch-action: none;
    z-index: 0;

    @include mobile {
      display: none;
    }
  }

  // .stories-list__cards
  &__cards {
    padding-bottom: 136px;
  }

  // .stories-list__card
  &__card {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  // .stories-list__trigger
  &__trigger {
    border-radius: 24px;
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black-true, 0.5);
    }

    // .stories-list__trigger-btn
    &-btn {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 124px;
      height: 124px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $color-purple;
      background-color: $white-true;
      padding: 33px;
      border-radius: 50%;
      pointer-events: none;
      touch-action: none;
      z-index: 2;
    }

    // .stories-list__trigger-icon
    &-icon {
      margin-left: 10px;
    }

    // .stories-list__trigger-poster
    &-poster {
      aspect-ratio: 368/654;
      width: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
}
</style>
