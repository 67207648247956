var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"u-text-input",class:[
    {
      _error: _vm.error,
      'u-text-input--is-loading': _vm.isLoading
    },
    `u-text-input--textarea-size-${_vm.textAreaSize}`
  ]},[(_vm.label.length)?_c('div',{staticClass:"u-text-input__label",domProps:{"innerHTML":_vm._s(_vm.label)}}):(_vm.isExistSlotLabel)?_c('div',{staticClass:"u-text-input__label"},[_vm._t("label")],2):_vm._e(),_c('div',{staticClass:"u-text-input__wrapper"},[(_vm.isTextArea)?_c('textarea',{staticClass:"u-text-input__textarea",class:{_error: _vm.error},attrs:{"name":_vm.name,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}}):_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask ? _vm.mask : ''),expression:"mask ? mask : ''"}],ref:"input",staticClass:"u-text-input__input",class:{
        _error: _vm.error,
        _editable: _vm.editableAction,
        _readonly: _vm.isReadOnly,
        '_ios-fix': _vm.isIOSFix,
        '_readonly-ios': _vm.isReadOnlyField && _vm.isIOS
      },attrs:{"type":_vm.type,"name":_vm.name,"placeholder":_vm.placeholder,"readonly":_vm.isReadOnlyField && !_vm.isIOS},domProps:{"value":_vm.value},on:{"blur":_vm.onBlur,"keydown":_vm.submitEditFromEnter,"input":_vm.handleInput}}),(_vm.editableAction)?_c('div',{staticClass:"u-text-input__edit",class:{'u-text-input__edit--editing': !_vm.isEdit},on:{"click":_vm.editHandler}},[(_vm.isEdit)?_c('div',{staticClass:"u-text-input__edit-item _confirm"},[_c('inline-svg',{attrs:{"src":"confirm-icon"}})],1):_c('div',{staticClass:"u-text-input__edit-item _edit"},[_c('inline-svg',{attrs:{"src":"edit-icon"}})],1)]):_vm._e(),(_vm.isPassword)?_c('div',{staticClass:"u-text-input__eye",class:{
        'is-active': _vm.passwordShowed,
      }},[(_vm.passwordShowed)?_c('inline-svg',{attrs:{"src":"u-eye"},on:{"click":function($event){$event.preventDefault();return _vm.changePasswordType.apply(null, arguments)}}}):_c('inline-svg',{attrs:{"src":"eye-close"},on:{"click":function($event){$event.preventDefault();return _vm.changePasswordType.apply(null, arguments)}}})],1):_vm._e(),(_vm.isLoading)?_c('inline-svg',{staticClass:"u-text-input__loading",attrs:{"src":"loading"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }