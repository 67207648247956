<template>
  <div
    class="filter-btn"
    :class="{'filter-btn--is-active': isActive}"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot/>
    <span class="filter-btn__icon">
      <inline-svg class="filter-btn__svg" src="close-bold"/>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.filter-btn';

#{$b} {
  font-family: $font-family-inter;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 20px;
  padding: 10px 22px ;
  background-color: $white-true;
  border: 1px solid $white-true;
  font-size: 16px;
  white-space: nowrap;
  line-height: 1;
  color: rgba($black-true, 0.5);
  font-weight: 500;
  user-select: none;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  &:hover {
    @include mobile-min {
      border-color: $color-base;
    }
  }

  // .filter-btn--is-active
  &--is-active {
    background-color: $color-base;
    border-color: $color-base;
    padding-right: 11px;
    color: $white-true;
  }

  &__icon {
    min-width: 0;
    max-width: 0;
    max-height: 0;
    margin-left: 8px;
    transition: all 0.5s ease-in-out;

    #{$b}--is-active & {
      min-width: 18px;
      max-width: 18px;
      max-height: 18px;
    }
  }
}
</style>
