<template>
  <section
    ref="section"
    class="figures"
    :class="{ 'figures--overflow': isOverflow }"
  >
    <div :class="{ 'g-container': !fullWidth }">
      <div
        v-for="(item, i) in items"
        :key="i"
        ref="figure"
        class="figures-item"
        :class="item.name"
      >
        <div :class="{ 'figures__reverse-x': item.reverseX, 'figures__reverse-y': item.reverseY }">
          <img
          :class="{ animated: animateParallax }"
          v-rellax="rellax"
          :src="item.image"
          alt="item.name"
        />
        </div>
      </div>
    </div>
    <slot />
  </section>
</template>

<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// IE fix ScrollTrigger
window.requestAnimationFrame = window.requestAnimationFrame.bind(window);

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Figures",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isFirst: {
      type: Boolean,
      default: false
    },
    isOverflow: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    alwaysShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      animateParallax: false,
      rellax: false,
      animateIn: undefined,
      fadeOutLeft: {
        x: -80,
        opacity: 0,
        ease: "power2",
        duration: 1,
        onComplete: this.startParallax
      },
      fadeOutRight: {
        x: 80,
        opacity: 0,
        ease: "power2",
        duration: 1,
        onComplete: this.startParallax
      },
      fadeOutTop: {
        y: -80,
        opacity: 0,
        ease: "power2",
        duration: 1,
        onComplete: this.startParallax
      }
    };
  },
  mounted() {
    this.animateIn = gsap.timeline({
      scrollTrigger: {
        trigger: this.$refs.section,
        start: this.isFirst ? "top top" : "top center",
        // markers: "true",
        toggleActions: this.alwaysShow ? "play" : "play none none reverse"
      }
    });

    this.items.forEach((item, i) => {
      const element = this.$refs.figure[i];

      switch (item.animation) {
        case "fade-in-left":
          this.animateIn.from(element, this.fadeOutLeft);
          break;
        case "fade-in-right":
          this.animateIn.from(element, this.fadeOutRight);
          break;
        case "fade-in-top":
          this.animateIn.from(element, this.fadeOutTop);
          break;
        default:
          this.startParallax();
          break;
      }
    });
  },
  methods: {
    startParallax() {
      if (this.rellax > 0) return;
      this.animateParallax = true;
      this.rellax = { speed: -Number(Math.random().toFixed(1)) - 0.1 };
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".figures";

#{$b} {
  position: relative;

  &--overflow {
    overflow: hidden;
  }

  & > .g-container {
    position: relative;

    @include low-desktop {
      position: static;
    }
  }

  &_absolute {
    > .g-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      min-height: 1760px;
    }
  }

  &__reverse-x {
    transform: scaleX(-1);
  }

  &__reverse-y {
    transform: scaleY(-1);
  }

  &-item {
    position: absolute;
    z-index: 0;

    img {
      &.animated {
        transition: transform 0.5s ease;
      }
    }

    svg {
      path {
        fill: currentColor;
      }
    }

    &.figure1 {
      width: 883px;
      top: 0;
      right: 47px;
      color: #3262db;

      @include desktop {
        width: 616px;
        top: 24px;
        right: 0px;
      }

      @include tablet {
        width: 600px;
        top: 24px;
        right: -100px;
      }

      @include mobile {
        width: 420px;
        top: 0;
        right: -130px;
      }
    }

    &.figure2 {
      width: 324px;
      top: 470px;
      left: 20px;
      color: #3262db;

      @include desktop {
        width: 280px;
        top: 330px;
        left: 15px;
      }

      @include tablet {
        width: 260px;
        top: 352px;
        left: -8px;
      }

      @include mobile {
        width: 220px;
        top: 390px;
        left: -50px;
      }
    }

    &.figure3 {
      top: 50px;
      right: -80px;

      @include desktop {
        top: 50px;
        right: -100px;
        width: 400px;
      }

      @include tablet {
        top: 30px;
        right: 30px;
        width: 300px;
      }

      @include mobile {
        top: 30px;
        right: -70px;
        width: 210px;
      }
    }

    &.figure4 {
      top: 25px;
      left: -25px;

      @include desktop {
        top: -30px;
        left: -150px;
      }

      @include tablet {
        top: -65px;
        left: -80px;
        width: 400px;
      }

      @include mobile {
        top: -55px;
        left: -60px;
        width: 290px;
      }
    }

    &.figure5 {
      top: 35px;
      left: 440px;

      @include desktop {
        top: -10px;
        left: 450px;
        width: 350px;
      }

      @include tablet {
        top: -40px;
        left: 320px;
        width: 300px;
      }

      @include mobile {
        top: -30px;
        left: 140px;
        width: 210px;
      }
    }

    &.figure6 {
      top: 820px;
      right: -140px;

      @include desktop {
        top: 780px;
        right: -150px;
        width: 400px;
      }

      @include tablet {
        top: 720px;
        right: -100px;
        width: 300px;
      }

      @include mobile {
        top: 800px;
        right: -100px;
        width: 220px;
      }
    }

    &.figure7 {
      top: 850px;
      left: -95px;

      @include desktop {
        top: 800px;
        left: -114px;
        width: 350px;
      }

      @include tablet {
        top: 800px;
        left: -120px;
        width: 300px;
      }

      @include mobile {
        top: 900px;
        left: -60px;
        width: 200px;
      }
    }

    &.science-figure-1 {
      top: -10px;
      left: -150px;

      @include desktop {
        top: -10px;
        left: -235px;
        width: 843px;
      }

      @include tablet {
        top: -10px;
        left: -322px;
        width: 797px;
      }

      @include mobile {
        top: -10px;
        left: -340px;
        width: 563px;
      }
    }

    &.science-figure-2 {
      top: 50px;
      right: 20px;

      @include desktop {
        top: 50px;
        right: -200px;
        width: 510px
      }

      @include tablet {
        top: 40px;
        right: -300px;
        width: 482px;
      }

      @include mobile {
        top: 40px;
        right: -290px;
        width: 336px;
      }
    }

    &.science-figure-3 {
      top: 900px;
      right: 50px;

      @include desktop {
        top: 900px;
        right: -250px;
        width: 600px;
      }

      @include tablet {
        top: 900px;
        right: -180px;
        width: 500px;
      }

      @include mobile {
        top: 782px;
        right: -300px;
        width: 438px;
      }
    }

    &.science-figure-4 {
      top: 30px;
      left: -20px;

      @include desktop {
        top: -20px;
        left: -50px;
        width: 322px;
      }

      @include tablet {
        top: 15px;
        left: -60px;
        width: 221px;
      }

      @include mobile {
        top: 10px;
        left: -70px;
        width: 150px;
      }
    }

    &.science-figure-5 {
      top: 230px;
      left: 35%;

      @include desktop {
        top: 134px;
        left: 34%;
        width: 340px;
      }

      @include tablet {
        top: 411px;
        left: 34%;
        width: 277px;
      }

      @include mobile {
        top: 840px;
        left: auto;
        right: -45px;
        width: 190px;
      }
    }

    &.science-figure-6 {
      top: 300px;
      right: -220px;

      @include desktop {
        top: 190px;
        right: -200px;
        width: 450px;
      }

      @include tablet {
        top: -190px;
        right: -250px;
        width: 400px;
      }

      @include mobile {
        top: 280px;
        right: -100px;
        width: 300px;
      }
    }

    &.science-figure-7 {
      top: 1000px;
      left: 60px;

      @include desktop {
        top: 710px;
        left: -5px;
        width: 366px;
      }

      @include tablet {
        top: 800px;
        left: -40px;
        width: 300px;
      }

      @include mobile {
        top: 1290px;
        left: -50px;
        width: 210px;
      }
    }

    &.science-figure-8 {
      top: 1100px;
      right: -30px;

      @include desktop {
        top: 890px;
        right: -100px;
        width: 440px;
      }

      @include tablet {
        top: 1070px;
        right: -100px;
        width: 380px;
      }

      @include mobile {
        top: 1700px;
        right: -70px;
        width: 270px
      }
    }

    &.figure-y1 {
      top: 70px;
      left: 70px;

      @include tablet {
        width: 192px;
        top: 90px;
      }

      @include mobile {
        left: -40px;
      }
    }

    &.figure-y2 {
      top: -60px;
      right: -100px;

      @include tablet {
        top: 40px;
        right: -160px;
        width: 750px;
      }
      @include mobile {
        top: 0;
        right: -270px;
        width: 530px;
      }
    }

    &.figure-n1 {
      top: 70px;
      left: 70px;

      @include low-desktop-only {
        left: -5%;
      }

      @include mobile {
        left: -40%;
        top: 125px;
        width: 400px;
      }
    }

    &.figure-n2 {
      top: -60px;
      right: -100px;

      @include mobile {
        top: -15px;
        right: -15%;
        width: 200px;
      }
    }

    &.figure-bg1 {
      top: 28px;
      left: 0;

      @include tablet {
        top: 10px;
        right: -110px;
        width: 300px;
      }

      @include mobile {
        top: 160px;
        right: -60px;
        width: 140px;
      }
    }
    &.figure-bg2 {
      bottom: -20%;
      right: calc(50% - 720px);
      @include tablet {
        bottom: 10%;
        right: -5%;
        width: 300px;
      }
      @include mobile {
        display: none;
      }
    }
    &.figure-bg3 {
      top: -230px;
      left: calc(50% - 300px);
      @include tablet {
        left: 30%;
        width: 350px;
      }
      @include mobile {
        top: -160px;
        width: 150px;
      }
    }
    &.figure-bg4 {
      top: 50%;
      left: calc(-810px + 50%);
      @include tablet {
        display: none;
      }
    }
    &.figure-bg5 {
      top: -50%;
      right: calc(50% - 600px);
      @include tablet {
        top: -30%;
        right: 10%;
        width: 290px;
      }
      @include mobile {
        width: 210px;
      }
    }
    &.figure-bg6 {
      top: -80%;
      left: 24%;
      @include tablet {
        top: -25%;
        left: 19%;
        width: 170px;
      }
      @include mobile {
        width: 120px;
      }
    }
    &.figure-bg7 {
      right: 0;
      top: -90%;
      @include low-desktop {
        right: -150px;
      }
      @include tablet {
        right: 0;
        width: 200px;
        top: 0;
      }
      @include mobile {
        right: -30px;
        width: 110px;
        top: 30%;
      }
    }
    &.figure-bg8 {
      top: -40%;
      left: 0;
      @include tablet {
        width: 200px;
      }
    }
    &.figure-bg9 {
      top: -50%;
      right: calc(50% - 600px);
      @include tablet {
        top: -20%;
        right: calc(50% - 420px);
        width: 520px;
      }
      @include mobile {
        top: -80%;
        right: 0;
        width: 250px;
      }
    }
    &.science-detail-1 {
      top: 80px;
      right: -100px;

      @include desktop {
        top: 40px;
        right: -160px;
        width: 400px;
      }
      @include mobile {
        top: 0;
        right: -270px;
        width: 530px;
      }
    }
    &.science-detail-2 {
      top: 600px;
      left: -100px;

      @include desktop {
        top: 450px;
        left: -100px;
        width: 400px;
      }

      @include tablet {
        top: 370px;
        left: -100px;
        width: 326px;
      }

      @include mobile {
        top: 400px;
        left: -70px;
        width: 150px;
      }
    }

    &.science-detail-3 {
      top: 1024px;
      right: 10px;

      @include desktop {
        top: 840px;
        right: -90px;
        width: 320px;
      }

      @include tablet {
        top: 700px;
        right: -90px;
        width: 260px;
      }

      @include mobile {
        top: 650px;
        right: -40px;
        width: 120px;
      }
    }

    &.science-detail-4 {
      top: 1800px;
      left: 10px;

      @include desktop {
        top: 1400px;
        left: -20px;
        width: 240px;
      }

      @include tablet {
        top: 1100px;
        left: -40px;
        width: 190px;
      }

      @include mobile {
        top: 840px;
        left: -50px;
        width: 90px;
      }
    }

    &.science-detail-5 {
      top: 2200px;
      right: 60px;

      @include desktop {
        top: 1700px;
        right: -40px;
        width: 430px;
      }

      @include tablet {
        top: auto;
        bottom: 480px;
        right: -60px;
        width: 348px;
      }

      @include mobile {
        top: auto;
        bottom: 800px;
        right: -60px;
        width: 200px;
      }
    }

    &.science-detail-6 {
      top: 2600px;
      left: -150px;

      @include desktop {
        top: 2440px;
      }

      @include tablet {
        top: auto;
        bottom: 45px;
        left: -130px;
        width: 360px;
      }

      @include mobile {
        top: auto;
        bottom: 400px;
        width: 180px;
        left: -70px;
      }
    }

    &.figure-bgn1 {
      top: 1%;
      right: 0;
      @include low-desktop {
        width: 282px;
      }
      @include tablet {
        right: -4%;
        width: 210px;
      }
      @include mobile {
        right: -22%;
        width: 126px;
      }
    }

    &.figure-bgn2 {
      top: 6%;
      left: 40%;
      @include low-desktop {
        width: 180px;
        top: 12%;
      }
      @include tablet {
        width: 132px;
      }
      @include mobile {
        left: 1%;
        top: 10%;
        width: 80px;
      }
    }

    &.figure-bgn3 {
      top: 18%;
      left: -12%;
      @include low-desktop {
        top: 26%;
        width: 470px;
      }
      @include tablet {
        width: 350px;
      }
      @include mobile {
        top: 42%;
        width: 260px;
      }
    }

    &.figure-bgn4 {
      top: 39%;
      left: 50%;
      @include low-desktop {
        width: 180px;
        left: 58%;
      }
      @include tablet {
        width: 130px;
      }
      @include mobile {
        top: 30%;
        left: 81%;
        width: 80px;
      }
    }

    &.figure-bgn5 {
      top: 55%;
      right: 1%;
      @include low-desktop {
        top: 60%;
        width: 180px;
      }
      @include tablet {
        width: 130px;
      }
      @include mobile {
        top: 72%;
        right: -2%;
        width: 80px;
      }
    }

    &.figure-bgn6 {
      top: 61%;
      left: 5%;
      @include low-desktop {
        top: 87%;
        width: 275px;
      }
      @include tablet {
        top: 67%;
        width: 180px;
      }
      @include mobile {
        top: 56%;
        left: -7%;
        width: 100px;
      }
    }

    &.figure-bgn7 {
      top: 91%;
      right: 1%;
      @include low-desktop {
        top: 83%;
        width: 200px;
      }
      @include tablet {
        width: 150px;
      }
      @include mobile {
        top: 87%;
        width: 110px;
      }
    }

    &.figure-nd1 {
      top: 100%;
      left: 0;

      @include low-desktop {
        left: -150px;
      }

      @include tablet {
        left: -250px;
      }

      @include mobile {
        left: -60%;
        top: 175px;
        width: 400px;
      }
    }

    &.figure-nd2 {
      top: 0;
      right: -100px;

      @include low-desktop {
        right: -330px;
      }

      @include tablet {
        right: -430px;
      }

      @include mobile {
        top: -15px;
        right: -500px;
      }
    }

    &.figure-bgnd1 {
      top: 60px;
      right: -35px;

      @include low-desktop {
        top: 220px;
        right: -116px;
        width: 320px;
      }
      @include tablet {
        top: 232px;
        right: -200px;
      }
      @include mobile {
        top: 50px;
        right: -50px;
        width: 150px;
      }
    }

    &.figure-bgnd2 {
      top: 94px;
      left: -85px;

      @include low-desktop {
        top: 300px;
        left: -50px;
        width: 360px;
      }
      @include tablet {
        top: 310px;
        left: -82px;
      }
      @include mobile {
        top: 282px;
        left: -25px;
        width: 185px;
      }
    }

    &.figure-bgnd3 {
      top: 828px;
      left: 66px;

      @include low-desktop {
        top: 845px;
        left: 10px;
        width: 200px;
      }
      @include tablet {
        top: 855px;
        left: -30px;
      }
      @include mobile {
        top: 563px;
        left: 40px;
        width: 102px;
      }
    }

    &.figure-bgnd4 {
      top: 929px;
      right: -180px;

      @include low-desktop {
        top: 823px;
        right: -76px;
        width: 300px;
      }
      @include tablet {
        top: 835px;
        right: -137px;
      }
      @include mobile {
        top: 400px;
        right: -50px;
        width: 140px;
      }
    }

    &.figure-bgnd5 {
      top: 1300px;
      left: -150px;
      width: 600px;

      @include low-desktop {
        top: 1570px;
        left: -110px;
        width: 422px;
      }
      @include tablet {
        top: 1766px;
        left: -144px;
        width: 322px;
      }
      @include mobile {
        top: 1600px;
        left: -100px;
        width: 222px;
      }
    }

    &.figure-bgnd6 {
      top: 2200px;
      left: -184px;

      @include low-desktop {
        top: 2350px;
        left: -140px;
        width: 360px;
      }
      @include tablet {
        left: -172px;
      }
      @include mobile {
        top: 2500px;
        width: 185px;
        left: -40px;
      }
    }

    &.figure-bgnd7 {
      top: 2400px;
      right: -74px;

      @include low-desktop {
        top: 2490px;
        right: -130px;
        width: 250px;
      }
      @include tablet {
        top: 2636px;
        right: -10px;
      }
      @include mobile {
        top: 2800px;
        right: -70px;
        width: 180px;
      }
    }

    &.figure-bgnd8 {
      top: 2500px;
      left: 40%;

      @include low-desktop {
        top: 2461px;
        left: 40%;
        width: 180px;
      }
      @include mobile {
        top: 3000px;
        left: -70px;
        width: 142px;
      }
    }
    &.figure-sp1 {
      top: 30px;
      left: 18%;
      @include low-desktop {
        top: 0;
        left: 6%;
      }
      @include tablet {
        top: 30px;
        left: -6%;
      }
      @include mobile {
        top: 20px;
        left: -11%;
        width: 170px;
      }
    }
    &.figure-ay1 {
      top: 30px;
      left: -120px;

      @include desktop {
        top: 80px;
        left: -90px;
        width: 800px;
      }

      @include tablet {
        top: 80px;
        left: -340px;
      }

      @include mobile {
        top: 50px;
        left: -320px;
        width: 500px;
      }
    }
    &.figure-ay2 {
      top: 95px;
      right: -40px;

      @include desktop {
        top: 130px;
        right: -180px;
        width: 410px;
      }

      @include tablet {
        right: -200px;
      }

      @include mobile {
        top: 130px;
        right: -140px;
        width: 250px;
      }
    }
    &.figure-ay3 {
      top: -170px;
      left: -108px;

      @include desktop {
        top: -85px;
        left: -120px;
        width: 220px;
      }

      @include tablet {
        top: -45px;
        left: -75px;
        width: 167px;
      }

      @include mobile {
        top: 15px;
        left: -30px;
        width: 77px;
      }
    }
    &.figure-ay4 {
      top: -65px;
      right: 2px;

      @include desktop {
        width: 428px;
        top: 15px;
        right: -150px;
      }

      @include tablet {
        width: 322px;
        top: 25px;
        right: -130px;
      }

      @include mobile {
        width: 148px;
        top: 80px;
        right: -95px;
      }
    }
    &.figure-ay5 {
      top: 290px;
      left: 40px;

      @include desktop {
        top: 300px;
        left: 10px;
        width: 378px;
      }

      @include tablet {
        top: 350px;
        left: -60px;
        width: 290px;
      }

      @include mobile {
        top: 235px;
        left: -25px;
        width: 125px;
      }
    }
    &.figure-ay6 {
      top: 960px;
      left: -80px;

      @include desktop {
        top: 896px;
        left: -142px;
      }

      @include tablet {
        top: 940px;
        left: -102px;
        width: 360px;
      }

      @include mobile {
        top: 527px;
        left: -60px;
        width: 160px;
      }
    }
    &.figure-ay7 {
      top: 926px;
      right: -125px;

      @include desktop {
        top: 870px;
        right: -280px;
        width: 445px;
      }

      @include tablet {
        top: 820px;
        right: -180px;
        width: 335px;
      }

      @include mobile {
        top: 1250px;
        right: -81px;
        width: 160px;
      }
    }
    &.figure-ayd1 {
      top: 356px;
      left: -53px;

      @include desktop {
        top: 415px;
        left: -110px;
        width: 333px;
      }

      @include tablet {
        top: 375px;
        left: -131px;
        width: 293px;
      }

      @include mobile {
        top: 294px;
        left: -44px;
        width: 108px;
      }
    }
    &.figure-ayd2 {
      top: 70px;
      right: -53px;

      @include desktop {
        top: 124px;
        right: -120px;
        width: 333px;
      }

      @include tablet {
        top: 120px;
        right: -205px;
        width: 293px;
      }

      @include mobile {
        top: 199px;
        right: -42px;
        width: 108px;
      }
    }
    &.figure-ayd3 {
      top: 1020px;
      left: 57px;

      @include desktop {
        top: 1440px;
        left: -34px;
      }

      @include tablet {
        top: 1280px;
        left: -63px;
        width: 296px;
      }

      @include mobile {
        top: 627px;
        left: -20px;
        width: 109px;
      }
    }
    &.figure-ayd4 {
      top: 1005px;
      right: -150px;

      @include desktop {
        top: 890px;
        right: -208px;
        width: 594px;
      }

      @include tablet {
        top: 775px;
        right: -283px;
        width: 523px;
      }

      @include mobile {
        top: 445px;
        right: -70px;
        width: 193px;
      }
    }
    &.figure-ayd5 {
      top: 1952px;
      left: -200px;

      @include desktop {
        top: 2400px;
        left: -158px;
        width: 400px;
      }

      @include tablet {
        top: 2400px;
        left: -173px;
        width: 376px;
      }

      @include mobile {
        top: 1034px;
        left: -60px;
        width: 138px;
      }
    }
    &.figure-ayd6 {
      top: 2300px;
      right: -96px;

      @include desktop {
        top: 2550px;
        right: -153px;
        width: 304px;
      }

      @include tablet {
        top: 2256px;
        right: -134px;
        width: 268px;
      }

      @include mobile {
        top: 987px;
        right: -16px;
        width: 98px;
      }
    }
    &.figure-ayd7 {
      top: 2800px;
      left: 84px;

      @include desktop {
        top: 3000px;
        left: 0;
        width: 210px;
      }

      @include tablet {
        top: 3080px;
        left: -33px;
        width: 185px;
      }

      @include mobile {
        top: 2587px;
        left: -8px;
        width: 68px;
      }
    }
    &.figure-sp2 {
      top: 100px;
      right: 2%;
      @include low-desktop {
        top: 100px;
        right: -28%;
      }
      @include tablet {
        right: -48%;
      }
      @include mobile {
        right: -295px;
        width: 480px;
      }
    }
    &.figure-bgsp1 {
      top: -15%;
      left: -90px;
      @include tablet {
        top: 0;
        left: -170px;
        width: 320px;
      }
      @include mobile {
        top: 1%;
        left: -200px;
        width: 260px;
      }
    }
    &.figure-bgsp2 {
      top: 10%;
      left: 50%;
      @include tablet {
        top: 16%;
        left: 50%;
        width: 200px;
      }
    }
    &.figure-bgsp3 {
      top: 30%;
      left: 40%;
      @include tablet {
        top: 30%;
        left: 22%;
        width: 290px;
      }
    }
    &.figure-bgsp4 {
      top: 50%;
      left: -6%;
      @include tablet {
        display: none;
      }
    }
    &.figure-bgsp5 {
      top: 70%;
      left: 5%;
      @include tablet {
        top: 66%;
        left: -11%;
        width: 250px;
      }
      @include mobile {
        top: 56%;
        left: -2%;
        width: 200px;
      }
    }
    &.figure-bgsp6 {
      top: 73%;
      right: -4%;
      @include tablet {
        top: 63%;
        right: -23%;
        width: 400px;
      }
      @include mobile {
        top: 81%;
        right: -40%;
        width: 250px;
      }
    }
    &.figure-bgpd1 {
      top: -100px;
      left: 250px;
      @include low-desktop {
        top: 0;
        left: auto;
        right: -200px;
        width: 390px;
      }
      @include tablet {
        right: -350px;
      }
    }
    &.figure-bgpd2 {
      top: 800px;
      left: 340px;
      @include low-desktop {
        top: 830px;
        left: auto;
        right: -300px;
        width: 400px;
      }
      @include tablet {
        top: 0;
        left: -200px;
        right: auto;
        width: 300px;
      }
      @include mobile {
        top: 770px;
        left: auto;
        right: -170px;
        width: 200px;
      }
    }
    &.figure-bgpd3 {
      display: none;
      @include low-desktop {
        display: block;
        top: 500px;
        left: -100px;
      }
      @include tablet {
        top: 590px;
        left: -180px;
        width: 450px;
      }
      @include mobile {
        top: 530px;
        width: 250px;
      }
    }
    &.figure-bgpd4 {
      top: 1500px;
      left: 240px;
      @include low-desktop {
        left: auto;
        right: -300px;
      }
      @include tablet {
        top: 1550px;
        right: -340px;
        width: 400px;
      }
      @include mobile {
        top: 2650px;
        right: -240px;
        width: 300px;
      }
    }
    &.figure-bgpd5 {
      top: 2300px;
      left: -70px;
      @include low-desktop {
        width: 500px;
        top: 2450px;
        left: auto;
        right: -80px;
      }
      @include tablet {
        width: 420px;
        top: 2460px;
        right: -130px;
      }
      @include mobile {
        width: 220px;
        top: 2210px;
      }
    }
    &.figure-bgpd6 {
      display: none;
      @include low-desktop {
        display: block;
        top: 2090px;
        left: -120px;
      }
      @include tablet {
        top: 2120px;
        width: 420px;
      }
      @include mobile {
        top: 2420px;
        width: 220px;
      }
    }
    &.figure-mp1 {
      top: 178px;
      left: -38px;

      @include low-desktop {
        top: 157px;
        left: -160px;
        width: 268px;
      }
      @include tablet {
        top: 162px;
        left: -160px;
        width: 268px;
      }
      @include mobile {
        top: 100px;
        left: -100px;
        width: 120px;
      }
    }
    &.figure-mp2 {
      top: 0;
      right: 336px;

      @include low-desktop {
        top: 0;
        right: 153px;
        width: 238px;
      }
      @include tablet {
        top: 0;
        right: -104px;
        width: 238px;
      }
      @include mobile {
        top: 24px;
        right: -52px;
        width: 108px;
      }
    }
    &.figure-mp3 {
      top: 900px;
      left: 77px;

      @include low-desktop {
        top: 826px;
        left: -80px;
        width: 240px;
      }
      @include tablet {
        top: 1279px;
        left: -75px;
        width: 240px;
      }
      @include mobile {
        top: 600px;
        left: -55px;
        width: 108px;
      }
    }
    &.figure-mp4 {
      top: 489px;
      right: 245px;

      @include low-desktop {
        top: 451px;
        right: 52px;
        width: 400px;
      }
      @include tablet {
        top: 747px;
        right: -68px;
        width: 400px;
      }
      @include mobile {
        top: 356px;
        right: -45px;
        width: 197px;
      }
    }
    &.figure-mp5 {
      top: 1490px;
      left: -64px;

      @include low-desktop {
        top: 1372px;
        left: -210px;
        width: 381px;
      }
      @include tablet {
        top: 2357px;
        left: -210px;
        width: 381px;
      }
      @include mobile {
        top: 1100px;
        left: -115px;
        width: 172px;
      }
    }
    &.figure-mp6 {
      top: 1750px;
      left: 40%;

      @include low-desktop {
        top: 1582px;
        left: 40%;
        width: 240px;
      }
      @include tablet {
        top: 2514px;
        left: 50%;
        width: 240px;
      }
      @include mobile {
        top: 1160px;
        left: 175px;
        width: 108px;
      }
    }
    &.figure-mp7 {
      top: 1382px;
      right: -25px;

      @include low-desktop {
        top: 1272px;
        right: -180px;
        width: 483px;
      }
      @include tablet {
        top: 1773px;
        right: -240px;
        width: 450px;
      }
      @include mobile {
        top: 800px;
        right: -120px;
        width: 260px;
      }
    }
    &.figure-bsh-1 {
      top: -160px;
      left: -150px;

      @include desktop {
        top: -175px;
        left: -250px;
        width: 843px;
      }
      @include tablet {
        top: -100px;
        left: -350px;
        width: 753px;
      }
      @include mobile {
        top: -80px;
        left: -300px;
        width: 511px;
      }
    }
    &.figure-bsh-2 {
      top: -100px;
      right: 80px;

      @include desktop {
        top: -140px;
        right: -240px;
        width: 510px;
      }
      @include tablet {
        top: -40px;
        right: -230px;
        width: 450px;
      }
      @include mobile {
        top: -60px;
        right: -260px;
        width: 306px;
      }
    }
    &.figure-bs-1 {
      top: 67px;
      left: 0;

      @include desktop {
        top: -25px;
        left: -109px;
      }
      @include tablet {
        top: -25px;
        left: -129px;
      }
      @include mobile {
        top: 6px;
        left: -15px;
        width: 96px;
      }
    }
    &.figure-bs-2 {
      top: 187px;
      right: 369px;

      @include desktop {
        top: 100px;
        right: 65px;
      }
      @include tablet {
        top: 96px;
        right: -170px;
      }
      @include mobile {
        top: 48px;
        right: -48px;
        width: 92px;
      }
    }
    &.figure-bs-3 {
      top: 584px;
      right: 403px;

      @include desktop {
        top: 497px;
        right: 98px;
      }
      @include tablet {
        top: 493px;
        right: -138px;
      }
      @include mobile {
        top: 190px;
        right: -36px;
        width: 130px;
      }
    }
    &.figure-bs-4 {
      top: 1082px;
      left: -21px;

      @include desktop {
        top: 995px;
        left: 132px;
      }
      @include tablet {
        top: 991px;
        left: -152px;
      }
      @include mobile {
        top: 1215px;
        left: -15px;
        width: 96px;
      }
    }
    &.figure-bs-5 {
      top: 1200px;
      right: -70px;

      @include desktop {
        top: 1067px;
        right: -300px;
      }
      @include tablet {
        top: 1027px;
        right: -360px;
      }
      @include mobile {
        top: 1300px;
        right: -150px;
        width: 214px;
      }
    }
    &.figure-bs-6 {
      top: 2000px;
      left: -104px;

      @include desktop {
        top: 1900px;
        left: -180px;
      }
      @include tablet {
        top: 1900px;
        left: -235px;
      }
      @include mobile {
        top: 2400px;
        left: -52px;
        width: 187px;
      }
    }
    &.figure-bs-7 {
      top: 2000px;
      right: 185px;

      @include desktop {
        top: 2000px;
        right: -200px;
      }
      @include tablet {
        top: 2000px;
        right: -300px;
      }
      @include mobile {
        top: 2400px;
        right: -120px;
        width: 230px;
      }
    }
    &.figure-bs-8 {
      top: 3200px;
      left: 720px;

      @include desktop {
        top: 3100px;
        left: 720px;
      }
      @include low-desktop {
        top: 3200px;
        left: 520px;
        width: 300px;
      }
      @include tablet {
        top: 3200px;
        left: 420px;
        width: 240px;
      }
      @include mobile {
        top: 4750px;
        left: 20px;
        width: 240px;
      }
    }
    &.figure-search-page-1 {
      top: -350px;
      left: -150px;

      @include desktop {
        top: -410px;
        left: -340px;
        width: 843px;
      }
      @include tablet {
        top: -300px;
        left: -300px;
        width: 753px;
      }
      @include mobile {
        top: -80px;
        left: -300px;
        width: 511px;
      }
    }
    &.figure-search-page-2 {
      top: -350px;
      right: 80px;

      @include desktop {
        top: -310px;
        right: -140px;
        width: 500px;
      }
      @include tablet {
        top: -240px;
        right: -230px;
        width: 450px;
      }
      @include mobile {
        top: -60px;
        right: -260px;
        width: 306px;
      }
    }
    &.figure-search-page-3 {
      top: 200px;
      left: -100px;

      @include desktop {
        top: 550px;
        left: -30px;
        width: 363px;
      }
      @include tablet {
        top: 440px;
        left: 50px;
        width: 270px;
      }
      @include mobile {
        top: 940px;
        left: -50px;
        width: 182px;
      }
    }
    &.figure-search-page-4 {
      top: -20px;
      left: 527px;

      @include desktop {
        top: 300px;
        left: 432px;
        width: 178px;
      }
      @include tablet {
        top: 258px;
        left: 400px;
        width: 132px;
      }
      @include mobile {
        top: 120px;
        left: -20px;
        width: 80px;
      }
    }
    &.figure-search-page-5 {
      top: -88px;
      right: -50px;

      @include desktop {
        top: 130px;
        right: -30px;
        width: 282px;
      }
      @include tablet {
        top: 32px;
        right: -94px;
        width: 210px;
      }
      @include mobile {
        top: -108px;
        right: -40px;
        width: 126px;
      }
    }
    &.figure-search-page-6 {
      top: 569px;
      left: 45%;

      @include desktop {
        top: 747px;
        left: 50%;
        width: 176px;
      }
      @include tablet {
        top: 768px;
        left: 53%;
        width: 132px;
      }
      @include mobile {
        top: 550px;
        left: auto;
        right: 6px;
        width: 80px;
      }
    }
    &.figure-search-page-7 {
      top: 874px;
      right: -30px;

      @include desktop {
        top: 955px;
        right: -20px;
        width: 178px;
      }
      @include tablet {
        top: 1043px;
        right: -65px;
        width: 132px;
      }
      @include mobile {
        top: 1200px;
        right: -30px;
        width: 80px;
      }
    }
    &.figure-search-page-8 {
      top: 970px;
      left: 140px;

      @include desktop {
        top: 1133px;
        left: 57px;
        width: 310px;
      }
      @include tablet {
        top: 1200px;
        left: 57px;
        width: 228px;
      }
      @include mobile {
        top: 1400px;
        left: -55px;
        width: 142px;
      }
    }
    &.figure-fbh-1 {
      top: -230px;
      left: -154px;

      @include low-desktop {
        top: -180px;
        left: -190px;
        width: 700px;
      }
      @include tablet {
        display: none;
      }
    }
    &.figure-fbh-2 {
      top: -240px;
      right: 70px;

      @include low-desktop {
        top: -140px;
        right: 5px;
        width: 450px;
      }
      @include tablet {
        display: none;
      }
    }
    &.figure-fbh-3 {
      display: none;

      @include tablet {
        display: block;
        top: -70px;
        left: -348px;
      }

      @include mobile {
        top: -40px;
        left: -320px;
        width: 500px;
      }
    }
    &.figure-fbh-4 {
      display: none;

      @include tablet {
        display: block;
        top: 130px;
        right: -200px;
      }

      @include mobile {
        top: 70px;
        right: -150px;
        width: 250px;
      }
    }
    &.figure-404-1 {
      top: -150px;
      left: -30px;

      @include low-desktop {
        top: -120px;
        left: -180px;
        width: 380px;
      }
      @include tablet {
        top: -300px;
        left: -160px;
        width: 360px
      }
      @include mobile {
        top: -140px;
        left: -130px;
        width: 200px
      }
    }
    &.figure-404-2 {
      top: 200px;
      right: -560px;

      @include low-desktop {
        top: 130px;
        right: -620px;
        width: 800px;
      }
      @include tablet {
        top: 550px;
        right: -550px;
        width: 700px;
      }
      @include mobile {
        top: auto;
        bottom: -20px;
        right: -360px;
        width: 450px;
      }
    }
  }
}
</style>
