<template>
  <component
    :is="tag"
    class="play-button"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span class="play-button__icon">
      <inline-svg class="play-button__icon-svg" src="play-icon" />
    </span>
    <span class="play-button__content"><slot></slot></span>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'button'
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.play-button';

#{$b} {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-inter;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.3;
  color: $white-true;
  border: 1px solid $white-true;
  border-radius: 20px;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  padding: 4px 14px 4px 4px;
  cursor: pointer;
  transition: $transtion-default;

  &:hover {
    @include mobile-min {
      background-color: $white-true;
      color: $color-base-origin;
    }
  }

  // .play-button__icon
  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    color: $color-base;
    margin-right: 8px;
    border-radius: 50%;
    background-color: $white-true;
    transition: $transtion-default;

    #{$b}:hover & {
      @include mobile-min {
        background-color: $color-base;
        color: $white-true;
      }
    }

    // .play-button__icon-svg
    &-svg {
      width: 12px;
      height: 12px;
      padding-left: 2px;
    }
  }
}
</style>
