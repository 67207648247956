<template>
  <div class="u-branches">
    <UFigures
      class="u-branches__figures"
      :items="figures"
      position="static"
      :fullWidth="true"
    >
      <div class="u-branches__inner">
        <div class="u-g-header">
          <h2 class="title-2xl u-branches__title">{{ title }}</h2>
          <UCaption class="u-branches__caption" />
        </div>
        <USimpleCardList
          class="u-branches__list"
          :cardList="pageList"
          :buttonLabel="buttonLabel"
          :ymData="ymData"
          ymParamKey="Directions"
        />
        <div class="u-branches__footer">
          <div class="u-branches__back">
            <UniversitiesBack :href="hrefWithRegion(backUrl)" />
          </div>
          <CustomButton
            v-if="isExistsBtnLoadMore"
            class="u-branches__show-more"
            size="md"
            theme="primary"
            :status="btnStatus"
            @click="loadMore(false)"
          >
            Показать еще
          </CustomButton>
        </div>
      </div>
    </UFigures>
  </div>
</template>

<script>
import PaginationPageMixin from '@/mixins/universities/PaginationPageMixin.js'

export default {
  mixins: [PaginationPageMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    buttonLabel: {
      type: String,
      default: ''
    },
    backUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      figures: [
        {
          name: 'f-u-branches-1',
          image: '/frontend/assets/img/bg-shapes/circle-left-opacity.svg',
          animation: 'fade-in-left'
        },
        {
          name: 'f-u-branches-2',
          image: '/frontend/assets/img/bg-shapes/circle-left-opacity.svg',
          animation: 'fade-in-left'
        },
      ],
      pageList: JSON.parse(JSON.stringify(this.items)),
      ymData: {
        code: 94756882,
        type: 'reachGoal',
        operation: 'click_specialties',
        options: {
          Directions: ''
        }
      }
    }
  },
  computed: {
    getUrlForLoadPage() {
      const params = (new URL(document.location)).searchParams;
      const knowledgeArea = params.get('knowledgeArea') || ''
      return `/api/rest-component/dalee/direction/getElementsList/?filter[knowledgeArea]=${knowledgeArea}`
    }
  },
  created() {
    this.loadPage()
  }
};
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-branches';

#{$b} {
  position: relative;

  // .u-branches__inner
  &__inner {
    position: relative;
  }

  // .u-branches__title
  &__title {
    margin: 0;
  }

  // .universities-banner__caption
  &__caption {
    margin-right: auto;
    margin-left: 30px;

    @include tablet {
      margin-left: 16px;
    }
  }

  // .u-branches__footer
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 24px;
    }
  }

  // .u-branches__show-more
  &__show-more {
    @include mobile {
      order: -1;
      margin-bottom: 24px;
      width: 100%;
    }
  }
}
</style>
