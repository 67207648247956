<template>
  <a
    :href="hrefWithRegion(data.url)"
    class="u-project-card"
    :class="{'u-project-card--no-image': !data.image}"
  >
    <div class="u-project-card__header">
      <div
        v-if="data.image"
        class="u-project-card__img"
        :style="`background-image:url('${data.image}')`"
      />
    </div>
    <div class="u-project-card__body">
      <div
        v-if="Boolean(data.category)"
        class="u-project-card__category tt-uppercase"
      >
        {{ data.category }}
      </div>
      <div
        v-if="Boolean(data.partners) && Boolean(data.partners.length)"
        class="u-project-card__partners"
      >
        <img
          v-for="(partner,i) in data.partners"
          :key="`u-project-card__partner-logo-${i}`"
          class="u-project-card__partner-logo"
          :src="partner"
          :alt="data.title"
        >
      </div>
      <h3
        class="u-project-card__title tt-uppercase"
        v-html="data.title"
      />
      <p
        v-if="Boolean(data.text)"
        class="u-project-card__text"
        v-html="data.text"
      />
      <div class="u-project-card__actions">
        <CustomButton class="u-project-card__btn">
          Подробнее
        </CustomButton>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      require: true
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-project-card';

$headerHeight: 289px;

#{$b} {
  height: 100%;
  font-family: $font-family-inter;
  cursor: pointer;
  color: $white-true;
  text-decoration: none;
  display: block;
  transition: $transtion-default;
  border-radius: 16px;

  // .u-project-card--no-image
  &--no-image {
    background-color: $color-base-origin;
  }

  // @include mobile {
  //   background-color: $color-base;
  // }

  &:hover {
    @include mobile-min {
      transform: scale(1.01);
    }
  }

  // .u-project-card__header
  &__header {
    position: relative;
    height: $headerHeight;
    overflow: hidden;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;

    @include mobile {
      width: 100%;
      height: auto;
      aspect-ratio: 376/240;
    }
    #{$b}:not(#{$b}--no-image) & {
      @include mobile {
        overflow: unset;
      }
    }
  }

  // .u-project-card__img
  &__img {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include mobile {
      border-radius: 16px;
    }
  }

  // .u-project-card__body
  &__body {
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    overflow: hidden;
    padding: 34px 24px 24px 24px;
    margin-top: -10px;
    background-color: $color-base-origin;

    #{$b}--no-image & {
      margin: 0;
      padding: 24px;
      background-color: transparent;
      @include mobile-min {
        height: calc(100% - $headerHeight);
      }
    }

    #{$b}:not(#{$b}--no-image) & {
      @include mobile-min {
        height: calc(100% - $headerHeight + 10px);
      }
    }
  }

  // .u-project-card__category
  &__category {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.1em;
    margin-bottom: 16px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .u-project-card__partners
  &__partners {
    display: flex;
    flex-wrap: wrap;
    @include mobile {
      margin-bottom: 8px;
    }
  }

  // .u-project-card__partner-logo
  &__partner-logo {
    height: 38px;
    width: auto;
    margin-bottom: 18px;

    &:not(:last-child) {
      margin-right: 32px;
    }

    @include mobile {
      margin-bottom: 16px;
    }
  }

  // .u-project-card__title
  &__title {
    font-family: $font-family-main-head;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;
    margin: 0 0 16px 0;
  }

  // .u-project-card__text
  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    margin: 0 0 24px 0;
  }

  // .u-project-card__actions
  &__actions {
    margin-top: auto;
    text-align: right;
  }
}

</style>
