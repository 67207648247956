<template>
  <div class="initiatives-events-filter">
    <div class="initiatives-events-filter__item u-select u-select--small">
      <div class="initiatives-events-filter__label">
        Направления
      </div>
      <v-select
        v-model="form.direction"
        :clearable="false"
        :searchable="true"
        label="name"
        :components="{ OpenIndicator }"
        :reduce="option => option.id"
        :options="directionOptions"
        @input="onChangeFilter"
      >
        <template #no-options>
          Не найдено
        </template>
      </v-select>
    </div>
    <div class="initiatives-events-filter__item u-select u-select--small">
      <div class="initiatives-events-filter__label">
        Аудитория
      </div>
      <v-select
        v-model="form.audience"
        :clearable="false"
        :searchable="true"
        label="name"
        :components="{ OpenIndicator }"
        :reduce="option => option.id"
        :options="audienceOptions"
        @input="onChangeFilter"
      >
        <template #no-options>
          Не найдено
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {vSelect},
  props: {
    directionOptions: {
      type: Array,
      default: () => []
    },
    audienceOptions: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      form: {
        direction: '',
        audience: '',
      },
      OpenIndicator: {
        render: createElement => createElement('span')
      },
    }
  },
  methods: {
    onChangeFilter() {
      this.$emit('onFilter', this.form)
    },
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.initiatives-events-filter';
$gap: 32px;

#{$b} {
  display: grid;
  grid-template-columns: repeat(2, 400px);
  gap: $gap;

  @include low-desktop {
    grid-template-columns: repeat(2, calc((100% - $gap) / 2));
  }

  @include mobile {
    display: block;
  }

  // .initiatives-events-filter__item
  &__item {
    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  &__label {
    font-family: $font-family-inter;
    font-size: 12px;
    line-height: 1.3;
    text-transform: initial;
    margin-bottom: 8px;
    margin-left: 8px;
    font-weight: normal;
  }
}
</style>
