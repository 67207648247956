<template>
  <div class="u-gallery-slider">
    <div class="u-gallery-slider__body">
      <swiper
        v-if="isUpdatedSwiper"
        ref="gallerySwiper"
        class="u-gallery-slider__swiper"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(item,i) in initialItems"
          :key="`u-gallery-slider__swiper-slide-${i}`"
          class="u-gallery-slider__swiper-slide"
        >
          <div
            class="u-gallery-slider__item"
            :class="{'u-gallery-slider__item--is-video':isVideo(item)}"
            :style="setBackground(item)"
            @click="onOpenLigthBox(i)"
          >
            <div v-if="isVideo(item)" class="u-gallery-slider__video">
              <div class="u-gallery-slider__video-play">
                <inline-svg src="play-btn-2"></inline-svg>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="u-gallery-slider__footer">
      <div class="u-gallery-slider__swiper-controls">
        <div class="u-swiper-controls">
          <button
            ref="btnPrev"
            class="u-swiper-controls__btn u-swiper-controls__btn--prev">
          </button>
          <div
            ref="paginationFraction"
            class="u-swiper-controls__count"
          >
          </div>
          <button
            ref="btnNext"
            class="u-swiper-controls__btn u-swiper-controls__btn--next">
          </button>
        </div>
      </div>
      <div
        ref="paginationMobile"
        class="u-swiper-pagination u-gallery-slider__swiper-pagination">
      </div>
    </div>
  </div>
</template>

<script>
import mixinGallerySlider from "@/mixins/mixinGallerySlider";

export default {
  mixins: [mixinGallerySlider],
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

.u-gallery-slider {

  // .u-gallery-slider__body
  &__body {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .u-gallery-slider__item
  &__item {
    height: 400px;
    background-color: $white-true;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;

    // .u-gallery-slider__item--is-video
    &--is-video {
      background-color: rgba($color-base-origin, 0.1);
      background-size: contain;
    }

    @include mobile {
      height: 298px;
    }
  }

  // .u-gallery-slider__video
  &__video {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    .object-fit-video {
      overflow: hidden;
      border-radius: 16px;
    }
  }

  // .u-gallery-slider__video-play
  &__video-play {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $white-true;
    color: $color-base;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    @include mobile {
      width: 50px;
      height: 50px;
      border-radius: 50px;
    }

    &._played {
      opacity: 0;
      visibility: hidden;
    }

    .inline-svg {
      flex-shrink: 0;
      width: 11px;
      height: 15px;
      margin-left: 4px;

      @include mobile {
        width: 9.17px;
        height: 12.5px;
        margin-left: 2px;
      }
    }
  }

  // .u-gallery-slider__footer
  &__footer {
    @include mobile {
      display: flex;
      justify-content: center;
    }
  }

  // .u-gallery-slider__swiper-pagination
  &__swiper-pagination {
    @include mobile-min {
      display: none;
    }
  }

  // .u-gallery-slider__swiper-controls
  &__swiper-controls {
    @include mobile {
      display: none;
    }
  }
}
</style>
