<template>
  <BannerWrapper class="u-calendar-banner">
    <div class="u-inner-header u-calendar-banner__inner-header">
      <div class="u-calendar-banner__info">
        <div class="u-calendar-banner__info-main">
          <div class="u-calendar-banner__info-top">
            <a :href="hrefWithRegion('/')" class="u-inner-header__back tt-uppercase">
              <InlineSvg src="arr-left" />
              <span>назад</span>
            </a>
            <time v-if="isDetail" class="u-calendar-banner__info-date tt-uppercase">
              СЕГОДНЯ
            </time>
          </div>
          <h1 class="u-inner-header__title u-calendar-banner__title" v-html="title" />
          <p v-if="Boolean(text)" class="u-calendar-banner__description" v-html="text" />
          <div class="u-calendar-banner__actions">
            <CustomButton
              tag="a"
              :href="hrefWithRegion(detailUrl)"
              class="u-calendar-banner__show-more"
              theme="primary"
              size="md"
            >
              <span class="u-calendar-banner__show-more-desktop">Подробнее</span>
              <span class="u-calendar-banner__show-more-mobile">Узнать больше</span>
            </CustomButton>
          </div>
        </div>
        <img
          class="u-calendar-banner__logo-card"
          src="/frontend/assets/img/science/event-icon-1.png"
        />
      </div>
      <div class="u-calendar-banner__calendar">
        <UCalendar
          :prop-day="selectedDay"
          :subtitle="monthTheme"
          :event-days="eventDays"
          :is-show-weekdays="true"
          is-wait-change
          :into-banner="true"
          @change-month="onChangeMonth"
          @setdate="onSetDate"
        />
      </div>
    </div>
  </BannerWrapper>
</template>

<script>
import EventPickerMixin from '@/mixins/EventPickerMixin.js'

export default {
  mixins: [EventPickerMixin],
  name: "UCalendarBanner",
};
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

.u-calendar-banner {
  font-family: $font-family-inter;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 700px;
    height: 315px;
    background-image: url('/frontend/assets/img/bg-shapes/banner/dark-circle-left-opacity.svg');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    pointer-events: none;
    touch-action: none;
  }

  .u-inner-header__back {
    margin: 0;
  }
  .banner-wrapper__banner {
    min-height: auto;
  }

  // .u-calendar-banner__inner-header
  &__inner-header {
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto 417px;
    gap: 102px;
    padding-bottom: 64px;

    @include tablet {
      display: block;
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      gap: 0;
      justify-content: unset;
    }
  }

  // .u-calendar-banner__info
  &__info {
    @include tablet {
      margin-bottom: 48px;
    }

    @include mobile {
      min-height: 343px;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      margin: 0;
      order: 1;
      border-radius: 16px;
      overflow: hidden;
      background-color: $color-base-origin;
      background-image: url('/frontend/assets/img/science/bg-card.svg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  // .u-calendar-banner__info-main
  &__info-main {
    height: 100%;
    display: flex;
    flex-direction: column;

    @include mobile {
      height: auto;
    }
  }

   // .u-calendar-banner__info-top
  &__info-top {
    display: flex;
    align-items: center;
    margin-bottom: 64px;

    @include mobile {
      display: none;
    }
  }

  // .u-calendar-banner__logo-card
  &__logo-card {
    @include mobile-min {
      display: none;
    }

    width: 81px;
    height: auto;
    align-self: flex-end;
    margin-left: 16px;
    margin-right: -7px;
  }

  // .u-calendar-banner__info-date
  &__info-date {
    margin-left: 64px;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.1em;
  }

  // .u-calendar-banner__title
  &__title {
    line-height: 100%;

    @include mobile {
      font-size: 32px;
      line-height: 100%;
      word-spacing: 100vw;
      margin-bottom: 14px;
    }
  }

  // .u-calendar-banner__description
  &__description {
    margin: 10px 0 ;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;

    @include mobile {
      margin: auto 0 14px 0;
      font-size: 16px;
      line-height: 130%;
    }
  }

  // .u-calendar-banner__actions
  &__actions {

    @include mobile-min {
      margin-top: auto;
    }

    @include tablet-only {
      margin-top: 20px;
    }
  }

  // .u-calendar-banner__show-more
  &__show-more {

    @include mobile {
      height: 30px;
      border-color: $white-true;
      color: $white-true !important;
      background-color: transparent;
      padding: 5px 13px;
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
      &:hover,
      &:active {
        border-color: $white-true;
        color: $white-true;
        background-color: transparent;
      }
      &:visited,
      &:link {
        color: $white-true !important;
        color: $white-true !important;
        background-color: transparent;
        * {
        color: $white-true !important;
          color: $white-true !important;
          background-color: transparent;
        }
      }
      * {
        color: $white-true !important;
        color: $white-true !important;
        background-color: transparent;
      }
    }

    // .u-calendar-banner__show-more-desktop
    &-desktop {
      @include mobile {
        display: none;
      }
    }
    // .u-calendar-banner__show-more-mobile
    &-mobile {
      @include mobile-min {
        display: none;
      }
    }
  }

  // .u-calendar-banner__calendar
  &__calendar {
    @include mobile-min {
      max-width: 448px;
    }

    @include tablet {
      margin: 0 auto;
    }

    @include mobile {
      width: 100%;
      margin-bottom: 24px;
      order: 0;
    }
  }
}

</style>
