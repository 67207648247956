<template>
  <div
    class="burger-menu-button"
    :class="{ 'burger-menu-button--is-opened': IS_OPENED_MENU }"
  >
    <div class="burger-menu-button__icon" @click.prevent="toggleMenu(!IS_OPENED_MENU)">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'BurgerMenuButton',

  computed: {
    ...mapGetters('header', ['IS_OPENED_MENU']),
  },

  methods: {
    ...mapMutations('header', ['SET_IS_OPENED_MENU']),
    toggleMenu(state) {
      this.SET_IS_OPENED_MENU(state);
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: ".burger-menu-button";

#{$b} {
  cursor: pointer;

  @include tablet {
    display: inline-flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
    user-select: none;
  }

  @include mobile {
    position: absolute;
    right: 0;
    top: 6px;
    z-index: 100;
  }

  #{$b}--showed & {
    z-index: 110;
  }

  &__text {
    position: relative;
    transition: .3s ease-in-out;

    @include mobile {
      display: none;
    }

    &:not(:last-child) {
      margin-right: 15px;

      @include tablet {
        margin-right: 10px;
      }
    }
  }

  &__icon {
    @include boxsize(34px);
    position: relative;
    transform: rotate(0deg);
    transition: .3s ease-in-out;
    background-color: $white-true;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include hover {
      box-shadow: 0px 0px 5px 3px rgba($color-dark, 0.75);
    }

    #{$b}--is-opened & {
      box-shadow: 0px 0px 12px 2px rgba($color-dark, 0.5);
    }

    @include mobile-sm {
      width: 32px;
      height: 32px;
    }

    span {
      position: absolute;
      display: block;
      left: 10px;
      height: 2px;
      background-color: $black-true;
      z-index: 2;
      width: 14px;
      transition: all .3s ease-in-out;

      &:nth-child(1) {
        top: 10px;

        #{$b}--is-opened & {
          width: 16px;
          transform: rotate(45deg) translate(4px, 5px)
        }
      }

      &:nth-child(2) {
        top: 16px;

        #{$b}--is-opened & {
          opacity: 0;
        }
      }

      &:nth-child(3) {
        top: 22px;

        #{$b}--is-opened & {
          width: 16px;
          transform: rotate(-45deg) translate(3px, -5px)
        }
      }
    }
  }

  &__close {
    display: none;
    align-items: center;
    cursor: pointer;

    p {
      margin: 0;
    }

    i {
      display: flex;
    }
  }

  &--showed {
    .burger-menu-button__icon {
      display: none;
    }

    .burger-menu-button__close {
      display: flex;
    }
  }
}

</style>
