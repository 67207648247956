<template>
  <div
    class="editing-card"
    :class="editingCardClass"
    @mouseenter="showCard()"
    @click.self="showCard()"
    @mouseover.self="showCard()"
    @mouseleave="hideCard()"
  >

    <div v-if="!isVisibleConfirm" class="editing-card__inner editing-card__over">
      <div v-if="isEdit" class="editing-card__edit editing-card__action-wrap">
        <div class="editing-card__action" @click="edit()">
          <div class="editing-card__icon">
            <inline-svg class="editing-card__icon-svg" src="pencil"></inline-svg>
          </div>
          <div class="editing-card__text tt-uppercase">
            редактировать
          </div>
        </div>
      </div>
      <div v-if="isRemove" class="editing-card__delete editing-card__action-wrap">
        <div class="editing-card__action" @click="showConfigmation()">
          <div class="editing-card__icon">
            <inline-svg class="editing-card__icon-svg" src="close"></inline-svg>
          </div>
          <div class="editing-card__text tt-uppercase">
            Удалить
          </div>
        </div>
      </div>
    </div>
    <div v-if="isVisibleConfirm" class="editing-card__confirmation editing-card__over">
      <div class="editing-card__confirmation-title">
        Подтвердите удаление
      </div>
      <div class="editing-card__confirmation-actions">
        <CustomButton
          class="editing-card__confirmation-btn"
          :size="size"
          @click="remove()"
        >
          Удалить
        </CustomButton>
        <CustomButton
          class="editing-card__confirmation-btn"
          :size="size"
          @click="cancelRemove()"
        >
          Отменить
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isRemove: {
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'md' // md || sm
    },
    gap: {
      type: String,
      default: 'md' // md || sm
    }
  },
  data() {
    return {
      isVisibleConfirm: false,
      isVisibleCard: false
    }
  },
  computed: {
    editingCardClass() {
      const classes = []
      const prefix = 'editing-card--'

      classes.push(`${prefix}${this.size}`)
      classes.push(`${prefix}gap-${this.gap}`)

      if (this.isVisibleCard) {
        classes.push('editing-card--is-visible')
      }

      return classes.join(' ')
    }
  },
  methods: {
    edit() {
      this.$emit('edit')
      this.hideCard()
    },
    remove() {
      this.$emit('remove')
      this.hideCard()
    },
    showConfigmation() {
      this.isVisibleConfirm = true
    },
    hideConfigmation() {
      this.isVisibleConfirm = false
    },
    cancelRemove() {
      this.hideCard()
    },
    showCard() {
      this.isVisibleCard = true
    },
    hideCard() {
      this.isVisibleCard = false
      this.hideConfigmation()
    }
  }
}
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: '.editing-card';

#{$b} {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $white-true;
  border-radius: inherit;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  transition: $transtion-default;

  &::before {
    content: '';
    background-color: $color-base-origin;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
    transition: $transtion-default;
    z-index: 1;
    pointer-events: none;
    touch-action: none;

    @include mobile {
      background-color: rgba($black-true, 0.8);
    }
  }

  &:not(#{$b}--is-visible) {
    opacity: 0;

    #{$b}__over {
      opacity: 0;
      transition: unset;
    }

    &::before {
      opacity: 0;
      transform: scale(0.8);
    }
    * {
      pointer-events: none;
      touch-action: none;
    }
  }

  // .editing-card__over
  &__over {
    position: relative;
    z-index: 2;
    transition: $transtion-default;
  }

  // .editing-card__inner
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  // .editing-card__action-wrap
  &__action-wrap {
    width: 201px;
    padding: 32px 0;

    #{$b}--gap-sm & {
      padding: 16px 0;
    }
  }

  // .editing-card__action
  &__action {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      @include mobile-min {
        #{$b}__icon {
          background-color: $white-true;
          color: $black-true;
        }
      }
    }
  }

  // .editing-card__icon
  &__icon {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 16px;
    border: 2px solid $white-true;
    color: $white-true;
    transition: $transtion-default;

    @include mobile {
      background-color: $white-true;
      color: $black-true;
    }

    // .editing-card__icon-svg
    &-svg {
      width: 12px;
      height: 12px;

      path {

        #{$b}__edit & {
          fill: transparent !important;
          stroke: currentColor !important;
        }
      }
    }
  }

  // .editing-card__text
  &__text {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3;
    transition: $transtion-default;
  }

  // .editing-card__confirmation
  &__confirmation {

    // .editing-card__confirmation-title
    &-title {
      font-size: 18px;
    }

    // .editing-card__confirmation-actions
    &-actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 10px;
    }

    // .editing-card__confirmation-btn
    &-btn {
      margin: 4px;
    }
  }

}
</style>
