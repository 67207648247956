<template>
  <a
    class="u-news-card"
    :class="newsCardClassStr"
    :href="cardData.target !== '_blank' ? hrefWithRegion(cardData.url) : cardData.url"
    :target="cardData.target"
  >
    <div class="u-news-card__inner">
      <div
        v-if="Boolean(cardData.image)"
        class="u-news-card__preview"
      >
        <img
          :src="cardData.image"
          :alt="cardData.title"
          class="u-news-card__image"
        >
      </div>
      <div class="u-news-card__main">
        <time
          v-if="!cardData.hideDate"
          class="u-news-card__date"
          :class="{ 'u-news-card__date--over-image': Boolean(cardData.image) }"
        >
          {{ cardData.date }}
        </time>
        <div class="u-news-card__info">
          <h3
            class="u-news-card__title title-md tt-uppercase"
            v-html="cardData.title"
          />
          <p
            v-if="cardData.text"
            class="u-news-card__description"
            v-html="cardData.text"
          />
          <CustomButton class="u-news-card__learn-more">
            Узнать больше
          </CustomButton>
        </div>
      </div>
    </div>
  </a>
</template>

<script>

export default {
  props: {
    size: {
      type: String,
      default: 'default' // default | large
    },
    cardData: {
      type: Object,
      require: true
    }
  },

  computed: {
    newsCardClassStr() {
      const prefix = 'u-news-card--'
      const classes = []

      classes.push(this.size)

      if (this.cardData && this.cardData.isSmall) {
        classes.push('small-mob')
      }

      return classes.map(item => prefix + item).join(' ')
    }
  },
}
</script>
