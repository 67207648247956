<template>
  <div class="talisman-info">
    <div class="u-g-container">
      <div class="talisman-info__inner">
        <UFigures
          :items="figures"
          :fullWidth="true"
          :isAnimateImg="false"
          position="static"
        />
        <div class="talisman-info__items">
          <div
            v-for="(item, i) in items"
            :key="`talisman-info__item-${i}`"
            class="talisman-info__item"
          >
            <div class="talisman-info__item-name" v-html="item.name" />
            <div class="talisman-info__item-text tt-uppercase" v-html="item.text" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        {
          name: '1494',
          text: 'идеи талисмана <br> поступило <br> на конкурс',
        },
        {
          name: '82',
          text: 'региона россии <br> приняло участие <br> в конкурсе',
        },
        {
          name: '520',
          text: 'студентов <br> предложили <br> свои идеи',
        },
        {
          name: '500',
          text: 'школьников <br> приняли участие <br> в конкурсе',
        },
      ]
    }
  },
  data() {
    return {
      figures: [
        {
          name: 'f-talisman-timelaps',
          image: '/frontend/assets/img/talisman/lines-timelapse.svg',
          animation: 'fade-in-left'
        }
      ]
    }
  }
}
</script>


<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.talisman-info';

#{$b} {

  // .talisman-info__inner
  &__inner {
    position: relative;
    overflow: hidden;
    padding: 70px;
    border-radius: 16px;
    background-color: $color-base;
    color: $white-true;

    @include tablet {
      padding: 40px 20px;
    }

    @include mobile {
      padding: 20px;
    }
  }

  // .talisman-info__items
  &__items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      display: block;
    }
  }

  // .talisman-info__item
  &__item {
    position: relative;
    z-index: 1;

    @include mobile {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    @include mobile {
      br {
        display: none;
      }
    }

    // .talisman-info__item-name
    &-name {
      font-family: $font-family-main-head;
      font-size: 90px;
      line-height: 1;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 8px;

      @include tablet {
        font-size: 60px;
      }
      @include mobile {
        font-size: 36px;
      }
    }
  }
}
</style>
