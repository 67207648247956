<template>
  <form class="pass-change-form">
    <template v-if="!formSent">
      <div class="pass-change-form__field">
        <u-text-input
          class="u-text-input--smaller"
          name="email"
          label="E-mail"
          placeholder="E-mail"
          v-model="form.email"
          is-read-only
          :error="$v.form.email.$error"
        />
        <ErrorFormWrapper :isVisible="$v.form.email.$error && (!$v.form.email.required || !$v.form.email.email)">
          <div v-show="$v.form.email.$error && !$v.form.email.required">Обязательное поле</div>
          <div v-show="$v.form.email.$error && !$v.form.email.email">Некорректный e-mail</div>
        </ErrorFormWrapper>
      </div>
      <div v-if="isAuthorized" class="pass-change-form__field">
        <u-text-input
          class="u-text-input--smaller"
          name="password"
          label="Старый пароль"
          placeholder="Старый пароль"
          v-model="form.oldPassword"
          input-type="password"
          :error="$v.form.oldPassword.$error"
          isPassword
        />
        <ErrorFormWrapper :isVisible="$v.form.oldPassword.$error && !$v.form.oldPassword.required">
          <div>Обязательное поле</div>
        </ErrorFormWrapper>
      </div>
      <div class="pass-change-form__field">
        <u-text-input
          class="u-text-input--smaller"
          name="password"
          label="Новый пароль"
          placeholder="Новый пароль"
          v-model="form.password"
          input-type="password"
          :error="$v.form.password.$error"
          isPassword
        />
        <ErrorFormWrapper :isVisible="$v.form.password.$error && (!$v.form.password.required || !$v.form.password.minLength)">
          <div v-show="$v.form.password.$error && !$v.form.password.required" >
            Обязательное поле
          </div>
          <div v-show="$v.form.password.$error && !$v.form.password.minLength" >
            Должен содержать не менее 8 символов, цифры, заглавные буквы, только латиницу и спецсимволы.
          </div>
        </ErrorFormWrapper>
      </div>
      <div class="pass-change-form__field">
        <u-text-input
          class="u-text-input--smaller"
          name="confirm-password"
          label="Повторите новый пароль"
          placeholder="Повторите новый пароль"
          v-model="form.confirmPassword"
          input-type="password"
          :error="$v.form.confirmPassword.$error"
          isPassword
        />
        <ErrorFormWrapper :isVisible="$v.form.confirmPassword.$error && (!$v.form.confirmPassword.required || !$v.form.confirmPassword.sameAsPassword)">
          <div v-show="$v.form.confirmPassword.$error && !$v.form.confirmPassword.required" >
            Обязательное поле
          </div>
          <div v-show="$v.form.confirmPassword.$error && !$v.form.confirmPassword.sameAsPassword && $v.form.confirmPassword.required" >
            Пароли не совпадают
          </div>
        </ErrorFormWrapper>
      </div>
      <div class="pass-change-form__field">
        <ErrorFormWrapper :isVisible="true">
          <div class="_color-succes">
            Пароль должен содержать от 8 символов, цифры, заглавные буквы, только латиницу и спецсимволы.
          </div>
        </ErrorFormWrapper>
      </div>
      <ErrorFormWrapper
        class="pass-change-form__errors-after-submit"
        :isVisible="Boolean(errorsAfterSubmit.length)"
        :maxHeight="1000"
      >
        <div v-for="error in errorsAfterSubmit" :key="`error-after-submit-${error.code}`">
          <span v-html="error.message"/>
        </div>
      </ErrorFormWrapper>
      <div class="pass-change-form__bottom">
        <CustomButton
          class="ppass-change-form__иет"
          theme="primary"
          size="md"
          type="button"
          @click.prevent="submitForm"
        >
          Сменить пароль
        </CustomButton>
      </div>
    </template>
    <template v-else>
      <div class="pass-change-form__success">
        Вы успешно изменили пароль!
      </div>
    </template>
  </form>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import requiredIf from "vuelidate/lib/validators/requiredIf";

export default {
  name: "PassChangeForm",
  mixins: [validationMixin],
  props: {
    action: {
      type: String,
      default: ""
    },
    isAuthorized: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      required
    },
    checkword: {
      type: String,
      required
    }
  },
  data() {
    return {
      formSent: false,
      form: {
        email: this.email,
        checkword: this.checkword,
        oldPassword: "",
        password: "",
        confirmPassword: "",
      },
      errorsAfterSubmit: []
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      checkword: {
        required
      },
      oldPassword: {
        required: requiredIf(function() {
          return this.isAuthorized;
        })
      },
      password: {
        required,
        minLength: minLength(8),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      },
    }
  },
  methods: {
    async submitForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const response = await axios.post(
          '/bitrix/services/main/ajax.php?mode=class&c=dalee:auth&action=changePassword',
          this.form
        );

        if (response.data.status === 'error') {
          this.errorsAfterSubmit = response.data.errors
        } else {
          this.errorsAfterSubmit = []
          this.formSent = true;
          this.reset();
          window.location.href = this.hrefWithRegion('/lk/');
        }
      }
    },
    reset() {
      this.$v.$reset();
      this.form.email = "";
      this.form.checkword = "";
      this.form.password = "";
      this.form.confirmPassword = "";
    },
    changeForm(name) {
      this.$emit("onChangeForm", name);
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".pass-change-form";

#{$b} {

  &__field {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 31px;
    }
  }

  &__bottom {
    display: block;
    text-align: center;

    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  &__field {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  &__success {
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 1.6;

    @include mobile {
      font-size: 14px;
    }
  }
}
</style>
