<template>
  <div class="media-loader" :class="{ 'media-loader--is-error': error }">
    <div class="media-loader__wrapper">
      <div class="media-loader__inner">
        <input
          class="media-loader__input"
          type="file"
          :ref="`mediaLoader-${id}`"
          :id="id"
          :accept="validFormatStr"
          :multiple="isMultiple"
          @input="onInput"
        >
        <div class="media-loader__list">
          <label v-if="!maxIsLoaded" class="media-loader__card" :for="id">
            <div class="media-loader__card-inner media-loader__choise">
              <span class="media-loader__choise-icon" />
              Добавить
            </div>
          </label>
          <div
            v-for="(file, i) in previewsList"
            :key="`media-loader__file-${i}`"
            class="media-loader__card"
            :class="{'media-loader__card--is-confirm': currentIndexFile === i}"
          >
            <div class="media-loader__card-inner media-loader__file">
              <img v-show="file" :src="file" class="media-loader__file-preview">
              <div v-show="!file" class="media-loader__placeholder">
                <InlineSvg
                  class="media-loader__placeholder-bg"
                  src="border-dashed-rounded"
                />
                <InlineSvg
                  class="media-loader__placeholder-icon"
                  src="loading-no-animate"
                />
              </div>
              <div class="media-loader__file-remove" @click="removeConfirmation(i)">
                <InlineSvg
                  class="media-loader__remove-icon"
                  src="close-thin"
                />
              </div>
            </div>
            <div class="media-loader__info">
              <div v-if="getFileName(files[i])" class="media-loader__info-name">
                {{ getFileName(files[i]) }}
              </div>
              <div v-if="getFileSizeStr(files[i])" class="media-loader__info-size">
                {{ getFileSizeStr(files[i]) }}
              </div>
            </div>
            <ConfirmationWindow
              :isVisible="currentIndexFile === i"
              :initPosition="confirmationPosition"
              @success="onRemove()"
              @cancel="currentIndexFile = null"
            >
              Вы действительно хотите удалить
              этот файл?
            </ConfirmationWindow>
          </div>
        </div>
      </div>
      <ErrorFormWrapper :isVisible="isExistsError" :maxHeight="200">
        <div :class="{ 'media-loader__errors': isExistsError }">
          <div v-show="errorsFile.size">
            <div v-if="maxSize !== Infinity">Максимальный размер файла: {{ maxSize }}МБ</div>
            <div v-if="maxSizeVideo !== Infinity">Максимальный размер видеофайла: {{ maxSizeVideo }}МБ</div>
            <div>Файл не должен быть пустым</div>
          </div>
          <div v-show="errorsFile.format">Файл должен быть в формате: {{ validFormatStr }}</div>
          <div v-show="errorsFile.limit">Максимальное количество файлов: {{ maxCount }}</div>
        </div>
      </ErrorFormWrapper>
    </div>
  </div>
</template>

<script>
import { randomString } from '@/components/helpers/randomString.js'

export default {
  props: {
    maxSize: {
      type: Number,
      default: Infinity // в МБ // 20
    },
    maxSizeVideo: {
      type: Number,
      default: Infinity // в МБ // 20
    },
    validFormat: {
      type: Array,
      default: () => [] // Array<string> // [png, doc]
    },
    error: {
      type: Boolean,
      default: false
    },
    maxCount: {
      type: Number,
      default: Infinity // макс кол-во файлов
    },
    initialFiles: {
      type: [Array, File, Object],
      default: () => []
    },
  },
  data() {
    return {
      id: null,
      errorsFile: {
        size: false,
        format: false,
        limit: false
      },
      callStack: {},
      val: '',
      files: [],
      previewsList: [],
      currentIndexFile: null,
      maxLengthFileName: 9,
      confirmationPosition: {
        desktop: {
          x: 'left',
          y: 'top',
        },
        mobile: {
          x: 'center',
          y: 'top',
        }
      },
      placeholders: {
        pdf: require('@/assets/icons/pdf-icon.svg'),
        video: require('@/assets/img/placeholder-video.svg'),
        otherType: require('@/assets/icons/other-placeholder.svg'),
      }
    }
  },
  computed: {
    validFormatStr() {
      return this.validFormat
        .map(item => {
          return item.trim().startsWith('.') ?  item : '.' + item
        })
        .join(', ')
    },
    maxIsLoaded() {
      return this.files.length === this.maxCount
    },
    isExistsError() {
      return this.errorsFile.size || this.errorsFile.format || this.errorsFile.limit
    },
    isMultiple() {
      return this.maxCount > 1
    }
  },
  methods: {
    isFileObject(file) {
      return file instanceof Object && !(file instanceof File) && (file.name && file.size)
    },
    getFileName(file) {
      let name = file.name
      if (!name) return

      const indexBeforeExtension = name.lastIndexOf('.')
      const extension = name.substring(indexBeforeExtension + 1)
      const index = Math.min(indexBeforeExtension, this.maxLengthFileName - extension.length)

      if (name.length > this.maxLengthFileName + 1) {
        name = `${file.name.substring(0, index)}...${extension}`
      }

      return name
    },
    getFileSize(file) {
      return Math.round((file.size / 1024 / 1024) * 10) / 10
    },
    getFileSizeStr(file) {
      if (!file.size) return

      const sizeMb = this.getFileSize(file)
      return sizeMb >= 0 && sizeMb < 1 ? `${Math.round(file.size / 1024)} Кб` : `${sizeMb} Мб`
    },
    isValidMaxSize(file) {
      let maxSize = Infinity

      if (this.isVideo(file)) {
        maxSize = this.maxSizeVideo
      } else {
        maxSize = this.maxSize
      }

      return maxSize !== Infinity && (this.getFileSize(file) > maxSize)
    },
    isValidFile(file) {
      if (!(file instanceof File) && !this.isFileObject(file)) {
        return false
      } else if (this.isFileObject(file)) {
        return true
      }

      let isValid = true

      if (this.isValidMaxSize(file) || file.size <= 0) {
        this.showErrorByKey('size')
        isValid = false
      }

      if (this.validFormat.length >= 1) {
        const index = file.name.lastIndexOf('.');
        const fileExtansion = file.name.substring(index + 1)
        const isMatch = this.validFormat.find(item => item === fileExtansion)

        if (!isMatch) {
          this.showErrorByKey('format')
          isValid = false
        }
      }

      return isValid
    },
    isVideo(file) {
      return file instanceof File && file.type.match('video.*')
    },
    getPlaceholderForFile(file) {
      let fileParams = {
        name: '',
        type: '', // string , File, fileObject
      }

      if (file instanceof File) {
        fileParams.name = file.name
        fileParams.type = 'File'
      } else if (this.isFileObject(file)) {
        fileParams.name = file.name
        fileParams.type = 'fileObject'
      } else if (typeof file === 'string') {
        fileParams.name = file
        fileParams.type = 'string'
      }

      const splitName = fileParams.name.split('.')
      const extension = splitName[splitName.length-1]

      if (Object.hasOwn(this.placeholders, extension)) {
        return this.placeholders[extension]
      } else if (fileParams.type === 'File' && file.type.match('video.*') ) {
        return this.placeholders.video
      } else {
        return this.placeholders.otherType
      }
    },
    createPreviewsList(files) {
      this.previewsList = files.map((file, i) => {
        if (typeof file === 'string' && file) {
          return file
        }
        if (this.isFileObject(file) && file?.img) {
          return file.img
        }
        if (file instanceof File && file.type.match('image.*') && !file.type.endsWith('tiff')) {
          this.previewFromFileReader(file, i)
          return null
        }
        return this.getPlaceholderForFile(file)
      })
    },
    previewFromFileReader(file, index) {
      if (file.type.match('image.*')) {
        const reader = new FileReader();

        reader.addEventListener('load', (e) => {
          const leftList = this.previewsList.slice(0, index)
          const rightList = this.previewsList.slice(index + 1)

          this.previewsList = [...leftList, e.target.result, ...rightList]
        })

        reader.readAsDataURL(file);
      }
    },
    handleFileLoader(files) {
      files.forEach((file) => {
        if (this.files.length === this.maxCount ) {
          this.showErrorByKey('limit')
          return
        }

        const isValid = this.isValidFile(file) || (typeof file === 'string' && file) || this.isFileObject(file)

        if (isValid) {
          this.files.push(file)
        }
      })

    },
    filesInit(data) {
      let files = data
      if (!Array.isArray(data)) {
        files = [data]
      }
      this.handleFileLoader(files)
      this.createPreviewsList(this.files)
    },
    onInput(e) {
      if (!(e.target instanceof HTMLInputElement)) {
        return
      }

      const files = e.target.files;

      if (!files || !files.length) {
        throw new Error('Файлы отсутствуют в input[type="file"]');
      }

      this.filesInit(Array.from(files))
      this.$emit('change', this.files);
      this.removeInputValue()
    },
    removeConfirmation(i) {
      this.currentIndexFile = i
    },
    onRemove() {
      this.files = this.files.filter((_,i) => i !== this.currentIndexFile)
      this.previewsList = this.previewsList.filter((_,i) => i !== this.currentIndexFile)
      this.$emit('change', this.files);
      this.currentIndexFile = null
    },
    showErrorByKey(key) {
      this.errorsFile[key] = true

      // запуск отложенного удаления ошибки валидации
      this.delayedRemove(key)
    },
    removeErrorByKey(key) {
      this.cancelDelayedRemove(key)
      this.errorsFile[key] = false
    },
    // отложеное удаление ошибки валидации
    delayedRemove(key) {
      this.callStack[key] = setTimeout(this.removeErrorByKey.bind(this, key), 8000);
      this.removeInputValue()
    },
    // отмена отложенного удаления ошибки валидации
    cancelDelayedRemove(key) {
      clearTimeout(this.callStack[key])
    },
    removeInputValue() {
      if (this.$refs[`mediaLoader-${this.id}`]) {
        this.$refs[`mediaLoader-${this.id}`].value = ''
      }
    },
    reInit() {
      this.files = []
      this.filesInit(this.initialFiles)
    }
  },
  created() {
    this.id = randomString()
    this.filesInit(this.initialFiles)
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.media-loader';
$list-gap: 4px;
$size-card: 80px;
$size-icon: 32px;

#{$b} {
  font-family: $font-family-inter;
  font-size: 12px;
  line-height: 1;
  font-weight: normal;
  color: $color-base-origin;
  transition: $transtion-default;

  @include mobile-min {
    display: inline-block;
  }

  &--is-error {
    color: $color-danger;
  }

  // .media-loader__list
  &__list {
    display: inline-flex;
    flex-wrap: wrap;
    margin: -($list-gap);

    @include mobile {
      position: relative;
      display: flex;
    }
  }

  // .media-loader__card
  &__card {
    padding: $list-gap;
    user-select: none;

    // .media-loader__card-inner
    &-inner {
      width: $size-card;
      height: $size-card;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      border-radius: 16px;
      overflow: hidden;
      cursor: pointer;
      transition: $transtion-default;
    }
  }

  // .media-loader__choise
  &__choise {
    border: 1px dashed $color-base-origin;
    transition: $transtion-default;


    #{$b}--is-error & {
      border-color: $color-error;
    }

    &:hover {
      @include mobile-min {
        background-color: $color-base-origin;
        border-style: solid;
        border-color: $color-base-origin;
        color: $white-true;

        #{$b}__choise-icon {
          border-color: $white-true;

          &::before,
          &::after {
            background-color: $white-true;
          }
        }
      }
    }

    // .media-loader__choise-icon
    &-icon {
      width: $size-icon;
      height: $size-icon;
      border-radius: 50%;
      position: relative;
      border: 1px solid $color-base-origin;
      transition: $transtion-default;
      margin-bottom: 8px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: $color-base-origin;
        transition: $transtion-default;

        #{$b}--is-error & {
          background-color: $color-error;
        }
      }

      &::before {
        width: calc(100% - 14px);
        height: 1px;
      }
      &::after {
        width: 1px;
        height: calc(100% - 14px);
      }

      #{$b}--is-error & {
        border-color: $color-error;
      }
    }
  }

  // .media-loader__file
  &__file {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background-color: rgba($black-true, 0.5);
      transition: $transtion-default;
      opacity: 0;
    }

    &:hover {
      @include mobile-min {
        &::after,
        #{$b}__file-remove {
          opacity: 1
        }
      }
    }

    #{$b}__card--is-confirm & {
      &::after,
      #{$b}__file-remove {
        opacity: 1
      }
    }

    // .media-loader__file-preview
    &-preview {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    // .media-loader__file-remove
    &-remove {
      position: absolute;
      left: 50%;
      top: 50%;
      width: $size-icon;
      height: $size-icon;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white-true;
      color: $black-true;
      border-radius: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
      transition: $transtion-default;
      opacity: 0;
    }
  }

  // .media-loader__remove-icon
  &__remove-icon {
    width: 12px;
    height: 12px;
  }

  // .media-loader__error
  &__error {
    margin-top: 10px;
  }

  // .media-loader__input
  &__input {
    display: none;
  }

  // .media-loader__errors
  &__errors {
    margin-top: 20px;
  }

  // .media-loader__info
  &__info {
    margin-top: 6px;
    font-size: 12px;
    line-height: 100%;
    font-weight: 400;

    // .media-loader__info-size
    &-size {
      font-weight: 500;
      margin-top: 5px;
    }
  }

  // .media-loader__wrapper
  &__wrapper {
    @include mobile-min {
      display: inline-block;
    }
  }

  // .media-loader__placeholder
  &__placeholder {
    position: relative;
    color: $color-base-origin;

    // .media-loader__placeholder-bg
    &-bg {
      width: 100%;
      height: 100%;

      *  {
        fill: transparent !important;
        stroke: currentColor;
      }
    }

    // .media-loader__placeholder-icon
    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      max-width: 50%;
      max-height: 50%;

      svg {
        animation: spin linear 1s infinite;
      }

      *  {
        fill: transparent !important;
        stroke: currentColor;
      }
    }
  }
}
</style>
