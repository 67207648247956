<template>
  <div class="u-institutions">
    <UFigures
      class="u-institutions__figures"
      :items="figures"
      position="static"
      :fullWidth="true"
    >
      <div class="u-institutions__inner">
        <div class="u-g-header">
          <h2 class="title-xl u-institutions__title">{{ title }}</h2>
          <UCaption class="u-institutions__caption" />
        </div>
        <USelectFilters
          v-if="isExistsFilterOptions"
          class="u-institutions__filter"
          :selectList="getSelectList"
          :selectedOptions="selectedOptions"
          :filterOptions="filterOptions"
          @onChangeSelect="onChangeSelect"
        />
        <div v-if="isExistsPageList" class="u-cards-list">
          <div class="u-cards-list__inner">
            <div class="u-institutions__card-wrapper u-cards-list__card-wrapper"
              v-for="(cardData,i) in pageList"
              :key="`u-institutions__card-${cardData.id}`"
              :class="getCardWrapperWidthClassModifier(i)"
            >
              <UInstitutionCard
                :data="cardData"
                :buttonLabel="buttonLabel"
                :isWide ="getWidthFlag(i)"
                @click="onClick(cardData)"
              />
            </div>
          </div>
        </div>
        <NotFound v-else :text="currentFilterError || 'По данным параметрам ничего не найдено'" />
        <div class="u-institutions__footer">
          <div class="u-institutions__back">
            <UniversitiesBack :href="hrefWithRegion(backUrl)" />
          </div>
          <CustomButton
            v-if="isExistsBtnLoadMore"
            class="u-institutions__show-more"
            size="md"
            theme="primary"
            :status="btnStatus"
            @click="loadMore(false)"
          >
            Показать еще
          </CustomButton>
        </div>
      </div>
    </UFigures>
  </div>
</template>

<script>
import FilterPageMixin from '@/mixins/universities/FilterPageMixin.js'
import PaginationPageMixin from '@/mixins/universities/PaginationPageMixin.js'

export default {
  inject: ['ymHandler'],
  mixins: [FilterPageMixin, PaginationPageMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    buttonLabel: {
      type: String,
      default: ''
    },
    backUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedOptions: {
        knowledgeArea: '',
        direction: '',
        qualification: '',
        region: '',
        specialties: '',
      },
      filterOptionsPreset: {
        knowledgeArea: {id: '', name: 'Все области знания'},
        direction: {id: '', name: 'Все направления'},
        qualification: {id: '', name: 'Все квалификации'},
        region: {id: '', name: 'Все Регионы'},
        specialties: {id: '', name: 'Все Специальности'},
      },
      figures: [
        {
          name: 'f-u-institutions-1',
          image: '/frontend/assets/img/bg-shapes/circle-left-opacity.svg',
          animation: 'fade-in-left'
        },
        {
          name: 'f-u-institutions-2',
          image: '/frontend/assets/img/bg-shapes/circle-left-opacity.svg',
          animation: 'fade-in-left'
        },
      ],
    }
  },
  computed: {
    getUrlForLoadPage() {
      return `/api/rest-component/dalee/vz/getElementsList/?${this.getFilterParamForApi('filter')}`
    },
    getUrlForLoadOptions() {
      return `/api/rest-component/dalee/vz/getFilter/?${this.getFilterParamForApi('preFilter')}`
    },
    isCanChangeSpecialties() {
      return !!this.selectedOptions.qualification && !!this.selectedOptions.knowledgeArea
    },
    getChangeHandlers() {
      return {
        knowledgeArea: this.onChangeKnowledgeArea,
        direction: this.onChangeDirection,
        qualification: this.onChangeQualification,
        region: this.onChangeRegion,
        specialties: this.onChangeSpecialties,
      }
    },
    getSelectList() {
      return [
        {
          label: 'Область знания',
          key: 'knowledgeArea',
        },
        {
          label: 'Направление',
          key: 'direction',
        },
        {
          label: 'Квалификация',
          key: 'qualification',
        },
        {
          label: 'Регион',
          key: 'region',
        },
        {
          label: 'Специальность',
          key: 'specialties',
          isHidden: !this.isCanChangeSpecialties,
        },
      ]
    }
  },
  methods: {
    getWidthFlag(i) {
      return i === 0 || i % 3 === 0
    },
    getCardWrapperWidthClassModifier(i) {
      if (this.getWidthFlag(i)) {
        return 'u-institutions__card-wrapper_wide'
      }
    },
    onChangeKnowledgeArea() {
      this.selectedOptions.direction = ''
      this.selectedOptions.specialties = ''
      this.selectedOptions.qualification = ''
      this.onFilterHelper()
    },
    onChangeDirection(id) {
      this.selectedOptions.specialties = ''
      this.selectedOptions.qualification = ''
      this.setSelectedOption(id, 'direction', 'knowledgeArea')
      this.onFilterHelper()
    },
    onChangeQualification() {
      this.selectedOptions.specialties = ''
      this.onFilterHelper()
    },
    onChangeRegion() {
      this.onFilterHelper()
    },
    onChangeSpecialties(id) {
      this.setSelectedOption(id, 'specialties', 'direction')
      this.onFilterHelper()
    },
    onChangeSelect(id, key) {
      this.getChangeHandlers[key](id)
    },
    onClick(data) {
      this.ymHandler({
        code: 94756882,
        type: 'reachGoal',
        operation: 'click_gotoSite',
        options: {
          'URL_UniversitySite': data.url
        }
      })
    }
  },
};
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-institutions';
$gap: 32px;

#{$b} {
  position: relative;

  &__inner {
    position: relative;
  }

  &__title {
    margin: 0;
  }

  &__filter {
    margin-bottom: 64px;

    @include mobile {
      margin-bottom: 32px;
    }
  }

  &__card-wrapper {
    width: 50%;
    min-height: 246px + 16px;

    @include tablet {
      width: 100%;
    }

    &_wide {
      width: 100%;
    }
  }

  &__title {
    margin: 0;
  }

  &__caption {
    margin-right: auto;
    margin-left: 30px;

    @include tablet {
      margin-left: 16px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 24px;
    }
  }

  &__show-more {
    @include mobile {
      order: -1;
      margin-bottom: 24px;
      width: 100%;
    }
  }
}
</style>
