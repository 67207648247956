<template>
  <div>
    <ul class="events-filter__month-list">
      <li v-for="(item, i) in filters" :key="i">
        <div
          class="events-filter__month-item"
          :class="{ 'is-active': item.isActive }"
          @click.prevent="toggleMonthFilter(item, i)"
        >
          <span>{{ item.name }}</span>
          <inline-svg v-if="item.isActive && i > 0" src="close"/>
        </div>
      </li>
    </ul>
    <div :key="itemsDataKey" class="lk-sociological">
      <template v-for="(item, i) in items">
        <div class="lk-sociological__item" :key="i">
          <div class="lk-sociological__item-inner js-same-height" data-same-height="2-container">
            <div class="lk-sociological__item-count">{{ i <= 8 ? '0'+(i+1) : i+1 }}/</div>
            <div class="lk-sociological__item-question js-same-height" data-same-height="1-title" v-html="item.question"></div>
            <div :class="`_${item.chartType}`" class="lk-sociological__item-charts">
              <template v-if="item.chartType === 'bar'">
                <div v-if="item.values[1]">
                  <div class="lk-sociological__item-charts-label" :style="{color: chartColors[0]}">Текущий месяц, {{selectedMonth}}</div>
                  <bar-chart :data-sets="item.values[1]" :colors="staticColors[0]"></bar-chart>
                </div>
                <div>
                  <div class="lk-sociological__item-charts-label" :style="{color: chartColors[1]}">Усреднённое значение, все мес.</div>
                  <bar-chart :data-sets="item.values[0]" :colors="staticColors[1]"></bar-chart>
                </div>
              </template>
              <template v-else>
                <div v-if="item.values[1]">
                  <div class="lk-sociological__item-charts-label" :style="{color: chartColors[0]}">Текущий месяц, {{selectedMonth}}</div>
                  <donut-chart :data-sets="item.values[1]" :colors="getColorArray(0, item.answers)"></donut-chart>
                </div>
                <div>
                  <div class="lk-sociological__item-charts-label" :style="{color: chartColors[1]}">Усреднённое значение, все мес.</div>
                  <donut-chart :data-sets="item.values[0]" :colors="getColorArray(1, item.answers)"></donut-chart>
                </div>
              </template>
            </div>
            <ul class="lk-sociological__item-legend" :class="{_only: !item.values[1]}">
              <li v-for="(answer, j) in item.answers" :key="j">
                <i v-if="item.values[1]" :style="{backgroundColor: getColor(0, j, item.answers.length)}"></i>
                <i :style="{backgroundColor: getColor(1, j, item.answers.length)}"></i>
                {{answer}}
              </li>
            </ul>
            <div v-if="item.text" class="lk-sociological__item-note" v-html="getNoteText(item.text)"></div>
          </div>
          <div v-if="item.statistic && item.statistic.length" class="lk-sociological__item-other">
            <ul class="lk-sociological__item-other--legend">
              <li v-if="selectedMonth !== 'Все'" :style="{color: initialColors[0] }"><i :style="{backgroundColor: initialColors[0] }"></i>Текущий месяц, {{selectedMonth}}</li>
              <li :style="{color: initialColors[1] }"><i :style="{backgroundColor: initialColors[1] }"></i>Усреднённое значение, все месяцы</li>
            </ul>
            <div class="lk-sociological__item-other--wrapper" v-for="(otherItem, idx) in item.statistic" :key="idx">
              <div class="lk-sociological__item-other--title" v-if="otherItem.title">{{ otherItem.title }}</div>
              <div class="lk-sociological__item-other--list">
                <div v-for="(item, i) in otherItem.items" :key="i" class="lk-sociological__item-other--item">
                  <div v-if="item.monthlyValues" class="lk-sociological__item-other--bar">
                    <div
                      v-for="(value, k) in item.monthlyValues"
                      :key="k"
                      class="lk-sociological__item-other--value"
                      :style="{width: +value+'%', backgroundColor: getColor(0, k, item.values.length) }"
                    >
                      <span>{{value}}%</span>
                    </div>
                  </div>
                  <div class="lk-sociological__item-other--bar">
                    <div
                      v-for="(value, k) in item.values"
                      :key="k"
                      class="lk-sociological__item-other--value"
                      :style="{width: +value+'%', backgroundColor: getColor(1, k, item.values.length) }"
                    >
                      <span>{{value}}%</span>
                    </div>
                  </div>
                  <div class="lk-sociological__item-other--name">{{item.name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="(i % 2 === 1) || (items.length === i + 1)" class="lk-sociological__divider" :key="'div'+i"></div>
      </template>
    </div>
    <div :key="itemsOtherDataKey" v-if="!hideOtherItems" class="lk-sociological-bars">
      <div class="lk-sociological-bars__item" v-for="(item, i) in itemsOther" :key="i">
        <div class="lk-sociological-bars__title">{{item.title}}</div>
        <ul class="lk-sociological-bars__legend">
          <li v-if="item.list[0].values[1]" :style="{color: chartColors[0]}">
            <i :style="{backgroundColor: chartColors[0]}"></i>Текущий месяц, {{selectedMonth}}
          </li>
          <li :style="{color: chartColors[1]}">
            <i :style="{backgroundColor: chartColors[1]}"></i>Усреднённое значение, все мес.
          </li>
        </ul>
        <div class="lk-sociological-bars__row">
          <div
            v-for="(col, j) in item.list"
            :key="j"
            class="lk-sociological-bars__col"
            :class="`lk-sociological-bars__col-${item.list.length}`"
          >
            <div class="lk-sociological-bars__label">{{col.label}}</div>
            <div
              v-for="(bar, k) in col.values"
              :key="k"
              class="lk-sociological-bars__bar"
              :class="`lk-sociological-bars__bar--${k}`"
            >
              <div
                v-for="(value, l) in bar"
                :key="l"
                class="lk-sociological-bars__value"
                :style="{width: value+'%', backgroundColor: col.values.length > 1 ? getColor((col.values.length - (k + 1)), l, bar.length) : getColor(1, l, bar.length)}"
              >
                <span>{{value}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DonutChart from "./DonutChart";
  import axios from "axios";
  import BarChart from "./BarChart";
  export default {
    name: "LkRaportSociological",
    components: {BarChart, DonutChart},
    props: {
      initialMonthFilters: {
        type: Array,
        default: () => [
          { name: "Все", fullName: "Все", filter: "all", isActive: true },
          { name: "Янв", fullName: "Январь", filter: "", isActive: false },
          { name: "Фев", fullName: "Февраль", filter: "", isActive: false },
          { name: "Мар", fullName: "Март", filter: "", isActive: false },
          { name: "Апр", fullName: "Апрель", filter: "", isActive: false },
          { name: "Май", fullName: "Май", filter: "", isActive: false },
          { name: "Июн", fullName: "Июнь", filter: "", isActive: false },
          { name: "Июл", fullName: "Июль", filter: "", isActive: false },
          { name: "Авг", fullName: "Август", filter: "", isActive: false },
          { name: "Сен", fullName: "Сентябрь", filter: "", isActive: false },
          { name: "Окт", fullName: "Октябрь", filter: "", isActive: false },
          { name: "Ноя", fullName: "Ноябрь", filter: "", isActive: false },
          { name: "Дек", fullName: "Декабрь", filter: "", isActive: false },
        ]
      },
      initialColors: {
        type: Array,
        default: () => ['#5893FB', '#203B87']
      },
      initItems:{
        type: Array,
        required: true
      },
      initItemsOther: {
        type: Array,
        default: () => [],
      },
      hideOtherItems: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        filters: [],
        chartColors: this.initialColors,
        selectedMonth: 'Все',
        defaultItems: [],
        items: [],
        itemsDataKey: 0,
        itemsOther: [],
        itemsOtherDataKey: 1000,
        staticColors: [
            ['#5893fb', '#79a9fc', '#9bbefd', '#bcd4fd', '#dee9fe'],
            ['#203B87', '#586ca5', '#8f9dc3', '#d2d8e7', '#d2d8e7'],
        ]
      }
    },
    created() {
      this.defaultItems = this.cloneArray(this.initItems);
      // hardcode bar type
      //this.defaultItems[0].chartType = 'bar';
    },
    mounted() {
      this.filters = this.initialMonthFilters;
      this.items = [...this.initItems];
      if (!this.hideOtherItems) {
        this.itemsOther = this.initItemsOther;
      }
      this.sameHeight();
      window.addEventListener('resize', this.sameHeight);
      this.$root.$on('header-tab-change', this.sameHeight);
    },
    destroyed() {
      window.removeEventListener('resize', this.sameHeight);
    },
    methods: {
      async toggleMonthFilter(el, i) {
        let filterParam;

        if (this.filters[i].isActive === true) {
          this.filters[i].isActive = !this.filters[i].isActive;
          this.filters[0].isActive = true;
          this.selectedMonth = this.filters[0].fullName;
          filterParam = this.filters[0].filter;
        } else {
          this.filters.forEach(item => {
            item.isActive = false;
          });
          this.filters[i].isActive = true;
          this.selectedMonth = this.filters[i].fullName;
          filterParam = this.filters[i].filter;
        }

        if (filterParam === this.filters[0].filter) {
          this.resetValues();
          this.forceViewUpdate();
          this.$nextTick(() => this.sameHeight());

        } else {
          axios.get(`/bitrix/services/main/ajax.php?mode=class&c=dalee:sociological.measurements&action=filter&month=${this.filters[i].filter}`)
            .then((res) => {
              this.resetValues();
              this.setItemValues(res.data.data['init-items']);
              if (!this.hideOtherItems) {
                this.setOtherItemValues(res.data.data['init-items-other']);
              }
              this.forceViewUpdate();
            })
            .catch((e) => {
              console.error(e);
            })
            .finally(() => { this.sameHeight() });
        }
      },
      cloneArray(copiedArray) {
        return JSON.parse(JSON.stringify(copiedArray));
      },
      getNoteText(text) {
        return Array.isArray(text) ? text.join('<br>') : text;
      },
      getColor(colorIndex, itemIndex, itemsLength) {
        if (itemIndex === 0) {
          return this.chartColors[colorIndex]
        } else {
          let alpha = (100 / itemsLength) * (itemsLength - itemIndex) * 0.01;
          return this.hexToRGB(this.chartColors[colorIndex], alpha);
        }
      },
      getColorArray(colorIndex, items) {
        const itemsLength = items.length;
        const vm = this;
        return items.map(function (item, i) {
          let alpha = (100 / itemsLength) * (itemsLength - i) * 0.01;
          return vm.hexToRGB(vm.chartColors[colorIndex], alpha)
        })
      },
      hexToRGB(hex, alpha) {
        var r = parseInt(hex.slice(1, 3), 16),
          g = parseInt(hex.slice(3, 5), 16),
          b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
          return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
          return "rgb(" + r + ", " + g + ", " + b + ")";
        }
      },
      sameHeight() {
        this.$nextTick(function () {
          if (window.matchMedia("(min-width: 768px)").matches) {
            const elements = document.querySelectorAll('.js-same-height');
            let types = {};
            if (elements.length) {
              elements.forEach(function (el) {
                const type = el.dataset.sameHeight;
                if (types[type] === undefined) {
                  types[type] = [];
                }
                types[type].push(el);
              });

              Object.keys(types).sort().forEach(function(key) {
                types[key].forEach(function (el, i) {
                  el.removeAttribute('style');
                  if (i % 2 === 1) {
                    const max = Math.max(el.clientHeight, types[key][i - 1].clientHeight);
                    el.style.height = max + 'px';
                    types[key][i - 1].style.height = max + 'px';
                  }
                });
              });
            }
          }
        });
      },
      resetValues() {
        this.items = this.cloneArray(this.defaultItems);

        if (!this.hideOtherItems) {
          this.itemsOther = this.itemsOther.map((item) => {
            item.list = item.list.map((it) => {
              it.values[1] = undefined;
              return it;
            });
            return item;
          });
        }
      },
      setItemValues(initItems) {
        if (initItems) {
          Object.keys(initItems).forEach((i) => {
            const correspondingItem = this.items.find((item) => {
              return item.code === i;
            });
            if (!correspondingItem) {
              return;
            }
            correspondingItem.text = initItems[i].text;
            correspondingItem.values[1] = initItems[i].values.map((val) => +val);
            initItems[i].statistic.forEach((items, j) => {
              items.items.forEach((value, k) => {
                correspondingItem.statistic[j].items[k].monthlyValues = value.values;
              });
            });
          });
        }
      },
      setOtherItemValues(initOtherItems) {
        if (initOtherItems) {
          Object.keys(initOtherItems).forEach((i) => {
            const correspondingItem = this.itemsOther.find((item) => {
              return item.section_code === i;
            });
            if (!correspondingItem) {
              return;
            }

            correspondingItem.list.forEach((category) => {
              const receivedCategory = initOtherItems[i].values[category.code];
              category.values[1] = receivedCategory ? receivedCategory.value.map((val) => +val) : undefined;
            });
          });
        }
      },
      forceViewUpdate() {
        this.$nextTick(() => {
          this.itemsDataKey++;
          this.itemsOtherDataKey++;
        });
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/base/includes";

  .lk-sociological {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    border-top: $line-width solid rgba($color-black, .15);
    padding-top: 56px;
    @include mobile {
      padding-top: 40px;
      border-bottom: $line-width solid rgba($color-black, .15);
      margin-bottom: 40px;
    }
    &__item {
      width: calc(50% - 22px);
      @include mobile {
        width: 100%;
        margin-bottom: 40px;
      }

      &-inner {
        display: flex;
        flex-direction: column;
        position: relative;
      }

      &-count {
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $color-base;
        margin-bottom: 16px;
      }

      &-question {
        font-size: 18px;
        line-height: 160%;
        margin-bottom: 32px;
      }
      &-charts {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        @include low-desktop {
          justify-content: center;
        }
        &._bar {
          > div {
            &:only-child {
              width: 60%;
              transform: translateX(-24px);
            }
          }
        }
        > div {
          width: 210px;
          margin-bottom: 22px;
          &:only-child {
            margin-left: auto;
            margin-right: auto;
          }
          &:not(:last-child) {
            margin-right: 50px;
            @include low-desktop {
              margin-right: 0;
            }
          }
        }
        &-label {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          margin-bottom: 16px;
          text-align: center;
          ._bar & {
            text-align: left;
          }
        }
      }
      &-legend {
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: rgba($color-black, .5);
        @include low-desktop-min {
          transform: translateX(-42px);
          &._only {
            transform: translateX(-22px);
          }
        }

        &:not(:last-child) {
          margin-bottom: 32px;
        }

        li {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
        i {
          display: inline-block;
          vertical-align: middle;
          transform: translateY(-1px);
          width: 12px;
          height: 12px;
          margin-right: 10px;
          border-radius: 50%;
          &:not(:first-child) {
            margin-left: -5px;
          }
        }
      }
      &-note {
        display: block;
        font-size: 12px;
        line-height: 20px;
        color: rgba($color-black, .5);
        position: absolute;

        &:not(:first-child) {
          bottom: -30px;
        }
      }

      &-other {
        margin-top: 50px;
        border-top: 1px solid #e7e7e7;
        padding-top: 32px;

        &--wrapper {
          &:not(:last-child) {
            margin-bottom: 22px;
          }
        }
        &--legend {
          display: flex;
          font-weight: 500;
          font-size: 11px;
          line-height: 20px;
          margin-bottom: 32px;
          @include tablet {
            flex-wrap: wrap;
          }

          li {
            position: relative;
            padding-left: 22px;
            @include tablet {
              width: 100%;

            }

            &:not(:last-child) {
              margin-right: 32px;
              @include tablet {
                margin-bottom: 12px;
                margin-right: 0;
              }
            }

            i {
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 12px;
              height: 12px;
              border-radius: 50%;
            }
          }
        }
        &--title {
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          margin-bottom: 14px;
        }
        &--list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: -12px;
        }
        &--item {
          width: calc(50% - 12px);
          margin-bottom: 12px;
          @include tablet {
            width: 100%;
          }
        }
        &--bar {
          display: flex;
          padding-top: 18px;
          margin-bottom: 12px;
        }
        &--value {
          position: relative;
          height: 3px;
          min-width: 10%;
          &:nth-child(1) {
            span {
              color: #467EE1;
            }
          }
          &:nth-child(2) {
            span {
              color: #799FE3;
            }
          }
          &:nth-child(3) {
            span {
              color: #A2BAE2;
            }
          }
          span {
            position: absolute;
            bottom: calc(100% + 3px);
            left: 0;
            font-weight: 600;
            font-size: 10px;
            line-height: 150%;
            color: #A2BAE2;
          }
        }
        &--name {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: rgba($color-black, .5);
        }
      }
    }
    &__divider {
      width: 100%;
      height: $line-width;
      border-top: $line-width solid rgba($color-black, .15);
      margin: 56px 0;
      @include mobile {
        display: none;
      }
    }
  }

  .lk-sociological-bars {
    &__title {
      font-weight: bold;
      font-size: 18px;
      line-height: 160%;
      margin-bottom: 16px;
    }
    &__legend {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 32px;
      i {
        display: inline-block;
        vertical-align: middle;
        transform: translateY(-1px);
        width: 12px;
        height: 12px;
        margin-right: 10px;
        border-radius: 50%;
      }
      li {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;
      &:not(:last-child) {
        margin-bottom: 56px;
      }
    }
    &__col {
      width: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;

      &-2 {
        width: calc(100% / 2);
        @include mobile {
          width: 100%;
        }
      }

      &-3 {
        width: calc(100% / 3);
        @include low-desktop {
          width: calc(100% / 2);
        }
        @include mobile {
          width: 100%;
        }
      }
    }
    &__label {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }
    &__bar {
      display: flex;
      padding-top: 18px;

      &--0 {
        order: 1;
      }
    }
    &__value {
      position: relative;
      height: 5px;
      min-width: 5%;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
      span {
        position: absolute;
        bottom: 100%;
        left: 0;
        z-index: 1;
        font-weight: bold;
        font-size: 12px;
        line-height: 150%;
      }
    }
  }
</style>
