<template>
  <article v-if="isExistCard" class="report-card">
    <div class="report-card__inner">
      <h3 class="report-card__title title-lg ff-title" v-html="card.name" />
      <div v-if="card.description" class="report-card__description" v-html="card.description" />
      <div v-if="card.fields?.length" class="report-card__fields">
        <div
          v-for="(field, i) in card.fields"
          :key="`report-card__field-${field.id}`"
          class="report-card__field"
        >
          <div class="report-card__field-main">
            <input
              type="text"
              class="report-card__field-input"
              :class="{ 'report-card__field-input--filled': field.value }"
              placeholder="Введите показатель"
              :value="getFormattedValue(field.value)"
              @input="(e) => onInput(e, field.id, i)"
              @change="(e) => onChange(e, field.id, i)"
              @blur="(e) => onChange(e, field.id, i)"
            >
            <div class="report-card__field-label" v-html="field.label" />
          </div>
          <div class="report-card__info" v-html="field.caption" />
        </div>
      </div>
      <div class="report-card__files" v-if="card.canLoadFile">
        <div class="report-card__files-header">
          <div class="report-card__files-info">
            Разрешенные форматы – {{ validFormatString }}
            <br>
            Максимальный вес одного файла – {{ maxSize }} мб
          </div>
        </div>
        <div class="report-card__files-controller">
          <MediaLoaderController
            ref="mediaController"
            :validFormat="validFormatFile"
            :initialFiles="card.file"
            :maxSize="maxSize"
            @change="onFileChangeImageEvent"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import {
  mutateClearFilesFromForm,
  mutateOnFilesChange,
} from '@/components/helpers/formHelpers.js'

import { getFormattedValue } from '@/components/helpers/formatHelper.js'

export default {
  props: {
    card: {
      type: Object,
      default: () => ({})
    },

    validFormatFile: {
      type: Array,
      default: () => ['doc', 'docx', 'pdf'] // Array<string> // [png, doc]
    },

    maxSize: {
      type: Number,
      default: 20 // в МБ // 20
    },

    maxCount: {
      type: Number,
      default: Infinity // макс кол-во файлов
    },
  },

  data() {
    return {
      fields: {},
    }
  },

  computed: {
    isExistCard() {
      return this.card && Object.values(this.card).some(Boolean)
    },

    validFormatString() {
      return this.validFormatFile.join(', ')
    }
  },

  methods: {
    onChange(e, id, i) {
      let value = this.getFormattedValue(e.target.value);

      this.$emit('changeField', { ...this.card.fields[i], id, value })
    },

    onInput(e) {
      e.target.value = this.getFormattedValue(e.target.value);
    },

    initFields() {
      if (!this.card.fields?.length) {
        return
      }

      this.card.fields.forEach(item => {
        this.fields[item.id] = item.value
      })
    },

    onFileChange(files, fileProp) {
      mutateClearFilesFromForm(this.card, fileProp)
      mutateOnFilesChange(this.card, files, fileProp)
    },

    onFileChangeImageEvent(files) {
      this.onFileChange(files, 'file')
    },

    getFormattedValue(value) {
      return getFormattedValue(value)
    }
  },

  created() {
    this.initFields()
  }
}
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: '.report-card';

#{$b} {

  // .report-card__inner
  &__inner {
    padding: 32px;
    background-color: $white-true;
    border-radius: 16px;

    @include tablet {
      padding: 24px;
    }

    @include mobile {
      padding: 16px;
    }
  }

  // .report-card__title
  &__title {
    max-width: 992px;
    margin: 0 0 24px 0;
  }

  // .report-card__description
  &__description {
    max-width: 992px;
    color: rgba($black-true, 0.5);
    margin-bottom: 40px;

    @include tablet {
      margin-bottom: 24px;
    }
  }

  // .report-card__fields
  &__fields {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  // .report-card__field
  &__field {
    display: flex;
    align-items: center;
    gap: 16px;

    @include tablet {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    // .report-card__field-main
    &-main {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      max-width: 288px;

      @include tablet {
        max-width: 100%;
      }
    }

    // .report-card__filed-label
    &-label {
      color: rgba($black-true, 0.5)
    }

    // .report-card__field-input
    &-input {
      width: 190px;
      height: 48px;
      padding: 5px 15px;
      border: 1px solid rgba($black-true, 0.2);
      transition: $transtion-default;
      border-radius: 8px;

      @include hover {
        border-color: rgba($color-base-origin, 0.2);
      }

      &--filled,
      &:focus {
        border-color: $color-base-origin;
        color: $color-base-origin;
      }
    }
  }

  // .report-card__files
  &__files {

    // .report-card__files-header
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      @include tablet {
        display: block;
      }
    }

    // .report-card__files-info
    &-info {
      color: rgba($black-true, 0.5);

      @include tablet {
        margin-bottom: 16px;
      }
    }

    // .report-card__files-btn
    &-btn {
      @include mobile {
        width: 100%;
      }
    }

    // .report-card__files-controller
    &-controller {
      position: relative;
    }
  }
}
</style>
