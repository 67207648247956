<script>
  import {Doughnut} from 'vue-chartjs';
  import 'chartjs-plugin-datalabels';

  export default {
    name: "DonutChart",
    extends: Doughnut,
    props: {
      height: {
        type: Number,
        default: 210
      },
      dataSets: {
        type: Array,
        required: true
      },
      colors: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        chartdata: {
          datasets: [
            {
              backgroundColor: this.colors,
              data: this.dataSets,
              borderWidth: 0,
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: false },
          tooltips: { enabled: false },
          plugins: {
            datalabels: {
              anchor: 'center',
              align: 'center',
              color({ dataIndex }) {
                return dataIndex > 1 ? '#202020' : '#fff';
              },
              font: {
                family: 'Montserrat',
                size: 12,
                lineHeight: 2.5,
                weight: 'bold'
              },
              offset: 0,
            }
          },
        }
      }
    },
    mounted() {
      this.renderChart(this.chartdata, this.options);

      this.$nextTick(() => {
        this.renderChart(this.chartdata, this.options);
      });
    },
  }
</script>
