<template>
  <div
    class="custom-dropdown"
    :class="[
        {
          'custom-dropdown--is-opened': isOpened,
          'custom-dropdown--multiply': multiply,
        },
        `custom-dropdown--theme-${theme}`
      ]"
    >
    <div class="custom-dropdown__inner">
      <div
        class="custom-dropdown__caption"
        :class="{'custom-dropdown__caption--is-placeholder': caption.isPlaceholder}"
        @click="toggle"
      >
        <div class="custom-dropdown__caption-text">
          <span v-html="caption.text" />
          <span v-if="multiply && value.length > 1">
            +{{value.length - 1}}
          </span>
        </div>
        <div class="custom-dropdown__caption-arrow">
          <inline-svg class="custom-dropdown__caption-icon" src="u-arr-down" />
        </div>
      </div>
      <div class="custom-dropdown__list">
        <div
          v-if="canSelectAll"
          class="custom-dropdown__item"
          :class="{'custom-dropdown__item--is-active': (!value || !value.length)}"
          v-html="placeholder"
          @click="select('all')"
        />
        <div
          v-for="item in options"
          :key="`custom-dropdown__item-${item.id}`"
          class="custom-dropdown__item"
          :class="{'custom-dropdown__item--is-active': isActiveOption(item.id)}"
          v-html="item.name"
          @click="select(item.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number, Array],
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Все'
    },
    theme: {
      type: String,
      default: 'purple'
    },
    multiply: {
      type: Boolean,
      default: false
    },
    canSelectAll: {
      type: Boolean,
      default: true
    },
    closeAfterSelect: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    caption() {
      const captionFromValue = this.options.find(item => {
        return this.multiply ? this.value.find(v => v === item.id) : item.id === this.value

      })

      return {
        text: captionFromValue?.name || this.placeholder,
        isPlaceholder: !captionFromValue?.name
      }
    },
  },
  data() {
    return {
      isOpened: false
    }
  },
  methods: {
    select(id) {
      let value = id;

      if (this.multiply) {
        value = id === 'all' ? [] : this.value.includes(id) ? [...this.value.filter(v => v !== id)] : [...this.value, id]
      }

      this.$emit('input', value)

      if (this.closeAfterSelect) {
        this.isOpened = false
      }
    },
    open() {
      this.isOpened = true
    },
    toggle() {
      this.isOpened = !this.isOpened
    },
    closeOther(e) {
      const el = e.target.closest('.custom-dropdown')

      if (
        !(
          e.target.classList.contains('custom-dropdown') ||
          (el && el === this.$el)
        )
      ) {
        this.isOpened = false
      }
    },
    isActiveOption(id) {
      return (id === 'all' && (!this.value || !this.value.length)) || this.multiply
        ? this.value.includes(id)
        : this.value === id
    }
  },
  mounted() {
    window.addEventListener('click', this.closeOther)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeOther)
  }
}
</script>


<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.custom-dropdown';

#{$b} {
  width: 100%;
  // .custom-dropdown__inner
  &__inner {
    position: relative;
    width: 100%;
  }

  // .custom-dropdown__caption
  &__caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 5px 20px;
    height: 40px;
    min-width: 200px;
    border-radius: 10px;

    #{$b}--theme-purple & {
      border: none;
      border-bottom: 1px solid $white-true;
      background-color: transparent;
      width: 100%;
      border-radius: 0;
    }

    #{$b}--is-opened & {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    @include mobile {
      padding: 4px 10px;
    }

    // .custom-dropdown__caption-text
    &-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      #{$b}__caption--is-placeholder & {
      }
    }

    // .custom-dropdown__caption-arrow
    &-arrow {
      margin-left: 12px;
    }

    // .custom-dropdown__caption-icon
    &-icon {
      width: 15px;
      height: 15px;
      transition: $transtion-default;

      #{$b}--is-opened & {
        transform: rotate(180deg);
      }

      path {
        stroke: currentColor;
        fill: transparent !important;
      }
    }
  }

  // .custom-dropdown__list
  &__list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    touch-action: none;
    transition: $transtion-default;
    transform: translateY(-10px);
    overflow-y: auto;
    overflow-x: hidden;

    @include mobile-min {
      @include customScrollBar(4px, $black-true, transparent);
    }


    #{$b}--theme-purple & {
      background-color: $white-true;
      color: $color-purple;
      padding: 10px 0;
      max-height: 208px;

      @include mobile-min {
        @include customScrollBar(4px, $color-purple, transparent);
      }
    }

    #{$b}--is-opened & {
      opacity: 1;
      pointer-events: unset;
      touch-action: unset;
      transform: translateY(0);
    }
  }

  // .custom-dropdown__item
  &__item {
    padding: 8px;
    transition: $transtion-default;
    cursor: pointer;

    // .custom-dropdown__item--is-active
    &--is-active {
      display: none;

      #{$b}--theme-purple#{$b}--multiply & {
        display: block;
        padding-right: 25px;
        background-color: rgba($color-purple, 0.1);
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M16.5416 6.04172L7.70829 14.8751C7.63005 14.9539 7.52356 14.9983 7.41246 14.9983C7.30136 14.9983 7.19486 14.9539 7.11662 14.8751L2.62496 10.3834C2.54608 10.3052 2.50171 10.1987 2.50171 10.0876C2.50171 9.97646 2.54608 9.86996 2.62496 9.79172L3.20829 9.20839C3.28653 9.12951 3.39303 9.08514 3.50412 9.08514C3.61522 9.08514 3.72172 9.12951 3.79996 9.20839L7.40829 12.8167L15.3666 4.85839C15.5315 4.69857 15.7934 4.69857 15.9583 4.85839L16.5416 5.45006C16.6205 5.52829 16.6649 5.63479 16.6649 5.74589C16.6649 5.85699 16.6205 5.96349 16.5416 6.04172Z" fill="%235144C3"/></svg>');
        background-position: center right 12px;
        background-size: 20px 20px;
        background-repeat: no-repeat;
      }
    }

    &:hover {
      #{$b}--theme-purple {
        @include mobile-min {
          background-color: rgba($color-purple, 0.1);
        }
      }
    }

    @include mobile {
      padding: 4px 10px;
    }
  }
}
</style>
