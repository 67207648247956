<template>
  <div class="u-initiatives">
    <u-figures
      v-if="items && items.length > 2"
      :items="[
        {'name':'f-initiatives-page-circle-1','image':'/frontend/assets/img/bg-shapes/circle-group-2-left-opacity.svg','animation':'fade-in-left'},
        {'name':'f-initiatives-page-circle-2','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
      ]"
      position="static"
    ></u-figures>
    <div class="u-initiatives__main">
      <div class="u-initiatives__filter">
        <div class="u-select u-initiatives__select">
          <v-select
            v-model="selectedAudience"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="audienceOptions"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
        <div class="u-select u-initiatives__select">
          <v-select
            v-model="selectedPartner"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="partnersOptions"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
      </div>
      <div v-if="items.length" class="u-initiatives__list">
        <InitiativesCard
          v-for="(card, i) in items"
          :key="`u-initiatives__card-${i}`"
          class="u-initiatives__card"
          :card="card"
        />
      </div>
      <div v-else class="u-typography">
        <p>По выбранным фильтрам ничего не найдено</p>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from 'axios'

export default {
  name: "UInitiatives",
  components: { vSelect },
  props: {
    initItems: {
      type: Array,
      default: () => [],
    },
    audienceOptions: {
      type: Array,
      default: () => [
        {
          id: '', name: 'Вся аудитория',
        }
      ]
    },
    partnersOptions: {
      type: Array,
      default: () => [
        {
          id: '', name: 'Все ведомства и компании',
        }
      ]
    }
  },
  data() {
    return {
      selectedAudience: '',
      selectedPartner: '',
      items: this.initItems,
      OpenIndicator: {
        render: createElement => createElement('span')
      },
    };
  },
  computed: {
  },
  watch: {
    selectedAudience() {
      this.filterProcess();
    },
    selectedPartner() {
      this.filterProcess();
    },
  },
  methods: {
    async filterProcess() {
      let params = [];
      if (this.selectedAudience) {
        params.push(`audience=${this.selectedAudience}`);
      }
      if (this.selectedPartner) {
        params.push(`partner=${this.selectedPartner}`);
      }

      const res = await axios.get(`?AJAX=Y&${params.join("&")}`);
      this.items = [];
      if (res?.data?.ITEMS) {
        res.data.ITEMS.map(item => {
          this.items.push(item);
        });
      }
    }
  },
};
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: ".u-initiatives";

#{$b} {

  // .u-initiatives__main
  &__main{
    position: relative;
    z-index: 10;
  }

  // .u-initiatives__filter
  &__filter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 64px;

    @include mobile {
      display: block;

      margin-bottom: 48px;
    }
  }

  // .u-initiatives__select
  &__select{
    width: 400px;

    @include tablet {
      width: calc(50% - 10px);
    }

    @include mobile {
      width: 100%;

      &:not(:last-child){
        margin-bottom: 24px;
      }
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    @include mobile-min {
      margin: 0 0 0 -16px;
    }

  }

  // .u-initiatives__card
  &__card{
    width: 100%;
    @include mobile-min {
      width: 50%;
      padding-left: 16px;
      margin-bottom: 16px;
    }

    @include low-desktop-min {
      width: calc(100% / 3) ;
    }

    &:not(:last-child){
      @include mobile {
        margin-bottom: 24px;
      }
    }

    &:nth-child(8n + 2),
    &:nth-child(8n + 5) {
      @include low-desktop-min {
        width: calc( 100% / 3 * 2 );
      }
    }

    &:nth-child(4n + 3),
    &:nth-child(4n + 4) {
      @include mobile-min {
        width: 50%;
      }
    }
  }

}
</style>
