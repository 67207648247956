export function parseStringToDate(date) {
  let thisDate = date

  if (date && !(date instanceof Date && !isNaN(date)) && !(date.length < 10)) {

    const partialsDate = date.split('.')
    const dd = partialsDate[0]
    const mm = partialsDate[1]
    const yy = partialsDate[2]

    thisDate = new Date(yy, mm-1, dd)
  }

  return thisDate
}
