<template>
  <div class="change-pass">
    <form v-if="!showResult" class="change-pass__form" @submit.prevent="onSubmit">
      <div class="change-pass__field">
        <u-text-input
          class="u-text-input--smaller"
          name="password"
          label="Старый пароль"
          v-model="form.password"
          input-type="password"
          :error="$v.form.password.$error || errors.password"
          @input="errors.password = false"
          isPassword
        />
        <ErrorFormWrapper :isVisible="($v.form.password.$error && !$v.form.password.required) || errors.password">
          <div v-show="!$v.form.password.required">Обязательное поле</div>
          <div v-show="errors.password">Введен неверный пароль. Попробуйте еще раз.</div>
        </ErrorFormWrapper>
      </div>
      <div class="change-pass__field">
        <u-text-input
          class="u-text-input--smaller"
          name="password_new"
          label="Новый пароль"
          v-model="form.password_new"
          input-type="password"
          :error="$v.form.password_new.$error"
          isPassword
        />
        <ErrorFormWrapper :isVisible="$v.form.password_new.$error">
          <div v-show="!$v.form.password_new.required">Обязательное поле</div>
          <div v-show="!$v.form.password_new.minLength">Должен содержать не менее 8 символов</div>
          <div v-show="!$v.form.password_new.not">Новый пароль не должен совпадать со старым</div>
        </ErrorFormWrapper>
      </div>
      <div class="change-pass__field">
        <u-text-input
          class="u-text-input--smaller"
          name="password_new2"
          label="Новый пароль еще раз"
          v-model="form.password_new2"
          input-type="password"
          :error="$v.form.password_new2.$error"
          isPassword
        />
        <ErrorFormWrapper :isVisible="$v.form.password_new2.$error">
          <div v-show="!$v.form.password_new2.required">Обязательное поле</div>
          <div v-show="!$v.form.password_new2.sameAs && Boolean(form.password_new2)">Пароли не совпадают</div>
          <div v-show="!$v.form.password_new2.not">Новый пароль не должен совпадать со старым</div>
        </ErrorFormWrapper>
      </div>
      <div class="change-pass__field change-pass__field--center">
        <CustomButton
          class="change-pass__button"
          type="submit"
          theme="primary"
          size="md"
        >
          Сменить Пароль
        </CustomButton>
      </div>
    </form>
    <div v-else class="change-pass__result">
      <div class="change-pass__field">
        {{ errors.request ? 'Что-то пошло не так. Проверьте своё подключение к Сети или попробуйте позже.' : 'Пароль был успешно изменён.'}}
      </div>
      <div class="change-pass__field change-pass__field--center">
        <CustomButton
          class="change-pass__button"
          type="submit"
          theme="primary"
          size="md"
          @click.stop.prevent="$root.$emit('modal-cancel')"
        >
          Хорошо
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { validationMixin } from 'vuelidate';
  import { required, minLength, sameAs, not } from 'vuelidate/lib/validators';

  export default {
    name: "ChangePass",
    mixins: [validationMixin],
    data() {
      return {
        form: {
          password: '',
          password_new: '',
          password_new2: '',
        },
        errors: {
          request: false,
          password: false,
        },
        showResult: false,
      };
    },
    validations: {
      form: {
        password: { required },
        password_new: {
          required,
          minLength: minLength(8),
          not: not(sameAs('password'))
        },
        password_new2: {
          required,
          sameAs: sameAs('password_new'),
          not: not(sameAs('password'))
        },
      }
    },
    methods: {
      async onSubmit() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          axios.post('/bitrix/services/main/ajax.php?mode=class&c=dalee:personal_form&action=updatePassword', {
            password: this.form.password,
            password_new: this.form.password_new
          })
          .then((res) => {
            this.handleResponse(res.data);
          })
          .catch((e) => {
            console.error(e);
            this.handleResponse();
          });
        }
      },
      handleResponse(data) {
        if (data.status === 'success') {
          this.showResult = true;
        } else if (data.errors[0].message === 'wrong-password') {
          this.errors.password = true;
        } else {
          this.showResult = true;
          this.errors.request = true;
        }
      },
    },
  };
</script>

<style lang="scss">
  @import "../scss/base/includes";

  $b: ".change-pass";

  #{$b} {
    &__field {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 32px;
      }

      &--center {
        text-align: center;
      }
    }

    &__error {
      position: absolute;
      left: 0;
      top: calc(100% + 3px);
      font-size: 12px;
      line-height: 160%;
      color: $color-error;
    }

    &__button {
      width: 261px;
      max-width: 100%;
    }
  }
</style>
