<template>
  <div v-show="lightboxIndex || lightboxIndex === 0" class="u-popup-gallery">
    <CoolLightBox
      :items="items"
      :index="lightboxIndex"
      :loop="options.loop"
      :slideshow="options.slideshow"
      :gallery="options.gallery"
      :closeOnClickOutsideMobile="options.closeOnClickOutsideMobile"
      :thumbs-position="options['thumbs-position']"
      @close="close"
      @on-open="onLightboxOpen"
    />
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  components: { CoolLightBox },
  data() {
    return {
      lightboxIndex: null,
      items: [],
      options: {
        loop: false,
        slideshow: false,
        gallery: false,
        closeOnClickOutsideMobile: true,
        'thumbs-position': 'bottom'
      },
      htmlBlock: null
    }
  },
  created() {
    this.$root.$on('openPopupGallery', this.open)
    this.$root.$on('closePopupGallery', this.close)
  },
  mounted() {
    this.htmlBlock = document.querySelector("html");
  },
  methods: {
    open(initialIndex, items, options) {
      this.blockScrollPage()
      this.lightboxIndex = initialIndex
      this.items = items
      if (options) {
        this.options = { ...this.options, ...options }
      }

      this.$nextTick(() => {
        setTimeout(() => {
          const caption = this.$el.querySelector('.cool-lightbox-caption')
          const video = this.$el.querySelector('.cool-lightbox-video')

          if (!video) {
            return
          }

          const captionRect = caption.getBoundingClientRect()
          const videoRect = video.getBoundingClientRect()

          if (videoRect.height + captionRect.height > window.innerHeight) {
            caption.style.display = 'none'
          }

        }, 100);
      })

    },
    close() {
      this.htmlBlock.style.overflow = "";
      this.lightboxIndex = null
      this.items = []
      this.$root.$emit('setVisibleStatus', true)
    },
    blockScrollPage() {
      this.htmlBlock.style.overflow = "hidden";
    },
    onLightboxOpen() {
      this.$root.$emit('setVisibleStatus', false)
    },
  },
  beforeDestroy() {
    this.$root.$off('openPopupGallery', this.open)
    this.$root.$off('closePopupGallery', this.close)
  },
}
</script>

<style lang="scss">
$b: '.u-popup-gallery';

#{$b} {
  video {
    max-width: 100%;
    max-height: 100%;
  }

  .cool-lightbox-caption {
    pointer-events: none;
    touch-action: none;
  }
}
</style>
