<template>
  <section class="faq-block">
    <div class="faq-block__inner">
      <FaqBanner
        v-if="isExistBanner"
        class="faq-block__banner"
        :title="banner.title"
        :text="banner.text"
        @btnClick="openModal"
      />
      <div v-if="faqList && faqList.length" class="faq-block__body">
        <h2 class="faq-block__title title-2lg tt-uppercase">Ответы</h2>
        <div class="faq-block__main">
          <FaqList class="faq-block__faq-list" :list="faqList" />
          <div v-if="hasNextPage" class="faq-block__actions">
            <div class="faq-block__more fw-500" @click="loadMore">
              Показать еще
              <InlineSvg class="faq-block__more-icon" src="u-arr-down" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { hasQueryInUrl } from '/frontend/components/helpers/queryHelper.js'

export default {
  props: {
    banner: {
      type: Object,
      default: () => ({})
    },
    initialFaqList: {
      type: Array,
      default: () => []
    },
    actionGet: {
      type: String,
      default: ''
    },
    actionSend: {
      type: String,
      default: ''
    },
    initialNextNumPage: {
      type: Number,
      default: 0
    },
    themeOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      faqList: JSON.parse(JSON.stringify(this.initialFaqList)),
      nextNumPage: this.initialNextNumPage,
      isLoading: false,
    }
  },
  computed: {
    isExistBanner() {
      return this.banner && Object.values(this.banner).filter(item => !!item).length > 0
    },
    hasNextPage() {
      return this.nextNumPage > 1
    },
    resultActionGet() {
      const separator = hasQueryInUrl(this.actionGet) ? '&' : '?'
      return `${this.actionGet}${separator}PAGEN_2=${this.nextNumPage}&ajaxCall=Y`
    }
  },
  methods: {
    async loadMore() {
      if (this.isLoading || !this.hasNextPage) {
        return
      }

      try {
        this.isLoading = true
        this.$root.$emit('setLoading', this.isLoading)

        const response = await axios(this.resultActionGet)

        if (response.status === 200 || response.statusText === 'OK') {
          this.faqList = [...this.faqList, ...response.data.result.items]
          this.nextNumPage = response.data.result.nextNumPage
        }
      } catch(e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
        this.$root.$emit('setLoading', this.isLoading)
      }
    },
    openModal() {
      this.$root.$refs.customModal.openModal(null, null, true, true)
      this.$root.$refs.customModal.passContent({
        name: 'faq-form',
        title: 'Задать вопрос',
        props: {
          actionSubmit: this.actionSend,
          themeOptions: this.themeOptions,
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.faq-block';

#{$b} {

  // .faq-block__banner
  &__banner {
    @include mobile {
      &:not(:last-child) {
        margin-bottom: 65px;
      }
    }
  }

  // .faq-block__body
  &__body {
    padding: 0 32px;

    @include mobile {
      padding: 0;
    }
  }

  // .faq-block__title
  &__title {
    margin: 0 0 24px 0;

    @include mobile-min {
      display: none;
    }
  }

  // .faq-block__main
  &__main {
    padding: 32px;
    background-color: $white-true;
    border-radius: 0 0 16px 16px;

    @include mobile {
      border-radius: 16px;
      padding: 24px;
    }
  }

  // .faq-block__actions
  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }

  // .faq-block__more
  &__more {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    @include mobile-min {
      &:hover {
        #{$b}__more-icon {
          animation: bounce 1s infinite;
        }
      }
    }

    // .faq-block__more-icon
    &-icon {
      margin-left: 10px;
      width: 10px;
      height: 10px;
      transition: $transtion-default;

      svg {
        max-width: 100%;
        max-height: 100%;

        path {
          fill: transparent !important;
          stroke: currentColor;
        }
      }
    }
  }
}
</style>
