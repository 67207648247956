<template>
  <div class="faq-form">
    <div class="faq-form__inner">
      <div class="faq-form__fields">
        <div class="faq-form__field u-select u-select--small">
          <div class="faq-form__label text-sm">
            Выбор темы*
          </div>
          <div>
            <v-select
              v-model="form.theme"
              :class="{ 'vs--error': isRequiredErrorField('theme') }"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="themeOptions"
              placeholder="Выберите тему"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
            <ErrorFormWrapper
              :isVisible="isRequiredErrorField('theme')"
              class="faq-form__error-field"
            >
              <div>Обязательное поле</div>
            </ErrorFormWrapper>
          </div>
        </div>
        <div class="faq-form__field">
          <u-text-input
            class="u-text-input--smaller"
            name="fio"
            label="Детальный вопрос*"
            v-model="form.question"
            placeholder="Введите ваш вопрос"
            :error="isRequiredErrorField('question')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('question')"
            class="faq-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
        <div class="faq-form__field">
          <u-text-input
            class="u-text-input--smaller"
            name="fio"
            label="E-mail*"
            v-model="form.email"
            placeholder="E-mail"
            :error="isRequiredErrorField('email')
              || isEmailErrorField('email')
              || isGmailErrorField('email')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('email')
              || isEmailErrorField('email')
              || isGmailErrorField('email')"
            class="faq-form__error-field"
          >
            <div v-show="isRequiredErrorField('email')">Обязательное поле</div>
            <div v-show="isEmailErrorField('email')">Неправильный e-mail</div>
            <div v-show="isGmailErrorField('email') && !isEmailErrorField('email')">Укажите почту в российской зоне .ru</div>
          </ErrorFormWrapper>
        </div>
      </div>
      <ErrorFormWrapper
        class="faq-form__feedback-errors"
        :max-height="1000"
        :isVisible="Boolean(errorsAfterSubmit) && Boolean(errorsAfterSubmit.length)"
      >
        <div
          v-for="error in errorsAfterSubmit"
          :key="`faq-form__feedback-error-${error.code}`"
          class="faq-form__feedback-error"
        >
          <span v-html="error.message" />
        </div>
      </ErrorFormWrapper>
      <div class="faq-form__actions">
        <CustomButton
          class="faq-form__submit"
          theme="primary"
          size="md"
          :status="btnStatus"
          @click="onSubmit"
        >
          Отправить
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { checkGmail } from '/frontend/components/helpers/validate.js'

import vSelect from "vue-select";

export default {
  mixins: [ validationMixin ],
  components: { vSelect },
  props: {
    themeOptions: {
      type: Array,
      default: () => []
    },
    actionSubmit: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        theme: '',
        question: '',
        email: ''
      },
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      btnStatus: '',
      errorsAfterSubmit: []
    }
  },
  validations: {
    form: {
      theme: { required },
      question: { required },
      email: { required, email, isGmail: checkGmail },
    }
  },
  computed: {
    formData() {
      const formData = new FormData()

      for (let key in this.form) {
        formData.append(key, this.form[key])
      }

      return formData
    }
  },
  methods: {
    isRequiredErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].required
    },
    isEmailErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].email
    },
    isGmailErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].isGmail
    },
    async onSubmit() {
      this.btnStatus = 'loading'

      try {
        this.$v.$touch();

        if (this.$v.$invalid) {
          return
        }

        const options = {
          method: 'POST',
          url: this.actionSubmit,
          data: this.formData
        }

        const response = await axios(options)

        if (response.data.status === 'success') {
          this.btnStatus = 'success'
          this.errorsAfterSubmit = []
          this.$root.$refs.customModal.closeModal()
        } else {
          this.errorsAfterSubmit = response.data.errors
        }
      } catch(e) {
        throw new Error(e)
      } finally {
        this.btnStatus = ''
      }
    }
  }
}
</script>

<style lang="scss">

@import '/frontend/scss/base/u-includes';

$b: '.faq-form';

#{$b} {

  // .faq-form__fields
  &__fields {
    margin-bottom: 32px;
  }

  // .faq-form__label
  &__label {
    text-transform: initial;
    margin-bottom: 8px;
    margin-left: 8px;
  }

  // .faq-form__field
  &__field {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  // .faq-form__actions
  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // .faq-form__submit
  &__submit {
    min-width: 298px;

    @include mobile {
      width: 100%;
    }
  }

  &__feedback-error {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 15px;

    @include mobile {
      font-size: 18px;
    }

    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
}
</style>
