<template>
  <div v-if="isShow" class="header-search" :class="{ 'is-showed': isShow }">
    <form
      ref="searchForm"
      class="header-search__form"
      :action="actionSearch"
    >
      <div
        class="header-search__icons-left"
        @click="onSearch"
      >
        <img
          class="header-search__search-icon"
          src="/frontend/assets/img/svg/u-search.svg"
        >
        <div class="header-search__separator-icon"></div>
      </div>
      <input
        class="header-search__input"
        type="text"
        placeholder="Поиск"
        name="q"
        v-model="search"
        ref="searchInHeader"
      />
      <div class="header-search__close" @click="closeSearch">
        <inline-svg src="close-thin" />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    forMobile: {
      type: Boolean,
      default: false
    },
    actionSearch: {
      type: String,
      default: '/search/'
    },
    isShowAllways: {
      type: Boolean,
      default: false
    }
  },
  name: "HeaderSearch",
  data() {
    return {
      isShow: this.isShowAllways,
      search: "",
      header: undefined,
    };
  },
  mounted() {
    if (this.forMobile) {
      this.$root.$on("toggleSearchHeaderMobile", this.toggleSearch);
    } else {
      this.$root.$on("toggleSearchHeader", this.toggleSearch);
      this.header = document.querySelector("#header");
    }

    document.addEventListener('keypress', this.searchFromKey)
  },
  methods: {
    toggleSearch() {
      this.isShow = !this.isShow;

      if (this.isShow && !this.forMobile) {
        this.header.classList.add("is-header-search-opened");
        this.$nextTick(() => {
          this.$refs.searchInHeader.focus()
        })
      } else {
        if (this.header) {
          this.header.classList.remove("is-header-search-opened");
        }
      }
    },
    closeSearch() {
      this.isShow = false;
      if (this.header) {
        this.header.classList.remove("is-header-search-opened");
      }
    },
    onSearch() {
      this.$refs.searchForm.submit()
    },
    searchFromKey(e) {
      if ((e.key === "Enter" || e.keyCode === 13) && this.isShow) {
        this.onSearch()
      }
    }
  },

  beforeDestroy() {
    document.removeEventListener('keypress', this.searchFromKey)
  }
};
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: ".header-search";

#{$b} {
  position: relative;
  width: 100%;

  @include mobile {
    ~ div {
      display: none;
    }
  }

  &__form {
    display: block;
    position: relative;
    color: $white-true;

    @include tablet {
      margin-left: 200px;
    }

    @include mobile {
      margin: 0;
    }
  }

  // .header-search__icons-left
  &__icons-left {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 65px;
    display: inline-flex;
    align-items: center;
    padding-left: 15px;
    cursor: pointer;

    @include tablet-only {
      display: none;
    }

    @include mobile {
      width: 30px;
      left: unset;
      right: 0;
      padding: 0;
    }
  }

  // .header-search__search-icon
  &__search-icon {
    width: 21px;
    height: 21px;
    margin-right: 9px;

    @include mobile {
      width: 20px;
      height: 20px;
    }
  }

  // .header-search__separator-icon
  &__separator-icon {
    height: 26px;
    min-width: 1.5px;
    max-width: 1.5px;
    background-color: $black-true;

    @include mobile {
      display: none;
    }
  }

  &__input {
    height: 52px;
    width: 100%;
    display: block;
    padding: 10px 45px 10px 65px;
    border: 0;
    border-radius: 32px;
    background-color: $white-true ;
    font-family: $font-family-inter;
    color: $black-true;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    appearance: none;
    transition: $transtion-default;

    @include tablet {
      height: 37px;
      padding: 7px 42px 7px 16px;
    }

    @include mobile {
      height: 40px;
      padding-left: 14px;
    }


    @include placeholder {
      color: inherit;
      opacity: 0.8;
    }
  }

  &__close {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 21px;
    width: 20px;
    height: 100%;
    text-align: right;
    color: $black-true;
    transform: translateY(-50%);
    transition: color 0.2s ease;

    &:hover,
    &:focus {
      color: $color-base;
    }

    @include tablet {
      right: 13px;
    }

    @include mobile {
      display: none;
    }


    .inline-svg {
      display: inline-block;
      width: 13px;
      height: 13px;
    }

  }
}
</style>
