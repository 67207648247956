<template>
  <div class="share-button">
    <span class="share-button__icon">
      <inline-svg src="share-arrow" />
    </span>
    <span class="share-button__label" v-html="label"></span>
  </div>
</template>

<script>
export default {
  name: "ShareButton",
  props: {
    label: {
      type: String,
      default: "Поделиться"
    },
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".share-button";

#{$b} {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: $color-base;

  &--light {
    color: $white-true;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: $color-base;
    color: $white-true;
    border-radius: 30px;
    margin-right: 15px;

    @include mobile {
      margin-right: 10px;
    }

    .inline-svg {
      width: 14px;
      height: 14px;
    }
  }

  &__label {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @include mobile {
      font-size: 10px;
    }
  }
}
</style>
