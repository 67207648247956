<template>
  <div v-if="isUpdatedSwiper" class="initiatives-slider">
    <swiper ref="initiativesSwiper" :options="swiperOptions" @slideChange="onSlideChange">
      <slot/>
    </swiper>
    <div class="initiatives-slider__controls-wrapper">
      <div class="u-swiper-controls initiatives-slider__controls">
        <button
          class="u-swiper-controls__btn u-swiper-controls__btn--prev initiatives-slider__btn-prev">
        </button>
        <div class="u-swiper-controls__count initiatives-slider__count">
        </div>
        <button
          class="u-swiper-controls__btn u-swiper-controls__btn--next initiatives-slider__btn-next">
        </button>
      </div>
      <div class="initiatives-partners__swiper-pagination-mob">
        <div class="u-swiper-pagination initiatives-slider__pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InitiativesSlider",
  props: {
    isLoop: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      paginationEl: '.initiatives-slider__count',
      activeSlide: 0,
      isUpdatedSwiper: true,
    };
  },
  computed: {
    swiper() {
      return this.$refs.initiativesSwiper.$swiper;
    },
    swiperOptions() {
      return {
        slidesPerView: 1.2,
        slidesPerGroup: 1,
        spaceBetween: 16,
        loop: this.isLoop,
        navigation: {
          prevEl: '.initiatives-slider__btn-prev',
          nextEl: '.initiatives-slider__btn-next',
          disabledClass: "u-swiper-controls__btn--is-disabled"
        },
        pagination: {
          el: this.paginationEl,
          type: "bullets",
          bulletClass: "u-swiper-pagination__bullet",
          bulletActiveClass: "u-swiper-pagination__bullet--is-active",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            autoHeight: true,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          },
          1400: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            autoHeight: false,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          },
        }
      }
    }
  },
  methods: {
    goToSlide(i) {
      this.swiper.slideTo(i);
    },
    onSlideChange() {
      this.activeSlide = this.swiper.activeIndex;
    },
    updateSwiper(){
      this.isUpdatedSwiper = false
      this.setPaginationEl()
      this.$nextTick(()=> {
        this.isUpdatedSwiper = true
      })
    },
    setPaginationEl(){
      if (window.innerWidth > 767) {
        this.paginationEl = '.initiatives-slider__count'
      } else {
        this.paginationEl = '.initiatives-slider__pagination'
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateSwiper)
    this.updateSwiper()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSwiper)
  },
};
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: ".initiatives-slider";

#{$b} {
  color: $color-black;
  margin-bottom: 2rem;

  @include mobile {
    margin-right: -$container-pd-right-mobile;
    margin-bottom: 0;
  }

  &__inner {
    position: relative;
  }

  // .swiper-wrapper {

  //   @include tablet-min {
  //     height: 400px !important;
  //   }

  //   @include tablet {
  //     height: 375px !important;
  //   }
  // }

  .swiper-slide {
    height: auto;

    .event-panel {
      height: 100%;
    }
  }

  &__controls {
    box-shadow: 0 4px 12px rgba(11, 32, 95, 0.1);

    @include mobile {
      display: none;
    }

    &-wrapper {
      position: absolute;
      right: 0;
      top: -32px;
      transform: translateY(-100%);
      z-index: 10;

      @include mobile {
        transform: none;
        position: static;
      }
    }
  }

  // .initiatives-partners__swiper-pagination-mob
  &__swiper-pagination-mob {
    @include mobile-min {
      display: none;
    }
  }

  &__prev,
  &__next {
    outline: 0;
    text-decoration: none;
    user-select: none;
    cursor: pointer;
    flex-shrink: 0;
    width: 20.5px;
    height: 11px;
    transition: 0.3s;
    color: inherit;

    @include mobile {
      display: none;
    }

    svg {
      path {
        fill: transparent !important;
      }
    }

    @include desktop {
      width: 15.5px;
      height: 11px;
    }

    &:hover {
      &:not(.is-disabled) {
        color: $color-base;
      }
    }

    &.is-disabled {
      cursor: default;
      opacity: 0.35;
    }

    &.swiper-button-lock {
      display: none;
    }
  }

  &__bullets {
    display: none;
  }

  .swiper-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -15px;
    margin-right: -15px;
    @include mobile {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
