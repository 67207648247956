export const REGION_MONTH_OPTIONS = [
  { name: 'Все', id: '' },
  { name: 'Янв', id: 1 },
  { name: 'Фев', id: 2 },
  { name: 'Мар', id: 3 },
  { name: 'Апр', id: 4 },
  { name: 'Май', id: 5 },
  { name: 'Июн', id: 6 },
  { name: 'Июл', id: 7 },
  { name: 'Авг', id: 8 },
  { name: 'Сен', id: 9 },
  { name: 'Окт', id: 10 },
  { name: 'Ноя', id: 11 },
  { name: 'Дек', id: 12 },
]
