<template>
  <div v-if="contestantsList && contestantsList.length" class="talisman-contestants-list">
    <div class="talisman-contestants-list__inner">
      <div
        class="talisman-contestants-list__item"
        v-for="card in contestantsList"
        :key="`talisman-contestants-list__item-${card.id}`"
      >
        <TalismanContestantsCard
          class="talisman-contestants-list__card"
          :initialCardData="card"
          @showMore="openModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contestantsList: {
      type: Array,
    },
    actionVerifyPhone: {
      type: String,
      default: '',
    },
    actionVerifyCode: {
      type: String,
      default: '',
    },
  },
  computed: {
    getMixedList() {
      const result = JSON.parse(JSON.stringify(this.contestantsList))

      for (let i = result.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [result[i], result[j]] = [result[j], result[i]];

      }

      return result
    }
  },
  methods: {
    openModal(data) {
      this.$root.$refs.customModal.openModal(null, null, true, true);
      this.$root.$refs.customModal.passContent({
        name: 'talisman-contestants-detail',
        title: data.title,
        props: {
          actionVerifyPhone: this.actionVerifyPhone,
          actionVerifyCode: this.actionVerifyCode,
          actionRefreshCode: this.actionRefreshCode,
          cardData: data,
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-contestants-list';
$gap: 8px;

#{$b} {

  // .talisman-contestants-list__inner
  &__inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -#{$gap};

    @include mobile {
      display: block;
      margin: 0;
    }
  }

  // .talisman-contestants-list__item
  &__item {
    width: 100%;
    max-width: 50%;
    padding: 8px;

    @include mobile {
      padding: 0;
      max-width: 100%;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  // .talisman-contestants-list__card
  &__card {
    height: 100%;
  }
}
</style>
