<template>
  <div class="initives-feature-form">
    <QuillWyz
      class="initives-feature-form__editor"
      :initial-value="value"
      :toolbar-options="toolbarOptions"
      @change="onChangeWyz"
    />
    <ErrorFormWrapper
      :isVisible="Boolean(errorsAfterSubmit.length)"
      class="initives-feature-form__feedback-errors"
    >
      <div
        v-for="error in errorsAfterSubmit"
        :key="error.code"
        class="initives-feature-form__feedback-error"
      >
        <span v-html="error.message" />
      </div>
    </ErrorFormWrapper>
    <div class="initives-feature-form__actions">
      <CustomButton
        class="initives-feature-form__submit"
        theme="primary"
        size="md"
        :status="statusBtn"
        @click="submit"
      >
        Сохранить
      </CustomButton>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    initialValue: {
      type: String,
      default: ''
    },
    submitUrl: {
      type: String,
      default: '/'
    },
    initiativeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: this.initialValue,
      statusBtn: '',
      errorsAfterSubmit: []
    }
  },
  computed: {
    toolbarOptions() {
      return [
        ['bold', 'italic', 'underline', 'strike', 'link'],
        [{ 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'header': [2, false] }],
      ]
    },
  },
  methods: {
    onChangeWyz(html) {
      this.value = html
    },
    async submit() {
      this.statusBtn = 'loading'
      const options = {
        url: this.submitUrl,
        method: 'POST',
        data: {
          initiativeId: this.initiativeId,
          features: this.value
        }
      };

      const response = await axios(options);

      if (response.data.status === 'success') {
        this.succesResponse()
      } else {
        this.errorResponse(response)
      }
    },
    succesResponse() {
      this.statusBtn = 'success'
      this.errorsAfterSubmit = []
      this.$root.$emit('featuresUpdate', this.value)

      setTimeout(() => {
        this.$root.$refs.customModal.closeModal(null, null, true);
      }, 3000);
    },
    errorResponse(response) {
      this.statusBtn = ''
      this.errorsAfterSubmit = response.data.errors
    },
  }
}
</script>


<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: '.initives-feature-form';

#{$b} {

  // .initives-feature-form__editor
  &__editor {
    margin-bottom: 64px;

    @include mobile {
      margin-bottom: 32px;
    }
  }

  // .initives-feature-form__actions
  &__actions {
    @include mobile {
      text-align: center;
    }
  }

   // .initives-feature-form__feedback-error
  &__feedback-error {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 15px;

    @include mobile {
      font-size: 18px;
    }

    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
}
</style>
