<template>
  <div class="talisman-contestants-detail">
    <div class="talisman-contestants-detail__preview">
      <div v-if="isExistsSlider" class="talisman-contestants-detail__slider">
        <div
          class="talisman-contestants-detail__slider-btn talisman-contestants-detail__slider-btn--prev"
          :class="`talisman-contestants-detail__slider-btn--prev-${_uid}`"
        >
          <inline-svg class="talisman-contestants-detail__slider-icon" src="u-triangle-left" />
        </div>
        <swiper class="talisman-contestants-detail__swiper" :options="swiperOptions">
          <swiper-slide
            v-for="(img, i) in this.cardData.images"
            :key="`talisman-contestants-detail__swiper-slide-${i}`"
            class="talisman-contestants-detail__swiper-slide"
          >
            <img :src="img" class="talisman-contestants-detail__swiper-img">
          </swiper-slide>
        </swiper>
        <div
          class="talisman-contestants-detail__slider-btn talisman-contestants-detail__slider-btn--next"
          :class="`talisman-contestants-detail__slider-btn--next-${_uid}`"
        >
          <inline-svg class="talisman-contestants-detail__slider-icon" src="u-triangle-right" />
        </div>
        <div class="talisman-contestants-detail__slider-pagination">
          <div class="u-swiper-pagination" :class="`talisman-contestants-detail__pagination-${_uid}`"></div>
        </div>
      </div>
      <img v-else :src="getSinglePhoto" class="talisman-contestants-detail__img">
    </div>
    <div class="talisman-contestants-detail__content">
      <div class="talisman-contestants-detail__region tt-uppercase fw-500">
        {{ cardData.region }}
      </div>
      <div class="talisman-contestants-detail__description" v-html="cardData.description" />
      <!-- <div class="talisman-contestants-detail__footer">
        <div class="talisman-contestants-detail__votes tt-uppercase fw-500">
          {{ cardData.votesCaption }}
        </div>
        <CustomButton
          theme="primary"
          size="md"
          class="talisman-contestants-detail__btn"
          @click="goToVerify"
        >
          Проголосовать
        </CustomButton>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  inject: ['topMailRuHandler', 'ymHandler'],
  props: {
    cardData: {
      type: Object,
      required: true,
    },
    actionVerifyPhone: {
      type: String,
      default: '',
    },
    actionVerifyCode: {
      type: String,
      default: '',
    },
  },
  computed: {
    isExistsSlider() {
      return this.cardData.images && this.cardData.images.length > 1
    },
    getSinglePhoto() {
      return this.cardData.images[0] || this.cardData.mainImg
    },
    swiperOptions() {
      return {
        slidesPerView: 1,
        spaceBetween: 16,
        navigation: {
          prevEl: `.talisman-contestants-detail__slider-btn--prev-${this._uid}`,
          nextEl: `.talisman-contestants-detail__slider-btn--next-${this._uid}`,
          disabledClass: "talisman-contestants-detail__slider-btn--is-disabled"
        },
        pagination: {
          el: `.talisman-contestants-detail__pagination-${this._uid}`,
          type: "bullets",
          bulletClass: "u-swiper-pagination__bullet",
          bulletActiveClass: "u-swiper-pagination__bullet--is-active",
          clickable: true
        },
      }
    },
  },
  methods: {
    goToVerify() {
      this.topMailRuHandler({goal: 'vote'})
      this.ymHandler({ code: 93982301, type: 'reachGoal', operation: 'click_SendVote' })

      this.$root.$refs.customModal.openModal(null, null, true, true);
      this.$root.$refs.customModal.passContent({
        name: 'talisman-contestants-window',
        title: 'верификация',
        props: {
          actionVerifyPhone: this.actionVerifyPhone,
          actionVerifyCode: this.actionVerifyCode,
          actionRefreshCode: this.actionRefreshCode,
          workId: this.cardData.id,
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-contestants-detail';

#{$b} {
  font-family: $font-family-inter;
  display: grid;
  grid-template-columns: 400px auto;
  gap: 32px;

  @include tablet {
    display: block;
  }

  // .talisman-contestants-detail__preview
  &__preview {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
    padding: 68px 8px;

    @include tablet {
      aspect-ratio: unset;
      padding: 0 0 30px 0;
      margin-bottom: 24px;
    }
  }

  // .talisman-contestants-detail__slider
  &__slider {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // .talisman-contestants-detail__slider-btn
    &-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;

      color: $color-base-origin;
      transition: $transtion-default;
      cursor: pointer;

      svg {
        width: 100% !important;
        height: 100% !important;
        path {
          fill: transparent !important;
          stroke: currentColor;
        }
      }

      &:hover {
        @include mobile-min {
          color: $color-base;
        }
      }

      // .talisman-contestants-detail__slider-btn--is-disabled
      &--is-disabled {
        opacity: 0.5;
        pointer-events: none;
        touch-action: none;
      }

      @include tablet {
        display: none;
      }
    }

    // .talisman-contestants-detail__slider-icon
    &-icon {
      width: 10px !important;
      height: 16px !important;
    }

    // .talisman-contestants-detail__slider-pagination
    &-pagination {
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
    }
  }

  // .talisman-contestants-detail__swiper
  &__swiper {
    width: 100%;
    // margin: 0 30px;

    @include tablet {
      margin: 0;
    }

    // .talisman-contestants-detail__swiper-slide
    &-slide {
      height: auto;
    }

    // .talisman-contestants-detail__swiper-img
    &-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  // .talisman-contestants-detail__content
  &__content {
    display: flex;
    flex-direction: column;
  }

  // .talisman-contestants-detail__region
  &__region {
    letter-spacing: 1.6px;
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .talisman-contestants-detail__description
  &__description {
    word-break: break-word;

    &:not(:last-child) {
      margin-bottom: 32px;

      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  // .talisman-contestants-detail__footer
  &__footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      display: block;
    }
  }

  // .talisman-contestants-detail__votes
  &__votes {
    letter-spacing: 1.6px;

    @include mobile {
      margin-bottom: 16px;
    }
  }

  // .talisman-contestants-detail__btn
  &__btn {
    @include mobile {
      width: 100%;
    }
  }
}
</style>
