<template>
  <div class="lk-plan-documents">
    <h2 v-if="!requestId" class="lk-plan-documents__title tt-uppercase">
      региональный план
    </h2>
    <div class="lk-plan-documents__body">
      <div class="lk-plan-documents__inputs" :class="{'lk-plan-documents__inputs--is-invalid': isInvalidForm}">
        <div class="lk-plan-documents__field">
          <u-text-input
            class="u-text-input--smaller lk-plan-documents__input"
            name="fullName"
            label="Реквизиты*"
            v-model="form.document"
            placeholder="Номер и дата документа"
            :error="isRequiredErrorField('document')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('document')"
            class="lk-plan-documents__error-field"
          >
            <div>*Поля обязательны для заполнения </div>
          </ErrorFormWrapper>
        </div>
        <div class="lk-plan-documents__field">
          <u-text-input
            class="u-text-input--smaller lk-plan-documents__input"
            name="fullName"
            label="Ссылка на официальное опубликование акта"
            v-model="form.url"
            placeholder="Если план опубликован, прикрепите ссылку"
            :error="isUrlErrorField('url')"
          />
          <ErrorFormWrapper
            :isVisible="isUrlErrorField('url')"
            class="lk-plan-documents__error-field"
          >
            <div>Некорректная ссылка, ссылка должна начинаться с http/https</div>
          </ErrorFormWrapper>
        </div>
      </div>
      <div class="lk-plan-documents__file lk-plan-documents__field">
        <InputFile
          @change="onFileChange"
          :validFormat="validFormatFile"
          :maxSize="20"
          :error="isRequiredErrorField('file')"
          v-model="form.file"
        >
          Загрузить план*
        </InputFile>
        <ErrorFormWrapper
          :isVisible="isRequiredErrorField('file')"
          class="lk-plan-documents__error-field"
        >
          <div>Обязательное поле</div>
        </ErrorFormWrapper>
      </div>
      <ErrorFormWrapper class="lk-plan-documents__errors" :isVisible="true">
        <div>*Поля обязательны для заполнения </div>
      </ErrorFormWrapper>
      <ErrorFormWrapper
        class="lk-plan-documents__feedback-errors"
        :max-height="1000"
        :isVisible="Boolean(errorsAfterSubmit) && Boolean(errorsAfterSubmit.length)"
      >
        <div
          v-for="error in errorsAfterSubmit"
          :key="`lk-plan-documents__feedback-error-${error.code}`"
          class="lk-plan-documents__feedback-error"
        >
          <span v-html="error.message" />
        </div>
      </ErrorFormWrapper>
    </div>
    <div class="lk-plan-documents__actions">
      <CustomButton
        class="lk-plan-documents__btn"
        theme="primary"
        size="md"
        :status="statusBtn"
        @click="onSubmit"
      >
        Отправить
      </CustomButton>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mustBeValidUrl } from '/frontend/components/helpers/validate.js'

export default {
  mixins: [validationMixin],
  props: {
    formData: {
      type: Object,
      default: () => ({
        document: '',
        url: '',
        file: ''
      })
    },
    validFormatFile: {
      type: Array,
      default: () => [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
        'zip',
        'rar',
        '7z',
        'jar'
      ]
    },
    submitUrl: {
      type: String,
      default: ''
    },
    requestId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      form: this.formData,
      errorsAfterSubmit: [],
      isInvalidForm: false,
      statusBtn: ''
    }
  },
  validations: {
    form: {
      document: { required },
      url: { mustBeValidUrl },
      file: { required }
    }
  },
  methods: {
    resetForm() {
      for (let i in this.form) {
        this.form[i] = ''
      }
    },
    onFileChange(file) {
      this.form.file = file
    },
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.isInvalidForm = true
        return
      }

      this.statusBtn = 'loading'

      const data = new FormData();

      if (this.requestId) {
        data.append('idUpdate', this.requestId);
      }

      data.append('document', this.form.document);
      if (this.form.url) {
        data.append('url', this.form.url);
      }
      data.append('file', this.form.file);

      const options = {
        method: 'post',
        url: this.submitUrl,
        headers: {'content-type': 'multipart/form-data'},
        data,
      };

      const response = await axios(options);

      if (response.data.status === 'success') {
        this.succesResponse(response)
      } else {
        this.errorResponse(response)
      }
    },
    succesResponse(response) {
      this.statusBtn = 'success'
      this.$v.$reset();
      this.resetForm()
      this.errorsAfterSubmit = []

      setTimeout(() => {
        if (this.requestId) {
          this.$root.$refs.customModal.closeModal(null, null, true);
          this.$root.$emit('editedLkPlan', response.data.data.update_id, 'plan')
        } else {
          window.location.reload();
        }
        this.statusBtn = ''

      }, 3000);
    },
    errorResponse(response) {
      this.statusBtn = ''
      this.errorsAfterSubmit = response.data.errors
    },
    isRequiredErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].required
    },
    isUrlErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].mustBeValidUrl
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.lk-plan-documents';

#{$b} {

  // .lk-plan-documents__title
  &__title {
    font-family: $font-family-main-head;
    font-size: 44px;
    line-height: 1;
    font-weight: 500;

    margin: 0 0 32px 0;

    @include mobile {
      font-size: 28px;
      margin-bottom: 24px;
    }
  }

  // .lk-plan-documents__inputs
  &__inputs {
    display: grid;
    grid-template-columns: auto 400px;
    gap: 16px;
    margin-bottom: 24px;
    transition: $transtion-default;

    @include low-desktop {
      grid-template-columns: auto 350px;
    }

    @include tablet {
      grid-template-columns: 1fr 1fr;
    }

    @include mobile {
      display: block;
    }

    &--is-invalid {
      margin-bottom: 40px;
    }
  }

  // .lk-plan-documents__field
  &__field {
    position: relative;
  }

  // .lk-plan-documents__error-field
  &__error-field {
    position: absolute;
    top: 100%;
    width: 100%;
  }

  // .lk-plan-documents__feedback-errors
  &__feedback-errors {
    &.error-form-wrapper--is-visible {
      margin: 20px 0;
      max-height: 100px;
    }
  }

  // .lk-plan-documents__input
  &__input {
    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  // .lk-plan-documents__file
  &__file {
    margin-bottom: 24px;
  }

  // .lk-plan-documents__errors
  &__errors {
    margin: 26px 0 24px 0;

    @include mobile {
      margin: 22px 0 24px;
    }
  }

  // .lk-plan-documents__actions
  &__actions {
    @include mobile {
      text-align: center;
    }
  }

  // .lk-plan-documents__feedback-error
  &__feedback-error {
    font-size: 24px;
    padding: 4px;

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    @include mobile {
      font-size: 18px;
    }
  }
}
</style>
