<template>
  <div class="i-excursions-wrapper">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    submitTourFormUrl: {
      type: String,
      default: '/'
    },
    categoryOptions: {
      type: Array,
      default: () => []
    }
  },
  provide() {
    return {
      submitUrl: this.submitTourFormUrl,
      categoryOptions: this.categoryOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.i-excursions-wrapper';

#{$b} {
  position: relative;
}
</style>
