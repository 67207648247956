<template>
  <div class="primary-item" :class="primaryItemClassStr">
    <div class="primary-item__inner">
      <div class="primary-item__header">
        <img v-if="isAddtionalCard" :src="card.additionalLogo" class="primary-item__additional-logo">
        <div v-else class="primary-item__count tt-uppercase">{{ counter(index) }}</div>
      </div>
      <p class="primary-item__text" v-html="card.text" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    card: {
      type: Object,
      require: true
    }
  },
  methods: {
    counter(index) {
      const counter = index + 1
      return counter >= 10 ? counter : `0${counter}`
    }
  },
  computed: {
    isAddtionalCard() {
      return Boolean(this.card.additionalLogo)
    },
    primaryItemClassStr() {
      const prefix = 'primary-item--'
      const classes = []

      if (this.isAddtionalCard) {
        classes.push(prefix + 'additional')
      }
      if (this.card.size) {
        classes.push(prefix + this.card.size)
      }

      return classes.join(" ")
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.primary-item';

#{$b} {
  height: auto;
  font-size: 16px;
  line-height: 1.3;
  color: $white-true;
  font-weight: 400;

  width: 100%;
  max-width: calc(100% / 3);
  padding: 8px;

  @include low-desktop {
    max-width: calc(100% / 2);
  }
  &:not(:last-child) {
    @include mobile {
      margin-bottom: 24px;
    }
  }
  @include mobile {
    max-width: 100%;
    padding: 0;
  }

  // .primary-item--lg
  &--lg {
    max-width: calc(100% / 3 * 2);

    @include low-desktop {
      max-width: 100%;
    }
  }

  // .primary-item--additional
  &--additional {
    color: $color-primary;
    font-weight: bold;
  }

  // .primary-item__inner
  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    background-color: $color-base;
    border-radius: 16px;

    #{$b}--additional & {
      border: 2px solid $color-base;
      position: relative;
      background-color: transparent;

      &:after {
        content: "";
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        position: absolute;
        left: 8px;
        top: 8px;
        border-radius: 8px;
        opacity: 0.1;
        background: $gradient-primary-light-2;
        pointer-events: none;
        touch-action: none;
      }
    }
  }

  &:not(:last-child) {

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .primary-item__count
  &__count {
    font-weight: 500;
  }

  // .primary-item__text
  &__text {
    margin: 0;

    #{$b}--lg & {
      max-width: 577px;
    }
  }

  // .primary-item__header
  &__header {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .primary-item__additional-logo
  &__additional-logo {
    height: auto;
    max-width: 100%;
    width: auto;

    @include mobile {
      max-height: 73px;
      max-width: 140px;
    }
  }
}
</style>
