<template>
  <div ref="initiativesPartnersForm" class="initiatives-partners-form">
    <div class="initiatives-partners-form__fields" :class="{'initiatives-partners-form__fields--gap-large': isInvalidForm}">
      <div class="initiatives-partners-form__field">
        <u-text-input
          class="u-text-input--smaller"
          name="name"
          label="Наименование*"
          v-model="form.name"
          placeholder="..."
          :error="isRequiredErrorField('name')"
        />
        <ErrorFormWrapper
          :isVisible="isRequiredErrorField('name')"
          class="initiatives-partners-form__error-field"
        >
          <div>Обязательное поле</div>
        </ErrorFormWrapper>
      </div>
      <div class="initiatives-partners-form__field u-select u-select--small">
        <div class="initiatives-partners-form__label">
          Тип партнера
        </div>
        <div>
          <v-select
            v-model="form.partnerType"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="partnerTypeOptions"
            placeholder="..."
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
      </div>
      <div class="initiatives-partners-form__field">
        <div class="initiatives-partners-form__label">
          Белый логотип (svg, png. 500кб макс.)
        </div>
        <div class="initiatives-partners-form__field-el">
          <InputFile
            v-model="form.logoWhite"
            :validFormat="validFormatFile"
            :maxSize="0.5"
            @change="logoWhiteChange"
          >
            Загрузить
          </InputFile>
        </div>
      </div>
      <div class="initiatives-partners-form__field">
        <div class="initiatives-partners-form__label">
          Темный логотип (jpg, png. 500кб макс.)
        </div>
        <div class="initiatives-partners-form__field-el">
          <InputFile
            @change="logoBlackChange"
            v-model="form.logoBlack"
            :validFormat="validFormatFile"
            :maxSize="0.5"
          >
            Загрузить
          </InputFile>
        </div>
      </div>
      <div class="initiatives-partners-form__field">
        <u-text-input
          class="u-text-input--smaller"
          name="name"
          label="Ссылка на сайт"
          v-model="form.link"
          placeholder="..."
          :error="isUrlErrorField('link')"
        />
        <ErrorFormWrapper
          :isVisible="isUrlErrorField('link')"
          class="initiatives-partners-form__error-field"
        >
          <div>Некорректная ссылка, ссылка должна начинаться с http/https</div>
        </ErrorFormWrapper>
      </div>
    </div>
    <div class="initiatives-partners-form__footer">
      <ErrorFormWrapper class="initiatives-partners-form__errors" :isVisible="true">
        <div>*Поля обязательны для заполнения </div>
      </ErrorFormWrapper>
      <ErrorFormWrapper
        class="initiatives-partners-form__feedback-errors"
        :isVisible="Boolean(errorsAfterSubmit) && Boolean(errorsAfterSubmit.length)"
      >
        <div
          v-for="error in errorsAfterSubmit"
          :key="`initiatives-partners-form__feedback-error-${error.code}`"
          class="initiatives-partners-form__feedback-error"
        >
          {{error.message}}
        </div>
      </ErrorFormWrapper>
      <div class="initiatives-partners-form__actions">
        <CustomButton
          class="initiatives-partners-form__btn-submit"
          theme="primary"
          size="md"
          :status="statusBtn"
          @click="onSubmit"
        >
          Отправить
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mustBeValidUrl } from '/frontend/components/helpers/validate.js'
import {
  getResetForm,
} from '/frontend/components/helpers/formHelpers.js'

export default {
  mixins: [validationMixin],
  components: {vSelect},
  props: {
    partnerTypeOptions: {
      type: Array,
      default: () => []
    },
    initialForm: {
      type: Object,
      default: () => ({
        name: '',
        logoWhite: '',
        logoBlack: '',
        partnerType: '',
        link: '',
      })
    },
    initiativeId: {
      type: String,
      default: ''
    },
    validFormatFile: {
      type: Array,
      default: () => [
        'svg',
        'png',
      ]
    },
    actionSave: {
      type: String,
      default: ''
    },
    idUpdate: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      form: JSON.parse(JSON.stringify(this.initialForm)),
      isInvalidForm: false,
      statusBtn: '',
      errorsAfterSubmit: [],
      OpenIndicator: {
        render: createElement => createElement('span')
      },
    }
  },
  validations: {
    form: {
      name: { required },
      link: { mustBeValidUrl }
    }
  },
  computed: {
    getFormData() {
      const data = new FormData()

      data.append('initiativeId', this.initiativeId);

      for (let field in this.form) {
        data.append(field, this.form[field])
      }

      if (this.idUpdate) {
        data.append('idUpdate', this.idUpdate)
      }
      if (this.initialForm.logoWhite && !this.form.logoWhite) {
        data.append('logoWhiteDelete', true)
      }
      if (this.initialForm.logoBlack && !this.form.logoBlack) {
        data.append('logoBlackDelete', true)
      }

      return data
    },
  },
  methods: {
    isRequiredErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].required
    },
    isUrlErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].mustBeValidUrl
    },
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.scrollToForm()
        this.isInvalidForm = true
        return
      }

      this.statusBtn = 'loading'

      const options = {
        url: this.actionSave,
        method: 'POST',
        data:  this.getFormData
      };

      const response = await axios(options)

      if (response.data.status === 'success') {
        this.succesResponse(response)
      } else {
        this.errorResponse(response)
      }
    },
    succesResponse(response) {
      this.statusBtn = 'success'
      this.$v.$reset();
      this.resetForm()
      this.errorsAfterSubmit = []
      this.$root.$emit('editedPartnersList', { type: this.idUpdate ? 'update' : 'add', data: response.data.data.partner })

      setTimeout(() => {
        this.$root.$refs.customModal.closeModal(null, null, true);
        this.statusBtn = ''
      }, 3000);
    },
    errorResponse(response) {
      this.statusBtn = ''
      this.errorsAfterSubmit = response.data.errors
    },
    logoWhiteChange(file) {
      this.form.logoWhite = file
    },
    logoBlackChange(file) {
      this.form.logoBlack = file
    },
    resetForm() {
      this.form = getResetForm(this.form)
    },
    scrollToForm(to = 'start') {
      this.$refs.initiativesPartnersForm.scrollIntoView({
        behavior: "smooth",
        block: to,
      })
    },
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.initiatives-partners-form';

#{$b} {
  // .initiatives-partners-form__field
  &__field {

    // .initiatives-partners-form__field--full-width
    &--full-width {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  // .initiatives-partners-form__fields
  &__fields {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 16px));
    gap: 32px;
    transition: $transtion-default;

    @include tablet {
      grid-template-columns: repeat(2, calc(50% - 8px));
      gap: 16px;
    }

    @include mobile {
      display: block;
    }

     // .initiatives-partners-form__fields--gap-large
    &--gap-large {
      gap: 42px 32px;

      @include tablet {
        gap: 42px 16px;
      }
    }
  }

  // .initiatives-partners-form__field
  &__field {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    position: relative;

    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }

    // .initiatives-partners-form__field-el
    &-el {
      margin-left: 8px;
    }
  }

  // .initiatives-partners-form__error-field
  &__error-field {
    @include mobile-min {
      position: absolute;
      top: 100%;
    }
  }

  // .initiatives-partners-form__label
  &__label {
    font-family: $font-family-inter;
    font-size: 12px;
    line-height: 1.3;
    text-transform: initial;
    margin-bottom: 8px;
    margin-left: 8px;
    font-weight: normal;
  }

  // .initiatives-partners-form__footer
  &__footer {
    margin-top: 32px;

    @include mobile {
      margin-top: 24px;
    }
  }

  // .initiatives-partners-form__actions
  &__actions {
    margin-top: 64px;

    @include mobile {
      text-align: center;
      margin-top: 32px;
    }
  }

  // .initiatives-partners-form__feedback-error
  &__feedback-error {
    font-size: 24px;
    margin-bottom: 15px;

    @include mobile {
      font-size: 18px;
    }

    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
}
</style>
