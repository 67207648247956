<template>
  <div>
    <ul class="events-filter__month-list">
      <li v-for="(item, i) in filters" :key="i">
        <div
          class="events-filter__month-item"
          :class="{ 'is-active': item.isActive }"
          @click.prevent="toggleFilter(item, i)"
        >
          <span>{{ item.name }}</span>
          <inline-svg v-if="item.isActive && i > 0" src="close"/>
        </div>
      </li>
    </ul>
    <div ref="table" class="lk-ratings__fixed">
      <div class="lk-ratings__wrapper">
        <div class="lk-ratings">
          <div class="lk-ratings__row lk-ratings__row_head">
            <div class="lk-ratings__col">Регионы участники рейтинга</div>
            <div
              class="lk-ratings__col lk-ratings__col_toggler" v-for="(item, i) in sortItems"
              :key="i"
              @click="sortSet(i)"
              :class="{_active: sortBy === i}">
              <div class="lk-ratings__sort" :class="{_desc: item.sortType === 'desc'}"></div>
              <span v-html="item.name"></span>
            </div>
          </div>
          <div class="lk-ratings__row" v-for="(item, i) in countLimit ? sortedItems.slice(0,10) : sortedItems" :key="i">
            <div class="lk-ratings__col">{{item.name}}</div>
            <div class="lk-ratings__col">{{item.values[0] | formatNumberLimit}}</div>
            <div class="lk-ratings__col">{{item.values[1] | formatNumber}}</div>
            <div class="lk-ratings__col">{{item.values[2] | formatNumber}}</div>
          </div>
        </div>
      </div>
      <div class="lk-ratings__wrapper _clone">
        <div class="lk-ratings">
          <div class="lk-ratings__row lk-ratings__row_head">
            <div class="lk-ratings__col">Регионы участники рейтинга</div>
            <div
              class="lk-ratings__col lk-ratings__col_toggler" v-for="(item, i) in sortItems"
              :key="i"
              @click="sortSet(i)"
              :class="{_active: sortBy === i}">
              <div class="lk-ratings__sort" :class="{_desc: item.sortType === 'desc'}"></div>
              <span v-html="item.name"></span>
            </div>
          </div>
          <div class="lk-ratings__row" v-for="(item, i) in countLimit ? sortedItems.slice(0,10) : sortedItems" :key="i">
            <div class="lk-ratings__col">{{item.name}}</div>
            <div class="lk-ratings__col">{{item.values[0] | formatNumberLimit}}</div>
            <div class="lk-ratings__col">{{item.values[1] | formatNumber}}</div>
            <div class="lk-ratings__col">{{item.values[2] | formatNumber}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="lk-ratings__note">*Основной показатель рейтинга – количество участников мероприятий в регионе.</div>
    <a v-if="sortedItems.length > 10" href="#" @click.prevent="countLimitToggle" class="button button--base">{{ countLimit ? 'ВСЕ РЕГИОНЫ' : 'Скрыть'}}</a>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    name: "LkRaportRatings",
    props: {
      momentFilterList: {
        type: Array,
        default: () => [
          {
            filter: 'all',
            name: 'все',
            isActive: true
          },
          {
            filter: 'today',
            name: 'на текущий момент',
            isActive: false
          },
          {
            filter: 'week',
            name: 'за предыдущую неделю',
            isActive: false
          },
          {
            filter: 'month',
            name: 'за предыдущий месяц',
            isActive: false
          }
        ]
      },
      initItems: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        sortBy: 0,
        countLimit: true,
        filters: [],
        sortItems: [
          {
            name: 'Непосредственное участие <br> в онлайн активностях (% от количества населения региона)',
            sortType: 'desc'
          },
          {
            name: 'Непосредственное участие <br> в оффлайн активностях (% от количества населения региона)',
            sortType: 'desc'
          },
          {
            name: 'Охват профессионального сообщества (% от количества профессионального сообщества в регионе)',
            sortType: 'desc'
          }
        ],
        items: []
      }
    },
    computed: {
      sortedItems() {
        const vm = this;
        let result = vm.items.sort(function (a, b) {
          return parseFloat(a.values[vm.sortBy]) - parseFloat(b.values[vm.sortBy]);
        });
        if (vm.sortItems[vm.sortBy].sortType === 'asc') {
          return result;
        } else {
          return result.reverse();
        }
      }
    },
    mounted() {
      this.filters = this.momentFilterList;
      this.items = this.initItems;
    },
    methods: {
      sortSet(sortBy) {
        this.sortBy = sortBy;
        if (this.sortItems[sortBy].sortType === 'asc') {
          this.sortItems[sortBy].sortType = 'desc';
        } else {
          this.sortItems[sortBy].sortType = 'asc';
        }
      },
      async toggleFilter(el, i) {
        const options = {
          url: '/bitrix/services/main/ajax.php?mode=class&c=dalee:event.region.rating&action=filter',
          method: 'POST'
        };

        if (this.filters[i].isActive === true) {
          this.filters[i].isActive = !this.filters[i].isActive;
          this.filters[0].isActive = true;
          const {data} = await axios({...options, data: {period: this.filters[0].filter}});
          this.items = data.data;
        } else {
          this.filters.forEach(item => {
            item.isActive = false;
          });
          this.filters[i].isActive = true;
          const {data} = await axios({...options, data: {period: this.filters[i].filter}});
          this.items = data.data;
        }
      },
      countLimitToggle() {
        if (!this.countLimit) {
          this.$nextTick(() => {
            this.$smoothScroll({
              scrollTo: this.$refs.table,
              duration: 500,
              offset: -50,
            })
          });
        }
        this.countLimit = !this.countLimit;
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/base/includes";
  $mobileOffset: 145px;

  .lk-ratings {
    padding: 30px 0 40px;
    @include mobile {
      min-width: #{$container-pd-left-mobile + 768};
      padding-right: $container-pd-left-mobile;
    }

    &__fixed {
      @include mobile {
        position: relative;
      }
    }

    &__wrapper {
      @include mobile {
        position: relative;
        padding-left: $container-pd-left-mobile;
        margin-left: -#{$container-pd-left-mobile};
        margin-right: -#{$container-pd-left-mobile};
        overflow-x: auto;
      }

      &:not(._clone) {
        @include mobile {
          margin-left: $mobileOffset;
          padding-left: 0;
          .lk-ratings {
            justify-content: flex-start;
            margin-left: -$mobileOffset;
          }
          .lk-ratings__row_head {
            border-bottom: 0;
          }
          .lk-ratings__col {
            &:not(:first-child) {
              margin-right: 25px;
            }
            &:last-child {
              margin-right: 0;
            }
            &:first-child {
              visibility: hidden;
              max-width: $mobileOffset;
              width: 100%;
            }
          }
        }
      }

      &._clone {
        display: none;
        pointer-events: none;
        @include mobile {
          position: absolute;
          top: 0;
          left: 0;
          display: block;

          .lk-ratings__col {
            &:first-child {
              max-width: $mobileOffset;
              width: 100%;
            }
            &:nth-child(n+2) {
              visibility: hidden;
            }
          }
        }
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;

      &:nth-child(2) {
        color: $color-base;
      }

      &:nth-child(3),
      &:nth-child(4) {
        color: #8F8F8F;
      }

      &_head {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        border-bottom: 1px solid rgba($color-black, .2);
        margin-bottom: 18px;
      }
    }

    &__col {
      position: relative;
      width: calc((100% - 120px) / 4);
      @include tablet {
        font-size: 14px;
      }

      &_toggler {
        cursor: pointer;
      }

      &._active {
        color: $color-base;
      }
    }

    &__sort {
      &:before,
      &:after {
        content: '';
        position: absolute;
        left: -15px;
        z-index: 1;
        width: 0;
        height: 0;
        border-style: solid;
      }

      &:before {
        top: 2px;
        border-width: 0 3.5px 6.1px 3.5px;
        border-color: transparent transparent #007bff transparent;
      }

      &:after {
        top: 12px;
        border-width: 6.1px 3.5px 0 3.5px;
        border-color: rgba(#007bff, .5) transparent transparent transparent;
      }

      &._desc {
        &:before {
          border-color: transparent transparent rgba(#007bff, .5) transparent;
        }

        &:after {
          border-color: #007bff transparent transparent transparent;
        }
      }
    }

    &__note {
      font-size: 12px;
      line-height: 20px;
      color: rgba($color-black, .5);
      margin-bottom: 32px;
    }

    .button {
      min-width: 240px;
    }
  }
</style>
