<template>
  <div class="u-select-filters">
    <component
      :is="getTag"
      ref="modalWrapper"
      class="u-select-filters__mob-wrapper"
      :class="`u-select-filters__mob-wrapper--${mobTheme}`"
      v-show="isVisibleMobWrapper"
    >
      <div class="u-select-filters__inner">
        <div
          class="u-select u-select--wrap-text u-select-filters__item"
          v-for="select in getList"
          :key="`u-select-filters__select-${select.key}`"
        >
          <div class="u-select__label">
            {{ select.label }}
          </div>
          <v-select
            v-model="selectedOptions[select.key]"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="filterOptions[select.key]"
            @input="(val) => onChange(val, select.key)"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
      </div>
    </component>
    <CustomButton size="xl" :theme="getBtnTheme" class="u-select-filters__btn g-visible-mobile" @click="showFilter">
      {{ getFilterText }}
      <inline-svg src="filter-icon" class="u-select-filters__btn-icon"/>
    </CustomButton>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  inject: ['breakpoint'],
  components: { vSelect },
  props: {
    selectList: {
      type: Array,
      default: () => []
    },
    selectedOptions: {
      type: Object,
      default: () => ({})
    },
    filterOptions: {
      type: Object,
      default: () => ({})
    },
    mobTheme: {
      type: String,
      default: 'toggle' // modal, toggle
    }
  },
  data() {
    return {
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      isToggled: false
    }
  },
  computed: {
    getTag() {
      return this.breakpoint.isMobile && this.mobTheme === 'modal' ? 'modal' : 'div'
    },
    getList() {
      return this.selectList.filter(item => !(Object.hasOwn(item, 'isHidden') && item.isHidden === true))
    },
    getMobileShowHandler() {
      return {
        modal: this.showModal,
        toggle: this.onToggle,
      }
    },
    isVisibleMobWrapper() {
      if (!this.breakpoint.isMobile) {
        return true
      }
      return this.mobTheme === 'modal' ? true : this.isToggled
    },
    getBtnTheme() {
      return this.isToggled ? 'outline-primary' : 'primary'
    },
    getFilterText() {
      return this.mobTheme === 'modal' || !this.isToggled ? 'Фильтр' : 'Закрыть'
    }
  },
  methods: {
    onChange(id, key) {
      this.$emit('onChangeSelect', id, key)
    },
    showFilter() {
      this.getMobileShowHandler[this.mobTheme]()
    },
    showModal() {
      this.$refs.modalWrapper.openModal()
    },
    onToggle() {
      this.isToggled = !this.isToggled
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-select-filters';
$gap: 32px;

#{$b} {

  // .u-select-filters__inner
  &__inner {
    display: grid;
    grid-template-columns: repeat(3, calc(((100% - ($gap * 2))) / 3));
    gap: $gap;

    @include low-desktop {
      grid-template-columns: repeat(2, calc(((100% - $gap)) / 2));
    }

    @include mobile {
      display: block;
      padding-top: 28px;
    }
  }

  // .u-select-filters__mob-wrapper
  &__mob-wrapper {

    &--toggle {
      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  // .u-select-filters__item
  &__item {
    @include mobile {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  // .u-select-filters__btn
  &__btn {
    width: 100%;

    // .u-select-filters__btn-icon
    &-icon {
      max-height: 24px;
      height: auto;
      width: 20px;
      margin-left: 10px;
    }
  }
}

</style>
