<template>
  <div class="lk-plan-offer">
    <h2 class="lk-plan-offer__title tt-uppercase">
      Предложить
    </h2>
    <div class="lk-plan-offer__tabs">
      <UTabs>
        <Tab title="Мероприятие">
          <LkPlanProject class="lk-plan-offer__body" :list="eventsList">
            Мероприятие — массовое событие (конгресс, форум, фестиваль, выставка, культурное, спортивное или иное Мероприятие), направленное на решение задач Десятилетия науки и технологий (далее — ДНТ).
          </LkPlanProject>
          <LkPlanForm
            title="Заполните форму"
            type="event"
            :initiativesOptions="initiativesOptions"
            :eventLevelOptions="eventLevelOptions"
            :regionOptions="regionOptions"
            :partnerOptions="partnerOptions"
            :meetingFormatOptions="meetingFormatOptions"
            :targetAudienceOptions="targetAudienceOptions"
            :eventObjectivesOptions="eventObjectivesOptions"
            submitUrl="/bitrix/services/main/ajax.php?mode=class&c=dalee:event_form&action=addEvent"
            isCreate
            :eventDrafts="eventDrafts"
            :isPartnerForm="isPartnerForm"
            :allowDraftsSaving="allowDraftsSaving"
            :multipleInitiative="multipleInitiative"
          />
        </Tab>
        <Tab title="Проект">
          <LkPlanProject class="lk-plan-offer__body" :list="projectsList">
            Проект — работа, направленная на создание уникального продукта, способствующего достижению задач Десятилетия науки и технологий (далее — ДНТ) и (или) включению научной повестки в образовательный процесс, социальную, бытовую, культурную и иные сферы, непосредственно не связанные с осуществлением научно-исследовательской деятельности.
          </LkPlanProject>
          <LkPlanForm
            title="Заполните форму"
            type="project"
            :initiativesOptions="initiativesOptions"
            :eventLevelOptions="eventLevelOptions"
            :regionOptions="regionOptions"
            :partnerOptions="partnerOptions"
            :meetingFormatOptions="meetingFormatOptions"
            :targetAudienceOptions="targetAudienceOptions"
            :eventObjectivesOptions="eventObjectivesOptions"
            submitUrl="/bitrix/services/main/ajax.php?mode=class&c=dalee:event_form&action=addEvent"
            isCreate
            :eventDrafts="eventDrafts"
            :isPartnerForm="isPartnerForm"
            :allowDraftsSaving="allowDraftsSaving"
            :multipleInitiative="multipleInitiative"
          />
        </Tab>
      </UTabs>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    eventsList: {
      type: Array,
      default: () => [
        {
          title: 'Мероприятие должно:',
          list: [
            {
              text: 'Быть направлено на решение задач ДНТ;',
              additionalLogo: '',
              size: '',
            },
            {
              text: 'Не проводиться ранее или предполагать существенную трансформацию существующего мероприятия;',
              additionalLogo: '',
              size: '',
            },
            {
              text: 'Иметь широкий охват аудитории;',
              additionalLogo: '',
              size: '',
            },
            {
              text: 'Быть оформлено в фирменном стиле ДНТ;',
              additionalLogo: '',
              size: '',
            },
            {
              text: 'Способствовать появлению чувства гордости за отечественную науку среди широких слоев населения, предоставляя обществу объективную информацию об измеримых успехах и достижениях российской науки, которые могут влиять на жизнь людей.',
              additionalLogo: '',
              size: '',
            },
            {
              text: 'В качестве примера мероприятия можно привести Фестиваль науки 0+.',
              additionalLogo: '/frontend/assets/img/logo/science-plus-logo.svg',
              size: '',
            },
          ]
        },
      ]
    },
    projectsList: {
      type: Array,
      default: () => [
        {
          title: 'ПРОЕКТ МОЖЕТ:',
          list: [
            {
              text: 'Быть составной частью одной из инициатив (например, проект по созданию национальных научных троп может быть частью инициативы по развитию научно-популярного туризма);',
              additionalLogo: '',
              size: '',
            },
            {
              text: 'Включаться в план проведения ДНТ как самостоятельный структурный элемент (при соответствующем масштабе проекта);',
              additionalLogo: '',
              size: '',
            },
            {
              text: 'Быть трансформацией текущей деятельности, обеспеченной ресурсами, с учетом ее ориентации на сферу исследований и разработок (например: театральные проекты, выставки, кинопоказы, детская и молодежная инфраструктура, создание городской и транспортной инфраструктуры).',
              additionalLogo: '',
              size: '',
            },
          ]
        },
        {
          title: 'ПРОЕКТ ДОЛЖЕН:',
          list: [
            {
              text: 'Соответствовать целям и задачам ДНТ;',
              additionalLogo: '',
              size: '',
            },
            {
              text: 'Реализовываться впервые или являться масштабированием успешных практик;',
              additionalLogo: '',
              size: '',
            },
            {
              text: 'Иметь четкие сроки выполнения проекта, с учетом необходимости получения конечного продукта в период ДНТ;',
              additionalLogo: '',
              size: '',
            },
            {
              text: 'Способствовать появлению чувства гордости за отечественную науку среди широких слоев населения, предоставляя обществу объективную информацию об измеримых успехах и достижениях российской науки, которые могут влиять на жизнь людей.',
              additionalLogo: '',
              size: '',
            },
            {
              text: 'В качестве примера можно привести идею проекта по созданию «научных» детских площадок на территории Российской Федерации.',
              additionalLogo: '/frontend/assets/img/logo/man-with-wings.svg',
              size: 'lg',
            },
          ]
        },
      ]
    },
    // для LkPlanForm
    initiativesOptions: {
      type: Array,
      default: () => []
    },
    eventLevelOptions: {
      type: Array,
      default: () => []
    },
    regionOptions: {
      type: Array,
      default: () => []
    },
    partnerOptions: {
      type: Array,
      default: () => []
    },
    meetingFormatOptions: {
      type: Array,
      default: () => []
    },
    targetAudienceOptions: {
      type: Array,
      default: () => []
    },
    eventObjectivesOptions: {
      type: Array,
      default: () => []
    },
    eventDrafts: {
      type: Array,
      default: () => []
    },
    isPartnerForm: {
      type: Boolean,
      default: false
    },
    allowDraftsSaving: {
      type: Boolean,
      default: false
    },
    multipleInitiative: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.lk-plan-offer';

#{$b} {
  // .lk-plan-offer__title
  &__title {
    font-family: $font-family-main-head;
    color: $black-true;
    font-size: 44px;
    font-weight: 600;
    line-height: 100%;
    margin: 0 0 32px 0;

    @include mobile {
      font-size: 28px;
      margin-bottom: 24px;
    }
  }

  // .lk-plan-offer__body
  &__body {
    margin-bottom: 64px;

    @include mobile {
      margin-bottom: 48px;
    }
  }
}
</style>
