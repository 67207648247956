<template>
    <div class="brandstyle-logo">
      <div class="brandstyle-logo__img">
        <img
          :src="logo.img"
          :alt="logo.text"
        />
      </div>
      <div
        class="brandstyle-logo__text"
        :class="{'_color-white-true': logo.darkTheme}"
        v-html="logo.text"
      ></div>
    </div>
</template>

<script>
export default {
  props: {
    logo: {
      type: Object,
      require: true
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.brandstyle-logo';

#{$b} {
  display: block;
  height: 100%;
  font-size: 16px;
  line-height: 130%;
  color: $black-true;
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  @include low-desktop-min {
    height: 334px;
  }

  &__img {
    display: block;
    height: 100%;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__text {
    position: absolute;
    left: 24px;
    bottom: 24px;
    max-width: calc(100% - 48px);

    @include tablet {
      bottom: 16px;
      left: 20px;
      max-width: calc(100% - 40px);
    }

    .button-rounded {
      margin-top: 12px;
    }
  }
}
</style>
