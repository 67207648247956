<template>
  <div class="video-slider" ref="wrapper">
    <swiper
      ref="videoSwiper"
      :options="swiperOptions"
      :style="{marginRight: rightOffset}"
    >
      <swiper-slide class="video-slider__wrapper" v-for="(item, index) in slides" :key="item.id">
        <div class="video-slider__slide" @click="lightboxIndex = index">
          <div class="video-slider__cover" :style="{ backgroundImage: 'url(\''+item.img+'\')'}">
            <inline-svg src="play-btn"></inline-svg>
          </div>
          <span class="video-slider__title" v-html="item.title"></span>
          <span class="video-slider__label">
            {{ item.label }} <inline-svg src="arrow-external"></inline-svg>
          </span>
        </div>
      </swiper-slide>
    </swiper>
    <div class="video-slider__controls-wrapper">
      <div class="video-slider__controls">
        <div class="video-slider__prev">
          <inline-svg src="arrow-left" />
        </div>
        <div class="video-slider__count swiper-pagination"></div>
        <div class="video-slider__next">
          <inline-svg src="arrow-right" />
        </div>
      </div>
      <div class="video-slider__bullets g-pagination-bullets"></div>
    </div>

    <CoolLightBox
      :items="slides"
      :index="lightboxIndex"
      :loop="false"
      :slideshow="false"
      :gallery="false"
      thumbs-position="bottom"
      @close="onLightboxClose"
      @on-open="onLightboxOpen"
    />
  </div>
</template>

<script>
  import InlineSvg from "./InlineSvg";
  import  CoolLightBox from 'vue-cool-lightbox'
  import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
  import scrollbarWidth from './helpers/scrollbarWidth'
  export default {
    name: "VideoSlider",
    components: {InlineSvg, CoolLightBox},
    props: {
      slides: {
        type: Array,
        default: () => [
          {
            id: '0',
            img: '/frontend/assets/img/projects/cover.jpg',
            title: 'Остановить пандемию',
            label: 'Эпизод 1',
            src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk'
          },
          {
            id: '1',
            img: '/frontend/assets/img/projects/cover.jpg',
            title: '2',
            label: 'Эпизод 2',
            src: 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4'
          },
          {
            id: '3',
            img: '/frontend/assets/img/projects/cover.jpg',
            title: '3',
            label: 'Эпизод 3',
            src: 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4'
          },
          {
            id: '4',
            img: '/frontend/assets/img/projects/cover.jpg',
            title: '4',
            label: 'Эпизод 4',
            src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk'
          },
          {
            id: '5',
            img: '/frontend/assets/img/projects/cover.jpg',
            title: '5',
            label: 'Эпизод 5',
            src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk'
          },
          {
            id: '6',
            img: '/frontend/assets/img/projects/cover.jpg',
            title: '6',
            label: '6',
            src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk'
          },
        ]
      }
    },
    data() {
      return {
        swiperOptions: {
          slidesPerView: "auto",
          spaceBetween: 0,
          loop: false,
          watchOverflow: true,
          watchSlidesVisibility: true,
          slideVisibleClass: "swiper-slide-visible",
          navigation: {
            prevEl: ".video-slider__prev",
            nextEl: ".video-slider__next",
            disabledClass: "is-disabled"
          },
          pagination: {
            el: ".video-slider__bullets",
            type: "bullets",
            bulletClass: "g-pagination-bullet",
            bulletActiveClass: "is-active",
            clickable: true
          },
          breakpoints: {
            1024: {
              pagination: {
                el: ".video-slider__count",
                type: "fraction"
              }
            }
          }
        },
        rightOffset: 0,
        lightboxIndex: null,
        htmlBlock: undefined
      }
    },
    mounted() {
      let offset = window.innerWidth - (this.$refs.wrapper.getBoundingClientRect().left + this.$refs.wrapper.offsetWidth) - scrollbarWidth();
      if (offset > 0) {
        this.rightOffset = -offset + 'px'
      }
      this.htmlBlock = document.querySelector("html");
    },
    methods: {
      onLightboxOpen() {
        this.htmlBlock.style.overflow = "hidden";
      },
      onLightboxClose() {
        this.htmlBlock.style.overflow = "";
        this.lightboxIndex = null;
      },
    }
  }
</script>

<style lang="scss">
  @import "../scss/base/includes";
  .video-slider {
    position: relative;
    &__wrapper {
      width: 270px;
      margin-right: 40px;

      @include low-desktop {
        width: 250px;
        margin-right: 30px;
      }

      @include tablet {
        width: 206px;
      }
    }

    &__slide {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      text-decoration: none;

      &:hover {
        .video-slider__cover {
          .inline-svg {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
          }
        }
      }
    }

    &__cover {
      position: relative;
      @include bg-cover;
      width: 100%;
      height: 145px;
      margin-bottom: 14px;

      @include low-desktop {
        height: 136px;
      }

      @include tablet {
        height: 111px;
      }

      .inline-svg {
        width: 33%;
        color: $white-true;
        margin: 0 auto;
        opacity: 0;
        visibility: hidden;
        transform: scale(.85);
        transition: .2s;
      }
    }

    &__title {
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $white-true;
      margin-bottom: 7px;
    }

    &__label {
      display: flex;
      align-items: center;
      color: rgba($white-true, .5);

      @include tablet {
        font-size: 14px;
      }

      .inline-svg {
        display: inline-block;
        flex-shrink: 0;
        width: 14px;
        height: 15px;
        margin-left: 6px;
        path {
          stroke: rgba($white-true, .5);
          fill: none;
        }
      }
    }

    &__controls-wrapper {
      position: absolute;
      left: -150px;
      top: 140px;
      z-index: 10;

      @include low-desktop {
        top: 132px;
        left: -128px;
      }

      @include tablet {
        position: static;
        margin: 30px 0 0;
      }
    }

    &__controls {
      height: 0;
      width: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $white-true;

      @include desktop {
        width: 88px;
      }

      @include tablet {
        display: none;
      }

      .swiper-pagination {
        position: static;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }

    &__prev,
    &__next {
      outline: 0;
      text-decoration: none;
      user-select: none;
      cursor: pointer;
      flex-shrink: 0;
      width: 20.5px;
      height: 11px;
      transition: 0.3s;
      color: inherit;

      @include desktop {
        width: 15.5px;
        height: 11px;
      }

      @include hover {
        &:not(.is-disabled) {
          color: $color-base;
        }
      }

      &.is-disabled {
        cursor: default;
        opacity: 0.35;
      }

      &.swiper-button-lock {
        display: none;
      }
    }

    &__bullets {
      @include tablet-min {
        display: none;
      }

      .g-pagination-bullet {
        &:not(.is-active) {
          background-color: rgba($white-true, 0.15);
        }
      }
    }
  }
</style>
