<template>
  <div class="header-search-toggle" @click="toggleSearch">
    <inline-svg class="header-search-toggle__icon" src="u-search-2" />
    <span class="header-search-toggle__text">Поиск</span>
  </div>
</template>

<script>
export default {
  name: "HeaderSearchToggle",
  props: {
    forMobile: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleSearch() {
      if (this.forMobile) {
        this.$root.$emit("toggleSearchHeaderMobile");
      } else {
        this.$root.$emit("toggleSearchHeader");
      }
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: ".header-search-toggle";

#{$b} {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-inter;
  font-size: 14px;
  line-height: 130%;
  color: inherit;

  @include tablet {
    width: 21px;
    height: 21px;
  }

  &__text {
    @include tablet {
      display: none;
    }

    margin-left: 8px;
  }

  &__icon {
    .is-header-search-opened & {
      opacity: 0;
      pointer-events: none;
    }

    svg {
      circle {
        fill: transparent !important;
      }
      path {
        fill: $white-true !important;
      }
    }
  }

  .inline-svg {
    height: 21px;
    width: 21px;
  }
}
</style>
