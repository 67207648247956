<template>
  <div class="projects-gallery">
    <div class="projects-gallery__header">
      <div class="projects-gallery__header-title">
        <slot/>
      </div>
      <div class="projects-gallery__swiper-controls">
        <div class="u-swiper-controls">
          <button
            ref="btnPrev"
            class="u-swiper-controls__btn u-swiper-controls__btn--prev">
          </button>
          <div
            ref="paginationFraction"
            class="u-swiper-controls__count"
          >
          </div>
          <button
            ref="btnNext"
            class="u-swiper-controls__btn u-swiper-controls__btn--next">
          </button>
        </div>
      </div>
    </div>
    <div class="projects-gallery__swiper">
      <swiper
        v-if="isUpdatedSwiper"
        ref="projectsGallerySwiper"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(item,i) in gallery"
          :key="`projects-gallery__item-${i}`"
        >
          <ProjectGalleryCard
            :data="item"
            @play="onPlay(i)"
          />
        </swiper-slide>
      </swiper>

      <div class="projects-gallery__swiper-pagination-mob">
        <div ref="paginationMobile" class="u-swiper-pagination"></div>
      </div>
    </div>
    <CoolLightBox
      :items="gallery"
      :index="lightboxIndex"
      :loop="true"
      :slideshow="false"
      thumbs-position="bottom"
      @close="onLightboxClose"
    />
  </div>
</template>

<script>
export default {
  props: {
    initItems: {
      type: Array,
      default: ()=> [
        {
          cover: "https://www.shutterstock.com/image-photo/fantastic-evening-panorama-bachalp-lake-600w-1216555750.jpg",
          title: "НИКОЛАЙ ПИРОГОВ",
          text: "Как Николай Пирогов в 19-м веке создал первые томографические снимки, какие идеи знаменитого хирурга используют армии всего мира и какими гениальными открытиями русского врача мы пользуемся даже в мирной жизни",
          video: ""
        },
        {
          cover: "https://www.shutterstock.com/image-photo/fantastic-evening-panorama-bachalp-lake-600w-1216555750.jpg",
          title: "НИКОЛАЙ ПИРОГОВ",
          text: "Как Николай Пирогов в 19-м веке создал первые томографические снимки, какие идеи знаменитого хирурга используют армии всего мира и какими гениальными открытиями русского врача мы пользуемся даже в мирной жизни",
          video: "/upload/iblock/138/brand3.mp4"
        },
        {
          cover: "https://www.shutterstock.com/image-photo/fantastic-evening-panorama-bachalp-lake-600w-1216555750.jpg",
          title: "НИКОЛАЙ ПИРОГОВ",
          text: "Как Николай Пирогов в 19-м веке создал первые томографические снимки, какие идеи знаменитого хирурга используют армии всего мира и какими гениальными открытиями русского врача мы пользуемся даже в мирной жизни",
          video: ""
        },
        {
          cover: "https://www.shutterstock.com/image-photo/fantastic-evening-panorama-bachalp-lake-600w-1216555750.jpg",
          title: "НИКОЛАЙ ПИРОГОВ",
          text: "Как Николай Пирогов в 19-м веке создал первые томографические снимки, какие идеи знаменитого хирурга используют армии всего мира и какими гениальными открытиями русского врача мы пользуемся даже в мирной жизни",
          video: "/upload/iblock/138/brand3.mp4"
        },
      ]
    }
  },
  data() {
    return {
      lightboxIndex: null,
      isUpdatedSwiper: true,
      paginationEl: this.$refs.paginationFraction,
      htmlBlock: null
    }
  },
  methods: {
    onPlay(index) {
      this.onLightboxOpen()
      this.lightboxIndex = index
    },
    onLightboxOpen() {
        this.htmlBlock.style.overflow = "hidden";
    },
    onLightboxClose() {
      this.htmlBlock.style.overflow = "";
      this.lightboxIndex = null;
    },
    updateSwiper(){
      this.isUpdatedSwiper = false
      this.setPaginationEl()
      this.$nextTick(( )=> {
        this.isUpdatedSwiper=true
      })
    },
    setPaginationEl(){
      if(window.innerWidth > 767) {
        this.paginationEl = this.$refs.paginationFraction
      } else {
        this.paginationEl = this.$refs.paginationMobile
      }
    }
  },
  computed: {
    gallery() {
      return this.initItems.map(item => {
        return {
          cover: item.cover,
          title: item.title,
          text: item.text,
          src: item.video || item.cover,
          isVideo: Boolean(item.video)
        }
      })
    },
    swiper() {
      return this.$refs.projectsGallerySwiper.$swiper
    },
    swiperOptions() {
      return {
        slidesPerView: 1,
        spaceBetween: 14,
        navigation: {
          prevEl: this.$refs.btnPrev,
          nextEl: this.$refs.btnNext,
          disabledClass: "u-swiper-controls__btn--is-disabled"
        },
        pagination: {
          el: this.paginationEl,
          type: "bullets",
          bulletClass: "u-swiper-pagination__bullet",
          bulletActiveClass: "u-swiper-pagination__bullet--is-active",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 'auto',
            spaceBetween: 16,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          },
          1080: {
            slidesPerView: 'auto',
            spaceBetween: 16,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          },
        }
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateSwiper)
    this.updateSwiper()

    this.htmlBlock = document.querySelector("html");
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSwiper)
  },
}
</script>


<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.projects-gallery';

#{$b} {

  .swiper-container {
    overflow: unset !important;
  }

  .swiper-slide {
    height: auto;
    @include mobile-min {
      width: auto !important;
    }
  }

  // .projects-gallery__header
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
    }

    // .projects-gallery__header-title
    &-title {
      @include mobile-min {
        margin-right: 24px;
      }
    }
  }

  // .projects-gallery__swiper-controls
  &__swiper-controls {
    margin-left: auto;

    @include mobile {
      display: none;
    }
  }

  // .projects-gallery__swiper-pagination-mob
  &__swiper-pagination-mob {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    @include mobile-min {
      display: none;
    }
  }
}
</style>
