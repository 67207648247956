<template>
  <form class="reg-form" @submit.prevent="submitForm">
    <div v-if="registrationEmail" class="reg-form__wrapper">
      Письмо для подтверждения регистрации отправлено Вам на почту - <strong>{{ registrationEmail }}</strong>
    </div>
    <div v-else class="reg-form__wrapper">
      <div class="reg-form__field">
        <u-text-input
          class="u-text-input--smaller"
          name="email"
          label="Логин"
          placeholder="E-mail"
          v-model="form.email"
          :error="$v.form.email.$error"
        />
        <ErrorFormWrapper :isVisible="$v.form.email.$error && (!$v.form.email.required || !$v.form.email.email || !$v.form.email.isGmail)">
          <div v-show="$v.form.email.$error && !$v.form.email.required">
            Обязательное поле
          </div>
          <div v-show="$v.form.email.$error && !$v.form.email.email">
            Неправильный e-mail
          </div>
          <div v-show="$v.form.email.$error && !$v.form.email.isGmail">Укажите почту в российской зоне .ru</div>
        </ErrorFormWrapper>
      </div>
      <div class="reg-form__field">
        <u-text-input
          class="u-text-input--smaller"
          name="password"
          label="Пароль"
          placeholder="Пароль"
          v-model="form.password"
          input-type="password"
          :error="$v.form.password.$error"
          isPassword
        />
        <ErrorFormWrapper :isVisible="$v.form.password.$error && (!$v.form.password.required || !$v.form.password.minLength)">
          <div v-show="$v.form.password.$error && !$v.form.password.required" >
            Обязательное поле
          </div>
          <div v-show="$v.form.password.$error && !$v.form.password.minLength" >
            Должен содержать не менее 8 символов, цифры, заглавные буквы, только латиницу и спецсимволы.
          </div>
        </ErrorFormWrapper>
      </div>
      <div class="reg-form__field">
        <u-text-input
          class="u-text-input--smaller"
          name="confirm-password"
          label="Повторите пароль"
          placeholder="Повторите пароль"
          v-model="form.confirmPassword"
          input-type="password"
          :error="$v.form.confirmPassword.$error"
          isPassword
        />
        <ErrorFormWrapper :isVisible="$v.form.confirmPassword.$error && (!$v.form.confirmPassword.required || !$v.form.confirmPassword.sameAsPassword)">
          <div v-show="$v.form.confirmPassword.$error && !$v.form.confirmPassword.required" >
            Обязательное поле
          </div>
          <div v-show="$v.form.confirmPassword.$error && !$v.form.confirmPassword.sameAsPassword && $v.form.confirmPassword.required" >
            Пароли не совпадают
          </div>
        </ErrorFormWrapper>
      </div>
      <div class="reg-form__field">
        <div class="reg-form__checkbox">
          <input
            v-model="agreement"
            name="agreement"
            id="agreement"
            type="checkbox"
          />
          <label for="agreement">
            <span>
              Принимаю условия
              <a
                href="/upload/documents/Пользовательское_соглашение_наука.рф.pdf"
                target="_blank"
                >Пользовательского соглашения</a
              >
              и&nbsp;<a
                href="/upload/documents/Политика_конфиденциальности_наука.рф.pdf"
                target="_blank"
                >Политики конфиденциальности</a
              >
              по&nbsp;работе с&nbsp;персональными данными
            </span>
            <ErrorFormWrapper :isVisible="$v.agreement.$error">
              Необходимо согласиться с условиями
            </ErrorFormWrapper>
          </label>
        </div>
      </div>
      <div class="reg-form__field">
        <ErrorFormWrapper :isVisible="true">
          <div class="_color-secondary-info">
            Пароль должен содержать от 8 символов, цифры, заглавные буквы, только латиницу и спецсимволы.
          </div>
        </ErrorFormWrapper>
      </div>
      <ErrorFormWrapper
        class="reg-form__errors-after-submit"
        :isVisible="Boolean(errorsAfterSubmit.length)"
        :maxHeight="1000"
      >
        <div v-for="error in errorsAfterSubmit" :key="`error-after-submit-${error.code}`">
          <span v-html="error.message"/>
        </div>
      </ErrorFormWrapper>
      <div class="reg-form__bottom">
        <CustomButton
          class="reg-form__bottom-btn"
          theme="primary"
          size="md"
          type="button"
          @click.prevent="submitForm"
        >
          Зарегистрироваться
        </CustomButton>
        <div class="reg-form__bottom-text">
          Есть аккаунт?
          <a href="#" @click.prevent="changeForm('authorization')">
            Войти
          </a>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import { checkGmail } from '/frontend/components/helpers/validate.js'

export default {
  name: "RegForm",
  mixins: [validationMixin],
  props: {
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      passwordText: "",
      formSent: false,
      form: {
        email: "",
        password: "",
        confirmPassword: ""
      },
      agreement: false,
      registrationEmail: false,
      errorsAfterSubmit: []
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
        isGmail: checkGmail
      },
      password: {
        required,
        minLength: minLength(8)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    },
    agreement: {
      required,
      sameAs: sameAs(() => true)
    }
  },
  methods: {
    async submitForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const formData = new FormData();
        formData.append("email", this.form.email);
        formData.append("password", this.form.password);
        formData.append("confirmPassword", this.form.confirmPassword);

        const options = {
          url:
            "/bitrix/services/main/ajax.php?mode=class&c=dalee:auth&action=register",
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: formData
        };
        const response = await axios(options);

        this.formSent = true;

        if (
          Boolean(response.data) &&
          Boolean(response.data.errors) &&
          Boolean(response.data.errors.length)
        ) {
          this.errorsAfterSubmit = response.data.errors
        } else {
          this.errorsAfterSubmit = []
          this.reset();

          this.registrationEmail = response?.data?.data?.validData?.email;
        }
      }
    },
    reset() {
      this.$v.$reset();
      this.form.email = "";
      this.form.password = "";
      this.form.confirmPassword = "";
    },
    changeForm(name) {
      this.$emit("onChangeForm", name);
    },
    submitFormFromEnter(e) {
      if (e.key === "Enter" || e.keyCode === 13) {
        this.submitForm()
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.submitFormFromEnter)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.submitFormFromEnter)
  }
};
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: ".reg-form";

#{$b} {
  font-family: $font-family-inter;

  &__bottom {
    display: block;
    text-align: center;
    color: $black-true;

    // .reg-form__bottom-btn
    &-btn {
      width: 298px;
      max-width: 100%;
      justify-content: center;
    }

    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.3;

      &:not(:first-child) {
        margin-top: 32px;
      }

      a {
        color: $color-base-origin;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    .button {
      display: flex;
      width: 100%;
    }
  }

  &__field {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 31px;
    }
  }

  &__checkbox {
    position: relative;

    input {
      display: none;

      &:checked {
        + label {
          &:after {
            opacity: 1;
            visibility: visible;
            transform: none;
          }
        }
      }

      &:not(:checked) {
        + label {
          &:hover,
          &:focus {
            &:before {
              border-color: $color-base-origin;
            }
          }
        }
      }
    }

    label {
      position: relative;
      display: inline-block;
      padding-left: 40px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.3;
      color: $black-true;

      &:before,
      &:after {
        content: "";
        position: absolute;
      }

      &:before {
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        border: $line-width solid $color-border;
        transition: border-color 0.2s ease;
        border-radius: 4px;
      }

      &:after {
        top: 0;
        left: 5px;
        width: 19px;
        height: 15px;
        background-image: $img-check;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-5px);
        transition: 0.2s;
      }

      a {
        color: $color-base-origin;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__errors-after-submit {
    & > *:last-child {
      margin-bottom: 31px;
    }
  }
}
</style>
