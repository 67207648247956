<template>
  <div v-if="isExistsTimelaps" class="talisman-timelaps" :class="`talisman-timelaps--theme-${theme}`">
    <UFigures
      v-if="isThemeBlock"
      :items="figures"
      :fullWidth="true"
      :isAnimateImg="false"
      position="static"
    />
    <div class="talisman-timelaps__inner">
      <div class="talisman-timelaps__captions">
        <div
          v-for="item in timelaps"
          :key="`talisman-timelaps__caption-${item.date || item.caption}`"
          class="talisman-timelaps__caption tt-uppercase"
          :class="{'talisman-timelaps__caption--is-active': item.isActive}"
          v-html="item.caption"
        />
      </div>
      <time v-if="withData" class="talisman-timelaps__time tt-uppercase">
        <span class="talisman-timelaps__item">
          <span class="talisman-timelaps__item-main">
            {{ currentTimeLaps.days }}
          </span>
          <span class="talisman-timelaps__item-caption">
            Дней
          </span>
        </span>
        <span class="talisman-timelaps__item">
          <span class="talisman-timelaps__item-main">
            {{ currentTimeLaps.hours }}
          </span>
          <span class="talisman-timelaps__item-caption">
            Часов
          </span>
        </span>
        <span class="talisman-timelaps__item">
          <span class="talisman-timelaps__item-main">
            {{ currentTimeLaps.minutes }}
          </span>
          <span class="talisman-timelaps__item-caption">
            Минут
          </span>
        </span>
        <span class="talisman-timelaps__item">
           <span class="talisman-timelaps__item-main">
            {{ currentTimeLaps.seconds }}
          </span>
          <span class="talisman-timelaps__item-caption">
            Секунд
          </span>
        </span>
      </time>
    </div>
  </div>
</template>

<script>
import formatNumberToString from '/frontend/components/helpers/formatNumberToString'
export default {
  props: {
    theme: {
      type: String,
      default: 'inline', // block | inline
    },
    timelapsInitial: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      timelaps: JSON.parse(JSON.stringify(this.timelapsInitial)),
      currentTimeLaps: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      currentStep: null,
      idInterval: null,
      figures: [
        {
          name: 'f-talisman-timelaps',
          image: '/frontend/assets/img/talisman/lines-timelapse.svg',
          animation: 'fade-in-left'
        }
      ]
    }
  },
  computed: {
    isThemeBlock() {
      return this.theme === 'block'
    },
    isExistsTimelaps() {
      return this.timelapsInitial && Object.values(this.timelapsInitial).length > 0
    },
    withData() {
      return Boolean(Object.values(this.timelapsInitial).filter(item => item.date).join('').length)
    }
  },
  methods: {
    getCurrentDate() {
      return Math.floor(Date.now() / 1000)
    },
    parseStrDate(date) {
      let thisDate = date

       if (!(date instanceof Date && !isNaN(date))) {
        const partialsDate = date.split('.')
        const dd = partialsDate[0]
        const mm = partialsDate[1]
        const yy = partialsDate[2]

        thisDate = new Date(yy, mm-1, dd)
      }

      return thisDate
    },
    checkStep() {
      for (let item in this.timelaps) {
        if (this.timelaps[item].date > this.getCurrentDate() ) {
          this.currentStep = item
          this.setActiveStep()
          return
        }
      }
    },
    setActiveStep() {
      for (let item in this.timelaps) {
        this.timelaps[item].isActive = item === this.currentStep
      }
    },
    setTimeLaps() {
      if (
        !this.currentStep
        || !this.timelaps
        || !this.timelaps[this.currentStep]
        || !this.timelaps[this.currentStep].date
      ) {
        return
      }
      const date1 = this.timelaps[this.currentStep].date
      const date2 = this.getCurrentDate()
      const delta = date1 - date2

      const days = delta / 60 / 60 / 24
      const daysResult = Math.trunc(days)
      const daysInSeconds = daysResult * 60 * 60 * 24

      const hours = (delta - daysInSeconds) / 60 / 60
      const hoursResult = Math.trunc(hours)
      const hoursInSeconds = hoursResult * 60 * 60

      const minutes = (delta - daysInSeconds - hoursInSeconds) / 60
      const minutesResult = Math.trunc(minutes)
      const minutesInSecond = minutesResult * 60

      const seconds = delta - daysInSeconds - hoursInSeconds - minutesInSecond

      this.currentTimeLaps = {
        days: formatNumberToString(daysResult),
        hours: formatNumberToString(hoursResult),
        minutes: formatNumberToString(minutesResult),
        seconds: formatNumberToString(seconds)
      }

      if (!daysResult && !hoursResult && !minutesResult && !seconds) {
        this.checkStep()
      }
    },
    init() {
      if (!this.withData) {
        return
      }
      this.checkStep()
      this.setTimeLaps()
      this.idInterval = setInterval(this.setTimeLaps, 1000);
    }
  },
  created() {
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.idInterval)
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-timelaps';

#{$b} {
  position: relative;
  color: $white-true;

  // .talisman-timelaps--theme-block
  &--theme-block {
    overflow: hidden;
    padding: 70px 139px 74px 88px;
    border-radius: 16px;
    background-color: $color-base;

    @include low-desktop {
      padding: 70px;
    }
    @include tablet {
      padding: 70px 24px 70px 40px;
    }
    @include mobile {
      padding: 20px;
    }
  }

  // .talisman-timelaps__inner
  &__inner {
    #{$b}--theme-inline & {
      display: flex;
      flex-direction: column;
    }

    #{$b}--theme-block & {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;

      @include mobile {
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }

  // .talisman-timelaps__captions
  &__captions {

    #{$b}--theme-inline & {
      margin-bottom: 39px;

      @include low-desktop {
        margin-bottom: 24px;
      }

      @include mobile {
        padding-left: 20px;
      }
    }

    #{$b}--theme-block & {
      @include mobile {
        padding-left: 20px;
        margin-bottom: 32px;
      }
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  // .talisman-timelaps__caption
  &__caption {
    font-family: $font-family-main-head;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    position: relative;

    &:not(:last-child) {
      #{$b}--theme-inline & {
        margin-bottom: 7px;
      }
      #{$b}--theme-block & {
        margin-bottom: 12px;

        @include mobile {
          margin-bottom: 7px;
        }
      }

    }

    &:not(#{$b}__caption--is-active) {
      opacity: 0.6;
    }

    &--is-active {
      &::before {
        content: "";
        position: absolute;
        left: -11px;
        top: 50%;
        transform: translateX(-100%) translateY(-50%);
        width: 9px;
        height: 17px;
        background-image: url('/frontend/assets/img/svg/triangle-right.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

  }

  // .talisman-timelaps__time
  &__time {
    display: flex;
    align-items: center;
    flex: 0;
  }

  // .talisman-timelaps__item
  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: $font-family-main-head;
    font-weight: 500;
    line-height: 1;

    &:not(:last-child) {
      padding-right: 54px;

      @include tablet {
        padding-right: 32px;
      }
      @include mobile {
        padding-right: 22px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 39px;
        right: 11px;
        width: 31px;
        height: 9px;
        background-color: $white-true;

        @include low-desktop {
          top: 28px;
        }
        @include tablet {
          right: 9px;
          width: 18px;
          height: 5px;
        }
        @include mobile {
          right: 12px;
          top: 50%;
          transform: translateY(-50%);

          #{$b}--theme-inline & {
            height: 4px;
            width: 16px;
            right: 9px;
          }
        }
        @include mobile-sm {
          right: 8px;
        }
      }
    }

    // .talisman-timelaps__item-main
    &-main {
      font-size: 90px;
      min-width: 105px;

      @include low-desktop {
        font-size: 60px;
        min-width: 69px;
      }

      #{$b}--theme-block & {
        @include mobile {
          font-size: 40px;
          min-width: 60px;
        }
        @include mobile-sm {
          font-size: 32px;
          min-width: 47px;
        }
      }

      #{$b}--theme-inline & {

        @include low-desktop-min {
          font-size: 80px;
          min-width: 96px;
        }

        @include mobile {
          font-size: 32px;
          min-width: 47px;
        }
      }
    }

    // .talisman-timelaps__item-caption
    &-caption {
      font-size: 15px;

      #{$b}--theme-block & {
        @include mobile {
          font-size: 16px;
        }
        @include mobile-sm {
          font-size: 12px;
        }
      }

      #{$b}--theme-inline & {
        @include mobile {
          font-size: 12px;
          min-width: 44px;
        }
      }
    }
  }
}
</style>
