<template>
  <div class="primary-list">
    <PrimaryItem
      v-for="(item, i) in list"
      :key="`primary-list__item-${i}`"
      :index="i"
      :card="item"
    />
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.primary-list';

#{$b} {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;

  @include mobile {
    margin: 0;
    display: block;
  }
}
</style>
