<template>
  <div class="input-file" :class="{'input-file--is-error': error}">
    <div class="input-file__wrapper">
      <div class="input-file__inner">
        <label
          :for="id"
          class="input-file__label"
        >
          <slot/>
        </label>
        <input
          class="input-file__input"
          type="file"
          :ref="`inputFile-${id}`"
          :id="id"
          :accept="validFormatStr"
          @input="onInput"
        >
        <div
          v-if="Boolean(fileName)"
          class="input-file__name"
        >
          <span class="input-file__name-field">{{ fileName }}</span>
          <div class="input-file__clear-btn" @click="onRemove()">
            <inline-svg
              class="input-file__clear-icon"
              src="close-thin"
            />
          </div>
        </div>
      </div>
      <ErrorFormWrapper :isVisible="errorsFile.size || errorsFile.format" :maxHeight="100">
        <div v-show="errorsFile.size">
          <div v-if="maxSize !== Infinity">Максимальный размер файла: {{ maxSize }}МБ</div>
          <div> Файл не должен быть пустым</div>
        </div>
        <div v-show="errorsFile.format">Файл должен быть в формате: {{ validFormatStr }}</div>
      </ErrorFormWrapper>
    </div>
  </div>
</template>

<script>
import {randomString} from '/frontend/components/helpers/randomString.js'

export default {
  props: {
    maxSize: {
      type: Number,
      default: Infinity // в МБ // 20
    },
    validFormat: {
      type: Array,
      default: () => [] // Array<string> // [png, doc]
    },
    error: {
      type: Boolean,
      default: false
    },
    value: {
      type: [File, String],
      default: ''
    }
  },
  data() {
    return {
      id: null,
      fileName: '',
      errorsFile: {
        size: false,
        format: false,
      },
      callStack: {},
      val: ''
    }
  },
  computed: {
    validFormatStr() {
      return this.validFormat
        .map(item => {
          return item.trim().startsWith('.') ?  item : '.' + item
        })
        .join(', ')
    },
  },
  methods: {
    isValidFile(file) {

      let isValid = true
      const isValidMaxSize = this.maxSize !== Infinity && ((file.size / 1024 / 1024) > this.maxSize)

      if (isValidMaxSize || file.size <= 0) {
        this.showErrorByKey('size')
        isValid = false
      } else {
        this.removeErrorByKey('size')
      }

      if (this.validFormat.length >= 1) {
        const index = file.name.lastIndexOf('.');
        const fileExtansion = file.name.substring(index + 1)
        const isMatch = this.validFormat.find(item => item === fileExtansion)

        if (!isMatch) {
          this.showErrorByKey('format')
          isValid = false
        } else {
          this.removeErrorByKey('format')
        }
      }

      return isValid
    },
    onInput(e) {
      if (!(e.target instanceof HTMLInputElement)) {
        return
      }
      const files = e.target.files;

      if (!files || !files.length) {
        throw new Error('Файлы отсутствуют в input[type="file"]');
      }

      const file = files[0]

      if (!this.isValidFile(file)) {
        return
      }

      this.fileName = file.name;
      this.$emit('change', file);
    },
    onRemove() {
      this.fileName = '';
      this.removeInputValue()
      this.removeAllError()
      this.$emit('change', '');
    },
    showErrorByKey(key) {
      this.errorsFile[key] = true
      this.cancelDelayedRemove(key)

      // запуск отложенного удаления ошибки валидации
      // если до этого уже был загружен файл
      if (this.fileName) {
        this.delayedRemove(key)
      }
    },
    removeErrorByKey(key) {
      this.cancelDelayedRemove(key)
      this.errorsFile[key] = false
    },
    removeAllError() {
      for (let key in this.errorsFile) {
        this.cancelDelayedRemove(key)
        this.errorsFile[key] = false
      }
    },
    // отложеное удаление ошибки валидации
    delayedRemove(key) {
      this.callStack[key] = setTimeout(this.removeErrorByKey.bind(this, key), 8000);
      this.removeInputValue()
    },
    // отмена отложенного удаления ошибки валидации
    cancelDelayedRemove(key) {
      clearTimeout(this.callStack[key])
    },
    removeInputValue() {
      this.$refs[`inputFile-${this.id}`].value = ''
    },
    fileModelChain() {
      if (!this.value) {
        this.onRemove()
        return
      }

      if (this.value && this.value instanceof File) {
        this.fileName = this.value.name
      } else {
        this.fileName = this.value
      }
    }
  },
  watch: {
    value() {
      this.fileModelChain()
    }
  },
  created() {
    this.id = randomString()
  },
  mounted() {
    this.fileModelChain()
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.input-file';
$label-gap: 12px;

#{$b} {
  font-family: $font-family-inter;
  font-size: 16px;
  line-height: 1;
  font-weight: normal;
  color: $black-true;
  min-height: 18px;
  transition: $transtion-default;

  &--is-error {
    padding-bottom: 10px;
    margin-bottom: 40px;
  }

  // .input-file__wrapper
  &__wrapper {
    margin-top: -($label-gap);
    margin-bottom: -($label-gap);
  }

  // .input-file__inner
  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  // .input-file__error
  &__error {
    margin-top: 10px;
  }

  // .input-file__input
  &__input {
    display: none;
  }

  // .input-file__label
  &__label {
    display: inline-block;
    color: $color-base-origin;
    text-decoration-style: dotted;
    text-decoration-color: $color-base-origin;
    text-decoration-line: underline;
    text-underline-offset: 5px;
    margin: $label-gap 12px $label-gap 0;
    cursor: pointer;
    transition: $transtion-default;

    &:hover {
      color: $color-base;
    }

    #{$b}--is-error & {
      color: color-error;
      text-decoration-color: $color-error;
    }
  }

  // .input-file__clear-btn
  &__clear-btn {
    min-width: 18px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba($black-true, 0.1);
    margin-left: 8px;
    cursor: pointer;
    transition: $transtion-default;

    &:hover {
      @include mobile-min {
        background-color: $color-base;
      }
    }
  }

  // .input-file__clear-icon
  &__clear-icon {
    width: 6px;
    height: 6px;

    #{$b}__clear-btn:hover & {
      @include mobile-min {
        color: $white-true;
      }
    }
  }

  // .input-file__name
  &__name {
    display: inline-flex;
    align-items: center;
    overflow: hidden;

    // .input-file__name-field
    &-field {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: 1.4;
    }
  }
}
</style>
