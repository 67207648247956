import axios from 'axios'

import { REGION_MONTH_OPTIONS } from '@/components/helpers/consts'
import { isEmpty } from '@/components/helpers/formatHelper'

function canFilterProp(prop, filterData) {
  if (
    (prop?.trim() === 'year' && filterData.filter === 'upcoming')
    || (prop?.trim() === 'ageGroup' && filterData.filter === 'past')
  ) {
    return false
  } else {
    return true
  }
}

export default {
  namespaced: true,
  state: {
    items: [],
    paginator: {},
    pagenName: '',
    actionGet: '',
    isLoading: false,

    filter: {
      filter: '',
      region: '',
      type: '',
      ageGroup: '',
      months: [],
      year: ''
    },

    options: {
      region: [],
      type: [],
      ageGroup: [],
      year: [],
      month: REGION_MONTH_OPTIONS,
    }
  },

  getters: {
    HAS_NEXT_PAGE(state) {
      return state.paginator?.NavPageNomer < state.paginator?.NavPageCount
    },

    ITEMS(state) {
      return state.items
    },

    IS_LOADING(state) {
      return state.isLoading
    },

    OPTIONS(state) {
      const options = {}

      for (let key in state.options) {
        if (!canFilterProp(key, state.filter)) {
          continue
        }

        const value = state.options[key]
        options[key] = value;
      }

      return options
    },

    FILTER(state) {
      return state.filter
    },

    FILTER_API_PARAMS(state, getters) {
      const params = {}

      for (let key in state.filter) {
        const value = state.filter[key]

        if (!canFilterProp(key, state.filter) || (key === 'months' && getters.MONTHS_DISABLED) || isEmpty(value)) {
          continue
        }

        params[key] = value;
      }

      return params
    },

    MONTHS_DISABLED(state) {
      return state.filter?.filter !== 'upcoming'
    }
  },

  mutations: {
    SET_STATE(state, { name, value }) {
      if (!Object.hasOwn(state, name)) {
        return console.warn(`store:excursions | SET_STATE - свойство ${name} не объявлено в state экземпляре`)
      }

      state[name] = value
    },

    SET_OPTIONS(state, options) {
      state.options = { ...state.options, ...options }
    },

    SET_FILTER_ITEM(state, { name, value }) {
      if (!name || !Object.hasOwn(state.filter, name)) {
        return console.warn(`store:excursions | SET_FILTER_ITEM - property ${name} do not exists in filter state`)
      }

      state.filter = { ...state.filter, [name]: value }
    },

    SET_ITEMS(state, { items, withClear }) {
      state.items = withClear ? items : [...state.items, ...items]
    }
  },

  actions: {
    async LOAD_ITEMS({ commit, state, getters }, withClear = false) {
      if (state.isLoading) {
        return
      }

      try {
        commit('SET_STATE', { name: 'isLoading', value: true })

        const options = {
          url: state.actionGet,
          params: {
            AJAX: 'Y',
            [state.pagenName]: withClear ? 1 : state?.paginator?.NavPageNomer + 1,
            ...getters.FILTER_API_PARAMS
          }
        }

        const response = await axios(options)

        if (response?.data?.ITEMS && Array.isArray(response?.data?.ITEMS)) {
          commit('SET_ITEMS', { items: response.data.ITEMS || [], withClear })

          commit('SET_OPTIONS', {
            year: response.data?.DATE || [],
            region: response.data?.REGIONS || [],
            month: response.data?.MONTH || []
          })

          commit('SET_STATE', { name: 'paginator', value: response?.data?.PAGINATOR })

          if (response.data?.IGNORE_REGION) {
            commit('SET_FILTER_ITEM', { name: 'region', value: '' })
          }
        }
      } catch (e) {
        throw new Error(`store:excursions | LOAD_ITEMS - ${e}`)
      } finally {
        commit('SET_STATE', { name: 'isLoading', value: false })
      }
    }
  }
}
