<template>
  <div
    class="u-initiative-condition"
    :class="cardClass">
    <div class="u-initiative-condition__icon">
      <img
        :src="iconHref"
        alt="">
    </div>
    <div
      class="u-initiative-condition__text text-base"
      v-html="text">
    </div>
  </div>
</template>

<script>
const CARD_SIZE = ['m', 'l']; // m - 50%, l - 100%
export default {
  name: "UInitiativeCondition",
  props: {
    iconHref: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'm', // m, l
      validate: (value) => CARD_SIZE.includes(value)
    }
  },
  computed: {
    cardClass() {
      return {
        'u-initiative-condition--size-l': this.size === 'l',
      }
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

.u-initiative-condition {
  $root: #{&};
  display: grid;
  align-items: center;
  grid-template-columns: 64px auto;
  gap: 16px;
  padding: 24px;
  border-radius: 16px;
  background: $gradient-primary-horizontal;
  color: $black-true;
  transition: 0.2s ease;
  transition-property: color, background;

  &--size-l {
    width: 100%;
    grid-column: -1 / 1;
  }

  &:hover {
    background: $color-base;
    color: $white-true;

    #{$root}__icon {
      filter: grayscale(1) brightness(1000%);
    }
  }

  &__text {
    color: inherit;
    letter-spacing: -.02em;
    line-height: 1.3;
  }

  &__icon {
    width: 64px;
    height: 64px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
