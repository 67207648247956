<template>
  <div class="u-knowledge-areas">
    <UFigures
      :items="figures"
      :full-width="true"
      position="static"
    >
      <div class="universities-wrapper__relative-block">
        <div class="u-g-container">
          <initiatives-themes
            section-title="Области знаний"
            title-size="2xl"
            category=""
            :themes="knowledgeList"
          />
        </div>
      </div>
    </UFigures>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      knowledgeList: [],
      figures: [
        {'name':'f-universities-main-page-2','image':'/frontend/assets/img/bg-shapes/circle-group-left-opacity.svg','animation':'fade-in-left'},
        {'name':'f-universities-main-page-3','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
      ]
    }
  },
  methods: {
    async loadKnowledgeList() {
      const response = await axios.get('/api/rest-component/dalee/groups/getElementsList/')

      if (response.data.status === 'success') {
        this.knowledgeList = response.data.data
      }
    }
  },
  created() {
    this.loadKnowledgeList()
  }
};
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-knowledge-areas';

#{$b} {

}
</style>
