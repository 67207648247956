<template>
  <form class="pass-recovery" @submit.prevent="submitForm">
    <template v-if="!formSent">
      <div class="pass-recovery__field">
        <u-text-input
          class="u-text-input--smaller"
          input-type="email"
          name="email"
          label="Введите E-mail"
          placeholder="E-mail"
          v-model="form.email"
          :error="$v.form.email.$error"
        />
        <ErrorFormWrapper :isVisible="$v.form.email.$error && (!$v.form.email.required || !$v.form.email.email)">
          <div v-show="$v.form.email.$error && !$v.form.email.required">Обязательное поле</div>
          <div v-show="$v.form.email.$error && !$v.form.email.email">Некорректный e-mail</div>
        </ErrorFormWrapper>
      </div>
      <ErrorFormWrapper
        :isVisible="Boolean(errorsAfterSubmit.length)"
        class="pass-recovery__errors-after-submit"
      >
        <div v-for="(error,i) in errorsAfterSubmit" :key="`error-after-submit-${i}`">
          {{error.message}}
        </div>
      </ErrorFormWrapper>
      <div class="pass-recovery__bottom">
        <CustomButton
          class="pass-recovery__bottom-btn"
          theme="primary"
          size="md"
          type="button"
          @click.prevent="submitForm"
        >
          Восстановить
        </CustomButton>
        <div class="pass-recovery__bottom-text">
          Вспомнили пароль?
          <a href="#" @click.prevent="changeForm('authorization')">
            Войти
          </a>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="pass-recovery__success">
        На&nbsp;указанный e-mail адрес отправлен запрос на&nbsp;восстановление пароля
      </div>
    </template>
  </form>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "PassRecovery",
  mixins: [validationMixin],
  props: {
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      formSent: false,
      form: {
        email: "",
      },
      errorsAfterSubmit: []
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
    }
  },
  methods: {
    async submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const response = await axios.post(
          '/bitrix/services/main/ajax.php?mode=class&c=dalee:auth&action=recoveryPassword',
          this.form
        );

        if (
          Boolean(response.data) &&
          Boolean(response.data.errors) &&
          Boolean(response.data.errors.length)
        ) {
          this.errorsAfterSubmit = response.data.errors
        } else {
          this.errorsAfterSubmit = []
          this.formSent = true;
          this.reset();
        }
      }
    },
    reset() {
      this.$v.$reset();
      this.form.login = "";
    },
    changeForm(name) {
      this.$emit("onChangeForm", name);
    },
    submitFormFromEnter(e) {
      if (e.key === "Enter" || e.keyCode === 13) {
        this.submitForm()
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.submitFormFromEnter)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.submitFormFromEnter)
  }
};
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: ".pass-recovery";

#{$b} {
  font-family: $font-family-inter;

  &__bottom {
    display: block;
    text-align: center;
    color: $black-true;

    // .pass-recovery__bottom-btn
    &-btn{
      width: 208px;
      justify-content: center;
    }

    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.3;

      &:not(:first-child) {
        margin-top: 32px;
      }

      a {
        color: $color-base-origin;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    .button {
      display: flex;
      width: 100%;
    }
  }

  &__field {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  &__error {
    position: absolute;
    left: 0;
    top: calc(100% + 3px);
    font-size: 12px;
    line-height: 160%;
    color: $color-error;

    @include mobile-sm {
      font-size: 10px;
      line-height: 1.3;
    }
  }

  &__success {
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 1.6;

    @include mobile {
      font-size: 14px;
    }
  }

  &__errors-after-submit {
    & > *:last-child {
      margin-bottom: 31px;
    }
  }
}
</style>
