<template>
  <div class="u-lk-digest-table">
    <div class="u-lk-digest-table__header g-hidden-mobile _color-secondary-info">
      <div class="u-lk-digest-table__row">
        <div class="u-lk-digest-table__col">
          <div class="u-lk-digest-table__col-item">Название <br> инициативы</div>
        </div>
        <div class="u-lk-digest-table__col">
          <div class="u-lk-digest-table__col-item">Ключевые мероприятия/проекты</div>
        </div>
        <div class="u-lk-digest-table__col">
          <div class="u-lk-digest-table__col-item">Информационные <br> поводы которые <br> необходимо отметить</div>
        </div>
        <div class="u-lk-digest-table__col">
          <div class="u-lk-digest-table__col-item">Ссылки на информационные <br> поводы (при&nbsp;наличии)</div>
        </div>
      </div>
    </div>
    <div class="u-lk-digest-table__body">
      <div
        v-for="row in items"
        :key="`u-lk-digest-table__row-${row.id}`"
        class="u-lk-digest-table__row"
      >
        <div class="u-lk-digest-table__col">
          <div class="u-lk-digest-table__col-item g-visible-mobile _color-secondary-info">
            Название инициативы
          </div>
          <div class="u-lk-digest-table__col-item">{{ row.initiative }}</div>
        </div>
        <div class="u-lk-digest-table__col">
          <div class="u-lk-digest-table__col-item g-visible-mobile _color-secondary-info">
            Ключевые мероприятия/проекты
          </div>
          <div class="u-lk-digest-table__col-item">{{ row.events }}</div>
        </div>
        <div class="u-lk-digest-table__col">
          <div class="u-lk-digest-table__col-item g-visible-mobile _color-secondary-info">
            Информационные поводы которые необходимо отметить
          </div>
          <div class="u-lk-digest-table__col-item">{{ row.reasons }}</div>
        </div>
        <div class="u-lk-digest-table__col">
          <div class="u-lk-digest-table__col-item g-visible-mobile _color-secondary-info">
            Ссылки на информационные поводы (при&nbsp;наличии)
          </div>
          <div class="u-lk-digest-table__col-item u-lk-digest-table__col-item--actions">
            <div class="u-lk-digest-table__edit-link">
              {{row.links}}
            </div>
            <div class="u-lk-digest-table__actions">
              <button
                class="u-lk-digest-table__link"
                target="_blank"
                @click="onEdit(row.id)"
              >
                Редактировать
              </button>
              <button
                class="u-lk-digest-table__link u-lk-digest-table__remove-link"
                target="_blank"
                @click="onDeletePrompt(row.id)"
              >
                Удалить
              </button>
              <ConfirmationWindow
                :isVisible="currentId === row.id"
                :deleteStatus="deleteStatus"
                @success="onDelete()"
                @cancel="currentId = null"
              >
                Вы действительно хотите удалить
                эту информацию для дайджеста?
              </ConfirmationWindow>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorFormWrapper
      class="u-lk-digest-table__feedback-errors"
      :max-height="1000"
      :isVisible="Boolean(errorsAfterSubmit) && Boolean(errorsAfterSubmit.length)"
    >
      <div
        v-for="error in errorsAfterSubmit"
        :key="`u-lk-digest-table__feedback-error-${error.code}`"
        class="u-lk-digest-table__feedback-error"
      >
        <spa v-html="error.message" />
      </div>
    </ErrorFormWrapper>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    initItems: {
      type: Array,
      default: () => []
    },
    actionEditDigest: {
      type: String,
      default: ''
    },
    actionRemoveDigest: {
      type: String,
      default: ''
    },
    actionGetDigest: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentId: null,
      deleteStatus: '',
      errorsAfterSubmit: [],
      items: this.initItems
    }
  },
  methods: {
    onDeletePrompt(id) {
      this.deleteStatus = ''
      this.currentId = id
    },
    async onDelete() {
      this.deleteStatus = 'loading'

      const options = {
        url: this.actionRemoveDigest,
        method: 'POST',
        data: {
          idRemove: this.currentId
        }
      };

      const response = await axios(options)

      if (response.data.status === 'success') {
        this.deleteStatus = 'success'
        this.errorsAfterSubmit = []
        this.removeItemById(this.currentId)

        setTimeout(() => {
          this.deleteStatus = ''
          this.currentId = null
        }, 3000);
      } else {
        this.errorsAfterSubmit = response.data.errors
      }
    },
    async onEdit(id) {
      const url = `${this.actionGetDigest}&id=${id}`
      const response = await axios(url)

      if (response.data.status === 'success') {
        const submitUrl = this.actionEditDigest

        this.$root.$refs.customModal.openModal(null, null, true, true);
        this.$root.$refs.customModal.passContent({
          name: 'u-lk-digest-form',
          props: {...response.data.data, submitUrl, requestId: id},
          title: 'Редактировать Информацию для дайджеста'
        });

        this.errorsAfterSubmit = []
      } else {
        this.errorsAfterSubmit = response.data.errors
      }
    },
    removeItemById(id) {
      this.items = this.items.filter(item => item.id !== id)
    },
    async getItemById(id) {
      const response = await axios(`${this.actionGetDigest}Short&id=${id}`)

      if (response.data.status === 'success') {
        this.setNewItemInDigestTable(response.data.data)
      }
    },
    setNewItemInDigestTable(data) {
      const index = this.items.findIndex(item => item.id === data.id)

      if (index === -1) {
        // Добавление нового айтема
        this.items = [...this.items, data]
      } else {
        // Обновление существующего
        this.items = [...this.items.slice(0, index), data, ...this.items.slice(index + 1)]
      }
    }
  },

  mounted() {
    this.$root.$on('uploadDigestItem', this.getItemById)
  }
}
</script>


<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-lk-digest-table';

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;

  @include mobile {
    line-height: 1.6;
  }

  // .u-lk-digest-table__header
  &__header {
    padding-bottom: 3px;
    border-bottom: 1px solid $color-secondary-2;
    margin-bottom: 24px;
  }

  // .u-lk-digest-table__row
  &__row {
    display: grid;
    grid-template-columns: 1fr 178px 1fr 400px;
    gap: 16px;

    @include low-desktop {
      grid-template-columns: 1fr 1fr 1fr 400px;
    }

    @include tablet {
      grid-template-columns: 1fr 1fr 1fr 300px;
    }

    @include mobile {
      display: block;
      background-color: $white-true;
      border-radius: 16px;
      padding: 24px;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  // .u-lk-digest-table__col
  &__col {

    // .u-lk-digest-table__col-item
    &-item {
      display: flex;
      align-items: center;
      word-break: break-word;

      @include mobile {
        display: block;
      }
    }

    &:not(:last-child) {

      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  // .u-lk-digest-table__link
  &__link {
    color: $color-base-origin;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    text-decoration: none;
    padding: 0;

    &:hover {
      @include mobile-min {
        text-decoration: underline;
      }
    }
  }

  // .u-lk-digest-table__edit-link
  &__edit-link {
    width: 183px;
    @include lineClamp(1);

    @include tablet {
      width: 83px;
    }

    @include mobile {
      width: 100%;
    }
  }

  // .u-lk-digest-table__actions
  &__actions {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    gap: 16px;
    padding-left: 16px;

    @include mobile {
      padding-left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 24px;
      margin-top: 24px;
      border-top: 1px solid $color-secondary-2;
    }
  }

  // .u-lk-digest-table__feedback-errors
  &__feedback-errors {
    &.error-form-wrapper--is-visible {
      margin: 20px 0;
      max-height: 100px;
    }
  }

  // .u-lk-digest-table__feedback-error
  &__feedback-error {
    font-size: 24px;
    padding: 4px;

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    @include mobile {
      font-size: 18px;
    }
  }
}
</style>
