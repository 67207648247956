<template>
  <div class="u-actual-news-list">
    <UNewsCard
      v-for="(card,i) in newsList"
      :key="`u-actual-news-list__card-${i}`"
      class="u-actual-news-list__card"
      :size="newsCardSizeClass(i)"
      :cardData="card"
    />
  </div>
</template>

<script>
export default {
  props: {
    newsList: {
      type: Array,
    },
    indexLarge: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    newsCardSizeClass(index) {
      return index % 5 === this.indexLarge ? 'large' : 'default'
    },
  }
}
</script>

<style lang="scss">
.u-actual-news-list {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;

  // .u-actual-news-list__card
  &__card {
    padding: 8px;
  }
}
</style>
