<template>
  <u-figures
    class="special-projects-figures"
    :items="[
      {'name':'f-special-project-1','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-right'},
    ]"
    :full-width="true"
  >
    <div class="special-projects">
      <div class="u-g-container">
        <UProjectsFilter
          class="special-projects__filter"
          :tabs="tabs"
          @selectTab="selectedTab => currentTab = selectedTab"
          :currentTab="currentTab"
        />
        <UProjects
          v-if="Boolean(items) && Boolean(items.length)"
          class="special-projects__projects"
          :items="items"
        />
        <not-found v-else text="По данным параметрам ничего не найдено" />
        <div
          v-if="isExistsNextPage"
          class="special-projects__footer"
        >
          <CustomButton
            theme="primary"
            size="md"
            @click="loadMore"
            :disabled="isDisabledBtn"
          >
            Показать ещё
          </CustomButton>
        </div>
      </div>
    </div>
 </u-figures>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    initItems: {
      type: Array,
      default: ()=> [
        {
          image: 'https://www.shutterstock.com/image-photo/draughts-board-on-hard-wood-600w-1765485437.jpg',
          category: 'спецпроект',
          partners: ['/frontend/assets/img/projects/logo-1.svg','/frontend/assets/img/projects/logo-2.svg','/frontend/assets/img/projects/logo-3.png'],
          title: '«Наука. Территория героев»',
          text: 'Различные исследования в области медицины, экологии, искусственного интеллекта, генетики, физики, изучения Арктики и других направлениях',
          url: "/special-projects/246/"
        },
        {
          image: 'https://www.shutterstock.com/image-photo/draughts-board-on-hard-wood-600w-1765485437.jpg',
          category: 'спецпроект',
          partners: ['/frontend/assets/img/projects/logo-1.svg','/frontend/assets/img/projects/logo-2.svg','/frontend/assets/img/projects/logo-3.png'],
          title: '«Наука. Территория героев»',
          text: 'Различные исследования в области медицины, экологии, искусственного интеллекта, генетики, физики, изучения Арктики и других направлениях',
          url: "/special-projects/246/"
        },
        {
          image: 'https://www.shutterstock.com/image-photo/draughts-board-on-hard-wood-600w-1765485437.jpg',
          category: 'спецпроект',
          partners: ['/frontend/assets/img/projects/logo-1.svg','/frontend/assets/img/projects/logo-2.svg','/frontend/assets/img/projects/logo-3.png'],
          title: '«Наука. Территория героев»',
          text: 'Различные исследования в области медицины, экологии, искусственного интеллекта, генетики, физики, изучения Арктики и других направлениях',
          url: "/special-projects/246/"
        },
        {
          image: 'https://www.shutterstock.com/image-photo/draughts-board-on-hard-wood-600w-1765485437.jpg',
          category: 'спецпроект',
          partners: ['/frontend/assets/img/projects/logo-1.svg','/frontend/assets/img/projects/logo-2.svg','/frontend/assets/img/projects/logo-3.png'],
          title: '«Наука. Территория героев»',
          text: 'Различные исследования в области медицины, экологии, искусственного интеллекта, генетики, физики, изучения Арктики и других направлениях',
          url: "/special-projects/246/"
        },
        {
          image: 'https://www.shutterstock.com/image-photo/draughts-board-on-hard-wood-600w-1765485437.jpg',
          category: 'спецпроект',
          partners: ['/frontend/assets/img/projects/logo-1.svg','/frontend/assets/img/projects/logo-2.svg','/frontend/assets/img/projects/logo-3.png'],
          title: '«Наука. Территория героев»',
          text: 'Различные исследования в области медицины, экологии, искусственного интеллекта, генетики, физики, изучения Арктики и других направлениях',
          url: "/special-projects/246/"
        },
        {
          image: 'https://www.shutterstock.com/image-photo/draughts-board-on-hard-wood-600w-1765485437.jpg',
          category: 'спецпроект',
          partners: ['/frontend/assets/img/projects/logo-1.svg','/frontend/assets/img/projects/logo-2.svg','/frontend/assets/img/projects/logo-3.png'],
          title: '«Наука. Территория героев»',
          text: 'Различные исследования в области медицины, экологии, искусственного интеллекта, генетики, физики, изучения Арктики и других направлениях',
          url: "/special-projects/246/"
        }
      ]
    },
    tabs: {
      type: Array,
      default: ()=> [
        {
          name: 'Все',
          query: 'all'
        },
        {
          name: 'Активные',
          query: 'active'
        },
      ]
    },
    initNextUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      items: this.initItems,
      currentTab: 'all',
      nextUrl: this.initNextUrl,
      isDisabledBtn: false
    }
  },
  computed: {
    isExistsNextPage() {
      return this.nextUrl && Boolean(this.items) && Boolean(this.items.length)
    }
  },
  methods: {
    async loadMore() {
      await this.updateItems(this.nextUrl)
    },
    async updateItems(url, replace = false) {
      this.isDisabledBtn = true
      const res = await axios.get(url);
      this.nextUrl = res.data.NEXT_URL

      if (replace) {
        this.items = res.data.ITEMS
      } else {
        this.items = [...this.items, ...res.data.ITEMS]
      }

      this.isDisabledBtn = false
    }
  },
  watch: {
    async currentTab() {
      const url = `/special-projects/?PAGEN_1=1&AJAX=Y&list=${this.currentTab}`
      await this.updateItems(url, true)
    }
  },
}
</script>


<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.special-projects';

#{$b} {
  padding: 0 0 128px 0;
  position: relative;
  z-index: 2;

  @include mobile {
    padding: 0 0 64px;
  }

  // .special-projects__filter
  &__filter {
    margin-bottom: 18px;
    transform: translateY(-100%);

    @include mobile {
      margin-bottom: 0;
    }
  }

  // .special-projects__footer
  &__footer {
    margin-top: 32px;

    @include mobile {
      text-align: center;
      margin-top: 24px;
    }
  }
}

#{$b}-figures {
  background-color: $color-secondary;
}
</style>
