<template>
  <div
    v-if="showBtn"
    class="scroll-arrow"
    :class="{ 'scroll-arrow--is-fixed' : isFixed }"
    @click="scrollTop"
  />
</template>

<script>
export default {
  name: "ScrollTopBtn",
  data() {
    return {
      showBtn: false,
      isFixed: true,
    }
  },
  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollPosition() {
      const footerNode = document.querySelector('.footer')

      if (footerNode.getBoundingClientRect().top < window.innerHeight && this.isFixed)
        this.isFixed = false
      if (footerNode.getBoundingClientRect().top > window.innerHeight && !this.isFixed)
        this.isFixed = true

      window.pageYOffset > 200 ? this.showBtn = true : this.showBtn = false;
    },
  },
  mounted() {
    document.addEventListener('scroll', this.scrollPosition);
    this.$root.$on('setVisibleStatus', status => {
      this.showBtn = status
    })
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollPosition);
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: ".scroll-arrow";

#{$b} {
  @include mobile {
    position: absolute;
    cursor: pointer;
    background-color: white;
    box-shadow: 0 16px 48px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 2L5 13' stroke='%23184FFF' stroke-width='1.5'/%3E%3Cpath d='M1 6L5 2L9 6' stroke='%23184FFF' stroke-width='1.5'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    right: 12px;
    bottom: 32px;
    z-index: 22;

    &--is-fixed {
      position: fixed;
    }
  }
}
</style>
