<template>
  <div class="faq-banner">
    <UFigures
      :items="figures"
      position="static"
      :isAnimateImg="false"
      :fullWidth="true"
      startPosition="center bottom"
    >
      <div class="faq-banner__inner">
        <div class="faq-banner__content">
          <h2 class="faq-banner__title title-lg tt-uppercase ff-title" v-html="title" />
          <p class="faq-banner__text text-lg text-lg--mob-default" v-html="text" />
        </div>
        <CustomButton
          class="faq-banner__btn"
          theme="light-black"
          size="2xl"
          @click="$emit('btnClick')"
        >
          Задать вопрос
        </CustomButton>
      </div>
    </UFigures>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      figures: [
        {
          name: 'f-faq-banner-desktop',
          image: '/frontend/assets/img/excursions/circle-group-line-2-light.svg',
          animation: 'fade-in-left'
        },
        {
          name: 'f-faq-banner-mob',
          image: '/frontend/assets/img/bg-shapes/circle-left-opacity-dark.svg',
          animation: 'fade-in-left'
        },
      ],
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.faq-banner';

#{$b} {
  position: relative;
  background-color: $color-primary;
  border-radius: 16px;
  overflow: hidden;
  color: $white-true;

  // .faq-banner__inner
  &__inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 32px;

    @include tablet {
      display: block;
      padding: 24px 16px 24px 16px;
    }

    @include mobile {
      padding-bottom: 48px;
    }
  }

  // .faq-banner__content
  &__content {
    max-width: 796px;
    padding-right: 24px;

    @include tablet {
      padding-right: 0;
      margin-bottom: 32px;
    }
  }

  // .faq-banner__title
  &__title {
    margin: 0 0 24px 0;
  }

  // .faq-banner__text
  &__text {
    margin: 0;
  }

  // .faq-banner__btn
  &__btn {
    align-self: flex-end;
    margin-top: auto;
    margin-left: auto;

    @include mobile {
      margin: 0;
      width: 100%;
    }
  }
}
</style>
