<template>
  <div class="u-lk-ratings">
    <h3 class="u-lk-form__subtitle u-lk-ratings__title">
        Региональные проекты и мероприятия
    </h3>
    <div class="u-lk-ratings__filter">
      <ReportFilter :filters="filters" @onFiltered="onFiltered"/>
    </div>
    <div ref="table" class="u-lk-ratings__fixed">
      <div class="u-lk-ratings__wrapper">
        <div class="u-lk-ratings">
          <div class="u-lk-ratings__row u-lk-ratings__row_head">
            <div class="u-lk-ratings__col">Регион</div>
            <div
              class="u-lk-ratings__col u-lk-ratings__col_toggler"
              v-for="(item, i) in sortItems"
              :key="i"
              @click="sortSet(i)"
              :class="{_active: sortBy === i}"
            >
              <div class="u-lk-ratings__sort u-sorted-icon" :class="{'u-sorted-icon--desc': item.sortType === 'desc'}" />
              <span v-html="item.name" />
            </div>
          </div>
          <div class="u-lk-ratings__row" v-for="(item, i) in countLimit ? sortedItems.slice(0,10) : sortedItems" :key="i">
            <div class="u-lk-ratings__col u-lk-ratings__col_name">{{ item.name }}</div>
            <div class="u-lk-ratings__col">
              <div class="u-lk-ratings__col-info" v-html="sortItems[0].name" />
              {{ item.values[0] | formatNumberLimit }}
            </div>
            <div class="u-lk-ratings__col">
              <div class="u-lk-ratings__col-info" v-html="sortItems[1].name" />
              {{ item.values[1] | formatNumber }}
            </div>
            <div class="u-lk-ratings__col">
              <div class="u-lk-ratings__col-info" v-html="sortItems[2].name" />
              {{ item.values[2] | formatNumber }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="u-lk-ratings__note">
      *Основной показатель рейтинга – количество участников мероприятий в регионе.
    </div>
    <div v-if="sortedItems.length > 10" class="u-lk-ratings__actions">
      <CustomButton
        theme="primary"
        size="md"
        @click="countLimitToggle"
      >
        {{ countLimit ? 'Все регионы' : 'Скрыть' }}
      </CustomButton>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LkRaportRatings",
  props: {
    momentFilterList: {
      type: Array,
      default: () => [
        {
          filter: 'all',
          name: 'все',
          isActive: true
        },
        {
          filter: 'today',
          name: 'на текущий момент',
          isActive: false
        },
        {
          filter: 'week',
          name: 'за предыдущую неделю',
          isActive: false
        },
        {
          filter: 'month',
          name: 'за предыдущий месяц',
          isActive: false
        }
      ]
    },
    initItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      sortBy: 0,
      countLimit: true,
      filters: [],
      sortItems: [
        {
          name: 'Количество проектов/мероприятий',
          sortType: 'desc'
        },
        {
          name: 'Онлайн участники',
          sortType: 'desc'
        },
        {
          name: 'Оффлайн участники',
          sortType: 'desc'
        }
      ],
      items: []
    }
  },
  computed: {
    sortedItems() {
      const vm = this;
      let result = vm.items.sort(function (a, b) {
        return parseFloat(a.values[vm.sortBy]) - parseFloat(b.values[vm.sortBy]);
      });
      if (vm.sortItems[vm.sortBy].sortType === 'asc') {
        return result;
      } else {
        return result.reverse();
      }
    },
    activeFilterItem() {
      return this.filters.find(item => item.isActive)
    }
  },
  mounted() {
    this.filters = this.momentFilterList;
    this.items = this.initItems;
  },
  methods: {
    sortSet(sortBy) {
      this.sortBy = sortBy;
      if (this.sortItems[sortBy].sortType === 'asc') {
        this.sortItems[sortBy].sortType = 'desc';
      } else {
        this.sortItems[sortBy].sortType = 'asc';
      }
    },
    async onFiltered() {
      const options = {
        url: '/bitrix/services/main/ajax.php?mode=class&c=dalee:event.region.rating&action=filter',
        method: 'POST'
      };

      const {data} = await axios({...options, data: {period: this.activeFilterItem.filter}});
      this.items = data.data;
    },
    countLimitToggle() {
      if (!this.countLimit) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: this.$refs.table,
            duration: 500,
            offset: -50,
          })
        });
      }
      this.countLimit = !this.countLimit;
    }
  }
}
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: '.u-lk-ratings';
$mobileOffset: 145px;

#{$b} {
  font-family: $font-family-inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: $black-true;

  &__fixed {
    @include mobile {
      position: relative;
    }
  }

  // .u-lk-ratings__title
  &__title {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .u-lk-ratings__filter
  &__filter {
    margin-bottom: 34px;
  }

  &__wrapper {
    @include mobile {

    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    @include mobile {
      display: block;
      padding: 34px 24px 24px 24px;
      border-radius: 16px;
      background-color: $white-true;
    }

    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }

    &_head {
      border-bottom: 1px solid rgba($color-black, .2);
      margin-bottom: 18px;
      padding-bottom: 24px;
      color: $color-info-secondary;

      @include mobile {
        display: none;
      }
    }
  }

  &__col {
    position: relative;
    width: calc((100% - 120px) / 4);
    @include tablet {
      font-size: 14px;
    }

    @include mobile {
      width: 100%;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }

    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }

    &_toggler {
      height: fit-content;
      cursor: pointer;
    }

    &_name {
      @include mobile {
        padding: 0 0 24px 0;
        margin-bottom: 24px;
        border-bottom: 1px solid rgba($black-true, 0.25)
      }
    }

    &-info {
      @include mobile-min {
        display: none;
      }
      width: 100%;
      max-width: calc(100% - 80px);
      padding-right: 20px;
      color: $color-info-secondary;
    }
  }

  &__sort {
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%)
  }

  &__note {
    font-size: 16px;
    line-height: 130%;
    color: rgba(32, 32, 32, 0.5);
    margin-top: 20px;

    @include mobile {
      margin-top: 24px;
    }
  }

  &__actions {
    margin-top: 32px;

    @include mobile {
      text-align: center;
      margin-top: 24px;
    }
  }
}
</style>
