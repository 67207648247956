<template>
  <div>
    <div class="g-h2 lk-form__title lk-form__subscribe-row">
      Подписки
      <button v-if="!isSubscribedAll" class="subscribe-list__button _white" @click="subscribeAll">Подписаться на все</button>
      <button v-else class="subscribe-list__button _white" @click="unSubscribeAll">Отписаться от всех</button>
    </div>
    <div class="subscribe-list">
      <div v-for="item in subscribeList" :key="item.id" class="subscribe-list__item-wrapper">
        <div class="subscribe-list__item g-blur-bg">
          <div class="subscribe-list__item-title" v-html="item.title"></div>
          <div v-if="item.selectList" class="select">
            <v-select
              v-model="item.selectedValue"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="item.selectList"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
          </div>
          <button
            v-if="!item.subscribed"
            class="subscribe-list__button"
            :class="{ _white: !item.subscribed }"
            @click="subscribeItem(item)"
          >Подписаться</button>
          <button
            v-else
            class="subscribe-list__button"
            :class="{ _white: !item.subscribed }"
            @click="unsubscribeItem(item)"
          >Отписаться</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "LkSubscribe",
  props: {
    subscribeOptions: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      subscribeList: this.subscribeOptions,
      OpenIndicator: {
        render: createElement => createElement('span')
      },
    }
  },
  components: {vSelect},
  computed: {
    isSubscribedAll() {
      return this.subscribeList.every(item => item.subscribed === true);
    }
  },
  methods: {
    async unSubscribeAll() {
      const response = await axios.post(
        '/bitrix/services/main/ajax.php?mode=class&c=dalee:subscribe_form&action=unsubscribeAll',
      );

      if (response.data.status === 'error') {
        alert('Ошибка!');
      } else {
        this.subscribeList.forEach(item => item.subscribed = false);
      }
    },
    async subscribeAll() {
      const response = await axios.post(
        '/bitrix/services/main/ajax.php?mode=class&c=dalee:subscribe_form&action=subscribeAll',
      );

      if (response.data.status === 'error') {
        alert('Ошибка!');
      } else {
        this.subscribeList.forEach(item => item.subscribed = true);
      }
    },
    async subscribeItem(item) {
      const response = await axios.post(
        '/bitrix/services/main/ajax.php?mode=class&c=dalee:subscribe_form&action=subscribe',
        { id: item.id }
      );

      if (response.data.status === 'error') {
        alert('Ошибка!');
      } else {
        item.subscribed = !item.subscribed;
      }
    },
    async unsubscribeItem(item) {
      const response = await axios.post(
        '/bitrix/services/main/ajax.php?mode=class&c=dalee:subscribe_form&action=unsubscribe',
        { id: item.id }
      );

      if (response.data.status === 'error') {
        alert('Ошибка!');
      } else {
        item.subscribed = !item.subscribed;
      }
    }
  }
}
</script>
