<template>
  <div class="u-social">
    <div class="u-social__inner">
      <div class="u-social__subscribe">
        <div class="u-social__subscribe-label title-md">
          Подписаться
        </div>
        <div class="u-social__subscribe-btns">
          <a href="https://t.me/npnauka" target="_blank" rel="noopener noreferrer" class="u-social__subscribe-btn">
            <InlineSvg class="u-social__subscribe-icon" src="u-social/tg" />
          </a>
          <a href="https://vk.com/sciencerf" target="_blank" rel="noopener noreferrer" class="u-social__subscribe-btn">
            <InlineSvg class="u-social__subscribe-icon" src="u-social/vk" />
          </a>
        </div>
      </div>
      <div class="u-social__separator" />
      <div class="u-social__sharing">
        <div class="u-social__sharing-label text-md fw-700">
          Поделиться
        </div>
        <div class="u-social__sharing-links">
          <a :href="VKSharingUrl" target="_blank" class="u-social__link">
            <InlineSvg class="u-social__link-svg" src="u-social/vk" />
          </a>
          <a :href="TGSharingUrl" target="_blank" class="u-social__link">
            <InlineSvg class="u-social__link-svg" src="u-social/tg" />
          </a>
          <a :href="OKSharingUrl" target="_blank" class="u-social__link">
            <InlineSvg class="u-social__link-svg" src="u-social/ok" />
          </a>
          <button class="u-social__link u-social__link--share" @click="copyCurrentUrl">
            <InlineSvg class="u-social__link-svg" src="u-social/share" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import saveInBuffer from '@/components/helpers/saveInBuffer.js'
import decodeCyrillicDomain from '@/components/helpers/decodeCyrillicDomain.js'

export default {
  props: {
    sharingUrl: {
      type: String,
      default: ''
    },
    sharingTitle: {
      type: String,
      default: ''
    },
    sharingDescription: {
      type: String,
      default: ''
    },
    sharingImage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentUrl: this.sharingUrl,
    }
  },
  created() {
    if (!this.sharingUrl) {
      this.currentUrl = window.location.href
    }
  },
  computed: {
    VKSharingUrl() {
      return `https://vk.com/share.php?${this.assembleQueryString({url: this.formattedUrl, title: this.sharingTitle, image: this.sharingImage})}`;
    },
    TGSharingUrl() {
      return `https://t.me/share/url?${this.assembleQueryString({url: this.formattedUrl, text: this.sharingDescription || this.sharingTitle})}`;
    },
    OKSharingUrl() {
      return `https://connect.ok.ru/offer?${this.assembleQueryString({url: this.formattedUrl, title: this.sharingTitle, description: this.sharingDescription, image: this.sharingImage})}`;
    },
    formattedUrl() {
      return decodeCyrillicDomain(this.currentUrl)
    }
  },
  methods: {
    copyCurrentUrl() {
      saveInBuffer(this.formattedUrl)
    },
    assembleQueryString(data) {
      let list = [];
      for (let p in data) {
        if (data[p]) {
          list.push(`${p}=${data[p]}`);
        }
      }
      return list.join('&');
    },
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: ".u-social";
$item-size: 32px;
$item-size-mob: 48px;

#{$b} {
  position: relative;

  // .u-social__inner
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @include tablet {
      flex-direction: column;
      gap: 34px;
    }
  }

  // .u-social__subscribe
  &__subscribe {
    display: inline-flex;
    align-items: center;
    background-color: $color-base-origin;
    border-radius: 64px;
    padding: 2px 2px 2px 24px;
    gap: 16px;

    @include mobile {
      width: 100%;
      max-width: 420px;
      justify-content: space-between;
    }

    // .u-social__subscribe-label
    &-label {
      color: $white-true;

      @include mobile {
        font-size: 16px;
      }
    }

    // .u-social__subscribe-btns
    &-btns {
      display: inline-flex;
      gap: 2px;
    }

    // .u-social__subscribe-btn
    &-btn {
      width: 64px;
      height: 48px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: $white-true;
      padding: 16px;
      border-radius: 4px;
      color: $black-true;
      transition: $transtion-default;
      cursor: pointer;

      @include mobile-sm {
        width: 40px;
        height: 40px;
        padding: 8px;
      }

      &:hover {
        background-color: $black-true;
        color: $white-true;
      }

      &:first-child {
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
      }

      &:last-child {
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;
      }
    }
  }

  // .u-social__separator
  &__separator {
    width: 100%;
    height: 1px;
    background-color: $black-true;

    @include tablet {
      display: none;
    }
  }

  // .u-social__sharing
  &__sharing {
    display: inline-flex;
    align-items: center;
    gap: 18px;
    padding-right: 10px;

    @include tablet {
      flex-direction: column;
      gap: 10px;
      padding-right: 0;
    }

    @include mobile {
      width: 100%;
    }

    // .u-social__sharing-links
    &-links {
      display: inline-flex;
      align-items: center;
      gap: 10px;

      @include mobile {
        width: 100%;
        padding: 0 10px;
        justify-content: space-between;
        max-width: 420px;
      }
    }
  }

  // .u-social__link
  &__link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: $item-size;
    padding: 6px;
    width: $item-size;
    height: $item-size;
    border-radius: 50%;
    border: 1px solid transparent;
    cursor: pointer;
    background-color: $white-true;
    outline: none;
    text-decoration: none;
    color: $black-true;
    transition: $transtion-default;
    box-shadow: 0px 3px 7px 1px rgba($black-true, 0.05);

    @include tablet {
      min-width: $item-size-mob;
      width: $item-size-mob;
      height: $item-size-mob;
      padding: 10px;
    }

    // .u-social__link-svg
    &-svg {
      max-width: 100%;
      max-height: 100%;

      #{$b}__link--share & {
        path {
          fill: transparent !important;
          stroke: currentColor;
        }
      }
    }

    &:hover {
      color: $color-base;
      border-color: $color-base;
    }
  }
}
</style>
