<template>
  <div
    class="brandstyle-font"
    :class="{'brandstyle-font--primary': font.isPrimary}"
  >
    <div class="brandstyle-font__header">
      {{font.header}}
    </div>
    <div class="brandstyle-font__body">
      <div
        v-for="(item,i) in font.items"
        :key="`brandstyle-font__item-${i}`"
        class="brandstyle-font__item"
        :class="`fw-${item.fontWeight}`"
      >
        <div class="brandstyle-font__word">
          {{item.word}}
        </div>
        <div class="brandstyle-font__text">
          {{item.text}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    font: {
      type: Object,
      require: true
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.brandstyle-font';

#{$b} {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: $font-family-mulish;
  border-radius: 16px;
  background-color: rgba($white-true, 0.8);
  color: $black-true;
  padding: 24px;

  @include mobile {
    height: 100%;
  }

  &--primary {
    background-color: $color-base;
    color: $white-true;
    @include mobile-min {
      max-width: 33%;
    }
  }

  // .brandstyle-font__header
  &__header {
    font-family: $font-family-inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.1em;
  }

  // .brandstyle-font__body
  &__body {
    display: flex;
    justify-content: space-between;
  }

  // .brandstyle-font__word
  &__word {
    font-size: 158px;
    line-height: 100%;
    margin: 32px 0;

    @include low-desktop {
      font-size: 130px;
    }
    @include tablet {
      font-size: 90px;
    }
    @include mobile {
      font-size: 158px;
    }
  }

  // .brandstyle-font__text
  &__text {
    font-size: 28px;
    line-height: 100%;

    @include tablet {
      font-size: 18px;
    }

    @include mobile {
      font-size: 28px;
    }
  }
}
</style>
