<template>
  <div class="application-table">
    <div class="application-table__inner" ref="applicationInner">
      <div class="application-table__header g-hidden-mobile _color-secondary-info">
        <div class="application-table__row application-table__row--head">
          <div
            v-for="item in tableHeadInit"
            :key="`application-table__col-header-${item.key}`"
            class="application-table__col"
          >
            <div
              v-if="item.sorted"
              class="application-table__col-item application-table__col-item--sorted"
              @click="setSortedType(item.key)"
            >
              <div
                class="application-table__sorted-btn u-sorted-icon"
                :class="{'u-sorted-icon--desc': getSortOrder(item.key) === 'DESC'}"
              />
              <div>{{ item.name }}</div>
            </div>
            <div v-else class="application-table__col-item">
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="application-table__body">
        <div
          v-for="item in filteredTableBody"
          :key="`application-table__row-${item.id}`"
          class="application-table__row"
          :class="{'application-table__row--is-hidden-mobile': !item.isVisible}"
        >
          <div class="application-table__col application-table__col--name">
            <a
              v-if="item.link"
              :href="item.link"
              target="_blank"
              class="application-table__col-item"
            >
              {{ item.name }}
            </a>
            <div v-else class="application-table__col-item">
              {{ item.name }}
            </div>
          </div>
          <div class="application-table__col">
            <div
              class="application-table__col-item"
              :class="`application-table__col-item--status-${item.status.code}`"
            >
              <div class="g-visible-mobile _color-secondary-info">Статус</div>
              <div class="application-table__col-value">{{ item.status.title }}</div>
            </div>
          </div>
          <div class="application-table__col">
            <div class="application-table__col-item">
              <div class="g-visible-mobile _color-secondary-info">Дата</div>
              <div class="application-table__col-value">{{ item.date }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isCanLoadmore" class="application-table__actions">
      <CustomButton
        class="application-table__show-more"
        size="md"
        theme="outline-primary"
        @click="showMore"
      >
        Показать ещё
      </CustomButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableHeadInit: {
      type: Array,
      default: () => []
    },
    tableBodyInit: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sortItems: {
        date: {
          type: 'DESC',
          query: 'date',
          sortedFn: this.sortDates
        }
      },
      activeSortQuery: "date",
      activeSortType: "DESC", // DESK || ASC
      filterStatusList: [],
      maxVisibleInMobile: 5,
    }
  },
  computed: {
    filteredTableBody() {
      return this.tableBodyInit
        .filter((item) => {
          if (!this.filterStatusList.length || (this.filterStatusList.includes(item.status.code))) {
            return item
          }
        })
        .sort(this.sortItems[this.activeSortQuery].sortedFn)
        .map((item, i) => {
          return {
            ...item,
            isVisible: i+1 <= this.maxVisibleInMobile
          }
        })
    },
    isCanLoadmore() {
      return this.maxVisibleInMobile < this.filteredTableBody.length
    }
  },
  methods: {
    sortDates(item1, item2) {
      const date1 = Date.parse(this.parseDateFromStr(item1.date))
      const date2 = Date.parse(this.parseDateFromStr(item2.date))

      return this.sortItems.date.type === 'DESC' ? (date2 - date1) : (date1 - date2);
    },
    parseDateFromStr(date) {
      let thisDate = date

      if (!(date instanceof Date && !isNaN(date))) {
        const partialsDate = date.split('.')
        const dd = partialsDate[0]
        const mm = partialsDate[1]
        const yy = partialsDate[2]

        thisDate = new Date(yy, mm-1, dd)
      }

      return thisDate
    },
    toggleTypeSort(name) {
      const currentType = this.sortItems[name].type

      if (currentType === 'ASC') {
        this.sortItems[name].type = 'DESC'
      } else {
        this.sortItems[name].type = 'ASC'
      }
    },
    setSortedType(name) {
      this.toggleTypeSort(name)

      this.activeSortQuery = this.sortItems[name].query
      this.activeSortType = this.sortItems[name].type
    },
    getSortOrder(name) {
      return this.sortItems[name].type
    },
    setFilterStatus(filterList) {
      this.filterStatusList = filterList
    },
    showMore() {
      const bottom = this.$refs.applicationInner.getBoundingClientRect().bottom

      this.maxVisibleInMobile += 5

      window.scrollTo({
        top:  window.scrollY + bottom,
        behavior: 'smooth'
      });
    }
  },
  mounted() {
    this.$root.$on('applicationFilter', this.setFilterStatus)
  },
  beforeDestroy() {
    this.$root.$off('applicationFilter', this.setFilterStatus)
  }
}
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: '.application-table';

#{$b} {
  color: $black-true;
  font-family: $font-family-inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;

  // .application-table__row
  &__row {
    display: grid;
    grid-template-columns: auto 296px 272px;
    gap: 80px;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba($black-true, 0.2);

    @include low-desktop {
      grid-template-columns: auto 265px 160px;
    }

    @include tablet {
      grid-template-columns: auto 220px 100px;
      gap: 40px;
    }

    @include mobile {
      display: block;
      border: none;
      padding: 34px 24px 24px 24px;
      border-radius: 16px;
      background-color: $white-true;
    }

    &:not(:first-child) {
      @include mobile {
        margin-top: 24px;
      }
    }

    &:not(#{$b}__row--head) {
      padding-top: 32px;
    }

    &--head {
      color: $color-info-secondary;
    }

    // .application-table__row--is-hidden-mobile
    &--is-hidden-mobile {
      @include mobile {
        display: none;
      }
    }
  }

  // .application-table__col
  &__col {

    // .application-table__col--name
    &--name {
      @include mobile {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid rgba($black-true, 0.25)
      }
    }

    &:not(:last-child) {
      @include mobile {
        padding-bottom: 24px;
      }
    }

    // .application-table__col-item
    &-item {
      display: flex;
      align-items: center;

      @include mobile {
        justify-content: space-between;
      }

      &--status-approved {
        color: $color-succes;
      }
      &--status-declined {
        color: $color-danger;
      }

      // .application-table__col-item--sorted
      &--sorted {
        cursor: pointer;
        user-select: none;
      }
    }

    // .application-table__col-value
    &-value {
      @include mobile {
        padding-left: 16px;
      }
    }
  }

  // .application-table__sorted-btn
  &__sorted-btn {
    margin-right: 8px;
  }

  // .application-table__actions
  &__actions {
    @include mobile-min {
      display: none;
    }
    @include mobile {
      text-align: center;
      margin-top: 48px;
    }
  }
}

</style>
