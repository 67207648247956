<template>
  <u-figures
    :items="[
      {'name':'f-lk-application-history','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
    ]"
    :full-width="true"
    :is-overflow="true"
    position="static"
  >
    <div class="application-history">
      <div class="application-history__header">
        <h2 class="application-history__title tt-uppercase">
          Ваши предложенные мероприятия
        </h2>
        <ApplicationFilter
          v-if="filterList && filterList.length"
          :filterList="filterList"
          class="application-history__filter"
        />
      </div>
      <div class="application-history__body">
        <ApplicationTable
          v-if="tableBodyInit && tableBodyInit.length > 0"
          :tableBodyInit="tableBodyInit"
          :tableHeadInit="tableHeadInit"
        />
        <not-found v-else text="Вы ещё не создавали мероприятия" />
      </div>
      <div class="application-history__footer">
        <div class="application-history__actions">
          <CustomButton
            class="application-history__btn"
            theme="primary"
            size="md"
            @click="goToEventsFormTab"
          >
            Предложить ещё мероприятие
          </CustomButton>
        </div>
      </div>
    </div>
  </u-figures>
</template>

<script>
export default {
  props: {
    filterList: {
      type: Array,
      default: () => []
    },
    tableHeadInit: {
      type: Array,
      default: () => [
        {
          name: 'Ваши предложенные мероприятия',
          key: 'name',
          sorted: false
        },
        {
          name: 'Статус',
          key: 'status',
          sorted: false
        },
        {
          name: 'Дата',
          key: 'date',
          sorted: true
        }
      ]
    },
    tableBodyInit: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    goToEventsFormTab() {
      this.$root.$emit('header-tabs-change', 'add-event')
      setTimeout(() => {
        this.$root.$emit('scrollToPlanForm')
      });
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.application-history';

#{$b} {
  color: $black-true;
  font-family: $font-family-inter;
  position: relative;

  // .application-history__title
  &__title {
    font-family: $font-family-main-head;
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 100%;
    margin: 0;

    @include mobile {
      font-size: 28px;
      margin-bottom: 24px;
    }
  }

  // .application-history__header
  &__header {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 48px;
    }
  }

  // .application-history__filter
  &__filter {
    margin-top: 64px;

    @include mobile {
      margin-top: 48px;
    }
  }

  // .application-history__body
  &__body {
    margin-bottom: 64px;

    @include mobile {
      margin-bottom: 48px;
    }
  }

  // .application-history__actions
  &__actions {
    @include mobile {
      text-align: center;
    }
  }

  // .application-history__footer-info
  &__footer-info {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    color: rgba($color-black, 0.5);
    margin: 0 0 32px 0;

    @include mobile {
      margin-bottom: 48px;
    }
  }
}
</style>
