<template>
  <div v-if="isActive" class="tabs-panel">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    title: { type: String, default: "" },
    selected: { type: Boolean, default: false },
    url: { type: String, default: "" },
  },
  data() {
    return {
      isActive: false
    };
  },
  methods: {
    changeTab(name) {
      this.title === name ? this.isActive = true : this.isActive = false;
    }
  },
  mounted() {
    this.isActive = this.selected;
    this.$root.$on('tab-change', this.changeTab);
  },
  destroyed() {
    this.$root.$off('tab-change', this.changeTab);
  }
};
</script>
