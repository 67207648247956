<template>
  <div>
    <div class="news-page__filter">
      <div class="news-page__filter-title g-side-line">Популярные темы</div>
      <ul class="news-filter__tags">
        <li
          v-for="item in themesList"
          :key="item.id"
          class="news-filter__tags-item"
        >
          <input v-model="selectedThemes" name="themes" :value="item.id" :id="`theme-${item.id}`" type="checkbox">
          <label :for="`theme-${item.id}`">
            <span>#{{item.name}}</span>
            <inline-svg src="close"/>
          </label>
        </li>
      </ul>
      <div class="news-filter__bottom">
        <div class="news-filter__tabs" v-if="timePeriod">
          <ul class="news-filter__tabs-list">
            <li
              v-for="item in timePeriod"
              class="news-filter__tabs-item"
              :key="item.id"
              :class="{_active: item.id == currentTimePeriod }"
              @click="setTimePeriod(item)"
            >{{item.name}}</li>
          </ul>
        </div>
        <div class="news-filter__select select" v-if="regionOptions">
          <v-select
            v-model="selectedRegion"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="regionOptions"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
      </div>
    </div>
    <div class="news-page__body">
      <div v-if="newsItems.length">
        <a
          v-for="item in newsItems"
          class="news-item g-blur-bg"
          :key="item.id"
          :href="hrefWithRegion(item.url)"
        >
          <div class="news-item__container">
            <div class="news-item__info">
              <div class="news-item__date" v-html="item.date"></div>
              <div class="news-item__title" v-html="item.title"></div>
              <div class="news-item__text" v-html="item.text" v-if="item.text"></div>
            </div>
            <div class="news-item__img" :style="`background-image:url('${item.image}');`" v-if="item.image"></div>
          </div>
        </a>
      </div>
      <not-found v-else text="По данным параметрам ничего не найдено" />
      <div class="news-page__more g-side-line" v-show="paginator.NavPageNomer < paginator.NavPageCount">
        <a href="#" @click.prevent="loadMoreItems(false)">Показать ещё</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'

export default {
  name: "NewsPage",
  components: { vSelect },
  props: {
    url: {
      type: String,
      required: true
    },
    regionOptions: {
      type: Array,
      default: () => [
        {id: '', name: 'Все регионы'},
        {id: '1', name: 'не все'}
      ]
    },
    timePeriod: {
      type: Array,
      default: () => [
        {id: '0', name: 'Все'},
        {id: '1', name: 'Сегодня'}
      ]
    },
    themesList: {
      type: Array,
      default: () => [
        {id: '0', name: 'наука'},
        {id: '1', name: 'медицина'},
        {id: '2', name: 'COVID-19'},
        {id: '3', name: 'алтгу'},
        {id: '4', name: 'исследования'},
        {id: '5', name: 'генетика'},
        {id: '6', name: 'биология'},
        {id: '7', name: 'химия'},
        {id: '8', name: 'экология'},
        {id: '9', name: 'история'},
        {id: '10', name: 'новые материалы'},
        {id: '11', name: 'климат'},
        {id: '12', name: 'связность территорий'}
      ]
    },
    initialNewsItems: {
      type: Array,
      default: () => [
        {
          id: '0',
          date: '22 марта',
          title: 'Начался прием заявок на&nbsp;конкурс &laquo;Моя страна&nbsp;&mdash; моя Россия&raquo;',
          text: 'Алексей Комиссаров сообщил, что всероссийский конкурс пройдет по&nbsp;17&nbsp;номинациям',
          image: '/frontend/assets/img/news-01.jpg',
          url: '#'
        },
        {
          id: '1',
          date: '21 марта',
          title: 'Первую ярославскую гибридную электростанцию отправят на Крайний Север',
          text: 'В Ярославской области разработаны электростанции, способные работать от дизельного топлива, энергии ветра и солнца',
          image: '/frontend/assets/img/news-01.jpg',
          url: '#'
        },
        {
          id: '2',
          date: '20 марта',
          title: 'Ученые АлтГУ разработали пленку с антимикробными свойствами для восстановления кожи',
          text: 'Ученые лаборатории клеточных технологий и экспериментальной фармакологии при поддержке внутриуниверситетского гранта завершили один из этапов проекта по регенеративной медицине',
          image: '/frontend/assets/img/news-01.jpg',
          url: '#'
        },
        {
          id: '0',
          date: '22 марта',
          title: 'Начался прием заявок на&nbsp;конкурс &laquo;Моя страна&nbsp;&mdash; моя Россия&raquo;',
          text: 'Алексей Комиссаров сообщил, что всероссийский конкурс пройдет по&nbsp;17&nbsp;номинациям',
          image: '/frontend/assets/img/news-01.jpg',
          url: '#'
        },
        {
          id: '0',
          date: '22 марта',
          title: 'Начался прием заявок на&nbsp;конкурс &laquo;Моя страна&nbsp;&mdash; моя Россия&raquo;',
          text: 'Алексей Комиссаров сообщил, что всероссийский конкурс пройдет по&nbsp;17&nbsp;номинациям',
          image: '/frontend/assets/img/news-01.jpg',
          url: '#'
        }
      ]
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    initPaginator: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      selectedRegion: "",
      currentTimePeriod: "0",
      selectedThemes: [],
      newsItems: this.initialNewsItems,
      nextUrl: this.initNextUrl,
      paginator: this.initPaginator,
    }
  },
  watch: {
    selectedThemes() {
      this.loadFirstPage();
    },
    selectedRegion() {
      this.loadFirstPage();
    },
    currentTimePeriod() {
      this.loadFirstPage();
    }
  },
  methods: {
    setTimePeriod(value) {
      this.currentTimePeriod = value.id;
    },
    loadFirstPage() {
      const lines = [];
      if (this.selectedThemes.length) {
        this.selectedThemes.map(id => {
          lines.push(`sid[]=${id}`);
        });
      }
      if (this.selectedRegion) {
        lines.push(`rid=${this.selectedRegion}`);
      }
      if (this.currentTimePeriod) {
        lines.push(`period=${this.currentTimePeriod}`);
      }
      const params = lines.join("&");
      this.nextUrl = `?AJAX=Y&PAGEN_${this.paginator.NavNum}=1&${params}`;
      this.loadMoreItems(true);
    },
    async loadMoreItems(clearItems) {
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.NEXT_URL;
      this.paginator = res.data.PAGINATOR;
      if (clearItems) {
        this.newsItems = [];
      }
      res.data.ITEMS.map(item => {
        this.newsItems.push(item);
      });
    }
  }
}
</script>

