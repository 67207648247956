<template>
  <div
    class="video-block"
    :class="{ 'is-played': isPlayed }"
  >
    <div v-if="!isYoutube" class="video-block__play" @click="play">
      <inline-svg src="play-btn-2"></inline-svg>
    </div>
    <div class="video-block__video">
      <template v-if="isYoutube">
        <iframe
          class="video-block__video-item"
          :src="src"
          width="100%"
          height="100%"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </template>
      <template v-else>
        <object-fit-video
          ref="video"
          class="video-block__video-item"
          :poster="poster ? poster : false"
        >
          <source :src="src" />
        </object-fit-video>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoBlock",
  props: {
    src: {
      type: String,
      default: ""
    },
    poster: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isYoutube: false,
      isPlayed: false,
      video: undefined,
    };
  },
  mounted() {
    this.video = this.$refs.video.$el;
    this.isYoutube = this.matchYoutubeUrl(this.src);
    this.video.addEventListener('pause', this.pause);
  },
  beforeDestroy() {
    this.video.removeEventListener("pause", this.pause);
  },
  methods: {
    matchYoutubeUrl(url) {
      var regEx = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      return regEx.test(url);
    },
    play() {
      if (this.video) {
        this.isPlayed = true;
        this.video.play();
        this.video.setAttribute('controls', 'controls');
      }
    },
    pause() {
      this.isPlayed = false;
      this.video.pause();
      this.video.removeAttribute('controls');
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".video-block";

#{$b} {
  display: block;
  position: relative;

  &:not(:first-child) {
    margin-top: 60px;

    @include low-desktop {
      margin-top: 50px;
    }

    @include mobile {
      margin-top: 32px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 60px;

    @include low-desktop {
      margin-bottom: 50px;
    }

    @include mobile {
      margin-bottom: 32px;
    }
  }

  &__video {
    display: block;

    &::before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }
  }

  &__video-item {
    @include full-abs;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__play {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background-color: $white-true;
    color: $color-base;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    @include mobile {
      width: 50px;
      height: 50px;
      border-radius: 50px;
    }

    #{$b}.is-played & {
      opacity: 0;
      visibility: hidden;
    }

    .inline-svg {
      flex-shrink: 0;
      width: 11px;
      height: 15px;
      margin-left: 4px;

      @include mobile {
        width: 9.17px;
        height: 12.5px;
        margin-left: 2px;
      }
    }
  }
}
</style>
