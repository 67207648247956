<template>
  <div class="science-playground-list" :class="`science-playground-list--type-${type}`">
    <div class="u-g-container">
      <div class="science-playground-list__inner">
        <SciencePlaygroundCard
          class="science-playground-list__card"
          v-for="card in items"
          :key="`science-playground-list__card-${card.id}`"
          :card="card"
          :type="type"
          :enabledYm="enabledYm"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'column' // column | grid
    },
    enabledYm: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.science-playground-list';

#{$b} {

  // .science-playground-list__inner
  &__inner {

    #{$b}--type-column & {
      column-count: 3;
      column-gap: 32px;

      @include low-desktop {
        column-count: 2;
      }

      @include mobile {
        column-count: unset;
        column-gap: unset;
      }
    }

    #{$b}--type-grid & {
      display: grid;
      grid-template-columns: repeat(3, calc((100% - (2 * 32px)) / 3) );
      gap: 32px;

      @include tablet {
        grid-template-columns: repeat(2, calc((100% - 32px) / 2) );
      }

      @include mobile {
        display: block;
      }
    }
  }

  // .science-playground-list__card
  &__card {
    #{$b}--type-column & {
      break-inside: avoid-column;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }

    #{$b}--type-grid & {
      @include mobile {
        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }
    }
  }
}
</style>
