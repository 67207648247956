<template>
  <div class="initiatives-news" :class="sliderStyle">
    <div class="initiatives-news__header">
      <component
        :is="getTitleTag"
        class="initiatives-news__title tt-uppercase title-xl"
        :class="{'initiatives-news__title--is-link': titleLink}"
        :href="hrefWithRegion(titleLink)"
      >
        <span v-html="initialNewsTitle" />
      </component>
      <div class="initiatives-news__controls-swiper">
        <div class="u-swiper-controls">
          <button class="u-swiper-controls__btn u-swiper-controls__btn--prev" :class="`initiatives-news__prev-${this._uid}`" />
          <div class="u-swiper-controls__count " :class="`initiatives-news__controls-${this._uid}`" />
          <button class="u-swiper-controls__btn u-swiper-controls__btn--next" :class="`initiatives-news__next-${this._uid}`" />
        </div>
      </div>
    </div>
    <div class="initiatives-news__body">
      <swiper
        v-if="isUpdatedSwiper"
        ref="initiativesNewsSwiper"
        class="initiatives-news__swiper"
        :options="swiperOptions"
      >
        <swiper-slide v-if="isEditable && !hideActionButtons" class="initiatives-news__swiper-item">
          <AddendumCard class="initiatives-news__addendum-card" @click="addNews">
            Добавить Новости
          </AddendumCard>
        </swiper-slide>
        <swiper-slide
          v-for="(item, index) in getNewsItems"
          :key="`${keyLabel}${item.id}`"
          class="initiatives-news__swiper-item"
        >
          <slot :cardData="item" :id="index" :callParentClickHandler="callParentClickHandler">
            <UNewsCard
              class="initiatives-news__news-card"
              :cardData="item"
            />
          </slot>
          <EditingCard
            v-if="isEditable && !hideActionButtons"
            @edit="editNews(item.id)"
            @remove="removeNews(item.id)"
          />
        </swiper-slide>
      </swiper>
      <div class="initiatives-news__mob-pagination">
        <div class="u-swiper-pagination" :class="`initiatives-news__pagination-${this._uid}`" />
      </div>
    </div>
    <div
      v-if="showMore"
      class="initiatives-news__footer"
    >
      <CustomButton
        theme="primary"
        size="md"
        tag="a"
        :href="btnTarget !== '_blank' ? hrefWithRegion(allNewsLink) : allNewsLink"
        :target="btnTarget"
      >
        <span v-html="btnText" />
      </CustomButton>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  inject: {
    isEditable: {
      from: 'isEditable',
      default: false
    },
  },
  props: {
    initialNewsItems: { type: Array, default: () => [] },
    initialNewsTitle: { type: String, default: '' },
    sliderStyle: { type: String, default: '' },
    showMore: { type: Boolean, default: true },
    allNewsLink: { type: String, default: '/news/' },
    btnText: { type: String, default: 'Показать ещё' },
    initiativeId: { type: String, default: '' },
    actionSave: { type: String, default: '' },
    actionGet: { type: String, default: '' },
    actionDelete: { type: String, default: '' },
    hideActionButtons: { type: Boolean, default: false },
    keyLabel: { type: String, default: 'initiatives-news__swiper-item-' },
    btnTarget: { type: String, default: '' },
    titleLink: { type: String, default: '' },
  },
  data() {
    return {
      isUpdatedSwiper: true,
      paginationEl: `.initiatives-news__controls-${this._uid}`,
      newsItems: JSON.parse(JSON.stringify(this.initialNewsItems)),
    }
  },
   computed: {
    swiper() {
      return this.$refs.initiativesNewsSwiper.$swiper;
    },
    swiperOptions() {
      return {
        slidesPerView: 1.17,
        slidesPerGroup: 1,
        spaceBetween: 16,
        navigation: {
          prevEl: `.initiatives-news__prev-${this._uid}`,
          nextEl: `.initiatives-news__next-${this._uid}`,
          disabledClass: "u-swiper-controls__btn--is-disabled"
        },
        pagination: {
          el: this.paginationEl,
          type: "bullets",
          bulletClass: "u-swiper-pagination__bullet",
          bulletActiveClass: "u-swiper-pagination__bullet--is-active",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          },
          1280: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          }
        },
        windowWidth: null,
      }
    },
    getNewsItems() {
      return this.newsItems.map(news => ({...news, target: this.btnTarget}))
    },
    getTitleTag() {
      return this.titleLink ? 'a' : 'h2'
    },
  },
  methods: {
    updateSwiper() {
      if (!this.windowWidth || this.windowWidth !== window.innerWidth) {
        this.windowWidth = window.innerWidth

        this.isUpdatedSwiper = false
        this.setPaginationEl();
        this.$nextTick(() => {
          this.isUpdatedSwiper = true
        });
      }
    },
    setPaginationEl() {
      if (window.innerWidth > 767) {
        this.paginationEl = `.initiatives-news__controls-${this._uid}`
      } else {
        this.paginationEl = `.initiatives-news__pagination-${this._uid}`
      }
    },
    addNews() {
      this.$root.$refs.customModal.openModal(null, null, true, true);
      this.$root.$refs.customModal.passContent({
        name: 'initiatives-news-form',
        props: {
          initiativeId: this.initiativeId,
          actionSave: this.actionSave,
        },
        title: 'добавить новость',
      })
    },
    async editNews(id) {
      this.$root.$refs.customModal.openModal(null, null, true, true);

      const initialForm = await this.getFormPropsForEdit(id)

      if (!initialForm) {
        this.$root.$refs.customModal.closeModal()
        return
      }

      this.$root.$refs.customModal.passContent({
        name: 'initiatives-news-form',
        props: {
          initiativeId: this.initiativeId,
          actionSave: this.actionSave,
          initialForm,
          idUpdate: id
        },
        title: 'редактировать новость',
      })
    },
    async getFormPropsForEdit(id) {
      const response = await axios(`${this.actionGet}&id=${id}`)

      if (response.data.status === 'success') {
        return response.data.data
      } else {
        const error = response.data && response.data.errors ? response.data.errors
          .map(item => item.message)
          .join('. ')
          : 'Произошла непредвиденная ошибка'
        alert(error)
      }
    },
    async removeNews(id) {
      const options = {
        url: this.actionDelete,
        method: 'POST',
        data: {
          initiativeId: this.initiativeId,
          idDelete: id,
        }
      };

      const response = await axios(options)

      if (response.data.status === 'success') {
        this.newsItems = this.newsItems.filter(item => item.id !== id)
      } else {
        const error = response.data && response.data.errors ? response.data.errors
          .map(item => item.message)
          .join('. ')
          : 'Произошла непредвиденная ошибка'
        alert(error)
      }
    },
    updateInitiativesNews(response) {
       if (response.type === 'update') {
        const index = this.newsItems.findIndex(item => item.id === response.data.id)
        this.newsItems = [...this.newsItems.slice(0, index), response.data, ...this.newsItems.slice(index + 1)]
      } else {
        this.newsItems = [response.data, ...this.newsItems]
      }
    },
    callParentClickHandler(id) {
      this.$emit('button-click', id);
    }
  },
  mounted() {
    this.isUpdatedSwiper = false;
    window.addEventListener('resize', this.updateSwiper);
    this.updateSwiper();
    this.$root.$on('editedInitiativesNews', this.updateInitiativesNews);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSwiper);
    this.$root.$off('editedInitiativesNews', this.updateInitiativesNews)
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: ".initiatives-news";

#{$b} {
  #{$b}__news-card {
    max-width: none;
    height: 100%;
  }

  .swiper-wrapper {
    @include mobile-min {
      padding: 10px 0;
    }
  }
  .swiper-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .swiper-slide {
    height: auto !important;
  }

  // .initiatives-news__header
  &__header {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      display: block;
      margin-bottom: 24px;
    }
  }

  // .initiatives-news__title
  &__title {
    font-family: $font-family-main-head;
    margin: 0 20px 0 0;
    color: $black-true;

    @include mobile {
      margin: 0;
    }

    .u-home-page__science-news & {
      font-weight: 500;
      font-size: 64px;
      line-height: 100%;

      @include mobile {
        font-size: 48px;
      }
    }

    &--is-link {
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
      transition: $transtion-default;

      &:hover {
        color: $color-base-origin;
      }
    }
  }

  // .initiatives-news__controls-swiper
  &__controls-swiper {
    @include mobile {
      display: none;
    }
  }

  // .initiatives-news__swiper-item
  &__swiper-item {
    height: auto;
    position: relative;
    border-radius: 16px;
  }

  // .initiatives-news__body
  &__body {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
      margin-right: -$container-pd-right-mobile;
    }
  }

  // .initiatives-news__mob-pagination
  &__mob-pagination {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    @include mobile-min {
      display: none;
    }
  }

  // .initiatives-news__footer
  &__footer {
    @include mobile {
      text-align: center;
    }
  }

  &._simple {

    @include tablet-min {
      .u-news-card {
        --bg-color: white;
      }
    }

    @include tablet-min {
      .u-news-card__main {
        color: $black-true;
      }
    }

    @include tablet-min {
      .custom-button--theme-outline-light {
        color: $black-true;
        border-color: $black-true;

        &:hover {
          color: $white-true;
          border-color: $white-true;
          background-color: $color-base-origin;
        }
      }
    }
  }

  // .initiatives-news__addendum-card
  &__addendum-card {
    min-height: 400px;
  }
}
</style>
