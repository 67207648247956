<template>
  <u-figures
    :items="[
      {'name':'f-lk-report-1','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
    ]"
    :fullWidth="true"
    position="static"
    :isOverflow="true"
  >
    <div class="u-lk-social-measurements">
      <h2 class="u-lk-social-measurements__title tt-uppercase">
        Социологические замеры
      </h2>
      <div class="u-lk-social-measurements__table">
        <div class="u-lk-social-measurements__table-head _color-secondary-info g-hidden-mobile">
          <div class="u-lk-social-measurements__row">
            <div class="u-lk-social-measurements__col u-lk-social-measurements__col-title">
              <button
                class="u-lk-social-measurements__col-sorted"
                @click="getSortedPageData('name')"
              >
                <div
                  class="u-sorted-icon u-lk-social-measurements__sorted-icon"
                  :class="{'u-sorted-icon--desc': getSortOrder('name') === 'DESC'}"
                />
                <span>Название</span>
              </button>
            </div>
            <div class="u-lk-social-measurements__col">
              <button
                class="u-lk-social-measurements__col-sorted"
                @click="getSortedPageData('date')"
              >
                <div
                  class="u-sorted-icon u-lk-social-measurements__sorted-icon"
                  :class="{'u-sorted-icon--desc': getSortOrder('date') === 'DESC'}"
                />
                <span>Дата</span>
              </button>
            </div>
          </div>
        </div>
        <div class="u-lk-social-measurements__table-body">
          <div
            class="u-lk-social-measurements__row"
            v-for="(item,i) in items"
            :key="`u-lk-social-measurements__row-${i}`"
          >
            <div class="u-lk-social-measurements__col u-lk-social-measurements__col-title">
              <div class="u-lk-social-measurements__col-value">{{ item.name }}</div>
            </div>
            <div class="u-lk-social-measurements__col">
              <div class="u-lk-social-measurements__col-text _color-secondary-info g-visible-mobile">Дата</div>
              <div class="u-lk-social-measurements__col-value">{{ item.date }}</div>
            </div>
            <div class="u-lk-social-measurements__col">
              <div class="u-lk-social-measurements__col-value">
                <a
                  :href="item.link"
                  class="u-lk-social-measurements__col-link"
                  target="_black"
                  download
                >
                  Скачать
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="u-lk-social-measurements__pagination">
        <CustomPagination
          :pagination="{countPage: pagination.countPage, currentPage: pagination.currentPage}"
          @onSelect="updatePageData"
        />
      </div>
    </div>
  </u-figures>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    initItems: {
      type: Array,
      default: () => []
    },
    paginationInit: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      items: this.initItems,
      pagination: this.paginationInit,
      sortItems: {
        name: {
          type: 'DESC',
          query: 'NAME'
        },
        date: {
          type: 'DESC',
          query: 'DATE'
        }
      },
      activeSortQuery: "DATE",
      activeSortType: "DESC"
    }
  },
  methods: {
    async updatePageData(page) {
      if (page > this.pagination.countPage || page < 1) {
        return
      }

      const url = `${this.pagination.generalLnk}${page}&SORT_BY=${this.activeSortQuery}&SORT_ORDER=${this.activeSortType}`
      const response = await axios(url)

      if (response && response.data) {
        this.items = response.data.items
        this.pagination = response.data.pagination
      }
    },
    async getSortedPageData(name) {
      this.toggleTypeSort(name)

      this.activeSortQuery = this.sortItems[name].query
      this.activeSortType = this.sortItems[name].type

      const url = `${this.pagination.generalLnk}${1}&SORT_BY=${this.activeSortQuery}&SORT_ORDER=${this.activeSortType}`
      const response = await axios(url)

      if (response && response.data) {
        this.items = response.data.items
        this.pagination = response.data.pagination
      }
    },
    toggleTypeSort(name) {
      const currentType = this.sortItems[name].type

      if (currentType === 'ASC') {
        this.sortItems[name].type = 'DESC'
      } else {
        this.sortItems[name].type = 'ASC'
      }
    },
    getSortOrder(name) {
      return this.sortItems[name].type
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-lk-social-measurements';

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;
  font-size: 16px;
  line-height: 1.3;
  position: relative;

  // .u-lk-social-measurements__title
  &__title {
    font-family: $font-family-main-head;
    font-weight: 500;
    font-size: 44px;
    line-height: 100%;
    margin: 0 0 64px 0;

    @include mobile {
      font-size: 28px;
      margin-bottom: 48px;
    }
  }

  // .u-lk-social-measurements__table
  &__table {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid rgba($black-true, 0.2);

    // .u-lk-social-measurements__table-head
    &-head {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid rgba($black-true, 0.2);
    }

  }
  // .u-lk-social-measurements__row
  &__row {
    display: grid;
    grid-template-columns: auto 145px 145px;
    gap: 30px;

    &:not(:last-child) {
      margin-bottom: 24px;

      #{$b}__table-head & {
        margin: 0;
      }
    }

    @include tablet {
      grid-template-columns: auto 145px 85px;
    }

    @include mobile {
      display: block;
      border-radius: 16px;
      background-color: $white-true;
      padding: 34px 24px 24px 24px;
    }
  }

  // .u-lk-social-measurements__col
  &__col {

    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }

    @include mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    // .u-lk-social-measurements__col-title
    &-title {
      max-width: 680px;

      @include mobile {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid rgba($black-true, 0.25)
      }
    }

    // .u-lk-social-measurements__col-link
    &-link {
      text-decoration: none;
      color: $color-base-origin;
      margin-left: auto;

      &:hover {
        @include mobile-min {
          text-decoration: underline;
        }
      }

      @include mobile {
        margin: 0 auto;
      }
    }

    // .u-lk-social-measurements__col-sorted
    &-sorted {
      display: inline-flex;
      align-items: center;
      font-size: inherit;
      color: inherit;
      font-weight: inherit;
      text-decoration: none;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      padding: 0;
      margin: 0;
    }

    // .u-lk-social-measurements__col-value
    &-value {
      display: flex;
    }
  }

  // .u-lk-social-measurements__sorted-icon
  &__sorted-icon {
    margin-right: 8px;
  }
}
</style>
