<template>
  <div
    class="universities-banner"
    :class="getRootClasses"
    :style="`background-image: url(${background})`"
  >
    <div class="universities-banner__wrapper">
      <div class="u-g-container">
        <div class="universities-banner__inner">
          <div class="universities-banner__header">
            <img v-if="image" :src="image" class="universities-banner__img-icon g-visible-mobile">
            <h1 class="universities-banner__title title-xll" v-html="title" />
          </div>
          <div v-if="text || image" class="universities-banner__body">
            <div v-if="text" class="universities-banner__text text-lg" v-html="text" />
            <div v-if="image" class="universities-banner__img">
              <img :src="image" class="universities-banner__img-icon g-hidden-mobile">
            </div>
          </div>
          <div v-if="isExistsNavList" class="universities-banner__footer">
            <UniversitiesNav :navList="navList" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    gradientStyle: {
      type: String,
      default: '' // primary
    },
    navList: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    isExistsNavList() {
      return this.navList && this.navList.length
    },
    getRootClasses() {
      return this.gradientStyle ? `universities-banner--gradient-${this.gradientStyle}` : !this.background ? 'universities-banner--gradient-primary' : ''
    }
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.universities-banner';

#{$b} {
  min-height: 400px;
  display: flex;
  color: $white-true;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $black-true;
  padding-top: var(--header-height);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    touch-action: none;
  }

  &--gradient {

    // .universities-banner--gradient-primary
    &-primary {
      &::before {
        background: linear-gradient(269deg, rgba(9, 48, 174, 0.55) 0%, rgba(24, 79, 255, 0.00) 100%);
      }
    }
  }

  @include low-desktop {
    min-height: 320px;
  }

  @include tablet {
    min-height: unset;
  }

  // .universities-banner__wrapper
  &__wrapper {
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    align-items: flex-end;
    z-index: 1;
  }

  // .universities-banner__inner
  &__inner {
    padding-top: 40px;

    @include tablet {
      padding-top: 100px;
    }

    @include mobile {
      padding-top: 60px;
    }
  }

  // .universities-banner__header
  &__header {
    display: flex;
  }

  // .universities-banner__title
  &__title {
    margin: 0;
  }

  // .universities-banner__body
  &__body {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 32px;

    @include mobile {
      display: block;
      margin-top: 18px;
      margin-bottom: 24px;
    }
  }

  // .universities-banner__footer
  &__footer {
    margin-top: 70px;

    @include low-desktop {
      margin-top: 48px;
    }
  }

  // .universities-banner__text
  &__text {
    width: 100%;
    max-width: 860px;
    padding-right: 20px;
    * {
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    @include mobile {
      font-size: 16px;
    }
  }

  // .universities-banner__img
  &__img {
    width: 100%;
    max-width: 150px;

    @include mobile {
      margin-top: 16px;
    }

    // .universities-banner__img-icon
    &-icon {
      width: 100%;
      height: auto;

      @include mobile {
        width: 100%;
        max-width: 40px;
        max-height: 40px;
        margin-right: 10px;
      }
    }
  }
}
</style>
