<template>
    <div class="statistics">
        <h2 class="statistics__title title-xl">Об акции «Наука рядом»</h2>
        <div class="statistics__items">
          <div
            v-for="(item, index) in statisticsNumbers"
            :key="index"
            class="statistics__item statistics-item"
          >
            <div class="statistics-item__value" v-html="item.number"></div>
            <div class="statistics-item__description">
              {{
                index === 0 ? 'участников посетили мероприятия акции' :
                index === 1 ? 'мероприятий прошло с&nbsp;момента запуска акции' :
                'площадок стали партнёрами акции'
              }}
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    statisticsNumbers: {
      type: Array,
      default: () => []
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

.statistics {
    position: relative;
    &__title {
        position: relative;
        font-family: $font-family-main-head;
        font-weight: 500;
        font-size: 44px;
        line-height: 100%;
        margin-bottom: 2rem;
        margin-top: 0;
        text-transform: uppercase;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;

        @include mobile {
            display: block;
        }
    }

    &__item {
        flex-basis: 336px;
        flex-grow: 1;
        flex-shrink: 0;
        margin-bottom: 23px;

        @include mobile {
            width: 100%;
        }
    }

    &-item {
        box-sizing: border-box;
        border-left: 2px solid rgba(34, 34, 34, .5);
        padding: 9px 0 9px 30px;

        @include mobile {
            padding: 1px 0 2px 23px;
        }

        &__value {
            font-family: $font-family-main-head;
            color: $color-base;
            font-weight: 600;
            line-height: 100%;
            font-size: 84px;
            margin-bottom: 14px;

            @include mobile {
                margin-bottom: 8px;
            }
        }

        &__description {
            font-family: $font-family-inter;
            max-width: 236px;
            font-size: 16px;
            font-weight: 400;
            line-height: 130%;

            &--short {
                max-width: 190px;
            }

            @include mobile {
                max-width: 100%;

                &--short {
                    max-width: 100%;
                }
            }
        }
    }
}
</style>
