<template>
  <u-figures
    :items="[
      {'name':'f-lk-subscribe-1','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
    ]"
    :fullWidth="true"
    position="static"
    :isOverflow="true"
  >
    <div class="u-lk-subscribe">
      <div class="u-lk-subscribe__header">
        <h2 class="u-lk-form__title">Подписки</h2>
        <div class="g-hidden-mobile">
          <CustomButton
            v-if="!isSubscribedAll"
            class="u-subscribe-list__button"
            theme="primary"
            size="md"
            @click="subscribeAll"
          >
            Подписаться на всё
          </CustomButton>
          <CustomButton
            v-else
            class="u-subscribe-list__button"
            theme="outline-primary"
            size="md"
            @click="unSubscribeAll"
          >
            Отписаться от всех
          </CustomButton>
        </div>
      </div>
      <div class="u-subscribe-list">
        <div
          v-for="item in subscribeList"
          :key="item.id"
          class="u-subscribe-list__item"
          :class="{ 'u-subscribe-list__item--subscribed': item.subscribed }"
        >
          <img
            v-if="!item.subscribed"
            class="u-subscribe-list__item-shape"
            src="@/assets/img/bg-shapes/circle-group-left-opacity-part.svg"
          >
          <div class="u-subscribe-list__item-title title-lg tt-uppercase" v-html="item.title" />
          <div
            class="u-subscribe-list__item-description"
            v-if="item.description"
            v-html="item.description"
          />
          <CustomButton
            v-if="!item.subscribed"
            class="u-subscribe-list__item-btn"
            theme="outline-primary"
            @click="subscribeItem(item)"
          >
            Подписаться
          </CustomButton>
          <CustomButton
            v-else
            class="u-subscribe-list__item-btn"
            theme="outline-light"
            @click="unsubscribeItem(item)"
          >
            Отписаться
          </CustomButton>
        </div>
      </div>
      <div class="u-lk-subscribe__footer g-visible-mobile">
        <CustomButton
          v-if="!isSubscribedAll"
          class="u-subscribe-list__button"
          theme="primary"
          size="md"
          @click="subscribeAll"
        >
          Подписаться на всё
        </CustomButton>
        <CustomButton
          v-else
          class="u-subscribe-list__button"
          theme="outline-primary"
          size="md"
          @click="unSubscribeAll"
        >
          Отписаться от всех
        </CustomButton>
      </div>
    </div>
  </u-figures>
</template>

<script>
import axios from "axios";

export default {
  name: "ULkSubscribe",
  props: {
    subscribeOptions: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      subscribeList: this.subscribeOptions,
    }
  },
  computed: {
    isSubscribedAll() {
      return this.subscribeList.every(item => item.subscribed === true);
    }
  },
  methods: {
    async unSubscribeAll() {
      const response = await axios.post(
        '/bitrix/services/main/ajax.php?mode=class&c=dalee:subscribe_form&action=unsubscribeAll',
      );

      if (response.data.status === 'error') {
        alert('Ошибка!');
      } else {
        this.subscribeList.forEach(item => item.subscribed = false);
      }
    },
    async subscribeAll() {
      const response = await axios.post(
        '/bitrix/services/main/ajax.php?mode=class&c=dalee:subscribe_form&action=subscribeAll',
      );

      if (response.data.status === 'error') {
        alert('Ошибка!');
      } else {
        this.subscribeList.forEach(item => item.subscribed = true);
      }
    },
    async subscribeItem(item) {
      const response = await axios.post(
        '/bitrix/services/main/ajax.php?mode=class&c=dalee:subscribe_form&action=subscribe',
        { id: item.id }
      );

      if (response.data.status === 'error') {
        alert('Ошибка!');
      } else {
        item.subscribed = !item.subscribed;
      }
    },
    async unsubscribeItem(item) {
      const response = await axios.post(
        '/bitrix/services/main/ajax.php?mode=class&c=dalee:subscribe_form&action=unsubscribe',
        { id: item.id }
      );

      if (response.data.status === 'error') {
        alert('Ошибка!');
      } else {
        item.subscribed = !item.subscribed;
      }
    }
  }
}
</script>


<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-lk-subscribe';

#{$b} {
  position: relative;

  .u-lk-form__title {
    @include mobile-min {
      margin: 0;
    }
  }

  // .u-lk-subscribe__header
  &__header {
    display: flex;
    justify-content: space-between;

    @include mobile-min {
      margin-bottom: 32px;
    }
  }

  // .u-lk-subscribe__footer
  &__footer {
    text-align: center;
    margin-top: 24px;
  }
}
</style>
