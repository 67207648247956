<template>
  <div class="events">
    <div class="g-wrapper-offset-left">
      <events-filter
        :initial-filters="initParams.initialFilters"
        :event-type-options="eventTypeOptions"
        :partners-options="partnersOptions"
        :region-options="regionsOptions"
        :year-options="yearOptions"
        @change="onFilterChange"
      />
      <div class="events__body">
        <ul v-if="items.length" class="events__list">
          <li
            v-for="(item, i) in items"
            :key="i"
            :class="{
              '_additional': item.isAdditional,
              '_regional': item.isRegional,
            }"
          >
            <a :href="hrefWithRegion(item.url)" class="events__item">
              <div class="events__item-body">
                <div class="events__item-date">
                  <div class="events__item-date-label">
                    <inline-svg src="clock" />
                    <span v-if="item.dateText" v-html="item.dateText" />
                    <div v-else>
                      <span v-for="(item, i) in periodItems(item.dateFrom, item.dateTo, item.onlyMonths)" :key="i">
                        <span v-if="i === 1" class="separator"> — </span>
                        {{ item }}
                      </span>
                    </div>
                  </div>
                  <div class="events__item-additional" v-if="item.isAdditional">дополнительное мероприятие</div>
                  <div class="events__item-regional" v-if="item.isRegional && item.id !== 4828">региональное мероприятие</div>
                </div>
                <div class="events__item-organizers" v-if="item.organizers.length">
                  <span>При поддержке: </span>
                  <span v-html="item.organizers.map(item => item.name).join(', ')"/>
                </div>
                <div class="events__item-title" v-html="item.title" />
                <div class="events__item-place">
                  <span>{{ item.place }}</span>
                </div>
              </div>
              <div v-if="item.image" class="events__item-media">
                <div
                  class="events__item-image"
                  :style="{ backgroundImage: `url('${item.image}')` }"
                ></div>
              </div>
            </a>
          </li>
        </ul>
        <not-found v-else text="По данным параметрам ничего не найдено" />
        <div class="events__load-more" v-show="paginator.NavPageNomer < paginator.NavPageCount">
          <div class="g-h4" @click="loadMoreItems(false)">Показать ещё</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import InlineSvg from './InlineSvg.vue';

export default {
  components: { InlineSvg },
  name: 'Events',
  props: {
    url: {
      type: String,
      required: true
    },
    initiative: {
      type: Boolean,
      default: false,
    },
    initParams: {
      type: Object,
    },
    initItems: {
      type: Array,
      default: () => [
        {
          date: "2021-04-18 09:00",
          period: "12 марта — 14 апреля 2021",
          organizers: [{ name: "Минобрнауки России" }],
          title: "Фестиваль «Техносреда»",
          region: "Центральный регион",
          place: "Москва, Ленина 45/2, 5 этаж",
          image: "/frontend/assets/img/events/events-news-04.jpg",
        },
        {
          date: "2021-04-29 15:40",
          period: "12 марта — 14 апреля 2021",
          organizers: [{ name: "Минобрнауки России" }],
          title: "Международный кибертурнир «Битва за науку»",
          region: "Центральный регион",
          place: "Санкт-Петербург, Карла Либкнехта 12",
          image: "/frontend/assets/img/events/events-news-01.jpg",
        },
        {
          date: "2021-04-20 14:01",
          period: "12 марта — 14 апреля 2021",
          organizers: [{ name: "Минобрнауки России" }],
          title: "Торжественное мероприятие, посвящен- ное началу строительства Центра коллективного пользования «Сибирский кольцевой источник фотонов»",
          region: "Центральный регион",
          place: "Санкт-Петербург, Карла Либкнехта 12",
          image: "/frontend/assets/img/events/events-news-02.jpg",
        },
        {
          date: "2021-04-16 20:20",
          period: "12 марта — 14 апреля 2021",
          organizers: [{ name: "Минобрнауки России" }, { name: "Минпросвещения России" }],
          title: "Тематические выпускные: Всероссийский студенческий выпускной «С Наукой в сердце»; Всероссийский школьный выпускной",
          region: "Центральный регион",
          place: "Москва, Ленина 45/2, 5 этаж",
          image: "/frontend/assets/img/events/events-news-03.jpg",
        },
        {
          date: "2021-05-24 14:05",
          period: "12 марта — 14 апреля 2021",
          organizers: [{ name: "Минобрнауки России" }, { name: "Минпросвещения России" }],
          title: "Всероссийский открытый урок в День знаний «Современная наука»",
          region: "Центральный регион",
          place: "Москва, Ленина 45/2, 5 этаж",
          image: "/frontend/assets/img/events/events-news-04.jpg",
        },
        {
          date: "2021-04-30 19:10",
          period: "12 марта — 14 апреля 2021",
          organizers: [{ name: "Минобрнауки России" }],
          title: "Спуск на воду научно- исследовательского судна «Пионер-М»",
          region: "Центральный регион",
          place: "Санкт-Петербург, Карла Либкнехта 12",
          image: "",
        },
      ]
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    initPaginator: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      filterState: {},
      items: this.initItems,
      nextUrl: this.initNextUrl,
      paginator: this.initPaginator,
    };
  },
  computed: {
    partnersOptions() {
      return this.sortingAlphabetically(this.initParams.partners);
    },
    regionsOptions() {
      return this.sortingAlphabetically(this.initParams.regions);
    },
    eventTypeOptions() {
      return this.sortingAlphabetically(this.initParams.eventTypeOptions);
    },
    yearOptions() {
      return this.sortingAlphabetically(this.initParams.years);
    }
  },
  methods: {
    periodItems(from, to, onlyMonths = false) {
      const dateFrom = moment(from);
      const dateTo = moment(to);

      if (onlyMonths) {
        return this.periodItemsOnlyMonths(dateFrom, dateTo);
      }

      let periods = [];
      if (dateFrom.isSame(dateTo)) {
        periods.push(dateFrom.format("DD MMMM"));
      } else {
        if (dateFrom.isSame(dateTo, "year")) {
          if (dateFrom.isSame(dateTo, "month")) {
            periods.push(dateFrom.format("DD"), dateTo.format("DD MMMM"));
          } else {
            periods.push(dateFrom.format("DD MMMM"), dateTo.format("DD MMMM"));
          }
        } else {
          periods.push(dateFrom.format("YYYY"), dateTo.format("YYYY"));
        }
      }

      return periods;
    },
    periodItemsOnlyMonths(dateFrom, dateTo) {
      let periods = [];

      if (dateFrom.isSame(dateTo)) {
        periods.push(dateFrom.format("MMMM"));
      } else {
        if (dateFrom.isSame(dateTo, "year")) {
          if (dateFrom.isSame(dateTo, "month")) {
            periods.push(dateTo.format("MMMM"));
          } else {
            periods.push(dateFrom.format("MMMM"), dateTo.format("MMMM"));
          }
        } else {
          periods.push(dateFrom.format("MMMM YYYY"), dateTo.format("MMMM YYYY"));
        }
      }

      return periods;
    },
    sortingAlphabetically(items) {
      items.sort((a, b) => {
        let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }

        return 0
      });

      items.sort((item) => {
        if (item.id === "") {
          return -1;
        }
      });

      return items;
    },
    formatDate(date) {
      return moment(date).locale("ru").format("DD MMMM / HH:mm")
    },
    onFilterChange(filterState) {
      this.filterState = filterState;
      this.loadFirstPage(true);
    },
    loadFirstPage() {
      const lines = [];
      if (this.filterState.filter) {
        lines.push(`filter=${this.filterState.filter}`);
      }
      if (this.filterState.region) {
        lines.push(`region=${this.filterState.region}`);
      }
      if (this.filterState.organizer) {
        lines.push(`partners=${this.filterState.organizer}`);
      }
      if (this.filterState.type) {
        lines.push(`type=${this.filterState.type}`);
      }
      if (this.filterState.year) {
        lines.push(`year=${this.filterState.year}`);
      }
      if (this.filterState.month.length) {
        lines.push(this.filterState.month.map(id => `months[]=${id}`).join('&'));
      }
      const params = lines.join("&");

      this.nextUrl = `?AJAX=Y&PAGEN_${this.paginator.NavNum}=1&${params}`;
      this.loadMoreItems(true);
    },
    async loadMoreItems(clearItems) {
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.NEXT_URL;
      this.paginator = res.data.PAGINATOR;
      if (clearItems) {
        this.items = [];
      }
      res.data.ITEMS.map(item => {
        this.items.push(item);
      });
    }
  },
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: '.events';
$offsetTop: 64px;

@mixin additionalFlex {
  @include desktop-min {
    display: flex;
    align-items: center;
    justify-content: space-between;

    #{$b}__item-additional {
      text-align: right;
      max-width: 170px;
      margin-top: 0;
    }
  }
}

#{$b} {
  display: block;
  padding-top: 120px;

  &-section {
    position: relative;
    z-index: 2;
    padding-top: 0;
    padding-bottom: 70px;
    margin-top: #{-$offsetTop};

    @include desktop {
      padding-bottom: 50px;
    }

    .figures {
      position: absolute;
      top: $offsetTop;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }

  &__body {
    &:not(:first-child) {
      margin-top: 58px;

      @include desktop {
        margin-top: 38px;
      }

      @include tablet {
        margin-top: 30px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 36px;

      @include desktop {
        margin-bottom: 24px;
      }

      @include tablet {
        margin-bottom: 14px;
      }
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 0 0 -30px;
    display: flex;
    flex-wrap: wrap;

    @include desktop-min {
      margin-left: -40px;
    }

    &>li {
      width: 100%;
      padding-left: 30px;
      margin-bottom: 30px;

      @include mobile-min {
        width: 50%;
      }

      @include desktop-min {
        width: calc(100% / 3);
        padding-left: 40px;
        margin-bottom: 40px;
      }

      // &._additional {
      //   #{$b}__item-body {
      //     background: url(/frontend/assets/img/events/additional-bg.jpg) center !important;
      //     background-size: cover !important;
      //     color: $color-black !important;
      //   }
      // }

      // &._regional {
      //   #{$b}__item-body {
      //     background: url(/frontend/assets/img/events/regional-bg.jpg) center !important;
      //     background-size: cover !important;
      //     color: $white-true !important;
      //   }
      // }

      &:nth-child(3n+2) {

        @include desktop-min {
          width: calc(100% / 3 * 2);
        }

        &._additional,
        &._regional {
          #{$b}__item-date {
            @include additionalFlex;
          }
        }
      }

      &:nth-child(6n+3),
      &:nth-child(6n+4) {

        @include mobile-min {
          width: 50%;
        }

        &._additional,
        &._regional {
          #{$b}__item-date {
            @include additionalFlex;
          }
        }
      }
    }
  }

  &__item {
    position: relative;
    z-index: 0;
    min-height: 306px;
    height: 100%;
    display: flex;
    text-decoration: none;
    color: $color-black;
    background-color: rgba($color: $white-true, $alpha: 0.8);
    box-shadow: 0px 40px 80px rgba($color: $black-true, $alpha: 0.05);
    transition: .2s ease;
    transition-property: transform,box-shadow;

    &:hover,
    &:focus {
      transform: translateY(-10px);
      box-shadow: $shadow-default-hover;
    }

    @include mobile {
      min-height: auto;
      height: auto;
      flex-direction: column;
    }

    @include mobile-min {
      background-color: #133287;
      color: $white-true;
    }

    // #{$b}__list>li:nth-child(6n+3) &,
    // #{$b}__list>li:nth-child(6n+4) & {
    //   @include mobile-min {
    //     background-color: #133287;
    //     color: $white-true;
    //     min-height: 370px;
    //   }
    // }

    // #{$b}__list>li:nth-child(6n+6) & {
    //   color: $white-true;
    // }

    &-body {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      padding: 36px 30px 30px 40px;

      @include desktop {
        padding: 30px 30px 25px 30px;
      }

      #{$b}__list>li:nth-child(6n+3) &,
      #{$b}__list>li:nth-child(6n+4) & {
        @include tablet-only {
          padding-top: 40px;
        }
      }

      // #{$b}__list>li:nth-child(3n+2) & {
      //   @include desktop-min {
      //     background: linear-gradient(61.25deg, #EAEAEA 0%, $white-true 99.13%);
      //   }
      // }

      // #{$b}__list>li:nth-child(6n+6) & {
      //   background: #133287;
      // }
    }

    &-date {
      display: block;
      margin-bottom: 24px;

      @include mobile {
        margin-bottom: 20px;
      }
    }

    &-date-label {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-height: 30px;
      border: 1.5px solid $color-base;
      border-radius: 30px;
      padding: 4px 8px;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: inherit;

      @include mobile {
        min-height: 24px;
        font-size: 10px;
        padding: 0 10px 0 3px;
        border-width: 1px;
      }

      .inline-svg {
        width: 18px;
        min-height: 18px;
        color: $color-base;
        margin-right: 10px;

        @include mobile {
          margin-right: 5px;
        }
      }

      span {
        display: inline-block;
        white-space: nowrap;

        &.separator {
          margin-left: 0.3em;
        }
      }
    }

    &-additional,
    &-regional {
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-top: 20px;

      #{$b}__list>li:nth-child(6n+3) &,
      #{$b}__list>li:nth-child(6n+4) & {
        @include desktop-min {
          text-align: right;
          margin-top: 0;
        }
      }
    }

    &-additional {
      color: $color-base;
    }

    &-regional {
      color: $color-base !important;
    }

    &-organizers {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: inherit;
      opacity: 0.5;
      margin-bottom: 12px;

      &>span {
        &:first-child {
          @include mobile {
            display: block;
          }
        }
      }
    }

    &-title {
      display: block;
      font-weight: 400;
      font-size: 22px;
      line-height: 1.4;
      margin: 0;

      @include desktop {
        font-size: 18px;
      }

      @include desktop {
        font-size: 16px;
      }

      @include mobile {
        font-size: 14px;
        line-height: 1.5;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &-place {
      margin-top: auto;
      display: block;
      font-weight: 700;
      font-size: 14px;
      line-height: 1.6;

      @include desktop {
        font-size: 12px;
      }
    }

    &-media {
      background-color: #eee;
      flex-shrink: 0;

      @include mobile {
        order: -1;
      }

      #{$b}__list>li:nth-child(6n+1) &,
      #{$b}__list>li:nth-child(6n+6) & {

        @include mobile-min {
          display: none;
        }
      }

      #{$b}__list>li:nth-child(3n+2) & {

        @include desktop-min {
          display: block;
          width: calc(50% - 20px);
        }
      }

      #{$b}__list>li:nth-child(6n+3) &,
      #{$b}__list>li:nth-child(6n+4) & {
        @include mobile-min {
          @include full-abs;
          z-index: -1;

          &:before {
            content: "";
            background-color: #365380;
            opacity: .5;
            @include full-abs;
          }
        }
      }

      // #{$b}__list>li:nth-child(6n+6) & {
      //   background-color: #304d9a;
      // }
    }

    &-image {
      display: block;
      width: 100%;
      height: 100%;
      @include bg-cover;

      @include mobile {
        padding-top: 56%;
      }
    }
  }

  &__load-more {
    cursor: pointer;
    position: relative;
    color: $color-base;
    transition: color .3s ease;

    &:hover,
    &:focus {
      color: $color-black;
    }

    @include tablet {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:after {
      content: "";
      width: 116px;
      height: 1.5px;
      position: absolute;
      top: 0.5em;
      right: calc(100% + 38px);
      background-color: $color-blue-line;
      background: linear-gradient(0deg, $color-blue-line 0%, #4f92ff 100%);

      @include desktop {
        right: calc(100% + 30px);
        width: 85px;
      }

      @include tablet {
        position: static;
        margin-left: 25px;
      }

      @include mobile {
        width: 55px;
      }
    }

    &:not(:first-child) {
      margin-top: 30px;

      @include desktop {
        margin-top: 20px;
      }
    }
  }
}
</style>
