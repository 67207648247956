export default function scrollTo(element, to, duration) {
  return new Promise((resolve) => {
    const start = performance.now();
    const from = element.scrollTop;

    function ease(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }

    function scroll(timestamp) {
      const currentTime = timestamp - start;
      const progress = Math.min(currentTime / duration, 1);
      const easedProgress = ease(progress);
      const scrollTop = from + (to - from) * easedProgress;

      element.scrollTop = scrollTop;

      if (currentTime < duration) {
        window.requestAnimationFrame(scroll);
      } else {
        resolve();
      }
    }

    window.requestAnimationFrame(scroll);
  });
}
