<template>
  <article
    class="project-gallery-card"
    :class="{'project-gallery-card--without-text': !isExistsTextContent}"
  >
    <div
      class="project-gallery-card__header"
      @click="$emit('play')"
    >
      <div class="project-gallery-card__preview">
        <img :src="data.cover" :alt="data.title || 'галерея'" class="project-gallery-card__img">
        <inline-svg
          v-if="data.isVideo"
          class="project-gallery-card__play"
          src="u-play"
        />
      </div>
    </div>
    <div v-if="isExistsTextContent" class="project-gallery-card__body">
      <h3
        class="project-gallery-card__title tt-uppercase"
        v-html="data.title"
      />
      <p
        v-if="Boolean(data.text)"
        class="project-gallery-card__text"
        v-html="data.text"
      />
    </div>
  </article>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      require: true
    }
  },
  computed: {
    isExistsTextContent() {
      return Boolean(this.data.text) || Boolean(this.data.title)
    }
  }

}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$header-height: 281px;
$header-height-low-desktop: 240px;
$header-height-tablet: 200px;
$body-width: 327px;
$body-width-low-desktop: 327px;
$body-width-tablet: 290px;

$b: '.project-gallery-card';

#{$b} {
  height: 100%;
  display: inline-flex;
  color: $black-true;
  background-color: $white-true;
  border-radius: 16px;
  overflow: hidden;
  transition: $transtion-default;

  &:hover {
    transform: scale(1.01);
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 568px;
    padding: 24px 16px;
    border-radius: 24px;
    background-color: $color-base;
    color: $white-true;
  }

  // .project-gallery-card--without-text
  &--without-text {
    @include mobile-min {
      background-color: transparent;
    }
  }

  // .project-gallery-card__header
  &__header {
    height: $header-height;
    max-width: calc($container-size - $body-width);
    overflow: hidden;
    border-radius: 16px;
    cursor: pointer;
    position: relative;

    @include desktop {
      max-width: calc((100vw - $container-pd-desk * 2) - $body-width);
    }

    @include low-desktop {
      max-width: calc((100vw - ($container-pd-left-tablet + $container-pd-right-tablet)) - $body-width-low-desktop);
      height: $header-height-low-desktop;
    }

    @include tablet {
      max-width: calc((100vw - ($container-pd-left-tablet + $container-pd-right-tablet)) - $body-width-tablet);
      height: $header-height-tablet;
    }

    @include mobile {
      max-width: 100%;
      width: auto;
      height: auto;
      align-self: center;
    }

    &:hover {

      svg {
        color: $color-base-origin !important;
      }
    }
  }

  // .project-gallery-card__preview
  &__preview {
    height: 100%;
    display: inline-block;
    position: relative;
  }

  // .project-gallery-card__img
  &__img {
    height: 100%;
    width: auto;
    object-fit: cover;
    object-position: center;

    @include mobile {
      height: auto;
      width: auto;
      max-width: 100%;
      border-radius: 16px;
    }
  }

  // .project-gallery-card__play
  &__play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;

    svg {
      color: $white-true;
      transition: $transtion-default;
    }
  }

  // .project-gallery-card__body
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    max-width: $body-width;

    @include low-desktop {
      max-width: $body-width-low-desktop;
    }

    @include tablet {
      max-width: $body-width-tablet;
    }

    @include mobile {
      max-width: 100%;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 32px;
      padding-bottom: 0;
    }
  }

  // .project-gallery-card__title
  &__title {
    font-family: $font-family-main-head;
    font-weight: 500;
    font-size: 22px;
    line-height: 100%;
    margin: 0;
  }

  // .project-gallery-card__text
  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    margin: 16px 0 0 0;
  }
}

</style>
