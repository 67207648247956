<template>
  <form @submit.stop.prevent="validateForm" class="event-form">
    <div class="event-form__col _wide">
      <div class="select _alt-font">
        <div class="text-input__label">Тема месяца</div>
        <v-select
          v-model="form.selectedMonthTheme"
          :clearable="false"
          :searchable="true"
          label="name"
          placeholder="Выберите тему месяца"
          :class="{ 'vs--error': $v.form.selectedMonthTheme.$error }"
          :components="{ OpenIndicator }"
          :reduce="option => option.name"
          :options="monthThemesOptions"
        >
          <template #option="option">
            {{ option.name }}
          </template>
          <template #no-options>
            Не найдено
          </template>
        </v-select>
        <div class="event-form__error _static" v-if="$v.form.selectedMonthTheme.$error && !$v.form.selectedMonthTheme.required">Обязательное поле</div>
      </div>
    </div>
    <div class="event-form__col">
      <div class="event-form__relative">
        <text-input
          name="eventDateFrom"
          label="Дата запуска проекта"
          placeholder="ДД.ММ.ГГГГ"
          v-model="form.eventDateFrom"
          mask="##.##.####"
          :error="$v.form.eventDateFrom.$error"
        />
        <div class="event-form__error _static" v-if="$v.form.eventDateFrom.$error && !$v.form.eventDateFrom.required">Обязательное поле</div>
        <div class="event-form__error _static" v-if="$v.form.eventDateFrom.$error && !$v.form.eventDateFrom.existDate">Введите существующую дату</div>
      </div>
    </div>
    <div class="event-form__col">
      <div class="event-form__relative">
        <text-input
          name="eventDateTo"
          label="Дата окончания проекта **"
          placeholder="ДД.ММ.ГГГГ"
          v-model="form.eventDateTo"
          mask="##.##.####"
          :error="$v.form.eventDateTo.$error"
        />
        <div class="event-form__error _static" v-if="$v.form.eventDateTo.$error && !$v.form.eventDateTo.required">Обязательное поле</div>
        <div class="event-form__error _static" v-if="$v.form.eventDateTo.$error && !$v.form.eventDateTo.existDate">Введите существующую дату</div>
        <div class="event-form__error _static" v-if="$v.form.eventDateTo.$error && !$v.form.eventDateTo.afterFrom">Не может быть раньше даты запуска</div>
      </div>
    </div>
    <div class="event-form__col">
      <div class="select _alt-font">
        <div class="text-input__label">Формат проекта</div>
        <v-select
          v-model="form.selectedProjectFormat"
          :clearable="false"
          :searchable="true"
          label="name"
          placeholder="Выберите формат проекта"
          :class="{ 'vs--error': $v.form.selectedProjectFormat.$error }"
          :components="{ OpenIndicator }"
          :reduce="option => option.name"
          :options="projectFormatOptions"
        >
          <template #no-options>
            Не найдено
          </template>
        </v-select>
        <div class="event-form__error" v-if="$v.form.selectedProjectFormat.$error && !$v.form.selectedProjectFormat.required">Обязательное поле</div>
      </div>
    </div>
    <div class="event-form__col">
      <text-input
        name="projectName"
        label="Наименование проекта"
        placeholder="Например, «Большой проект»"
        v-model="form.projectName"
        :error="$v.form.projectName.$error"
      />
      <div class="event-form__error" v-if="$v.form.projectName.$error">Обязательное поле</div>
    </div>
    <div class="event-form__col">
      <text-input
        name="organization"
        label="Организация"
        placeholder="Например, ООО «Организация»"
        v-model="form.organization"
        :error="$v.form.organization.$error"
      />
      <div class="event-form__error" v-if="$v.form.organization.$error">Обязательное поле</div>
    </div>
    <div class="event-form__col">
      <text-input
        name="responsiblePerson"
        label="ответственное лицо"
        placeholder="ФИО"
        v-model="form.responsiblePerson"
        :error="$v.form.responsiblePerson.$error"
      />
      <div class="event-form__error" v-if="$v.form.responsiblePerson.$error">Обязательное поле</div>
    </div>
    <div class="event-form__col">
      <text-input
        name="personPosition"
        label="должность"
        placeholder="Старший менеджер"
        v-model="form.personPosition"
        :error="$v.form.personPosition.$error"
      />
      <div class="event-form__error" v-if="$v.form.personPosition.$error">Обязательное поле</div>
    </div>
    <div class="event-form__col">
      <text-input
        name="phone"
        label="телефон"
        placeholder="+7 (123) 456-78-90"
        v-model="form.phone"
        mask="+7 (###) ###-##-##"
        :error="$v.form.phone.$error"
      />
      <div class="event-form__error" v-if="$v.form.phone.$error">Обязательное поле</div>
    </div>
    <div class="event-form__col _wide">
      <div class="select _alt-font">
        <div class="text-input__label">Регион проведения мероприятия, может быть несколько</div>
        <v-select
          v-model="form.district"
          :clearable="false"
          :searchable="true"
          label="name"
          :class="{ 'vs--error': $v.form.district.$error }"
          placeholder="Например, Центральный"
          :components="{ OpenIndicator }"
          :reduce="option => option.id"
          :options="dataRegionsOptions"
          @input="districtSelect"
          multiple
        >
          <template #no-options>
            Не найдено
          </template>
        </v-select>
      </div>
      <div class="event-form__error" v-if="$v.form.district.$error">Обязательное поле</div>
    </div>
    <div class="event-form__col _wide text-input">
      <file-input
        name="files"
        label="Прикрепите фотографии"
        @file-added="fileAdded"
        :initial-files="initialFiles"
      />
    </div>
    <div class="event-form__col _divider"></div>
    <div class="event-form__col _wide">
      <text-input
        name="shortDescription"
        placeholder="Краткое описание проекта (с указанием ссылки на сайт проекта, если имеется)"
        v-model="form.shortDescription"
        :error="$v.form.shortDescription.$error"
        is-text-area
      />
      <div class="event-form__error" v-if="$v.form.shortDescription.$error">Обязательное поле</div>
    </div>
    <div class="event-form__col _wide">
      <text-input
        name="fullDescription"
        placeholder="Полное описание проекта (с указанием целей и задач проекта, целевой аудиторией и др. информацией), ссылка на сайт если имеется"
        v-model="form.fullDescription"
        :error="$v.form.fullDescription.$error"
        is-text-area
      />
      <div class="event-form__error" v-if="$v.form.fullDescription.$error">Обязательное поле</div>
    </div>
    <div class="event-form__col-label">KPI (Ключевые показатели эффективности) вашего проекта</div>
    <div class="event-form__col event-form__col--3">
      <text-input
        name="activityCoverage"
        label="Непосредственное участие в&nbsp;онлайн&nbsp;активностях (чел.)"
        placeholder="10 000"
        v-model="form.activityCoverageOnline"
        :error="$v.form.activityCoverageOnline.$error"
      />
      <div class="event-form__error" v-if="$v.form.activityCoverageOnline.$error">Обязательное поле</div>
    </div>
    <div class="event-form__col event-form__col--3">
      <text-input
        name="activityCoverage"
        label="Непосредственное участие в&nbsp;оффлайн&nbsp;активностях (чел.)"
        placeholder="10 000"
        v-model="form.activityCoverageOffline"
        :error="$v.form.activityCoverageOffline.$error"
      />
      <div class="event-form__error" v-if="$v.form.activityCoverageOffline.$error">Обязательное поле</div>
    </div>
    <div class="event-form__col event-form__col--3">
      <text-input
        name="professionalCoverage"
        label="Охват профессионального сообщества (чел.)"
        placeholder="100 000"
        v-model="form.professionalCoverage"
        :error="$v.form.professionalCoverage.$error"
      />
      <div class="event-form__error" v-if="$v.form.professionalCoverage.$error">Обязательное поле</div>
    </div>
    <div class="event-form__col-warn">*Все поля обязательны для заполнения</div>
    <div class="event-form__col-warn">**Если мероприятие длится один день, то в графе
      «дата окончания проекта» необходимо указать такую же дату, что и «дата начала проекта»</div>
    <button type="submit" class="button event-form__button button--base">Отправить</button>
  </form>
</template>

<script>
  import axios from "axios";
  import TextInput from "./TextInput";
  import vSelect from "vue-select";
  import { validationMixin } from 'vuelidate'
  import { required, requiredIf, helpers } from 'vuelidate/lib/validators'
  import FileInput from "./FileInput";

  const moment = require("moment");
  const existDate = (value) => !helpers.req(value) || moment(value, 'DD.MM.YYYY').isValid();

  export default {
    name: "EventForm",
    props: {
      monthThemesOptions: {
        type: Array,
        default: () => [
          { id: '0', name: 'Апрель – «Освоение космоса»' },
          { id: '1', name: 'Другое' }
        ]
      },
      projectFormatOptions: {
        type: Array,
        default: () => [
          { id: '0', name: 'Мероприятия (форумы, конференции, выставки и пр.)' },
          { id: '1', name: 'Конкурсы и соревнования (наличие «воронки» и победителей)' },
          { id: '2', name: 'Акции (максимальное вовлечение широкой аудитории)' },
          { id: '3', name: 'Продукты и технологии (запуск производства, презентация продукта и пр.)' },
          { id: '4', name: 'Инфраструктура (открытие кампуса, закладка нового университета, создание крупной лаборатории)' },
          { id: '5', name: 'Мультимедиа (кино, платформы, медиа и т.п.)' },
          { id: '6', name: 'Другое' }
        ]
      },
      regionsOptions: {
        type: Array,
        default: () => [
          { id: '0', name: 'Москва' },
        ]
      },
      regionalEvent: {
        type: Boolean,
        default: false,
      },
      initialData: {
        type: Object,
      },
      initialFiles: {
        type: Array,
      },
      submitUrl: {
        type: String,
        default: '/bitrix/services/main/ajax.php?mode=class&c=dalee:event_form&action=send',
      },
      requestId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        dataRegionsOptions: [...this.regionsOptions],
        form: {
          regionalEvent: this.regionalEvent,
          selectedMonthTheme: '',
          selectedProjectFormat: '',
          projectName: '',
          organization: '',
          responsiblePerson: '',
          personPosition: '',
          phone: '',
          district: '',
          shortDescription: '',
          fullDescription: '',
          activityCoverageOnline: '',
          activityCoverageOffline: '',
          professionalCoverage: '',
          eventDateFrom: '',
          eventDateTo: '',
        },
        files: [],
        OpenIndicator: {
          render: createElement => createElement('span')
        },
        isModal: false,
      }
    },
    mixins: [validationMixin],
    components: {FileInput, TextInput, vSelect},
    validations: {
      form: {
        selectedMonthTheme: {required},
        selectedProjectFormat: {required},
        projectName: {required},
        organization: {required},
        responsiblePerson: {required},
        personPosition: {required},
        phone: {
          required,
          phoneLength: (value) => value.replace(/\D/g, '').length === 11
        },
        district: {required},
        shortDescription: {required},
        fullDescription: {required},
        activityCoverageOnline: {
          required: requiredIf(function() {
            return !this.regionalEvent;
          })
        },
        activityCoverageOffline: {
          required: requiredIf(function() {
            return !this.regionalEvent;
          })
        },
        professionalCoverage: {
          required: requiredIf(function() {
            return !this.regionalEvent;
          })
        },
        eventDateFrom: {
          required,
          existDate
        },
        eventDateTo: {
          required,
          existDate,
          afterFrom: function(value) {
            return !helpers.req(value) || !this.form.eventDateFrom || (moment(value, 'DD.MM.YYYY').isAfter(moment(this.form.eventDateFrom, 'DD.MM.YYYY'), 'day') || moment(value, 'DD.MM.YYYY').isSame(moment(this.form.eventDateFrom, 'DD.MM.YYYY')))
          }
        },
      }
    },
    created() {
      if (this.initialData) {
        this.form = { ...this.initialData };
        this.isModal = true;
      }

      if (this.initialFiles) {
        this.initialFiles.forEach((file) => {
          if (file) {
            this.files.push({ name: file.replace(/^.*[\\/]/, ''), url: file });
          }
        });
      }

      if (!this.form.regionalEvent) {
        this.dataRegionsOptions.unshift({id: 'all', name: 'Все'});
      }
    },
    mounted() {
      this.clearFormData = {...this.form};
      this.$root.$on('modal-submit', this.submitHandler);
    },
    beforeDestroy() {
      this.$root.$off('modal-submit', this.submitHandler);
    },
    methods: {
      validateForm() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$nextTick(() => {
            this.$smoothScroll({
              scrollTo: document.querySelector('.text-input._error'),
              offset: -50,
              container: this.isModal ? document.querySelector('.v-modal.is-open') : window,
            })
          });
        } else {
          this.$root.$emit('modal-cancel');
          this.openModal('eventAttentionModal');
        }
      },
      async submitForm() {
        const data = new FormData();
        const jsonUrlEncoded = encodeURIComponent(JSON.stringify(this.form));
        const options = {
          method: 'post',
          url: this.submitUrl,
          headers: {'content-type': 'multipart/form-data'},
          data,
        };
        data.append('json', jsonUrlEncoded);

        if (this.requestId) {
          data.append('id', this.requestId);
        }

        this.files.forEach((file) => {
          data.append('files[]', file.url || file);
        });

        try {
          const res = await axios(options);
          this.handleResponse(res.status === 200 && res.data.status !== 'error');
        } catch (error) {
          console.error(error);
          this.handleResponse();
        }
      },
      handleResponse(success = false) {
        const modalToOpen = success ? 'eventResultModal' : 'eventErrorModal';
        this.$root.$refs.eventAttentionModal.closeModal();
        this.$root.$refs.eventAttentionModal.toggleDialog();
        this.openModal(modalToOpen);
        if (success) {
          this.resetForm();
          setTimeout(function () {
            window.location.reload()
          }, 3000)
        }
      },
      submitHandler() {
        this.$root.$refs.eventAttentionModal.toggleDialog(false);
        this.submitForm();
      },
      resetForm() {
        this.form = this.clearFormData;
        this.files = [];
        this.$v.$reset();
      },
      openModal(modalRef) {
        this.$root.$refs[modalRef].openModal();
      },
      districtSelect(value) {
        if (value.indexOf('all') > -1) {
          if (value.length > 1 && value[0] === 'all') {
            this.form.district = value.splice(1);
          } else {
            this.form.district = ['all'];
          }
        }
      },
      fileAdded(files) {
        this.files = files;
      }
    },
  }
</script>

<style lang="scss">
  @import "../scss/base/includes";
  .event-form {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:before {
      content: '';
      position: absolute;
      top: 8px;
      right: calc(100% + 30px);
      width: 188px;
      height: $line-width;
      background-color: $color-base;

      @include low-desktop {
        width: 135px;
      }

      @include tablet {
        display: none;
      }

      .v-modal__dialog & {
        display: none;
      }
    }

    &__col {
      position: relative;
      width: calc((100% - 40px) / 2);
      margin-bottom: 52px;

      @include low-desktop {
        width: calc((100% - 30px) / 2);
      }

      @include tablet {
        width: 100%;
      }

      @include mobile {
        margin-bottom: 34px;
      }

      &--3 {
        width: calc((100% - 70px) / 3);

        @include low-desktop {
          width: calc((100% - 40px) / 3);
        }

        @include tablet {
          width: 100%;
        }
      }

      &-label {
        width: 100%;
        font-size: 22px;
        line-height: 160%;
        margin-bottom: 44px;

        @include mobile {
          font-size: 14px;
          line-height: 1.4;
          margin-bottom: 34px;
        }
      }

      &-warn {
        width: 100%;
        font-weight: bold;
        font-size: 12px;
        line-height: 150%;
        color: $color-error;
        margin: 8px 0 60px;

        @include mobile {
          margin: 6px 0 40px;
        }
      }

      &._wide {
        width: 100%;
      }

      &._divider {
        width: calc(100% + 58px);
        height: $line-width;
        background-color: rgba($color-black, 0.5);
        margin: 8px 0 40px -58px;

        @include tablet-min {
          ~ .event-form__col {
            .text-input {
              display: flex;
              flex-direction: column;
              height: 100%;
            }

            .text-input__wrapper {
              margin-top: auto;
            }
          }
        }

        @include tablet {
          width: calc(100% + 60px);
          margin-left: -60px;
        }

        @include mobile {
          width: calc(100% + 30px);
          margin: 6px 0 30px -30px;
        }

        .v-modal__dialog & {
          margin: 8px 0 40px -40px;

          @include mobile {
            margin: 8px 0 40px -30px;
          }
        }
      }
    }

    &__relative {
      position: relative;
    }

    &__checkbox {
      position: relative;
      margin-top: 21px;

      input {
        display: none;

        &:checked {
          + label {
            &:after {
              opacity: 1;
              visibility: visible;
              transform: none;
            }
          }
        }

        &:not(:checked) {
          + label {

            &:hover,
            &:focus {
              &:before {
                border-color: $color-base;
              }
            }
          }
        }
      }

      label {
        position: relative;
        display: inline-block;
        padding-left: 35px;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        @include mobile {
          font-size: 10px;
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
        }

        &:before {
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          border: $line-width solid $color-border;
          transition: border-color .2s ease;
        }

        &:after {
          top: 0;
          left: 5px;
          width: 19px;
          height: 15px;
          background-image: $img-check;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          opacity: 0;
          visibility: hidden;
          transform: translateY(-5px);
          transition: .2s;
        }
      }
    }

    &__button {
      min-width: 100%;
      @include mobile-min {
        min-width: 242px;
      }
    }

    &__error {
      position: absolute;
      left: 0;
      top: calc(100% + 3px);
      font-size: 14px;
      line-height: 160%;
      color: $color-error;

      &._static {
        position: static;
        left: 0;
        top: 0;
        margin: 5px 0 0;
      }
    }

    .v-modal__dialog & {
      padding-top: 26px;
      margin-bottom: -34px;

      @include mobile {
        margin-bottom: -25px;
      }
    }
  }
</style>
