<template>
  <div class="file-uploader">
    <div class="file-uploader__header">
      <label :for="name" class="button button--base">
        Выбрать файлы
      </label>
    </div>

    <template v-if="files.length">
      <table class="file-uploader__table">
        <thead>
          <tr>
            <th>Превью</th>
            <th>Имя</th>
            <th>Месяц</th>
            <th>Категория</th>
            <th>Размер</th>
            <th>Статус</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(file, index) in files" :key="file.id" :id="index">
            <td class="file-uploader__thumb-cell">
              <div v-if="file.thumb" class="file-uploader__thumb">
                <img :src="file.thumb" />
              </div>
              <span v-else>Нет превью</span>
            </td>
            <td>
              <div class="file-uploader__filename">
                <div
                  v-if="file.error === 'edit'"
                  class="file-uploader__filename-input is-edited"
                >
                  <input
                    type="text"
                    id="editName"
                    v-model="editFile.name"
                    placeholder="Введите новое имя"
                  />
                  <span
                    class="file-uploader__filename-icon"
                    @click.prevent="onEditorFile"
                  >
                    <inline-svg src="check-2" />
                  </span>
                </div>
                <div v-else class="file-uploader__filename-input">
                  <input
                    type="text"
                    :id="'name_' + index"
                    v-model="file.name"
                    readonly
                  />
                  <span
                    class="file-uploader__filename-icon"
                    @click.prevent="
                      file.active ||
                      file.success ||
                      file.error === 'compressing'
                        ? false
                        : onEditFileShow(file)
                    "
                  >
                    <inline-svg src="edit-icon" />
                  </span>
                </div>
              </div>
              <div
                class="file-uploader__progress"
                v-if="file.active || file.progress !== '0.00'"
              >
                <div
                  role="progressbar"
                  class="file-uploader__progress-bar"
                  :class="{ 'is-danger': file.error }"
                  :style="{ width: file.progress + '%' }"
                >
                  {{ file.progress }}%
                </div>
              </div>
            </td>
            <td>
              <div class="select">
                <v-select
                  v-model="selectedMonth"
                  :clearable="false"
                  :searchable="true"
                  label="name"
                  :components="{ OpenIndicator }"
                  :reduce="option => option.id"
                  :options="monthOptions"
                >
                  <template #no-options>
                    Не найдено
                  </template>
                </v-select>
              </div>
            </td>
            <td>
              <div class="select">
                <v-select
                  v-model="selectedCategory"
                  :clearable="false"
                  :searchable="true"
                  label="name"
                  :components="{ OpenIndicator }"
                  :reduce="option => option.id"
                  :options="categoryOptions"
                >
                  <template #no-options>
                    Не найдено
                  </template>
                </v-select>
              </div>
            </td>
            <td>{{ getFormatSize(file.size) }}</td>
            <td>
              <span v-if="file.error">{{ file.error }}</span>
              <span v-else-if="file.success">
                Загружено
              </span>
              <span v-else-if="file.active">
                Загружается
              </span>
              <span v-else/>
            </td>
            <td>
              <a
                href="#"
                class="file-uploader__remove"
                @click.prevent="$refs.upload.remove(file)"
                >Удалить</a
              >
            </td>
          </tr>
        </tbody>
      </table>

      <div class="file-uploader__footer">
        <button
          v-if="!$refs.upload || !$refs.upload.active"
          type="button"
          class="button button--base"
          @click.prevent="$refs.upload.active = true"
        >
          Начать загрузку
        </button>
      </div>
    </template>

    <file-upload
      class="file-uploader__uploader"
      :post-action="postAction"
      :put-action="putAction"
      :custom-action="customAction"
      :extensions="extensions"
      :accept="accept"
      :multiple="multiple"
      :directory="directory"
      :create-directory="createDirectory"
      :size="size || 0"
      :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
      :headers="headers"
      :data="data"
      :drop="drop"
      :drop-directory="dropDirectory"
      :add-index="addIndex"
      v-model="files"
      @input-filter="inputFilter"
      @input-file="inputFile"
      ref="upload"
    />
  </div>
</template>

<script>
import axios from "axios";
import Compressor from "compressorjs";
import FileUpload from "vue-upload-component";
import vSelect from "vue-select";

export default {
  name: "FileUploader",
  components: {
    FileUpload,
    vSelect
  },
  props: {
    monthOptions: {
      type: Array,
      default: () => [
        { id: "", name: "Месяц" },
        { id: "1", name: "Янв" },
        { id: "2", name: "Фев" },
        { id: "3", name: "Мар" },
        { id: "4", name: "Апр" },
        { id: "5", name: "Май" },
        { id: "6", name: "Июн" },
        { id: "7", name: "Июл" },
        { id: "8", name: "Авг" },
        { id: "9", name: "Сен" },
        { id: "10", name: "Окт" },
        { id: "11", name: "Ноя" },
        { id: "12", name: "Дек" }
      ]
    },
    categoryOptions: {
      type: Array,
      default: () => [
        { id: "", name: "Без категории" },
        { id: "1", name: "Категория 1" }
      ]
    }
  },
  data() {
    return {
      files: [],
      accept: "image/png,image/gif,image/jpeg,image/webp,video/mov,video/mp4,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel",
      extensions: "gif,jpg,jpeg,png,webp,mov,mp4,pdf,docx,xlsx",
      minSize: 1024,
      size: 1024 * 1024 * 100,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      createDirectory: false,
      addIndex: false,
      thread: 3,
      name: "file",
      postAction: "/bitrix/services/main/ajax.php?mode=class&c=dalee:photobank&action=upload",
      putAction: "/bitrix/services/main/ajax.php?mode=class&c=dalee:photobank&action=upload",
      headers: {
        "X-Csrf-Token": document.querySelector("meta[name=csrf-token]").content
      },
      data: {
        _csrf_token: document.querySelector("meta[name=csrf-token]").content,
      },
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      editFile: {
        show: false,
        name: ""
      },
      selectedMonth: "",
      selectedCategory: "",
      OpenIndicator: {
        render: createElement => createElement("span")
      }
    };
  },
  watch: {
    "editFile.show"(newValue, oldValue) {
      // error
      if (!newValue && oldValue) {
        this.$refs.upload.update(this.editFile.id, {
          error: this.editFile.error || ""
        });
      }
      if (newValue) {
        this.$nextTick(() => {
          this.editFile = {
            ...this.editFile
          };
        });
      }
    }
  },
  methods: {
    getFormatSize(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
        // Automatic compression
        if (
          newFile.file &&
          newFile.error === "" &&
          newFile.type.substr(0, 6) === "image/" &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = "compressing";
          const uploader = this.$refs.upload;
          new Compressor(newFile.file, {
            quality: 0.6,
            success(file) {
              uploader.update(newFile, {
                error: "",
                file,
                size: file.size,
                type: file.type
              });
            },
            error(err) {
              uploader.update(newFile, {
                error: err.message || "compress"
              });
            }
          });
        }
      }
      if (
        newFile &&
        newFile.error === "" &&
        newFile.file &&
        (!oldFile || newFile.file !== oldFile.file)
      ) {
        // Create a blob field
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        }
      }
      // image size
      if (
        newFile &&
        newFile.error === "" &&
        newFile.type.substr(0, 6) === "image/" &&
        newFile.blob &&
        (!oldFile || newFile.blob !== oldFile.blob)
      ) {
        newFile.error = "image parsing";
        let img = new Image();
        img.onload = () => {
          this.$refs.upload.update(newFile, {
            error: "",
            height: img.height,
            width: img.width
          });
        };
        img.οnerrοr = () => {
          this.$refs.upload.update(newFile, { error: "parsing image size" });
        };
        img.src = newFile.blob;
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.upload.update(newFile, { error: "size" });
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // error
        }
        if (newFile.success && !oldFile.success) {
          // success
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
    },
    alert(message) {
      alert(message);
    },
    onEditFileShow(file) {
      this.editFile = { ...file, show: true };
      this.files.forEach(item => {
        if (item.id !== file.id) {
          if (item.error === "edit") {
            item.error = "";
          }
        }
      });
      this.$refs.upload.update(file, { error: "edit" });
    },
    onEditorFile() {
      if (!this.$refs.upload.features.html5) {
        this.alert("Ваш браузер не поддерживается");
        this.editFile.show = false;
        return;
      }

      let data = {
        name: this.editFile.name,
        error: ""
      };

      this.$refs.upload.update(this.editFile.id, data);
      this.editFile.error = "";
      this.editFile.show = false;
    },
    async customAction(e) {
      try {
        const formData = new FormData;
        formData.append('name', e.name);
        formData.append('category', this.selectedCategory);
        formData.append('month', this.selectedMonth);
        formData.append('file', e.file);

        const response = await axios({
          url: '/bitrix/services/main/ajax.php?mode=class&c=dalee:photobank&action=upload',
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(response.data);
        this.files = [];
        this.$emit('complete');

      } catch (e) {
        console.log(e);
        alert('Error!');
      }
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".file-uploader";

#{$b} {
  display: block;

  &:not(:first-child) {
    margin-top: 80px;
  }

  &:not(:last-child) {
    margin-bottom: 80px;
  }

  &__header {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  &__footer {
    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  &__overflow {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    border: 0;

    thead {
      th,
      td {
        vertical-align: bottom;
        font-weight: 700;
        font-size: 16px;
        text-align: left;
        padding: 0 0 20px;
        border-bottom: 1px solid $color-gray;
      }
    }

    tbody {
      th,
      td {
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        padding: 12px 0;
        border-bottom: 1px solid $color-gray;
        vertical-align: middle;
      }
    }

    thead,
    tbody {
      th,
      td {
        padding-left: 20px;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }

  &__thumb-cell {
    width: 100px;
  }

  &__thumb {
    display: block;
    position: relative;

    &::before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }

    & > img {
      @include full-abs;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__filename {
    &-input {
      display: block;
      position: relative;

      input {
        height: 40px;
        line-height: 40px;
        display: block;
        width: 100%;
        appearance: none;
        border: 1px solid $color-black;
        font-size: 16px;
        font-weight: 400;
        padding: 0 40px 0 10px;

        &:read-only {
          border-color: $color-gray;
          color: #6b6b6b;
          cursor: default;
        }
      }
    }

    &-icon {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      color: $color-black;
      display: flex;
      align-items: center;
      justify-content: center;

      .is-edited & {
        color: $color-base;
      }

      .inline-svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  &__progress {
    display: flex;
    overflow: hidden;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    background-color: $color-gray;
    border-radius: 4px;

    &:not(:first-child) {
      margin-top: 10px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &-bar {
      height: 100%;
      line-height: 1;
      color: $white-true;
      background-color: $color-base;
      background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
      background-size: 14px;
      transition: width 0.6s ease;

      &.is-danger {
        background-color: $color-red;
      }
    }
  }

  &__remove {
    display: inline-block;
    text-decoration: none;
    color: $color-red;
    font-weight: 700;

    &:hover {
      text-decoration: none;
      color: $color-black;
    }
  }

  .select {
    width: 200px;
  }
}
</style>
