<template>
  <div class="talisman-stepline">
    <div class="talisman-stepline__inner">
      <div class="talisman-stepline__list talisman-stepline__list--past">
        <div class="talisman-stepline__item" v-for="(step, i) in getLeftSteps" :key="`talisman-stepline__item-${i}`">
         <CustomButton
            class="talisman-stepline__item-btn"
            :class="{'talisman-stepline__item-btn--is-disabled': !step.isActive}"
            size="lx"
            theme="primary"
          >
            {{ step.caption }}
          </CustomButton>
        </div>
      </div>
      <div class="talisman-stepline__list talisman-stepline__list--main">
        <div class="talisman-stepline__item" v-for="(step, i) in getRightSteps" :key="`talisman-stepline__item-${i}`">
          <CustomButton
            class="talisman-stepline__item-btn fw-400"
            :class="{'talisman-stepline__item-btn--is-disabled': !step.isActive}"
            size="lx"
            theme="primary"
          >
            <span v-html="step.caption"></span>
          </CustomButton>
        </div>
      </div>
      <div class="talisman-stepline__stages">
        <TalismanTimelaps class="talisman-stepline__timelaps" :timelapsInitial="getTimelaps" theme="block" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      default: () => [
        {
          caption: "Отбор конкурсных работ",
          isActive: false
        },
        {
          caption: "Общероссийское онлайн&#8209;голосование",
          isActive: true
        },
        {
          caption: "Выбор победителя членами Координационного комитета",
          isActive: false
        },
        {
          caption: "Объявление победителя на сайте&nbsp;конкурса",
          isActive: false
        },
      ]
    }
  },
  computed: {
    getActiveStepIndex() {
      return this.steps.findIndex(item => item.isActive)
    },
    getRightSteps() {
      return this.steps.filter((item, i) => i >= this.getActiveStepIndex)
    },
    getLeftSteps() {
      return this.steps.filter((item, i) => i < this.getActiveStepIndex)
    },
    getTimelaps() {
      return this.steps.reduce((acc, item, i) => {
        acc[`step${i+1}`] = {
          caption: item.caption,
          isActive: item.isActive,
          date: 0,
        }

        return acc
      },{})
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-stepline';
$gap: 32px;

@mixin stepSeparator {
  position: relative;
  padding-right: $gap;

  &::before {
    content: "";
    position: absolute;
    right: calc(($gap / 2) - 20px);
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
    border: 2px solid $white-true;
    border-radius: 50%;
    background-color: $color-base-origin;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: $gap;
    height: 2px;
    background-color: $color-base-origin;
    transform: translateY(-50%);
  }
}

#{$b} {

  // .talisman-stepline__inner
  &__inner {
    position: relative;
  }

  // .talisman-stepline__list
  &__list {
    display: flex;
    align-items: center;

    @include removeScrollBar();

    // .talisman-stepline__list--past
    &--past {
      position: absolute;
      left: 0;
      transform: translateX(-100%);
    }

    @include mobile {
      display: none;
    }
  }

  // .talisman-stepline__item
  &__item {
    pointer-events: none;
    touch-action: none;

    #{$b}__list--main & {
      &:not(:last-child) {
        @include stepSeparator();
      }
    }

    #{$b}__list--past & {
      @include stepSeparator();
    }

    // .talisman-stepline__item-btn
    &-btn {
      // .talisman-stepline__item-btn--is-disabled
      &--is-disabled {
        background-color: $color-primary-light !important;
        border-color: $color-primary-light !important;
      }
    }
  }

  // .talisman-stepline__stages
  &__stages {
    @include mobile-min {
      display: none;
    }
  }

  // .talisman-stepline__timelaps
  &__timelaps {
  }
}
</style>
