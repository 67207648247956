<template>
  <div class="universities-back">
    <ArrowLink :direction="direction" tag="a" :textClass="textClass" :href="hrefWithRegion(href)">
      <slot>
        назад
      </slot>
    </ArrowLink>
  </div>
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      default: "left"
    },
    textClass: {
      type: String,
      default: ""
    },
    href: {
      type: String,
      default: ""
    }
  },
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.universities-back';

#{$b} {
  display: inline-block;
  color: $color-base-origin;
  cursor: pointer;
}
</style>
