<template>
  <div>
    <ul class="events-filter__month-list _without-first">
      <li v-for="(item, i) in filters" :key="i">
        <div
          class="events-filter__month-item"
          :class="{ 'is-active': item.isActive }"
          @click.prevent="toggleFilter(item, i)"
        >
          <span>{{ item.name }}</span>
          <inline-svg v-if="item.isActive" src="close"/>
        </div>
      </li>
    </ul>
    <div class="lk-progress">
      <div class="lk-progress__row">
        <div class="lk-progress__col" v-for="(item, i) in events" :key="i">
          <div class="lk-progress__title">{{item.title}}</div>
          <div class="lk-progress__values">
            <div>
              <span>Прошло {{item.values.current | formatNumber}} </span>
              <span class="lk-progress__additional" v-if="item.values.currentAdditional">({{item.values.currentAdditional > 0 ? `+${item.values.currentAdditional}` : item.values.currentAdditional}})</span>
            </div>
            <div>
              <span>из {{item.values.max | formatNumber}} </span>
              <span class="lk-progress__additional" v-if="item.values.maxAdditional">({{item.values.maxAdditional > 0 ? `+${item.values.maxAdditional}` : item.values.maxAdditional}})</span>
            </div>
          </div>
          <div class="lk-progress__bar">
            <div :style="{width: getPercentage(item.values.current, item.values.max)}"></div>
          </div>
        </div>
      </div>
      <slot name="note"></slot>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: "LkRaportProgress",
    props: {
      eventsList: {
        type: Array,
        default: () => [
          {
            title: 'Всего',
            values: {
              current: 1355,
              currentAdditional: 7,
              max: 2046,
              maxAdditional: 5
            }
          },
          {
            title: 'Основных',
            values: {
              current: 52,
              currentAdditional: 4,
              max: 532,
              maxAdditional: 5
            }
          },
          {
            title: 'Дополнительных',
            values: {
              current: 143,
              currentAdditional: 9,
              max: 1003,
              maxAdditional: 5
            }
          },
          {
            title: 'Региональных',
            values: {
              current: 174,
              max: 511,
            }
          }
        ]
      },
      momentFilterList: {
        type: Array,
        default: () => [
          {
            filter: 'now',
            name: 'на текущий момент',
            isActive: true
          },
          {
            filter: 'week',
            name: 'за предыдущую неделю',
            isActive: false
          },
          {
            filter: 'month',
            name: 'за предыдущий месяц',
            isActive: false
          }
        ]
      },
      code: {
        type: String,
        default: 'event_counter',
      },
    },
    data() {
      return {
        filters: [],
        events: [],
      }
    },
    mounted() {
      this.filters = this.momentFilterList;
      this.events = [...this.eventsList];
    },
    methods: {
      getPercentage(current, max) {
        return (current / max * 100) + '%'
      },
      async toggleFilter(el, i) {
        if (this.filters[i].isActive === true) {
          this.filters[i].isActive = !this.filters[i].isActive;
          this.filters[0].isActive = true
        } else {
          this.filters.forEach(item => {
            item.isActive = false;
          });
          this.filters[i].isActive = true;
        }

        axios.get(`/bitrix/services/main/ajax.php?mode=class&c=dalee:activity.report&action=filter&period=${this.filters[i].filter}&section=${this.code}`)
        .then((res) => {
          if (res.data.status === 'success') {
            this.events = res.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/base/includes";

  .lk-progress {
    margin-top: 20px;

    &__row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: -40px;
    }

    &__col {
      width: calc((100% - 43px) / 2);
      margin-bottom: 40px;
      @include mobile {
        width: 100%;
      }
    }

    &__title {
      font-weight: bold;
      font-size: 18px;
      line-height: 160%;
      margin-bottom: 16px;
    }

    &__values {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      > div {
        font-size: 16px;
        line-height: 20px;
        @include mobile {
          font-size: 14px;
        }

        &:last-child {
          opacity: .5;
        }
      }
    }

    &__additional {
      color: $color-base;
    }

    &__bar {
      position: relative;
      width: 100%;
      height: 10px;
      background: rgba($color-base, .4);

      > div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: $color-base;
        max-width: 100%;
      }
    }

    &__note {
      font-size: 12px;
      margin-top: 20px;
      color: rgba($color-black, .6);
    }
  }
</style>
