<template>
  <div>
    <ul class="events-filter__month-list">
      <li v-for="(item, i) in filters" :key="i">
        <div
          class="events-filter__month-item"
          :class="{ 'is-active': item.isActive }"
          @click.prevent="toggleFilter(item, i)"
        >
          <span>{{ item.name }}</span>
          <inline-svg v-if="item.isActive && i > 0" src="close"/>
        </div>
      </li>
    </ul>
    <div class="lk-request-history__wrapper">
      <div class="lk-request-history">
        <div class="lk-request-history__row lk-request-history__row_head">
          <div class="lk-request-history__col lk-request-history__col_name">Название</div>
          <div class="lk-request-history__col">Статус</div>
          <div
            class="lk-request-history__col lk-request-history__col_toggler"
            @click="sortSet"
          >
            <div class="lk-request-history__sort" :class="{_desc: sortType === 'desc'}"></div>
            <span>Дата</span>
          </div>
          <div class="lk-request-history__col lk-request-history__col_link"></div>
        </div>
        <div class="lk-request-history__row" v-for="(item, i) in sortedItems" :key="i">
          <div class="lk-request-history__col lk-request-history__col_name" v-html="item.name"></div>
          <div class="lk-request-history__col" :style="{color: statusColor(item.status)}">
            {{ statusText(item.status)}}
            <div class="lk-request-history__tooltip">
              <inline-svg v-if="item.tooltip" src="tooltip-2" v-tooltip="item.tooltip"></inline-svg>
            </div>
          </div>
          <div class="lk-request-history__col">{{item.date[0] | getMonth}}<span v-if="item.date[1]"> — {{item.date[1] | getMonth}}</span>
          </div>
          <div class="lk-request-history__col lk-request-history__col_link">
            <div class="lk-plan__report" :class="item.src ? 'lk-plan__report--uploaded' : ''" :key="reportBtnKey">
              <template v-if="item.src">
                <a download :href="item.src" class="lk-form__link">
                  Посмотреть отчет
                </a>
                <button
                  class="lk-plan__remove-btn"
                  title="Удалить отчет"
                  @click.stop.prevent="removeReport(item.id)"
                />
              </template>
              <template v-else>
                <label class="lk-form__link lk-plan__report-label">
                  <input type="file" @change="uploadReport($event, item.id)" accept="application/vnd.openxmlformats-officedocument.presentationml.presentation">
                  Загрузить отчёт
                </label>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lk-request-history__note">*Срок загрузки отчета - не позднее 5 дней с момента завершения мероприятия
    </div>
    <button @click.stop.prevent="$root.$emit('header-tabs-change', 'add-event')" type="button"
            class="lk-plan__button button button--base">
      Предложить ещё мероприятие
    </button>
  </div>
</template>

<script>
  import InlineSvg from "./InlineSvg";
  import {VTooltip} from "v-tooltip";
  import moment from "moment";
  import axios from "axios";

  export default {
    name: "LkRequestHistory",
    components: {InlineSvg},
    directives: {
      'tooltip': VTooltip
    },
    props: {
      momentFilterList: {
        type: Array,
        default: () => [
          {
            filter: 'all',
            name: 'все',
            isActive: true
          },
          {
            filter: 'waiting',
            name: 'Принята к рассмотрению',
            isActive: false
          },
          {
            filter: 'denied',
            name: 'отклонена',
            isActive: false
          },
          {
            filter: 'approved',
            name: 'одобрена',
            isActive: false
          }
        ]
      },
      initItems: {
        type: Array,
        default: () => [
          {
            name: 'Форум инновационных идей',
            status: 'waiting',
            date: ['15.05.2021'],
            src: '#'
          },
          {
            name: 'Всероссийская студенческая олимпиада <br> «Я – профессионал»',
            status: 'denied',
            date: ['21.05.2021'],
            src: '#',
            tooltip: 'Ваше мероприятие не соответсвует целям и задачам Года науки.'
          },
          {
            name: 'Цикл публичных выступлений «На острие науки» о главных достижениях российской науки и технологий',
            status: 'approved',
            date: ['28.05.2021', '1.06.2021'],
            src: '#',
          },
          {
            name: 'Создание онлайн-календаря научных достижений в России «Ни дня без науки»',
            status: 'approved',
            date: ['21.05.2021'],
            src: '#',
          },
          {
            name: 'Подведение итогов проекта «Научный десант-2021»',
            status: 'denied',
            date: ['1.06.2021', '1.07.2021'],
            src: '#',
            tooltip: 'Ваше мероприятие не соответсвует целям и задачам Года науки.'
          },
        ]
      }
    },
    data() {
      return {
        sortType: 'asc',
        sortBy: 0,
        currentFilter: 'all',
        filters: [],
        items: [],
        reportBtnKey: 0,
      }
    },
    computed: {
      sortedItems() {
        const vm = this;
        let result = this.items;
        if (this.currentFilter !== 'all') {
          result = result.filter(function (item) {
            return item.status === vm.currentFilter;
          })
        }
        result = result.sort(function (a, b) {
          return moment(a.date[0], 'DDMMYYYY') - moment(b.date[0], 'DDMMYYYY');
        });
        if (vm.sortType === 'asc') {
          return result;
        } else {
          return result.reverse();
        }
      }
    },
    mounted() {
      this.filters = this.momentFilterList;
      this.items = this.initItems;
    },
    filters: {
      getMonth(date) {
        const month = +date.split('.')[1];
        let monthText = '';
        switch (month) {
          case 1:
            monthText = 'Января';
            break;
          case 2:
            monthText = 'Февраля';
            break;
          case 3:
            monthText = 'Марта';
            break;
          case 4:
            monthText = 'Апреля';
            break;
          case 5:
            monthText = 'Мая';
            break;
          case 6:
            monthText = 'Июня';
            break;
          case 7:
            monthText = 'Июля';
            break;
          case 8:
            monthText = 'Августа';
            break;
          case 9:
            monthText = 'Сентября';
            break;
          case 10:
            monthText = 'Октября';
            break;
          case 11:
            monthText = 'Ноября';
            break;
          case 12:
            monthText = 'Декабря';
            break;
          default:
            break;
        }
        return +date.split('.')[0] + ' ' + monthText;
      }
    },
    methods: {
      sortSet() {
        if (this.sortType === 'asc') {
          this.sortType = 'desc';
        } else {
          this.sortType = 'asc';
        }
      },
      toggleFilter(el, i) {
        if (this.filters[i].isActive === true) {
          this.filters[i].isActive = !this.filters[i].isActive;
          this.filters[0].isActive = true;
          this.currentFilter = this.filters[0].filter;
        } else {
          this.filters.forEach(item => {
            item.isActive = false;
          });
          this.filters[i].isActive = true;
          this.currentFilter = this.filters[i].filter;
        }
      },
      statusColor(status) {
        switch (status) {
          case 'denied':
            return '#FF7171';
          case 'approved':
            return '#1DCE00';
          default:
            return 'inherit'
        }
      },
      statusText(status) {
        switch (status) {
          case 'denied':
            return 'Отклонено';
          case 'approved':
            return 'Одобрено';
          case 'waiting':
          default:
            return 'Принято к рассмотрению'
        }
      },
      removeReport(id) {
        axios.post('/local/ajax/updateEvent.php', {
          removeReport: id,
        })
          .then((res) => {
            if (res.data.status === 'success') {
              this.handleReport(id);
            } else {
              console.log(`Request failed, status ${res.data.status}`);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },
      uploadReport($event, id) {
        const data = new FormData();
        data.append('id', id);
        data.append('report', $event.target.files[0]);

        axios({
          url: '/local/ajax/updateEvent.php',
          method: 'post',
          headers: {'content-type': 'multipart/form-data'},
          data,
        })
          .then((res) => {

            if (res.data.status === 'success') {
              this.handleReport(id, res.data.url);
            } else {
              console.log(`Request failed, status ${res.data.status}`);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },
      handleReport(id, url = null) {
        this.items.forEach((event) => {
          if (event.id === id) {
            event.src = url;
            this.reportBtnKey += 1;
          }
        });
      },
    }
  }
</script>

<style lang="scss">
  @import "../scss/base/includes";

  .lk-request-history {
    padding: 30px 0 40px;
    @include mobile {
      min-width: #{$container-pd-left-mobile + 768};
      padding-right: $container-pd-left-mobile;
    }

    &__wrapper {
      @include mobile {
        padding-left: $container-pd-left-mobile;
        margin-left: -#{$container-pd-left-mobile};
        margin-right: -#{$container-pd-left-mobile};
        overflow-x: auto;
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba($color-black, .2);

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &_head {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: rgba($color-black, .5);
      }
    }

    &__col {
      position: relative;
      width: 20%;
      @include tablet {
        font-size: 14px;
      }

      &_toggler {
        cursor: pointer;
      }

      &_name {
        width: 45%;
        padding-right: 110px;
      }

      &_link {
        width: 15%;

        a {
          text-decoration: none;
          color: $color-base;
        }
      }
    }

    &__sort {
      &:before,
      &:after {
        content: '';
        position: absolute;
        left: -15px;
        z-index: 1;
        width: 0;
        height: 0;
        border-style: solid;
      }

      &:before {
        top: 2px;
        border-width: 0 3.5px 6.1px 3.5px;
        border-color: transparent transparent #007bff transparent;
      }

      &:after {
        top: 12px;
        border-width: 6.1px 3.5px 0 3.5px;
        border-color: #007bff transparent transparent transparent;
        opacity: .5;
      }

      &._desc {
        &:before {
          opacity: .5;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    &__note {
      font-size: 12px;
      line-height: 20px;
      color: rgba($color-black, .5);
      margin-bottom: 32px;
    }

    &__tooltip {
      display: inline-block;
      vertical-align: middle;
      width: 17px;
      height: 17px;
      margin-left: 8px;

      &:hover {
        circle {
          fill: transparent;
          stroke: $color-base;
          r: 8;
        }

        path {
          fill: $color-base;
        }
      }
    }

    .button {
      min-width: 385px;
    }
  }
</style>
