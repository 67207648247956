<template>
  <div class="initiatives-partners">
    <slot name="header">
      <div v-if="isVisibleCaption" class="initiatives-partners__header">
        <h2 class="initiatives-partners__title tt-uppercase title-xl">
          Партнеры
        </h2>
      </div>
      <div class="initiatives-partners__subheader">
        <p v-if="isVisibleCaption" class="initiatives-partners__text">
          Стать партнером инициативы –
          <a
            class="initiatives-partners__link-mail u-link u-link--base"
            href="mailto:info@10nauki.ru"
          >
            info@10nauki.ru
          </a>
        </p>
        <h2 v-else class="initiatives-partners__title tt-uppercase title-xl">
          Партнеры
        </h2>
        <div class="initiatives-partners__swiper-controls">
          <div class="u-swiper-controls">
            <button
              ref="btnPrev"
              class="u-swiper-controls__btn u-swiper-controls__btn--prev">
            </button>
            <div
              ref="paginationFraction"
              class="u-swiper-controls__count"
            >
            </div>
            <button
              ref="btnNext"
              class="u-swiper-controls__btn u-swiper-controls__btn--next">
            </button>
          </div>
        </div>
      </div>
    </slot>
    <div class="initiatives-partners__body">
      <swiper
        v-if="isUpdatedSwiper"
        ref="initiativesSwiper"
        class="initiatives-partners__swiper"
        :options="swiperOptions"
      >
        <swiper-slide v-if="isEditable" class="initiatives-partners__swiper-item">
          <AddendumCard class="initiatives-partners__addendum-card" @click="addPartner">
            Добавить партнера
          </AddendumCard>
        </swiper-slide>
        <swiper-slide
          v-for="(card, i) in items"
          :key="`initiatives-partners__swiper-item-${i}`"
          class="initiatives-partners__swiper-item"
        >
          <slot name="card" :card="card">
            <InitiativesPartnersCard
              class="initiatives-partners__partner-card"
              :card="card"
            />
          </slot>
          <EditingCard
            v-if="isEditable"
            gap="sm"
            @edit="editPartner(card.id)"
            @remove="removePartner(card.id)"
          />
        </swiper-slide>
      </swiper>
      <div class="initiatives-partners__swiper-pagination-mob">
        <div ref="paginationMobile" class="u-swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  inject: {
    isEditable: {
      from: 'isEditable',
      default: false
    },
  },
  props: {
    initialItems: {
      type: Array,
      default: ()=> []
    },
    partnerTypeOptions: {
      type: Array,
      default: () => []
    },
    initiativeId: { type: String, default: '' },
    actionSave: { type: String, default: '' },
    actionGet: { type: String, default: '' },
    actionDelete: { type: String, default: '' },
    isVisibleCaption: { type: Boolean, default: true }
  },
  data() {
    return {
      isUpdatedSwiper: true,
      paginationEl: this.$refs.paginationFraction,
      items: JSON.parse(JSON.stringify(this.initialItems)),
      windowWidth: null,
    }
  },
   computed: {
    swiper() {
      return this.$refs.initiativesSwiper.$swiper;
    },
    swiperOptions() {
      return {
        slidesPerView: 1.17,
        slidesPerGroup: 1,
        spaceBetween: 16,
        navigation: {
          prevEl: this.$refs.btnPrev,
          nextEl: this.$refs.btnNext,
          disabledClass: "u-swiper-controls__btn--is-disabled"
        },
        pagination: {
          el: this.paginationEl,
          type: "bullets",
          bulletClass: "u-swiper-pagination__bullet",
          bulletActiveClass: "u-swiper-pagination__bullet--is-active",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          },
          1280: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          }
        }
      }
    }
  },
  methods: {
    updateSwiper(){
      if (!this.windowWidth || this.windowWidth !== window.innerWidth) {
        this.windowWidth = window.innerWidth

        this.isUpdatedSwiper = false
        this.setPaginationEl()
        this.$nextTick(()=> {
          this.isUpdatedSwiper = true
        })
      }
    },
    setPaginationEl() {
      if (window.innerWidth > 767) {
        this.paginationEl = this.$refs.paginationFraction
      } else {
        this.paginationEl = this.$refs.paginationMobile
      }
    },
    addPartner() {
      this.$root.$refs.customModal.openModal(null, null, true, true);
      this.$root.$refs.customModal.passContent({
        name: 'initiatives-partners-form',
        props: {
          initiativeId: this.initiativeId,
          actionSave: this.actionSave,
          partnerTypeOptions: this.partnerTypeOptions,
        },
        title: 'добавить партнера',
      })
    },
    async editPartner(id) {
      this.$root.$refs.customModal.openModal(null, null, true, true);

      const initialForm = await this.getFormPropsForEdit(id)

      if (!initialForm) {
        this.$root.$refs.customModal.closeModal()
        return
      }

      this.$root.$refs.customModal.passContent({
        name: 'initiatives-partners-form',
        props: {
          initiativeId: this.initiativeId,
          actionSave: this.actionSave,
          partnerTypeOptions: this.partnerTypeOptions,
          initialForm,
          idUpdate: id,
        },
        title: 'редактировать партнера',
      })
    },
    async removePartner(id) {
      const options = {
        url: this.actionDelete,
        method: 'POST',
        data: {
          initiativeId: this.initiativeId,
          idDelete: id,
        }
      };

      const response = await axios(options);

      if (response.data.status === 'success') {
        this.items = this.items.filter(item => item.id !== id)
      } else {
        const error = response.data.errors
          .map(item => item.message)
          .join('. ')

        alert(error)
      }
    },
    async getFormPropsForEdit(id) {
      const response = await axios(`${this.actionGet}&id=${id}`)

      if (response.data.status === 'success') {
        return response.data.data
      } else {
        const error = response.data && response.data.errors ? response.data.errors
          .map(item => item.message)
          .join('. ')
          : 'Произошла непредвиденная ошибка'
        alert(error)
      }
    },
    updatePartnersList(response) {
       if (response.type === 'update') {
        const index = this.items.findIndex(item => item.id === response.data.id)
        this.items = [...this.items.slice(0, index), response.data, ...this.items.slice(index + 1)]
      } else {
        this.items = [response.data, ...this.items]
      }
    }
  },

  mounted() {
    window.addEventListener('resize', this.updateSwiper)
    this.updateSwiper()
    this.$root.$on('editedPartnersList', this.updatePartnersList)
    if (this.$slots.header != undefined) {
      this.$refs.btnPrev = this.$slots.header[0].context.$refs.btnPrev;
      this.$refs.paginationFraction = this.$slots.header[0].context.$refs.paginationFraction;
      this.$refs.btnNext = this.$slots.header[0].context.$refs.btnNext;
      this.paginationEl = this.$refs.paginationFraction;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSwiper)
    this.$root.$off('editedPartnersList', this.updatePartnersList)
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

.initiatives-partners {

  .swiper-slide {
    height: auto !important;
  }
  .swiper-wrapper {
    @include mobile-min {
      padding: 10px 0;
    }
  }
  .swiper-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -15px;
    margin-right: -15px;
  }

  // .initiatives-partners__header
  &__header {
    margin-bottom: 32px;
    display:  flex;
    justify-content: space-between;


    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .initiatives-partners__title
  &__title {
    margin: 0;
  }

  // .initiatives-partners__subheader
  &__subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    @include mobile {
      display: block;
      margin-bottom: 24px;
    }
  }

  // .initiatives-partners__text
  &__text {
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    margin: 0 20px 0 0;

    @include mobile {
      font-size: 16px;
      line-height: 130%;
      margin: 0;
    }
  }

  // .initiatives-partners__swiper-controls
  &__swiper-controls {
    margin-left: auto;
    @include mobile {
      display: none;
    }
  }

  // .initiatives-partners__swiper
  &__swiper {
    @include mobile {
      margin-bottom: 24px;
    }

    // .initiatives-partners__swiper-item
    &-item {
      height: auto;
      position: relative;
      border-radius: 16px;
    }
  }

  // .initiatives-partners__swiper-pagination-mob
  &__swiper-pagination-mob {
    display: flex;
    justify-content: center;

    @include mobile-min {
      display: none;
    }
  }

  // .initiatives-partners__addendum-card
  &__addendum-card {
    min-height: 286px;
  }
}
</style>
