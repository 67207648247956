<template>
  <div class="quill-wyz" :class="{'quill-wyz--is-error': isError}">
    <div
      v-html="value"
      class="quill-wyz__editor"
      :style="quillEditorStyle"
      :id="`editor-${_uid}`"
    />
  </div>
</template>

<script>
import Quill from 'quill';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

export default {
  inject: ['breakpoint'],
  props: {
    toolbarOptions: {
      type: Array,
      default: () => [
        ['bold', 'italic', 'underline', 'strike', 'link'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'header': [2, 3, 4, 5, 6, false] }],
      ]
    },
    placeholder: {
      type: String,
      default: '...'
    },
    initialValue: {
      type: String,
      default: ''
    },
    heightEditor: {
      type: Object,
      default: () => ({
        lg: '380',
        md: '300'
      })
    },
    isError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editor: null,
      value: this.initialValue
    }
  },
  computed: {
    editorOptions() {
      return {
        modules: {
          toolbar: this.toolbarOptions
        },
        theme: 'snow',
        placeholder: this.placeholder,
      }
    },
    quillEditorStyle() {
      const styles = []

      if (this.breakpoint.isDesktop) {
        styles.push(`height: ${this.heightEditor.lg}px`)
      } else {
        styles.push(`height: ${this.heightEditor.md}px`)
      }

      return styles.join(';')

    }
  },
  mounted() {
    this.editor = new Quill(`#editor-${this._uid}`, this.editorOptions)
    this.editor.on('text-change', this.onChange)
  },
  beforeDestroy() {
    this.editor.off('text-change', this.onChange)
  },
  methods: {
    onChange() {
      const text = this.editor.getText()
      const value = Boolean(text) && Boolean(text.trim()) ? this.editor.root.innerHTML : ''
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.quill-wyz';

#{$b} {

  // .quill-wyz--is-error
  &--is-error {
    .ql-container,
    .ql-toolbar {
      border-color: $color-danger !important;
    }
  }

  // .quill-wyz__editor
  &__editor {
    font-size: 16px !important;
    color: $black-true !important;
    font-family: $font-family-inter !important;
  }
}
</style>
