<template>
  <u-figures
    :items="[
      {'name':'f-search-page-1','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
    ]"
    :fullWidth="true"
  >
    <div
      ref="searchPage"
      class="search-page"
    >
      <div class="u-g-container">
        <div class="search-page__top">
          <form ref="searchForm" class="search-page__search">
            <input class="search-page__search-input" type="text" placeholder="Поиск" name="q" v-model="search">
            <div class="search-page__search-button" @click="searchSubmit">
              <inline-svg src="u-search-icon" />
            </div>
          </form>
        </div>
      </div>

      <div
        v-if="items.length"
        class="search-page__bg"
      >
        <div class="u-g-container">
          <div class="search-page__result">
            <div class="search-page__result-side">
              <div class="search-page__result-number">
                {{ declOfNum(initFound || 0, ['Найден', 'Найдено', 'Найдено']) }}
                {{ initFound || 0 }}
                {{ declOfNum(initFound || 0, ['результат', 'результата', 'результатов']) }}
              </div>
            </div>
            <div class="search-page__result-body">
              <ul class="search-page__list">
                <li v-for="item in items" :key="item.id">
                  <a :href="hrefWithRegion(item.url)" class="search-page__item">
                    <h3
                      class="search-page__item-title"
                      v-html="item.titleFormated"
                    >
                    </h3>
                    <div
                      v-if="Boolean(item.bodyFormated)"
                      class="search-page__item-text"
                      v-html="item.bodyFormated"
                    />
                  </a>
                </li>
              </ul>

              <div class="search-page__load-more" v-show="needShowLoadMore">
                <CustomButton
                  theme="primary"
                  size="md"
                  @click="loadMore"
                >
                  Показать ещё
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="search-page__bg">
        <div class="u-g-container"><p>Ничего не найдено</p></div>
      </div>
    </div>
    <scroll-top-btn></scroll-top-btn>
  </u-figures>
</template>

<script>
import axios from "axios";

export default {
  name: "SearchPage",
  props: {
    initSearch: { type: String },
    initItems: { type: Array },
    initFound: { type: Number },
    initNavResult: { type: Object }
  },
  data() {
    return {
      search: this.initSearch,
      items: this.initItems,
      navResult: this.initNavResult
    };
  },
  computed: {
    needShowLoadMore() {
      return this.navResult.NavPageNomer < this.navResult.NavPageCount;
    },
    loadMoreUrl() {
      const page = parseInt(this.navResult.NavPageNomer) + 1;

      return '/search/?ajax=Y&q=' + this.search + '&PAGEN_1=' + page;
    }
  },
  methods: {
    searchSubmit() {
      this.$refs.searchForm.submit();
    },
    declOfNum(number, titles) {
      const cases = [2, 0, 1, 1, 1, 2];
      return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    },
    async loadMore() {
      try {
        const response = await axios({
          method: 'get',
          url: this.loadMoreUrl
        });
        if (response.data.status === 'error') {
          throw new Error(response.data.errors);
        }
        response.data.ITEMS.map(el => {
          this.items.push(el);
        });

        this.navResult = response.data.NAV_RESULT;

      } catch (e) {
        console.log(e);
        alert('Error!');
      }
    },
    setMinHeightPage() {
      const banner = document.querySelector('.banner-wrapper')
      const footer = document.querySelector('.footer')

      if (!banner || !footer) {
        return
      }

      const minHeight = window.innerHeight - (banner.offsetHeight + footer.offsetHeight);

      if (minHeight > 0) {
        this.$refs.searchPage.style = `min-height: ${minHeight}px`
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.setMinHeightPage)
    this.setMinHeightPage()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setMinHeightPage)
  }
}
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: ".search-page";
$fieldHeight: 68px;
$fieldHeightMob: 56px;
$fieldOffset: 32px;
$fieldOffsetMob: 24px;


$offset: $fieldHeight + $fieldOffset;
$offsetMobile: $fieldHeightMob + $fieldOffsetMob;

#{$b} {
  display: block;
  font-family: $font-family-inter;

  // .search-page-banner
  &-banner {
    .banner-wrapper__banner {
      min-height: 331px;

      @include mobile {
        min-height: 315px;
      }

      .u-g-container {
        margin-top: 0;
      }
    }
  }
  // .search-page-banner__inner-header
  &-banner__inner-header {
    padding: 100px 0;

    @include mobile {
      padding-top: 130px;
    }
  }

  &-section {
    position: relative;
    z-index: 10;
    margin-top: #{-$offset};


    @include mobile {
      margin-top: #{-$offsetMobile};
    }

  }

  &__search {
    display: block;
    position: relative;
    color: $white-true;
    margin-bottom: $fieldOffset;

    @include mobile {
      margin-bottom: $fieldOffsetMob;
    }

    &-input {
      height: $fieldHeight;
      width: 100%;
      display: block;
      padding: 16px 60px 16px 32px;
      border: 2px solid rgba($white-true, 0.2);
      border-radius: 34px;
      background-color: rgba($white-true, 0.05);
      font-family: $font-family-main-head;
      color: inherit;
      font-weight: 500;
      // important - IOS fix
      font-size: 28px !important;
      line-height: 1;
      appearance: none;
      transition: $transtion-default;

      &:hover,
      &:focus {
        border-color: $white-true;
      }

      @include mobile {
        padding: 12px 50px 12px 24px;
        height: $fieldHeightMob;
      }

      @include placeholder {
        color: inherit;
        opacity: 0.8;
      }
    }

    &-button {
      cursor: pointer;
      display: block;
      position: absolute;
      top: 0;
      right: 22px;
      width: 24px;
      height: $fieldHeight;
      text-align: right;
      color: inherit;
      transition: color .2s ease;
      padding-top: 23px;

      &:hover,
      &:focus {
        color: $color-base;
      }

      @include mobile {
        padding-top: 16px;
        height: $fieldHeightMob;
      }

      .inline-svg {
        display: inline-block;
        width: 22px;
        height: 22px;
      }
    }
  }


  &__result {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &-body {
      width: calc(100% - 312px);

      @include low-desktop {
        width: calc(100% - 280px);
      }

      @include tablet {
        width: calc(100% - 236px);
      }

      @include mobile {
        width: 100%;
      }
    }

    &-side {
      width: 312px;
      padding-left: 50px;
      order: 99;
      text-align: right;

      @include low-desktop {
        width: 280px;
      }

      @include tablet {
        width: 236px;
      }

      @include mobile {
        order: 0;
        text-align: left;
        padding-left: 0;
        margin-bottom: 32px;
        width: 100%;
      }
    }

    &-number {
      display: block;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.5;
      text-transform: uppercase;
      color: $color-info;

      @include mobile {
        font-size: 16px;
        line-height: 1.3;
      }
    }

    b {
      font-weight: normal;
      background-color: #C4DAFF;
    }
  }

  // .search-page__bg
  &__bg {
    background-color: $color-secondary;
    padding-top: 64px;
    padding-bottom: 128px;
    min-height: inherit;

    @include mobile {
      padding-top: 32px;
      padding-bottom: 64px;
    }
  }

  // .search-page__bg-circle
  &__bg-circle{
    position: absolute;
    left: 0;
    top: calc(25px + $offset);
    width: 700px;
    aspect-ratio: 700/700;
    pointer-events: none;
    touch-action: none;
    background-image: url('/frontend/assets/img/bg-shapes/circle-right-opacity.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);


    @include mobile {
      width: 408px;
      left: -162px;
      top: calc(18px + $offsetMobile)
    }
  }

  &__list {
    list-style: none;
    padding-left: 0;
    margin: 0;

    li {
      padding-bottom: 32px;
      border-bottom: 1px solid $black-true;

      &:not(:first-child) {
        padding-top: 32px;
      }
    }
  }

  &__item {
    display: block;
    color: $color-black;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      #{$b}__item-title {
        color: $color-base-origin;
        text-decoration: underline;
      }
    }

    &-title {
      color: inherit;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.5;
      transition: $transtion-default;
      margin: 0;

      @include mobile {
        font-size: 16px;
        line-height: 1.5;
      }
    }

    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.3;
      color: inherit;
      margin: 16px 0 0 0;

      @include mobile {
        font-size: 12px;
      }
    }

  }

  &__load-more {
    margin-top: 32px;
    @include mobile {
      text-align: center;
    }
  }
}
</style>
