<template>
  <nav
    class="navigation-block"
    :class="{ 'navigation-block--is-slider': isSlider }"
  >
    <div v-if="SECONDARY_ITEMS?.length" :class="{ 'u-g-container': withContainer }">
      <div class="navigation-block__inner">
        <component
          :is="listTag"
          :options="isSlider ? swiperOptions : {}"
          class="navigation-block__list"
        >
          <component
            :is="itemTag"
            v-for="item in SECONDARY_ITEMS"
            :key="`navigation-block__item-${item.id}`"
            class="navigation-block__item"
          >
            <NavigationCard class="navigation-block__card" :card="item" />
          </component>
        </component>
        <div v-show="hasNavigation" class="navigation-block__controls">
          <div class="navigation-block__control navigation-block__control--prev" :class="`navigation-block__control-prev-${_uid}`">
            <inline-svg src="u-triangle-left" />
          </div>
          <div class="navigation-block__control navigation-block__control--next" :class="`navigation-block__control-next-${_uid}`">
            <inline-svg src="u-triangle-right" />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isSlider: {
      type: Boolean,
      default: false
    },
    withContainer: {
      type: Boolean,
      default: false
    },
    withSaveHeight: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      resizeObserver: null,
      slidePerView: 4,
    }
  },

  computed: {
    ...mapGetters('header', ['SECONDARY_ITEMS']),
    listTag() {
      return this.isSlider ? 'swiper' : 'div'
    },
    itemTag() {
      return this.isSlider ? 'swiper-slide' : 'div'
    },
    swiperOptions() {
      return {
        slidesPerView: this.slidePerView,
        spaceBetween: 20,
        navigation: {
          prevEl: `.navigation-block__control-prev-${this._uid}`,
          nextEl: `.navigation-block__control-next-${this._uid}`,
          disabledClass: "navigation-block__control--is-disabled"
        },
        loop: this.hasNavigation
      }
    },
    hasNavigation() {
      return this.SECONDARY_ITEMS?.length > this.slidePerView
    }
  },

  methods: {
    saveHeight() {
      if (!this.withSaveHeight) {
        return
      }

      const method = this.$el.scrollHeight ? 'add' : 'remove'
      document.documentElement.style.setProperty('--navigation-height', `${Math.ceil(this.$el.scrollHeight)}px`);
      document.body.classList[method]('with-navigation-block-into-banner')
    },
    resizeObserverHandler(entries) {
      entries.forEach(() => {
        this.saveHeight()
      })
    }
  },

  mounted() {
    if (this.withSaveHeight) {
      this.resizeObserver = new ResizeObserver(this.resizeObserverHandler)
      this.resizeObserver.observe(this.$el)

      this.$nextTick().then(this.saveHeight)
    }
  },

  beforeDestroy() {
    document.body.classList.remove('with-navigation-block-into-banner')
    this.resizeObserver?.unobserve(this.$el)
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.navigation-block';

#{$b} {

  // .navigation-block__item
  &__item {
    #{$b}:not(#{$b}--is-slider) & {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    #{$b}--is-slider & {
      height: auto;
    }
  }

  // .navigation-block__inner
  &__inner {
    position: relative;
  }

  // .navigation-block__card
  &__card {
    #{$b}--is-slider & {
      height: 100%;
    }
  }

  // .navigation-block__controls
  &__controls {
    @include tablet {
      display: none !important;
    }
  }

  // .navigation-block__control
  &__control {
    position: absolute;
    top: 50%;
    @include boxsize(40px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 11px;
    border-radius: 50%;
    background-color: $color-base-origin;
    border: 2px solid $color-base-origin;
    cursor: pointer;
    user-select: none;
    transition: $transtion-default;
    z-index: 1;

    path {
      fill: transparent !important;
      stroke: currentColor !important;
    }

    &--is-disabled {
      pointer-events: none;
      touch-action: none;
      opacity: 0.75;
    }

    &--prev {
      left: 0;
      transform: translateX(-50%) translateY(-50%);

      svg {
        padding-right: 3px;
      }
    }

    &--next {
      right: 0;
      transform: translateX(50%) translateY(-50%);

      svg {
        padding-left: 3px;
      }
    }

    @include hover {
      background-color: $white-true;
      color: $color-base-origin;
    }
  }
}
</style>
