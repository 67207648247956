<template>
  <div class="header-tabs">
    <div class="header-tabs__top">
      <div class="header-tabs__overflow" v-dragscroll.x:nochilddrag>
        <ul class="header-tabs__list">
          <li v-for="item in tabs" :key="item.tabId">
            <div
              class="header-tabs__item"
              :class="{ 'is-active': item.isActive }"
              @click.prevent="selectTab(item.tabId)"
            >
              {{ item.title }}
            </div>
          </li>
        </ul>
      </div>
      <div class="header-tabs__container">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "HeaderTabs",
    data() {
      return {
        tabs: [],
      };
    },
    mounted() {
      this.tabs = this.$children;
      this.$root.$on('header-tabs-change', this.selectTab);
    },
    methods: {
      selectTab(tabId) {
        this.tabs.forEach(tab => {
          tab.isActive = tab.tabId === tabId;
        });
        this.$root.$emit('header-tab-change');
      },
    }
  };
</script>

<style lang="scss">
  @import "../scss/base/includes";

  $b: '.header-tabs';
  $offsetTop: 64px;

  #{$b} {
    display: block;
    position: relative;
    z-index: 2;

    &::before {
      content: "";
      position: absolute;
      top: 12px;
      right: calc(100% + 38px);
      width: 116px;
      height: 1.5px;
      background-color: $color-base;

      @include desktop {
        right: calc(100% + 30px);
        width: 85px;
      }

      @include tablet {
        display: none;
      }
    }

    &__wrapper {
      position: relative;
      z-index: 2;
      padding-top: 0;
      padding-bottom: 70px;
      margin-top: #{-$offsetTop};

      @include desktop {
        padding-bottom: 50px;
      }

      @include mobile {
        margin-top: calc(-#{$offsetTop} + 1px);
      }

      .figures {
        position: absolute;
        top: $offsetTop;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
    }

    &__top {
      margin-bottom: 90px;

      @include desktop {
        margin-bottom: 50px;
      }

      @include mobile {
        margin-bottom: 30px;
      }
    }

    &__overflow {
      min-width: 100%;
      -ms-overflow-style: none;
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none;
      box-sizing: border-box;

      @include tablet {
        margin-left: #{-$container-pd-left-tablet};
        margin-right: #{-$container-pd-right-tablet};
        padding-left: $container-pd-left-tablet;
        padding-right: $container-pd-right-tablet;
      }

      @include mobile {
        margin-left: #{-$container-pd-left-mobile};
        margin-right: #{-$container-pd-right-mobile};
        padding-left: $container-pd-left-mobile;
        padding-right: $container-pd-right-mobile;
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: inline-flex;
      align-items: flex-end;
      min-width: 100%;

      &>li {

        &:not(:last-child) {
          margin-right: 30px;

          @include low-desktop {
            margin-right: 26px;
          }
        }
      }
    }

    &__item {
      cursor: pointer;
      display: block;
      position: relative;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: rgba($color: $white-true, $alpha: 0.7);
      padding-bottom: 40px;
      white-space: nowrap;

      @include mobile {
        font-size: 10px;
      }

      &::after {
        content: "";
        width: 100%;
        height: 7px;
        background-color: transparent;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &.is-active {
        color: $white-true;

        &::after {
          background-color: $color-base;
        }
      }
    }
  }
</style>
