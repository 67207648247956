<template>
  <div>
    <div v-if="smiData.length" class="u-lk-media">
      <h3 class="u-lk-form__subtitle u-lk-media__title">Число публикаций в СМИ</h3>
      <div class="u-lk-media__body">
        <div class="u-lk-media__row">
          <div v-for="item in smiData" :key="item.code" class="u-lk-media__col">
            <h4 class="u-lk-media__col-title">
              {{ item.name }}
            </h4>
            <div class="u-lk-media__col-value">
              <span>{{ item.value }}</span>
              <span
                v-if="item.additional"
                class="u-lk-media__col-additional"
              >
                {{ item.additional > 0 ? `+${item.additional}` : item.additional }}
              </span>
            </div>
          </div>
        </div>
        <div class="u-lk-media__note">*по данным медиалогии</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LkRaportCommunity",
  props: {
    initialSmiData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      smiData: [],
    }
  },
  created() {
    this.smiData = [...this.initialSmiData];
  },
}
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: '.u-lk-media';

#{$b} {
  font-family: $font-family-inter;
  font-size: 16px;
  line-height: 1.3;
  font-family: 400;

  // .u-lk-media__title
  &__title {
    margin-bottom: 32px;
  }

  // .u-lk-media__row
  &__row {
    max-width: 1060px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 30px;

    @include mobile {
      display: block;
    }
  }

  // .u-lk-media__col
  &__col {
    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }

    &-title {
      font-size: inherit;
      font-weight: inherit;
      margin: 0 0 16px 0;
    }

    &-value {
      font-size: 24px;
      line-height: 1.5;
    }

    &-additional {
      color: $color-base-origin;
    }
  }

  // .u-lk-media__note
  &__note {
    color: $color-info-secondary;
    margin-top: 24px;
  }
}

</style>
