<template>
    <div
      class="u-text-input"
      :class="[
        {
          _error: error,
          'u-text-input--is-loading': isLoading
        },
        `u-text-input--textarea-size-${textAreaSize}`
      ]"
      >
      <div v-if="label.length" class="u-text-input__label" v-html="label"></div>
      <div v-else-if="isExistSlotLabel" class="u-text-input__label">
        <slot name="label"></slot>
      </div>
      <div class="u-text-input__wrapper">
        <textarea
          v-if="isTextArea"
          :name="name"
          :value="value"
          :class="{_error: error}"
          class="u-text-input__textarea"
          :placeholder="placeholder"
          v-on:input="$emit('input', $event.target.value)"
        >
        </textarea>
        <input
          v-else
          :type="type"
          ref="input"
          :class="{
            _error: error,
            _editable: editableAction,
            _readonly: isReadOnly,
            '_ios-fix': isIOSFix,
            '_readonly-ios': isReadOnlyField && isIOS
          }"
          class="u-text-input__input"
          :name="name"
          :value="value"
          :placeholder="placeholder"
          :readonly="isReadOnlyField && !isIOS"
          v-mask="mask ? mask : ''"
          @blur="onBlur"
          @keydown="submitEditFromEnter"
          @input="handleInput"
        >
        <div
          v-if="editableAction"
          class="u-text-input__edit"
          @click="editHandler"
          :class="{'u-text-input__edit--editing': !isEdit}"
        >
          <div v-if="isEdit" class="u-text-input__edit-item _confirm">
            <inline-svg src="confirm-icon"/>
          </div>
          <div v-else class="u-text-input__edit-item _edit">
            <inline-svg src="edit-icon"/>
          </div>
        </div>
        <div
          v-if="isPassword"
          class="u-text-input__eye"
          :class="{
            'is-active': passwordShowed,
          }"
        >
          <inline-svg v-if="passwordShowed" src="u-eye" @click.prevent="changePasswordType"/>
          <inline-svg v-else src="eye-close" @click.prevent="changePasswordType"/>
        </div>
        <inline-svg
          v-if="isLoading"
          class="u-text-input__loading"
          src="loading"
        />
      </div>
    </div>
</template>

<script>
  import { VueMaskDirective } from 'v-mask'

  export default {
    name: "TextInput",
    props: {
      placeholder: String,
      value: String,
      name: String,
      label: {
        type: String,
        default: ''
      },
      error: {
        type: Boolean,
        default: false
      },
      isTextArea: {
        type: Boolean,
        default: false
      },
      editableAction: {
        type: String,
        required: false
      },
      mask: {
        type: String,
        required: false
      },
      inputType: {
        type: String,
        default: "text"
      },
      isPassword: {
        type: Boolean,
        default: false
      },
      isReadOnly: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      maxLength: {
        type: Number,
        default: Number.POSITIVE_INFINITY
      },
      textAreaSize: {
        type: String,
        default: 'default'
      }
    },
    data() {
      return {
        isEdit: false,
        passwordType: "password",
        passwordShowed: false,
        type: this.inputType,
      }
    },
    directives: {mask: VueMaskDirective},
    methods: {
      edit() {
        this.isEdit = true;
        this.$refs.input.focus();
      },
      changePasswordType() {
        this.passwordShowed = this.type !== "text";
        this.type = this.type === "text" ? "password" : "text";
      },
      submit() {
        this.$emit('change');
        this.isEdit = false;
        this.$refs.input.blur();
      },
      submitEditFromEnter(e) {
        if ((e.key === "Enter" || e.keyCode === 13) && this.isEdit) {
          this.submit()
        }
      },
      onBlur() {
        this.$emit('blur')
        this.submit()
      },
      editHandler() {
        if (this.isEdit) {
          this.submit()
        } else {
          this.edit()
        }
      },
      handleInput(e) {
        let value = e.target.value

        const conditionMaxLength = typeof value === 'number'
          ? String(value).length > this.maxLength
          : value.length > this.maxLength

        if (conditionMaxLength) {
          value = value.slice(0, this.maxLength)
          e.target.value = value
        }

        this.$emit('input', value)
      }
    },
    computed: {
      isIOS() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      },
      isIOSFix() {
        return this.isPassword && this.isIOS
      },
      isReadOnlyField() {
        return !!this.editableAction && !this.isEdit || this.isReadOnly
      },
      isExistSlotLabel() {
        return Boolean(this.$slots.label?.length)
      }
    },
  }
</script>

<style lang="scss">
  @import "../scss/base/u-includes";
  .u-text-input {
    font-family: $font-family-inter;
    color: $black-true;

    // .u-text-input--is-loading
    &--is-loading {
      pointer-events: none;
      touch-action: none;

      input, textarea {
        opacity: 0.75;
      }
    }

    &.u-text-input--smaller {
      .u-text-input__label {
        margin-bottom: 8px;
        margin-left: 8px;
      }

      input,
      textarea {
        font-size: 16px;
        padding: 0 8px 8px;
        border-width: 1px;

        // IOS fix
        @include mobile {
          border-radius: 0 !important;
        }
      }

      .u-text-input__textarea {
        min-height: 80px;
        max-height: 80px;
      }

      &.u-text-input--textarea-size-lg {
        .u-text-input__textarea {
          min-height: 160px;
          max-height: 160px;
        }
      }
    }


    &__label {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.3;
      margin-bottom: 11px;
    }

    &__wrapper {
      display: flex;
      position: relative;
    }

    &__edit {
      cursor: pointer;
      user-select: none;
      position: absolute;
      height: 100%;
      bottom: 0;
      right: 0;
      color: $color-base;
      padding-bottom: 10px;
      display: flex;
      align-items: flex-end;
      transition: $transtion-default;

      &:hover {
        @include mobile-min {
          color: $black-true;
        }
      }

      &--editing {
        left: 0;
        top: 0;
        display: flex;
        justify-content: flex-end;
      }

      &-item {
        display: block;

        &._edit {


          .inline-svg {
            width: 20px;
            height: 20px;

            svg {
              stroke: currentColor;
              fill: transparent !important;

              * {
                fill: transparent !important;
                stroke: currentColor;
              }
            }
          }
        }

        &._confirm {

          .inline-svg {
            width: 20px;
            height: 20px;

            svg {
              stroke: currentColor;
              fill: transparent !important;

              * {
                fill: transparent !important;
                stroke: currentColor;
              }
            }
          }
        }
      }
    }

    &__eye {
      cursor: pointer;
      user-select: none;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      color: #DEDEDE;

      &.is-active {
        color: $color-base;
        .inline-svg {
          width: 26px;
          height: 16px;
        }
      }

      .inline-svg {
        width: 23px;
        height: 12px;
      }

    }

    input,
    textarea {
      width: 100%;
      padding: 0 0 21px;
      border-bottom: $line-width solid $color-base-origin;
      font-size: 22px;
      line-height: 160%;
      transition: .2s;

      &::placeholder{
        color: rgba($color-black, 0.25)
      }

      @include mobile {
        font-size: 16px;

        .lk-form & {
          font-size: 14px;
          padding: 0 0 15px;
        }
      }

      &:focus {
        &:not(:read-only) {
          border-color: $color-base;
        }
      }

      &._error {
        border-color: $color-error !important;
      }
    }

    input {
      text-overflow: ellipsis;

      &:read-only {
        cursor: default;
      }

      &[type="password"] {
        color: rgba($color-black, 0.25)
      }

      &[type="number"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
        }
      }

      &._readonly-ios {
        pointer-events: none;
        touch-action: none;
      }

      &._editable {
        padding-right: 60px;

        @include mobile {
          padding-right: 50px;
        }
      }

      &._ios-fix {
        padding-right: 35px;
      }
    }

    textarea {
      min-height: 187px;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      resize: none;
      outline: none;
      background-color: transparent;

      @include mobile {
        font-size: 16px;
        min-height: 160px;
      }

      &:focus {
        border-color: $color-base;
      }

      &._error {
        border-color: $color-error;
      }
    }

    &__loading {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 75%;
      transform: translate(-50%, -50%);

      * {
        fill: transparent !important;
        stroke: $color-primary;
      }
    }
  }
</style>
