<template>
  <button class="btn-login" @click="onLogin">
    <div class="btn-login__icon">
      <inline-svg src="u-profile" />
    </div>
    <span class="btn-login__text">Войти</span>
  </button>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations('header', ['SET_IS_OPENED_MENU']),
    onLogin() {
      this.$root.$refs.authModal.isShow = true
      this.SET_IS_OPENED_MENU(false)
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.btn-login';

#{$b} {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  border: none;
  color: $white-true;
  padding: 0;

  &__icon {
    width: 14px;
    height: 19px;
    margin-right: 8px;

    * {
      fill: transparent !important;
    }
  }

  &__text {
    font-family: $font-family-inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;

    @include mobile {
      font-size: 28px;
    }
  }
}

</style>
