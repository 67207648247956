var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.elTag,{tag:"component",staticClass:"science-playground-card",class:[
    {
      'science-playground-card--is-video': _vm.isVideoCard,
      'science-playground-card--is-default': _vm.isDefaultCard,
      'science-playground-card--is-gallery': _vm.isGalleryCard,
      'science-playground-card--is-info': _vm.withInfoBlock,
      'science-playground-card--without-img': _vm.withoutImg,
      'science-playground-card--has-shadow': _vm.card.isShadow,
    },
    `science-playground-card--type-${_vm.type}`,
  ],attrs:{"href":_vm.hrefWithRegion(_vm.card.url)},on:{"click":_vm.openModalHandler}},[_c('div',{staticClass:"science-playground-card__inner"},[(!_vm.withoutImg)?_c('div',{staticClass:"science-playground-card__preview"},[_c('img',{staticClass:"science-playground-card__preview-img",attrs:{"src":_vm.card.preview}}),_c('div',{staticClass:"science-playground-card__btn-play"},[_c('inline-svg',{staticClass:"science-playground-card__icon-play",attrs:{"src":"play-icon"}})],1)]):_vm._e(),(_vm.card.title || (_vm.card.text && _vm.isDefaultCard) || (_vm.date))?_c('div',{staticClass:"science-playground-card__body"},[(_vm.date)?_c('time',{staticClass:"science-playground-card__date"},[_vm._v(_vm._s(_vm.date))]):_vm._e(),(_vm.card.title)?_c('h3',{staticClass:"science-playground-card__title title-lg tt-uppercase",domProps:{"innerHTML":_vm._s(_vm.card.title)}}):_vm._e(),(_vm.card.text && _vm.isDefaultCard)?_c('p',{staticClass:"science-playground-card__text",domProps:{"innerHTML":_vm._s(_vm.card.text)}}):_vm._e(),(_vm.canDetailEl)?_c('CustomButton',{staticClass:"science-playground-card__btn",attrs:{"theme":_vm.withoutImg ? 'outline-purple' : 'outline-light-purple'},on:{"click":function($event){$event.stopPropagation();return _vm.onClickBtn.apply(null, arguments)}}},[_vm._v(" Узнать больше ")]):_vm._e()],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }