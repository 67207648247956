<template>
  <div class="months-filter">
    <div class="months-filter__inner">
      <div class="months-filter__list">
        <div
          v-for="option in optionsWithSeparator"
          :key="`months-filter__item-${option.id}`"
          class="months-filter__item text-md tt-uppercase"
          :class="{
            'months-filter__item--is-active': isActive(option.id) && !option.separator,
            'months-filter__item--separator': option.separator
          }"
          @click="selectMonth(option)"
        >
          <span class="months-filter__item-name">
            {{ option.name }}
          </span>
          <InlineSvg v-if="isActive(option.id) && option.id" class="months-filter__item-icon" src="close" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { REGION_MONTH_OPTIONS } from '@/components/helpers/consts'

export default {
  props: {
    options: {
      type: Array,
      default: () => REGION_MONTH_OPTIONS
    },

    values: {
      type: Array,
      default: () => []
    },

    allKey: {
      type: String,
      default: ''
    }
  },

  computed: {
    optionsWithouAllOption() {
      return this.options?.filter(option => option.id !== this.allKey) || []
    },

    optionsWithSeparator() {
      const options = []
      const optionAll = this.options.find(option => option.id === this.allKey)

      if (optionAll) {
        options.push(optionAll)
      }

      if (this.optionsWithouAllOption?.length) {
        options.push({
          id: null,
          name: '/',
          separator: true
        })
        options.push(...this.optionsWithouAllOption)
      }

      return options
    }
  },

  methods: {
    selectMonth(option) {
      if (option.separator) {
        return
      }

      const values = option.id === this.allKey
        ? []
        : this.values?.includes(option.id)
          ? this.values.filter(v => v !== option.id)
          : [...(this.values || []), option.id]

      this.$emit('change', values)
    },

    isActive(id) {
      return id === this.allKey ? !this.values?.length : this.values?.includes(id)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.months-filter';

#{$b} {

  // .months-filter__list
  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
  }

  // .months-filter__item
  &__item {
    height: 34px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    text-align: center;
    white-space: nowrap;
    font-family: $font-family-main-head;
    cursor: pointer;
    transition: $transtion-default;
    border-radius: 16px;

    @include hover {
      &:not(#{$b}__item--is-active) {
        color: $color-primary;
      }
    }

    // .months-filter__item--is-active
    &--is-active {
      padding-left: 8px;
      padding-right: 8px;
      background-color: $color-primary;
      color: $white-true;
    }

    // .months-filter__item--separator
    &--separator {
      pointer-events: none;
      touch-action: none;
    }

    // .months-filter__item-icon
    &-icon {
      @include boxsize(9px);
    }
  }
}
</style>
