export default {
  namespaced: true,
  state: {
    isOpenedMenu: false,
    mainItems: [],
    secondaryItems: []
  },

  getters: {
    IS_OPENED_MENU(state) {
      return state.isOpenedMenu
    },
    MAIN_ITEMS(state) {
      return state.mainItems
    },
    SECONDARY_ITEMS(state) {
      return state.secondaryItems
    }
  },

  mutations: {
    SET_IS_OPENED_MENU(state, isOpened) {
      state.isOpenedMenu = isOpened
    },
    SET_MAIN_ITEMS(state, items) {
      state.mainItems = items
    },
    SET_SECONDARY_ITEMS(state, items) {
      state.secondaryItems = items
    }
  },
}
