import axios from "axios";
import moment from "moment";
const TODAY = new Date();

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    initData: {
      type: [Object, Array]
    }
  },
  data() {
    return {
      items: [
        {
          date: "2021-03-25", // Год, месяц, число
          title: "14 марта",
          text:
            "Челябинские ученые разрабатывают технологию безотходной переработки медьсодержащих шлаков."
        }
      ],
      monthTheme: "",
      dateString: "",
      title: "",
      text: "",
      description: "",
      image: "",
      detailUrl: "",
      hasDetail: false,
      selectedDay: TODAY,
      isCollapsed: true,
      isCalendarShowed: false
    };
  },
  computed: {
    monthItems() {
      const data = {};
      this.items.map(item => {
        data[item.date] = item;
      });
      return data;
    },
    eventDays() {
      return this.items.map(item => {
        return item.date;
      });
    },
    isToday() {
      const today = moment(TODAY);
      return moment(this.selectedDay).isSame(today, 'day');
    }
  },
  async mounted() {
    if (this.isDetail && !Array.isArray(this.initData)) {
      this.dateString = this.initData.dateString;
      this.title = this.initData.title;
      this.text = this.initData.text;
      this.image = this.initData.image;
      this.detailUrl = this.initData.detailUrl;
      this.hasDetail = this.initData.hasDetail;
      this.description = this.initData.description;
    }
    await this.onChangeMonth(TODAY.getMonth() + 1, TODAY.getFullYear());
    this.onSetDate(TODAY);
    this.selectedDay = TODAY;
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    toggleCalendar() {
      this.isCalendarShowed = !this.isCalendarShowed;
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = "0";
    },
    getFormatDate(date, format) {
      return date
        ? moment(date)
            .locale("ru")
            .format(format)
        : "";
    },
    async onChangeMonth(month, year) {
      const res = await axios.get(this.url + "&month=" + month + "&year=" + year);
      this.items = res.data.data.items;
      this.monthTheme = res.data.data.monthTheme;
      return res;
    },
    onSetDate(date) {
      this.selectedDay = date;
      const dateStr = moment(date).format("YYYY-MM-DD");
      const event = this.monthItems[dateStr];

      this.isCollapsed = true;

      if (event) {
        this.image = event.image;
        this.title = event.title;
        this.text = event.text;
        this.image = event.image;
        this.detailUrl = event.detailUrl;
        this.description = event.description;
        this.dateString = event.dateString;
        this.hasDetail = event.hasDetail;
      }
    }
  }
}
